import styled from 'styled-components';

export const DevicesPageContent = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
  }
`;

export const DevicesCustomerDeviceList = styled.ul<{ $opened: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;

  padding: 32px;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const DevicesCustomerProjectList = styled.ul`
  padding: 16px;
`;

export const DevicesSection = styled.section`
  header {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 1024px) {
      padding-top: 16px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
`;

export const DevicesSectionHeaderActions = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  padding-top: 32px;
`;

export const DevicesProjectSidebar = styled.section<{ $opened: boolean; $loading: boolean }>`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }

  position: sitcky;
  top: 0;
  border-left: 18px solid ${({ theme }) => theme.colors.primary};
  border-right: 1px solid ${({ theme, $opened }) => !$opened ? theme.colors.transparent : theme.colors.surface_frame};
  width: ${({ $opened }) => $opened ? '250px' : '0px'};
  transition: width .3s ease-in-out;
  overflow: hidden;

  > header {
    display: ${({ $opened }) => $opened ? 'flex' : 'none'};
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.surface_frame};

    > button {
      width: auto;
      padding: 0px;
    }

    > button > span {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.neutra_200};
    }
  }

  > div {
    padding: 16px;
    display: ${({ $opened }) => $opened ? 'grid' : 'none'};
  }

  > ul {
    animation: ${({ $opened }) => $opened ? 'fadeIn' : 'fadeOut'} .3s linear;

    @keyframes fadeIn {
      0% {
        display: none;
        opacity: 0;
      }

      90% {
        display: block;
        opacity: 0;
      }

      100% {
        display: block;
        opacity: 1;
      }
    }

    display: ${({ $opened }) => $opened ? 'grid' : 'none'};
  }
  
  > button {
    width: 107px;
    height: 36px;

    position: absolute;
    top: 200px;
    left: -30px;
    
    display: ${({ $loading, $opened }) => !$loading && !$opened ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;

    transform: rotate(90deg);

    ${({ theme }) => theme.fonts.typograph.button2};

    color: ${({ theme }) => theme.colors.surface_white};
    background: ${({ theme }) => theme.colors.primary};

    border-radius: 6px;

    cursor: pointer;

    > span {
      width: 20px;
      font-size: 20px;
    }
  }
`;

export const SelectedCustomerDevicesTable = styled.table`
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  border: 1px solid ${({ theme }) => theme.colors.surface_frame};

  > thead {
    > tr {
      > th {
        ${({ theme }) => theme.fonts.typograph.body2};
        font-size: 13px;
        color: ${({ theme }) => theme.colors.neutra_600};

        padding: 8px;
        text-align: center;
        border-bottom: 1px solid ${({ theme }) => theme.colors.surface_frame};
      }
    }
  }

  > tbody {
    > tr {
      > td {
        ${({ theme }) => theme.fonts.typograph.body2};
        font-size: 13px;
        text-align: center;

        padding: 8px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.surface_frame};
      }
    }

    > tr:nth-child(even) {
      background-color: #fafafb;
    }
  }
`;
