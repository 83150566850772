import { useState } from 'react';
import { RoleType } from '../../invitation-modal.d';
import { ButtonWrapper, Dropdown, Item } from './role-selector.style';

const renderList = (
  list: RoleType[],
  onSelect: (role: RoleType) => void,
  setShow: (show: boolean) => void
) =>
  list.map((role) => (
    <Item
      id="gt_role_dropdown"
      key={role.id}
      onClick={(e) => {
        e.preventDefault();
        onSelect(role);
        setShow(false);
      }}
    >
      {role.label}
    </Item>
  ));

interface Props {
  onSelect: (role: RoleType | null) => void;
  selected: RoleType | null;
}

const RoleSelector = ({ selected, onSelect }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const roles = [
    { id: 'admin', editor: true, admin: true, label: 'Project Manager' },
    { id: 'editor', editor: true, admin: false, label: 'Editor' },
    { id: 'observer', editor: false, admin: false, label: 'Observer' },
  ];

  return (
    <ButtonWrapper
      type="button"
      onClick={(e: any) => {
        if (e.target?.id === 'gt_role_dropdown') return;

        setShow(true);
      }}
      onBlur={(event: any) => {
        event.preventDefault();

        if (event.target?.id === 'gt_role_dropdown') return;
        if (event.relatedTarget?.id === 'gt_role_dropdown') return;

        setShow(false);
      }}
    >
      {selected?.label || 'Role'}
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.86824 5.48057C4.48435 6.15239 3.51565 6.15239 3.13176 5.48057L0.854936 1.49614C0.47399 0.829482 0.955357 3.37812e-07 1.72318 4.04937e-07L6.27682 8.03029e-07C7.04464 8.70154e-07 7.52601 0.829483 7.14506 1.49614L4.86824 5.48057Z"
          fill="#5850ec"
        />
      </svg>
      <Dropdown tabIndex={0} id="gt_role_dropdown" show={show}>
        {renderList(roles, (role: RoleType) => onSelect(role), setShow)}
      </Dropdown>
    </ButtonWrapper>
  );
};

export default RoleSelector;
