import {
  LogoContainer,
  GtLogoIMG,
  GtLogoNameSVG
} from './gt-logo.style';

/**
 * 
 * @returns 
 */
const GtLogo = () => {
  return (
    <LogoContainer>
      <GtLogoIMG src="/assets/icons/gt-logo.svg" />
      <GtLogoNameSVG
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 91.34 7.92"
      >
        <path
          d="M90.91,163.13V162h3.7a2.28,2.28,0,0,1,0,.45c-.06,2-2,3.23-4.43,3.23-2.9,0-4.78-1.45-4.78-3.49s1.93-3.44,4.73-3.44a8.67,8.67,0,0,1,3.48.63v1.24a7,7,0,0,0-3.3-.71c-1.94,0-3.46.76-3.46,2.28s1.4,2.33,3.32,2.33c1.43,0,2.63-.44,2.91-1.37Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M96.07,165.54v-6h1.31v4.89h4.39v1.13Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M106.54,165.66c-2.34,0-4.22-1.19-4.22-3.12s1.88-3.12,4.22-3.12,4.22,1.17,4.22,3.12S108.89,165.66,106.54,165.66Zm0-5.09c-1.63,0-2.82.73-2.82,2s1.19,2,2.82,2,2.82-.76,2.82-2S108.17,160.57,106.54,160.57Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          fill="#231f20"
          d="M112.34,165.54v-6h3.9c1.09,0,2.43.07,2.43,1.65a1.18,1.18,0,0,1-.79,1.06,1.47,1.47,0,0,1,1.32,1.49c0,1.49-1.33,1.82-2.49,1.82Zm1.31-5V162h2.67c.55,0,1-.18,1-.7s-.43-.7-1-.7Zm0,2.36v1.58h3c.63,0,1.11-.16,1.11-.78s-.48-.8-1.11-.8Z"
          transform="translate(-85.44 -157.74)"
        />
        <path
          fill="#231f20"
          d="M125.78,164.52h-4.09l-.66,1h-1.47l4.18-6.12,4.17,6.12h-1.46Zm-3.38-1.07h2.68l-1.34-2Z"
          transform="translate(-85.44 -157.74)"
        />
        <path
          d="M128.92,165.54v-6h1.31v4.89h4.39v1.13Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M136.62,165.54V160h-3v-1.15H141V160h-3v5.54Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M147.25,165.54,146,163.66H143.4v1.88h-1.31v-6h4.11a2.11,2.11,0,0,1,2.38,2.06,2,2,0,0,1-1.26,1.88l1.39,2.08Zm-3.85-4.86v1.82H146c1,0,1.16-.49,1.16-.92s-.16-.9-1.16-.9Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M155.43,164.52h-4.09l-.66,1h-1.46l4.17-6.12,4.17,6.12H156.1Zm-3.38-1.07h2.68l-1.34-2Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M164.88,165.12a6.61,6.61,0,0,1-2.77.54c-2.67,0-4.34-1.12-4.34-3.12s1.67-3.12,4.34-3.12a6.43,6.43,0,0,1,2.77.55v1.24a5.45,5.45,0,0,0-2.74-.65c-1.86,0-3,.74-3,2s1.09,2,3,2a6.07,6.07,0,0,0,2.71-.56Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M170.43,162l3,3.56h-1.71l-2.29-2.71-1.58,1.34v1.37h-1.31v-6h1.31v3.14l3.77-3.14h1.71Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M174.6,158.08a1.43,1.43,0,0,0-.44-.07c-.19,0-.38,0-.38.18,0,.31.92-.07.92.62,0,.41-.32.47-.62.47a2.38,2.38,0,0,1-.55-.06V159a2.07,2.07,0,0,0,.5.06c.23,0,.43,0,.43-.19,0-.34-.93.05-.93-.62,0-.4.34-.46.58-.46a1.9,1.9,0,0,1,.49.07Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
        <path
          d="M176.53,159.25l-.24-.87-.48.9-.48-.9-.25.87h-.24l.43-1.51.54,1.06.54-1.06.43,1.51Z"
          transform="translate(-85.44 -157.74)"
          fill="#231f20"
        />
      </GtLogoNameSVG>
    </LogoContainer>
  );
};

export default GtLogo;
