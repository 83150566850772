import {
  SettingsContext,
  SettingsDispatchType,
  Timezone,
  Unit,
} from './settings.context.d';
import { handleModalChange } from './settings.handlers';

/**
 * 
 * @param state
 * @param action 
 * @returns settings context state
 */
export const reducer = (
  state: SettingsContext,
  action: SettingsDispatchType
): SettingsContext => {
  switch (action.type) {
      case 'SET_TIMEZONE_LIST':
        return { ...state, timezoneList: action.data as Timezone[] };
      case 'SET_SPEED_UNIT':
        return { ...state, speedUnit: action.data as Unit };
      case 'SET_DISTANCE_UNIT':
        return { ...state, distanceUnit: action.data as Unit };
      case 'SET_WEATHER_UNIT':
        return { ...state, weatherUnit: action.data as Unit };
      case 'SET_TIMEZONE_UNIT':
        return { ...state, timezone: action.data as Timezone };
      case 'SCROLL_BLOCK':
        return { ...state, scrollBlocked: action.data as boolean };
      case 'OPEN_MODAL':
        return handleModalChange(action, state);
    default:
      throw new Error(`Unknown settings reducer action ${action.type}`);
  }
};
