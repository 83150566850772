import styled from 'styled-components';

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NewProjectButton = styled.button<{ hide: boolean }>`
  padding: 6px 12px;
  background-color: #5850ec;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const HeaderWrapper = styled.header`
  position: relative;
  z-index: 2;
`;
