import { useState, useEffect, useContext } from 'react';
import {
  Form,
  FormWrapper,
  Container,
  Row,
  LinkButton,
  Button,
  FilesWrapper,
  FileItem,
  FileLabel,
  IlustrationWrapper,
  dropDownCustomStyle,
  DownloadText,
} from './first-form.style';
import { NewRunIlustration } from '../../svgs/newRun.icons';
import SettingsStore from 'app/modules/settings/settings.context';
import InputGroup from 'app/components/input-group/inputGroup';
import Input from 'app/components/input/input';
import DropdownButton from 'app/components/dropdown-button/dropdown-button';
import ButtonComponent from 'app/components/button/button';
import { distanceUnitOptions } from 'app/modules/inspection/run/devices/desktop/components/overlay-run-header/over-run-header.models';

interface FileChanged {
  fileType: string;
  fileName: string;
  fileExt: string;
  file: File;
}

const onFileChange = (setValue: (data: FileChanged) => void, type?: string) => {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  if (type) fileSelector.setAttribute('accept', type);

  fileSelector.click();
  fileSelector.onchange = () => {
    if (!fileSelector.files?.length) return;

    const fileType = fileSelector.files[0].type.split('/')[1];
    const fileName = fileSelector.files[0].name.split('.')[0];
    const fileExt = fileSelector.files[0].name.split('.').reverse()[0];
    const file = fileSelector.files[0];

    const fileToReturn = {
      fileType,
      fileName,
      fileExt,
      file,
    };
    setValue(fileToReturn);
  };
};

const FirstStep = ({ onSubmit, errors, isLoading, setErrors, reset }: any) => {
  const settingsContext = useContext(SettingsStore);
  const [name, setName] = useState<string>('');
  const [pipelineName, setPipelineName] = useState<string>('');
  const [surveyBy, setSurveyBy] = useState<string>('');
  const [gpsAccuracy, setGpsAccuracy] = useState<string>('');
  const [gpsDatum, setGpsDatum] = useState<string>('');
  const [gpsEquipment, setGpsEquipment] = useState<string>('');
  const [geoidModel, setGeoidModel] = useState<string>('');
  const [surveyorPhone, setSurveyorPhone] = useState<string>('');
  const [surveyorCompany, setSurveyorCompany] = useState<string>('');
  const [kml, setKml] = useState<FileChanged | null>(null);
  const [kmls, setKmls] = useState<FileChanged[]>([]);
  const [owner, setOwner] = useState<string>('');
  const cleanFields = () => {
    setName('');
    setSurveyBy('');
    setGpsAccuracy('');
    setGpsDatum('');
    setGpsEquipment('');
    setGeoidModel('');
    setOwner('');
    setSurveyorCompany('');
    setSurveyorPhone('');
    setPipelineName('');
    setKmls([]);
    setKml(null);
    setErrors({});
  };

  const removeFile = (
    file: FileChanged,
    files: FileChanged[],
    setFiles: (data: any) => void
  ) => {
    const filesCopy = files.filter(
      (f: FileChanged) => f.fileName !== file.fileName
    );
    setFiles(filesCopy);
  };

  useEffect(() => {
    if (kml) {
      const kmlsCopy = [...kmls];
      kmlsCopy.push(kml);
      setKmls(kmlsCopy);
      setKml(null);
    }
  }, [kml]);

  useEffect(() => {
    cleanFields();
  }, [reset]);

  return (
    <Container>
      <FormWrapper>
        <Form>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.name}
              label="Survey Name"
            >
              <Input
                id="create-survey-name"
                value={name}
                handleChange={({ target }) => setName(target.value)}
              />
            </InputGroup>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.pipelineName}
              label="Pipeline Name"
            >
              <Input
                id="create-survey-pipeline-name"
                value={pipelineName}
                handleChange={({ target }) => setPipelineName(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.gpsAccuracy}
              label="GPS survey accuracy"
            >
              <Input
                value={gpsAccuracy}
                handleChange={({ target }) => setGpsAccuracy(target.value)}
              />
            </InputGroup>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.gpsDatum}
              label="GPS datum"
            >
              <Input
                value={gpsDatum}
                handleChange={({ target }) => setGpsDatum(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.geoidModel}
              label="Geoid model"
            >
              <Input
                value={geoidModel}
                handleChange={({ target }) => setGeoidModel(target.value)}
              />
            </InputGroup>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.gpsEquipment}
              label="GPS equipment"
            >
              <Input
                value={gpsEquipment}
                handleChange={({ target }) => setGpsEquipment(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.surveyorCompany}
              label="Surveyor Company"
            >
              <Input
                value={surveyorCompany}
                handleChange={({ target }) => setSurveyorCompany(target.value)}
              />
            </InputGroup>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.surveyorPhone}
              label="Surveyor Phone"
            >
              <Input
                value={surveyorPhone}
                type="tel"
                handleChange={({ target }) => setSurveyorPhone(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.owner}
              label="Owner/Operator"
            >
              <Input
                value={owner}
                handleChange={({ target }) => setOwner(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.unities}
              label="Chainage U of M"
            >
              <DropdownButton
                iconFillColor="#000"
                customStyle={dropDownCustomStyle}
                iconFill="#000"
                text={`${settingsContext.state.distanceUnit?.label}`}
                type="radio"
                groups={[
                  distanceUnitOptions.map((option) => ({
                    ...option,
                    selected: option.id === settingsContext.state.distanceUnit?.id,
                  })), // 1
                ]}
                onClick={(option: any, index: number) => {
                  const setDistanceUnit = (option: any) =>
                    settingsContext.dispatch({
                      type: 'SET_DISTANCE_UNIT',
                      data: {
                        id: option.id,
                        label: option.label,
                      },
                    });
                  const handles = [setDistanceUnit]; // 0, 1
                  handles[index](option);
                }}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.kmls}
              description="Supported files: .kmz, .kml, .xls(x), .png, .jpeg/.jpg, .gif,
                .zip (combining any of those files)"
            >
              <DownloadText href="https://cdi-gt-medias.s3.us-east-2.amazonaws.com/GlobalTrack_SheetTemplate.xlsx">
                Download Sheet Template
              </DownloadText>
              <Button
                onClick={() =>
                  onFileChange(setKml, 'application/vnd.google-earth.kml+xml')
                }
              >
                Upload Files
              </Button>
            </InputGroup>
          </Row>
          <FilesWrapper>
            {kmls.map((kml: any, i: number) => (
              <FileItem key={i}>
                <FileLabel>{`${kml.fileName}.${kml.fileExt}`}</FileLabel>
                <svg
                  onClick={() => removeFile(kml, kmls, setKmls)}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="14.2427"
                    y="4.34375"
                    width="2"
                    height="14"
                    rx="1"
                    transform="rotate(45 14.2427 4.34375)"
                    fill="#000"
                  />
                  <rect
                    x="15.6567"
                    y="14.2422"
                    width="2"
                    height="14"
                    rx="1"
                    transform="rotate(135 15.6567 14.2422)"
                    fill="#000"
                  />
                </svg>
              </FileItem>
            ))}
          </FilesWrapper>
          <Row>
            <ButtonComponent
              customStyle={`
                height: 32px;
                :focus {
                  padding: 5px 10px;
                  border: 1px solid #e5e5e5;
                  border-radius: 6px;
                  width: 100%;
                  background-color: #5850ec;
                  color: white;
                  font-weight: 500;
                  font-size: 0.93em;
                  line-height: 1.12em;
                  cursor: pointer;
                  height: 32px;
                }
              `}
              handleClick={() =>
                onSubmit(
                  {
                    name,
                    kmls,
                    surveyBy,
                    gpsAccuracy,
                    gpsDatum,
                    gpsEquipment,
                    geoidModel,
                    surveyorCompany,
                    surveyorPhone,
                    pipelineName,
                    owner,
                  },
                  cleanFields
                )
              }
              value={isLoading ? 'loading...' : 'Upload Survey'}
              disabled={isLoading}
              id="create-survey-submit"
            />
            <LinkButton onClick={cleanFields}>Clear Fields</LinkButton>
          </Row>
        </Form>
        <IlustrationWrapper>
          <NewRunIlustration />
        </IlustrationWrapper>
      </FormWrapper>
    </Container>
  );
};

FirstStep.defaultProps = {};

export default FirstStep;
