import { useState, useEffect } from 'react';
import downloadjs from 'downloadjs';
import {
  ActionList,
  ActionItem,
  Caption,
  CaptionWrapper,
  CaptionActionsWrapper,
  CaptionActionButton,
  Dropdown,
  MediaWrapper,
  MediaPreview,
  MediaActionsButton,
  SiteSketchTag,
} from './media-carrousel-item.style';

interface MediaCarrouselItemProps {
  media: string;
  mediaId: string;
  isSiteSketch: boolean;
  caption: string;
  onToggleSiteSketch: () => void;
  onDelete: () => void;
  setAllowArrowNavigation: (allowed: boolean) => void;
  onEditCaption: (caption: string) => void;
  permissionType: string;
}

/**
 * request image blob by xml http request to make download using
 * the downloadjs package
 * @param url file url
 */
const download = (url: string) => {
  const filename = url.split('?')[0]?.split('/').pop() || '';
  const request = new XMLHttpRequest();

  request.open('GET', url, true);
  request.responseType = 'blob';
  request.onload = (e: any) => {
    downloadjs(
      e.target.response,
      filename,
      `image/${filename.split('.').pop()}`
    );
  };

  request.send();
};

const MediaCarrouselItem = ({
  media,
  mediaId,
  isSiteSketch,
  caption,
  onDelete,
  onToggleSiteSketch,
  onEditCaption,
  setAllowArrowNavigation,
  permissionType,
}: MediaCarrouselItemProps) => {
  const [showActions, setShowActions] = useState<boolean>(false);
  const [editingCaption, setEditingCaption] = useState<boolean>(false);
  const [captionText, setCaptionText] = useState<string>('');
  const [shift, setShift] = useState<boolean>(false);

  useEffect(() => {
    setCaptionText(caption);
  }, []);

  const captionId = `gt_edit_caption_area_${mediaId}`;

  return (
    <MediaWrapper>
      {isSiteSketch ? <SiteSketchTag>Site Sketch</SiteSketchTag> : null}
      <MediaPreview alt="" src={media} />
      <CaptionWrapper className="caption_wrapper" editing={editingCaption}>
        <Caption
          className={captionId}
          placeholder="No caption."
          value={captionText}
          disabled={!editingCaption || permissionType !== 'editor'}
          onChange={(e) => {
            const target = e.target as unknown as HTMLInputElement;
            setCaptionText(target.value);
          }}
          onBlur={(e: any) => {
            if (e.relatedTarget?.id === 'gt_caption_actions') return;
            setCaptionText(caption.trim() || '');
            setEditingCaption(false);
            setAllowArrowNavigation(true);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Shift') {
              setShift(true);
            }
          }}
          onKeyUp={(e: any) => {
            if (e.key === 'Shift') {
              e.preventDefault();
              setShift(false);
            }

            if (e.key === 'Enter' && !shift) {
              e.preventDefault();
              setEditingCaption(false);
              onEditCaption(captionText.trim());
              setAllowArrowNavigation(true);
            }

            if (e.key === 'Escape') {
              e.preventDefault();
              setEditingCaption(false);
              setCaptionText(caption.trim() || '');
              setAllowArrowNavigation(true);
            }
          }}
        />
        {!editingCaption ? null : (
          <CaptionActionsWrapper
            onClick={(e) => e.stopPropagation()}
            className="caption-actions-wrapper"
          >
            <CaptionActionButton
              onClick={() => {
                setEditingCaption(false);
                onEditCaption(captionText.trim());
                setAllowArrowNavigation(true);
              }}
              id="gt_caption_actions"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffffff"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z" />
              </svg>
            </CaptionActionButton>
            <CaptionActionButton
              id="gt_caption_actions"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                setEditingCaption(false);
                setCaptionText(caption || '');
                setAllowArrowNavigation(true);
              }}
            >
              <svg
                id="gt_caption_actions"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffffff"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
              </svg>
            </CaptionActionButton>
          </CaptionActionsWrapper>
        )}
      </CaptionWrapper>
      <Dropdown hide={editingCaption}>
        <MediaActionsButton
          onClick={() => setShowActions(!showActions)}
          onBlur={(e: any) => {
            if (e.relatedTarget?.id === 'gt_actions') return;
            setShowActions(false);
          }}
        >
          <svg
            width="16"
            height="4"
            viewBox="0 0 16 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="white" />
            <circle cx="8" cy="2" r="2" fill="white" />
            <circle cx="14" cy="2" r="2" fill="white" />
          </svg>
        </MediaActionsButton>
        <ActionList show={showActions} id="gt_actions" tabIndex={0}>
          {permissionType === 'editor' ? (
            <>
              <ActionItem
                id="gt_actions"
                onClick={() => {
                  setShowActions(false);
                  onToggleSiteSketch();
                }}
              >
                {isSiteSketch ? 'Unmark as site sketch' : 'Mark as site sketch'}
              </ActionItem>
              <ActionItem
                id="gt_actions"
                onClick={() => {
                  setShowActions(false);
                  setAllowArrowNavigation(false);
                  setEditingCaption(true);
                  const captionEl: any = document.querySelector(
                    '.' + captionId
                  );

                  if (captionEl) {
                    setTimeout(() => captionEl.focus(), 1);
                  }
                }}
              >
                Edit caption
              </ActionItem>
              <ActionItem
                id="gt_actions"
                onClick={() => {
                  setShowActions(false);
                  onDelete();
                }}
              >
                Delete
              </ActionItem>
            </>
          ) : null}
          <ActionItem
            id="gt_actions"
            onClick={(e) => {
              e.preventDefault();
              setShowActions(false);
              download(media);
            }}
          >
            Download
          </ActionItem>
        </ActionList>
      </Dropdown>
    </MediaWrapper>
  );
};

export default MediaCarrouselItem;
