/**
 * 
 * @returns 
 */
const CloseIcon = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="16.9492"
      y="4.94971"
      width="2"
      height="16"
      transform="rotate(45 16.9492 4.94971)"
      fill="#99A2AD"
    />
    <rect
      x="18.3633"
      y="16.2634"
      width="2"
      height="16"
      transform="rotate(135 18.3633 16.2634)"
      fill="#99A2AD"
    />
  </svg>
);

export default CloseIcon;
