import styled from 'styled-components';

export const DeviceModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;

  width: 100%;
  height: 100vh;
  background-color: #ffffff;

  > #scan-render {
    position: absolute;
    width: 100%;
    height: 100%;

    > video {
      height: 100%; 
      object-fit: cover;
    }
  }
`;

export const DeviceHeader = styled.header`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  height: 40px;

  padding-left: 16px;
  padding-right: 16px;
`;

export const DeviceCloseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
`;
