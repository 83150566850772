import styled from 'styled-components';

export const Group = styled.div`
  width: 100%;

  display: grid;
  gap: 8px;
`;

export const GroupLabel = styled.label<{ disabled: boolean }>`
  ${({ theme }) => theme.fonts.typograph.body2};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.neutra_300 : theme.colors.outline_on_surface)};
`;

export const GroupInput = styled.input`
  height: 100%;
  padding: 0px 16px;
  border: none;
  
  ${({ theme }) => theme.fonts.typograph.body2};
  
  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    ${({ theme }) => theme.colors.body2};
    color: ${({ theme }) => theme.colors.neutra_300};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.outline_on_surface};
  }
`;

export const GroupError = styled.p.attrs({ className: 'error' })`
  margin: 0;

  ${({ theme }) => theme.fonts.typograph.caption};
  color: ${({ theme }) => theme.colors.system_danger};
`;

export const GroupInputWrapper = styled.div<{ error: boolean}>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  width: 100%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid;
  border-color: ${({ theme, error }) => (error ? theme.colors.system_danger : theme.colors.neutra_300)};
  background-color: ${({ theme }) => theme.colors.surface_white};
  overflow: hidden;

  &:has(input:disabled) {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutra_100};    
  }

  > span {
    color: ${({ theme }) =>  theme.colors.neutra_300};
    font-size: 22px;
    padding-right: 8px;

    cursor: pointer;
  }
  
  > input {
    padding-left: 16px;
    padding-right: 8px;

    &:disabled,
    &:disabled + span {
      cursor: not-allowed;
    }
  }
`;
