import { SettingsContext } from "app/modules/settings/settings.context.d";
import { PassageType, TrackingPointCascadeType } from "../../inspection.interfaces.d";
import { formatDistance, formatMps, formatTimezoneDate } from "@/lib/formatter";

const getETA = (
  point: TrackingPointCascadeType,
  settingsState: SettingsContext,
) => {

  return formatTimezoneDate({
    date: point.eta,
    timezone: settingsState.timezone?.id,
    format: 'MM/DD HH:mm:ss'
  }) || '';
}

const getPassageDate = (
  passage: PassageType | undefined,
  settingsState: SettingsContext,
) => {
  if (!passage) return '';

  return formatTimezoneDate({
    date: passage.tstamp,
    timezone: settingsState.timezone?.id,
    format: 'YYYY-MM-DD'
  }) || '';
}

const getPassageTime = (
  passage: PassageType | undefined,
  settingsState: SettingsContext,
) => {
  if (!passage) return '';

  return formatTimezoneDate({
    date: passage.tstamp,
    timezone: settingsState.timezone?.id,
    format: 'HH:mm:ss.SSS'
  }) || '';
}

const getSpeed = (
  speed: string,
  settingsState: SettingsContext,
) => {
  return formatMps({
    distance: speed,
    unit: settingsState.speedUnit,
    returnNumber: true,
  }) || '';
}

const getDistance = (
  value: string,
  settingsState: SettingsContext,
) => {
  return formatDistance({
    distance: parseFloat(value),
    unit: settingsState.distanceUnit?.id || 'm',
    returnNumber: true,
  }) || '';
}
const getLngOrLat = (
  value: string,
) => {
  return parseFloat(value).toFixed(8);
}

const getColumnFromCascade = (column, point: TrackingPointCascadeType, settingsState) => {
  const data = {
    index: () => `${point.index}`,
    active: () => point.active ? '✓' : 'x',
    type: () => `${point.type}`,
    name: () => `${point.name}`,
    passage_date: () => getPassageDate(point.passage, settingsState),
    passage_time: () => getPassageTime(point.passage, settingsState),
    speed: () => getSpeed(point.speed, settingsState),
    speed_delta: () => getSpeed(point.speed_delta, settingsState),
    eta: () => getETA(point, settingsState),
    distance: () => getDistance(point.distance, settingsState),
    longitude: () => getLngOrLat(point.geometry.coordinates[0]),
    latitude: () => getLngOrLat(point.geometry.coordinates[1]),
    chainage: () => getDistance(`${point.chainage}`, settingsState),
    elevation: () => getDistance(`${point.elevation}`, settingsState),
    inclination: () => `${parseFloat(point.inclination)?.toFixed(1) || 0}°`,
    milepost: () => `${point.milepost}`,
    description: () => `${point.description}`,
    comment: () => `${point.comment}`,
    depth_of_cover: () => `${point.depth_of_cover}`,
    alignment_sheet: () => `${point.alignment_sheet}`,
    speed_offset: () => getSpeed(`${point.speed_offset}`, settingsState),
    device_sn: () => `${point.device_sn}`,
    county: () => `${point.county}`,
    state: () => `${point.state}`,
    country: () => `${point.country}`,
    tract: () => `${point.tract}`,
    pressure_differential: () => `${point.pressure_differential}`,
    location_description: () => `${point.location_description}`,
    antenna_height: () => `${point.antenna_height}`,
    datum: () => `${point.datum}`,
    elevation_top: () => `${point.elevation_top}`,
    ellipsoid_height: () => `${point.ellipsoid_height}`,
    ellipsoid_height_cop: () => `${point.ellipsoid_height_cop}`,
    geoid_model: () => `${point.geoid_model}`,
    survey_accuracy: () => `${point.survey_accuracy}`,
    survey_method: () => `${point.survey_method}`,
  };

  const getValue = data[column] as () => string;

  const value = getValue ? getValue() : ''
  return !value || value === 'null' ? '' : value;
}

const getRowFromCascade = (columns, columnNames, point: TrackingPointCascadeType, settingsState: SettingsContext) => {
  const row = {};
  const data = {
    index: () => `${point.index || ''}`,
    active: () => point.active ? '✓' : 'x',
    type: () => `${point.type || ''}`,
    name: () => `${point.name || ''}`,
    passage_date: () => getPassageDate(point.passage, settingsState),
    passage_time: () => getPassageTime(point.passage, settingsState),
    speed: () => getSpeed(point.speed, settingsState),
    speed_delta: () => getSpeed(point.speed_delta, settingsState),
    eta: () => getETA(point, settingsState),
    "ete": () => '',
    distance: () => getDistance(point.distance, settingsState),
    longitude: () => getLngOrLat(point.geometry.coordinates[0]),
    latitude: () => getLngOrLat(point.geometry.coordinates[1]),
    chainage: () => getDistance(`${point.chainage}`, settingsState),
    elevation: () => getDistance(`${point.elevation}`, settingsState),
    inclination: () => `${parseFloat(point.inclination)?.toFixed(1) || 0}°`,
    milepost: () => `${point.milepost || ''}`,
    description: () => `${point.description || ''}`,
    comment: () => `${point.comment || ''}`,
    depth_of_cover: () => `${point.depth_of_cover || ''}`,
    alignment_sheet: () => `${point.alignment_sheet || ''}`,
    speed_offset: () => getSpeed(`${point.speed_offset}`, settingsState),
    device_sn: () => `${point.device_sn || ''}`,
    county: () => `${point.county || ''}`,
    state: () => `${point.state || ''}`,
    country: () => `${point.country || ''}`,
    tract: () => `${point.tract || ''}`,
    pressure_differential: () => `${point.pressure_differential || ''}`,
    location_description: () => `${point.location_description || ''}`,
    antenna_height: () => `${point.antenna_height || ''}`,
    datum: () => `${point.datum || ''}`,
    elevation_top: () => `${point.elevation_top || ''}`,
    ellipsoid_height: () => `${point.ellipsoid_height || ''}`,
    ellipsoid_height_cop: () => `${point.ellipsoid_height_cop || ''}`,
    geoid_model: () => `${point.geoid_model || ''}`,
    survey_accuracy: () => `${point.survey_accuracy || ''}`,
    survey_method: () => `${point.survey_method || ''}`,
  };

  const fieldsWithUnits = {
    speed: settingsState.speedUnit?.id,
    speed_delta: settingsState.speedUnit?.id,
    distance: settingsState.distanceUnit?.id,
    distance_delta: settingsState.distanceUnit?.id,
    chainage: settingsState.distanceUnit?.id,
  };
  
  columns.forEach((c, index) => {
    if (data[c] && columnNames[index]) {
      let columnName = columnNames[index];
      
      if (fieldsWithUnits[c]) {
        columnName = `${columnName} (${fieldsWithUnits[c]})`;
      }
      
      row[columnName] = data[c]();
    }
  });
  
  return row;
}

export const convertCSVPoint = (
  point: TrackingPointCascadeType,
  columns: string[],
  settingsState: SettingsContext,
) => columns.map((c: string) => getColumnFromCascade(c, point, settingsState));


export const convertXLXSPoint = (
  point: TrackingPointCascadeType,
  columns: string[],
  columnNames: string[],
  settingsState: SettingsContext,
) => getRowFromCascade(columns, columnNames, point, settingsState);
