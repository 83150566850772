import { ReactNode, MouseEvent } from 'react';
import { Button } from './secondary-button.style';

interface Props {
  handleClick?(e: MouseEvent): void;
  value?: string;
  customStyle?: string;
  testId?: string;
  children: ReactNode;
}

/**
 * Button component
 * @returns
 */
const SecondaryButton = ({
  value,
  handleClick,
  children,
  customStyle,
  testId,
}: Props) => (
  <Button
    type="button"
    value={value}
    data-testid={testId}
    onClick={handleClick}
    customStyle={customStyle || ''}
  >
    {children}
  </Button>
);

SecondaryButton.defaultButton = {
  customStyle: '',
  value: null,
  handleClick: () => '',
};

export default SecondaryButton;
