import styled from "styled-components";

export const MobileQubesModalWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 21;

  background-color: #ffffff;

  height: -webkit-fill-available;
  width: 100%;

  overflow-y: auto;

  display: grid;
  grid-template-rows: auto 1fr;
  align-content: start;

  > header {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const MobileQubesModalGoBackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const MobileQubesModalList = styled.ul`
  overflow-y: auto;
`;
