import styled from 'styled-components';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 450px 1fr;

  @media (max-width: 768px) {
    display: block;
    height: 100vh;
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 500px;
`;

export const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 12px;

  > button {
    margin: 0;
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Banner = styled.section`
  display: none;

  background-color: #eff0f3;
  font-size: 0.75;
  line-height: 1.125em;
  cursor: pointer;
  position: relative;
  padding: 24px 0;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const GtLogo = styled.img`
  height: 30px;
`;

export const GtNameLogo = styled.svg`
  height: 22px;
`;

export const GtSolutions = styled.svg`
  width: 85%;
  margin-top: 38px;
  padding: 0 26px;

  position: relative;
  z-index: 4;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  margin-top: 16px;
  padding: 0 26px;

  position: relative;
  z-index: 4;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em;

  margin-bottom: 20px;
`;

export const submitCustomStyle = `
  width: 225px;
  margin-top: 32px;

  @media(max-width: 768px) {
   max-width: 100%;
   width: 100%;
  }
`;

export const Terms = styled.p`
  margin-top: 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #686c7b;

  > a {
    color: #5850ec;
  }
`;

export const Row = styled.div`
  display: grid;
  gap: 10px;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LoginLink = styled.span`
  >a {
    text-decoration: underline;
  }
`;
