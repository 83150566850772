import styled from 'styled-components';

const textColors = { default: 'neutra_700', primary: 'primary', negative: 'surface_white' };
const textHoverColors = { default: 'neutra_900', primary: 'primary_900', negative: 'neutra_300' };
const textActiveColors = { default: 'neutra_900', primary: 'primary_900', negative: 'neutra_300' };
const textDisabledColors = { default: 'neutra_100', primary: 'primary_100', negative: 'neutra_300' };

export const Button = styled.button<{ $style: string }>`
  display: flex;
  align-items: center;
  gap: 8px;

  height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: transparent;

  border: none;
  border-radius: 6px;
  background-color: transparent;
  color: ${({ theme, $style }) => theme.colors[textColors[$style]]};
  cursor: pointer;

  transition-duration: 400ms;

  &:hover {
    color: ${({ theme, $style }) => theme.colors[textHoverColors[$style]]};
  }
  
  &:active,
  &:visited {
    color: ${({ theme, $style }) => theme.colors[textActiveColors[$style]]};
  }
  
  &:disabled {
    color: ${({ theme, $style }) => theme.colors[textDisabledColors[$style]]};
    cursor: not-allowed;
  }

  > span.material-icons-round {
    font-size: 24px;
  }
`; 
