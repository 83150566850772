import styled from 'styled-components';

export const Wrapper = styled.div<{ customStyle?: string }>`
  width: 100%;
  height: 20px;
  position: relative;

  ${({ customStyle }) => customStyle}
`;

export const Dots = styled.img`
  position: absolute;
  right: -25px;
  top: 25px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const ListWrapper = styled.div<{ show?: boolean }>`
  width: 200px;
  position: relative;
  background-color: #fff;
  position: absolute;
  right: -25px;
  top: 50px;
  z-index: 10;
  box-shadow: 3px 9px 26px -3px rgba(0, 0, 0, 0.81);

  ${({ show }) => (show ? '' : 'display: none')}
`;

export const Option = styled.div`
  display: grid;
  grid-template-columns: 15px 1fr;
  width: 100%;
  padding: 3px 12px;
  cursor: pointer;
  :hover {
    background-color: #afafaf;
  }
  > svg {
  }
`;

export const OptionLabel = styled.label`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
`;
