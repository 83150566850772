import { NotificationProviderValue } from 'app/modules/notification/notification.context.d';
import { AccountDispatchType, RegisterData } from '../account.context.d';
import { verifyToken } from 'app/services/auth.service';
import {
  acceptProjectInvitation,
  acceptShareableInvitation,
  getProjectInvitation,
} from 'app/services/invitation.service';
import { History } from 'history';

/**
 * 
 * @param token 
 * @param history 
 * @param setVerified 
 * @returns 
 */
export const verifyAuth = async (
  token: string | null,
  history: History,
  setVerified: (verified: boolean) => void
) => {
  try {
    await verifyToken(token);
  } catch (err) {
    setVerified(true);
    return;
  }

  history.push(`/projects`);
  setVerified(true);
};

/**
 * 
 * @param token 
 * @param registerData 
 * @param dispatch 
 * @param history 
 * @param setError 
 * @returns 
 */
export const acceptSharableInvitation = async (
  token: string,
  registerData: RegisterData | null,
  dispatch: (action: AccountDispatchType) => void,
  notificationContext: NotificationProviderValue,
  history: History,
  setError: (error: any) => void
) => {
  let acceptResponse;

  try {
    acceptResponse = await acceptShareableInvitation(token, {
      ...registerData,
      user_id: undefined,
    });

    const verifyResponse = await verifyToken(acceptResponse.data.auth_token);

    window.localStorage.setItem(
      'CDI@auth-token',
      acceptResponse.data.auth_token
    );
    window.localStorage.setItem(
      'CDI@auth-email',
      verifyResponse.data.user.email
    );

    dispatch({
      type: 'LOGIN',
      data: {
        token: acceptResponse.data.auth_token,
        user: verifyResponse.data.user,
      },
    });
  } catch (err: any) {
    if (err?.response?.data?.type === 'register/duplicated-email') {
      setError({ email: 'E-mail already registered.' });
      notificationContext.dispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          text: 'User already exists, please go back and log in.'
        },
      });
      return;
    }
  }

  history.push(`/projects/${acceptResponse?.data.invitation.project.id}`);
};

/**
 * 
 * @param token 
 * @param registerData 
 * @param dispatch 
 * @param history 
 * @returns 
 */
export const acceptInvitation = async (
  token: string,
  registerData: RegisterData | null,
  dispatch: (action: AccountDispatchType) => void,
  history: History,
) => {
  const acceptResponse = await acceptProjectInvitation(token, registerData);
  const verifyResponse = await verifyToken(acceptResponse.data.token);

  window.localStorage.setItem('CDI@auth-token', acceptResponse.data.token);
  window.localStorage.setItem('CDI@auth-email', verifyResponse.data.user.email);

  dispatch({
    type: 'LOGIN',
    data: { token: acceptResponse.data.token, user: verifyResponse.data.user },
  });

  if (acceptResponse.data?.projects?.length > 1) {
    history.push(`/projects`);
    return;
  }

  history.push(`/projects/${acceptResponse.data.projects[0]}`);
};

/**
 * 
 * @param token 
 * @param setEmail 
 * @param history 
 */
export const getInvitation = async (
  token: string,
  setEmail: (email: string) => void,
  history: History
) => {
  const invitationResponse = await getProjectInvitation(token);

  if (invitationResponse.data.registered) {
    history.push(`/`);
  }

  setEmail(invitationResponse.data.email);
};