import styled from "styled-components";

export const FloatingRefreshNotificationContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 92px;

  top: 0;
  right: 0;
  
  padding: 8px 24px 8px 16px;
  background-color: #323030;
  
  
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  animation: slide-up 1s ease;

  @keyframes slide-up {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  > p {
    color: ${({ theme }) => theme.colors.surface_white};
    font-size: 16px;
  }

  > button {
    font-size: 16px;
  }
`;

