import { useReducer, createContext, useEffect, ReactNode } from 'react';
import { reducer } from './settings.reducer';
import {
  SettingsContext,
  SettingsDispatchType,
  SettingsProviderValue,
} from './settings.context.d';
import { setSettingsFromLocalStore, updateLocalStoreSettings } from './settings.controller';
import { initialModalsValue } from './settings.handlers';

export const initialSettingsState: SettingsContext = {
  timezoneList: undefined,
  speedUnit: {
    id: "mph",
    label: "mph",
  },
  distanceUnit: undefined,
  weatherUnit: undefined,
  timezone: undefined,
  focusTime: undefined,
  scrollBlocked: false,
  modals: { ...initialModalsValue },
}

const SettingsStore = createContext({} as SettingsProviderValue);

interface Props {
  children: ReactNode;
  value?: SettingsContext;
}

/**
 * 
 * @returns 
 */
export const SettingsProvider = (props: Props) => {
  // Define state and dispatch method for this Context
  const reducerData = useReducer(reducer, { ...initialSettingsState, ...props.value });
  const state: SettingsContext = reducerData[0];
  const dispatch: (data: SettingsDispatchType) => void = reducerData[1];

  useEffect(() => {
    setSettingsFromLocalStore(dispatch);
  }, []);
  
  useEffect(() => {
    updateLocalStoreSettings('distanceUnit', state.distanceUnit);
  }, [state.distanceUnit]);
  
  useEffect(() => {
    updateLocalStoreSettings('timezone', state.timezone);
  }, [state.timezone]);
  
  useEffect(() => {
    updateLocalStoreSettings('speedUnit', state.speedUnit);
  }, [state.speedUnit]);
  
  useEffect(() => {
    updateLocalStoreSettings('weatherUnit', state.weatherUnit);
  }, [state.weatherUnit]);
  
  // props comes afterwards so the can override the default ones.
  return (
    <SettingsStore.Provider value={{ state, dispatch }}>
      {props.children}
    </SettingsStore.Provider>
  );
};

export default SettingsStore;
