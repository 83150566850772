import { useEffect, useState, useMemo } from 'react';
import { InstanceType } from '@rxdjango/react';
import { Column } from 'slickgrid';
import MobileDetect from 'mobile-detect';
import { CustomCulumn, CustomSlickGrid, SlickCellProps, SlickRowProps, SlickTableProps } from './slick-table.interfaces';
import { init } from './slick-table.controller';
import { GtTable } from './slick-table.style';
import GTDataView from './slick-table.dataview';
import 'slickgrid/dist/styles/css/slick.grid.css';
import 'slickgrid/dist/styles/css/slick.gridmenu.css';
import 'slickgrid/dist/styles/css/slick.columnpicker.css';

/**
 *
 * @returns
 */
function SlickCell<T extends InstanceType>(props: SlickCellProps<T>) {  
  const values = useMemo(() => {
    const nvalues = [] as string[];

      nvalues.push(props.data[props.column.field] || undefined);

    if (props.column.affected_by?.length) {
      for (const affected of props.column.affected_by) {
        nvalues.push(props.data[affected] || undefined);
      }
    }

    return nvalues;
  }, [props.data, props?.column]);

  useEffect(() => {
    props.grid?.updateCell(props.row, props.cell);
  }, [...values]);

  return null;
};

/**
 *
 * @returns
 */
function SlickRow <T extends InstanceType>(props: SlickRowProps<T>) {
  const [data, setData] = useState<T>(props.data);
  const columns = props.grid?.getColumns() as CustomCulumn[];

  useEffect(() => {
    if (props.dataView.getItemById(props.data.id)) {
      props.dataView.updateItem(props.data.id, { ...props.data });
      setData({ ...props.data });
    }
  }, [props.data]);

  return (
    <>
      {
        columns?.map((column, index) => (
          <SlickCell
            dataView={props.dataView}
            permissionType={props.permissionType}
            column={column}
            key={index}
            cell={index}
            row={props.index}
            data={data}
            grid={props.grid}
          />
        ))
      }
    </>
  );
};

/**
 *
 * @param param0
 * @returns
 */
function SlickTable<T extends InstanceType>(props: SlickTableProps<T>) {
  const [grid, setGrid] = useState<CustomSlickGrid<T>>();
  const [dataView, setDataView] = useState<GTDataView<T>>(new GTDataView<T>());
  const md = new MobileDetect(window.navigator.userAgent);

  const overideOnLoad = (gd: CustomSlickGrid<T>, data: GTDataView<T>) => {
    setGrid(gd);
    setDataView(data);
    if (props.onLoad) props.onLoad(gd, data);
  };

  useEffect(() => {
    // init table
    init(
      props.columns,
      dataView,
      {
        editable: true,
        alwaysAllowHorizontalScroll: true,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        autoEdit: !!md.mobile(),
        headerRowHeight: 18,
        gridMenu: {
          columnTitle: 'Columns',
          hideForceFitButton: true,
          hideSyncResizeButton: true,
          iconImage: '/assets/slickgrid/drag-handle.png',
          resizeOnShowHeaderRow: true,
        },
        rowHeight: 25,
        ...props.options,
      },
      overideOnLoad,
      props.metadata,
    );
  }, []);

  useEffect(() => {
    if (grid) {
      grid.setColumns(props.columns as Column[]);
      grid.invalidateAllRows();
      grid.render();
    }
  }, [props.columns]);

  useEffect(() => {
    dataView.setItems(props.data);
  }, [props.data.length]);

  useEffect(() => {
    if (grid) {
      const options = grid.getOptions();
      grid.setOptions({
        ...options,
        editable: props.permissionType === 'editor',
      });

      grid.metadata = props.metadata;
      grid.invalidateAllRows();
      grid.render();
    }
  }, [props.permissionType, props.metadata]);

  return (
    <>
      <GtTable customStyle={props.customStyle} id="grid" />
      {
        grid ? props.data.map((data, index) => (
          <SlickRow
            key={index}
            data={data}
            dataView={dataView}
            index={index}
            grid={props.grid}
            permissionType={props.permissionType}
          />
        )) : null
      }
    </>
  )
};

SlickTable.defaultProps = {};

export default SlickTable;
