import { useEffect, useState } from "react";
import { changePipeline, renderPipeline } from "./pipeline.controller";
import { PipelineType } from "app/modules/survey/survey.interfaces";

interface Props {
  pipeline: PipelineType;
  map: google.maps.Map;
}

const Pipeline = ({ pipeline, map }: Props) => {
  const [innerPolyline, setInnerPolyline] = useState<google.maps.Polyline | undefined>();
  const [outerPolyline, setOuterPolyline] = useState<google.maps.Polyline | undefined>();

  useEffect(() => {
    renderPipeline(
      pipeline,
      map,
      outerPolyline,
      setOuterPolyline,
      innerPolyline,
      setInnerPolyline,
    );
    return () => {
      innerPolyline?.setMap(null);
      outerPolyline?.setMap(null);
    };
  }, [pipeline]);
  
  useEffect(() => {
    changePipeline(
      pipeline,
      map,
      outerPolyline,
      innerPolyline,
    );
  }, [pipeline]);

  useEffect(() => {
    return () => {
      innerPolyline?.setMap(null);
      outerPolyline?.setMap(null);
    };
  }, [pipeline, innerPolyline, outerPolyline]);

  return null;
};

export default Pipeline;