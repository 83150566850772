import { createContext } from 'react';
import { InspectionContext, InspectionProviderValue } from './inspection.context.d';

export const initialInspectionState: InspectionContext = {
  map: undefined,
  grid: undefined,
  tick: 0,
  no_connection_since: undefined,
  reconnection_attempt: undefined,
  permission_type: undefined,
  view_mode: undefined,
  weather_tile: undefined,
  user_position: undefined,
  has_labels: false,
  has_ruler: false,
  modals: {
    event: false,
    create_event: false,
    show_create_timepin_modal: false,
    launching: false,
    finishing: false,
    aborting: false,
  },
  widgets: {
    weather: true,
    speed: true,
    elevation: true,
    inclination: true,
    arrival: true,
    next_point: true,
  },
  show_timepins_modal: false,
  selected_point: undefined,
  selected_timepin: undefined,
  selected_trigger_passage: undefined,
  selected_qube_marker: undefined,
  selected_point_triggers: undefined,
  selected_media: undefined,
  following_logged_user: false,
  following_pig: false,
  explanation_map_tooltip: undefined,
  mobile: {
    selected_point: undefined,
    selected_point_to_passage: undefined,
    selected_point_to_gallery: undefined,
    selected_point_to_trigger: undefined,
    selected_cluster_markers: [],
    selected_qube: undefined,
    selected_trigger: undefined,
    selected_media_index: 0,
    passage_tstamp: undefined,
    settings_modal: false,
    informations_modal: false,
    widgets_modal: false,
    schedule_modal: false,
    qubes_modal: false,
  },
};

const InspectionStore = createContext({} as InspectionProviderValue);
export default InspectionStore;
