import { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import VMasker from 'vanilla-masker';
import { extractNumbers, formatTimezoneDate } from '@/lib/formatter';
import Button from '../button/button';
import InputGroup from '../input-group/inputGroup';
import Input from '../input/input';
import LinkButton from '../link-button/link-button';
import {
  ModalWrapper,
  Modal,
  ButtonWrapper,
  ConfirmationTitle,
  ConfirmationFooter,
  buttonCustomStyle,
  ErrorLabel,
  CloseButtonContainer,
  EventExplanation,
  Main,
  Header,
} from './create-event-modal.style';
import { saveEvent } from './create-event-modal.controller';
import InputDate from '../input-date/input-date';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import AccountStore from 'app/modules/account/account.context';

const CreateEventOverlay = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const accountContext = useContext(AccountStore);

  const [name, setName] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [error, setError] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timePosition, setTimeposition] = useState<any>(null);

  const clear = () => {
    setName('');
    setTime('');
    setDate('');
    setError('');
  };

  useEffect(() => {
    if (inspectionContext.state.selected_point) {
      inspectionContext.dispatch({
        type: 'TOGGLE_MODAL',
        data: 'create_event',
      });
    }
  }, [inspectionContext.state.selected_timepin]);

  // every change on "show_create_timepin_modal" a new date is added or deleted
  useEffect(() => {
    const show = inspectionContext.state.modals.create_event;
    const referenceDate = new Date();
    if (show) {
      const newDate = formatTimezoneDate({
        date: referenceDate,
        timezone: settingsContext.state.timezone?.id || '',
        format: 'YYYY/MM/DD',
      });

      setDate(newDate);
    } else {
      clear();
    }
    if (inspectionContext.state.selected_point && !show) {
      inspectionContext.dispatch({ type: 'SET_SELECTED_POINT', data: undefined });
    }
  }, [inspectionContext.state.modals.create_event]);

  useEffect(() => {
    if (timePosition?.element && timePosition?.position) {
      timePosition.element.selectionStart = timePosition.position;
      timePosition.element.selectionEnd = timePosition.position;
      setTimeposition(null);
    }
  }, [time]);

  if (!inspectionContext.state.modals.create_event) return null;

  return (
    <ModalWrapper>
      <Modal>
        <Header>
          <ConfirmationTitle>Events</ConfirmationTitle>
          <EventExplanation>
            Add a date and time between the launch and finish time (or ETA at
            receiver if it&apos;s an ongoing run). A pin will drop and show
            where the pig was/will be at that time.
          </EventExplanation>
        </Header>
        <Main>
          <CloseButtonContainer
            onClick={() =>
              inspectionContext.dispatch({
                type: 'TOGGLE_MODAL',
                data: 'create_event',
              })
            }
          >
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.94977"
                width="2"
                height="7"
                rx="1"
                transform="rotate(45 4.94977 0)"
                fill="#494C5D"
              />
              <rect
                x="6.36395"
                y="8.48438"
                width="2"
                height="7"
                rx="1"
                transform="rotate(135 6.36395 8.48438)"
                fill="#494C5D"
              />
              <rect
                x="0.449768"
                y="3.94922"
                width="14"
                height="2"
                rx="1"
                fill="#494C5D"
              />
            </svg>
            Back
          </CloseButtonContainer>
          <InputGroup
            label="Name"
            customStyle={`
              padding: 0;
              label {
                margin: 8px 0;
                padding-right: 8px;
                padding-bottom: 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }
            `}
          >
            <Input
              customStyle={`
                width: 200px;
                padding-left: 4px;
                padding-right: 4px;
                ${error?.field === 'name' ? 'border: 1px solid red;' : ''}
              `}
              value={name}
              placeholder={'Event Name'}
              testid="create-timepin-name-field"
              handleChange={(timepin) => setName(timepin.target.value)}
            />
          </InputGroup>
          <InputGroup
            label="Time"
            customStyle={`
              padding: 0;
              label {
                margin: 8px 0;
                padding-right: 8px;
                padding-bottom: 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }

              > input {
                width: 200px;
                padding: 4px 8px;
                border: 1px solid #e5e5e5;
                border-radius: 4px;

                &:active {
                  padding: 5px 10px;
                  border: 1px solid #5850ec;
                }
                
                &:focus {
                  padding: 5px 10px;
                  border: 1px solid #5850ec;
                }
              }
            `}
          >
            <InputMask
              maskChar=""
              mask="99:99:99.9999"
              id="create-timepin-time-field"
              placeholder="HH:mm:ss"
              value={time}
              onChange={(timepin) => {
                 const start = timepin.target.selectionStart;
                const formatedValue = VMasker.toPattern(
                  extractNumbers(timepin.target.value),
                  '99:99:99.9999'
                );

                if (
                  formatedValue.length < time.length &&
                  formatedValue.length !== 1
                ) {
                  setTimeposition({ element: timepin.target, position: start });
                }

                setTime(formatedValue);
              }}
            />
          </InputGroup>
          <InputGroup
            label="Date"
            customStyle={`
              padding: 0;
              label {
                margin: 8px 0;
                padding-right: 8px;
                padding-bottom: 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }
             `}
          >
            <InputDate
              placeholder="YYYY/MM/DD"
              customStyle={`
                width: 200px;
                padding-left: 4px;
                padding-right: 4px;
                ${error?.field === 'date' ? 'border: 1px solid red;' : ''}
              `}
              testid="create-timepin-date-field"
              handleChange={({ value }) => {
                const tempDate = new Date(value);
                setDate(`${tempDate.getFullYear()}/${tempDate.getMonth() + 1}/${tempDate.getDate()}`)
              }}
              value={date}
              id="SchedulerEditInitialDate"
            />
          </InputGroup>
          <ErrorLabel>{error?.error}</ErrorLabel>
          <ConfirmationFooter>
            <ButtonWrapper>
              <Button
                customStyle={buttonCustomStyle}
                disabled={isLoading || !name || !time}
                testid="create-timepin-submit-button"
                handleClick={() =>
                  saveEvent(
                    {
                      name,
                      time,
                      date,
                    },
                    inspectionContext,
                    settingsContext.state,
                    accountContext.state,
                    setIsLoading,
                    clear,
                    setError
                  )
                }
                value={!isLoading ? 'Create' : 'loading . . .'}
              />
              <LinkButton
                customStyle={`
                  border: 1px solid #DCDFE2;
                  box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
                `}
                handleClick={() => inspectionContext.dispatch({
                  type: 'TOGGLE_MODAL',
                  data: 'create_event',
                })}
              >
                Cancel
              </LinkButton>
            </ButtonWrapper>
          </ConfirmationFooter>
        </Main>
      </Modal>
    </ModalWrapper>
  );
};

CreateEventOverlay.defaultProps = {
  hide: true,
  title: 'What is Lorem Ipsum?',
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  confirmationText: 'Confirm',
  cancelationText: 'Cancel',
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff">
      <path d="M12 5.99L19.53 19H4.47L12 5.99M2.74 18c-.77 1.33.19 3 1.73 3h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18zM11 11v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zm0 5h2v2h-2z" />
    </svg>
  ),
};

export default CreateEventOverlay;
