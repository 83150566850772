import { formatTimezoneDate } from "@/lib/formatter";
import { Timezone } from "app/modules/settings/settings.context.d";
import moment from "moment-timezone";

export const resetZoom = (
  timezone: Timezone,
  x: [Date, Date],
  rangeY: [number, number],
  delta: number,
) => {
  if (x) {
    const timezoneId = timezone?.id;

    const initTime = x[0];
    const endTime = x[1];
    
    const tzInitDate = moment.tz(initTime, timezoneId || 'UTC');
    const tzEndDate = moment.tz(endTime, timezoneId || 'UTC');

    const initDate = tzInitDate.toDate();
    const endDate = tzEndDate.toDate();

    return { x: [initDate, endDate], y: [rangeY[0], rangeY[1] + delta] };
  }
};

export const round = (n) => {
  return Math.round(100 * n) / 100;
};

export const formatTime = (timezone: Timezone, dt: Date) => {
  return formatTimezoneDate({
    date: dt,
    timezone: timezone?.id || '',
    format: 'HH:mm:ss',
  });
};

export const formatDatum = ({ timezone, datum }) => {
  return `acx: ${formatTime(timezone, datum.x)} - acy: ${round(datum.y)}`;
};
