import styled from 'styled-components';

export const BatteryWrapper = styled.figure<{ width: number }>`
  position: relative;
  width: ${({ width }) => width}px;

  > svg {
    margin-top: 2px;
    width: 100%;
    transform: rotate(180deg);
  }

  > span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 1em;

    color: #161e2e;
  }
`;
