import { useContext, useEffect, useState } from 'react';
import { timezoneListModel } from '../../../../../models/timezones';
import InputGroup from '../../../../input-group/inputGroup';
import Button from '../../../../button/button';
import {
  distanceUnitOptions,
  speedUnitOptions,
  weatherUnitOptions,
} from './units-and-timezone.models';
import {
  Form,
  FormHeader,
  FormTitle,
  FormDescription,
  Fieldset,
  Select,
  ActionsWrapper,
  SlimButton,
} from './units-and-timezone.style';
import SettingsStore from 'app/modules/settings/settings.context';
import AccountStore from 'app/modules/account/account.context';

const renderOptions = (options: any, selected: string | undefined) =>
  options.map((option: any) => (
    <option key={option.id} selected={selected === option.id} value={option.id}>
      {option.label}
    </option>
  ));

const UnitsAndTimezone = () => {
  const settingsContext = useContext(SettingsStore);
  const accountContext = useContext(AccountStore);
  const [temperatureUnit, setTemperatureUnit] = useState<string | undefined>(
    undefined
  );
  const [distanceUnit, setDistanceUnit] = useState<string | undefined>(
    undefined
  );
  const [speedUnit, setSpeedUnit] = useState<string | undefined>(undefined);
  const [timezone, setTimezone] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (settingsContext.state.weatherUnit) setTemperatureUnit(settingsContext.state.weatherUnit.id);
    if (settingsContext.state.speedUnit) setSpeedUnit(settingsContext.state.speedUnit.id);
    if (settingsContext.state.distanceUnit) setDistanceUnit(settingsContext.state.distanceUnit.id);
    if (settingsContext.state.timezone) setTimezone(settingsContext.state.timezone.id);
  }, [settingsContext.state.speedUnit, settingsContext.state.distanceUnit, settingsContext.state.timezone, settingsContext.state.weatherUnit]);

  return (
    <Form>
      <div>
        <FormHeader>
          <FormTitle>Units and Timezone</FormTitle>
          <FormDescription>
            Choose your preferred units and timezones for GlobalTrack
          </FormDescription>
        </FormHeader>
        <Fieldset>
          <InputGroup label="Temperature">
            <Select
              onChange={(e) => {
                settingsContext.dispatch({
                  type: 'SET_WEATHER_UNIT',
                  data: weatherUnitOptions.find(
                    (temparature) => temparature.id === e.target.value
                  ),
                });
              }}
            >
              {renderOptions(weatherUnitOptions, temperatureUnit)}
            </Select>
          </InputGroup>
          <InputGroup label="Distance">
            <Select
              onChange={(e) => {
                settingsContext.dispatch({
                  type: 'SET_DISTANCE_UNIT',
                  data: distanceUnitOptions.find(
                    (distance) => distance.id === e.target.value
                  ),
                });
              }}
            >
              {renderOptions(distanceUnitOptions, distanceUnit)}
            </Select>
          </InputGroup>
          <InputGroup label="Speed">
            <Select
              onChange={(e) => {
                settingsContext.dispatch({
                  type: 'SET_SPEED_UNIT',
                  data: speedUnitOptions.find(
                    (speed) => speed.id === e.target.value
                  ),
                });
              }}
            >
              {renderOptions(speedUnitOptions, speedUnit)}
            </Select>
          </InputGroup>
        </Fieldset>
        <Fieldset>
          <InputGroup label="Timezone">
            <Select
              value={timezone}
              onChange={(e) => {
                settingsContext.dispatch({
                  type: 'SET_TIMEZONE_UNIT',
                  data: timezoneListModel
                    .map((t) => ({
                      label: t.name,
                      id: t.location,
                    }))
                    .find((timezone) => timezone.id === e.target.value),
                });
              }}
            >
              {renderOptions(
                timezoneListModel.map((t) => ({
                  label: t.name,
                  id: t.location,
                })),
                timezone
              )}
            </Select>
          </InputGroup>
        </Fieldset>
      </div>
      <ActionsWrapper>
        <Button
          handleClick={() => {
            accountContext.dispatch({
              type: 'CLOSE_PROFILE_MODAL',
            });
          }}
          customStyle={`
              width: auto;
              padding: 12px 16px;

              &:focus {
                padding: 12px 16px;
              }
            `}
          value="Update"
        />
        <SlimButton
          type="button"
          onClick={() => {
            accountContext.dispatch({
              type: 'CLOSE_PROFILE_MODAL',
            });
          }}
        >
          Cancel
        </SlimButton>
      </ActionsWrapper>
    </Form>
  );
};

export default UnitsAndTimezone;
