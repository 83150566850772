import {
  MobileQubesModalWrapper,
  MobileQubesModalList,
  MobileQubesModalGoBackButton,
} from './mobile-qubes-modal.style';
import QubeItem from 'app/components/qube-item/qube-item';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import { useContext, useMemo } from 'react';

/**
 * 
 * @param qubes 
 * @returns 
 */
const renderQubes = (qubes: QubeDeviceType[], onClick: (qube: QubeDeviceType) => void) => {
  return qubes.map((qube) => {
    return (
      <QubeItem qube={qube} key={qube.serial} onClick={() => onClick(qube)} />
    );
  });
};

/**
 * 
 * @returns 
 */
const MobileQubesModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const qubes = useMemo(() => {
    const qubes = inspectionContext.run?.project
      ?.device_set
      ?.filter(({ qubedevice }) => qubedevice?._loaded)
      ?.map(({ qubedevice  }) => qubedevice);

    return qubes as QubeDeviceType[] || [] as QubeDeviceType[];
  }, [inspectionContext.run?.project?.device_set]);
  
  const handleClick = (qube: QubeDeviceType) => {
    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_QUBE',
      data: qube,
    });
  };

  const handleClose = () => {
    inspectionContext.dispatch({
      type: 'MOBILE_QUBES_MODAL',
      data: false,
    });
  };
  
  return (
    <MobileQubesModalWrapper>
      <header>
        <MobileQubesModalGoBackButton onClick={handleClose}>
          <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 9.67969L9 18.6797L10.4 17.1797L3 9.67969L10.4 2.17969L9 0.679688L0 9.67969Z" fill="#323030"/>
          </svg>
        </MobileQubesModalGoBackButton>
        <svg width="22" height="22" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.2 12.1C15.2 12.404 15.032 12.668 14.776 12.804L8.45601 16.356C8.32801 16.452 8.16801 16.5 8.00001 16.5C7.83201 16.5 7.67201 16.452 7.54401 16.356L1.22401 12.804C1.09575 12.7366 0.988416 12.6353 0.913665 12.5112C0.838913 12.3871 0.799604 12.2449 0.800006 12.1V4.9C0.800006 4.596 0.968006 4.332 1.22401 4.196L7.54401 0.644C7.67201 0.548 7.83201 0.5 8.00001 0.5C8.16801 0.5 8.32801 0.548 8.45601 0.644L14.776 4.196C15.032 4.332 15.2 4.596 15.2 4.9V12.1ZM8.00001 2.22L3.23201 4.9L8.00001 7.58L12.768 4.9L8.00001 2.22ZM2.40001 11.628L7.20001 14.332V8.964L2.40001 6.268V11.628ZM13.6 11.628V6.268L8.80001 8.964V14.332L13.6 11.628Z" fill="#D30000"/>
        </svg>
        <p>
          You have <strong>&nbsp;{qubes.length} devices&nbsp;</strong> in this page
        </p>
      </header>
      <MobileQubesModalList>{renderQubes(qubes, handleClick)}</MobileQubesModalList>
    </MobileQubesModalWrapper>
  );
};

export default MobileQubesModal;
 