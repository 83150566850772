import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;

  padding: 12px 16px;
  background-color: #ffffff;

  border: 1px solid #e7ebee;
  box-shadow: 0px 4px 10px rgba(37, 43, 64, 0.05);
  border-radius: 6px;

  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

export const Username = styled.label`
  display: flex;
  padding: 5px 0;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1em;

  color: #161e2e;
`;

export const Phone = styled.label`
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 13px;
  text-align: left;
  padding: 2px 0;

  svg {
    width: 13px;
    margin-right: 5px;
    margin-top: 2px;
  }
`;
export const Email = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 13px;
  text-align: left;
  padding: 2px 0;

  svg {
    width: 13px;
    margin-right: 5px;
    margin-top: 2px;
  }
`;

export const Figure = styled.figure`
  display: flex;
  align-items: center;

  width: 60px;
`;

export const InfoWrapper = styled.div``;
