import PropTypes from "prop-types";
import React, { JSXElementConstructor } from "react";
import { defaults, isFunction, isObject } from "lodash";
import {
  VictoryContainer,
  VictoryClipContainer,
  Data,
  Helpers,
} from "victory-core";

import ZoomHelpers from "./zoom-helpers.js";
import { CursorHelpers } from "./cursor-helpers";

const DEFAULT_DOWNSAMPLE = 150;

export const zoomContainerMixin = (base) =>
  class VictoryZoomContainer extends base {
    static displayName = "CustomContainer";

    static propTypes = {
      allowPan: PropTypes.bool,
      allowZoom: PropTypes.bool,
      clipContainerComponent: PropTypes.element.isRequired,
      disable: PropTypes.bool,
      downsample: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      minimumZoom: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
      onZoomDomainChange: PropTypes.func,
      zoomDimension: PropTypes.oneOf(["x", "y"]),
      zoomDomain: PropTypes.shape({
        x: undefined,
        y: undefined,
      }),
      onClick: PropTypes.func,
    };

    static defaultProps = {
      ...VictoryContainer.defaultProps,
      clipContainerComponent: <VictoryClipContainer />,
      allowPan: true,
      allowZoom: true,
      zoomActive: false,
      onClick: () => {},
    };

    newElements = [];

    static defaultEvents = (props) => {
      return [
        {
          target: "parent",
          eventHandlers: {
            onMouseDown: (evt, targetProps) => {
              return props.disable
                ? {}
                : ZoomHelpers.onMouseDown(evt, targetProps);
            },
            onTouchStart: (evt, targetProps) => {
              return props.disable
                ? {}
                : ZoomHelpers.onMouseDown(evt, targetProps);
            },
            onMouseUp: (evt, targetProps) => {
              if (props.disable) {
                return {};
              }
              const r = ZoomHelpers.onMouseUp(evt, targetProps)[0];
              const { click } = r.mutation();
              props.onClick(click);
              return r;
            },
            onTouchEnd: (evt, targetProps) => {
              return props.disable
                ? {}
                : ZoomHelpers.onMouseUp(evt, targetProps);
            },
            onMouseLeave: (evt, targetProps) => {
              return props.disable
                ? {}
                : ZoomHelpers.onMouseLeave(evt, targetProps);
            },
            onTouchCancel: (evt, targetProps) => {
              return props.disable
                ? {}
                : ZoomHelpers.onMouseLeave(evt, targetProps);
            },
            // eslint-disable-next-line max-params
            onMouseMove: (evt, targetProps, eventKey, ctx) => {
              if (props.disable) {
                return {};
              }
              ZoomHelpers.onMouseMove(evt, targetProps, eventKey, ctx);
              props.onMouseMove(targetProps.cursorValue);
              return CursorHelpers.onMouseMove(evt, targetProps);
            },
            // eslint-disable-next-line max-params
            onTouchMove: (evt, targetProps, eventKey, ctx) => {
              if (props.disable) {
                return {};
              }
              evt.preventDefault();
              return ZoomHelpers.onMouseMove(evt, targetProps, eventKey, ctx);
            },
            ...(props.disable || !props.allowZoom
              ? {}
              : { onWheel: ZoomHelpers.onWheel }),
          },
        },
      ];
    };

    clipDataComponents(children, props) {
      const { scale, clipContainerComponent, polar, origin, horizontal } =
        props;
      const rangeX = horizontal ? scale.y.range() : scale.x.range();
      const rangeY = horizontal ? scale.x.range() : scale.y.range();
      const plottableWidth = Math.abs(rangeX[0] - rangeX[1]);
      const plottableHeight = Math.abs(rangeY[0] - rangeY[1]);
      const radius = Math.max(...rangeY);
      const groupComponent = React.cloneElement(clipContainerComponent, {
        clipWidth: plottableWidth,
        clipHeight: plottableHeight,
        translateX: Math.min(...rangeX),
        translateY: Math.min(...rangeY),
        polar,
        origin: polar ? origin : undefined,
        radius: polar ? radius : undefined,
        ...clipContainerComponent.props,
      });
      return React.Children.toArray(children).map((child: React.ReactNode) => {
        if (!Data.isDataComponent(child)) {
          return child;
        }
        return React.cloneElement(child as React.ReactElement<HTMLElement>, { groupComponent } as React.Attributes);
      });
    }

    modifyPolarDomain(domain, originalDomain) {
      // Only zoom the radius of polar charts. Zooming angles is very confusing
      return {
        x: originalDomain.x,
        y: [0, domain.y[1]],
      };
    }

    downsampleZoomData(props, child, domain) {
      const { downsample } = props;

      const getData = (childProps) => {
        const { data, x, y } = childProps;
        const defaultGetData =
          child.type && isFunction(child.type.getData)
            ? child.type.getData
            : () => undefined;
        // skip costly data formatting if x and y accessors are not present
        return Array.isArray(data) && !x && !y
          ? data
          : defaultGetData(childProps);
      };

      const data = getData(child.props);

      // return undefined if downsample is not run, then default() will replace with child.props.data
      if (!downsample || !domain || !data) {
        return undefined;
      }

      const maxPoints = downsample === true ? DEFAULT_DOWNSAMPLE : downsample;
      const dimension = props.zoomDimension || "x";

      // important: assumes data is ordered by dimension
      // get the start and end of the data that is in the current visible domain
      let startIndex = data.findIndex(
        (d) => d[dimension] >= domain[dimension][0],
      );
      let endIndex = data.findIndex((d) => d[dimension] > domain[dimension][1]);
      // pick one more point (if available) at each end so that VictoryLine, VictoryArea connect
      if (startIndex !== 0) {
        startIndex -= 1;
      }
      if (endIndex !== -1) {
        endIndex += 1;
      }

      const visibleData = data.slice(startIndex, endIndex);

      return Data.downsample(visibleData, maxPoints, startIndex);
    }

    modifyChildren(props) {
      const childComponents = React.Children.toArray(props.children) as any;
      // eslint-disable-next-line max-statements
      return childComponents.map((child) => {
        if (typeof child === "string" || typeof child === "number") {
          return null;
        }
        const role = child.type && child.type.role;
        const isDataComponent = Data.isDataComponent(child);
        const { currentDomain, zoomActive, allowZoom } = props;
        const originalDomain = defaults({}, props.originalDomain, props.domain);
        const zoomDomain = defaults({}, props.zoomDomain, props.domain);
        const cachedZoomDomain = defaults(
          {},
          props.cachedZoomDomain,
          props.domain,
        );
        let domain;
        if (!ZoomHelpers.checkDomainEquality(zoomDomain, cachedZoomDomain)) {
          // if zoomDomain has been changed, use it
          domain = zoomDomain;
        } else if (allowZoom && !zoomActive) {
          // if user has zoomed all the way out, use the child domain
          domain = child.props.domain;
        } else {
          // default: use currentDomain, set by the event handlers
          domain = defaults({}, currentDomain, originalDomain);
        }

        let newDomain = props.polar
          ? this.modifyPolarDomain(domain, originalDomain)
          : domain;
        if (newDomain && props.zoomDimension) {
          // if zooming is restricted to a dimension, don't squash changes to zoomDomain in other dim
          newDomain = {
            ...zoomDomain,
            [props.zoomDimension]: newDomain[props.zoomDimension],
          };
        }
        // don't downsample stacked data
        const newProps =
          isDataComponent && role !== "stack"
            ? {
                domain: newDomain,
                data: this.downsampleZoomData(props, child, newDomain),
              }
            : { domain: newDomain };
        return React.cloneElement(child, defaults(newProps, child.props));
      });
    }

    getCursorPosition(props) {
      const { cursorValue, defaultCursorValue, domain, cursorDimension } =
        props;
      if (cursorValue) {
        return cursorValue;
      }

      if (typeof defaultCursorValue === "number") {
        return {
          x: (domain.x[0] + domain.x[1]) / 2,
          y: (domain.y[0] + domain.y[1]) / 2,
          [cursorDimension]: defaultCursorValue,
        };
      }

      return defaultCursorValue;
    }

    getPadding(props) {
      if (props.padding === undefined) {
        const child = props.children.find((c) => {
          return isObject(c.props) && c.props.padding !== undefined;
        });
        return Helpers.getPadding(child.props);
      }
      return Helpers.getPadding(props);
    }

    getCursorElements(props) {
      // eslint-disable-line max-statements
      const {
        scale,
        horizontal,
      } = props;
      const cursorValue = this.getCursorPosition(props);

      if (!cursorValue) {
        return [];
      }

      const newElements: React.ReactElement[] = [];
      let cursorCoordinates = {
        x: horizontal ? scale.y(cursorValue.y) : scale.x(cursorValue.x),
        y: horizontal ? scale.x(cursorValue.x) : scale.y(cursorValue.y),
      };

      // Hack - this is to prevent a bug
      let snapshot = [ cursorValue,
                       cursorCoordinates ];
      let lastSnapshot: JSON | string = window.localStorage.getItem('cursorSnapshot') as string;
      if (lastSnapshot) lastSnapshot = JSON.parse(lastSnapshot) as JSON;
      snapshot = JSON.parse(JSON.stringify(snapshot));
      if (!(typeof lastSnapshot === 'string') &&
          lastSnapshot &&
          lastSnapshot[0].x == snapshot[0].x &&
          lastSnapshot[0].y == snapshot[0].y) {
        cursorCoordinates = lastSnapshot[1];
      } else {
        window.localStorage.setItem('cursorSnapshot',
                                    JSON.stringify(snapshot));
      }
      return newElements;
    }


    // Overrides method in VictoryContainer
    getChildren(props) {
      const children = this.modifyChildren(props);
      const zoomElements = this.clipDataComponents(children, props);
      const newElements = props.panning ? [] : this.getCursorElements(props);

      return [
        ...zoomElements,
        ...newElements,
      ];
    }

  };

export const CustomContainer = zoomContainerMixin(VictoryContainer) as unknown as JSXElementConstructor<any>;

