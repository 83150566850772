import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;

  background: #e7ebee;
`;

export const Bar = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}%;
  height: 100%;

  background-color: #31d988;
`;
