/* eslint-disable @typescript-eslint/ban-ts-comment */
interface Point {
  lat: number;
  lng: number;
}
export interface GetBoundsBoxResponse {
  east: number;
  north: number;
  south: number;
  west: number;
}

export const getBoundsBox = (points: Point[]): GetBoundsBoxResponse => {
  let gtLat = 0;
  let ltLat = 0;
  let gtLng = 0;
  let ltLng = 0;

  const array: Point[] = points.filter((o) => o.lat);

  array.forEach((e: Point) => {
    if (e.lat > gtLat || !gtLat) gtLat = e.lat;
    if (e.lat < ltLat || !ltLat) ltLat = e.lat;
    if (e.lng > gtLng || !gtLng) gtLng = e.lng;
    if (e.lng < ltLng || !ltLng) ltLng = e.lng;
  });

  return {
    east: gtLng,
    north: gtLat,
    south: ltLat,
    west: ltLng,
  };
};

export const getBoundingBoxCenter = (coords: GetBoundsBoxResponse): google.maps.LatLng =>  {
  const { east, west, north, south } = coords;
  
  const lat = (north + south) / 2;
  const lng = (east + west) / 2;

  return new google.maps.LatLng(lat, lng);
}

const getIconType = (point: any, type?: string): string => {
  const types: any = {
    blue: 'default',
    green: 'green',
    yellow: 'yellow',
    red: 'gray',
  };

  if (type === 'animated') {
    return `${type}-${types[point.color] || 'default'}`;
  }

  return types[point.color] || 'default';
};

export const getTrackingPointIconSrc = (point: any, type?: string): any => {  
  // @ts-ignore
  const legends: any = {
    default: 'Waiting',
    blue: 'Waiting',
    green: 'Recorded',
    yellow: 'Overdue',
    grey: 'Skipped/Missed',
    gray: 'Skipped/Missed',
  };


  const domain = 'https://cdi-gt-medias.s3.us-east-2.amazonaws.com';
  const noBorder = type === 'no-border';
  const noBorderPath = noBorder ? 'no-border/' : '';
  const icon: any = {
    url: `${domain}/${noBorderPath}${getIconType(point, type)}/agm.svg`,
    legend: legends[`${getIconType(point)}`],
  };


  if (point?.type) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/${
      point.type
    }.svg`;

    const deviceTriggerIcons = {
      default: '/assets/icons/qube/blue/geolock.svg',
      blue: '/assets/icons/qube/blue/ad-hoc-point.svg',
      gray: '/assets/icons/qube/gray/ad-hoc-point.svg',
      red: '/assets/icons/qube/gray/ad-hoc-point.svg',
      yellow: '/assets/icons/qube/yellow/ad-hoc-point.svg',
      green: '/assets/icons/qube/green/ad-hoc-point.svg',
    };

    if (point.is_device_trigger && point?.type === 'ad-hoc-point' ) {
      icon.url = deviceTriggerIcons[point.color];
      return icon;
    }

    return icon;
  }

  const launcherRegex = /launch/gi;
  const receiverRegex = /receiver/gi;
  const mlvRegex = /mlv/gi;

  if (launcherRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/launcher.svg`;
  }
  if (receiverRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/receiver.svg`;
  }

  if (mlvRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/valve.svg`;
  }

  return icon;
};

interface CustomIcon extends google.maps.Icon {
  legend?: string;
}

export const getTrackingpointIcon = (
  point: any,
  type?: string,
  zoom?: number
): CustomIcon | undefined => {
  if (!point || !window.google) return;
  const legends: any = {
    default: 'Waiting',
    blue: 'Waiting',
    green: 'Recorded',
    yellow: 'Overdue',
    grey: 'Skipped/Missed',
    gray: 'Skipped/Missed',
  };

  let scaledSize = new window.google.maps.Size(18, 18);
  let anchor = new window.google.maps.Point(9, 9);

  if (zoom && zoom > 11) {
    scaledSize = new window.google.maps.Size(36, 36);
    anchor = new window.google.maps.Point(18, 18);
  }

  if (zoom && zoom <= 11 && zoom > 9) {
    scaledSize = new window.google.maps.Size(24, 24);
    anchor = new window.google.maps.Point(12, 12);
  }



  const domain = 'https://cdi-gt-medias.s3.us-east-2.amazonaws.com';
  const noBorder = type === 'no-border';
  const noBorderPath = noBorder ? 'no-border/' : '';
  const icon: any = {
    url: `${domain}/${noBorderPath}${getIconType(point, type)}/agm.svg`,
    // @ts-ignore
    scaledSize, // scaled size
    // @ts-ignore
    origin: new window.google.maps.Point(0, 0), // origin
    // @ts-ignore
    anchor, // anchor
    legend: legends[`${getIconType(point)}`],
  };


  const deviceTriggerIcons = {
    default: '/assets/icons/qube/blue-geolock.svg',
    blue: '/assets/icons/qube/blue/ad-hoc-point.svg',
    gray: '/assets/icons/qube/gray/ad-hoc-point.svg',
    red: '/assets/icons/qube/gray/ad-hoc-point.svg',
    yellow: '/assets/icons/qube/yellow/ad-hoc-point.svg',
    green: '/assets/icons/qube/green/ad-hoc-point.svg',
  };


  if (point.is_device_trigger && point?.type === 'ad-hoc-point' ) {
    icon.url = deviceTriggerIcons[point.color];
    return icon;
  }

  if (point?.type) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/${
      point.type
    }.svg`;

    return icon;
  }

  const launcherRegex = /launch/gi;
  const receiverRegex = /receiver/gi;
  const mlvRegex = /mlv/gi;

  if (launcherRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/launcher.svg`;
  }
  if (receiverRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/receiver.svg`;
  }

  if (mlvRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/valve.svg`;
  }


  return icon;
};

export const objectDeepClone = (data: any) =>
  JSON.parse(JSON.stringify(data || {}));

export const getMarkerPosition = (coordinates: [number, number]) => {
  return {
    lng: coordinates[0],
    lat: coordinates[1],
  };
};
