import styled from 'styled-components';

export const Wrapper = styled.div<{ displayNone?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;

  z-index: 22;
  background-color: rgba(0, 0, 0, 0.5);

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ displayNone }) => (displayNone ? 'display: none;' : '')}
`;

export const Modal = styled.div`
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px;
  max-height: 400px;
  overflow: auto;
`;

export const Run = styled.div`
  background-color: #fff;
  padding: 15px 10px;
  margin: 2px 0;
  cursor: pointer;
  * {
    cursor: pointer;
  }
`;

export const RunTitle = styled.label`
  display: block;
  font-size: 16px;
`;

export const InfoGroup = styled.label`
  display: block;

  font-size: 9px;
  color: #686c7b;

  > strong {
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    font-size: 10px;
  }
`;

export const Details = styled.div``;
export const Footer = styled.div`
  font-size: 9px;
  color: #686c7b;
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
`;
