import styled from 'styled-components';


export const QubeModalCopyButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const QubeModalQubeInformations = styled.div`
  padding: 16px;
  position: relative;

  display: grid;
  gap: 16px;
`; 

export const QubeInfoList = styled.dl`
  display: flex;
  flex-direction: column;

  gap: 12px;
  margin: 0;
`;

export const QubeInfo = styled.div``;

export const QubeInfoLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(105, 115, 134, 0.75);

  margin-bottom: 6px;
`;

export const QubeInfoValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;

  margin: 0;
`;

export const QubePropertiesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 8px;
`;

export const QubeProperty = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const QubePropertyStatus = styled.span<{ $checked: boolean }>`
  width: 16px;
  height: 16px;

  border-radius: 50%;
  border: solid 1px ${({ $checked }) => ($checked ? '#308c04' : '#4d5158')}; 
  background-color: ${({ $checked }) => ($checked ? '#56f000' : '#a4abb6')};
`;

export const QubePropertyLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'Inter';
  font-style: normal;
  line-height: 16px;

  color: #1a1f36;

  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
`;

export const QubeModalHeader = styled.header`
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  border-bottom: solid 1px #ececec;
`;

export const QubeModalStatus = styled.div<{ $armed: boolean }>`
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-top: solid 1px #ececec;
  padding-top: 16px;

  > label {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
  }

  > button {
    position: relative;
    transition-duration: 0.3s;
    cursor: pointer;


    display: flex;
    align-items: center;
  
    width: 92px;
    height: 24px;
  
    border-radius: 37px;
    background-color: ${({ $armed }) => !$armed ? '#a4abb6' : '#d30000'};
    color: #a4abb6;
    border: none;
    padding: none;
  
    > span.switch {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-shadow: 0 4px 8px 0 #09080833;
  
      background-color: #ffffff;
      z-index: 1;

      will-change: transform;
      transform: ${({ $armed }) => $armed ? 'translateX(68px)' : 'translateX(0)'};
    }
    
    > span.status {
      display: flex;
      justify-content: ${({ $armed }) => $armed ? 'flex-start' : 'flex-end'};
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 0;
      color: #ffffff;
      font-size: 12px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }
`;

export const QubeModalSerial = styled.h2`
  ${({ theme }) => theme.fonts.typograph.headline2};
  font-size: 18px;
  font-weight: 700;
  color: #090808;
`;

export const QubeModalBattery = styled.div`
  background-color: #090808;
  padding: 4px 8px;

  border-radius: 42px;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-family: 'Inter';
    color: #ffffff;
    font-size: 11.7px;
    font-weight: 700;
  }
`;

export const QubeModalGoBackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const QubeInfoTable = styled.table`
  > tr {
    height: 32px;
  }

  > tr > td:nth-child(1) {
    font-family: 'Inter';
    font-weight: 400;
    color: #595959;
  }

  > tr > td:nth-child(2) {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #090808;
  }
`;

export const QubeModalCopied = styled.span`
  background-color: #090808;
  color: ${({ theme }) => theme.colors.surface_white};
  border-radius: 4px;
  padding: 2px 4px;

  font-size: 12px;
`;

export const QubeCardContainer = styled.div`
  display: grid;
  grid-template-rows: 44px auto 1fr;
  align-content: start;
`;

