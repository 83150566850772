import styled from 'styled-components';

export const DeviceModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 30;

  @media (min-width: 1024px) {
    padding: 40px;
  }
`;

export const DeviceActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 32px; 
`;

export const DeviceContent = styled.div`
  padding: 32px;
`;

export const DeviceModalDescription = styled.p`
  color: #656978;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 21px 8px;
`;

export const DeviceModal = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;

  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  background-color: #ffffff;
  
  @media (min-width: 1024px) {
    max-width: 580px;
    background: #ffffff;
  }
`;

export const DeviceHeader = styled.header`
  position: relative;
  padding: 32px;
`;

export const DeviceCloseButton = styled.button`
  position: absolute;
  top: 14px;
  right: 15px;

  z-index: 31;

  background-color: transparent;
  cursor: pointer;
`;

export const DeviceModalTitle = styled.h2`
  color: #1A1F36;
  text-align: center;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeviceTable = styled.table`
  width: 100%;
  margin-top: 16px;

  > tr {
    border-bottom: 1px solid #dfe0e4;
    border-left: 1px solid #dfe0e4;
    border-right: 1px solid #dfe0e4;
  }

  > tr:first-child {
    border: 1px solid #dfe0e4;
  }
  
  > tr > th {
    width: 33%;
    height: 38px;

    color: rgba(26, 31, 54, 0.65);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  > tr > td {
    height: 38px;
    background-color: #fafafb;

    color: #161e2e;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

`;
