import { PipelineType } from "app/interfaces/inspection.interfaces";

/**
 * renderDriveline
 * @returns
 */
export const renderDriveline = ({
  driveline,
  map,
  drivelinePoly,
  setDrivelinePoly
}: {
  map?: google.maps.Map;
  driveline?: PipelineType['driveline'];
  drivelinePoly?: google.maps.Polyline[];
  setDrivelinePoly: (data: google.maps.Polyline[]) => void;
}) => {
  if (!driveline?.coordinates || !map) return;
  drivelinePoly?.forEach((polyline) => {
    polyline.setMap(null);
  });

  const polylines: google.maps.Polyline[] = [];

  driveline.coordinates.forEach((path: any) => {
    const points = path.map((p: number[]) => ({ lng: p[0], lat: p[1] }));
    const polyline = new google.maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: '#4e4ee9',
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 1,
      map,
    });
    polyline.setMap(map);
    polylines.push(polyline);
  });


  setDrivelinePoly(polylines);
};
