import styled from 'styled-components';

export const Avatar = styled.img`
  width: 36px;
  height: 36px;

  object-fit: cover;
  object-position: center;

  border-radius: 50%;
`;

export const ButtonWrapper = styled.button`
  position: relative;

  background-color: transparent;
  cursor: pointer;

  height: 36px;

  > svg {
    width: 36px;
    height: 36px;
  }
`;

export const Dropdown = styled.div<{ opened: boolean }>`
  display: ${({ opened }) => (opened ? 'block' : 'none')};

  position: absolute;
  bottom: 100%;
  margin-bottom: 16px;

  width: 258px;
  border-radius: 6px;
  box-shadow: 0px 6px 8px rgba(51, 57, 78, 0.2);
  overflow: hidden;

  text-align: left;
`;

export const DropdownHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 2px;

  padding: 16px;
  background-color: #fafafb;
`;

export const DropdownActions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 16px;
  background-color: #ffffff;
`;

export const DropdownAction = styled.li`
  display: flex;
  align-items: center;
  gap: 14px;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

export const ProfileName = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #161e2e;
`;

export const ProfileEmail = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #697386;
`;

export const ProfileRole = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #697386;
`;
