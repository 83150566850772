import styled from 'styled-components';

export const CountryCodeInput = styled.div`
  position: relative;

  padding: 5px 10px;
  background-color: #f1f1f1;
  border-right: 1px solid #e5e5e5;
`;

export const CountryCodeDropdown = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  height: -webkit-fill-available;
  
  display: ${({ open }) => (open ? 'grid' : 'none')};
  background-color: #ffffff;
  padding: 16px;

  grid-template-rows: auto 1fr auto;
  gap: 16px;

  @media (min-width: 1024px) {
    position: absolute;
    top: 100%;

    width: 250px;
    height: 250px;

    border: 1px solid #e5e5e5;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.02);
  }
`;

export const CountryCodeList = styled.ul`
  overflow: auto;

  > li {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 4px;
    padding-top: 4px; 
    padding-bottom: 4px;

    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2em;
    color: #686c7b;
    cursor: pointer;
    
    &:hover {
      background-color: #f1f1f1;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      display: inline-block;
      width: 20px;
      margin-right: 8px;
    }
  }
`;

export const CountryCodeSearchInput = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 3px;

  width: 100%;
  padding: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.2em;
  color: #686c7b;

  &:active {
    border: 1px solid #5850ec;
    padding: 5px;
  }

  &:focus-within {
    border: 1px solid #5850ec;
    padding: 5px;
  }
`;

export const SelectedCountry = styled.span`
  letter-spacing: -2px; 

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2em;
  color: #686c7b;
  margin-bottom: 20px;

  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;
`;

export const Input = styled.input`
  padding: 5px;
`;
