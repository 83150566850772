import { useEffect, useState } from 'react';
import DefaultButton from '../default-button/default-button';
import InputGroup from '../input-group/inputGroup';
import Toltip from '../toltip/toltip';
import { generateLinks } from './shareable-link.controller';
import {
  CopyButton,
  CopyWrapper,
  Input,
  ShareableLinkWrapper,
} from './shareable-link.style';
import { GeneratedType } from './shareable-link.d';
import RoleSelector from '../invitation-modal/components/role-selector/role-selector';
import { RoleType } from '../invitation-modal/invitation-modal.d';

interface Props {
  project: string;
  authToken: string | undefined;
  links: GeneratedType;
}

const roles = [
  { id: 'admin', editor: true, admin: true, label: 'Project Manager' },
  { id: 'editor', editor: true, admin: false, label: 'Editor' },
  { id: 'observer', editor: false, admin: false, label: 'Observer' },
];

const ShareableLink = ({ authToken, project, links }: Props) => {
  const [generated, setGenerated] = useState<GeneratedType>(links);
  const [role, setRole] = useState<RoleType>(roles[1]);
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 4000);
  }, [copied]);

  useEffect(() => {
    setGenerated(links);
  }, [links]);

  useEffect(() => {
    setCopied(false);
  }, [role]);

  const selectedTab = role?.id as 'editor' | 'observer' | 'admin';

  return (
    <ShareableLinkWrapper>
      <InputGroup
        label="Shareable links"
        description="* This link will expire in 24 hours."
      >
        <CopyWrapper>
          <Input
            autoComplete="off"
            id="shareable-link"
            placeholder="There is no valid link"
            onClick={(event) => {
              const target = event.target as HTMLInputElement;
              target.select();
              target?.setSelectionRange(0, 99999);
            }}
            value={generated[selectedTab] || ''}
          />

          <RoleSelector
            selected={role}
            onSelect={(role: RoleType | null) => {
              if (role) setRole(role);
            }}
          />

          {generated[selectedTab] ? (
            <Toltip
              active={copied}
              text={copied ? 'Link copied!' : 'Copy link!'}
            >
              <CopyButton
                copied={copied}
                onClick={() => {
                  const target = document.querySelector(
                    '#shareable-link'
                  ) as HTMLInputElement;
                  target?.select();
                  target?.setSelectionRange(0, 99999);

                  navigator.clipboard.writeText(target.value);

                  setCopied(true);
                }}
              >
                {copied ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="18px"
                    viewBox="0 0 24 24"
                    width="18px"
                    fill="#4b5066"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="18px"
                    viewBox="0 0 24 24"
                    width="18px"
                    fill="#4b5066"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                  </svg>
                )}
              </CopyButton>
            </Toltip>
          ) : (
            <Toltip active={false} text="Generate">
              <DefaultButton
                customStyle={`
                  width: 48px;
                  height: 38px;
                  border-radius: 0px 3px 3px 0px;
                  overflow: hidden;
                `}
                type="button"
                onClick={() => {
                  if (authToken) {
                    generateLinks(
                      authToken,
                      project,
                      setGenerated,
                      generated,
                      role?.id as 'editor' | 'admin' | 'observer'
                    );
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enableBackground="new 0 0 24 24"
                  height="18px"
                  viewBox="0 0 24 24"
                  width="18px"
                  fill="#ffffff"
                >
                  <g>
                    <rect fill="none" height="24" width="24" />
                  </g>
                  <g>
                    <path d="M8,11h8v2H8V11z M20.1,12H22c0-2.76-2.24-5-5-5h-4v1.9h4C18.71,8.9,20.1,10.29,20.1,12z M3.9,12c0-1.71,1.39-3.1,3.1-3.1h4 V7H7c-2.76,0-5,2.24-5,5s2.24,5,5,5h4v-1.9H7C5.29,15.1,3.9,13.71,3.9,12z M19,12h-2v3h-3v2h3v3h2v-3h3v-2h-3V12z" />
                  </g>
                </svg>
              </DefaultButton>
            </Toltip>
          )}
        </CopyWrapper>
      </InputGroup>
    </ShareableLinkWrapper>
  );
};

export default ShareableLink;
