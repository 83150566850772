import styled from 'styled-components';

export const DeviceCarouselWrapper = styled.div<{ hide: boolean }>`
  position: fixed;

  display: ${({ hide }) => (hide ? 'none' : 'grid')};
  grid-template-columns: 1fr;

  justify-content: center;
  justify-items: center;
  align-items: center;

  gap: 32px;

  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  background-color: rgba(35, 42, 51, 0.65);

  z-index: 22;

  @media (min-width: 1024px) {
    grid-template-columns: auto auto auto;
    padding: 32px;
  }
`;

export const DeviceCarouselList = styled.ul<{ count: number }>`
  width: 100%;
  max-width: 520px;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 100%);
  gap: 16px;

  overflow: auto;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 1024px) {
    grid-template-columns: repeat(${({ count }) => count}, 520px);
    height: auto;
  }
`;

export const DeviceCarouselModal = styled.li`
  scroll-snap-align: center;

  min-height: 90vh;
  background-color: #ffffff;

  @media (min-width: 1024px) {
    border-radius: 6px;
  }
`;

export const DeviceCarouselPrevButton = styled.button<{ hide: boolean }>`
  display: none;
  justify-content: center;
  align-items: center;

  width: 103px;
  height: 98px;

  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 12px;

  cursor: pointer;

  @media (min-width: 1024px) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
  }
`;

export const DeviceCarouselNextButton = styled.button<{ hide: boolean }>`
  display: none;
  justify-content: center;
  align-items: center;

  width: 103px;
  height: 98px;

  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 12px;

  cursor: pointer;

  > svg {
    transform: rotate(180deg);
  }

  @media (min-width: 1024px) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
  }
`;

export const DeviceCarouselModalHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  align-items: center;

  padding: 16px;

  position: relative;
`;

export const DeviceTitleWrapper = styled.div``;

export const DeviceTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #1a1f36;
  margin-top: -15px;
`;

export const DeviceStatus = styled.span<{ color: string }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: ${({ color }) => color};
`;

export const DeviceStatusFigure = styled.img`
  width: 44px;
  height: 44px;

  background-color: transparent;
`;

export const DeviceCarouselModalContent = styled.div``;
export const DeviceCarouselModalAbout = styled.div`
  background-color: #f7f8f9;
  padding: 20px 30px;
`;

export const DeviceCarouselModalAboutContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: 32px;
  margin-top: 16px;
`;

export const DeviceCarouselModalRecentLocation = styled.div`
  margin-top: 2px;
  background-color: #f7f8f9;
`;
export const DeviceContentSectionTitle = styled.h4`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #161e2e;
`;

export const DeviceInfoList = styled.dl`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;

  margin: 0;
`;

export const DeviceInfo = styled.div``;
export const DeviceInfoLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(105, 115, 134, 0.75);

  margin-bottom: 2px;
`;
export const DeviceInfoValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;

  margin: 0;
`;

export const DeviceCarouselModalRecentLocationHeader = styled.header`
  padding: 20px 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    ${({ theme }) => theme.fonts.typograph.overline}
  }
`;

export const DeviceMap = styled.div`
  width: 100%;
  height: 176px;

  background-color: #d4d4d4;
`;

export const DeviceCoords = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #5850ec;
  text-decoration: none;
`;

export const DeviceCloseButton = styled.button`
  position: absolute;

  right: 12px;
  top: 12px;

  cursor: pointer;
  background-color: transparent;
  border: none;
`;


export const DevicePropertiesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 8px;
`;
export const DeviceProperty = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DevicePropertyStatus = styled.span<{ checked: boolean }>`
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: ${({ checked }) => (!checked ? '#d4d4d4' : '#e40f0f')};
`;

export const DevicePropertyLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;

  display: flex;
  align-items: center;
  gap: 16px;
`;
