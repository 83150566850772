import styled from 'styled-components';

export const Card = styled.div`
  display: inline-grid;
  grid-template-columns: 26px auto auto;
  align-items: center;

  gap: 4px;
  margin-bottom: 8px;

  cursor: pointer;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
  border-radius: 6px;

  padding: 4px;

  > data,
  > span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 1em;

    color: #161e2e;

    font-weight: 600;
  }

  > span {
    opacity: 0.5em;
    font-weight: 400;
  }
`;
