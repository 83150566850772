import styled from 'styled-components';

export const GtTableHeadColumn = styled.th<{
  width?: number;
  sortable?: boolean;
  isDragOver?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  width: ${({ width }) => width || 200}px;
  height: 30px;

  text-align: left;

  > span {
    display: inline-block;
    width: 100%;

    padding: 4px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  
    color: rgba(26, 31, 54, 0.65);
  
    background: rgba(220, 223, 226, 0.25);
    user-select: none;

    &:not(:last-child) {
      border-right: solid 1px #fff;
    }
  }


  resize: horizontal;
  overflow-x: auto;


  ${({ isDragOver }) =>
    isDragOver
      ? `
        border-left: solid 2px #5850ec !important;
    `
      : `
  
    `}

  ${({ sortable }) =>
    sortable
      ? `
      cursor: pointer;
  
      &:hover {
        text-decoration: underline;
      }
    `
      : ''}
`;
