import { GtTableColumn, GtTableRow } from './tableRow.style';
import CellInputText from '../cell-input-text/cellInputText';
import CellInputNumber from '../cell-input-number/cellInputNumber';
import CellInputDate from '../cell-input-date/cell-input-date';
import { Column } from '../../table.interfaces';

interface Props {
  row?: any;
  columns: Column[];
  data: any;
  selectedLine: any;
  lineHandleClick: (data: any) => void;
}

/**
 *
 */
const getInput = (column: Column, row: any, data: any): any => {
  const getContent = (row: any, field: any) => {
    const path = field.split('.');
    if (path.length === 1) return row[path];
    if (path.length === 2 && row[path[0]]) return row[path[0]][path[1]];
    else return '';
  };

  if (column.renderCell) {
    return column.renderCell(row);
  }

  const value = column.valueGetter
    ? column.valueGetter(row)
    : getContent(row, column.field);

  if (!column.handleChange) {
    return column?.valueFormatter ? column.valueFormatter(value, data) : value;
  }

  const obj: any = {
    text: (
      <CellInputText
        handleChange={column.handleChange}
        value={column.valueFormatter ? column?.valueFormatter(value) : value}
        placeholder={'YYYY-MM-DD'}
        id={row.id}
        field={column.field}
        customStyle={
          'width: 100%; height: 100%; background-color: transparent; border: none;'
        }
        disabled={column.isDisable && column.isDisable()}
      />
    ),
    number: (
      <CellInputNumber
        handleChange={column.handleChange}
        value={column.valueFormatter ? column?.valueFormatter(value) : value}
        placeholder={'YYYY-MM-DD'}
        id={row.id}
        field={column.field}
        hasError={() => (column.hasError ? column.hasError(row) : '')}
        customStyle={
          'width: 100%; height: 100%; background-color: transparent; border: none;'
        }
        disabled={column.isDisable && column.isDisable()}
      />
    ),
    date: (
      <CellInputDate
        handleChange={column.handleChange}
        value={column.valueFormatter ? column?.valueFormatter(value) : value}
        placeholder={'YYYY-MM-DD'}
        id={row.id}
        field={column.field}
        hasError={() => (column.hasError ? column.hasError(row) : '')}
        customStyle={
          'width: 100%; height: 100%; background-color: transparent; border: none;'
        }
        disabled={column.isDisable && column.isDisable()}
      />
    ),
  };
  if (!column.type) return obj['text'];
  return obj[column.type] || obj['text'];
};

/**
 *
 * @param columns
 * @returns
 */
const renderColumns = (columns: Column[], row: any, data: any) =>
  columns.map((column, i) => (
    <GtTableColumn
      key={`${column.field}${i}${row.id}`}
      title={column.hasError ? column.hasError(row) : column?.description}
      width={column.width}
      removePadding={column.type}
    >
      {getInput(column, row, data)}
    </GtTableColumn>
  ));

/**
 *
 * @param columns
 * @returns
 */
const renderRows = (
  row: any,
  columns: Column[],
  data: any,
  selectedLine: any,
  lineHandleClick: (data: any) => void
) =>
  row.id ? (
    <GtTableRow
      key={`row-${row.id}-${row.index}`}
      id={`gt-row-${row.id}`}
      isSelected={selectedLine === row.id}
      onClick={() => lineHandleClick(row)}
    >
      {renderColumns(columns, row, data)}
    </GtTableRow>
  ) : null;

/**
 * Input component
 * @returns
 */
const TableRow = ({
  row,
  columns,
  data,
  selectedLine,
  lineHandleClick,
}: Props) => {
  return renderRows(row, columns, data, selectedLine, lineHandleClick);
};

TableRow.defaultProps = {};

export default TableRow;
