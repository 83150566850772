import {
  deleteSurveyPointMedia,
  deleteSurveyPoint,
  toggleSurveyPointMediaSiteSketch,
  updateSurveyPointMediaCaption,
  updateSurveyPoint,
} from '../../services/survey.service';
import { FileChanged } from './point-form';

export const onDeleteSurveyPoint = async (
  surveyId: number,
  token: string,
  reload: () => void
) => {
  await deleteSurveyPoint(surveyId, token);
  reload();
};

export const deleteMedia = async (
  medias: any[],
  media: any,
  setMedias: (data: FileChanged[]) => any,
  auth: any
) => {
  let updatedMedias: FileChanged[] = medias.map((m) => m);
  if (media.id) {
    try {
      await deleteSurveyPointMedia(media.id, auth.token);
      updatedMedias = medias.filter((m: FileChanged) => m.id !== media.id);
    } catch (err) {
      console.log(err);
    }
  } else {
    updatedMedias = medias.filter((m: FileChanged) => m.url !== media.url);
  }
  setMedias(updatedMedias);
};

export const updateSiteSketch = async (
  medias: any[],
  media: any,
  setMedias: (data: FileChanged[]) => any,
  auth: any
) => {
  let updatedMedias: FileChanged[] = [...medias];
  if (media.id) {
    try {
      const test = await toggleSurveyPointMediaSiteSketch(
        !media.is_sketch,
        media.id,
        auth.token
      );
      updatedMedias = medias.map((m: FileChanged) => {
        if (
          (media.url && m.url === media.url) ||
          (m.id && m.id === test.data.id)
        )
          return test.data;
        return m;
      });
      setMedias(updatedMedias);
    } catch (err) {
      console.log(err);
    }
  }
};

export const updateMediaCaption = async (
  medias: any[],
  media: any,
  setMedias: (data: FileChanged[]) => any,
  auth: any,
  caption: string
) => {
  let updatedMedias: FileChanged[] = [...medias];
  if (media.id) {
    try {
      const test = await updateSurveyPointMediaCaption(
        caption,
        media.id,
        auth.token
      );
      updatedMedias = medias.map((m: FileChanged) => {
        if (
          (media.url && m.url === media.url) ||
          (m.id && m.id === test.data.id)
        )
          return test.data;
        return m;
      });
      setMedias(updatedMedias);
    } catch (err) {
      console.log(err);
    }
  }
};

export const updateIcon = async ({
  value,
  id,
  token,
  dispatch,
  refresh,
}: any) => {
  dispatch({
    type: 'SET_TOAST',
    data: {
      title: 'Loading . . . ',
      type: 'loading',
    },
  });
  try {
    const updatePointData: any = {
      id: id,
      type: value,
    };
    await updateSurveyPoint(updatePointData, id, token);
    refresh();
  } catch (err) {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: `Error to update tracking point`,
      },
    });
  }
};
