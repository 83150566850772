import styled from 'styled-components';

export const SheetContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  .slick-viewport .slick-viewport-top .slick-viewport-left {
    height: 100%;
  }
  .grid-pane > .slickgrid-container {
    > .slick-pane.slick-pane-top.slick-pane-left {
      max-height: 100%;
      > .slick-viewport.slick-viewport-top.slick-viewport-left {
        overflow: auto !important;
        width: 100%;
        max-height: calc(100% - 41px);
      }
    }
  }
`;
