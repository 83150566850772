// external imports
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// context
import AccountStore from 'app/modules/account/account.context';
// interfaces
import { AuthType } from 'app/modules/account/account.context.d';
import { ErrorType } from './createProjectModal.interfaces';
// componenets
import ButtonComponent from '../button/button';
import InputGroup from '../input-group/inputGroup';
import Input from '../input/input';
import { saveProject } from './createProjectModal.controller';
import { NewProjectIlustration } from './svgs/newProject.icons';
// stylesheet
import {
  ModalWrapper,
  Modal,
  Title,
  Header,
  CloseIcon,
  TextArea,
  Container,
  Form,
  IlustrationWrapper,
  Dataset,
  FormWrapper,
} from './createProjectModal.style';

interface Props {
  opened: boolean;
  onClose: () => void; 
}

/**
 * 
 * @param props 
 * @returns 
 */
const CreateProjectModal = (props: Props) => {
  const history = useHistory();
  
  // context
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState<ErrorType>({})
  const [createLoading, setCreateLoading] = useState<boolean>(false)
  useEffect(() => {
    setErrors({});
  }, [name])

  return (
    <ModalWrapper hide={!props.opened}>
      <Modal>
        <Header>
          <CloseIcon onClick={props.onClose}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
          </CloseIcon>
        </Header>
        <Container>
          <FormWrapper>
            <Form>
              <Title className={'create-new-project'}>Create a new project</Title>
              <Dataset>
                <InputGroup error={errors.name} customStyle="padding-right: 6px;" label="Name">
                  <Input
                    id='create-new-project-name'
                    value={name}
                    handleChange={({ target }) => setName(target.value)}
                  />
                </InputGroup>
              </Dataset>
              <InputGroup label="Project Description">
                <TextArea
                  id='create-new-project-description'
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                />
              </InputGroup>
              <ButtonComponent
                value="Create"
                id="create-new-project-button"
                disabled={createLoading}
                handleClick={() => saveProject(
                  name,
                  description,
                  auth,
                  history,
                  props.onClose,
                  setCreateLoading,
                  setErrors,
                )}
              />
            </Form>
          </FormWrapper>
          <IlustrationWrapper>
            <NewProjectIlustration />
          </IlustrationWrapper>
        </Container>
      </Modal>
    </ModalWrapper>
  );
};

CreateProjectModal.defaultProps = {};

export default CreateProjectModal;
