import styled from 'styled-components';

export const Card = styled.li<{ customStyle?: string }>`
  background: #ffffff;
  border: 1px solid #e7ebee;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(37, 43, 64, 0.05);
  border-radius: 6px;

  padding: 16px 20px;

  cursor: pointer;

  > header {
    margin-bottom: 16px;

    display: grid;
    justify-content: space-between;
    align-items: start;
    grid-template-columns: 1fr auto;
    gap: 8px;

    @media (min-width: 1024px) {
      align-items: end;
    }
  }

  > footer {
    display: grid;
    grid-template-columns: auto 1fr;

    margin-top: 8px;
  }

  ${({ customStyle }) => customStyle}
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: right;
  gap: 8px;

  @media (min-width: 1024px) {
    min-height: auto;
  }
`;

export const RunCompletePercentage = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 1.4em;

  color: #919191;

  @media (min-width: 1024px) {
    font-size: 10px;
  }
`;

export const WeatherInformation = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;

  > img {
    width: 30px;
    vertical-align: middle;
  }
`;

export const Temperature = styled.strong`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.4em;

  color: #1a1f36;
`;

export const WeatherText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 1.4em;

  color: #686c7b;
`;

export const Actions = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: auto;
  gap: 8px;
  justify-content: flex-end;
  align-items: flex-end;

  @media (min-width: 1024px) {
    grid-template-columns: ${({ columns }) => `repeat(${columns}, auto)`};
  }
`;

export const MenuButton = styled.button<{ hide: boolean }>`
  border: 1px solid #dfe0e4;
  border-radius: 6px;
  background-color: transparent;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const MenuListWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  top: 40px;
  right: 0;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;

  overflow: hidden;
  z-index: 3;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const MenuListItem = styled.div`
  padding: 12px;
  display: flex;
  justify-content: flex-start;

  font-size: 13px;
  font-weight: 500;
  width: 100px;

  color: #6b7280;

  :hover {
    background-color: #f1f1f1;
  }

  > svg {
    margin-right: 12px;
  }
`;

export const ScheduledLabel = styled.label`
  font-size: 9px;
  color: #686c7b;

  > strong {
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    font-size: 10px;
  }
`;

export const PipelineLength = styled.label`
  font-size: 9px;
  color: #686c7b;

  margin-top: -2px;

  > strong {
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    font-size: 10px;
  }
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const LineStatText = styled.p`
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;

  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #686c7b;

  text-align: right;

  margin-top: 12px;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;
