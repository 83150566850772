import styled from 'styled-components';

export const ChartName = styled.label`
  font-size: 0.9rem;
`;

export const Wrapper = styled.div`
  font-size: 0.9rem;
  position: relative;
  border-radius: 6px;

  >.VictoryContainer {
    >svg {
      z-index: 0;
    }
  }
`;

export const Rangex1 = styled.input<{ hidden?: boolean }>`
  font-size: 0.7rem;
  margin-bottom: 1rem;
  border: 1px solid #e5e5e5;
  padding: 4px 6px;
  max-width: 40px;
  text-align: right;

  position: absolute;
  top: 33px;
  left: 10px;
  z-index: 500;
  border-radius: 3px;

  &:focus {
    font-size: 0.7rem;
    padding: 4px 6px;
    border: 1px solid #e5e5e5;
  }
  &:active{
    font-size: 0.7rem;
    padding: 4px 6px;
    border: 1px solid #e5e5e5;
  }

  ${({ hidden }) => hidden && `
    display: none;
  `}
`;

export const Rangex2 = styled.input<{ hidden?: boolean }>`
  font-size: 0.7rem;
  border: 1px solid #e5e5e5;
  padding: 4px 6px;
  max-width: 40px;
  text-align: right;

  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 500;
  border-radius: 3px;

  &:focus {
    font-size: 0.7rem;
    padding: 4px 6px;
    border: 1px solid #e5e5e5;
  }
  &:active{
    font-size: 0.7rem;
    padding: 4px 6px;
    border: 1px solid #e5e5e5;
  }

  ${({ hidden }) => hidden && `
    display: none;
  `}
`;
