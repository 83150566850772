import styled from 'styled-components';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 450px 1fr;

  @media (max-width: 768px) {
    display: block;
    height: 100vh;
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 500px;
`;

export const ActionGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Banner = styled.section`
  background-color: #eff0f3;
  font-size: 0.75;
  line-height: 1.125em;
  cursor: pointer;
  position: relative;
  padding-top: 24px;
  overflow: hidden;
`;

export const IlustrationWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  max-height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  position: sticky;

  @media (min-width: 768px) {
    width: 450px;
    height: auto;
    object-fit: contain;
    bottom: -150px;
  }
`;

export const GtLogo = styled.img`
  height: 30px;
`;

export const GtNameLogo = styled.svg`
  height: 22px;
`;

export const GtSolutions = styled.svg`
  width: 85%;
  margin-top: 38px;
  padding: 0 26px;

  position: relative;
  z-index: 4;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  margin-top: 16px;
  padding: 0 26px;

  position: relative;
  z-index: 4;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em;

  margin-bottom: 20px;
`;

export const submitCustomStyle = `
  width: 225px;
  margin-top: 16px;

  @media(max-width: 768px) {
   max-width: 100%;
   width: 100%;
  }
`;
