import styled from 'styled-components';

export const UsersContainer = styled.div`
  display: grid;
  padding-top: 43px;

  @media (max-width: 1024px) {
    grid-template-rows: 43px auto;
    padding-top: 0;
  }
`;

export const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 155px;
  gap: 6px;
`;

export const UsersContent = styled.main`
  padding: 16px;
  max-height: calc(100vh - 43px);
  background-color: rgba(240, 242, 243, 0.5);
  overflow: auto;
`;

export const UsersContentHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr 500px;
  align-items: end;
  margin-bottom: 32px;
`;

export const List = styled.div``;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  color: #161e2e;
`;
