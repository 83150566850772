import Avatar from 'app/components/avatar/avatar';
import {
  CardWrapper,
  InfoWrapper,
  Username,
  Phone,
  Email,
  Figure,
} from './userCard.style';

/**
 *
 * @returns
 */
const UserCard = ({ user, handleLineCLick }: any) => (
  <CardWrapper onClick={() => handleLineCLick(user)}>
    <Figure>
      <Avatar
        size={60}
        src={user.avatar}
        email={user.email}
        firstName={user.first_name}
        lastName={user.last_name}
      />
    </Figure>
    <InfoWrapper>
      <Username>{`${user.first_name} ${user.last_name}`}</Username>
      <Phone>
        <svg
          enableBackground="new 0 0 50 50"
          version="1.1"
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect fill="none" height="50" width="50" />
          <path
            d="M30.217,35.252c0,0,4.049-2.318,5.109-2.875  c1.057-0.559,2.152-0.7,2.817-0.294c1.007,0.616,9.463,6.241,10.175,6.739c0.712,0.499,1.055,1.924,0.076,3.32  c-0.975,1.396-5.473,6.916-7.379,6.857c-1.909-0.062-9.846-0.236-24.813-15.207C1.238,18.826,1.061,10.887,1,8.978  C0.939,7.07,6.459,2.571,7.855,1.595c1.398-0.975,2.825-0.608,3.321,0.078c0.564,0.781,6.124,9.21,6.736,10.176  c0.419,0.66,0.265,1.761-0.294,2.819c-0.556,1.06-2.874,5.109-2.874,5.109s1.634,2.787,7.16,8.312  C27.431,33.615,30.217,35.252,30.217,35.252z"
            fill="none"
            stroke="#000000"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </svg>
        {user.phone || '---'}
      </Phone>
      <Email>
        <svg
          enableBackground="new 0 0 32 32"
          version="1.1"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28,5H4C1.791,5,0,6.792,0,9v13c0,2.209,1.791,4,4,4h24c2.209,0,4-1.791,4-4V9  C32,6.792,30.209,5,28,5z M2,10.25l6.999,5.25L2,20.75V10.25z M30,22c0,1.104-0.898,2-2,2H4c-1.103,0-2-0.896-2-2l7.832-5.875  l4.368,3.277c0.533,0.398,1.166,0.6,1.8,0.6c0.633,0,1.266-0.201,1.799-0.6l4.369-3.277L30,22L30,22z M30,20.75l-7-5.25l7-5.25  V20.75z M17.199,18.602c-0.349,0.262-0.763,0.4-1.199,0.4c-0.436,0-0.851-0.139-1.2-0.4L10.665,15.5l-0.833-0.625L2,9.001V9  c0-1.103,0.897-2,2-2h24c1.102,0,2,0.897,2,2L17.199,18.602z"
            fill="#333333"
            id="mail"
          />
        </svg>
        {user.email}
      </Email>
    </InfoWrapper>
  </CardWrapper>
);

export default UserCard;
