import { useEffect, useRef, useState } from "react";
import CloseIcon from "../../icons/close.icon";
import ScanIcon from "../../icons/scan.icon";
import {
  DeviceCloseButton,
  DeviceHeader,
  DeviceModal,
  DeviceModalWrapper,
  DeviceModalFigure,
  DeviceModalTitle,
  DeviceModalDescription,
  DeviceModalTyperWrapper,
  DeviceModalTyperLabel,
  DeviceModalTyperInput,
  DeviceModalActions,
  cancelButtonCustomStyle,
} from "./device-type-scan.style";
import DefaultButton from "app/components/default-button/default-button";

interface Props {
  onClose: () => void;
  onScan: (serial: string) => void;
}

/**
 * 
 * @param props
 * @returns
 */
const DeviceTypeScan = (props: Props) => {
  const serialRef = useRef<HTMLInputElement>(null);
  const [serial, setSerial] = useState<string>('');

  useEffect(() => {
    const serialInput = serialRef.current;
    serialInput?.focus();
  }, []);

  return (
    <DeviceModalWrapper>
      <DeviceModal>
        <DeviceHeader>
          <DeviceModalFigure>
            <ScanIcon />
          </DeviceModalFigure>

          <DeviceCloseButton
            onClick={props.onClose}
          >
            <CloseIcon />
          </DeviceCloseButton>
        </DeviceHeader>

        <DeviceModalTitle>Scan Device</DeviceModalTitle>
        <DeviceModalDescription>Type here the device number located at the bar code.</DeviceModalDescription>

        <DeviceModalTyperWrapper>
          <DeviceModalTyperLabel>Device #</DeviceModalTyperLabel>
          <DeviceModalTyperInput
            ref={serialRef}
            value={serial}
            onChange={(e) => setSerial(e.target.value)}
            placeholder="type here"
          />
        </DeviceModalTyperWrapper>

        <DeviceModalActions>
          <DefaultButton
            disabled={!serial}
            onClick={() => {
              props.onScan(serial)
              props.onClose();
            }}
          >
            Confirm code
          </DefaultButton>

          <DefaultButton
            onClick={props.onClose}
            customStyle={cancelButtonCustomStyle}
          >
            Cancel
          </DefaultButton>
        </DeviceModalActions>
      </DeviceModal>
    </DeviceModalWrapper>
  );
};

export default DeviceTypeScan;

