import { PartipantType } from 'app/services/user.service';
import { manageProjectsPermissions } from 'app/services/projects.service';

export const editUserPermissions = async (
  participants: PartipantType[],
  user: string,
  token: string,
  onSuccess: () => void
) => {
  await manageProjectsPermissions(
    participants.map(({ project, admin, editor }: any) => ({
      project_id: project.id,
      admin,
      editor,
    })),
    user,
    token
  );

  onSuccess();
};
