import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// context
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';
// interfaces
import { AuthType } from 'app/modules/account/account.context.d';
import { ProjectType } from 'app/interfaces/inspection.interfaces';
import { UserType } from 'app/services/user.service';
// components
import ProjectAccess from './components/project-access/project-access';
import InvitationWithProjectModal from 'app/components/invitation-with-projects-modal/invitation-with-project-modal';
import Breadcrumbs, { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
import DefaultButton from 'app/components/default-button/default-button';
import UserCard from './components/user-card/userCard';
import UsersTable from './components/table/usersTable';
import SearchInput from 'app/components/search-input/search-input';
import ViewModeButton, { viewModes } from 'app/components/view-mode-button/view-mode-button';
import { filterUserBySearch, getAllUsers } from './people.controller';
// stylesheet
import {
  ActionsWrapper,
  List,
  UsersContainer,
  UsersContent,
  UsersList,
  UsersContentHeader,
  Title,
} from './people.style';
import NavigationContainer from 'app/components/navigation-container/navigation-container';

/**
 *
 * @returns
 */
const UsersListPage = () => {
  // router
  const history = useHistory();

  // contexts
  const notificationContext = useContext(NotificationStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  
  const [viewType, setViewType] = useState<string>('cards');
  const [search, setSearch] = useState<string>('');
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const [invitation, setInvitation] = useState<boolean>(false);

  const breadcrumbs: Breadcrumb[] = [
    {
      id: '#1',
      text: 'People',
      loading: false,
      href: '/people',
    },
  ];

  useEffect(() => {
    const viewType = window.localStorage.getItem('CDI@users-page/view-mode');
    if (viewType) setViewType(viewType);
    getAllUsers({ setAllUsers, setUsers, auth });
  }, []);

  useEffect(() => {
    if (!auth.token) history.push('/');
  }, [auth.token]);

  useEffect(() => {
    filterUserBySearch({ allUsers, search, setUsers });
  }, [search]);

  useEffect(() => {
    window.localStorage.setItem('CDI@users-page/view-mode', viewType);
  }, [viewType]);

  return (
    <NavigationContainer>
      <UsersContainer>
        <Breadcrumbs
          customStyle={`
            position: sticky;
            top: 0;
            left: 0;
          `}
          routes={breadcrumbs}
        />
        <UsersContent>
          <UsersContentHeader>
            <Title>People</Title>
            <ActionsWrapper>
              <SearchInput
                customStyle={`
                  max-width: 500px;
                `}
                value={search}
                placeholder="Search by name, phone or email"
                onChange={({ target }) => setSearch(target.value)}
              />
              <ViewModeButton type={viewType} onViewModeChange={setViewType} />
              <DefaultButton
                onClick={() => setInvitation(true)}
                customStyle={`
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  position: relative;
                  top: 1px;

                  height: 40px;
                `}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="22" height="22" rx="3" fill="#3E38B3" />
                  <rect x="10" y="5" width="2" height="12" rx="1" fill="white" />
                  <rect
                    x="17"
                    y="10"
                    width="2"
                    height="12"
                    rx="1"
                    transform="rotate(90 17 10)"
                    fill="white"
                  />
                </svg>
                Invite
              </DefaultButton>
            </ActionsWrapper>
          </UsersContentHeader>
          <List>
            {viewType === viewModes.GRID ? (
              <UsersList>
                {users
                  .sort((usrA, usrB) => (usrA.id > usrB.id ? 1 : -1))
                  .map((usr) => (
                    <li key={usr.id}>
                      <UserCard
                        handleLineCLick={(usr: UserType) => setSelectedUser(usr)}
                        user={usr}
                      />
                    </li>
                  ))}
              </UsersList>
            ) : (
              <UsersTable
                handleLineCLick={(usr: UserType) => setSelectedUser(usr)}
                users={users.sort((usrA, usrB) => (usrA.id > usrB.id ? 1 : -1))}
              />
            )}
          </List>
          <ProjectAccess
            managerProjects={
              ((auth.user.managed_projects as unknown) ||
                []) as ProjectType[]
            }
            participantProjects={selectedUser?.participant_set || []}
            token={auth.token || ''}
            onSuccess={() => {
              const userFirstName = selectedUser?.first_name || 'user';
              setSelectedUser(null);

              getAllUsers({ setAllUsers, setUsers, auth: auth });

              notificationContext.dispatch({
                type: 'SET_TOAST',
                data: {
                  title: `Project access of ${userFirstName} was saved!`,
                  type: 'success',
                },
              });
            }}
            opened={!!selectedUser}
            email={selectedUser?.email || ''}
            id={selectedUser?.id || ''}
            firstName={selectedUser?.first_name || ''}
            lastName={selectedUser?.last_name || ''}
            avatarUri={
              typeof selectedUser?.avatar !== 'string'
                ? selectedUser?.avatar?.dataUrl
                : selectedUser?.avatar
            }
            onClose={() => setSelectedUser(null)}
          />
          <InvitationWithProjectModal
            managerProjects={
              ((auth.user.managed_projects as unknown) ||
                []) as ProjectType[]
            }
            opened={invitation}
            onClose={() => setInvitation(false)}
          />
        </UsersContent>
      </UsersContainer>
    </NavigationContainer>
  );
};

export default UsersListPage;
