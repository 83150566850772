import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;

  margin-top: 16px;
  margin-bottom: 32px;
`;

export const Content = styled.main`
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(240, 242, 243, 0.5);
`;

export const Figure = styled.figure`
  width: 90px;
  height: 90px;

  border-radius: 50%;
  overflow: hidden;

  background-color: black;

  margin-left: auto;
  margin-right: auto;
`;

export const InvitationWrapper = styled.section`
  background-color: #ffffff;
  border: 1px solid #e7ebee;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(37, 43, 64, 0.05);
  border-radius: 6px;

  padding: 32px;
`;

export const InvitationOwner = styled.strong`
  color: #5850ec;
`;

export const InvitationTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;

  color: #161e2e;

  text-align: center;

  margin-top: 24px;
`;

export const Permission = styled.li`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4em;

  color: #686c7b;
`;

export const PermissionList = styled.ul`
  list-style: disc;
  list-style-position: inside;

  display: flex;
  flex-direction: column;
`;

export const PermissionText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4em;

  color: #686c7b;

  margin-bottom: 12px;
`;

export const ProjectPermissions = styled.section``;

export const Message = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2em;

  margin-top: 32px;

  color: #686c7b;

  strong {
    font-weight: 500;
    color: #ed2024;
  }
`;

export const ProjectList = styled.ul`
  list-style: disc;
  list-style-position: inside;

  margin-top: 32px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4em;

  color: #161e2e;
`;

export const ProjectListItem = styled.li``;
