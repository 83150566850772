import { Buttons, Button } from './view-mode-button.style';

export const viewModes = {
  GRID: 'grid',
  LIST: 'list',
};

interface Props {
  type: string;
  onViewModeChange: (type: string) => void;
}

const ViewModeButton = ({ type, onViewModeChange }: Props) => (
  <Buttons>
    <Button
      selected={type === viewModes.GRID}
      onClick={() => onViewModeChange(viewModes.GRID)}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="6"
          height="6"
          rx="2"
          fill={type === viewModes.GRID ? '#5850EC' : '#8E949F'}
        />
        <rect
          y="9"
          width="6"
          height="6"
          rx="2"
          fill={type === viewModes.GRID ? '#5850EC' : '#8E949F'}
        />
        <rect
          x="9"
          width="6"
          height="6"
          rx="2"
          fill={type === viewModes.GRID ? '#5850EC' : '#8E949F'}
        />
        <rect
          x="9"
          y="9"
          width="6"
          height="6"
          rx="2"
          fill={type === viewModes.GRID ? '#5850EC' : '#8E949F'}
        />
      </svg>
    </Button>
    <Button
      selected={type === viewModes.LIST}
      onClick={() => onViewModeChange(viewModes.LIST)}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          width="10"
          height="3"
          rx="1"
          fill={type === viewModes.LIST ? '#5850EC' : '#8E949F'}
        />
        <rect
          x="5"
          y="6"
          width="10"
          height="3"
          rx="1"
          fill={type === viewModes.LIST ? '#5850EC' : '#8E949F'}
        />
        <rect
          x="5"
          y="12"
          width="10"
          height="3"
          rx="1"
          fill={type === viewModes.LIST ? '#5850EC' : '#8E949F'}
        />
        <rect
          width="3"
          height="3"
          rx="1"
          fill={type === viewModes.LIST ? '#5850EC' : '#8E949F'}
        />
        <rect
          y="6"
          width="3"
          height="3"
          rx="1"
          fill={type === viewModes.LIST ? '#5850EC' : '#8E949F'}
        />
        <rect
          y="12"
          width="3"
          height="3"
          rx="1"
          fill={type === viewModes.LIST ? '#5850EC' : '#8E949F'}
        />
      </svg>
    </Button>
  </Buttons>
);

export default ViewModeButton;
