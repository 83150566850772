import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL || '/api';

/**
 * request to django api all registered projects
 * @returns promise that contains projects
 */
export const loadAllProjects = (token: string, page = 1) => {
  return axios.get(`${API_URL}/projects/?page=${page}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * request to django api all registered projects
 * @returns promise that contains projects
 */
export const loadProjectsByMonth = async (month: number, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  };

  return axios.get(`${API_URL}/projects/`, { headers });
};

/**
 * request to django api all registered projects
 * @returns promise that contains projects
 */
export const createProject = (project: any, token: string) => {
  return axios.post(`${API_URL}/projects/`, project, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * request to django api all registered projects
 * @returns promise that contains projects
 */
export const updateProject = (project: any, token: string) => {
  return axios.put(`${API_URL}/projects/${project.id}/`, project, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * request to django api all registered projects
 * @returns promise that contains projects
 */
export const deleteProject = (id: string, token: string) => {
  return axios.delete(`${API_URL}/projects/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * request to django api a project by reference
 * @param id project reference to use on find
 */
export const loadProject = (id: number, token: string) =>
  axios.get(`${API_URL}/projects/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * request to django api all runs of the referenced project
 * @param id project reference to use on find request
 */
export const loadProjectRuns = (id: number, token: string) =>
  axios.get(`${API_URL}/projects/${id}/runs/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * check if th logged user has administrator functions
 * @param projectId project id
 * @param token auth token
 */
export const checkIsProjectAdministrator = (projectId: string, token: string) =>
  axios.get(`${API_URL}/projects/${projectId}/admin/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

interface ManagePartipantType {
  project_id: string;
  admin: boolean;
  editor: boolean;
}

export const manageProjectsPermissions = (
  participant_projects: ManagePartipantType[],
  user_id: string,
  token: string
) =>
  axios.post(
    `${API_URL}/users/${user_id}/projects/`,
    { participant_projects, user_id },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 * get surveys
 * @param id run reference on django api
 * @param cb callback function
 */
export const getAvailableSurveys = (projectId: number, token: string) => {
  return axios.get(`${API_URL}/projects/${projectId}/surveys/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * assign qubes to project
 * @param id run reference on django api
 * @param cb callback function
 */
export const assignQubes = (token: string, projectId: number, qubes: string[] | number[]) => {
  return axios.post(
    `${API_URL}/projects/${projectId}/qubes/`, 
    { qubes },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    },
  );
};

/**
 * unassign qubes to project
 * @param id run reference on django api
 * @param cb callback function
 */
export const unassignQubes = (token: string, projectId: number, qubes: string[] | number[]) => {
  return axios.post(
    `${API_URL}/projects/${projectId}/qubes/unassign`, 
    { qubes },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    },
  );
};
