import { InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { captureException } from "@sentry/react";
import { AccountContext } from "app/modules/account/account.context.d";


export const createMarkerElement = (avatar): HTMLSpanElement => {
  const markerWrapper = document.createElement('div');
  const markerEl = document.createElement('img');

  markerEl.setAttribute('src', avatar);
  markerWrapper.setAttribute('class', 'GT-LOGGED-USER-MARKER-ICON');
  markerWrapper.appendChild(markerEl);
  const label = document.createElement('label');
  label.innerText = 'You';
  label.setAttribute('class', 'display-none');
  markerWrapper.appendChild(label);

  return markerWrapper;
}

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderMarker = async (
  map: google.maps.Map,
  oldmarker: google.maps.marker.AdvancedMarkerElement | undefined,
  setMarker: (mk: google.maps.marker.AdvancedMarkerElement) => void,
  inspectionContext: InspectionProviderValue,
  accountState: AccountContext,
): Promise<google.maps.marker.AdvancedMarkerElement> => {

  const getAvatar = (avatar: string) => 
    avatar && avatar[0] !== '/'
        ? `${avatar}`
        : '';


  const usrAvatar = getAvatar(accountState?.auth?.user.avatar);
  const customerAvatar = getAvatar(inspectionContext.run?.project?.customer?.company?.logo);
  const avatar = usrAvatar || customerAvatar || '/assets/icons/gt-logo.svg';

  if (oldmarker) {
    oldmarker.map = null;
  }
  let AdvancedMarkerElement
  try {
    const markerLib = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    AdvancedMarkerElement = markerLib.AdvancedMarkerElement as google.maps.MarkerLibrary['AdvancedMarkerElement'];

  } catch (err) {
    captureException(err);
  }

  const marker = new AdvancedMarkerElement({
    map,
    gmpClickable: true,
    content: createMarkerElement(avatar),
    zIndex: 3,
  });

  navigator.geolocation.watchPosition(
    (position) => {
      marker.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      inspectionContext.dispatch({
        type: 'SET_USER_POSITION',
        data: marker.position,
      });
    },
    () => '',
    { enableHighAccuracy: true, maximumAge: 0, timeout: 10000 }
  );

  setMarker(marker);
  return marker;
};