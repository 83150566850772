import { useContext, useEffect, useState } from 'react';
import { centerMap, mapSurvey } from './survey.controller';
import { Props } from './survey.d';
import SurveyStore from 'app/modules/survey/survey.context';
import Pipeline from './components/pipeline/pipeline';
import { SurveyContext } from 'app/modules/survey/survey.context.d';
import Driveline from './components/driveline/driveline';
import { PipelineType } from 'app/modules/survey/survey.interfaces';
import TPMarker from '../run/components/tp-marker/tp-marker';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';

/**
 * 
 * @param param0 
 * @returns 
 */
const Survey = ({
  map,
  zoom,
  isActiveLabels,
  isActiveRuler,
  measureTool,
  localSurvey,
}: Props) => {
  const surveyContext = useContext(SurveyStore);
  const [pipeline, setPipeline] = useState<PipelineType>()
  const [survey, setSurvey] = useState<SurveyContext['survey']>()

  // Center map
  useEffect(() => {
    if (pipeline) {
      centerMap({
        pipeline: pipeline as any,
        map,
      });
    }
  }, [pipeline]);

  // update pipeline from survey store
  useEffect(() => {
    if (surveyContext.state?.pipeline) {
      setPipeline(surveyContext.state?.pipeline);
    }
  }, [surveyContext.state?.pipeline]);
  
  // update survey from survey store
  useEffect(() => {
    if (surveyContext.state?.survey) {
      setSurvey(surveyContext.state?.survey);
    }
  }, [surveyContext.state?.survey]);

  // update everything from local survey
  useEffect(() => {
    if (localSurvey) {
      mapSurvey(
        localSurvey,
        setPipeline,
        setSurvey,
      );
    }
  }, [localSurvey]);

  return (
    <>
      { map &&
        pipeline ? (
          <Pipeline
            pipeline={pipeline}
            map={map}
          />
        ) : null
      }
      { map &&
        pipeline?.driveline ? (
          <Driveline
            driveline={pipeline.driveline}
            map={map}
          />
        ) : null
      }
      { map &&
        survey?.surveypoint_set.map((p, i) =>(
          <TPMarker
            key={`survey-point${p.id}-${i}`}
            point={p as unknown as TrackingPointCascadeType}
            index={i}
            map={map}
            zoom={zoom}
            hasLabels={isActiveLabels}
            hasRuler={isActiveRuler}
            measureTool={measureTool}
          />
        ))
      }

    </>
  );
};

export default Survey;
