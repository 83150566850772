import { formatTimezoneDate } from '@/lib/formatter';
import LinkCell from '../link-cell/link-cell';

export const columnsModel = ({ timezone }: any): any[] => [
  {
    field: 'name',
    headerName: 'Name',
    width: 240,
    formatter: (survey: any) => survey?.name || '',
  },
  {
    headerName: 'Owner/Operator',
    width: 150,
    formatter: (survey: any) => survey?.owner || '',
  },
  {
    field: 'survey_by',
    headerName: 'Survey Company',
    width: 120,
    formatter: (survey: any) => survey?.survey_by?.name || '',
  },
  {
    field: 'points_length',
    headerName: 'Tracking Points',
    width: 170,
    alignTo: 'center',
    formatter: (survey: any) => survey?.points_length,
  },
  {
    headerName: 'Download files',
    field: 'files',
    width: 150,
    formatter: (survey: any) => {
      return LinkCell({ survey });
    },
  },
  {
    headerName: 'Created at',
    field: 'created_at',
    width: 150,
    formatter: (survey: any) =>
      formatTimezoneDate({
        date: survey?.created,
        timezone,
        format: 'YYYY/MM/DD HH:mm:ss',
      }),
  },
  {
    headerName: 'Last edited',
    width: 150,
    field: 'last_edit',
    formatter: (survey: any) =>
      formatTimezoneDate({
        date: survey?.updated_at,
        timezone,
        format: 'YYYY/MM/DD HH:mm:ss',
      }),
  },
];
