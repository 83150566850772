import styled from 'styled-components';

export const AvatarIMG = styled.img<{
  size?: number;
  customStyle?: string;
  withPointer?: boolean;
}>`
  width: ${({ size }) => (size ? size : 32)}px;
  height: ${({ size }) => (size ? size : 32)}px;
  border-radius: 50%;

  border: 2px solid #ffffff;
  box-sizing: border-box;

  ${({ withPointer }) => (withPointer ? 'cursor: pointer;' : '')}
  ${({ customStyle }) => customStyle}
`;

export const EmptyAvatar = styled.figure<{
  size?: number;
  customStyle?: string;
  withPointer?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;

  width: ${({ size }) => (size ? size : 32)}px;
  height: ${({ size }) => (size ? size : 32)}px;
  border-radius: 50%;

  border: 2px solid #ffffff;
  box-sizing: border-box;

  background-color: #4e46e9;

  color: #ffffff;
  font-size: ${({ size }) => (size ? size / 3 : 32 / 3)}px;
  cursor: default;

  ${({ withPointer }) => (withPointer ? 'cursor: pointer;' : '')}
  ${({ customStyle }) => customStyle}
`;
