import styled from 'styled-components';

export const GtTableHeadColumn = styled.th<{
  width?: number;
  sortable?: boolean;
  isDragOver?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  width: ${({ width }) => width || 200}px;
  height: 30px;
  padding: 6px;

  text-align: left;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  color: rgba(26, 31, 54, 0.8);

  background-color: rgba(220, 223, 226);
  user-select: none;

  resize: horizontal;
  overflow-x: auto;

  &:not(:last-child) {
    border-right: solid 1px #fff;
  }

  ${({ isDragOver }) =>
    isDragOver
      ? `
        border-left: solid 2px #5850ec !important;
    `
      : `
  
    `}

  ${({ sortable }) =>
    sortable
      ? `
      cursor: pointer;
  
      &:hover {
        text-decoration: underline;
      }
    `
      : ''}
`;
