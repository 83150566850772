import styled from 'styled-components';

const bgColors = { primary: 'primary', secondary: 'neutra_700', transparent: 'transparent' };
const bgHoverColors = { primary: 'primary_800', secondary: 'neutra_800', transparent: 'transparent' };
const bgActiveColors = { primary: 'primary_900', secondary: 'neutra_900', transparent: 'transparent' };
const iconColors = { primary: 'surface_white', secondary: 'surface_white', transparent: 'outline_on_surface' };

export const Button = styled.button<{ $style: 'primary' | 'secondary' | 'transparent' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: ${({ theme, $style }) => theme.colors[bgColors[$style]]};

  border: none;
  border-radius: 6px;

  color: ${({ theme, $style }) => theme.colors[iconColors[$style]]};
  cursor: pointer;

  transition-duration: 400ms;

  &:hover {
    background-color: ${({ theme, $style }) => theme.colors[bgHoverColors[$style]]};
  }
  
  &:active,
  &:visited {
    background-color: ${({ theme, $style }) => theme.colors[bgActiveColors[$style]]};
  }
  
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.neutra_300};
    cursor: not-allowed;
  }
`; 
