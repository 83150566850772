import styled from 'styled-components';

export const DesktopDataEditorInput = styled.textarea`
  &,
  &:focus,
  &:active {
    position: absolute;
    top: 1px;
    left: 1px;

    width: 100%;
    height: 50px;
    padding: 2px 6px;
    border: 1px solid #5850ec;
    border-radius: 2px;
    z-index: 1;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 10px;

    color: #4b5066;

    @media (min-width: 1024px) {
      font-size: 11px;
      padding: 4px;
    }
  }

  &:disabled {
    display: none;
  }
`;

export const DesktopDataEditorText = styled.p<{ isObserver: boolean }>`
  display: inline-block;
  background-color: ${({ isObserver }) => (isObserver ? 'transparent' : '#dcdfe2')};
  border-radius: 2px;
  padding: 0px 4px;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
  top: 2.5px;

  cursor: pointer;
`;

export const DesktopDataEditorWrapper = styled.div`
  position: relative;
  width: 100%;
`;
