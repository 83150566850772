import { useEffect, useState } from 'react';
import { ControlList, ControlItem, Control } from './map-type.style';

interface ControlType {
  id: string;
  name: string;
}

const renderTypes = (
  controlTypes: ControlType[],
  onMapTypeChange: (mapType: string) => void
) =>
  controlTypes.map(({ id, name }) => (
    <ControlItem key={`${id}-${name}`}>
      <Control onClick={() => onMapTypeChange(id)}>{name}</Control>
    </ControlItem>
  ));

interface Props {
  onMapTypeChange: (mapType: string) => void;
  hasStreetViewButton?: boolean;
}

/**
 * 
 * @param param0 
 * @returns 
 */
const MapTypeControl = ({ onMapTypeChange, hasStreetViewButton }: Props) => {
  const [types, setTypes] = useState<ControlType[]>([]);
  useEffect(() => {
    const controlTypes = [
      {
        name: 'Map',
        id: window?.google?.maps?.MapTypeId?.ROADMAP,
      },
      {
        name: 'Satellite',
        id: window?.google?.maps?.MapTypeId?.HYBRID,
      },
      {
        name: 'Terrain',
        id: window?.google?.maps?.MapTypeId?.TERRAIN,
      },
    ];

    setTypes(controlTypes);
  }, [window?.google?.maps?.MapTypeId]);

  return (
    <ControlList $hasStreetViewButton={!!hasStreetViewButton}>
      {renderTypes(types, onMapTypeChange)}
    </ControlList>
  );
};

export default MapTypeControl;
