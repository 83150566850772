import { useContext, MouseEvent } from 'react';
import InspectionStore from 'app/modules/inspection/inspection.context';
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';
import { AuthType } from 'app/modules/account/account.context.d';
import Button from '../../../../../components/button/button';
import LinkButton from '../../../../../components/link-button/link-button';
import { finishRun } from 'app/modules/inspection/inspection.controller';
import {
  ModalWrapper,
  Modal,
  ModalFigure,
  ButtonWrapper,
  ConfirmationTitle,
  ConfirmationText,
  ConfirmationFooter,
} from './confirmation-finish-modal.style';

const ConfirmationFinishModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);
  const auth = accountContext.state.auth as AuthType;
  const icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff"><path d="M12 5.99L19.53 19H4.47L12 5.99M2.74 18c-.77 1.33.19 3 1.73 3h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18zM11 11v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zm0 5h2v2h-2z" /></svg>;
  const title = 'Are you sure you want to finish the run?';
  const text = 'This action will finish the run, you will be able to resume it.';
  const confirmationText = 'Finish';
  const cancelationText = 'Cancel';
  
  /**
   * 
   */
  const handleCancel = () => {
    inspectionContext.dispatch({ type: 'CLOSE_MODALS' });
  };

  /**
   * 
   */
  const handleConfirm = () => {
    if (!inspectionContext.run?.id) return; 
    finishRun(inspectionContext.run.id, auth.token, notificationContext.dispatch);
    inspectionContext.dispatch({ type: 'CLOSE_MODALS' });
  };
  
  return (
    <ModalWrapper onClick={handleCancel}>
      <Modal onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}>
        <ModalFigure>{icon}</ModalFigure>
        <ConfirmationTitle>{title}</ConfirmationTitle>
        <ConfirmationText>{text}</ConfirmationText>
        <ConfirmationFooter>
          <ButtonWrapper>
            <Button
              handleClick={handleConfirm}
              value={confirmationText}
            />
            <LinkButton handleClick={handleCancel}>{cancelationText}</LinkButton>
          </ButtonWrapper>
        </ConfirmationFooter>
      </Modal>
    </ModalWrapper>
  );
};

export default ConfirmationFinishModal;


