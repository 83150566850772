import { GtTableColumn, GtTableRow } from './tableRow.style';
import CellInputText from '../cell-input-text/cellInputText';
import CellInputNumber from '../cell-input-number/cellInputNumber';
import CellInputDate from '../cell-input-date/cellInputDate';
import CellSelector from '../cell-selector/cell-selector';
import { Column } from '../../simple-table.d';

interface Props {
  row?: any;
  columns: Column[];
}

/**
 *
 */
const getInput = (column: Column, row: any): any => {
  const getContent = (row: any, field: any) => {
    const path = field.split('.');
    if (path.length === 1) return row[path];
    if (path.length === 2 && row[path[0]]) return row[path[0]][path[1]];
    else return '';
  };

  if (column.renderCell) {
    return column.renderCell(row);
  }

  const value = column.valueGetter
    ? column.valueGetter(row)
    : getContent(row, column.field);

  if (!column.handleChange || !column.type) {
    return (
      <p>
        {column?.valueFormatter ? column.valueFormatter(value, row) : value}
      </p>
    );
  }

  const obj: any = {
    text: (
      <CellInputText
        handleChange={column.handleChange}
        value={
          column.valueFormatter ? column?.valueFormatter(value, row) : value
        }
        placeholder={'YYYY-MM-DD'}
        id={row.id}
        field={column.field}
        customStyle={
          'width: 100%; height: 100%; background-color: transparent; border: none;'
        }
        disabled={column.isDisable && column.isDisable()}
      />
    ),
    number: (
      <CellInputNumber
        handleChange={column.handleChange}
        value={
          column.valueFormatter ? column?.valueFormatter(value, row) : value
        }
        placeholder={'YYYY-MM-DD'}
        id={row.id}
        field={column.field}
        hasError={() => (column.hasError ? column.hasError(row) : '')}
        customStyle={
          'width: 100%; height: 100%; background-color: transparent; border: none;'
        }
        disabled={column.isDisable && column.isDisable()}
      />
    ),
    selector: (
      <CellSelector
        id={column.field}
        options={column.options || []}
        handleChange={(value: { id: string; label: string }) =>
          column.handleChange?.(value, row)
        }
        value={
          column.valueFormatter ? column?.valueFormatter(value, row) : value
        }
      />
    ),
    date: (
      <CellInputDate
        handleChange={column.handleChange}
        value={
          column.valueFormatter ? column?.valueFormatter(value, row) : value
        }
        placeholder={'YYYY-MM-DD'}
        id={row.id}
        field={column.field}
        hasError={() => (column.hasError ? column.hasError(row) : '')}
        customStyle={
          'width: 100%; height: 100%; background-color: transparent; border: none;'
        }
        disabled={column.isDisable && column.isDisable()}
      />
    ),
  };

  if (!column.type) return <p>{obj['text']}</p>;
  return obj[column.type] || <p>{obj['text']}</p>;
};

/**
 *
 * @param columns
 * @returns
 */
const renderColumns = (columns: Column[], row: any) =>
  columns.map((column, i) => (
    <GtTableColumn
      key={`${column.field}${i}${row.id}`}
      title={column.hasError ? column.hasError(row) : column?.description}
      width={column.width}
      removePadding={column.type}
      ellipsis={column.ellipsis}
    >
      {getInput(column, row)}
    </GtTableColumn>
  ));

/**
 *
 * @param columns
 * @returns
 */
const renderRows = (row: any, columns: Column[]) =>
  row.id ? (
    <GtTableRow key={`row-${row.id}-${row.index}`} id={`gt-row-${row.id}`}>
      {renderColumns(columns, row)}
    </GtTableRow>
  ) : null;

/**
 * Input component
 * @returns
 */
const TableRow = ({ columns, row }: Props) => {
  return renderRows(row, columns);
};

TableRow.defaultProps = {};

export default TableRow;
