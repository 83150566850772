import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  Note,
  ButtonsWrapper,
  NoteText,
  CreatedAt,
  NoteActionButton,
  TextArea,
  Loading,
  NoteSaveWrapper,
  NoteSaveButton,
} from './note-card.style';

interface NoteParams {
  note: {
    id: string;
    text: string;
    created_at?: string;
  };
  autoFocus?: boolean
  onCancel?: () => void;
  onDelete: (setLoading: (loading: boolean) => void) => void;
  onEdit: (text: string, setLoading: (loading: boolean) => void) => void;
  allowEdition: boolean;
}

/**
 * note component to render on tracking point side nar
 * @param note it is the note informations
 * @returns a react component
 */
const NoteCard = ({ onCancel, autoFocus, note, onDelete, onEdit, allowEdition }: NoteParams) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [editionText, setEditionText] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [shift, setShift] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      setEditMode(true);
      setTimeout(() => textareaRef.current?.focus(), 500);
    }
  }, []);

  useEffect(() => {
    setEditionText(note.text);
  }, [note.id]);

  return (
    <>
      <Note>
        <Loading show={loading}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              fill="rgb(75,80,102)"
              cy="50"
              stroke="rgb(75,80,102)"
              strokeWidth="10"
              r="40"
              strokeDasharray="190"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1.9048192771084336s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </Loading>
        
        <ButtonsWrapper className="note-actions-wrapper">
          {editMode || loading || !allowEdition ? null : (
            <NoteActionButton
              onClick={() => {
                setEditMode(true);

                setTimeout(() => {
                  if (textareaRef?.current) {
                    textareaRef.current.focus();
                    textareaRef.current.select();
                  }
                }, 50);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="rgb(75,80,102)"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
              </svg>
            </NoteActionButton>
          )}
          {editMode || loading || !allowEdition ? null : (
            <NoteActionButton
              onClick={(e) => {
                e.preventDefault();
                onCancel?.();
                onDelete(setLoading)}
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="rgb(75,80,102)"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
              </svg>
            </NoteActionButton>
          )}
        </ButtonsWrapper>

        {editMode ? (
          <TextArea
            ref={textareaRef}
            value={editionText}
            onKeyDown={(e) => {
              if (e.key === 'Shift') {
                setShift(true);
              }
            }}
            onKeyUp={(e: any) => {
              if (e.key === 'Shift') {
                e.preventDefault();
                setShift(false);
              }

              if (e.key === 'Enter' && !shift) {
                e.preventDefault();
                setLoading(true);
                setEditMode(false);
                
                onEdit(editionText.trim(), setLoading);
                setEditionText(editionText.trim());
                
                if (textareaRef.current) textareaRef.current.blur();
              }

              if (e.key === 'Escape') {
                e.preventDefault();
                setEditionText(note.text);
                setEditMode(false);

                if (onCancel) onCancel();
              }
            }}
            onBlur={() => {
              setEditMode(false);

              if (!autoFocus) setEditionText(note.text);
              if (autoFocus && !editionText.trim()) onCancel?.();
            }}
            onChange={(e: any) => {
              setEditionText(e.target.value);
            }}
          />
        ) : (
          <NoteText>{note.text}</NoteText>
        )}
        {
          note.created_at ? (
            <CreatedAt>{moment(note.created_at).format('llll')}</CreatedAt>
          ) : null
        }
      </Note>
      {
        autoFocus && !loading ? (
          <NoteSaveWrapper>
            <NoteSaveButton
              type="button"
              onClick={(e) => {
                e.preventDefault();

                if (editionText.trim()) {
                  setLoading(true);
                  setEditMode(false);                  
                  onEdit(editionText.trim(), setLoading);
                  setEditionText(editionText.trim());
                }
                
                if (!editionText.trim() && textareaRef.current) {
                  onCancel?.();
                }
              }}
            >
              Save
            </NoteSaveButton>
          </NoteSaveWrapper>
        ) : null
      }
    </>
  );
};

export default NoteCard;
