import styled from 'styled-components';

export const List = styled.ul<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  ${({ reverse }) =>
    reverse
      ? `
    & > img:not(:first-child),
    & > figure:not(:first-child) {
      margin-right: -12px !important;
    }
  `
      : `
  & > img:not(:last-child),
  & > figure:not(:last-child) {
    margin-right: -12px !important;
  }
  `};
`;

export const AddPartipantButton = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 50%;

  border: 2px solid #ffffff;
  box-sizing: border-box;

  background-color: #5850ec;

  position: relative;
  top: -4px;
  left: 6px;

  color: #ffffff;
  font-size: 24px;
  line-height: 1em;

  transition-duration: 200ms;

  cursor: pointer;

  &:hover {
    background-color: #3e38b2;
  }
`;

export const AddictionalNumber = styled.figure<{ onClick?: () => void }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #ffffff;
  box-sizing: border-box;

  background-color: #5850ec;

  color: #ffffff;
  font-size: 24px;
  line-height: 1em;

  color: #ffffff;
  font-size: ${32 / 3}px;
  cursor: default;

  transition-duration: 200ms;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}
`;
