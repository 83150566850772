import styled from 'styled-components';

export const ModalWrapper = styled.div<{ opened: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 30;
`;

export const Modal = styled.div`
  width: 100%;
  max-width: 580px;

  background: #ffffff;

  border-radius: 6px;
`;

export const ModalHeader = styled.header`
  position: relative;
  padding: 32px;
`;

export const ModalContent = styled.div`
  padding: 24px;
  background-color: #f7f8f9;
`;

export const InviteForm = styled.form``;

export const ModalTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;

  color: #161e2e;

  &:active {
    padding: 0;
    border: none;
  }

  &:focus {
    padding: 0;
    border: none;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  padding: 6px;
  border: none;

  &:active {
    padding: 6px;
    border: none;
  }

  &:focus {
    padding: 6px;
    border: none;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;

  position: absolute;
  top: 16px;
  right: 16px;

  cursor: pointer;
`;

export const MultiSelectInputWrapper = styled.div<{ focus: boolean }>`
  position: relative;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: 4px;

  min-height: 40px;
  padding: 5px 10px;
  border: 1px solid ${({ focus }) => (!focus ? '#e5e5e5' : '#5850ec')};
  border-radius: 3px;
  width: 100%;

  > div {
    display: grid;

    > ul {
      display: flex;
      flex-wrap: wrap;
      column-gap: 4px;

      > li {
        margin-bottom: 6px;
      }
    }
  }
`;

export const Email = styled.li`
  display: inline-flex;
  align-items: center;
  gap: 6px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #161e2e;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
  border-radius: 3px;

  padding: 6px 8px;

  > svg {
    width: 16px;
    height: 16px;

    cursor: pointer;
  }
`;

export const PredictUsersWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const ProjectList = styled.ul`
  margin-top: 16px;
  margin-bottom: 24px;

  max-height: 200px;
  overflow: auto;
`;

export const ProjectListHeader = styled.header`
  display: flex;
  justify-content: space-between;

  border-bottom: solid 1px #dfe4ea;

  padding: 11px 4px 11px 0;
`;

export const ProjectListTitle = styled.h3`
  font-weight: 500;
  font-size: 0.93em;
  line-height: 1em;
  display: block;

  color: #4b5066;
`;

export const ProjectListItem = styled.li`
  padding: 9px 4px 9px 0;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: rgba(26, 31, 54, 0.65);

  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    gap: 16px;
  }
`;

export const EmptyProjectsWrapper = styled.div`
  text-align: center;
  padding: 32px 16px 16px 16px;
`;

export const EmptyText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: rgba(26, 31, 54, 0.65);
`;

export const Error = styled.p`
  font-weight: 500;
  font-size: 0.8em;
  line-height: 0.93em;
  color: #dd0001;

  margin-top: 16px;
`;
