import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { formatTimezoneDate, formatTimezoneDateFromNow } from "@/lib/formatter";
import SettingsStore from "app/modules/settings/settings.context";
import InspectionStore from "app/modules/inspection/inspection.context";
import AccountStore from "app/modules/account/account.context";
import NotificationStore from "app/modules/notification/notification.context";
import { AuthType } from "app/modules/account/account.context.d";
import { TrackingPointCascadeType } from "app/modules/inspection/inspection.interfaces";
import SwitchButton from 'app/components/common/base/swtich-button/switch-button';
import SatellitePassages from "../../../../components/satellite-passages/satellite-passages";
import {
  TrackingPointModalAddButton,
  TrackingPointModalCloseButton,
  TrackingPointModalContent,
  TrackingPointModalContentHeader,
  TrackingPointModalMetaDataTable,
  TrackingPointModalMetaDataTableWrapper,
  TrackingPointModalPassage,
  TrackingPointModalPassageInvalidReason,
  TrackingPointModalPassageInput,
  TrackingPointModalTrackingPointName,
  TrackingPointModalWrapper,
  TrackingPointModalMedia,
  TrackingPointModalMediaHeader,
  TrackingPointModalMediaContent,
  TrackingPointModalMediaFigure,
  TrackingPointModalMediaFooter,
  TrackingPointModalMediaImage,
  TrackingPointModalMediaFooterMediaGalleryButton,
  TrackingPointPassageInformation,
  TrackingPointPassageInformationPassage,
  TrackingPointPassageDate,
  TrackingPointPassageTime,
  TrackingPointPassageDataGroup,
  TrackingPointPassageDataLabel,
  TrackingPointPassageDataValue,
  TrackingPointPresetWrapper,
  TrackingPointSeeMoreButton,
  TrackingPointSeeMoreButtonWrapper,
} from './tracking-point-overlay.style';
import { metadata } from "./tracking-point-overlay.model";
import MobileDataEditor from "../mobile-data-editor/mobile-data-editor";
import { handleCommonTextChange, handleHold, handlePassageChange, serializePassageTime, updateIcon } from "../../mobile.controller";
import InputDate from "app/components/input-date/input-date";
import IconSelector from "app/components/icon-selector/iconSelector";
import QubeCardMobile from "../qube-card-mobile/qube-card-mobile";
import { uploadMedia } from "../../../desktop/components/tracking-point-overlay/tracking-point-overlay.controller";

/**
 *
 * @returns
 */
const TrackingPointOverlay = () => {
  const settingsContext = useContext(SettingsStore);
  const notificationContext = useContext(NotificationStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const mediaInputRef = useRef<HTMLInputElement>(null);

  const point = inspectionContext.state.mobile.selected_point as TrackingPointCascadeType;
  const qube = undefined;
  const passage = point.passage;

  const [isSeeMore, setIsSeeMore] = useState<boolean>(false);
  const [isSeeTriggers, setIsSeeTriggers] = useState<boolean>();
  const [passageTimeValue, setPassageTimeValue] = useState<string>('');
  const [passageDateValue, setPassageDateValue] = useState<Date>();
  const [showingIconSelector, setShowingIconSelector] = useState<boolean>(false);

  const passageTime = useMemo(() => {
    if (!passage?.tstamp) return '';
    const time = formatTimezoneDate({
      date: passage.tstamp,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'HH:mm:ss.SSS',
    });

    setPassageTimeValue(time);
    return time;
  }, [passage?.tstamp, settingsContext.state.timezone]);

  const passageDate = useMemo(() => {
    if (!passage?.tstamp) return '';
    setPassageDateValue(new Date(passage.tstamp));
    return formatTimezoneDate({
      date: passage.tstamp,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'MMM DD, YYYY',
    });
  }, [passage?.tstamp, settingsContext.state.timezone]);

  const eta = useMemo(() => {
    if (!point.eta) return '-';
    return formatTimezoneDate({
      date: point.eta,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'MM/DD HH:mm:ss',
    });
  }, [point.eta, settingsContext.state.timezone]);

  const ete = useMemo(() => {
    if (!point.eta) return '-';
    return formatTimezoneDateFromNow({
      date: new Date(point.eta),
      timezone: settingsContext.state.timezone?.id || 'utc',
      isNext: !!point.is_next,
    });
  }, [point.eta, settingsContext.state.timezone, inspectionContext.state.tick]);

  useEffect(() => {
    if (point.trigger_set?.length) {
      setIsSeeTriggers(true);
    }
  }, []);

  useEffect(() => {
    const _tstamp = inspectionContext.run?.trackingpoint_set?.[point.index]?._tstamp
    if (_tstamp && point._tstamp !== _tstamp) {
      inspectionContext.dispatch({
        type: 'SET_MOBILE_SELECTED_POINT',
        data: inspectionContext.run?.trackingpoint_set?.[point.index],
      });      
    }
  }, [inspectionContext.run?.trackingpoint_set?.[point.index]?._tstamp]);

  /**
   * render rows of tracking point metadata
   * @returns
   */
  const renderMetadata = () => Object.keys(metadata).map((key) => {
    const units = {
      distance: settingsContext.state.distanceUnit,
      timezone: settingsContext.state.timezone,
      speed: settingsContext.state.speedUnit,
    };

    const { label, formatter, readonly } = metadata[key];
    let text = point[key];

    if (formatter) {
      text = formatter(point, units)
    }

    return (
      <tr key={key}>
        <td>{label}</td>
        <td style={{ width: '70%' }}>
          {readonly ?
            text :
            <MobileDataEditor
              value={text}
              isObserver={inspectionContext.state.permission_type !== 'editor'}
              onChange={(value) => {
                if (!auth) return;
                const data = {};
                data[key] = value;
                handleCommonTextChange(point.id, data, auth.token);
              }}
            />
          }
        </td>
      </tr>
    );
  });

  /**
   * render the first three media of tracking point
   * @returns
   */
  const renderMedia = () => point.media_set?.slice(0, 3).map((media, index) => {
    return (
      <TrackingPointModalMediaFigure
        key={media.id}
        onClick={() => {
          inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT', data: undefined });
          inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT_TO_GALLERY', data: point });
          inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_MEDIA_INDEX', data: index });
        }}
      >
        <TrackingPointModalMediaImage src={media.media} alt={media.caption || ''} />
      </TrackingPointModalMediaFigure>
    );
  });

  // stop propation to on cancel from wrapper to childrens
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <TrackingPointModalWrapper
      onClick={() => {
        inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT', data: undefined });
      }}
    >
      <div>
        <TrackingPointModalContent onClick={(e) => e.stopPropagation()}>
          <TrackingPointPresetWrapper $isObserver={inspectionContext.state.permission_type !== 'editor'}>
            <SwitchButton
              disabled={inspectionContext.state.permission_type !== 'editor'}
              label="Preset" on={!!point.active}
              handleChange={() => {
                handleHold(point, auth.token, notificationContext.dispatch);
              }}
            />
          </TrackingPointPresetWrapper>

          <TrackingPointModalContentHeader>
            <TrackingPointModalTrackingPointName>
              <figure>
                <IconSelector
                  showIconSelector={showingIconSelector}
                  setShowIconSelector={(data) => setShowingIconSelector(data)}
                  row={point}
                  permissionType={'editor'}
                  fill="#dfe0e4"
                  iconSize="28px"
                  handleChange={({ value }) => {
                    updateIcon({
                      id: point.id,
                      value,
                      token: auth?.token,
                      dispatch: notificationContext.dispatch,
                    })
                  }}
                />
              </figure>
              {point.name}
              </TrackingPointModalTrackingPointName>
            <TrackingPointPassageInformation>
              <TrackingPointPassageInformationPassage>
                <TrackingPointPassageTime $invalid={!!passage?.id && !passage.valid}>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 0C2.24288 0 0 2.24301 0 5C0 7.75698 2.24302 10 5 10C7.75698 10 10 7.75698 10 5C10 2.24301 7.75698 0 5 0ZM5 9.3752C2.58757 9.3752 0.625029 7.41261 0.625029 5.00023C0.625029 2.58785 2.58762 0.625262 5 0.625262C7.41238 0.625262 9.37497 2.58785 9.37497 5.00023C9.37497 7.41261 7.41238 9.3752 5 9.3752ZM6.99848 7.37779C7.0956 7.52037 7.05888 7.71486 6.91622 7.81198C6.86238 7.8486 6.80107 7.86618 6.74058 7.86618C6.64064 7.86618 6.54252 7.81844 6.48203 7.72962L4.74172 5.1761C4.70637 5.12426 4.68752 5.06295 4.68752 5.00009V1.65128C4.68752 1.47874 4.82745 1.33882 4.99998 1.33882C5.17252 1.33882 5.31245 1.47875 5.31245 1.65128V4.90377L6.99848 7.37779Z" fill="#5850EC"/>
                  </svg>
                  {passageTime || '---'}
                </TrackingPointPassageTime>
                <TrackingPointPassageDate $invalid={!!passage?.id && !passage.valid}>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.61911 0C2.22749 0 1.90479 0.330572 1.90479 0.731744V1.2195H0.714322C0.322701 1.2195 0 1.55007 0 1.95125V9.26826C0 9.66943 0.322701 10 0.714322 10H9.28568C9.6773 10 10 9.66943 10 9.26826V1.95125C10 1.55007 9.6773 1.2195 9.28568 1.2195H8.09521V0.731744C8.09521 0.330572 7.77251 0 7.38089 0C6.98927 0 6.66657 0.330572 6.66657 0.731744V1.2195H3.33326V0.731744C3.33326 0.330572 3.01056 0 2.61894 0H2.61911ZM2.61911 0.487757C2.75513 0.487757 2.85718 0.592303 2.85718 0.731636V2.19502C2.85718 2.33435 2.75513 2.43889 2.61911 2.43889C2.4831 2.43889 2.38104 2.33435 2.38104 2.19502V0.731636C2.38104 0.592299 2.4831 0.487757 2.61911 0.487757ZM7.38097 0.487757C7.51699 0.487757 7.61905 0.592303 7.61905 0.731636V2.19502C7.61905 2.33435 7.51699 2.43889 7.38097 2.43889C7.24496 2.43889 7.1429 2.33435 7.1429 2.19502V0.731636C7.1429 0.592299 7.24496 0.487757 7.38097 0.487757ZM0.714365 1.70726H1.90483V2.19502C1.90483 2.59619 2.22753 2.92676 2.61915 2.92676C3.01077 2.92676 3.33348 2.59619 3.33348 2.19502V1.70726H6.66678V2.19502C6.66678 2.59619 6.98948 2.92676 7.3811 2.92676C7.77272 2.92676 8.09542 2.59619 8.09542 2.19502V1.70726H9.28589C9.42191 1.70726 9.52396 1.8118 9.52396 1.95114V3.41452H0.47642V1.95114C0.47642 1.8118 0.578477 1.70726 0.714492 1.70726H0.714365ZM0.476293 3.90236H9.52384V9.26817C9.52384 9.40751 9.42178 9.51205 9.28576 9.51205H0.714407C0.578388 9.51205 0.476335 9.4075 0.476335 9.26817V3.90236H0.476293Z" fill="#5850EC"/>
                  </svg>
                  {passageDate || '---'}
                </TrackingPointPassageDate>

                <TrackingPointPassageDataGroup>
                  <TrackingPointPassageDataLabel>ETA:</TrackingPointPassageDataLabel>
                  <TrackingPointPassageDataValue>{eta}</TrackingPointPassageDataValue>
                </TrackingPointPassageDataGroup>

                <TrackingPointPassageDataGroup>
                  <TrackingPointPassageDataLabel>ETE:</TrackingPointPassageDataLabel>
                  <TrackingPointPassageDataValue>{ete}</TrackingPointPassageDataValue>
                </TrackingPointPassageDataGroup>
              </TrackingPointPassageInformationPassage>
            </TrackingPointPassageInformation>
          </TrackingPointModalContentHeader>

          <TrackingPointModalPassage isObserver={inspectionContext.state.permission_type !== 'editor'}>
            <TrackingPointModalAddButton
              disabled={inspectionContext.state.permission_type !== 'editor'}
              onClick={() => {
                inspectionContext.dispatch({ type: 'SET_SELECTED_POINT_TO_PASSAGE', data: point });
                inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT', data: undefined });
              }}
            >
              Add
            </TrackingPointModalAddButton>
            <TrackingPointModalPassageInput $invalid={!!passage?.id && !passage.valid}>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 0C2.24288 0 0 2.24301 0 5C0 7.75698 2.24302 10 5 10C7.75698 10 10 7.75698 10 5C10 2.24301 7.75698 0 5 0ZM5 9.3752C2.58757 9.3752 0.625029 7.41261 0.625029 5.00023C0.625029 2.58785 2.58762 0.625262 5 0.625262C7.41238 0.625262 9.37497 2.58785 9.37497 5.00023C9.37497 7.41261 7.41238 9.3752 5 9.3752ZM6.99848 7.37779C7.0956 7.52037 7.05888 7.71486 6.91622 7.81198C6.86238 7.8486 6.80107 7.86618 6.74058 7.86618C6.64064 7.86618 6.54252 7.81844 6.48203 7.72962L4.74172 5.1761C4.70637 5.12426 4.68752 5.06295 4.68752 5.00009V1.65128C4.68752 1.47874 4.82745 1.33882 4.99998 1.33882C5.17252 1.33882 5.31245 1.47875 5.31245 1.65128V4.90377L6.99848 7.37779Z" fill="#5850EC"/>
              </svg>

              <input
                type="tel"
                disabled={inspectionContext.state.permission_type !== 'editor'}
                value={passageTimeValue}
                onChange={(e) => setPassageTimeValue(e.target.value)}
                onBlur={() => {
                  if (inspectionContext.run && settingsContext.state.timezone?.id) {
                    const serializedPassage = serializePassageTime(passageTimeValue, settingsContext.state.timezone.id, passage?.tstamp)
                    handlePassageChange(serializedPassage, inspectionContext.run, point, auth.token);
                  }
                }}
              />
            </TrackingPointModalPassageInput>
            <TrackingPointModalPassageInput $invalid={!!passage?.id && !passage.valid}>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.61911 0C2.22749 0 1.90479 0.330572 1.90479 0.731744V1.2195H0.714322C0.322701 1.2195 0 1.55007 0 1.95125V9.26826C0 9.66943 0.322701 10 0.714322 10H9.28568C9.6773 10 10 9.66943 10 9.26826V1.95125C10 1.55007 9.6773 1.2195 9.28568 1.2195H8.09521V0.731744C8.09521 0.330572 7.77251 0 7.38089 0C6.98927 0 6.66657 0.330572 6.66657 0.731744V1.2195H3.33326V0.731744C3.33326 0.330572 3.01056 0 2.61894 0H2.61911ZM2.61911 0.487757C2.75513 0.487757 2.85718 0.592303 2.85718 0.731636V2.19502C2.85718 2.33435 2.75513 2.43889 2.61911 2.43889C2.4831 2.43889 2.38104 2.33435 2.38104 2.19502V0.731636C2.38104 0.592299 2.4831 0.487757 2.61911 0.487757ZM7.38097 0.487757C7.51699 0.487757 7.61905 0.592303 7.61905 0.731636V2.19502C7.61905 2.33435 7.51699 2.43889 7.38097 2.43889C7.24496 2.43889 7.1429 2.33435 7.1429 2.19502V0.731636C7.1429 0.592299 7.24496 0.487757 7.38097 0.487757ZM0.714365 1.70726H1.90483V2.19502C1.90483 2.59619 2.22753 2.92676 2.61915 2.92676C3.01077 2.92676 3.33348 2.59619 3.33348 2.19502V1.70726H6.66678V2.19502C6.66678 2.59619 6.98948 2.92676 7.3811 2.92676C7.77272 2.92676 8.09542 2.59619 8.09542 2.19502V1.70726H9.28589C9.42191 1.70726 9.52396 1.8118 9.52396 1.95114V3.41452H0.47642V1.95114C0.47642 1.8118 0.578477 1.70726 0.714492 1.70726H0.714365ZM0.476293 3.90236H9.52384V9.26817C9.52384 9.40751 9.42178 9.51205 9.28576 9.51205H0.714407C0.578388 9.51205 0.476335 9.4075 0.476335 9.26817V3.90236H0.476293Z" fill="#5850EC"/>
              </svg>

              <InputDate
                disabled={inspectionContext.state.permission_type !== 'editor'}
                value={passageDateValue?.toISOString()}
                handleChange={(e) => {
                  setPassageDateValue(e.value)
                  if (inspectionContext.run) {
                    handlePassageChange(e.value.valueOf(), inspectionContext.run, point, auth.token);
                  }
                }}
                format="MMM dd, yyyy"
                customStyle={`
                  border: none;
                  padding: 0;

                  input {
                    color: ${passage && !passage.valid ? 'rgba(237, 32, 36, 1)' : '#5850ec'} !important;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 10px !important;
                    line-height: 12px;
                  }
                `}
              />
            </TrackingPointModalPassageInput>
          </TrackingPointModalPassage>

          {
            passage?.invalid_reason ? (
              <TrackingPointModalPassageInvalidReason>
                {passage.invalid_reason}
              </TrackingPointModalPassageInvalidReason>
            ) : null
          }

          {
            inspectionContext.run?.project?.using_triggers ? (
              <TrackingPointSeeMoreButtonWrapper>
                  <TrackingPointSeeMoreButton
                    active={!isSeeTriggers}
                    onClick={() => {
                      setIsSeeTriggers(!isSeeTriggers);
                    }}
                  >
                                        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.96582 0.998596C7.86118 0.998464 7.76083 1.03996 7.68702 1.11388L4.18001 4.61321L0.673177 1.11388C0.573552 1.01447 0.428379 0.97567 0.29224 1.01203C0.156171 1.0484 0.0498759 1.15446 0.0134368 1.29023C-0.0230056 1.426 0.0158796 1.57093 0.115505 1.67033L3.90121 5.44792C3.97654 5.519 4.07629 5.55859 4.18002 5.55859C4.28374 5.55859 4.38349 5.519 4.45889 5.44792L8.24459 1.67033L8.24453 1.67039C8.31847 1.59655 8.36 1.49649 8.36 1.39213C8.36 1.28778 8.31847 1.18772 8.24453 1.11394C8.17058 1.04016 8.07029 0.998659 7.96572 0.998659L7.96582 0.998596Z" fill="#767986"/>
                    </svg>

                    <span>
                      {isSeeTriggers ? 'Close Triggers' : 'See Triggers'}
                    </span>
                  </TrackingPointSeeMoreButton>
                </TrackingPointSeeMoreButtonWrapper>
            ) : null
          }

          {
            inspectionContext.run?.project?.using_triggers
            && isSeeTriggers
            && auth?.token ? (
              <SatellitePassages
                isObserver={inspectionContext.state.permission_type !== 'editor'}
                agm={point}
                onClose={() => {
                  inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_TRIGGER', data: undefined });
                  inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT_TO_TRIGGER', data: undefined });
                  inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT', data: undefined });
                }}
                onTriggerMoreInfosClick={(trigger) => {
                  inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_TRIGGER', data: trigger });
                  inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT_TO_TRIGGER', data: point });
                  inspectionContext.dispatch({ type: 'SET_MOBILE_SELECTED_POINT', data: undefined });

                  setTimeout(() => {
                    const element = document.querySelector('._highlighted_trigger');
                    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }, 1);
                }}
                authToken={auth.token}
              />
            ) : null
          }

          <TrackingPointSeeMoreButtonWrapper>
            <TrackingPointSeeMoreButton
              active={!isSeeMore}
              onClick={() => {
                setIsSeeMore(!isSeeMore);
              }}
            >
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.96582 0.998596C7.86118 0.998464 7.76083 1.03996 7.68702 1.11388L4.18001 4.61321L0.673177 1.11388C0.573552 1.01447 0.428379 0.97567 0.29224 1.01203C0.156171 1.0484 0.0498759 1.15446 0.0134368 1.29023C-0.0230056 1.426 0.0158796 1.57093 0.115505 1.67033L3.90121 5.44792C3.97654 5.519 4.07629 5.55859 4.18002 5.55859C4.28374 5.55859 4.38349 5.519 4.45889 5.44792L8.24459 1.67033L8.24453 1.67039C8.31847 1.59655 8.36 1.49649 8.36 1.39213C8.36 1.28778 8.31847 1.18772 8.24453 1.11394C8.17058 1.04016 8.07029 0.998659 7.96572 0.998659L7.96582 0.998596Z" fill="#767986"/>
              </svg>

              <span>
                {isSeeMore ? 'See Less' : 'See More'}
              </span>
            </TrackingPointSeeMoreButton>
          </TrackingPointSeeMoreButtonWrapper>

          {
            isSeeMore ? (
              <TrackingPointModalMetaDataTableWrapper>
                <TrackingPointModalMetaDataTable>
                  {renderMetadata()}
                </TrackingPointModalMetaDataTable>
              </TrackingPointModalMetaDataTableWrapper>
            ) : null
          }

          <TrackingPointModalMedia>
            <TrackingPointModalMediaHeader>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.15385 9L5.46154 7.30769M9 7.46154L7.15385 5.61538L5.46154 7.30769M5.46154 7.30769L2.84615 4.69231L1.30769 6.23077L1 6.53846M1 6.53846L1 2.23077C1 1.55103 1.55104 0.999999 2.23077 1L7.76923 1.00001C8.44897 1.00001 9 1.55105 9 2.23078L9 7.76923C9 8.44896 8.44897 9 7.76923 9L2.23077 9C1.55103 9 1 8.44896 1 7.76923L1 6.53846ZM7.76923 3.15384C7.76923 3.66364 7.35596 4.07692 6.84615 4.07692C6.33635 4.07692 5.92308 3.66364 5.92308 3.15384C5.92308 2.64404 6.33635 2.23077 6.84615 2.23077C7.35596 2.23077 7.76923 2.64404 7.76923 3.15384Z" stroke="#5850EC" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <strong>Media</strong> ({point.media_set?.length || 0})
            </TrackingPointModalMediaHeader>

            <TrackingPointModalMediaContent>
              {renderMedia()}
            </TrackingPointModalMediaContent>

            <TrackingPointModalMediaFooter hidden={inspectionContext.state.permission_type !== 'editor'}>
              <TrackingPointModalMediaFooterMediaGalleryButton
                disabled={inspectionContext.state.permission_type !== 'editor'}
                onClick={() => {
                  mediaInputRef?.current?.click()
                }}
              >
                <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.25" y="0.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
                  <rect x="0.25" y="4.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
                  <rect x="4.25" y="0.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
                  <rect x="4.25" y="4.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
                </svg>
                Add Media
              </TrackingPointModalMediaFooterMediaGalleryButton>
              <input
                ref={mediaInputRef}
                type="file"
                onChange={(event) => {
                  if (event.target.files && auth && inspectionContext.run) {
                    uploadMedia(
                      point,
                      event.target.files,
                      auth.token,
                      notificationContext.dispatch,
                    );
                  }
                }}
              />
            </TrackingPointModalMediaFooter>
          </TrackingPointModalMedia>
        </TrackingPointModalContent>

        {qube ? <QubeCardMobile qube={qube} /> : null}

        <TrackingPointModalCloseButton
          type="button"
          onClick={(e) => {
            stopPropagation(e);
            inspectionContext.dispatch({
              type: 'SET_MOBILE_SELECTED_POINT',
              data: undefined,
            });
          }}
        >
          Close
        </TrackingPointModalCloseButton>
      </div>
    </TrackingPointModalWrapper>
  );
};

export default TrackingPointOverlay;
