import axios from 'axios';
import getEnv from 'env';
import { SurveyProviderValue } from '../../survey.context.d';
import { AccountContext } from 'app/modules/account/account.context.d';

const API_URL = getEnv('VITE_API_URL');

export const saveSurvey = async ({
  survey,
  auth,
  setErrors,
  dispatch,
}: {
  survey: any;
  auth: AccountContext['auth'];
  setErrors: (data: any) => void;
  dispatch: SurveyProviderValue['dispatch'];
}) => {
  if (!auth || !auth?.user?.managed_customer?.id) return;
  if (!survey?.name) return setErrors({ name: 'Name is required' });
  dispatch({
    type: 'SET_LOADING',
    data: true
  });

  try {
    const data = new FormData();

    data.append('geoid_model', survey.geoidModel);
    data.append('survey_id', survey.id);
    data.append('gps_survey_accuracy', survey.gpsAccuracy);
    data.append('gps_datum', survey.gpsDatum);
    data.append('gps_equipment', survey.gpsEquipment);
    data.append('name', survey.name);
    data.append('owner', survey.owner);
    data.append('customer_id', `${auth.user.managed_customer.id}`);
    data.append('survey_company', survey.surveyorCompany);
    data.append('surveyor_phone', survey.surveyorPhone);

    const createdSurvey = await axios.put(`${API_URL}/surveys/`, data, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Token ${auth.token}`,
      },
    });

    dispatch({
      type: 'SET_SURVEY',
      data: createdSurvey?.data?.survey
    });
    dispatch({
      type: 'SET_LOADING',
      data: false
    });
    dispatch({ type: 'OPEN_MODAL' });
  } catch (err) {
    dispatch({
      type: 'SET_LOADING',
      data: false
    });
  }
};
