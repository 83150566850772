import LinkButton from 'app/components/link-button/link-button';
import { Bar, BarActions, BarSelecteds, BarSelectedsAmount } from './customer-devices-float-bar.style';
import DefaultButton from 'app/components/default-button/default-button';
import BarAddIcon from '../../icons/add.icon';

interface Props {
  onlyDesk?: boolean;
  amount: number;
  onAddDevice: () => void;
}

/**
 * 
 * @returns 
 */
const CustomerDevicesFloatBar = (props: Props) => {
  return (
    <Bar onlyDesk={props.onlyDesk}>
      <BarSelecteds>
        <BarSelectedsAmount>{props.amount}</BarSelectedsAmount>
        Selected
      </BarSelecteds>

      <LinkButton
        handleClick={() => null}
        customStyle={`
          display: none;

          padding: 0;

          color: #17181B;
          font-size: 14px;
        `}
      >
        Deselect All
      </LinkButton>

      <BarActions>
        <DefaultButton
          onClick={props.onAddDevice}
          customStyle={`
            display: flex;
            align-items: center;
            justify-content: space-between;            
            gap: 8px;

            font-size: 14px;
          `}
        >
          <BarAddIcon />
          Add Devices to the Project
        </DefaultButton>
      </BarActions>
    </Bar>
  );
};

export default CustomerDevicesFloatBar;
