import { Link, Wrapper } from './link-cell.style';

const getLink = (file: string) => {
  if (file[0] === '/') return `http://localhost:8000${file}`;
  return file;
};

const renderKmz = () => (
  <svg
    width="25"
    height="18"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00004 5.30769L0.321756 4.57289C0.116675 4.7622 3.83854e-05 5.02859 3.73721e-05 5.30769L1.00004 5.30769ZM5.66669 1V0C5.4153 0 5.17313 0.0946841 4.98841 0.265198L5.66669 1ZM1 15.6667L2 15.6667V15.6667L1 15.6667ZM15.3333 15.6667C15.3333 15.1144 14.8856 14.6667 14.3333 14.6667C13.781 14.6667 13.3333 15.1144 13.3333 15.6667H15.3333ZM1.00003 6.33333L2.00003 6.33334L1.00003 6.33333ZM13.3333 3.33333C13.3333 3.88562 13.781 4.33333 14.3333 4.33333C14.8856 4.33333 15.3333 3.88562 15.3333 3.33333H13.3333ZM1.67832 6.04249L6.34497 1.7348L4.98841 0.265198L0.321756 4.57289L1.67832 6.04249ZM2.48148 18H12.8519V16H2.48148V18ZM2.48148 16C2.31558 16 2.18452 15.9397 2.10288 15.8662C2.02293 15.7942 2 15.7224 2 15.6667L0 15.6667C-9.05991e-06 17.0518 1.2127 18 2.48148 18V16ZM12.8519 18C14.1206 18 15.3333 17.0518 15.3333 15.6667H13.3333C13.3333 15.7224 13.3104 15.7942 13.2305 15.8662C13.1488 15.9397 13.0177 16 12.8519 16V18ZM12.8519 2C13.0177 2 13.1488 2.06034 13.2305 2.13382C13.3104 2.20577 13.3333 2.27763 13.3333 2.33333H15.3333C15.3333 0.948171 14.1206 0 12.8519 0V2ZM5.66669 2H6.92591V0H5.66669V2ZM6.92591 2H12.8519V0H6.92591V2ZM3.73721e-05 5.30769L3.36766e-05 6.33333L2.00003 6.33334L2.00004 5.3077L3.73721e-05 5.30769ZM3.36766e-05 6.33333L0 15.6667L2 15.6667L2.00003 6.33334L3.36766e-05 6.33333ZM13.3333 2.33333V3.33333H15.3333V2.33333H13.3333Z"
      fill="#295394"
    />
    <path
      d="M6.50568 12H7.7358V10.3466L8.34091 9.60795L9.93466 12H11.4062L9.22159 8.78693L11.3807 6.18182H9.90625L7.8125 8.74716H7.7358V6.18182H6.50568V12ZM12.0213 6.18182V12H13.2145V8.19886H13.2628L14.7685 11.9716H15.581L17.0866 8.21307H17.1349V12H18.3281V6.18182H16.8111L15.2088 10.0909H15.1406L13.5384 6.18182H12.0213ZM19.2791 12H23.7337V10.9858H20.8217L23.728 6.91193V6.18182H19.2734V7.19602H22.1825L19.2791 11.2699V12Z"
      fill="#295394"
    />
  </svg>
);

const renderSheet = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00004 5.30769L0.321756 4.57289C0.116675 4.7622 3.83854e-05 5.02859 3.73721e-05 5.30769L1.00004 5.30769ZM5.66669 1V0C5.4153 0 5.17313 0.0946841 4.98841 0.265198L5.66669 1ZM15.3333 15.6667C15.3333 15.1144 14.8856 14.6667 14.3333 14.6667C13.781 14.6667 13.3333 15.1144 13.3333 15.6667H15.3333ZM1 15.6667L2 15.6667V15.6667L1 15.6667ZM1.00003 6.33333L2.00003 6.33334L1.00003 6.33333ZM13.3333 3.33333C13.3333 3.88562 13.781 4.33333 14.3333 4.33333C14.8856 4.33333 15.3333 3.88562 15.3333 3.33333H13.3333ZM1.67832 6.04249L6.34497 1.7348L4.98841 0.265198L0.321756 4.57289L1.67832 6.04249ZM2.48148 18H12.8519V16H2.48148V18ZM2.48148 16C2.31558 16 2.18452 15.9397 2.10288 15.8662C2.02293 15.7942 2 15.7224 2 15.6667L0 15.6667C-9.05991e-06 17.0518 1.2127 18 2.48148 18V16ZM12.8519 18C14.1206 18 15.3333 17.0518 15.3333 15.6667H13.3333C13.3333 15.7224 13.3104 15.7942 13.2305 15.8662C13.1488 15.9397 13.0177 16 12.8519 16V18ZM12.8519 2C13.0177 2 13.1488 2.06034 13.2305 2.13382C13.3104 2.20577 13.3333 2.27763 13.3333 2.33333H15.3333C15.3333 0.948171 14.1206 0 12.8519 0V2ZM5.66669 2H6.92591V0H5.66669V2ZM6.92591 2H12.8519V0H6.92591V2ZM3.73721e-05 5.30769L3.36766e-05 6.33333L2.00003 6.33334L2.00004 5.3077L3.73721e-05 5.30769ZM3.36766e-05 6.33333L0 15.6667L2 15.6667L2.00003 6.33334L3.36766e-05 6.33333ZM13.3333 2.33333V3.33333H15.3333V2.33333H13.3333Z"
      fill="#1F6E42"
    />
    <path
      d="M10.6591 6.18182H9.2642L11.0511 9.09091L9.23011 12H10.6392L11.8324 10.0142H11.8778L13.071 12H14.4858L12.6705 9.09091L14.446 6.18182H13.0568L11.8778 8.16477H11.8324L10.6591 6.18182Z"
      fill="#1F6E42"
    />
  </svg>
);

const renderPdf = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00004 5.30769L0.321756 4.57289C0.116675 4.7622 3.83854e-05 5.02859 3.73721e-05 5.30769L1.00004 5.30769ZM5.66669 1V0C5.4153 0 5.17313 0.0946841 4.98841 0.265198L5.66669 1ZM15.3333 15.6667C15.3333 15.1144 14.8856 14.6667 14.3333 14.6667C13.781 14.6667 13.3333 15.1144 13.3333 15.6667H15.3333ZM1 15.6667L2 15.6667V15.6667L1 15.6667ZM1.00003 6.33333L2.00003 6.33334L1.00003 6.33333ZM13.3333 3.33333C13.3333 3.88562 13.781 4.33333 14.3333 4.33333C14.8856 4.33333 15.3333 3.88562 15.3333 3.33333H13.3333ZM1.67832 6.04249L6.34497 1.7348L4.98841 0.265198L0.321756 4.57289L1.67832 6.04249ZM2.48148 18H12.8519V16H2.48148V18ZM2.48148 16C2.31558 16 2.18452 15.9397 2.10288 15.8662C2.02293 15.7942 2 15.7224 2 15.6667L0 15.6667C-9.05991e-06 17.0518 1.2127 18 2.48148 18V16ZM12.8519 18C14.1206 18 15.3333 17.0518 15.3333 15.6667H13.3333C13.3333 15.7224 13.3104 15.7942 13.2305 15.8662C13.1488 15.9397 13.0177 16 12.8519 16V18ZM12.8519 2C13.0177 2 13.1488 2.06034 13.2305 2.13382C13.3104 2.20577 13.3333 2.27763 13.3333 2.33333H15.3333C15.3333 0.948171 14.1206 0 12.8519 0V2ZM5.66669 2H6.92591V0H5.66669V2ZM6.92591 2H12.8519V0H6.92591V2ZM3.73721e-05 5.30769L3.36766e-05 6.33333L2.00003 6.33334L2.00004 5.3077L3.73721e-05 5.30769ZM3.36766e-05 6.33333L0 15.6667L2 15.6667L2.00003 6.33334L3.36766e-05 6.33333ZM13.3333 2.33333V3.33333H15.3333V2.33333H13.3333Z"
      fill="#E61B23"
    />
    <path
      d="M6.50568 12H7.7358V10.1136H8.76989C10.108 10.1136 10.8864 9.31534 10.8864 8.15341C10.8864 6.99716 10.1222 6.18182 8.80114 6.18182H6.50568V12ZM7.7358 9.12784V7.1875H8.56534C9.27557 7.1875 9.61932 7.57386 9.61932 8.15341C9.61932 8.73011 9.27557 9.12784 8.57102 9.12784H7.7358ZM13.7479 12C15.5206 12 16.5945 10.9034 16.5945 9.08523C16.5945 7.27273 15.5206 6.18182 13.7649 6.18182H11.6854V12H13.7479ZM12.9155 10.946V7.2358H13.6996C14.7905 7.2358 15.3672 7.79261 15.3672 9.08523C15.3672 10.3835 14.7905 10.946 13.6967 10.946H12.9155ZM17.5057 12H18.7358V9.59659H21.1023V8.58239H18.7358V7.19602H21.358V6.18182H17.5057V12Z"
      fill="#E61B23"
    />
  </svg>
);

const renderWord = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00004 5.30769L0.321756 4.57289C0.116675 4.7622 3.83854e-05 5.02859 3.73721e-05 5.30769L1.00004 5.30769ZM5.66669 1V0C5.4153 0 5.17313 0.0946841 4.98841 0.265198L5.66669 1ZM15.3333 15.6667C15.3333 15.1144 14.8856 14.6667 14.3333 14.6667C13.781 14.6667 13.3333 15.1144 13.3333 15.6667H15.3333ZM1 15.6667L2 15.6667V15.6667L1 15.6667ZM1.00003 6.33333L2.00003 6.33334L1.00003 6.33333ZM13.3333 3.33333C13.3333 3.88562 13.781 4.33333 14.3333 4.33333C14.8856 4.33333 15.3333 3.88562 15.3333 3.33333H13.3333ZM1.67832 6.04249L6.34497 1.7348L4.98841 0.265198L0.321756 4.57289L1.67832 6.04249ZM2.48148 18H12.8519V16H2.48148V18ZM2.48148 16C2.31558 16 2.18452 15.9397 2.10288 15.8662C2.02293 15.7942 2 15.7224 2 15.6667L0 15.6667C-9.05991e-06 17.0518 1.2127 18 2.48148 18V16ZM12.8519 18C14.1206 18 15.3333 17.0518 15.3333 15.6667H13.3333C13.3333 15.7224 13.3104 15.7942 13.2305 15.8662C13.1488 15.9397 13.0177 16 12.8519 16V18ZM12.8519 2C13.0177 2 13.1488 2.06034 13.2305 2.13382C13.3104 2.20577 13.3333 2.27763 13.3333 2.33333H15.3333C15.3333 0.948171 14.1206 0 12.8519 0V2ZM5.66669 2H6.92591V0H5.66669V2ZM6.92591 2H12.8519V0H6.92591V2ZM3.73721e-05 5.30769L3.36766e-05 6.33333L2.00003 6.33334L2.00004 5.3077L3.73721e-05 5.30769ZM3.36766e-05 6.33333L0 15.6667L2 15.6667L2.00003 6.33334L3.36766e-05 6.33333ZM13.3333 2.33333V3.33333H15.3333V2.33333H13.3333Z"
      fill="#295394"
    />
    <path
      d="M10.8153 12H12.0142L13.1193 8.19602H13.1648L14.2727 12H15.4716L17.1364 6.18182H15.7926L14.8295 10.233H14.7784L13.7188 6.18182H12.5682L11.5057 10.2244H11.4574L10.4943 6.18182H9.15057L10.8153 12Z"
      fill="#295394"
    />
  </svg>
);

const renderIcon = (file: any) => {
  const iconType = file.name.split('.').reverse()[0];
  return (
    <Link
      rel="noreferrer"
      target="_blank"
      title={file.name || ''}
      key={file.id}
      href={getLink(file.thefile)}
    >
      {iconType === 'kmz' || iconType === 'kml' ? renderKmz() : null}
      {iconType === 'xlsx' || iconType === 'csv' ? renderSheet() : null}
      {iconType === 'pdf' ? renderPdf() : null}
      {iconType === 'doc' || iconType === 'docx' ? renderWord() : null}
    </Link>
  );
};

const LinkCell = ({ survey }: any) => {
  return (
    <Wrapper>{survey?.surveyfile_set?.map((s: any) => renderIcon(s))}</Wrapper>
  );
};

export default LinkCell;
