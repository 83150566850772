import {
  GtTable,
  GtTableBody,
  GtTableColumn,
  GtTableHead,
  GtTableHeadColumn,
  GtTableRow,
} from './usersTable.style';
import { columnsModel } from './userTable.model';

const renderColumns = ({ user, columns }: any) =>
  columns.map((column: any) => {
    if (!column.element) {
      return (
        <GtTableColumn
          width={column.width}
          key={`${column.field}-${user.id}`}
          ellipsis={column.ellipsis}
        >
          {column.formatter ? column.formatter(user) : user[column.field]}
        </GtTableColumn>
      );
    }
    return (
      <GtTableColumn width={column.width} key={`${column.field}-${user.id}`}>
        {column.element({
          user,
        })}
      </GtTableColumn>
    );
  });

/**
 *
 * @returns
 */
const UsersTable = ({ users, handleLineCLick }: any) => {
  return (
    <GtTable>
      <GtTableHead>
        <GtTableRow>
          {columnsModel().map((c: any) => (
            <GtTableHeadColumn width={c.width} key={c.field}>
              {c.headerName}
            </GtTableHeadColumn>
          ))}
        </GtTableRow>
      </GtTableHead>
      <GtTableBody>
        {users.map((usr: any) => (
          <GtTableRow onClick={() => handleLineCLick(usr)} key={usr.id}>
            {renderColumns({
              user: usr,
              columns: columnsModel(),
            })}
          </GtTableRow>
        ))}
      </GtTableBody>
    </GtTable>
  );
};

export default UsersTable;
