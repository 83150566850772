import styled from 'styled-components';

export const ProjectDevicesList = styled.ul<{ disabled: boolean; opened: boolean }>`
  display: grid;
  gap: 4px;

  margin-top: ${({ opened }) => (opened ? '8px' : '0px')};
  margin-bottom: ${({ opened }) => (opened ? '8px' : '0px')};

  height: ${({ opened }) => (opened ? 'auto' : '0px')};
  transition: height 0.3s ease-in-out;

  overflow: hidden;

  > p {
    text-align: center;
    padding: 8px;
    ${({ theme }) => theme.fonts.typograph.caption};
    color: ${({ theme }) => theme.colors.neutra_400};
  }

  > li {
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 8px;
  
    > span {
      color: ${({ theme }) => theme.colors.neutra_300};
      cursor: pointer;
      font-size: 18px;
    }
    
    &.text_highlight {
      rect {
        stroke-width: 3;
        stroke: ${({ theme }) => theme.colors.system_danger};
      }
      
      label {
        color: ${({ theme }) => theme.colors.system_danger};
        font-weight: 600;
      }
    }
    
    &.drag-over {
      rect {
        stroke-width: 3;
        stroke: ${({ theme }) => theme.colors.primary};
      }
      
      label {
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 600;
      }
    }
  }

  > li.add-device .toltip-message{
    left: 0px;
    bottom: 26px;
    height: auto;
    padding: 6px;
    font-size: 12px;  
    border-radius: 3px;

    &:before {
      left: 4px;
    }
    
  }

  > li.add-device > div > button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: ${({ theme }) => theme.colors.transparent};
    
    cursor: pointer;

    > p {
      color: ${({ theme }) => theme.colors.neutra_400};
    }

    &:disabled {
      cursor: not-allowed;
    }
    
    > span {
      width: 18px;
      height: 18px;
      
      font-size: 18px;
      color: ${({ theme, disabled }) => disabled ? theme.colors.neutra_500 : theme.colors.surface_white};
      background-color: ${({ theme, disabled }) => disabled ? theme.colors.neutra_100 : theme.colors.primary};
      font-weight: 900;
    }
  }
`;

export const ProjectDevicesContainer = styled.div`
  > p {
    font-weight: 600;
  }

  > header {
    display: grid;
    grid-template-columns: 1fr 20px;
    align-items: center;

    color: ${({ theme }) => theme.colors.neutra_600};
    cursor: pointer;
  }

  span.highlight {
    background-color: ${({ theme }) => theme.colors.primary_light_blue};
  }
`;