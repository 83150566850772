import styled from "styled-components";

export const ControlsComp = styled.div<{ $observerMode: boolean }>`
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 10px;

  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;
  gap: 4px;

  @media (min-width: 1024px) {
    ${({ $observerMode }) => ($observerMode ? 'right: 265px;' : '')}
  }
`;

export const ToggleControlsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  left: -1px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-bottom: 4px;

  box-shadow: 0px 6px 10px rgba(51, 57, 78, 0.2);

  > svg {
    position: relative;
    opacity: 0.7;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;
