import { changePassword } from '../../../../../services/user.service';
import { ErrorsType } from './security.d';
import { NotificationProviderValue } from 'app/modules/notification/notification.context.d';

/**
 *
 * @param setLoading
 * @param setErrors
 * @param currentPassword
 * @param newPassword
 * @param confirmPassword
 * @param token
 * @param dispatch
 */
export const submit = async (
  setLoading: (loading: boolean) => void,
  setErrors: (errors: ErrorsType) => void,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
  token: string,
  notificationDispatch: NotificationProviderValue['dispatch']
) => {
  setLoading(true);

  try {
    await changePassword(token, currentPassword, newPassword, confirmPassword);
  } catch (err: any) {
    if (err.response.data.type === 'account/not-authorized') {
      setErrors({ currentPassword: 'Password is invalid' });
      setLoading(false);
      return;
    }

    if (err.response.data.type === 'account/invalid-password') {
      setErrors({ newPassword: 'Password is invalid' });
      setLoading(false);
      return;
    }

    if (err.response.data.type === 'account/passwords-not-equals') {
      setErrors({
        confirmPassword: 'Passwords need to match',
      });
      setLoading(false);
      return;
    }

    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        text: 'Unexpected error occured',
      },
    });
    return;
  }

  setErrors({});
  setLoading(false);
  notificationDispatch({
    type: 'SET_TOAST',
    data: {
      type: 'success',
      text: 'Your password was changed',
    },
  });
};
