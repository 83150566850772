import { CSSProperties } from 'react';
import { MaterialIcon } from 'material-icons';
import { Button } from './filled-button.style';

interface Props {
  text?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  style?: CSSProperties,
  styleType?: 'primary' | 'secondary';
  disabled?: boolean;
  testId?: string;
  icon?: MaterialIcon;
}

/**
 * 
 * @param props 
 */
const FilledButton = ({
  text,
  onClick,
  styleType = 'primary',
  style = {},
  disabled = false,
  type = 'button',
  icon = undefined,
  testId,
}: Props) => {
  return (
    <Button
      type={type}
      disabled={!!disabled}
      style={style}
      onClick={onClick}
      data-testid={testId}
      $style={styleType}
    >
      {icon ? <span className="material-icons-outlined">{icon}</span> : null}
      {text}
    </Button>
  );
};

export default FilledButton;
