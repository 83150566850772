import styled from 'styled-components';

export const ScheduledActionWrapper = styled.div`
  position: relative;
`;

export const Dropdown = styled.ul<{ $hide: boolean }>`
  position: absolute;
  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
  top: calc(100% + 15px);
  right: 0;

  width: 120px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;
  z-index: 25;

  max-height: 300px;
  overflow-y: auto;

  > li {
    cursor: pointer;
    padding: 10px 20px;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.neutra_100};
    }
  }

  > li + li {
    border-top: solid 1px #dcdfe2;
  }
`;
