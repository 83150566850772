import { ParticipantType, UserType } from '../account/account.interfaces';
import { updateParticipant, updateUser } from './handlers/projects.particiant.handlers';
import { updateQube } from './handlers/projects.qube.handlers';
import {
  ProjectsContext,
  ProjectsDispatchType,
} from './projects.context.d';
import { QubeDeviceType } from '../qube/qube.interfaces';
import { initialProjectsState } from './project.state';

/**
 * 
 * @param state
 * @param action 
 * @returns account context state
 */
export const reducer = (
  state: ProjectsContext,
  action: ProjectsDispatchType
): ProjectsContext => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.data as ProjectsContext['loading'] };
    case 'SET_PROJECT':
      return { ...state, project: action.data as ProjectsContext['project'] };
    case 'RESET_PROJECT_PAGE':
      return { ...initialProjectsState  };
    case 'UPDATE_PARTICIPANT':
      return updateParticipant(state, action.data as ParticipantType);
    case 'UPDATE_USER':
      return updateUser(state, action.data as UserType);
    case 'UPDATE_QUBE':
      return updateQube(state, action.data as QubeDeviceType);

    default:
      throw new Error(`Unknown inspection reducer action ${action.type}`);
  }
};
