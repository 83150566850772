import axios from 'axios';

// import getEnv from '../../../env';

const API_URL = import.meta.env.VITE_API_URL || '/api';

export const saveSurvey = async ({
  survey,
  auth,
  setErrors,
  setIsLoading,
  dispatch,
  distanceUnit,
  setConfirmationModal,
  history,
  closeModal,
}: any) => {
  if (!survey.name) return setErrors({ name: 'Name is required' });
  if (!survey.kmls.length)
    return setErrors({ kmls: 'At least one kml/kmz is required' });

  setIsLoading(true);

  try {
    const data = new FormData();

    survey.kmls.map((kml: any) => {
      data.append(
        'medias',
        kml.file,
        `${kml.fileName}-${Date.now().toString()}.${kml.fileExt}`
      );
    });

    data.append('geoid_model', survey.geoidModel);
    data.append('gps_survey_accuracy', survey.gpsAccuracy);
    data.append('gps_datum', survey.gpsDatum);
    data.append('gps_equipment', survey.gpsEquipment);
    data.append('name', survey.name);
    data.append('owner', survey.owner);
    data.append('customer_id', auth.user.managed_customer.id);
    data.append('distance_unit', distanceUnit?.id || '');
    data.append('survey_company', survey.surveyorCompany);
    data.append('surveyor_phone', survey.surveyorPhone);
    data.append('pipeline_name', survey.pipelineName);
    try {
      const createdSurvey = await axios.post(`${API_URL}/surveys/`, data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Token ${auth.token}`,
        },
      });
      if (createdSurvey.data?.generation_error) {
        setConfirmationModal({
          hide: false,
          title: 'Uninterpretable Format:',
          text: 'Please verify that your files are in the correct formats, and check the documentation to confirm the supported files. The GlobalTrack team has already been notified of this issue and will get back to you. If urgent, contact globaltrack@pigging.com.',
          confirmationText: 'OK',
          hideCancelButton: true,
          onConfirm: () => setConfirmationModal({}),
        });
        setIsLoading(false);
        return;
      }
      history.push(`/surveys/${createdSurvey?.data?.survey?.id}`);
      setIsLoading(false);
      dispatch({
        type: 'SURVEY_POINTS',
        data: createdSurvey?.data?.survey?.surveypoint_set,
      });
      closeModal();
    } catch (err) {
      console.log([err]);
    }
  } catch (err) {
    setIsLoading(false);
  }
};
