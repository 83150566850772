import { useContext } from 'react';
import {
  GtTable,
  GtTableBody,
  GtTableColumn,
  GtTableHead,
  GtTableHeadColumn,
  GtTableRow,
} from './surveys-table.style';
import { columnsModel } from './surveys-table.model';
// context
import SettingsStore from 'app/modules/settings/settings.context';

/**
 *
 * @param param
 * @returns
 */
const renderColumns = ({ user, columns }: any) =>
  columns.map((column: any) => {
    if (!column.element) {
      return (
        <GtTableColumn
          alignTo={column.alignTo}
          width={column.width}
          key={`${column.field}-${user.id}`}
        >
          {column.formatter ? column.formatter(user) : user[column.field]}
        </GtTableColumn>
      );
    }
    return (
      <GtTableColumn width={column.width} key={`${column.field}-${user.id}`}>
        {column.element({
          user,
        })}
      </GtTableColumn>
    );
  });

/**
 *
 * @returns
 */
const SurveysTable = ({ surveys, handleLineCLick }: any) => {
  // contexts
  const settingsContext = useContext(SettingsStore);

  return (
    <GtTable>
      <GtTableHead>
        <GtTableRow>
          {columnsModel({ timezone: settingsContext.state.timezone?.id }).map((c: any) => (
            <GtTableHeadColumn width={c.width} key={c.field}>
              {c.headerName}
            </GtTableHeadColumn>
          ))}
        </GtTableRow>
      </GtTableHead>
      <GtTableBody>
        {surveys.map((usr: any) => (
          <GtTableRow onClick={() => handleLineCLick(usr)} key={usr.id}>
            {renderColumns({
              user: usr,
              columns: columnsModel({ timezone: settingsContext.state.timezone?.id }),
            })}
          </GtTableRow>
        ))}
      </GtTableBody>
    </GtTable>
  );
};

export default SurveysTable;
