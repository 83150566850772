import { useState, useContext, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import AccountStore from '../account.context';
import PublicWrapper from '../public-wrapper';
import GtLogo from './components/gt-logo/gt-logo';
import GtSolutions from './components/gt-solutions/gt-solutions';
import Typograph from 'stories/type/typograph/typograph';
import Input from 'stories/base/forms/input/input';
import PasswordInput from 'stories/base/forms/password-input/password-input';
import {
  Container,
  Form,
  ActionGroup,
  Banner,
  Image,
  FormWrapper,
  IlustrationWrapper,
  ForgotPasswordLink,
} from './login.page.style';
import { submitLogin, verifyAuth } from './login.controller';
import FilledButton from 'stories/base/buttons/filled-button/filled-button';

/**
 * Login component
 * @returns
 */
const Login = () => {
  // router
  const history = useHistory<RouteComponentProps['history']>();
  // context
  const accountContext = useContext(AccountStore);
  // states
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    const token: string | null = window.localStorage.getItem('CDI@auth-token');
    verifyAuth(token, history, setVerified, accountContext.dispatch);
  }, []);

  if (!verified) return null;

  return (
    <PublicWrapper>
      <Container data-testid="login">
        <Banner>
          <GtLogo />
          <GtSolutions />
          <IlustrationWrapper>
            <Image src="/assets/icons/login-image.svg" />
          </IlustrationWrapper>
        </Banner>
        <FormWrapper>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitLogin(e, { email, password, dispatch: accountContext.dispatch, setError, history });
            }}
          >
            <header>
              <Typograph text="Welcome back" type="headline6" />
            </header>
            
            <Input
              id="email"
              label="E-mail"
              error={error}
              errorTestId="login-email-error"
              placeholder="Your e-mail"
              value={email}
              onChange={({ target }: any) => setEmail(target.value)}
            />
            
            <ForgotPasswordLink
              onClick={(e) => {
                e.preventDefault();
                history.push('/forgot-password');
              }}
            >
              Forgot password?
            </ForgotPasswordLink>

            <PasswordInput
              id="password"
              label="Password"
              placeholder="Password"
              visibilityTestId="login-password-visibility"
              value={password}
              onChange={({ target }: any) => setPassword(target.value)}
            />

            <ActionGroup>
              <FilledButton text="Sign In" type="submit" />
            </ActionGroup>

            <footer>
              <Typograph type="caption">
                Any issues, contact us: <strong>globaltrack@pigging.com</strong>
              </Typograph>
            </footer>
          </Form>
        </FormWrapper>
      </Container>
    </PublicWrapper>
  );
};

export default Login;
