import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;

  border-bottom: 1px solid #f1f1f1;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const PointOverlay = styled.section`
  position: fixed;
  left: 0px;
  top: 0px;

  background-color: #fff;

  width: 100%;
  height: 100vh;
  overflow: auto;
  z-index: 10;

  padding-bottom: 24px;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  margin-right: 10px;
`;

export const Content = styled.div`
  position: relative;

  overflow: auto;

  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
`;

export const PicturesWrapper = styled.div`
  margin-top: 16px;
`;

export const Picture = styled.img`
  width: 150px;
  height: 150px;

  object-position: center;
  object-fit: contain;

  border: solid 1px rgba(0, 0, 0, 0.1);

  margin-right: 8px;

  cursor: pointer;
`;

export const PointTitle = styled.h2`
  font-weight: 500;
  display: block;
  font-size: 14px;
`;

export const GroupLabel = styled.label`
  font-weight: 500;
  display: block;
  font-size: 14px;

  margin-bottom: 16px;
`;

export const ActionsWrapper = styled.div`
  width: 100%;

  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PictureConteinar = styled.div`
  position: relative;
  display: inline-block;
`;

export const Icon = styled.svg`
  position: absolute;
  top: -9px;
  right: 0;

  background-color: #f1f1f1;
  border-radius: 100%;
  cursor: pointer;
`;

export const addMediaButtonStyle = `
  width: auto;
  display: inline-flex;

  position: relative;
  top: -6px;

  > svg {
    margin-right: 8px;
  }
`;

export const TrackingPointMedias = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  display: ${({ show }) => (show ? 'grid' : 'none')};
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  z-index: 3;
`;

export const TrackingPointFloatCloseButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 6px;

  position: absolute;
  top: 8px;
  right: 8px;

  background: rgba(62, 66, 85, 0.35);
  backdrop-filter: blur(10px);

  cursor: pointer;

  z-index: 1;

  svg {
    position: relative;
    top: 1px;
  }
`;

export const ArrowPrev = styled.div<{ hide: boolean }>`
  position: absolute;
  height: 100%;

  display: none;

  align-items: center;

  left: 1.5%;
  z-index: 3;

  svg {
    position: relative;
    left: -2px;
  }

  @media (min-width: 1024px) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
  }
`;

export const ArrowNext = styled.div<{ hide: boolean }>`
  position: absolute;
  height: 100%;

  display: none;
  align-items: center;

  top: 0;
  right: 1.5%;
  z-index: 3;

  svg {
    position: relative;
    right: -2px;

    transform: rotateY(160deg);
  }

  @media (min-width: 1024px) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
  }
`;

export const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  border-radius: 12px;

  background: rgba(255, 255, 255, 0.25);
`;

export const EmptyMediaWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const EmptyMediaMainText = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;

  color: #ffffff;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 32px;
`;

export const Group = styled.div``;

export const GroupList = styled.ul<{ columns?: number }>`
  display: grid;
  justify-content: start;
  gap: 8px 16px;

  ${({ columns }) => {
    if (columns && columns > 1) {
      let columnsText = '';

      for (let count = 0; count < columns; count += 1) {
        columnsText += '1fr ';
      }
      return `
        grid-template-columns: ${columnsText};
      `;
    }

    return '';
  }}
`;

export const GroupItem = styled.li`
  display: grid;
  grid-template-columns: auto 200px;
  align-items: center;
  gap: 40px;
`;

export const GroupItemLabel = styled.label`
  font-weight: 500;
  font-size: 13px;
  line-height: 1em;

  color: #4b5066;
`;

export const GroupItemInput = styled.input`
  padding: 3px 8px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;
  font-size: 13px;

  &:active {
    padding: 3px 8px;
    border: 1px solid #5850ec;
  }
  &:focus {
    padding: 3px 8px;
    border: 1px solid #5850ec;
  }
`;

export const MediasWrapper = styled.div`
  margin-top: 48px;
`;

export const IconSelectorWrapper = styled.div`
  height: 50px;
`;

export const PictureSketchLabel = styled.label`
  position: absolute;
  bottom: 0;
  display: inline;
  left: 0;
  right: 0;
  margin: auto;
  width: 78px;
  border-radius: 4px;
  font-size: 9px;
  padding: 1px 9px;
  background-color: rgba(82, 86, 103);
  color: #fff;
  font-weight: bold;
`;

export const SlimButton = styled.button`
  border: solid 1px #def0e4;
  color: #161e2e;
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: transparent;
  border-radius: 6px;

  padding: 11px 12px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1em;

  cursor: pointer;
`;
