import styled from 'styled-components';

export const Buttons = styled.div`
  width: 84px;

  background-color: #ffffff;
  border: 1px solid #dfe0e4;

  box-shadow: 0px 2px 2px rgba(29, 33, 57, 0.05);
  border-radius: 6px;

  overflow: hidden;
`;

export const Button = styled.button<{ selected: boolean }>`
  width: 40px;
  height: 40px;

  background-color: #ffffff;

  > svg {
    position: relative;
    top: 2px;
  }

  & + & {
    border-left: solid 1px #dfe0e4;
  }

  cursor: pointer;
`;
