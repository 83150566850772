import axios from 'axios';
import { ProjectType } from '../interfaces/inspection.interfaces';

const API_URL = import.meta.env.VITE_API_URL || '/api';

interface AvatarType {
  dataUrl: string;
  name: string;
  ext: string;
}

export interface PartipantType {
  project: ProjectType;
  admin: boolean;
  editor: boolean;
}

export interface UserType {
  id: string;
  phone?: string;
  country_code?: string;
  phone_number?: string;
  email: string;
  participant_set?: PartipantType[];
  first_name: string;
  online?: boolean;
  last_name?: string;
  timezone?: string;
  avatar?: string | AvatarType;
  location?: { coordinates: number[] };
}

/**
 *
 * @param dataURI
 * @returns
 */
const dataURItoBlob = (dataURI: string) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
};

/**
 * request to django api user edition
 * @param id run reference on django api
 * @returns promise that contains a run
 */
export const updateUser = (id: number, user: UserType, token: string) => {
  const data = new FormData();
  if (user.first_name) data.append('first_name', user.first_name);
  if (user.last_name) data.append('last_name', user.last_name);
  if (user.phone_number) data.append('phone_number', user.phone_number);
  if (user.country_code) data.append('country_code', user.country_code);

  const avatar = user.avatar as AvatarType;
  if (avatar?.dataUrl) {
    data.append(
      'avatar',
      dataURItoBlob(avatar.dataUrl),
      `${avatar.name}-${Date.now().toString()}.${avatar.ext}`
    );
  }

  return axios.patch(`${API_URL}/users/${id}/`, data, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 *
 * @param token
 * @returns
 */
export const fetchUsers = (token: string) => {
  return axios.get(`${API_URL}/users/`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 *
 * @param email
 * @param token
 * @returns
 */
export const getUserPredict = (email: string, token: string) => {
  return axios.get(`${API_URL}/users/${email}/predict/`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 *
 * @param token
 * @param currentPassword
 * @param newPassword
 * @param confirmPassword
 * @returns
 */
export const changePassword = (
  token: string,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
) =>
  axios.post(
    `${API_URL}/account/password/change`,
    {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
