import styled from 'styled-components';

export const RunStatusDropdownButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #5850ec;
  border-radius: 6px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  padding: 6px 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  text-transform: uppercase;

  color: #5850ec;
`;

export const RunStatusDropdownWrapper = styled.div`
  position: relative;
`;

export const RunStatusDropdown = styled.ul<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};

  top: calc(100% + 8px);
  position: absolute;
  padding: 8px;
  background-color: #ffffff;
  border-radius: 6px;

  width: 100px;
`;

export const RunStatusDropdownItem = styled.li`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;

  color: #4b5066;
`;

export const RunStatusTimeButton = styled.button`
  background-color: #5850ec;
  border-radius: 6px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  text-transform: uppercase;

  padding: 6px 8px;

  color: #ffffff;
`;

export const RunStatusWrapper = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  left: 8px;
  top: 8px;

  z-index: 20;
`;
