import {
  requestPasswordChangeEmail,
  resetPassword,
} from 'app/services/auth.service';
import { ErrorType } from './forgot-password.page.d';

export const sendEmail = async (
  email: string,
  navigate: () => void,
  setError: (error: ErrorType) => void
) => {
  try {
    await requestPasswordChangeEmail(email);
  } catch (err: any) {
    if (err.response.status === 401) {
      setError({ email: "This account isn't registered." });
      return;
    }
  }
  navigate();
};

export const changePassword = async (
  token: string,
  password: string,
  navigate: () => void
) => {
  if (!token) return;
  await resetPassword(token, password);
  navigate();
};
