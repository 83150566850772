import { useState, useContext, useEffect } from 'react';
import moment from 'moment-timezone';
import { SheetContainer } from './survey-sheet.style';
import Table from '../slick-table/slick-table';
import { getColumns } from '../../models/sheet/sheet';
import {
  getEvents,
  getMapBounds,
} from './survey-sheet.controller';
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
import SurveyStore from 'app/modules/survey/survey.context';
import { getCleanSurvey } from '../common/map/modules/survey/survey.dummy';
import { CustomCulumn } from '../slick-table/slick-table.interfaces';
import { SurveyPointType } from 'app/modules/survey/survey.interfaces';

const SurveyTrackingSheet = ({
  setBounds,
  setGrid,
  grid,
  metadata,
}: any) => {
  const accountContext = useContext(AccountStore);
  const settingsContext = useContext(SettingsStore);
  const surveyContext = useContext(SurveyStore);
  const [columns, setColumns] = useState<Slick.Column<any>[]>([]);
  const handleSelectPoint = ({ point, nextPoint, prevPoint }) => {
    const bounds = getMapBounds(point, nextPoint, prevPoint);
    setBounds(bounds);
    surveyContext.dispatch({
      type: 'SET_SELECTED_POINT',
      data: point?.id
    });
  };

  useEffect(() => {
    if (!accountContext.state.auth?.user) return;
    const nColumns = getColumns(
      {
        speed: settingsContext.state.speedUnit,
        distance: settingsContext.state.distanceUnit,
      },
      true,
      undefined,
      undefined,
      getEvents(surveyContext.dispatch, accountContext.state.auth?.token, handleSelectPoint)
    );
    setColumns(nColumns);
  }, [accountContext.state.auth?.token, surveyContext.state.selected_point]);

  useEffect(() => {
    if (grid) {
      grid?.resizeCanvas();
    }
  }, [surveyContext.state.survey?.surveypoint_set?.length, grid]);

  useEffect(() => {
    return () => {
      surveyContext.dispatch({
        type: 'SET_SURVEY',
        data: getCleanSurvey(),
      });
    }
  }, []);

  return (
    <SheetContainer>
      {surveyContext.state.survey?.surveypoint_set?.length ? (
        <Table<SurveyPointType>
          permissionType={'editor'}
          grid={grid}
          columns={columns as CustomCulumn[]}
          data={surveyContext.state.survey?.surveypoint_set || []}
          onLoad={setGrid}
          metadata={metadata}
          options={{
            editable: true,
          }}
        />
      ) : null}
    </SheetContainer>
  );
};

SurveyTrackingSheet.defaultProps = {
  distanceUnit: 'ft',
  speedUnit: 'mph',
  timezone: moment?.tz?.guess(),
};

export default SurveyTrackingSheet;
