import InspectionStore from "app/modules/inspection/inspection.context";
import Card from "../../../../../../../components/qube-item/qube-item";
import {
  QubeClusterModalWrapper,
  QubeClusterModalHeader,
  QubeClusterModalGoBackButton,
  QubeClusterModalTitle,
  QubeClusterModalList,
} from "./mobile-qube-cluster-modal.style"
import { useContext } from "react";

/**
 * 
 * @returns 
 */
const MobileQubeClusterModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const markers = inspectionContext.state.mobile.selected_cluster_markers;
  const handleOnClose = () => {
    inspectionContext.dispatch({
      type: 'SET_SELECTED_MOBILE_CLUSTER_MARKERS',
      data: [],
    });
  };

  /**
   * 
   */
  const handleClick = (qube) => {
    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_QUBE',
      data: qube,
    });
  };

  return (
    <QubeClusterModalWrapper>
      <QubeClusterModalHeader>
        <div>
          <QubeClusterModalGoBackButton onClick={handleOnClose}>
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 9.67969L9 18.6797L10.4 17.1797L3 9.67969L10.4 2.17969L9 0.679688L0 9.67969Z" fill="#323030"/>
            </svg>
          </QubeClusterModalGoBackButton>
          <QubeClusterModalTitle>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2 13.1C16.2 13.404 16.032 13.668 15.776 13.804L9.45599 17.356C9.32799 17.452 9.16799 17.5 8.99999 17.5C8.83199 17.5 8.67199 17.452 8.54399 17.356L2.22399 13.804C2.09573 13.7366 1.9884 13.6353 1.91365 13.5112C1.8389 13.3871 1.79959 13.2449 1.79999 13.1V5.9C1.79999 5.596 1.96799 5.332 2.22399 5.196L8.54399 1.644C8.67199 1.548 8.83199 1.5 8.99999 1.5C9.16799 1.5 9.32799 1.548 9.45599 1.644L15.776 5.196C16.032 5.332 16.2 5.596 16.2 5.9V13.1ZM8.99999 3.22L4.23199 5.9L8.99999 8.58L13.768 5.9L8.99999 3.22ZM3.39999 12.628L8.19999 15.332V9.964L3.39999 7.268V12.628ZM14.6 12.628V7.268L9.79999 9.964V15.332L14.6 12.628Z" fill="#D30000"/>
            </svg>
            You have <strong>{markers.length} devices</strong> here
          </QubeClusterModalTitle>
        </div>
      </QubeClusterModalHeader>
      <QubeClusterModalList>
        {markers.map((marker) => (
          <Card qube={marker.data.qube} key={marker.data.qube.serial} onClick={() => handleClick(marker.data.qube)} />
        ))}
      </QubeClusterModalList>
    </QubeClusterModalWrapper>
  )
};

export default MobileQubeClusterModal;
