import { fetchUsers } from 'app/services/user.service';

export const getAllUsers = async ({ setAllUsers, setUsers, auth }: any) => {
  const userPeopleResponse = await fetchUsers(auth.token);

  setUsers(userPeopleResponse.data);
  setAllUsers(userPeopleResponse.data);
};

export const filterUserBySearch = ({ allUsers, search, setUsers }: any) => {
  if (allUsers.length && search) {
    const filteredUsers = allUsers.filter((usr: any) => {
      const reg = new RegExp(`${search}`, 'gi');

      if (reg.test(usr.first_name)) return true;
      if (reg.test(usr.last_name)) return true;
      if (reg.test(usr.phone)) return true;
      if (reg.test(usr.email)) return true;
      if (usr.projects?.find((p: any) => reg.test(p))) return true;

      return false;
    });

    setUsers(filteredUsers);
  } else if (!search) {
    setUsers(allUsers);
  }
};
