import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;

  overflow: hidden;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
`;

export const Button = styled.div<{ $active: boolean }>`
  background-color: ${({ $active }) =>
    $active ? 'rgba(80, 88, 236, 0.45)' : 'rgba(255, 255, 255, 0.45)'};
  backdrop-filter: blur(10px);

  padding: 5px;
  cursor: pointer;

  transition-duration: 400ms;

  &:hover {
    background-color: ${({ $active }) =>
      $active ? 'rgba(80, 88, 236, 0.35)' : 'rgba(255, 255, 255, 0.35)'};
  }
`;
