import styled from 'styled-components';

export const CardSerial = styled.h4`
  ${({ theme }) => theme.fonts.typograph.headline2};
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;
  color: #090808;
`;

export const CardWrapper = styled.li`
  cursor: pointer;
  padding: 8px 16px;

  display: grid;
  grid-template-columns: 60px 1fr 60px 16px;
  align-items: center;
  gap: 4px;

  border-bottom: solid 1px #ececec;
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CardInfoSignal = styled.span<{ $checked: boolean }>`
  width: 16px;
  height: 16px;

  border-radius: 50%;
  border: solid 1px ${({ $checked }) => ($checked ? '#308c04' : '#4d5158')}; 
  background-color: ${({ $checked }) => ($checked ? '#56f000' : '#a4abb6')};
`;

export const CardInfoLabel = styled.label`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  color: #090808;
`;

export const CardBattery = styled.div`
  background-color: #090808;
  padding: 4px 8px;

  border-radius: 42px;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-family: 'Inter';
    color: #ffffff;
    font-size: 11.7px;
    font-weight: 700;
  }
`;
