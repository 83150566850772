import styled from 'styled-components';

export const Header = styled.header<{ opened: boolean; hidden: boolean }>`
  display: none;
  position: relative;

  z-index: 2;
  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);

  gap: 16px;

  width: 100%;

  align-items: center;
  justify-content: space-between;

  transform: translateY(${({ opened }) => (!opened ? '-50px' : '0')});
  height: ${({ opened }) => (!opened ? '0px' : 'auto')};
  padding: ${({ opened }) => (!opened ? '0' : '10px 15px')};

  will-change: transform, height;
  transition-property: transform, height;
  transition-duration: 0ms;

  > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  > button {
    bottom: ${({ opened }) => (opened ? '-42px' : '-92px')};
  }

  @media (min-width: 1024px) {
    display: flex;
    ${({ hidden }) => (hidden ? 'display: none;' : '')}
  }
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`;

export const RunName = styled.h1`
  display: inline-block;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #ffffff;
`;

export const ToggleButton = styled.button<{ hidden: boolean }>`
  position: absolute;
  right: 10px;

  width: 35px;
  height: 35px;

  background: rgba(62, 66, 85, 0.45);
  backdrop-filter: blur(10px);

  border-radius: 6px;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  cursor: pointer;
  z-index: 10;

  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`;

export const DocumentationButton = styled.button`
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);

  border-radius: 100%;
  color: #fff;
  font-size: 17px;
  line-height: 1em;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  cursor: pointer;

  > svg {
    position: relative;
    top: -1px;
  }
`;
