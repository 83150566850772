export const NewProjectIlustration = () => (
  <svg
    width="590"
    height="485"
    viewBox="0 0 590 485"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="130.338"
      width="577"
      height="325.198"
      rx="10"
      fill="#5850EC"
    />
    <g filter="url(#filter0_d_1018_345)">
      <path
        d="M60.7168 41.7461C60.7168 39.537 62.5077 37.7461 64.7168 37.7461H516.284C518.493 37.7461 520.284 39.537 520.284 41.7461V390.56C520.284 392.769 518.493 394.56 516.284 394.56H64.7168C62.5077 394.56 60.7168 392.769 60.7168 390.56V41.7461Z"
        fill="white"
      />
    </g>
    <path
      d="M60.7168 41.7461C60.7168 39.537 62.5077 37.7461 64.7168 37.7461H516.284C518.493 37.7461 520.284 39.537 520.284 41.7461V53.5543H60.7168V41.7461Z"
      fill="url(#paint0_linear_1018_345)"
    />
    <circle cx="69.7508" cy="45.6492" r="3.38748" fill="#C4C4C4" />
    <circle cx="79.9129" cy="45.6492" r="3.38748" fill="#C4C4C4" />
    <circle cx="90.075" cy="45.6492" r="3.38748" fill="#C4C4C4" />
    <rect
      x="91.2031"
      y="85.1699"
      width="159.211"
      height="25.9706"
      rx="4"
      fill="#EBF0F3"
    />
    <rect
      x="91.2031"
      y="119.045"
      width="88.0744"
      height="25.9706"
      rx="4"
      fill="#EBF0F3"
    />
    <rect
      x="60.7168"
      y="172.115"
      width="459.568"
      height="222.444"
      fill="#EBF0F3"
    />
    <rect
      x="103.625"
      y="210.508"
      width="373.751"
      height="145.661"
      fill="white"
    />
    <rect
      x="129.596"
      y="230.832"
      width="210.023"
      height="9.03327"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="129.596"
      y="285.031"
      width="210.023"
      height="4.51663"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="129.596"
      y="297.451"
      width="210.023"
      height="4.51663"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="129.596"
      y="309.873"
      width="210.023"
      height="4.51663"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="129.596"
      y="322.293"
      width="210.023"
      height="4.51663"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="129.596"
      y="247.77"
      width="117.432"
      height="9.03327"
      rx="2"
      fill="#EBF0F3"
    />
    <g filter="url(#filter1_d_1018_345)">
      <path
        d="M503.911 137.112C540.393 137.112 569.967 107.537 569.967 71.0558C569.967 34.5742 540.393 5 503.911 5C467.43 5 437.855 34.5742 437.855 71.0558C437.855 107.537 467.43 137.112 503.911 137.112Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter2_i_1018_345)">
      <rect
        x="500.523"
        y="43.3906"
        width="6.77495"
        height="55.3288"
        fill="url(#paint1_linear_1018_345)"
      />
      <rect
        x="531.574"
        y="67.668"
        width="6.77495"
        height="55.3288"
        transform="rotate(90 531.574 67.668)"
        fill="url(#paint2_linear_1018_345)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1018_345"
        x="0.716797"
        y="7.74609"
        width="579.568"
        height="476.814"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      ><svg
        width="590"
        height="485"
        viewBox="0 0 590 485"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <rect
            x="2"
            y="130.338"
            width="577"
            height="325.198"
            rx="10"
            fill="#5850EC"
          />
          <g filter="url(#filter0_d_1018_345)">
            <path
              d="M60.7168 41.7461C60.7168 39.537 62.5077 37.7461 64.7168 37.7461H516.284C518.493 37.7461 520.284 39.537 520.284 41.7461V390.56C520.284 392.769 518.493 394.56 516.284 394.56H64.7168C62.5077 394.56 60.7168 392.769 60.7168 390.56V41.7461Z"
              fill="white"
            />
          </g>
          <path
            d="M60.7168 41.7461C60.7168 39.537 62.5077 37.7461 64.7168 37.7461H516.284C518.493 37.7461 520.284 39.537 520.284 41.7461V53.5543H60.7168V41.7461Z"
            fill="url(#paint0_linear_1018_345)"
          />
          <circle cx="69.7508" cy="45.6492" r="3.38748" fill="#C4C4C4" />
          <circle cx="79.9129" cy="45.6492" r="3.38748" fill="#C4C4C4" />
          <circle cx="90.075" cy="45.6492" r="3.38748" fill="#C4C4C4" />
          <rect
            x="91.2031"
            y="85.1699"
            width="159.211"
            height="25.9706"
            rx="4"
            fill="#EBF0F3"
          />
          <rect
            x="91.2031"
            y="119.045"
            width="88.0744"
            height="25.9706"
            rx="4"
            fill="#EBF0F3"
          />
          <rect
            x="60.7168"
            y="172.115"
            width="459.568"
            height="222.444"
            fill="#EBF0F3"
          />
          <rect
            x="103.625"
            y="210.508"
            width="373.751"
            height="145.661"
            fill="white"
          />
          <rect
            x="129.596"
            y="230.832"
            width="210.023"
            height="9.03327"
            rx="2"
            fill="#EBF0F3"
          />
          <rect
            x="129.596"
            y="285.031"
            width="210.023"
            height="4.51663"
            rx="2"
            fill="#EBF0F3"
          />
          <rect
            x="129.596"
            y="297.451"
            width="210.023"
            height="4.51663"
            rx="2"
            fill="#EBF0F3"
          />
          <rect
            x="129.596"
            y="309.873"
            width="210.023"
            height="4.51663"
            rx="2"
            fill="#EBF0F3"
          />
          <rect
            x="129.596"
            y="322.293"
            width="210.023"
            height="4.51663"
            rx="2"
            fill="#EBF0F3"
          />
          <rect
            x="129.596"
            y="247.77"
            width="117.432"
            height="9.03327"
            rx="2"
            fill="#EBF0F3"
          />
          <g filter="url(#filter1_d_1018_345)">
            <path
              d="M503.911 137.112C540.393 137.112 569.967 107.537 569.967 71.0558C569.967 34.5742 540.393 5 503.911 5C467.43 5 437.855 34.5742 437.855 71.0558C437.855 107.537 467.43 137.112 503.911 137.112Z"
              fill="white"
            />
          </g>
          <g filter="url(#filter2_i_1018_345)">
            <rect
              x="500.523"
              y="43.3906"
              width="6.77495"
              height="55.3288"
              fill="url(#paint1_linear_1018_345)"
            />
            <rect
              x="531.574"
              y="67.668"
              width="6.77495"
              height="55.3288"
              transform="rotate(90 531.574 67.668)"
              fill="url(#paint2_linear_1018_345)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1018_345"
              x="0.716797"
              y="7.74609"
              width="579.568"
              height="476.814"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            ><svg
              width="590"
              height="485"
              viewBox="0 0 590 485"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                  x="2"
                  y="130.338"
                  width="577"
                  height="325.198"
                  rx="10"
                  fill="#5850EC"
                />
                <g filter="url(#filter0_d_1018_345)">
                  <path
                    d="M60.7168 41.7461C60.7168 39.537 62.5077 37.7461 64.7168 37.7461H516.284C518.493 37.7461 520.284 39.537 520.284 41.7461V390.56C520.284 392.769 518.493 394.56 516.284 394.56H64.7168C62.5077 394.56 60.7168 392.769 60.7168 390.56V41.7461Z"
                    fill="white"
                  />
                </g>
                <path
                  d="M60.7168 41.7461C60.7168 39.537 62.5077 37.7461 64.7168 37.7461H516.284C518.493 37.7461 520.284 39.537 520.284 41.7461V53.5543H60.7168V41.7461Z"
                  fill="url(#paint0_linear_1018_345)"
                />
                <circle cx="69.7508" cy="45.6492" r="3.38748" fill="#C4C4C4" />
                <circle cx="79.9129" cy="45.6492" r="3.38748" fill="#C4C4C4" />
                <circle cx="90.075" cy="45.6492" r="3.38748" fill="#C4C4C4" />
                <rect
                  x="91.2031"
                  y="85.1699"
                  width="159.211"
                  height="25.9706"
                  rx="4"
                  fill="#EBF0F3"
                />
                <rect
                  x="91.2031"
                  y="119.045"
                  width="88.0744"
                  height="25.9706"
                  rx="4"
                  fill="#EBF0F3"
                />
                <rect
                  x="60.7168"
                  y="172.115"
                  width="459.568"
                  height="222.444"
                  fill="#EBF0F3"
                />
                <rect
                  x="103.625"
                  y="210.508"
                  width="373.751"
                  height="145.661"
                  fill="white"
                />
                <rect
                  x="129.596"
                  y="230.832"
                  width="210.023"
                  height="9.03327"
                  rx="2"
                  fill="#EBF0F3"
                />
                <rect
                  x="129.596"
                  y="285.031"
                  width="210.023"
                  height="4.51663"
                  rx="2"
                  fill="#EBF0F3"
                />
                <rect
                  x="129.596"
                  y="297.451"
                  width="210.023"
                  height="4.51663"
                  rx="2"
                  fill="#EBF0F3"
                />
                <rect
                  x="129.596"
                  y="309.873"
                  width="210.023"
                  height="4.51663"
                  rx="2"
                  fill="#EBF0F3"
                />
                <rect
                  x="129.596"
                  y="322.293"
                  width="210.023"
                  height="4.51663"
                  rx="2"
                  fill="#EBF0F3"
                />
                <rect
                  x="129.596"
                  y="247.77"
                  width="117.432"
                  height="9.03327"
                  rx="2"
                  fill="#EBF0F3"
                />
                <g filter="url(#filter1_d_1018_345)">
                  <path
                    d="M503.911 137.112C540.393 137.112 569.967 107.537 569.967 71.0558C569.967 34.5742 540.393 5 503.911 5C467.43 5 437.855 34.5742 437.855 71.0558C437.855 107.537 467.43 137.112 503.911 137.112Z"
                    fill="white"
                  />
                </g>
                <g filter="url(#filter2_i_1018_345)">
                  <rect
                    x="500.523"
                    y="43.3906"
                    width="6.77495"
                    height="55.3288"
                    fill="url(#paint1_linear_1018_345)"
                  />
                  <rect
                    x="531.574"
                    y="67.668"
                    width="6.77495"
                    height="55.3288"
                    transform="rotate(90 531.574 67.668)"
                    fill="url(#paint2_linear_1018_345)"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_1018_345"
                    x="0.716797"
                    y="7.74609"
                    width="579.568"
                    height="476.814"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="30" />
                    <feGaussianBlur stdDeviation="30" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1018_345"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1018_345"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter1_d_1018_345"
                    x="417.855"
                    y="0"
                    width="172.111"
                    height="172.111"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="15" />
                    <feGaussianBlur stdDeviation="10" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1018_345"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1018_345"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter2_i_1018_345"
                    x="476.246"
                    y="43.3906"
                    width="55.3281"
                    height="56.3281"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0744445 0 0 0 0 0.0803333 0 0 0 0 0.133333 0 0 0 0.15 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_1018_345"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_1018_345"
                    x1="290.501"
                    y1="37.7461"
                    x2="290.501"
                    y2="53.5543"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F5F5F5" />
                    <stop offset="1" stopColor="#E6E7EA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1018_345"
                    x1="503.911"
                    y1="43.3906"
                    x2="503.911"
                    y2="98.7194"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#A3D8F7" />
                    <stop offset="0.078125" stopColor="#9FD6F7" />
                    <stop offset="1" stopColor="#73BCF9" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1018_345"
                    x1="534.962"
                    y1="67.668"
                    x2="534.962"
                    y2="122.997"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#A3D8F7" />
                    <stop offset="0.078125" stopColor="#9FD6F7" />
                    <stop offset="1" stopColor="#73BCF9" />
                  </linearGradient>
                </defs>
              </svg>
              <feOffset dy="30" />
              <feGaussianBlur stdDeviation="30" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1018_345"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1018_345"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_1018_345"
              x="417.855"
              y="0"
              width="172.111"
              height="172.111"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="15" />
              <feGaussianBlur stdDeviation="10" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1018_345"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1018_345"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_i_1018_345"
              x="476.246"
              y="43.3906"
              width="55.3281"
              height="56.3281"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0744445 0 0 0 0 0.0803333 0 0 0 0 0.133333 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_1018_345"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1018_345"
              x1="290.501"
              y1="37.7461"
              x2="290.501"
              y2="53.5543"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F5F5F5" />
              <stop offset="1" stopColor="#E6E7EA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1018_345"
              x1="503.911"
              y1="43.3906"
              x2="503.911"
              y2="98.7194"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A3D8F7" />
              <stop offset="0.078125" stopColor="#9FD6F7" />
              <stop offset="1" stopColor="#73BCF9" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1018_345"
              x1="534.962"
              y1="67.668"
              x2="534.962"
              y2="122.997"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A3D8F7" />
              <stop offset="0.078125" stopColor="#9FD6F7" />
              <stop offset="1" stopColor="#73BCF9" />
            </linearGradient>
          </defs>
        </svg>
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="30" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1018_345"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1018_345"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1018_345"
        x="417.855"
        y="0"
        width="172.111"
        height="172.111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="15" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1018_345"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1018_345"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_i_1018_345"
        x="476.246"
        y="43.3906"
        width="55.3281"
        height="56.3281"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0744445 0 0 0 0 0.0803333 0 0 0 0 0.133333 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1018_345"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1018_345"
        x1="290.501"
        y1="37.7461"
        x2="290.501"
        y2="53.5543"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset="1" stopColor="#E6E7EA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1018_345"
        x1="503.911"
        y1="43.3906"
        x2="503.911"
        y2="98.7194"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3D8F7" />
        <stop offset="0.078125" stopColor="#9FD6F7" />
        <stop offset="1" stopColor="#73BCF9" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1018_345"
        x1="534.962"
        y1="67.668"
        x2="534.962"
        y2="122.997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3D8F7" />
        <stop offset="0.078125" stopColor="#9FD6F7" />
        <stop offset="1" stopColor="#73BCF9" />
      </linearGradient>
    </defs>
  </svg>
);
