import { useContext, useMemo, useState } from "react";
import InspectionStore from "app/modules/inspection/inspection.context";
import { ScheduledActionWrapper, Dropdown } from "./launched-state.style";

/**
 * 
 * @param props 
 * @returns 
 */
const LaunchedState = () => {
  const inspectionContext = useContext(InspectionStore);
  const [hide, setHide] = useState<boolean>(true);
  
  const list = useMemo(() => {
    const abort = {
      id: 'abort',
      label: 'Abort Run',
      onClick: () => {
        inspectionContext.dispatch({ type: 'TOGGLE_MODAL', data: 'aborting' });
      }
    };

    const finish = {
      id: 'finish',
      label: 'Finish Run',
      onClick: () => {
        inspectionContext.dispatch({ type: 'TOGGLE_MODAL', data: 'finishing' });
      }
    };

    const list = [finish];
    if (inspectionContext.run?.estimation?.can_be_aborted) list.push(abort);
    return list.reverse();
  }, [inspectionContext.run?.estimation?.can_be_aborted]);

  /**
   * 
   */
  const handleClick = () => {
    setHide(!hide);
  };
  
  return (
    <ScheduledActionWrapper>
      <button
        data-testid="scheduled-action-button"
        disabled={inspectionContext.state.permission_type !== 'editor'}
        onClick={handleClick}
      >
        Active
      </button>

      <Dropdown $hide={hide} data-testid="scheduled-action-list">
        {
          list.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                setHide(true);
                item.onClick()
              }}
            >
              {item.label}
            </li>
          ))
        }
      </Dropdown>
    </ScheduledActionWrapper>
  );
};

export default LaunchedState;
