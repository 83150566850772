import { Button } from './labels.style';

const Labels = ({
  onClick,
  active,
}: {
  onClick: () => void;
  active: boolean;
}) => (
  <Button
    title="Active Tracking Point Labels"
    onClick={onClick}
    $active={active}
  >
    {active ? (
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
        <path d="M8.708 16.667V5.917H4.167V3.333H15.833V5.917H11.292V16.667Z" />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
        <path d="M11.062 8.646 9.167 6.75 6.583 4.167H16.667V6.75H11.854ZM16.438 18.833 9.604 12 7.958 15.833H5.146L7.625 10L1.167 3.562L2.396 2.333L17.667 17.604Z" />
      </svg>
    )}
  </Button>
);

export default Labels;
