import styled from 'styled-components';

export const DeviceModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;

  width: 100%;
  height: 100vh;
  background-color: #ffffff;

  display: grid;
  grid-template-rows: auto auto auto 1fr;
  align-items: start;
  gap: 16px;
`;

export const DeviceHeader = styled.header`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f0f2f3;
  height: 42px;

  padding-left: 16px;
  padding-right: 16px;
`;

export const DeviceCustomerDeviceTablewrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const DeviceModalText = styled.p`
  text-align: center;

  color: #656978;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 16px;
`;

export const DeviceHeaderSearch = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  align-items: end;

  padding: 16px;  
`;

export const DeviceHeaderActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
`;

export const DeviceCloseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
`;

