import styled from 'styled-components';

export const BreadcrumbsWrapper = styled.div<{ customStyle?: string }>`
  background-color: #f0f2f3;
  height: 42px;

  z-index: 21;

  display: grid;
  grid-template-columns: 1fr 30px;

  padding-right: 8px;
  justify-content: space-between;
  gap: 8px;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr auto;
  }

  ${({ customStyle }) => customStyle}
`;

export const BreadcrumbList = styled.ul`
  display: flex;
  align-items: center;
`;

export const BreadcrumbItem = styled.li`
  width: auto;
  max-width: 140px;

  > a {
    width: 100%;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {
    max-width: 500px;
  }

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  > a {
    color: #4b5066;
  }

  &:last-child {
    background-color: #fff;
    border-bottom: 1px solid #f0f2f3;

    > a {
      color: #5850ec;
    }
  }

  & + & {
    &:before {
      /* content: '/'; */
      display: inline-block;

      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      color: #4b5066;

      margin-right: 12px;
    }
  }

  @media (max-width: 374px) {
    max-width: 120px;

    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      &:before {
        content: none;
        display: none;
      }
    }
  }
`;

export const LogoutButton = styled.button`
  background-color: transparent;
  color: #4b5066;

  transition-duration: 400ms;
  opacity: 0.5;

  &:hover {
    color: #5850ec;
    opacity: 1;
  }

  cursor: pointer;
`;

export const EmailLabel = styled.label`
  color: #686c7b;
  padding-right: 24px;
  font-size: 15px;
`;

export const MobileDropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Button = styled.button`
  position: relative;
  top: 1px;

  background: transparent;
  backdrop-filter: blur(10px);

  cursor: pointer;
`;

export const DropdownList = styled.ul<{ hide: boolean }>`
  position: absolute;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  top: calc(100% + 15px);
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  right: 0;

  padding: 12px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;
  z-index: 25;

  overflow-y: auto;

  > li + li {
    border-top: solid 1px #dcdfe2;
  }
`;
