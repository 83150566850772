import { NotificationProviderValue } from 'app/modules/notification/notification.context.d';
import { UserType, updateUser } from '../../../../../services/user.service';
import { AccountProviderValue } from 'app/modules/account/account.context.d';

export const submit = async (
  id: number,
  user: UserType,
  auth: any,
  setLoading: (loading: boolean) => void,
  dispatch: AccountProviderValue['dispatch'],
  notificationDispatch: NotificationProviderValue['dispatch']
) => {
  setLoading(true);

  const userResponse = await updateUser(id, user, auth.token);

  const authCopy = { ...auth };
  authCopy.user = { ...authCopy.user, ...userResponse.data };

  dispatch({
    type: 'LOGIN',
    data: authCopy,
  });

  dispatch({
    type: 'CLOSE_PROFILE_MODAL',
  });

  notificationDispatch({
    type: 'SET_TOAST',
    data: {
      title: 'Profile updated!',
      type: 'success',
    },
  });

  setLoading(false);
};
