import {
  SurveyFileSetType,
  SurveyPointType,
  SurveyType
} from 'app/modules/survey/survey.interfaces.d';
import { SurveyContext } from '../survey.context.d';

/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const serializeSurvey = (state: SurveyContext, survey: SurveyType): SurveyContext => {
  const stateCopy = { ...state };
  const pointSet: number[] = [];
  const pointDic = {};
  const surveyFileSet: number[] = [];
  const surveyFileDic = {};
  
  survey.surveyfile_set.forEach((file: SurveyFileSetType) => {
    surveyFileSet.push(file.id);
    surveyFileDic[file.id] = file;
  });

  survey.surveypoint_set.forEach((point: SurveyPointType) => {
    pointSet.push(point.id);
    pointDic[point.id] = point;
  });

  stateCopy.points_dic = pointDic;
  stateCopy.files_dic = surveyFileDic;
  stateCopy.survey = survey;
  stateCopy.pipeline = survey.pipeline;


  return stateCopy as SurveyContext;
}
