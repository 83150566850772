import {
  CustomerContext,
  CustomerDispatchType,
} from './customer.context.d';

/**
 * 
 * @param state
 * @param action 
 * @returns account context state
 */
export const reducer = (
  state: CustomerContext,
  action: CustomerDispatchType,
): CustomerContext => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.data as CustomerContext['loading'] };

    default:
      throw new Error(`Unknown inspection reducer action ${action.type}`);
  }
};
