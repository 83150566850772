import { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import VMasker from 'vanilla-masker';
import { formatMps, formatTimezoneDate } from '@/lib/formatter';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import NotificationStore from 'app/modules/notification/notification.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import DefaultButton from 'app/components/default-button/default-button';
import SecondaryButton from 'app/components/secondary-button/secondary-button';
import { updateScheduledRun } from './mobile-schedule-modal.controller';
import Input from 'stories/base/forms/input/input';
import {
  MobileScheduleModalContent,
  MobileScheduleModalContentCloseButton,
  MobileScheduleModalContentForm,
  MobileScheduleModalContentFormFooter,
  MobileScheduleModalContentHeader,
  MobileScheduleModalContentTitle,
  MobileScheduleModalWrapper,
} from './mobile-schedule-modal.style';
import DateInput from 'stories/base/forms/date-input/date-input';

/**
 *
 */
const MobileScheduleModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const notificationContext = useContext(NotificationStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  const [scheduledTime, setScheduledTime] = useState<string>('');
  const [scheduledDate, setScheduledDate] = useState<string>('');
  const [predictedLaunchSpeed, setPredictedLaunchSpeed] = useState<string>('');

  useEffect(() => {
    if (inspectionContext.run?.scheduled_launch_at) {
      const date = formatTimezoneDate({
        date: inspectionContext.run.scheduled_launch_at,
        timezone: settingsContext.state.timezone?.id || 'utc',
        format: 'YYYY/MM/DD',
      });
      
      const time = formatTimezoneDate({
        date: inspectionContext.run.scheduled_launch_at,
        timezone: settingsContext.state.timezone?.id || 'utc',
        format: 'HH:mm:ss.SSS',
      });

      setScheduledDate(date);
      setScheduledTime(time);
    }
    if (inspectionContext.run?.predicted_launch_speed) {
      setPredictedLaunchSpeed(
        formatMps({
          distance: inspectionContext.run.predicted_launch_speed,
          unit: settingsContext.state.speedUnit,
          returnNumber: true,
        }) as string
      );
    }
  }, [inspectionContext.run, settingsContext.state.timezone, settingsContext.state.speedUnit]);

  /**
   * 
   */
  const handleClose = () => {
    inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_SCHEDULE_MODAL' });
  };
  
  /**
   * 
   */
  const handleApply = () => {
    const serializedTime = VMasker.toPattern(scheduledTime, '99:99:99.999');
    updateScheduledRun(
      scheduledDate,
      serializedTime,
      predictedLaunchSpeed,
      inspectionContext.run?.id,
      auth.token,
      settingsContext.state.timezone,
      notificationContext.dispatch,
      settingsContext.state.speedUnit,
    );
    inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_SCHEDULE_MODAL' });
  }

  return (
    <MobileScheduleModalWrapper>
      <MobileScheduleModalContent>
        <MobileScheduleModalContentHeader>
          <MobileScheduleModalContentTitle>
            Schedule run
            <MobileScheduleModalContentCloseButton onClick={handleClose}>
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="16.2637" y="4.94922" width="2" height="16" transform="rotate(45 16.2637 4.94922)" fill="#99A2AD"/>
                <rect x="17.6777" y="16.2637" width="2" height="16" transform="rotate(135 17.6777 16.2637)" fill="#99A2AD"/>
              </svg>
            </MobileScheduleModalContentCloseButton>
          </MobileScheduleModalContentTitle>
        </MobileScheduleModalContentHeader>
        <MobileScheduleModalContentForm>
          <DateInput
            icon="calendar_today"
            id="date"
            label="Date"
            value={scheduledDate}
            onChange={({ value }) => {
              const formatedDate = moment.utc(value).format('YYYY/MM/DD');
              setScheduledDate(formatedDate);
            }}
          />
          <Input
            id="time"
            label="Time"
            type="tel"
            icon='timer'
            value={VMasker.toPattern(scheduledTime, '99:99:99.999')}
            onChange={(e) => {
              setScheduledTime(e.target.value);
            }}
          />
          <Input
            id="speed"
            label={`Predicted Launch Speed (${settingsContext.state.speedUnit?.label})`}
            type="tel"
            value={predictedLaunchSpeed}
            icon='speed'
            onChange={(e) => {
              setPredictedLaunchSpeed(e.target.value);
            }}
          />

          <MobileScheduleModalContentFormFooter>
            <DefaultButton onClick={handleApply}>Apply</DefaultButton>
            <SecondaryButton handleClick={handleClose}>Cancel</SecondaryButton>
          </MobileScheduleModalContentFormFooter>
        </MobileScheduleModalContentForm>
      </MobileScheduleModalContent>
    </MobileScheduleModalWrapper>
  );
};

export default MobileScheduleModal;
