import { MaterialIcon } from 'material-icons';
import { Button } from './outline-button.style';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  text: string;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
  styleType?: 'default' | 'cancel' | 'confirm';
  disabled?: boolean; 
  icon?: MaterialIcon;
  style?: CSSProperties;
}

/**
 * 
 * @param props 
 */
const OutlineButton = ({
  text,
  onClick,
  styleType = 'default',
  disabled = false,
  type = 'button',
  icon,
  style,
}: Props) => {
  return (
    <Button
      style={style}
      type={type}
      disabled={!!disabled}
      onClick={onClick}
      $style={styleType}
    >
      {icon ? <span className="material-icons-outlined">{icon}</span> : null}
      {text}
    </Button>
  );
};

export default OutlineButton;