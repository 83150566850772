import { formatDistance, formatMps } from "@/lib/formatter";
import { TrackingPointType } from "app/interfaces/inspection.interfaces";

export const metadata = {
  chainage: {
    formatter: (tpoint: TrackingPointType, units: any) => {
      return formatDistance({
        distance: parseFloat(`${tpoint.chainage}`),
        unit: units.distance?.id,
      });
    },
    readonly: true,
    label: 'Chainage',
  },
  distance: {
    formatter: (tpoint: TrackingPointType, units: any) => {
      return formatDistance({
        distance: parseFloat(`${tpoint.distance}`),
        unit: units.distance?.id,
      });
    },
    readonly: true,
    label: 'Distance from Prev.',
  },
  speed: {
    formatter: (tpoint: TrackingPointType, units: any) => {
      return formatMps({
        distance: tpoint.speed,
        unit: units.speed,
      });
    },
    readonly: true,
    label: 'Speed'
  },
  speed_delta: {
    formatter: (tpoint: TrackingPointType, units: any) => {
      return formatMps({
        distance: tpoint.speed_delta,
        unit: units.speed,
      });
    },
    readonly: true,
    label: 'Speed Dt.'
  },
  latitude: {
    formatter: (tpoint: TrackingPointType) => {
      if (!tpoint.geometry?.coordinates[1]) return '';
      return parseFloat(tpoint.geometry.coordinates[1].toString()).toFixed(8);
    },
    readonly: true,
    label: 'Latitude',
  },
  longitude: {
    formatter: (tpoint: TrackingPointType) => {
      if (!tpoint.geometry?.coordinates[0]) return '';
      return parseFloat(tpoint.geometry.coordinates[0].toString()).toFixed(8);
    },
    readonly: true,
    label: 'Longitude',
  },
  elevation: {
    label: 'Elevation',
    readonly: true,
  },
  inclination: {
    formatter: (tpoint: TrackingPointType) => {
      if (!tpoint.inclination) return '';
      return `${parseInt(tpoint.inclination.toString(), 10).toFixed(1)}°`;
    },
    label: 'Inclination',
    readonly: true,
  },
  milepost: {
    label: 'Milepost',
  },
  description: {
    label: 'Description',
  },
  comment: {
    label: 'Comment',
  },
  depth_of_cover: {
    label: 'Depth of Cover',
  },
  alignment_sheet: {
    label: 'Alignment Sheet',
  },
  speed_offset: {
    label: 'Speed Offset',
  },
  device_sn: {
    label: 'Device SN',
  },
  county: {
    label: 'County',
  },
  state: {
    label: 'State',
  },
  country: {
    label: 'Country',
  },
  tract: {
    label: 'Tract',
  },
  pressure_differential: {
    label: 'Pressure Differencial',
  },
  location_description: {
    label: 'Location Description',
  },
  antenna_height: {
    label: 'Antenna Height',
  },
  datum: {
    label: 'Datum',
  },
  elevation_top: {
    label: 'Elevation (TOP)',
  },
  ellipsoid_height: {
    label: 'Ellipsoid Height',
  },
  ellipsoid_height_cop: {
    label: 'Ellipsoid Height (COP)',
  },
  geoid_model: {
    label: 'Geoid Model',
  },
  survey_accuracy: {
    label: 'Survey Accuracy',
  },
  survey_method: {
    label: 'Survey Method',
  }
};
