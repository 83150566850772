import styled from 'styled-components';

export const Wrapper = styled.div<{ $error?: string, $customStyle?: string }>`
  ${({ $error }) => ($error ? 'background-color: rgba(220, 20, 60, 0.2);' : null)}

  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;
  /* justify-content: center; */
  align-items: center;
  display: flex;

  .MuiInput-underline:before {
    border: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }

  .MuiInput-underline:after {
    border: none;
  }

  .MuiFormControl-marginNormal {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
  }

  .MuiInputBase-input {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 1em;
    color: #000;
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
  }

  ${({ $customStyle }) => $customStyle}
`;
