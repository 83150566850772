export const NewRunIlustration = () => (
  <svg
    width="591"
    height="495"
    viewBox="0 0 591 495"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="142.459"
      width="572.394"
      height="305.8"
      rx="10"
      fill="#5750E9"
    />
    <g filter="url(#filter0_d_1018_659)">
      <path
        d="M60.248 54.6074C60.248 52.3983 62.0389 50.6074 64.248 50.6074H512.147C514.356 50.6074 516.147 52.3983 516.147 54.6074V400.573C516.147 402.783 514.356 404.573 512.147 404.573H64.248C62.0389 404.573 60.248 402.783 60.248 400.573V54.6074Z"
        fill="white"
      />
    </g>
    <path
      d="M60.248 54.6074C60.248 52.3983 62.0389 50.6074 64.248 50.6074H512.147C514.356 50.6074 516.147 52.3983 516.147 54.6074V66.2895H60.248V54.6074Z"
      fill="url(#paint0_linear_1018_659)"
    />
    <circle cx="69.2081" cy="58.4483" r="3.36044" fill="#C4C4C4" />
    <circle cx="79.2901" cy="58.4483" r="3.36044" fill="#C4C4C4" />
    <circle cx="89.3722" cy="58.4483" r="3.36044" fill="#C4C4C4" />
    <g opacity="0.75">
      <rect
        x="60.248"
        y="66.291"
        width="455.899"
        height="338.284"
        fill="#EBF0F3"
      />
      <path d="M516.147 66.291H60.248V339.607H516.147V66.291Z" fill="#EBF0F3" />
      <path
        d="M396.027 230.518C393.073 228.978 391.997 224.778 391.997 224.778L391.769 223.676L348.14 149.79L336.359 156.732L378.94 228.842C379.807 231.784 382.524 238.79 389.517 242.535C391.76 243.737 394.897 244.812 398.945 244.812C402.018 244.812 405.61 244.193 409.723 242.553C425.324 236.312 488.976 179.645 516.139 155.092V136.625C478.518 170.963 416.835 224.988 404.635 229.862C400.933 231.338 398.033 231.566 396.027 230.518Z"
        fill="#FFFFFD"
      />
      <path
        d="M282.41 93.9967L160.117 135.037L164.472 147.992L286.765 106.952L282.41 93.9967Z"
        fill="#FFFFFD"
      />
      <path
        d="M319.747 105.466C309.589 109.794 295.238 114.805 278.634 120.617C247.924 131.358 209.702 144.733 178.327 160.813C138.91 181.011 117.938 201.783 114.209 224.322C107.389 265.438 126.71 289.89 144.116 303.164C162.279 317.012 181.91 321.404 182.74 321.577L187.627 299.329C185.156 298.773 127.23 285.098 136.685 228.039C138.363 217.917 148.748 201.555 188.721 181.074C218.71 165.705 256.112 152.622 286.165 142.109C303.161 136.169 317.832 131.039 328.682 126.42C358.589 113.693 368.647 93.7866 371.82 79.3191C372.85 74.6089 373.233 70.1994 373.242 66.291H350.438C350.429 68.7235 350.192 71.5022 349.544 74.4449C346.608 87.8647 336.579 98.3054 319.747 105.466Z"
        fill="#E2EAEE"
      />
      <path
        d="M313.728 298.609C375.73 293.143 441.38 287.676 445.939 310.453C447.744 319.49 447.689 330.095 446.969 339.606C441.361 370.97 410.847 396.36 396.292 405.135H250.113C214.268 402.895 163.301 370.41 156.807 339.606C153.032 325.521 154.136 302.609 190.635 285.854C224.372 270.366 251.726 304.075 313.728 298.609Z"
        fill="#E2E9EE"
      />
      <path
        d="M272.333 251.854L227.91 201.545L238.159 192.508L275.798 235.127L366.294 192.717L372.102 205.089L272.333 251.854Z"
        fill="#FFFFFD"
      />
      <path
        d="M287.387 282.22L286.858 282.156L155.432 265.047L157.201 251.49L288.098 268.536L457.155 263.99L457.52 277.656L287.387 282.22Z"
        fill="#FFFFFD"
      />
      <path
        d="M396.246 209.783C396.246 209.783 404.452 222.537 415.393 214.338C424.156 207.778 490.152 148.687 516.147 125.364V103.217L371.627 161.497L396.246 209.783Z"
        fill="#BDCDD7"
      />
      <path
        d="M491.984 300.431C475.143 300.431 461.439 286.738 461.439 269.911C461.439 253.083 475.143 239.39 491.984 239.39C501.804 239.39 510.557 244.055 516.147 251.27V232.931C509.199 228.385 500.892 225.725 491.984 225.725C467.602 225.725 447.762 245.549 447.762 269.911C447.762 294.272 467.602 314.097 491.984 314.097C500.892 314.097 509.199 311.436 516.147 306.89V288.551C510.557 295.766 501.804 300.431 491.984 300.431Z"
        fill="#FFFFFD"
      />
      <path
        d="M491.985 290.865C503.567 290.865 512.956 281.484 512.956 269.911C512.956 258.339 503.567 248.957 491.985 248.957C480.403 248.957 471.014 258.339 471.014 269.911C471.014 281.484 480.403 290.865 491.985 290.865Z"
        fill="#BDCDD7"
      />
      <path
        d="M487.371 230.927L500.62 234.298L516.147 173.494V145.853L509.519 144.158L487.371 230.927Z"
        fill="#FFFFFD"
      />
      <path
        d="M487.151 307.236L487.023 401H500.7L500.828 307.395L487.151 307.236Z"
        fill="#FFFFFD"
      />
      <path
        d="M361.598 222.537L379.834 251.691L287.742 258.068L361.598 222.537Z"
        fill="#BDCDD7"
      />
      <path
        d="M505.206 77.169L501.185 66.291H417.582L440.496 100.929L505.206 77.169Z"
        fill="#BDCDD7"
      />
      <path
        d="M422.086 124.708L405.847 66.291H391.65L408.902 128.37L422.086 124.708Z"
        fill="#FFFFFD"
      />
      <path
        d="M365.7 115.488C355.314 123.132 303.697 143.73 303.697 143.73L306.889 152.385L398.068 116.854L385.823 66.291H380.99L382.568 73.1239C382.568 73.1239 383.024 102.733 365.7 115.488Z"
        fill="#BDCDD7"
      />
      <path
        d="M207.048 192.016L195.195 183.816C195.195 183.816 137.751 212.97 142.31 243.946V251.234L207.048 192.016Z"
        fill="#BDCDD7"
      />
      <path
        d="M278.988 241.322H265.311V273.346H278.988V241.322Z"
        fill="#FFFFFD"
      />
      <path
        d="M252.032 239.797L228.336 215.803L214.058 229.881L237.754 253.875L252.032 239.797Z"
        fill="#BDCDD7"
      />
      <path
        d="M333.788 66.291H295.492V91.8005H333.788V66.291Z"
        fill="#BDCDD7"
      />
      <path
        d="M300.225 170.215L287.177 66.291H273.391L286.648 171.909L300.225 170.215Z"
        fill="#FFFFFD"
      />
      <path
        d="M237.474 189.01L72.7579 66.291H60.3666L60.248 66.4459V74.0167L229.296 199.97L237.474 189.01Z"
        fill="#FFFFFD"
      />
      <path
        d="M268.593 91.3445L233.033 100.911V66.7461H268.593V91.3445Z"
        fill="#BDCDD7"
      />
      <path
        d="M224.373 102.733V66.291H188.812V112.299L224.373 102.733Z"
        fill="#BDCDD7"
      />
      <path
        d="M176.884 98.1777H135.999C133.948 98.1777 132.279 96.5105 132.279 94.4607V80.0296C132.279 77.9797 133.948 76.3125 135.999 76.3125H176.884C178.936 76.3125 180.605 77.9797 180.605 80.0296V94.4607C180.605 96.5105 178.936 98.1777 176.884 98.1777Z"
        fill="#BDCDD7"
      />
      <path
        d="M113.132 120.955H69.3652V143.731H113.132V120.955Z"
        fill="#BDCDD7"
      />
      <path
        d="M94.8956 237.568H69.3652V284.943H94.8956V237.568Z"
        fill="#BDCDD7"
      />
      <path
        d="M105.837 186.551H69.3652V220.26H105.837V186.551Z"
        fill="#BDCDD7"
      />
      <path
        d="M80.3068 152.842H69.3652V175.618H80.3068V152.842Z"
        fill="#BDCDD7"
      />
      <path
        d="M138.663 152.842H89.4258V175.618H138.663V152.842Z"
        fill="#BDCDD7"
      />
      <path
        d="M126.809 306.809C126.809 306.809 137.751 319.563 145.045 319.563C151.537 319.563 144.307 329.676 144.571 339.606C142.413 352.115 154.564 389.789 191.305 404.575H76.49C72.9428 398.788 60.3594 376.795 67.5283 370.971C74.6973 365.146 85.8795 347.634 90.5744 339.606C101.452 330.378 126.809 306.809 126.809 306.809Z"
        fill="#BDCDD7"
      />
      <path
        d="M454.956 339.606C456.588 330.833 458.293 320.975 457.792 320.474C456.88 319.563 476.94 324.118 476.94 324.118L472.381 339.606L465.18 378.811L442.217 371.53L454.956 339.606Z"
        fill="#BDCDD7"
      />
      <path
        d="M338.388 168.84L322.426 175.568L333.767 202.431L349.73 195.702L338.388 168.84Z"
        fill="#BDCDD7"
      />
      <path
        d="M280.031 190.707L264.068 197.436L275.41 224.298L291.372 217.569L280.031 190.707Z"
        fill="#BDCDD7"
      />
      <path
        d="M326.283 205.043L297.808 214.755L288.197 188.371L316.463 178.35L326.283 205.043Z"
        fill="#BDCDD7"
      />
      <path
        d="M249.537 194.349L516.147 97.1033V89.8331L513.804 83.4102L244.851 181.512C234.994 185.111 225.94 190.368 217.934 197.146L60.248 330.651V339.607V348.008L226.779 207.568C233.544 201.838 241.204 197.392 249.537 194.349Z"
        fill="#FFFFFD"
      />
      <path
        d="M276.111 153.118L230.918 170.348L234.169 178.86L279.361 161.63L276.111 153.118Z"
        fill="#BDCDD7"
      />
    </g>
    <rect
      x="102.812"
      y="234.312"
      width="370.768"
      height="144.499"
      fill="white"
    />
    <rect
      x="128.576"
      y="254.475"
      width="208.347"
      height="8.96117"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="128.576"
      y="308.242"
      width="208.347"
      height="4.48058"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="128.576"
      y="320.564"
      width="208.347"
      height="4.48058"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="128.576"
      y="332.885"
      width="208.347"
      height="4.48058"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="128.576"
      y="345.207"
      width="208.347"
      height="4.48058"
      rx="2"
      fill="#EBF0F3"
    />
    <rect
      x="128.576"
      y="271.277"
      width="116.495"
      height="8.96117"
      rx="2"
      fill="#EBF0F3"
    />
    <g filter="url(#filter1_d_1018_659)">
      <path
        d="M505 137C541.451 137 571 107.451 571 71C571 34.5492 541.451 5 505 5C468.549 5 439 34.5492 439 71C439 107.451 468.549 137 505 137Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter2_i_1018_659)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.084 50.0732L497.501 44L512.534 50.3772C512.654 50.4249 512.787 50.4248 512.906 50.377L524.372 45.7798C527.329 44.5946 530.547 46.7716 530.547 49.9567V86.5482C530.547 88.388 529.427 90.0425 527.719 90.7261L514.736 95.922C513.426 96.446 511.965 96.4469 510.655 95.9245L497.261 90.5835L485.945 94.8527C482.347 96.2104 478.501 93.5492 478.504 89.7028L478.529 55.214C478.53 52.928 479.946 50.8814 482.084 50.0732ZM494.786 50.6176L483.852 54.7503C483.658 54.8237 483.529 55.0098 483.529 55.2176L483.504 89.7064C483.504 90.056 483.853 90.298 484.18 90.1745L494.786 86.1732V50.6176ZM499.837 50.6889L499.788 86.2084L509.263 89.9864V54.4544L499.837 50.6889ZM514.263 55.1921V90.7257L525.547 86.2098V50.6958L514.767 55.0179C514.601 55.0843 514.433 55.1424 514.263 55.1921Z"
        fill="url(#paint1_linear_1018_659)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1018_659"
        x="0.248047"
        y="20.6074"
        width="575.898"
        height="473.967"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="30" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1018_659"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1018_659"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1018_659"
        x="419"
        y="0"
        width="172"
        height="172"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="15" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.113725 0 0 0 0 0.129412 0 0 0 0 0.223529 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1018_659"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1018_659"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_i_1018_659"
        x="478.504"
        y="44"
        width="52.043"
        height="53.3164"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0744445 0 0 0 0 0.0803333 0 0 0 0 0.133333 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1018_659"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1018_659"
        x1="288.198"
        y1="50.6074"
        x2="288.198"
        y2="66.2895"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset="1" stopColor="#E6E7EA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1018_659"
        x1="504.525"
        y1="44"
        x2="504.525"
        y2="96.3157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3D8F7" />
        <stop offset="0.078125" stopColor="#9FD6F7" />
        <stop offset="1" stopColor="#73BCF9" />
      </linearGradient>
    </defs>
  </svg>
);
