import styled from 'styled-components';

export const CustomerDevicesBarContainer = styled.div`
  width: 100%;
  height: 48px;
  padding: 8px; 
  box-shadow: 0px -8px 20px 0px rgba(38, 59, 82, 0.10);
  background-color: #ffffff;

  position: fixed;
  bottom: 0;
  left: 0;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  justify-items: end;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & > div:first-child > p,
  & > div:first-child > button {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }
`;

export const CustomerDevicesBarSelectedAmount = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #5850ec;

  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CustomerDevicesBarActionsContainer = styled.div`
  position: relative;
  display: flex;

  > button:first-child {
    display: block;
    padding: 8px;

    width: 140px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const CustomerDevicesBarProjectSelector = styled.ul`
  position: absolute;

  bottom: 48px;
  left: 0;

  width: 100%;
  padding: 8px;
  background-color: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(38, 59, 82, 0.10);
  border-radius: 8px;
  overflow: hidden;

  > li {
    padding: 8px;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }
  }
`;

