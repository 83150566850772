import styled from 'styled-components';

export const MobileScheduleModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 40px;
  left: 0;
  z-index: 20;

  display: flex;
  align-items: center;
  
  height: calc(100vh - 40px);
  background-color: rgba(112, 122, 140, 0.5);

  padding: 30px;
`;

export const MobileScheduleModalContent = styled.div`
  width: 100%;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

export const MobileScheduleModalContentHeader = styled.header`
  padding: 14px 17px 24px 17px;
  border-bottom: 0.7px solid rgba(130, 132, 143, 0.7);
`;

export const MobileScheduleModalContentTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;

  margin-bottom: 9px;
`;

export const MobileScheduleModalContentCloseButton = styled.button`
  background-color: transparent;
`;

export const MobileScheduleModalContentForm = styled.form`
  padding: 14px 17px;
  display: grid;
  gap: 16px;
`;

export const MobileScheduleModalContentFormFooter = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9px;

  margin-top: 24px;
  margin-bottom: 8px;
`;
