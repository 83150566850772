export const distanceUnitOptions = [
  {
    id: 'm',
    label: 'm',
  },
  {
    id: 'km',
    label: 'km',
  },
  {
    id: 'ft',
    label: 'ft',
  },
  {
    id: 'mi',
    label: 'mi',
  },
];

export const weatherUnitOptions = [
  {
    id: 'celsius',
    label: '°C',
  },
  {
    id: 'farenheit',
    label: '°F',
  },
];

export const speedUnitOptions = [
  {
    id: 'mps',
    label: 'm/s',
  },
  {
    id: 'kmh',
    label: 'km/h',
  },
  {
    id: 'mph',
    label: 'mph',
  },
];
