import axios from 'axios';
const API_URL = import.meta.env.VITE_API_URL;

/**
 * format qube uptime
 * @returns
 */
export const getUptime = (secs: number) : string => {
  if (!secs) return '-';

  const fixZero = (n: number) => (n < 10 ? `0${n.toFixed(0)}` : n.toFixed(0));

  const minutes = Math.floor(secs / 60);
  const secondsUpTo = secs % 60;
  const hours = Math.floor(minutes / 60);
  const minutesUpTo = minutes % 60;

  return `${fixZero(hours)}:${fixZero(minutesUpTo)}:${fixZero(secondsUpTo)}`;
};

/**
 * send arm/disarm command to qube
 */
export const setArmed = async (
  mqttId: string,
  armed: boolean,
  token: string,
) => {
  return await axios.put(
    `${API_URL}/qube/${mqttId}/arm/${armed ? 1 : 0}/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

}
