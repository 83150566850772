import styled from "styled-components";

export const MobileSettingsModalWrapper = styled.div`
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 50;

  width: 100%;
  height: calc(100% - 40px);

  background: ${({ theme }) => theme.colors.surface_white};


  > header {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
    align-items: center;
    
    border-bottom: 1px solid #ececec;
    padding: 16px;

    > h2 {
      display: flex;
      align-items: center;
      
      font-family: 'Inter';
      font-weight: 700;
      font-size: 18px;
      line-height: 14px;
      color: #090808;
      > svg {
        margin-right: 4px;
      }
    }
  }

  > section {
    padding: 16px;

    > h3 {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 1em;

      color: #090808;

      margin-bottom: 8px;
    }

    > ul {
      display: flex;
      flex-wrap: wrap;
      
      gap: 8px;

      > li {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 30px;

        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;

        color: #090808;

        border: 1px solid #A4ABB6;
        border-radius: 30px;

        padding: 8px 16px;

        cursor: pointer;

        &.selected {
          background-color: #5850EC4D;
          color: #5850EC;
          border-color: transparent;

          font-weight: 700;
        }
      }
    }

    select {
      width: 300px;
      height: 30px;
      border: none;

      background-color: #5850EC4D;
      color: #5850EC;

      border-radius: 30px;
      padding-left: 8px;
      padding-right: 8px;

      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      
      height: 30px;
      border: none;

      background-color: #5850EC4D;
      color: #5850EC;

      border-radius: 30px;
      padding-left: 12px;
      padding-right: 12px;

      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
    }
  }
`;
