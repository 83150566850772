import { MaterialIcon } from 'material-icons';
import { Button } from './text-button.style';
import { CSSProperties } from 'react';

interface Props {
  text: string;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
  styleType?: 'default' | 'primary' | 'negative';
  disabled?: boolean; 
  icon?: MaterialIcon;
  style?: CSSProperties;
}

/**
 * 
 * @param props 
 */
const TextButton = ({
  text,
  onClick,
  icon,
  styleType = 'default',
  disabled = false,
  type = 'button',
  style,
}: Props) => {
  return (
    <Button
      type={type}
      disabled={!!disabled}
      onClick={onClick}
      $style={styleType}
      style={style}
    >
      {icon ? <span className="material-icons-outlined">{icon}</span> : null}
      {text}
    </Button>
  );
};

export default TextButton;