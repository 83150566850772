import axios from 'axios';
import getEnv from '../../../../../../../../env';

const API_URL = getEnv('VITE_API_URL');

/**
 * create new tracking point note
 * @param data informations to send to django api
 * @param token authozization logged user token
 */
export const createTrackingPointNote = (data: any, token: string) =>
  axios.post(`${API_URL}/trackingPointNotes/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * update tracking point note
 * @param data informations to send to django api
 * @param token authozization logged user token
 */
export const updateTrackingPointNote = (
  noteId: string,
  data: any,
  token: string
) =>
  axios.put(`${API_URL}/trackingPointNotes/${noteId}/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * delete tracking point note
 * @param data informations to send to django api
 * @param token authozization logged user token
 */
export const deleteTrackingPointNote = (noteId: string, token: string) =>
  axios.delete(`${API_URL}/trackingPointNotes/${noteId}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * create tracking point media
 * @param data informations to send to django api
 * @param token authozization logged user token
 */
export const createTrackingPointMedia = (
  trackingPointId: string,
  media: File,
  token: string
) => {
  const data = new FormData();
  data.append(
    'media',
    media,
    `${media.name.split('.')[0]}-${Date.now().toString()}.${
      media.name.split('.')[1]
    }`
  );
  data.append('tracking_point', trackingPointId);

  return axios.post(`${API_URL}/tracking_point_medias/`, data, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * delete tracking point media
 * @param data informations to send to django api
 * @param token authozization logged user token
 */
export const deleteTrackingPointMedia = (mediaId: string, token: string) =>
  axios.delete(`${API_URL}/tracking_point_medias/${mediaId}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * update tracking point media
 * @param caption new caption to save on media
 * @param mediaID identifier to find the media
 * @param token authozization logged user token
 */
export const updateTrackingPointMediaCaption = (
  caption: string,
  mediaId: string,
  token: string
) =>
  axios.put(
    `${API_URL}/tracking_point_medias/${mediaId}/`,
    { caption },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 * update tracking point media
 * @param is_sketch it says if is site sketch or not
 * @param mediaID identifier to find the media
 * @param token authozization logged user token
 */
export const toggleTrackingPointMediaSiteSketch = (
  is_sketch: boolean,
  mediaId: string,
  token: string
) =>
  axios.put(
    `${API_URL}/tracking_point_medias/${mediaId}/`,
    { is_sketch },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
