const hour = 60;
export const timezoneListModel = [
  {
    name: '(GMT+00:00) Default',
    location: 'UTC',
    offset: 0,
  },
  {
    name: '(GMT-11:00) Samoa',
    location: 'Pacific/Samoa',
    offset: -11.00 * hour,
  },
  {
    name: '(GMT-10:00) Hawaii',
    location: 'Pacific/Honolulu',
    offset: -10.00 * hour,
  },
  {
    name: '(GMT-09:30) Marquesas Islands',
    location: 'Pacific/Marquesas',
    offset: -9.3 * hour,
  },
  {
    name: '(GMT-09:00) Alaska',
    location: 'America/Anchorage',
    offset: -9.00 * hour,
  },
  {
    name: '(GMT-08:00) Baja California',
    location: 'America/Tijuana',
    offset: -8.00 * hour,
  },
  {
    name: '(GMT-08:00) Pacific Time (US & Canada)',
    location: 'America/Los_Angeles',
    offset: -8.00 * hour,
  },
  {
    name: '(GMT-07:00) Chihuahua,Mazatlan',
    location: 'America/Chihuahua',
    offset: -7.00 * hour,
  },
  {
    name: '(GMT-07:00) Arizona',
    location: 'America/Phoenix',
    offset: -7.00 * hour,
  },
  {
    name: '(GMT-07:00) Mountain Time (US & Canada)',
    location: 'America/Denver',
    offset: -7.00 * hour,
  },
  {
    name: '(GMT-06:00) Central Time (US & Canada)',
    location: 'America/Chicago',
    offset: -6.00 * hour,
  },
  {
    name: '(GMT-06:00) Central America',
    location: 'America/Guatemala',
    offset: -6.00 * hour,
  },
  {
    name: '(GMT-06:00) Guadalajara,Mexico City, Monterrey',
    location: 'America/Mexico_City',
    offset: -6.00 * hour,
  },
  {
    name: '(GMT-06:00) Saskatchewan',
    location: 'America/Regina',
    offset: -6.00 * hour,
  },
  {
    name: '(GMT-05:00) Bogota, Lima, Quito',
    location: 'America/Bogota',
    offset: -5.00 * hour,
  },
  {
    name: '(GMT-05:00) Eastern Time (US & Canada)',
    location: 'America/New_York',
    offset: -5.00 * hour,
  },
  {
    name: '(GMT-04:30) Caracas',
    location: 'America/Caracas',
    offset: -4.30 * hour,
  },
  {
    name: '(GMT-04:00) Atlantic Time (Canada)',
    location: 'America/Halifax',
    offset: -4.00 * hour,
  },
  {
    name: '(GMT-04:00) Asuncion',
    location: 'America/Asuncion',
    offset: -4.00 * hour,
  },
  {
    name: '(GMT-04:00) Cuiaba',
    location: 'America/Cuiaba',
    offset: -4.00 * hour,
  },
  {
    name: '(GMT-04:00) Santiago',
    location: 'America/Santiago',
    offset: -4.00 * hour,
  },
  {
    name: '(GMT-04:00) Georgetown, La Paz, Manaus, San Juan',
    location: 'America/La_Paz',
    offset: -4.00 * hour,
  },
  {
    name: '(GMT-03:30) Newfoundland',
    location: 'America/St_Johns',
    offset: -3.30 * hour,
  },
  {
    name: '(GMT-03:00) Buenos Aires',
    location: 'America/Buenos_Aires',
    offset: -3.00 * hour,
  },
  {
    name: '(GMT-03:00) Brasilia',
    location: 'America/Sao_Paulo',
    offset: -3.00 * hour,
  },
  {
    name: '(GMT-03:00) Cayenne, Fortaleza',
    location: 'America/Cayenne',
    offset: -3.00 * hour,
  },
  {
    name: '(GMT-03:00) Montevideo',
    location: 'America/Montevideo',
    offset: -3.00 * hour,
  },
  {
    name: '(GMT-03:00) Greenland',
    location: 'America/Godthab',
    offset: -3.00 * hour,
  },
  {
    name: '(GMT-02:00) Coordinated Universal Time-02',
    location: 'Etc/GMT+2',
    offset: -2.00 * hour,
  },
  {
    name: '(GMT-01:00) Azores',
    location: 'Atlantic/Azores',
    offset: -1.00 * hour,
  },
  {
    name: '(GMT-01:00) Cape Verde Is.',
    location: 'Atlantic/Cape_Verde',
    offset: -1.00 * hour,
  },
  {
    name: '(GMT+00:00) Casablanca',
    location: 'Africa/Casablanca',
    offset: +0.00 * hour,
  },
  {
    name: '(GMT+00:00) Monrovia,Reykjavik',
    location: 'Atlantic/Reykjavik',
    offset: +0.00 * hour,
  },
  {
    name: '(GMT+00:00) Greenwich Mean Time : Dublin,Edinburgh,Lisbon, London',
    location: 'Europe/London',
    offset: +0.00 * hour,
  },
  {
    name: '(GMT+01:00) Sarajevo,Skopje, Warsaw, Zagreb',
    location: 'Europe/Warsaw',
    offset: +1.00 * hour,
  },
  {
    name: '(GMT+01:00) West Central Africa',
    location: 'Africa/Lagos',
    offset: +1.00 * hour,
  },
  {
    name: '(GMT+01:00) Belgrade,Bratislava, Budapest, Ljubljana, Prague',
    location: 'Europe/Budapest',
    offset: +1.00 * hour,
  },
  {
    name: '(GMT+01:00) Brussels, Copenhagen,Madrid, Paris',
    location: 'Europe/Paris',
    offset: +1.00 * hour,
  },
  {
    name: '(GMT+01:00) Amsterdam,Berlin, Bern, Rome,Stockholm, Vienna',
    location: 'Europe/Berlin',
    offset: +1.00 * hour,
  },
  {
    name: '(GMT+02:00) Harare, Pretoria',
    location: 'Africa/Johannesburg',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Damascus',
    location: 'Asia/Damascus',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    location: 'Europe/Kiev',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Windhoek',
    location: 'Africa/Windhoek',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Minsk',
    location: 'Europe/Minsk',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Athens,Bucharest, Istanbul',
    location: 'Europe/Istanbu',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Amman',
    location: 'Asia/Amman',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Beirut',
    location: 'Asia/Beirut',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+02:00) Jerusalem',
    location: 'Asia/Jerusalem',
    offset: +2.00 * hour,
  },
  {
    name: '(GMT+03:00) Kuwait, Riyadh',
    location: 'Asia/Riyadh',
    offset: +3.00 * hour,
  },
  {
    name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    location: 'Europe/Moscow',
    offset: +3.00 * hour,
  },
  {
    name: '(GMT+03:00) Baghdad',
    location: 'Asia/Baghdad',
    offset: +3.00 * hour,
  },
  {
    name: '(GMT+03:00) Nairobi',
    location: 'Africa/Nairobi',
    offset: +3.00 * hour,
  },
  {
    name: '(GMT+03:30) Tehran',
    location: 'Asia/Tehran',
    offset: +3.30 * hour,
  },
  {
    name: '(GMT+04:00) Port Louis',
    location: 'Indian/Mauritius',
    offset: +4.00 * hour,
  },
  {
    name: '(GMT+04:00) Tbilisi',
    location: 'Asia/Tbilisi',
    offset: +4.00 * hour,
  },
  {
    name: '(GMT+04:00) Baku',
    location: 'Asia/Baku',
    offset: +4.00 * hour,
  },
  {
    name: '(GMT+04:00) Yerevan',
    location: 'Asia/Yerevan',
    offset: +4.00 * hour,
  },
  {
    name: '(GMT+04:00) Abu Dhabi, Muscat',
    location: 'Asia/Dubai',
    offset: +4.00 * hour,
  },
  {
    name: '(GMT+04:30) Kabul',
    location: 'Asia/Kabul',
    offset: +4.30 * hour,
  },
  {
    name: '(GMT+05:00) Yekaterinburg',
    location: 'Asia/Yekaterinburg',
    offset: +5.00 * hour,
  },
  {
    name: '(GMT+05:00) Islamabad,Karachi',
    location: 'Asia/Karachi',
    offset: +5.00 * hour,
  },
  {
    name: '(GMT+05:00) Tashkent',
    location: 'Asia/Tashkent',
    offset: +5.00 * hour,
  },
  {
    name: '(GMT+05:30) Chennai, Kolkata, Mumbai,New Delhi',
    location: 'Asia/Calcutta',
    offset: +5.30 * hour,
  },
  {
    name: '(GMT+05:30) Sri Jayawardenepura',
    location: 'Asia/Colombo',
    offset: +5.30 * hour,
  },
  {
    name: '(GMT+05:45) Kathmandu',
    location: 'Asia/Katmandu',
    offset: +5.45 * hour,
  },
  {
    name: '(GMT+06:00) Dhaka',
    location: 'Asia/Dhaka',
    offset: +6.00 * hour,
  },
  {
    name: '(GMT+06:00) Novosibirsk',
    location: 'Asia/Novosibirsk',
    offset: +6.00 * hour,
  },
  {
    name: '(GMT+06:00) Astana',
    location: 'Asia/Almaty',
    offset: +6.00 * hour,
  },
  {
    name: '(GMT+06:30) Yangon (Rangoon)',
    location: 'Asia/Rangoon',
    offset: +6.30 * hour,
  },
  {
    name: '(GMT+07:00) Krasnoyarsk',
    location: 'Asia/Krasnoyarsk',
    offset: +7.00 * hour,
  },
  {
    name: '(GMT+07:00) Bangkok, Hanoi,Jakarta',
    location: 'Asia/Bangkok',
    offset: +7.00 * hour,
  },
  {
    name: '(GMT+08:00) Ulaanbaatar',
    location: 'Asia/Ulaanbaatar',
    offset: +8.00 * hour,
  },
  {
    name: '(GMT+08:00) Perth',
    location: 'Australia/Perth',
    offset: +8.00 * hour,
  },
  {
    name: '(GMT+08:00) Taipei',
    location: 'Asia/Taipei',
    offset: +8.00 * hour,
  },
  {
    name: '(GMT+08:00) Kuala Lumpur,Singapore',
    location: 'Asia/Singapore',
    offset: +8.00 * hour,
  },
  {
    name: '(GMT+08:00) Beijing,Chongqing,Hong Kong, Urumqi',
    location: 'Asia/Shanghai',
    offset: +8.00 * hour,
  },
  {
    name: '(GMT+08:00) Irkutsk',
    location: 'Asia/Irkutsk',
    offset: +8.00 * hour,
  },
  {
    name: '(GMT+08:30) Pyongyang',
    location: 'Asia/Pyongyang',
    offset: +8.30 * hour,
  },
  {
    name: '(GMT+08:30) Eucla',
    location: 'Australia/Eucla',
    offset: 8.3 * hour,
  },
  {
    name: '(GMT+09:00) Seoul',
    location: 'Asia/Seoul',
    offset: +9.00 * hour,
  },
  {
    name: '(GMT+09:00) Osaka,Sapporo, Tokyo',
    location: 'Asia/Tokyo',
    offset: +9.00 * hour,
  },
  {
    name: '(GMT+09:00) Yakutsk',
    location: 'Asia/Yakutsk',
    offset: +9.00 * hour,
  },
  {
    name: '(GMT+09:30) Darwin',
    location: 'Australia/Darwin',
    offset: +9.30 * hour,
  },
  {
    name: '(GMT+09:30) Adelaide',
    location: 'Australia/Adelaide',
    offset: +9.30 * hour,
  },
  {
    name: '(GMT+10:00) Hobart',
    location: 'Australia/Hobart',
    offset: +10.00 * hour,
  },
  {
    name: '(GMT+10:00) Vladivostok',
    location: 'Asia/Vladivostok',
    offset: +10.00 * hour,
  },
  {
    name: '(GMT+10:00) Guam, Port Moresby',
    location: 'Pacific/Port_Moresby',
    offset: +10.00 * hour,
  },
  {
    name: '(GMT+10:00) Brisbane',
    location: 'Australia/Brisbane',
    offset: +10.00 * hour,
  },
  {
    name: '(GMT+10:00) Canberra,Melbourne, Sydney',
    location: 'Australia/Sydney',
    offset: +10.00 * hour,
  },
  {
    name: '(GMT+10:30) Lord Howe Island',
    location: 'Australia/LHI',
    offset: +10.30 * hour,
  },
  {
    name: '(GMT+11:00) Magadan, Solomon Is.,New Caledonia',
    location: 'Asia/Magadan',
    offset: +11.00 * hour,
  },
  {
    name: '(GMT+12:00) Fiji',
    location: 'Pacific/Fiji',
    offset: +12.00 * hour,
  },
  {
    name: '(GMT+12:00) Petropavlovsk-Kamchatsky',
    location: 'Asia/Kamchatka',
    offset: +12.00 * hour,
  },
  {
    name: '(GMT+12:00) Auckland, Wellington',
    location: 'Pacific/Auckland',
    offset: +12.00 * hour,
  },
  {
    name: '(GMT+12:00) Coordinated Universal Time+12',
    location: 'Etc/GMT-12',
    offset: +12.00 * hour,
  },
  {
    name: '(GMT+12:45) Chatham Islands',
    location: 'Pacific/Chatham',
    offset: +12.45 * hour,
  },
  {
    name: '(GMT+13:00) Phoenix Islands, Tokelau, Tonga',
    location: 'Pacific/Enderbury',
    offset: +13.00 * hour,
  },
  {
    name: '(GMT+14:00) Line Islands',
    location: 'Pacific/Kiritimati',
    offset: +14.00 * hour,
  },
];
