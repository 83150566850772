import styled from 'styled-components';

export const CaptionWrapper = styled.div<{ editing: boolean }>`
  width: 100%;

  transition-duration: 0.2s;
  opacity: ${({ editing }) => (editing ? 1 : 0)};

  position: absolute;
  left: 0;
  bottom: 0;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 0) 130%
  );
  backdrop-filter: blur(10px);
`;

export const MediaWrapper = styled.div`
  display: inline-block;

  width: calc(100% - 16px);
  height: 70vh;

  margin-left: auto;
  margin-right: auto;

  background: rgba(62, 66, 85, 0.35);
  backdrop-filter: blur(10px);
  border-radius: 6px;

  position: relative;

  @media (min-width: 1024px) {
    width: calc(100% - 148px);
  }

  @media (min-height: 900px) {
    height: 380px;
  }

  @media (min-height: 1200px) {
    height: 500px;
  }

  @media (min-height: 1400px) {
    height: 620px;
  }

  &:hover .caption_wrapper {
    opacity: 1;
  }
`;

export const MediaPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Dropdown = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (!hide ? 'block' : 'none')};
  position: absolute;

  bottom: 16px;
  right: 16px;
`;

export const MediaActionsButton = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  background: rgba(82, 86, 103, 0.65);
  backdrop-filter: blur(10px);

  border-radius: 6px;

  cursor: pointer;

  &:hover {
    background-color: #5850ec;
  }

  svg {
    cursor: pointer;
  }
`;

export const ActionList = styled.ul<{ show: boolean }>`
  position: absolute;
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 120px;
  background-color: #ffffff;

  border-radius: 3px;

  bottom: 40px;
  right: 0px;
  text-align: left;

  overflow: hidden;
`;

export const ActionItem = styled.li`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;

  color: #1a1f36;

  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;

  cursor: pointer;

  a {
    color: #1a1f36;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const Caption = styled.textarea`
  width: 100%;
  height: auto;
  min-height: 60px;

  padding: 16px;
  padding-right: 30%;

  background-color: transparent;

  resize: none;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;

  color: #ffffff;

  text-align: left;

  &::placeholder {
    color: #ffffff;
  }

  &:focus {
    padding: 16px;
    padding-right: 30%;
  }
`;

export const CaptionActionsWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 18px;

  gap: 4px;
`;

export const CaptionActionButton = styled.button`
  width: 16px;
  height: 16px;

  background-color: transparent;

  opacity: 0.5;
  cursor: pointer;

  transition-duration: 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const SiteSketchTag = styled.span`
  position: absolute;
  top: 8px;
  left: calc(50% - 50px);

  display: inline-block;
  width: 100px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 1em;

  text-transform: uppercase;

  background: rgba(82, 86, 103, 0.65);
  backdrop-filter: blur(10px);
  border-radius: 4px;

  padding: 6px;

  color: #ffffff;
`;
