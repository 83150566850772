import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);

  z-index: 22;
  padding: 16px;
`;

export const Modal = styled.div`
  position: relative;

  width: 100%;
  max-width: 520px;
  padding: 32px 24px;
  border-radius: 6px;

  background-color: #ffffff;
`;

export const ModalFigure = styled.figure`
  position: absolute;
  top: -35px;
  left: calc(50% - 35px);

  width: 75px;
  height: 75px;

  border-radius: 50%;

  background-color: #5850ec;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  svg {
    position: relative;
    width: 100%;
    top: -3px;
  }
`;

export const ConfirmationTitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;

  color: #1a1f36;

  margin-top: 32px;
`;

export const ConfirmationText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #1a1f36;

  margin-top: 8px;
  margin-bottom: 32px;
`;

export const ConfirmationFooter = styled.footer`
  display: grid;
  justify-content: space-between;
  align-items: start;

  grid-template-columns: 1fr;
  gap: 24px;

  button {
    height: 36px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: auto auto;
    gap: 12px;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;

  grid-template-columns: auto auto;
  gap: 12px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  justify: center;
  align-items: start;
`;
