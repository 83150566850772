import { ChangeEvent } from 'react';
import { countries } from './phone-input.model';
import { InputWrapper, Input, Select } from './phone-input.style';

const renderOptions = () =>
  countries.map((country) => (
    <option key={country.countryCode} value={country.countryCode}>
      {country.flag} +{country.countryCallingCode}
    </option>
  ));

interface Props {
  onCountryChange: (country: string, countryCallingCode: string) => void;
  onPhoneChange: (e: ChangeEvent<HTMLInputElement>) => void;
  phone?: string;
  ptestid?: string;
  country: string;
}

/**
 *
 * @returns
 */
const PhoneInput = ({
  onCountryChange,
  onPhoneChange,
  phone,
  ptestid,
  country,
}: Props) => (
  <InputWrapper>
    <Select
      value={country}
      onChange={(e) => {
        const country = countries.find(
          ({ countryCode }) => countryCode === e.target.value
        );

        if (!country) return;
        onCountryChange(country.countryCallingCode, country.countryCode);
      }}
    >
      {renderOptions()}
    </Select>
    <Input
      value={phone}
      type="tel"
      onChange={onPhoneChange}
      data-testid={ptestid}
    />
  </InputWrapper>
);

export default PhoneInput;
