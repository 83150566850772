import styled from 'styled-components';

export const ModalWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  display: ${({ open }) => (open ? 'flex' : 'none')};

  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.8);
`;

export const Figure = styled.img`
  width: 70%;
  object-fit: contain;
  max-height: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  width: 30px;
  height: 30px;
  border-radius: 6px;

  background: rgba(62, 66, 85, 0.35);
  backdrop-filter: blur(10px);

  cursor: pointer;
`;
