import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialIcon } from 'material-icons';
import {
  Group,
  GroupLabel,
  GroupError,
  GroupInputWrapper,
} from './date-input.style';
import { Timezone } from 'app/modules/settings/settings.context.d';
import moment from 'moment';

interface DateInputEvent {
  value: MaterialUiPickersDate;
  old_value: Date;
};

interface Props {
  id: string;
  format?: string;
  value: string;
  icon?: MaterialIcon;
  iconAlignment?: 'left' | 'right';
  testid?: string;
  label?: string;
  error?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (event: DateInputEvent) => void;
  tz?: Timezone;
};


/**
 * 
 * @param props 
 * @returns 
 */
const DateInput = ({
  id,
  label,
  format,
  value,
  error,
  icon,
  iconAlignment = 'left',
  placeholder,
  testid,
  onChange,
  disabled = false,
  tz,
}: Props) => {

  const getValue = () => {
    if (tz && value) {
      const momentValue = moment.tz(value, `${tz.id}`);
      return new Date(`${momentValue.format('YYYY-MM-DD')} 00:00`)
    } else if (value){
      return new Date(`${value}`);
    } else {
      return null;
    }
  }
  return (
    <Group>
      {label ? <GroupLabel disabled={disabled}>{label}</GroupLabel> : null}
      <GroupInputWrapper $iconAlignment={iconAlignment} $error={!!error} $icon={icon}>
        {icon && iconAlignment === 'left' ? <span className="material-icons-outlined">{icon}</span> : null}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          data-testid={testid}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          disableToolbar
          variant="inline"
          format={format || 'yyyy-MM-dd'}
          margin="normal"
          inputVariant="standard"
          value={getValue()}
          autoOk={true}
          onChange={() => {}}
          onAccept={(date) => {
            onChange({
              value: date,
              old_value: getValue() as Date,
            })
          }}
        />
        </MuiPickersUtilsProvider>
        {icon && iconAlignment === 'right' ? <span className="material-icons-outlined">{icon}</span> : null}
      </GroupInputWrapper>
      {error ? <GroupError>{error}</GroupError> : null}
    </Group>
  );
};

export default DateInput;
