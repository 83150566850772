import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  line-height: 1em;

  padding-left: 8px;
`;

export const Ball = styled.span<{ online: boolean }>`
  width: 8px;
  height: 8px;

  display: inline-block;

  border-radius: 50%;
  background-color: ${({ online }) => (online ? '#31d988' : '#8e949f')};
`;
