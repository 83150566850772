import { css } from 'styled-components';
import colors from './colors';

// headline 1
const headline1 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 300;
  line-height: 112px; /* 116.667% */
  letter-spacing: -1.5px;

  color: ${colors.outline_on_surface};
`;

// headline 2
const headline2 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
  line-height: 72px; /* 120% */
  letter-spacing: -0.5px;

  color: ${colors.outline_on_surface};
`;

// headline 3
const headline3 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 116.667% */

  color: ${colors.outline_on_surface};
`;

// headline 4
const headline4 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 105.882% */

  color: ${colors.outline_on_surface};
`;

// headline 5
const headline5 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  letter-spacing: 0.18px;

  color: ${colors.outline_on_surface};
`;

// headline 6
const headline6 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.15px;

  color: ${colors.outline_on_surface};
`;

// subtitle 1
const subtitle1 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  color: ${colors.outline_on_surface};
`;

// subtitle 2
const subtitle2 = css`
  font-feature-settings: 'clig' off, 'liga' off;
  
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.1px;

  color: ${colors.outline_on_surface};
`;

// body 1
const body1 = css`
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  color: ${colors.outline_on_surface};
`;

// body 2
const body2 = css`
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  color: ${colors.outline_on_surface};
`;

// button 1
const button1 = css`
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  text-transform: capitalize;

  color: ${colors.outline_on_surface};
`;

// button 2
const button2 = css`
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: capitalize;

  color: ${colors.outline_on_surface};
`;

// caption
const caption = css`
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;

  color: ${colors.outline_on_surface};
`;

// overline
const overline = css`
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: ${colors.outline_on_surface};
`;

const fonts = {
  primary: 'Inter, sans-serif',
  table: 'IBM Plex Mono, monospace',
  typograph: {
    headline1,
    headline2,
    headline3,
    headline4,
    headline5,
    headline6,
    subtitle1,
    subtitle2,
    body1,
    body2,
    button1,
    button2,
    caption,
    overline,
  },
};

export default fonts;