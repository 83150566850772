import { useMemo, useState } from "react";
import { ProjectSimpleType } from "app/modules/customer/customer.interfaces";
import FilledButton from "stories/base/buttons/filled-button/filled-button";
import TextButton from "stories/base/buttons/text-button/text-button";
import Typograph from "stories/type/typograph/typograph";
import IconButton from "stories/base/buttons/icon-button/icon-button";
import {
  CustomerDevicesBarContainer,
  CustomerDevicesBarSelectedAmount,
  CustomerDevicesBarActionsContainer,
  CustomerDevicesBarProjectSelector,
} from "./customer-devices-bar.style";

interface Props {
  selecteds: number[];
  projects: ProjectSimpleType[];
  onDeselectAll: () => void;
  onAddDevicesToProject: (projectId: number) => void;
}

/**
 * 
 * @param props 
 * @returns 
 */
const CustomerDevicesBar = (props: Props) => {  
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
  const [openSelector, setOpenSelector] = useState<boolean>(false);
  const selectedProject = useMemo(() => {
    return props.projects.find((project) => project?.id === selectedProjectId);
  }, [props.projects, selectedProjectId]);

  return (
    <CustomerDevicesBarContainer>
      <div>
        <CustomerDevicesBarSelectedAmount>
          {props.selecteds.length}
        </CustomerDevicesBarSelectedAmount>
        <Typograph type="subtitle2" text="Selected" />
        <TextButton text="Deselect all" onClick={props.onDeselectAll} />
      </div>
      
      <div>
        <CustomerDevicesBarActionsContainer>
          <FilledButton
            disabled={selectedProjectId === null}
            text={selectedProjectId && selectedProject ? `Add to ${selectedProject.name}` : "Select a project"}
            onClick={() => {
              if (selectedProjectId && selectedProject) {
                props.onAddDevicesToProject(selectedProjectId);
              }
            }}
          />

          {
            openSelector ? (
              <CustomerDevicesBarProjectSelector>
                {props.projects.map((project) => (
                  <li
                    key={project.id}
                    onClick={() => {
                      setOpenSelector(false);
                      setSelectedProjectId(project.id);
                    }}
                  >
                    <Typograph type="body2" text={project.name} />
                  </li>
                ))}
              </CustomerDevicesBarProjectSelector>
            ) : null
          }

          <IconButton
            icon="arrow_drop_down"
            styleType={selectedProjectId ? "primary" : "secondary"}
            onClick={() => {
              setOpenSelector(!openSelector);    
            }}
          />
        </CustomerDevicesBarActionsContainer>
      </div>
    </CustomerDevicesBarContainer>
  );
};

export default CustomerDevicesBar;