import styled from 'styled-components';

export const MobileConfigurationBtn = styled.button`
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 6px 10px rgba(51, 57, 78, 0.2);

  > svg {
    opacity: 0.7;
  }
`;

export const MobileConfigurationButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 47px;

  z-index: 5;

  > div {
    position: relative;
  }
`;

export const MobileConfigurationButtonDropdown = styled.ul<{ opened: boolean }>`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;

  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  flex-direction: column;
  gap: 8px;

  min-width: 163px;

  background-color: #ffffff;
  border-radius: 6px;

  padding: 15px 11px;
`;

export const MobileConfigurationButtonDropdownItem = styled.li`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;

  color: #4b5066;
`;
