import { useEffect, useState } from "react";
import { changeDriveline, renderDriveline } from "./driveline.controller";
import { DriveLineType } from "app/interfaces/inspection.interfaces";

interface Props {
  driveline: DriveLineType;
  map: google.maps.Map;
}

const Driveline = ({ driveline, map }: Props) => {
  const [drivePolylines, setDrivePolylines] = useState<google.maps.Polyline[] | undefined>();

  useEffect(() => {
    renderDriveline(
      driveline,
      map,
      drivePolylines,
      setDrivePolylines,
    );
    return () => {
      drivePolylines?.forEach((polyline) => {
        polyline.setMap(null);
      });
    };
  }, []);
  
  useEffect(() => {
    changeDriveline(
      driveline,
      map,
      drivePolylines,
      setDrivePolylines,
    );
  }, [driveline]);

  useEffect(() => {
    return () => {
      drivePolylines?.forEach((polyline) => {
        polyline.setMap(null);
      });
    };
  }, [driveline]);

  return null;
};

export default Driveline;