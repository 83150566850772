import { Button } from './ruler.style';

const Ruler = ({
  onClick,
  active,
}: {
  onClick: () => void;
  active: boolean;
}) => (
  <Button
    $active={active}
    title={active ? 'Hide ruler' : 'Show ruler'}
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M21 6H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H3V8h2v4h2V8h2v4h2V8h2v4h2V8h2v4h2V8h2v8z" />
    </svg>
  </Button>
);

export default Ruler;
