import styled from 'styled-components';

export const ReportContainer = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;

  @media print {
    background-color: #ffffff;

    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;

    header {
      display: none;
    }

    @page {
      size: landscape;
      margin: 0in;

      -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
      color-adjust: exact !important;
    }
  }
`;

export const PDFWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 47px;

  width: 100%;
  max-width: 1180px;

  padding-top: 47px;
  padding-bottom: 47px;

  margin-left: auto;
  margin-right: auto;

  @media print {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Page = styled.li`
  position: relative;
  width: 100%;
  height: 768px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(34, 55, 91, 0.15);

  @media print {
    box-shadow: none;
    height: 100vh !important;
    page-break-after: always;
  }
`;

export const InitialPage = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1fr;

  width: 100%;
  height: 100%;

  @media print {
    height: 100vh !important;
  }
`;

export const RunInformationWrapper = styled.div`
  background-color: #6862f3;
  padding: 24px 43px;

  @media print {
    height: 100vh !important;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;

  @media print {
    height: 100vh !important;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;

  color: #ffffff;

  margin-bottom: 20px;
`;

export const RunName = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;

  color: #ffffff;

  margin-bottom: 9px;
`;

export const InfoWrapper = styled.div`
  padding-bottom: 20px;
  margin-bottom: 24px;

  border-bottom: solid 1px rgba(22, 30, 46, 0.1);
`;

export const ValuesInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  padding-bottom: 20px;
  margin-bottom: 24px;

  > div > div {
    margin-top: 4px;
  }

  &:not(:last-child) {
    border-bottom: solid 1px rgba(22, 30, 46, 0.1);
  }
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;

  opacity: 0.65;

  margin-bottom: 8px;
`;

export const Value = styled.output`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  color: #ffffff;
`;

export const MapWrapper = styled.figure`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;

  img {
    object-fit: cover;
    width: 460px;

    filter: grayscale(70%);
    opacity: 0.6;
  }
`;

export const GTAttributeInformation = styled.span`
  position: absolute;
  top: 21px;
  right: 35px;

  font-weight: 500;
  font-size: 13px;
  line-height: 1em;

  color: #686c7b;
`;

export const CompanyName = styled.span`
  width: 100%;
  display: inline-flex;
  gap: 6px;
  position: absolute;
  bottom: 22px;
  left: 35px;

  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 140% */

  color: #ffffff;

  @media print {
    display: none;
  }
`;

export const GtLogo = styled.img<{ orientation: string }>`
  vertical-align: middle;
  margin-right: 8px;

  ${({ orientation }) =>
    orientation !== 'PORTRAIT'
      ? `
      object-fit: cover;
      object-position: center;
      height: 36px;
    `
      : `
      height: 36px;
    `}
`;

export const TrackingSheetPage = styled.div`
  padding: 24px 43px;

  @media print {
    margin-top: 32px;
  }
`;

export const TrackingSheetHeader = styled.header`
  margin-bottom: 40px;
`;

export const DarkTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;

  color: #000000;

  margin-bottom: 10px;
`;

export const PageName = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;

  color: #000000;

  margin-bottom: 24px;

  > small {
    font-size: 11px;
    color: rgba(26, 31, 54, 0.8);
  }
`;

export const FloatGTAttributeInformation = styled.span<{ white?: boolean }>`
  position: absolute;
  bottom: 28px;
  right: 35px;

  font-weight: 500;
  font-size: 13px;
  line-height: 1em;

  color: #686c7b;

  @media print {
    color: #000000;
    bottom: 12px;
  }

  ${({ white }) =>
    white
      ? `
    @media print {
      bottom: -32px;
    }
  `
      : ''}
`;

export const DarkCompanyName = styled.span`
  position: absolute;
  bottom: 22px;
  left: 35px;

  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 140% */

  color: #000000;

  @media print {
    display: none;
  }
`;

export const Wrapper = styled.div`
  padding: 24px 43px;
`;

export const TrackingPointPage = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;
  height: 100%;

  @media print {
    padding-top: 16px;
    padding-right: 16px;
  }
`;

export const TrackingPointName = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #000000;

  margin-bottom: 10px;
`;

export const TrackingPointInformations = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const TrackingPointInformationSectionName = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  margin-bottom: 5px;
`;

export const TrackingPointInformationSectionColumn = styled.div`
  display: grid;
  gap: 10px;
`;

export const TrackingPointInformationSection = styled.div``;

export const OtherInfos = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const TrackingPointInformationSectionList = styled.ul``;

export const OtherInfosContent = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
`;

export const TrackingPointInformationSectionListItem = styled.li<{ allgrey?: boolean }>`
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &:nth-child(even) {
    background-color: rgba(220, 223, 226, 0.35);
  }
  ${({ allgrey }) => allgrey ? 'background-color: rgba(220, 223, 226, 0.35);' : ''}
`;

export const TrackingPointInformationSectionListLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 2px;
  line-height: 16px;
  /* identical to box height */

  color: #676b7a;
`;

export const TrackingPointInformationSectionListValue = styled.output`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  padding: 2px;

  color: #000000;
`;

export const TrackingPointImages = styled.div`
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  border-left: solid 1px #d9d9d9;
  height: 100%;

  > div {
    width: 100%;
    display: grid;
    align-content: start;
    gap: 1px;

    padding: 4px;

    margin-bottom: 16px;

    > p {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 1em;

      color: #686c7b;
    }
  }
`;

export const ReportImageTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  padding-left: 5px;
  padding-right: 5px;
`;

export const SpeedCanvas = styled.canvas`
  max-height: 200px;
  width: 100%;
  padding: 5px;
`;

export const ElevationCanvas = styled.canvas`
  max-height: 200px;
  width: 100%;
  padding: 5px;
`;

export const InclinationCanvas = styled.canvas`
  max-height: 200px;
  width: 100%;
  padding: 5px;
`;
