import styled from 'styled-components';

export const MobileWidgetsModalWrapper = styled.div`
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 20;

  display: flex;
  align-items: center;

  width: 100%;
  height: calc(100vh - 40px);
  background-color: rgba(112, 122, 140, 0.5);
`;


export const MobileWidgetsModalContent = styled.div`
  width: 100%;
  height: 100%;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  max-height: 100%;

  display: grid;
  grid-template-rows: auto auto auto 2fr 3fr;
`;

export const MobileWidgetsModalContentCloseButton = styled.button`
  background-color: transparent;
`;

export const MobileWidgetsModalContentHeader = styled.header`
  padding: 16px;
  border-bottom: 1px solid #ececec;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 9px;
`;

export const MobileWidgetsModalContentTitle = styled.h2`
  display: flex;
  align-items: center;

  font-family: 'Inter';
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;

  color: #090808;

  > svg {
    margin-right: 4px;
  }
`;


export const MobileWidgetsModalWidgetsWeatherList = styled.div`
  display: grid;
  align-items: center;

  grid-template-columns: 1fr 1fr 1fr;

  width: 100%;
`;

export const MobileWidgetsModalWidgetsPigList = styled.div`
  display: grid;
  align-items: center;

  grid-template-columns: 1fr 1fr 1fr;

  gap: 6px;

  width: 100%;

  background-color: #dcdfe2;

  padding: 13px 6px;
`;

export const TrackingIcon = styled.img`
  width: 25px;
  height: 25px;
  padding: 5px;
`;

export const Timer = styled.span`
  padding: 0 5px 0 0;
  font-style: normal;
`;

export const TimerContainer = styled.div`
  padding: 0 5px 0 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;
  white-space: nowrap;
  display: block;
`;

export const CanvasContainer = styled.div`
  width: 100%;
  padding: 8px;
  background-color: #f1f2f3;
`;

export const ChartCanvas = styled.canvas`
  max-height: 300px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
