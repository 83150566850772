import styled from 'styled-components';

export const Widget = styled.li<{
  onClick?: (data: any) => void;
  minWidth?: string;
  isSelected?: boolean;
}>`
  display: grid;
  align-items: center;
  grid-template-columns: 30px auto;

  background-color: ${({ isSelected }) => (isSelected ? '#5850EC' : '#fff')};

  padding: 4px;
  border-radius: 6px;
  border: 0.5px solid #5850ec;
  cursor: pointer;

  > figure {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      max-width: 100%;
      padding: 5px;
    }

    &.large {
      width: 25px;
      height: 25px;

      > img {
        padding: 0;
      }
    }

    &.xlarge {
      /* width: 70px; */

      > img {
        padding: 0;
      }
    }

    > svg {
      /* width: 100%; */
    }
  }
`;

export const WidgetContent = styled.div`
  display: flex;
  gap: 4px;
`;

export const WidgetValue = styled.span<{
  isSelected?: boolean;
}>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;

  white-space: nowrap;
  display: block;
  /* color: #ffffff; */
  ${({isSelected}) => isSelected ? 'color: #ffffff;' : ''}
`;

export const WidgetLabel = styled.label<{
  isSelected?: boolean;
}>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 10px;

  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;


  ${({isSelected}) => isSelected ? 'color: #ffffff;' : 'color: rgba(26, 31, 54, 0.65);'}
`;

