import styled from 'styled-components';

export const Button = styled.div`
  z-index: 1;

  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(10px);
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
`;
