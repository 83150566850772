import { manageProjectsPermissions } from 'app/services/projects.service';

/**
 * 
 * @param projectId 
 * @param admin 
 * @param editor 
 * @param user 
 * @param token 
 * @param onSuccess 
 */
export const editUserPermissions = async (
  projectId: string,
  admin: boolean,
  editor: boolean,
  user: string,
  token: string,
  onSuccess: () => void
) => {
  await manageProjectsPermissions(
    [
      {
        project_id: projectId,
        admin,
        editor,
      },
    ],
    user,
    token
  );

  onSuccess();
};
