import styled from 'styled-components';

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: -webkit-fill-available;
  
  display: grid;
  grid-template-rows: auto calc(100% - 52px);
    
  background-color: ${({ theme }) => theme.colors.surface_white};
  z-index: 21;

  * {
    user-select: none;
  }
`;

export const OverlayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const OverlayContent = styled.div`
  display: grid;
`;

export const OverlayPassagesContent = styled.div`
  overflow-y: auto;
  padding-top: 8px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const OverlayCharts = styled.div`
  padding: 8px 16px;

  > .VictoryContainer {
    top: -16px;

  }
`;

export const ChartTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartTypeGroup = styled.ul`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.neutra_300};
  border-radius: 6px;
  overflow: hidden; 
`;

export const ChartTypeItem = styled.li<{ isSelected }>`
  :first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.neutra_300};
  }
  font-size: .8em;
  padding: 3px 8px;
  background-color: ${({ isSelected, theme }) => isSelected ? theme.colors.primary_light_blue : theme.colors.surface_white};
`;

export const ResetZoom = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 0.7rem;

  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #fff;

  border: 1px solid ${({ theme }) => theme.colors.neutra_300};
  border-radius: 6px;

  cursor: pointer;

  transition-duration: 400ms;
`;

export const FixedRangeWrapper = styled.div`
  font-size: 0.7rem;
  gap: 4px;
  line-height: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  >label {
    display: block;
  }
`;

export const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`;
