import { InspectionDispatchType, InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import { createQubeMarkerOverlay } from "./qube-marker-html-creators";
import { captureException } from "@sentry/react";
import { CustomMarker } from "./qube-marker.interface";

/**
 * 
 * @param device 
 */
export const getQubeContent = (
  device: QubeDeviceType,
  next: string | undefined,
  prev: string | undefined,
  opened: boolean,
  isObserver: boolean,
  modalOpened: boolean,
  dispatch: (param: InspectionDispatchType) => void,
  token: string
) => {
  const { contentEl } = createQubeMarkerOverlay(
    device,
    token,
    isObserver,
    opened,
    dispatch,
    next,
    prev,
    modalOpened,
  );

  return contentEl;
};

/**
 *
 *
 */
export const renderQubeDevice = async (
  device: QubeDeviceType,
  next: string | undefined,
  prev: string | undefined,
  isObserver: boolean,
  clusterOpened: boolean,
  map: google.maps.Map,
  setMarker: (d: CustomMarker) => void,
  dispatch: InspectionProviderValue['dispatch'],
  token: string,
) => {
  let AdvancedMarkerElement;

  try {
    const markerLib = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    AdvancedMarkerElement = markerLib.AdvancedMarkerElement as google.maps.MarkerLibrary['AdvancedMarkerElement'];
  } catch (err) {
    captureException(err);
  }

  const marker = new AdvancedMarkerElement({
    map,
    gmpClickable: true,
    content: getQubeContent(device, next, prev, false, isObserver, false, dispatch, token),
    zIndex: 30,
  });

  if (device.position && !device.geolocked) {
    marker.map = map;
  }
  
  
  marker.getPosition = () => ({ lat: () => device.position?.coordinates[1], lng: () => device.position?.coordinates[0] });
  marker.getVisible = () => true;
  marker.data = { qube: device, opened: clusterOpened };
  
  setMarker(marker);
  return marker;
};
