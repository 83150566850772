import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL;

interface SurveyorType {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
}

export interface SurveyType {
  id: number;
  customer: number;
  geoid_model?: string | null;
  gps_datum?: string | null;
  gps_equipment?: string | null;
  gps_survey_accuracy?: string | null;
  name?: string;
  owner?: string;
  speed_up_at_tees: boolean;
  surveyor: SurveyorType;
  surveyor_phone?: string;
  survey_company?: string;
}

/**
 * get surveys
 * @param id run reference on django api
 * @param cb callback function
 */
export const getSurveys = (token: string) => {
  return axios.get(`${API_URL}/surveys/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * get surveys
 * @param id run reference on django api
 * @param cb callback function
 */
export const updateSurveyPoint = (data: any, id: number, token: string) => {
  return axios.put(`${API_URL}/survey_point/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * get surveys
 * @param id run reference on django api
 * @param cb callback function
 */
export const uploadSurveyPointMedia = (
  surveyPointId: string,
  media: File,
  caption: string | null,
  isSiteSketch: boolean,
  token: string
) => {
  const data = new FormData();
  data.append(
    'media',
    media,
    `${media.name.split('.')[0]}-${Date.now().toString()}.${media.name.split('.')[1]
    }`
  );

  data.append('caption', caption || '');
  data.append('is_sketch', isSiteSketch.toString());
  data.append('survey_point', surveyPointId);

  return axios.post(`${API_URL}/survey_point_medias/`, data, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * get surveys/**
 * get surveys
 * @param id run reference on django api
 * @param cb callback function
 */
export const getSurvey = (id: number, token: string) => {
  return axios.get(`${API_URL}/surveys/${id}/`, {
    headers: {
      'Content-Type': 'application/json    ',
      Authorization: `Token ${token}`,
    },
  });
};

export const deleteSurveyPointMedia = (
  surveyPointMediaId: string,
  token: string
) => {
  return axios.delete(`${API_URL}/survey_point_medias/${surveyPointMediaId}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * update survey point media
 * @param caption new caption to save on media
 * @param mediaID identifier to find the media
 * @param token authozization logged user token
 */
export const updateSurveyPointMediaCaption = (
  caption: string,
  mediaId: string,
  token: string
) =>
  axios.put(
    `${API_URL}/survey_point_medias/${mediaId}/`,
    { caption },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 * update survey point media
 * @param is_sketch it says if is site sketch or not
 * @param mediaID identifier to find the media
 * @param token authozization logged user token
 */
export const toggleSurveyPointMediaSiteSketch = (
  is_sketch: boolean,
  mediaId: string,
  token: string
) =>
  axios.put(
    `${API_URL}/survey_point_medias/${mediaId}/`,
    { is_sketch },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 * delete Survey
 * @param id run reference on django api
 * @param cb callback function
 */
export const deleteSurvey = (id: number, token: string) => {
  return axios.delete(`${API_URL}/surveys/${id}/`, {
    headers: {
      'Content-Type': 'application/json    ',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * delete SurveyPoint and create a dump
 * @param id run reference on django api
 * @param cb callback function
 */
export const deleteSurveyPoint = (id: number, token: string) => {
  return axios.delete(`${API_URL}/survey_point/${id}/`, {
    headers: {
      'Content-Type': 'application/json    ',
      Authorization: `Token ${token}`,
    },
  });
};
