import { loadAllProjects } from 'app/services/projects.service';
import { getActiveRuns } from 'app/services/run.service';
import { ProjectType, RunType } from 'app/interfaces/inspection.interfaces';

/**
 *
 * @param params
 */
const setData = async ({
  page,
  setProjects,
  setRuns,
  auth,
  setHasMore,
  setActiveRuns,
}: any) => {
  const projectsResponse = await loadAllProjects(auth.token, page);
  setRuns(
    projectsResponse.data.projects.reduce(
      (previous: RunType[], current: ProjectType) =>
        previous.concat(current.runs),
      []
    )
  );

  const activeRunsResponse = await getActiveRuns(auth.token);

  setActiveRuns(activeRunsResponse.data);
  setHasMore(projectsResponse.data.has_more);
  setProjects(projectsResponse.data.projects);
};

/**
 *
 * @param params init params
 * @param params.setLoading control loading on component state
 * @param params.setProject control project on component state
 * @param params.setProject control the runs on component state
 */
export const init = async ({
  setLoading,
  setProjects,
  setRuns,
  auth,
  page,
  setHasMore,
  setActiveRuns,
}: any) => {
  setLoading(true);
  await setData({
    setRuns,
    setProjects,
    auth,
    page,
    setHasMore,
    setActiveRuns,
  });
  setLoading(false);
};

/**
 *
 * @param params init params
 * @param params.setLoading control loading on component state
 * @param params.setProject control project on component state
 * @param params.setProject control the runs on component state
 */
export const handleLoadMore = async ({
  setLoading,
  setProjects,
  setRuns,
  auth,
  page,
  setHasMore,
  runs,
  projects,
  setPage,
}: any) => {
  setLoading(true);
  const newPage = page + 1;

  const projectsResponse = await loadAllProjects(auth.token, newPage);
  setRuns([
    ...runs,
    ...projectsResponse.data.projects.reduce(
      (previous: RunType[], current: ProjectType) =>
        previous.concat(current.runs),
      []
    ),
  ]);

  setProjects([...projects, ...projectsResponse.data.projects]);
  setHasMore(projectsResponse.data.has_more);
  setPage(newPage);
  setLoading(false);
};
