import LinkButton from 'app/components/link-button/link-button';
import { Bar, BarActions, BarSelecteds, BarSelectedsAmount } from './device-float-bar.style';
import DefaultButton from 'app/components/default-button/default-button';
import BarRemoveIcon from '../../icons/remove.icon';

interface Props {
  amount: number;
  onRemoveDevices: () => void; 
}

/**
 * 
 * @returns 
 */
const DeviceFloatBar = (props: Props) => {
  return (
    <Bar>
      <BarSelecteds>
        <BarSelectedsAmount>{props.amount}</BarSelectedsAmount>
        Selected
      </BarSelecteds>

      <LinkButton
        handleClick={() => null}
        customStyle={`
          display: none;

          padding: 0;

          color: #17181B;
          font-size: 14px;
        `}
      >
        Deselect All
      </LinkButton>

      <BarActions>
        <DefaultButton
          onClick={props.onRemoveDevices}
          customStyle={`
            display: flex;
            align-items: center;
            justify-content: space-between;            
            gap: 8px;

            font-size: 14px;
          `}
        >
          <BarRemoveIcon />
          Remove Devices
        </DefaultButton>
      </BarActions>
    </Bar>
  );
};

export default DeviceFloatBar;
