import Typograph from 'stories/type/typograph/typograph';
import {
  DrivelineedPiece,
  DrivelineWrapper,
  HelpButton,
  HelpCard,
  HelpCardImage,
  HelpCardText,
  HelpCardTitle,
  HelpContainer,
  HelpList,
  HelpTextWrapper,
  HelpTitle,
  PipelineHightLightedPiece,
  PipelineHightLightWrapper,
  PipelinePiece,
  SeparatorWrapper,
  SeparatorContainer,
} from './helpButtonIconsLegend.style';

interface Props {
  show: boolean;
  hidden: boolean;
  title: string;
  onShow: () => void;
  onClose: () => void;
}

/**
 *
 * @param param0
 * @returns
 */
const HelpButtonLegend = ({ show, onShow, onClose, hidden, title }: Props) => (
  <HelpButton
    $hidden={hidden}
    $show={show}
    onClick={onShow}
    title={title}
    onBlur={onClose}
  >
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.124 7C8.124 6.83424 8.05815 6.67527 7.94094 6.55806C7.82373 6.44085 7.66476 6.375 7.499 6.375C7.33324 6.375 7.17427 6.44085 7.05706 6.55806C6.93985 6.67527 6.874 6.83424 6.874 7V10C6.874 10.1658 6.93985 10.3247 7.05706 10.4419C7.17427 10.5592 7.33324 10.625 7.499 10.625C7.66476 10.625 7.82373 10.5592 7.94094 10.4419C8.05815 10.3247 8.124 10.1658 8.124 10V7ZM8.11786 5.61836C8.28187 5.45436 8.374 5.23193 8.374 5C8.374 4.76778 8.28061 4.54559 8.11701 4.38199C7.9531 4.21808 7.7308 4.126 7.499 4.126C7.2672 4.126 7.0449 4.21808 6.88099 4.38199C6.71708 4.5459 6.625 4.7682 6.625 5C6.625 5.23193 6.71713 5.45436 6.88113 5.61836C7.04514 5.78237 7.26757 5.8745 7.4995 5.8745C7.73143 5.8745 7.95386 5.78237 8.11786 5.61836ZM7.5 0.375C5.61033 0.375 3.79806 1.12567 2.46186 2.46186C1.12567 3.79806 0.375 5.61033 0.375 7.5C0.375 9.38967 1.12567 11.2019 2.46186 12.5381C3.79806 13.8743 5.61033 14.625 7.5 14.625C9.38967 14.625 11.2019 13.8743 12.5381 12.5381C13.8743 11.2019 14.625 9.38967 14.625 7.5C14.625 5.61033 13.8743 3.79806 12.5381 2.46186C11.2019 1.12567 9.38967 0.375 7.5 0.375ZM1.625 7.5C1.625 6.72848 1.77696 5.96452 2.07221 5.25173C2.36745 4.53895 2.8002 3.89129 3.34575 3.34575C3.89129 2.8002 4.53895 2.36745 5.25173 2.07221C5.96452 1.77696 6.72848 1.625 7.5 1.625C8.27152 1.625 9.03548 1.77696 9.74827 2.07221C10.4611 2.36745 11.1087 2.8002 11.6543 3.34575C12.1998 3.89129 12.6325 4.53895 12.9278 5.25173C13.223 5.96452 13.375 6.72848 13.375 7.5C13.375 9.05815 12.756 10.5525 11.6543 11.6543C10.5525 12.756 9.05815 13.375 7.5 13.375C5.94185 13.375 4.44752 12.756 3.34575 11.6543C2.24397 10.5525 1.625 9.05815 1.625 7.5Z" fill="white" stroke="white" strokeWidth="0.25"/>
    </svg>

    <HelpContainer $show={show} onClick={(e) => e.stopPropagation()}>
      <header>
        <svg width="24" height="24" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.624 7C8.624 6.83424 8.55815 6.67527 8.44094 6.55806C8.32373 6.44085 8.16476 6.375 7.999 6.375C7.83324 6.375 7.67427 6.44085 7.55706 6.55806C7.43985 6.67527 7.374 6.83424 7.374 7V10C7.374 10.1658 7.43985 10.3247 7.55706 10.4419C7.67427 10.5592 7.83324 10.625 7.999 10.625C8.16476 10.625 8.32373 10.5592 8.44094 10.4419C8.55815 10.3247 8.624 10.1658 8.624 10V7ZM8.61786 5.61836C8.78187 5.45436 8.874 5.23193 8.874 5C8.874 4.76778 8.78061 4.54559 8.61701 4.38199C8.4531 4.21808 8.2308 4.126 7.999 4.126C7.7672 4.126 7.5449 4.21808 7.38099 4.38199C7.21708 4.5459 7.125 4.7682 7.125 5C7.125 5.23193 7.21713 5.45436 7.38113 5.61836C7.54514 5.78237 7.76757 5.8745 7.9995 5.8745C8.23143 5.8745 8.45386 5.78237 8.61786 5.61836ZM8 0.375C6.11033 0.375 4.29806 1.12567 2.96186 2.46186C1.62567 3.79806 0.875 5.61033 0.875 7.5C0.875 9.38967 1.62567 11.2019 2.96186 12.5381C4.29806 13.8743 6.11033 14.625 8 14.625C9.88967 14.625 11.7019 13.8743 13.0381 12.5381C14.3743 11.2019 15.125 9.38967 15.125 7.5C15.125 5.61033 14.3743 3.79806 13.0381 2.46186C11.7019 1.12567 9.88967 0.375 8 0.375ZM2.125 7.5C2.125 6.72848 2.27696 5.96452 2.57221 5.25173C2.86745 4.53895 3.3002 3.89129 3.84575 3.34575C4.39129 2.8002 5.03895 2.36745 5.75173 2.07221C6.46452 1.77696 7.22848 1.625 8 1.625C8.77152 1.625 9.53548 1.77696 10.2483 2.07221C10.9611 2.36745 11.6087 2.8002 12.1543 3.34575C12.6998 3.89129 13.1325 4.53895 13.4278 5.25173C13.723 5.96452 13.875 6.72848 13.875 7.5C13.875 9.05815 13.256 10.5525 12.1543 11.6543C11.0525 12.756 9.55815 13.375 8 13.375C6.44185 13.375 4.94752 12.756 3.84575 11.6543C2.74397 10.5525 2.125 9.05815 2.125 7.5Z" fill="#4D5158" stroke="#4D5158" strokeWidth="0.25"/>
        </svg>
        <HelpTitle>Understanding your map</HelpTitle>
      </header>

      <SeparatorContainer>
        <SeparatorWrapper>
          <Typograph type="subtitle1">Map points</Typograph>
          <HelpList>
            <HelpCard>
              <HelpCardImage src="https://cdi-gt-medias.s3.us-east-2.amazonaws.com/default/agm.svg" />
              <HelpTextWrapper>
                <HelpCardTitle>Blue points: <strong>Default</strong></HelpCardTitle>
                <HelpCardText>
                  The tool will arrive and Hold here, awaiting a time input.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <HelpCardImage src="https://cdi-gt-medias.s3.us-east-2.amazonaws.com/yellow/agm.svg" />
              <HelpTextWrapper>
                <HelpCardTitle>
                  Yellow points: <strong>Awaiting tool arrival</strong>
                </HelpCardTitle>
                <HelpCardText>
                  The point is presently Holding for a time input.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <HelpCardImage src="https://cdi-gt-medias.s3.us-east-2.amazonaws.com/gray/agm.svg" />
              <HelpTextWrapper>
                <HelpCardTitle>Grey points: <strong>Do not hold</strong></HelpCardTitle>
                <HelpCardText>
                  Will not Hold for a time input (often a preset AGM), or was
                  skipped due to downstream point having been tracked.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
          </HelpList>
        </SeparatorWrapper>
        <SeparatorWrapper>
          <Typograph type="subtitle1">Pipeline tracking</Typograph>
          <HelpList>
            <HelpCard>
              <PipelinePiece $color="#b8b8b8" />
              <HelpTextWrapper>
                <HelpCardTitle>Gray: <strong>Default</strong></HelpCardTitle>
                <HelpCardText>
                  Pipeline&apos;s standard route across the map.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <PipelinePiece $color="#9acd32" />
              <HelpTextWrapper>
                <HelpCardTitle>Green: <strong>Successfully tracked</strong></HelpCardTitle>
                <HelpCardText>
                  Pipeline behind the tool that has been successfully tracked.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <PipelineHightLightWrapper>
                <PipelineHightLightedPiece />
              </PipelineHightLightWrapper>
              <HelpTextWrapper>
                <HelpCardTitle>
                  Red highlight: <strong>Tool&apos;s positional margin of error</strong>
                </HelpCardTitle>
                <HelpCardText>
                  A region that represents the earliest likely arrival of the
                  tool and the latest likely arrival of the tool (i.e. the
                  tool&apos;s positional standard deviation).
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <DrivelineWrapper>
                <DrivelineedPiece />
              </DrivelineWrapper>
              <HelpTextWrapper>
                <HelpCardTitle>Blue line: <strong>Vehicle driveline</strong></HelpCardTitle>
                <HelpCardText>
                  Vehicle route taken to move between tracking points.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
          </HelpList>
        </SeparatorWrapper>
      </SeparatorContainer>
    </HelpContainer>
  </HelpButton>
);

export default HelpButtonLegend;
