import { Link } from 'react-router-dom';
import { Quantity, TabList, TabItem } from './tabs.style';

interface Tab {
  id?: string;
  text: string;
  href: string;
  quantity?: number;
  hide?: boolean;
}

/**
 *
 * @param routes
 * @param onClick
 * @returns
 */
const renderTabs = (routes: Tab[], selected: Tab) =>
  routes.map((route) => (
    <TabItem hide={route.hide} key={route.href} selected={selected.id === route.id}>
      <Link to={route.href}>
        {route.text}
        &nbsp;
        {route.quantity !== undefined ? <Quantity>{route.quantity}</Quantity> : null}
      </Link>
    </TabItem>
  ));

interface TabProps {
  routes: Tab[];
  selected: Tab;
}

/**
 *
 * @param param0
 * @returns
 */
const Tabs = ({ routes, selected }: TabProps) => (
  <TabList>{renderTabs(routes, selected)}</TabList>
);

export default Tabs;
