import { formatDistance } from '../../../lib/formatter';
import { getTrackingPointIconSrc } from '../../utils/map.utils';
import { FormatterReturn } from './sheet.d';

/**
 * function to formatter the latitude and the longitude
 * @param row
 * @param cell
 * @param value
 * @returns string
 */
export const coordsFormatter = (row: unknown, cell: number, value: string, defs: any, context: any) => {
  const coords = { longitude: context.geometry?.coordinates[0], latitude: context.geometry?.coordinates[1] };
  const field = defs.field;
  const addClasses = 'gt-input number right';

  if (coords[field] === undefined || coords[field] === null) return { text: '', addClasses };
  return { text: parseFloat(coords[field]).toFixed(8), addClasses };
};
/**
 * function to formatter the name
 * @param row
 * @param cell
 * @param value
 * @returns string
 */
export const nameFormatter = (row: unknown, cell: number, value: string) => {
  const addClasses = '_point-name';
  // TODO: check why it is receving value as NaN?
  return { text: value || '', addClasses };
};

/**
 * function to formatter the latitude and the longitude
 * @param row
 * @param cell
 * @param value
 * @returns string
 */
export const activeFormatter = (
  row: unknown,
  cell: number,
  value: string,
  defs: unknown,
  context: any,
  grid: any
) => {
  const isObserver = grid?.metadata?.permission_type === 'observer';
  const holdText = `
    <span data-testid="hold-column-index-${row}" class="${isObserver ? 'hold-circle disabled' : 'hold-circle'}"></span>
  `;

  const unholText = `
    <span data-testid="hold-column-index-${row}" class="${isObserver ? 'unhold-circle disabled' : 'unhold-circle'}"></span>
  `;

  const activeState = context.active ? holdText : unholText;
  return context.is_device_trigger ? `<span class="centered-hold-column">-</span>` : activeState;
};

/**
 * function to formatter the AGM type from ref to icon
 * @param row
 * @param cell
 * @param value
 * @param defs
 * @param context
 * @returns
 */
export const indexFormatter = (
  row: unknown,
  cell: number,
  value: string,
): string => {
  if (value === undefined || value === null) return '-'; 
  return value + 1;
};
/**
 * function to formatter the AGM type from ref to icon
 * @param row
 * @param cell
 * @param value
 * @param defs
 * @param context
 * @returns
 */
export const typeFormatter = (
  row: unknown,
  cell: number,
  value: string,
  defs: unknown,
  context: any // TODO: found de context type
): string => {
  const icon = getTrackingPointIconSrc(context, 'no-border');
  if (!context.name) return '';
  return `
    <img id="${context.name.replace(/ /g, '_')}" class="gt-tp-icon" src="${icon.url}" alt="" />
  `;
};

/**
 * function to formatter the text columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const defaultFormatter = (row: unknown, cell: number, value: string) => {
  const addClasses = 'gt-input';
  return { text: value, addClasses };
};

/**
 * function to formatter the number columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const numberFormatter = (
  row: unknown,
  cell: number,
  value: string,
  defs: any,
  context: any,
  float = false
): FormatterReturn => {
  const addClasses = 'gt-input number right';
  // TODO: check why it is receving value as NaN?
  
  const number = float ? parseFloat(value) : parseInt(value);
  return { text: isNaN(number) ? '' : number.toString(), addClasses };
};

/**
 * function to formatter the distance columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const distanceFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any,
  grid: any
) => {
  const addClasses = 'gt-input number';
  const formatted = formatDistance({
    distance: parseFloat(`${value}`),
    unit: grid.metadata?.units.distance?.id,
  }) as string;
  // TODO: check why it is receving value as NaN?
  return { text: formatted, addClasses };
};
