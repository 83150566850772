export const weatherDropDownCustomStyle = `
  button {
    background-color: #FFF;
    z-index: 1;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    color: #000;
    height: 40px;

    background-color: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(10px);
  }

  button:hover {
    background-color: rgba(255, 255, 255, 0.35);
  }

  ul {
    top: calc(100% - 15px);
    right: 15px;
  }
`;
