import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import { Chart, registerables } from 'chart.js';
import { columnsModel } from './chart-modal.model';
import { SettingsContext, Unit } from 'app/modules/settings/settings.context.d';
import { InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { Column, TableProps } from 'app/components/table/table.interfaces';
import { TableContentInterface } from './chart-modal.d';
import { RunCascadeType, TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';



export const getSpeedCanvas = (run: RunCascadeType, speedUnit?: Unit) => {
  const speeds = run.trackingpoint_set?.filter((point) => {
    const adHocWNoPassage = point.type === "ad-hoc-point" && !point.passage?.id;
    const holdedPoint = point.color === "red";
    return (!adHocWNoPassage && !holdedPoint);
  }).map(
    (point, index) => {
      if (
        (point.type === "ad-hoc-point" && !point.passage?.id) ||
        !point.passage?.id
      ){
        return undefined;
      }
      const speeds = (formatMps({
        distance: index === 0 ? run.predicted_launch_speed : point.speed,
        unit: speedUnit,
        returnNumber: true,
      }) || undefined) as number | undefined;
      return (speeds)
    }
  );

  return speeds || [];
};

/**
 *
 * @param points
 * @param distanceUnit
 * @returns
 */
const getElevationsCanvas = (tableContent: TableContentInterface[], distanceUnit?: Unit) =>
  tableContent.map((point) =>
    ({
      x: point.index,
      y: formatDistance({
        distance: point.elevation,
        unit: distanceUnit?.id || '',
        returnNumber: true,
      }) as number
    }));

/**
 *
 * @param points
 * @returns
 */
const getInclinationsCanvas = (tableContent: TableContentInterface[]) =>
  tableContent.map((point) => ({
    x: point.index,
    y: point.inclination
  }));

/**
 *
 * @param distanceUnit
 * @param speedUnit
 * @param canvas
 * @param setCanvas
 * @param run
 * @param selectedChart
 * @param runElevations
 * @returns
 */
export const setCanvasPlot = (
  settingsState: SettingsContext,
  canvas: Chart | undefined,
  setCanvas: (chart?: Chart) => void,
  inspectionState: InspectionProviderValue,
  selectedChart: string,
  tableContent: TableContentInterface[],
  speeds
) => {
  if (!document || !inspectionState.run?.trackingpoint_set) return;
  const element: any = document.getElementById('gt-chart');
  if (!element) return;
  const ctx = element?.getContext('2d');
  if (canvas?.destroy) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.destroy();
  }


  Chart.register(...registerables);

  const distancesLabels = inspectionState.run?.trackingpoint_set.filter((point) => {
    const adHocWNoPassage = point.type === "ad-hoc-point" && !point.passage?.id;
    const holdedPoint = point.color === "red";
    return (!adHocWNoPassage && !holdedPoint);
  }).map((point) => {
    return point?.name?.slice(0, 10)
  }) || [];
  
  
  
  const speedsDataset: any[] = [];
  speedsDataset.push({
    label: `Speed (${settingsState.speedUnit?.label})`,
    backgroundColor: 'rgb(255, 99, 132)',
    borderColor: 'rgb(255, 99, 132)',
    data: speeds.map((speed) => {
      return +speed! > 0 || +speed! == 0 ? +speed! : undefined
    	}),
    tension: 0.1,
    });

  const elevations = getElevationsCanvas(tableContent, settingsState.distanceUnit);
  const inclinations = getInclinationsCanvas(tableContent);

  if (selectedChart === 'speed') {
    setCanvas(new Chart(ctx, {
      type: 'line',
      data: {
        labels: [...distancesLabels],
        datasets: speedsDataset,
      },
    }));
  }

  if (selectedChart === 'elevation') {
    const datas: any[] = [];
    datas.push({
      label: `Elevations (${settingsState.distanceUnit?.label})`,
      backgroundColor: 'transparent',
      borderColor: 'rgb(0, 99, 132)',
      pointRadius: 0,
      data: [...elevations],
    })
    setCanvas(new Chart(ctx, {
      type: 'line',
      data: {
        labels: [...distancesLabels],
        datasets: datas,
      },
    }));
  }

  if (selectedChart === 'inclination') {
    const datas: any[] = [];
    datas.push({
      label: `Inclination (°)`,
      backgroundColor: 'transparent',
      borderColor: 'rgb(127, 255, 0)',
      pointRadius: 0,
      data: [...inclinations],
    })
    setCanvas(new Chart(ctx, {
      type: 'line',
      data: {
        labels: [...distancesLabels],
        datasets: datas,
      },
    }));
  }
};

/**
 *
 * @param run
 * @param timezone
 * @param distanceUnit
 * @param speedUnit
 * @param setTableContent
 * @param elevations
 * @returns
 */
export const loadTableContent = (
  inspectionState: InspectionProviderValue,
  settingsState: SettingsContext,
  setTableContent: (data: TableContentInterface[]) => void,
  tableContent: TableContentInterface[],
) => {
  if (!inspectionState.run) return;
  const trackingpointSet = inspectionState.run.trackingpoint_set;

  const content = trackingpointSet?.map(
    (point: TrackingPointCascadeType, i: number) => {
      const passage = point?.passage;
      return ({
        id: point?.id,
        name: point?.name,
        index: i,
        formatedSpeed: passage ? formatMps({
          distance: point?.speed,
          unit: settingsState.speedUnit,
        }) : undefined,
        passage: formatTimezoneDate({
          date: passage?.tstamp || '',
          format: 'll H:mm',
          timezone: settingsState.timezone?.id || '',
        }),
        distance: formatDistance({
          distance: point?.distance,
          unit: settingsState.distanceUnit?.id || '',
        }),
        formatedInclination: `${point?.inclination}°`,
        formatedElevation: formatDistance({
          distance: point?.elevation || '',
          unit: settingsState.distanceUnit?.id || '',
        }),
        location: `${point?.geometry?.coordinates[0].toFixed(
          6
        )}, ${point?.geometry?.coordinates[1].toFixed(6)}`,
        speed: passage ? point?.speed : undefined,
        elevation: point?.elevation,
        inclination: point?.inclination,
        
    }) 
  }) as TableContentInterface[];
  if (JSON.stringify(tableContent) !== JSON.stringify(content)){
    setTableContent(content);
  }
};

/**
 *
 * @param columnsKey
 * @param speedUnit
 * @param distanceUnit
 * @param timezone
 * @param elevations
 * @returns
 */
export const getColumns = (
  columnsKey: string,
  settingsState: SettingsContext,
): TableProps['columns'] => {
  if (
    !settingsState.speedUnit ||
    !settingsState.distanceUnit ||
    !settingsState.timezone
  ) return [];

  const storagedColumns = window.localStorage.getItem(columnsKey);
  const parsedStorageColumns = storagedColumns
    ? JSON.parse(storagedColumns)
    : null;

  const allColumns: Column[] = columnsModel();

  if (parsedStorageColumns) {
    return parsedStorageColumns.map((sc: Column) => ({
      ...allColumns.find((c: Column) => sc.field === c.field),
      ...sc,
    }));
  }

  return allColumns;
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getSpeedValue = (
  inspectionState: InspectionProviderValue,
  speedUnit: Unit | undefined,
): string | number => {
  const points = inspectionState.run?.trackingpoint_set || []

  let currentSpeed = 0;

  if (!inspectionState.run?.launched) {
    return '-';
  }

  if (!inspectionState.run?.is_finished && inspectionState.run?.estimation) {
    currentSpeed = inspectionState.run?.estimation.speed || 0;
  }

  const lastPoint = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPoint) {
    currentSpeed = lastPoint.speed;
  }

  return formatMps({
    distance: currentSpeed,
    unit: speedUnit,
  });
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getInclinationValue = (
  inspectionState: InspectionProviderValue,
) => {
  const points = inspectionState.run?.trackingpoint_set || []

  let inclination: number | string = 0;

  if (!inspectionState.run?.launched) {
    return '-';
  }

  // TODO: Why estimation returns a number sometimes, maybe the id not serialized?
  if (
    inspectionState.run?.launched &&
    !inspectionState.run?.is_finished &&
    typeof inspectionState.run?.estimation === 'object' &&
    inspectionState.run?.estimation?.inclination
  ) {
    inclination = inspectionState.run?.estimation.inclination;
    inclination = parseFloat(`${inspectionState.run?.estimation.inclination}`);
    return `${inclination.toFixed(1)}°`;
  }

  const lastPoint = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPoint) {
    inclination = parseInt(lastPoint.speed)

    return inclination ? `${inclination.toFixed(1)}°` : '-';
  }

  return '-';
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getElevationValue = (
  inspectionState: InspectionProviderValue,
  distanceUnit: Unit | undefined
) => {
  const points = inspectionState.run?.trackingpoint_set || []

  if (!inspectionState.run?.launched) {
    return '-';
  }

  let elevation = 0;

  if (
    inspectionState.run?.launched &&
    !inspectionState.run?.is_finished &&
    inspectionState.run?.estimation
  ) {
    elevation = inspectionState.run?.estimation.elevation || 0;
  }

  const lastPoint = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPoint) {
    elevation = parseInt(lastPoint.elevation)
  }

  return formatDistance({
    distance: elevation,
    unit: distanceUnit?.id,
  });
};
