/**
 * 
 */
const ScanIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
    <path d="M6.5 20H3.5C1.57 20 0 18.43 0 16.5V13.5C0 13.224 0.224 13 0.5 13C0.776 13 1 13.224 1 13.5V16.5C1 17.878 2.121 19 3.5 19H6.5C6.776 19 7 19.224 7 19.5C7 19.776 6.776 20 6.5 20ZM24 16.5V13.5C24 13.224 23.776 13 23.5 13C23.224 13 23 13.224 23 13.5V16.5C23 17.878 21.879 19 20.5 19H17.5C17.224 19 17 19.224 17 19.5C17 19.776 17.224 20 17.5 20H20.5C22.43 20 24 18.43 24 16.5ZM24 6.5V3.5C24 1.57 22.43 0 20.5 0H17.5C17.224 0 17 0.224 17 0.5C17 0.776 17.224 1 17.5 1H20.5C21.879 1 23 2.122 23 3.5V6.5C23 6.776 23.224 7 23.5 7C23.776 7 24 6.776 24 6.5ZM1 6.5V3.5C1 2.122 2.121 1 3.5 1H6.5C6.776 1 7 0.776 7 0.5C7 0.224 6.776 0 6.5 0H3.5C1.57 0 0 1.57 0 3.5V6.5C0 6.776 0.224 7 0.5 7C0.776 7 1 6.776 1 6.5ZM5 15.5V4.5C5 4.224 4.776 4 4.5 4C4.224 4 4 4.224 4 4.5V15.5C4 15.776 4.224 16 4.5 16C4.776 16 5 15.776 5 15.5ZM15 15.5V4.5C15 4.224 14.776 4 14.5 4C14.224 4 14 4.224 14 4.5V15.5C14 15.776 14.224 16 14.5 16C14.776 16 15 15.776 15 15.5ZM9 15V5C9 4.448 8.553 4 8 4C7.447 4 7 4.448 7 5V15C7 15.552 7.447 16 8 16C8.553 16 9 15.552 9 15ZM12 15V5C12 4.448 11.553 4 11 4C10.447 4 10 4.448 10 5V15C10 15.552 10.447 16 11 16C11.553 16 12 15.552 12 15ZM20 15V5C20 4.448 19.553 4 19 4C18.447 4 18 4.448 18 5V15C18 15.552 18.447 16 19 16C19.553 16 20 15.552 20 15ZM17 15.5V4.5C17 4.224 16.776 4 16.5 4C16.224 4 16 4.224 16 4.5V15.5C16 15.776 16.224 16 16.5 16C16.776 16 17 15.776 17 15.5Z" fill="white"/>
  </svg>
);

export default ScanIcon;
