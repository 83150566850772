import 'jquery';
import 'jquery-ui/dist/jquery-ui.min.js';

// configuration of events to each column
export const sheetEvents = {
  type: { onClick: 'selectPoint' },
  name: { onChange: 'handleCommonTextChange' },
  milepost: { onChange: 'handleCommonTextChange' },
  description: { onChange: 'handleCommonTextChange' },
  comment: { onChange: 'handleCommonTextChange' },
  depth_of_cover: { onChange: 'handleCommonTextChange' },
  alignment_sheet: { onChange: 'handleCommonTextChange' },
  speed_offset: { onChange: 'handleCommonTextChange' },
  device_sn: { onChange: 'handleCommonTextChange' },
  county: { onChange: 'handleCommonTextChange' },
  country: { onChange: 'handleCommonTextChange' },
  state: { onChange: 'handleCommonTextChange' },
  tract: { onChange: 'handleCommonTextChange' },
  chainage: { onChange: 'handleCommonTextChange' },
  elevation: { onChange: 'handleCommonTextChange' },
  pressure_differential: { onChange: 'handleCommonTextChange' },
  location_description: { onChange: 'handleCommonTextChange' },
  antenna_height: { onChange: 'handleCommonTextChange' },
  datum: { onChange: 'handleCommonTextChange' },
  elevation_top: { onChange: 'handleCommonTextChange' },
  ellipsoid_height: { onChange: 'handleCommonTextChange' },
  ellipsoid_height_cop: { onChange: 'handleCommonTextChange' },
  geoid_model: { onChange: 'handleCommonTextChange' },
  survey_accuracy: { onChange: 'handleCommonTextChange' },
  survey_method: { onChange: 'handleCommonTextChange' },
  survey_notes: { onChange: 'handleCommonTextChange' },
  survey_technician: { onChange: 'handleCommonTextChange' },
  surveyor: { onChange: 'handleCommonTextChange' },
  longitude: { onChange: 'handleCommonTextChange' },
  latitude: { onChange: 'handleCommonTextChange' },
  land_owner: { onChange: 'handleCommonTextChange' },
  owner_phone: { onChange: 'handleCommonTextChange' },
  ht_correction_top: { onChange: 'handleCommonTextChange' },
  height_comment: { onChange: 'handleCommonTextChange' },
  field_notes: { onChange: 'handleCommonTextChange' },
  reference_date: { onChange: 'handleCommonTextChange' },
  reference_time: { onChange: 'handleCommonTextChange' },
  reference_technician: { onChange: 'handleCommonTextChange' },
  cell_coverage: { onChange: 'handleCommonTextChange' },
  site_access: { onChange: 'handleCommonTextChange' },
};
