import styled from 'styled-components';

export const NavigationWrapper = styled.nav`
  display: none;

  @media (min-width: 1024px) {
    display: grid;

    grid-template-rows: auto 1fr auto;
    background-color: #f0f2f3;

    height: 100vh;
    position: sticky;
    top: 0;
  }
`;

export const NavigationHeader = styled.header`
  padding: 8px 12px;
`;

export const NavigationList = styled.ul`
  padding-top: 18px;
  padding-bottom: 18px;
`;

export const NavigationLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #6b7280;
`;

export const NavigationItem = styled.li<{ mobile?: boolean }>`
  display: ${({ mobile }) => (mobile ? 'block' : 'none')};
  padding: 10px 18px;
  transition-duration: 200ms;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: block;
  }

  &:hover {
    background-color: #5850ec;

    a {
      color: #ffffff;
    }

    svg {
      position: relative;
      top: 1px;
      fill: #ffffff;

      .blank-hover {
        stroke: #ffffff;
        opacity: 1;
      }

      .blank-fill {
        fill: #ffffff;
      }
    }
  }
`;

export const NavigationItemIcon = styled.figure`
  > svg {
    fill: #6b7280;
    width: 22px;
    height: 22px;
  }
`;

export const NavigationItemText = styled.span``;

export const NavigationFooter = styled.footer`
  padding: 12px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const GtLogo = styled.img`
  width: 30px;
`;

export const GtNameLogoSvg = styled.svg`
  height: 12px;
  margin-left: 6px;
`;

export const InfoWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 3px;
`;

export const ProfileName = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #161e2e;
`;

export const ProfileEmail = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  display: inline-block;
  overflow: hidden;
  width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #686c7b;
`;

export const ProfileButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContactUs = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4em;

  color: #686c7b;

  > strong {
    font-weight: 500;
  }

  margin-bottom: 16px;
`;
