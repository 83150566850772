import axios from 'axios';
import { useEffect, useState } from 'react';

/**
 * check connection status
 */
const getStatus = () =>
  axios.get(`/status.json`);

export type StatusType = {
  online: boolean;
  since: string;
};

export const useStatus = (noConnectionSince: Date | undefined) => {
  const [status, setStatus] = useState<StatusType | undefined>(undefined);
  const [upgraded, setUpgraded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  /**
   *
   */
  const fetchStatus = () => {
    getStatus()
    .then((response) => {
      const statusSince = status?.since ? new Date(status?.since) : undefined;
      const responseSince = new Date(response.data.since);

      setUpgraded(response?.data.online && statusSince && statusSince < responseSince);
      setStatus(response.data);

      if (!response.data.online) {
        setTimeout(fetchStatus, 2000);
      }
    })
    .catch((error) => {
      setTimeout(fetchStatus, 3000);
      setError(error.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchStatus();
  }, [!!noConnectionSince]);

  return { status, upgraded, loading, error };
};
