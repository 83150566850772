import { useEffect, useState } from 'react';
import { AvatarIMG, EmptyAvatar } from './avatar.style';

interface Props {
  src?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  size?: number;
  customStyle?: string;
  withPointer?: boolean;
  id?: string;
  onClick?: (data: any) => void;
}

const Avatar = ({
  src,
  email,
  firstName,
  lastName,
  size,
  customStyle,
  onClick,
  withPointer,
}: Props) => {
  const [loaddedSrc, setLoaddedSrc] = useState<string | null>(null);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => setLoaddedSrc(src);
      img.src = src;
    }
  }, []);
  if (!firstName && !lastName) return null;
  return loaddedSrc ? (
    <AvatarIMG
      size={size}
      src={loaddedSrc}
      alt=""
      withPointer={withPointer}
      title={`${firstName} ${lastName} - ${email}`}
      customStyle={customStyle}
      onClick={onClick}
    />
  ) : (
    <EmptyAvatar
      customStyle={customStyle}
      size={size}
      withPointer={withPointer}
      onClick={onClick}
      title={`${firstName} ${lastName} - ${email}`}
    >{`${firstName ? firstName[0] : email[0]}${
      lastName ? lastName[0] : ''
    }`}</EmptyAvatar>
  );
};

Avatar.defaultProps = {
  size: 32,
};

export default Avatar;
