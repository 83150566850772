import { useEffect, useState } from 'react';
import { Props } from './satellite-passages.d';
import {
  Content,
  Empty,
  EmptyWrapper,
  PassagesSection,
  SectionTitle,
} from './satellite-passages.style';
import { onApproveTrigger, onRejectTrigger } from './satellite-passages.controller';
import { TriggerType } from 'app/modules/detection/detection.interfaces';
import TriggerCard from './components/trigger-card/trigger-card';

/**
 * list of passages triggered by devices,
 * to triggers from qube, this would show the confidence level
 * @param props
 * @returns
 */
const SatellitePassages = ({
  agm,
  authToken,
  onTriggerClick,
  onTriggerMoreInfosClick,
  selected,
  isObserver,
  columns = 1,
  noActions = false
}: Props) => {
  const [approved, setApproved] = useState<TriggerType[]>();
  const [toBeApproved, setToBeApproved] = useState<TriggerType[]>();
  const [rejected, setRejected] = useState<TriggerType[]>();

  useEffect(() => {
    const newApproved: TriggerType[] = [];
    const newRejected: TriggerType[] = [];
    const newToBeApproved: TriggerType[] = [];

    agm?.trigger_set?.forEach(
      (t) => {
        if (t.processed && t.passage) newApproved.push(t);
        else if (t.processed && !t.passage) newRejected.push(t);
        else if (!t.processed) newToBeApproved.push(t);
      }
    );

    setApproved(newApproved);
    setToBeApproved(newToBeApproved);
    setRejected(newRejected);
  }, [JSON.stringify(agm?.trigger_set)]);

  if (!approved && !toBeApproved?.length && !rejected?.length ) {
    return (
      <Content columns={1}>
        <EmptyWrapper>
          <Empty>{"This AGM doesn't have any triggers yet..."}</Empty>
        </EmptyWrapper>
      </Content>
    )
  }

  /**
   *
   */
  const handleTriggerClick = () => {
    if (approved && onTriggerMoreInfosClick) onTriggerMoreInfosClick(approved[0] as unknown as TriggerType);
  };

  return (
    <Content columns={columns}>
      <PassagesSection id="approved-list">
        <SectionTitle>APPROVED</SectionTitle>

        {approved?.map((ap) => 
          <TriggerCard
            onClick={() => onTriggerClick?.(ap)}
            key={ap.id}
            trigger={ap}
            selected={selected?.id === ap.id}
            hasActions={!isObserver && !noActions}
            onReject={() => {
              onRejectTrigger({
                triggerId: ap.id,
                token: authToken,
              });

            }}
            onMoreInfoClick={onTriggerMoreInfosClick ? handleTriggerClick : undefined}
          />
        ) || (
          <Empty>There are no approved triggers yet</Empty>
        )}
      </PassagesSection>

      <PassagesSection id="to-be-approved-list">
        <SectionTitle>
          NEW TRIGGERS ({toBeApproved?.length || '-'})
        </SectionTitle>

        {toBeApproved?.length ? (
          [...toBeApproved]?.map((trigger) => (
            <TriggerCard
              onClick={() => onTriggerClick?.(trigger)}
              key={trigger.id}
              trigger={trigger}
              selected={selected?.id === trigger.id}
              hasActions={!isObserver && !noActions}
              onApprove={() => {
                onApproveTrigger({
                  triggerId: trigger.id,
                  token: authToken,
                });

              }}
              onReject={() => {
                onRejectTrigger({
                  triggerId: trigger.id,
                  token: authToken,
                });

              }}
              onMoreInfoClick={onTriggerMoreInfosClick ? () => onTriggerMoreInfosClick(trigger) : undefined}
            />
          ))
        ) : (
          <Empty>There are no triggers to be approved yet</Empty>
        )}
      </PassagesSection>

      <PassagesSection id="refected-list">
        <SectionTitle>REJECTED ({rejected?.length || '-'})</SectionTitle>
        {rejected?.length ? (
          rejected?.map((trigger) => (
            <TriggerCard
              onClick={() => onTriggerClick?.(trigger)}
              key={trigger.id}
              trigger={trigger}
              hasActions={!isObserver && !noActions}
              selected={selected?.id === trigger.id}
              onApprove={() => {
                onApproveTrigger({
                  triggerId: trigger.id,
                  token: authToken,
                });

              }}
              onMoreInfoClick={onTriggerMoreInfosClick ? () => onTriggerMoreInfosClick(trigger) : undefined}
            />
          ))
        ) : (
          <Empty>There are no rejected triggers yet</Empty>
        )}
      </PassagesSection>
    </Content>
  );
};

export default SatellitePassages;
