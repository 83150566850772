/**
 * 
 */
const RemoveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
    <rect width="23.0312" height="22" rx="3" fill="#3E38B3"/>
    <rect x="17.7969" y="10" width="2" height="12.5625" rx="1" transform="rotate(90 17.7969 10)" fill="white"/>
  </svg>
);

export default RemoveIcon;
