import { useContext, useEffect, useState } from 'react';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import ButtonComponent from '../button/button';
import InputGroup from '../input-group/inputGroup';
import Input from '../input/input';
import { saveProject } from './editProjectModal.controller';
import { Project } from './editProjectModal.interfaces';
import {
  ModalWrapper,
  Modal,
  Title,
  Header,
  CloseIcon,
  TextArea,
  Container,
  Form,
  IlustrationWrapper,
  Dataset,
  FormWrapper,
} from './editProjectModal.style';
import { NewProjectIlustration } from './svgs/newProject.icons';

/**
 * 
 * @param param
 * @returns 
 */
const EditProjectModal = ({ hide, closeAction, project, cb }: any) => {
  // contexts
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  const [name, setName] = useState(project?.name);
  const [description, setDescription] = useState(project?.description);

  useEffect(() => {
    setName(project?.name);
    setDescription(project?.description);
  }, [project?.name, project?.description]);

  return (
    <ModalWrapper hide={hide}>
      <Modal>
        <Header>
          <CloseIcon onClick={() => closeAction()}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
          </CloseIcon>
        </Header>
        <Container>
          <FormWrapper>
            <Form>
              <Title>Edit project</Title>
              <Dataset>
                <InputGroup customStyle="padding-right: 6px;" label="Name">
                  <Input
                    id="update-project-name"
                    value={name}
                    handleChange={({ target }) => setName(target.value)}
                  />
                </InputGroup>
              </Dataset>
              <InputGroup label="Project Description">
                <TextArea
                  id="update-project-description"
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                />
              </InputGroup>
              <ButtonComponent
                value="Save"
                id="update-project-confirm"
                handleClick={() => {
                  const projectData: Project = {
                    name,
                    description,
                    id: project.id,
                    customer_id: project?.customer?.id,
                  };
                  saveProject(projectData, auth, closeAction, cb);
                }}
              />
            </Form>
          </FormWrapper>
          <IlustrationWrapper>
            <NewProjectIlustration />
          </IlustrationWrapper>
        </Container>
      </Modal>
    </ModalWrapper>
  );
};

EditProjectModal.defaultProps = {};

export default EditProjectModal;
