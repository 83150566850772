import { useState } from 'react';
import {
  Avatar,
  ButtonWrapper,
  Dropdown,
  DropdownHeader,
  DropdownAction,
  DropdownActions,
  ProfileName,
  ProfileEmail,
  ProfileRole,
} from './profile-button.style';

interface ProfileType {
  name: string;
  email: string;
  avatar?: string | undefined | null;
  role: 'administrator' | 'editor' | 'observer' | 'user';
}
interface ProfileButtonProps {
  profile: ProfileType;
  logout: () => void;
  openProfileModal: () => void;
}

const ProfileButton = ({ profile, logout, openProfileModal }: ProfileButtonProps) => {
  const [opened, setOpened] = useState<boolean>(false);

  const roles = {
    administrator: 'Administrator',
    editor: 'Editor',
    observer: 'Observer',
    user: 'User',
  };

  return (
    <ButtonWrapper>
      {profile.avatar ? (
        <Avatar
          onClick={() => setOpened(!opened)}
          tabIndex={0}
          onBlur={(event: any) => {
            if (event.relatedTarget?.id === 'gt-navigation-dropdown') return;
            setOpened(false);
          }}
          src={profile.avatar}
          alt=""
        />
      ) : (
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setOpened(!opened)}
          style={{
            borderRadius: '50%',
            border: 'solid 2px #ffffff',
            boxShadow: opened ? '0px 0px 0px 2px #5850ec' : '',
          }}
          tabIndex={0}
          onBlur={(event: any) => {
            if (event.relatedTarget?.id === 'gt-navigation-dropdown') return;
            setOpened(false);
          }}
        >
          <circle cx="18" cy="18" r="18" fill="white" />
          <mask
            id="mask0_963_831"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="36"
            height="36"
          >
            <circle cx="18" cy="18" r="18" fill="white" />
          </mask>
          <g mask="url(#mask0_963_831)">
            <circle cx="18" cy="46.9999" r="24" fill="#B8C0C6" />
          </g>
          <circle cx="18" cy="14.9998" r="5" fill="#5D6C77" />
        </svg>
      )}
      <Dropdown
        opened={opened}
        tabIndex={0}
        id="gt-navigation-dropdown"
        onBlur={(event: any) => {
          if (event.relatedTarget?.id === 'gt-navigation-dropdown') return;
          setOpened(false);
        }}
      >
        <DropdownHeader>
          <ProfileName>{profile.name}</ProfileName>
          <ProfileEmail>{profile.email}</ProfileEmail>
          <ProfileRole>
            {roles[profile.role] || roles['administrator']}
          </ProfileRole>
        </DropdownHeader>
        <DropdownActions>
          <DropdownAction
            onClick={(e) => {
              e.stopPropagation();
              setOpened(false);
              openProfileModal();
            }}
          >
            <svg
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7257 9.78C14.7577 9.524 14.7817 9.264 14.7817 9C14.7817 8.736 14.7577 8.476 14.7257 8.22L16.4177 6.896C16.5697 6.776 16.6137 6.56 16.5137 6.384L14.9137 3.612C14.8137 3.44 14.6057 3.368 14.4257 3.44L12.4337 4.244C12.0217 3.928 11.5697 3.66 11.0817 3.456L10.7817 1.336C10.7457 1.148 10.5817 1 10.3817 1H7.1817C6.9817 1 6.8177 1.148 6.7857 1.336L6.4857 3.456C5.9977 3.66 5.5457 3.924 5.1337 4.244L3.1417 3.44C2.9617 3.372 2.7537 3.44 2.6537 3.612L1.0537 6.384C0.953698 6.556 0.997698 6.772 1.1497 6.896L2.8377 8.22C2.8057 8.476 2.7817 8.736 2.7817 9C2.7817 9.264 2.8057 9.524 2.8377 9.78L1.1497 11.104C0.997698 11.224 0.953698 11.44 1.0537 11.616L2.6537 14.388C2.7537 14.56 2.9617 14.632 3.1417 14.56L5.1337 13.756C5.5457 14.072 5.9977 14.34 6.4857 14.544L6.7857 16.664C6.8177 16.852 6.9817 17 7.1817 17H10.3817C10.5817 17 10.7457 16.852 10.7777 16.664L11.0777 14.544C11.5657 14.34 12.0177 14.076 12.4297 13.756L14.4217 14.56C14.6017 14.628 14.8097 14.56 14.9097 14.388L16.5097 11.616C16.6097 11.444 16.5657 11.228 16.4137 11.104L14.7257 9.78ZM8.7817 11.8C7.2337 11.8 5.9817 10.548 5.9817 9C5.9817 7.452 7.2337 6.2 8.7817 6.2C10.3297 6.2 11.5817 7.452 11.5817 9C11.5817 10.548 10.3297 11.8 8.7817 11.8Z"
                stroke="#697386"
                strokeWidth="2"
              />
            </svg>
            Settings
          </DropdownAction>
          <DropdownAction
            onClick={(e) => {
              e.stopPropagation();
              setOpened(false);
              logout();
            }}
          >
            <svg
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 1H2C1.44772 1 1 1.44772 1 2V16C1 16.5523 1.44772 17 2 17H6"
                stroke="#697386"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.5 12V11.5C6.5 9.84315 7.84315 8.5 9.5 8.5H14.5"
                stroke="#697386"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 5.5L16 8.5L13 11.5"
                stroke="#697386"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Logout
          </DropdownAction>
        </DropdownActions>
      </Dropdown>
    </ButtonWrapper>
  );
};

export default ProfileButton;
