import styled from 'styled-components';

export const Container = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Form = styled.div`
  width: 450px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Explanation = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;

  color: #6b7280;
  font-size: 10px;
`;

export const Title = styled.label`
  display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18;
  line-height: 22px;
  color: #161e2e;
`;

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const IlustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #eff0f3;
  @media (max-width: 1024px) {
    svg {
      max-width: 400px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SelectInput = styled.select`
  padding: 5px 15px 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;
  &:active {
    padding: 5px 15px 5px 10px;
    border: 1px solid #5850ec;
  }
  &:focus {
    padding: 5px 15px 5px 10px;
    border: 1px solid #5850ec;
  }
  ${({ disabled }) => (disabled ? 'cursor: nor-allowed;' : '')}
  ${({ customStyle }: any) => customStyle}
`;
