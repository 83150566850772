import styled from 'styled-components';

export const ModalWrapper = styled.div<{ opened: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 30;
`;

export const Modal = styled.div`
  width: 100%;
  max-width: 520px;

  background: #ffffff;

  border-radius: 6px;
`;

export const ModalHeader = styled.header`
  position: relative;
  padding: 24px 24px 16px 24px;
`;

export const ModalContent = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 24px;
  background-color: #f7f8f9;
`;

export const InviteForm = styled.form``;

export const ModalTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;

  color: #161e2e;

  &:active {
    padding: 0;
    border: none;
  }

  &:focus {
    padding: 0;
    border: none;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  padding: 0;
  border: none;
  height: 28px;

  &:active {
    padding: 0;
    border: none;
  }

  &:focus {
    padding: 0;
    border: none;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;

  position: absolute;
  top: 16px;
  right: 16px;

  cursor: pointer;
`;

export const MultiSelectInputWrapper = styled.div<{ focus: boolean }>`
  position: relative;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: 4px;

  min-height: 36px;
  padding: 5px 10px;
  border: 1px solid ${({ focus }) => (!focus ? '#e5e5e5' : '#5850ec')};
  border-radius: 3px;
  width: 100%;

  > div {
    display: grid;

    > ul {
      display: flex;
      flex-wrap: wrap;
      column-gap: 4px;

      > li {
        margin-bottom: 6px;
      }
    }
  }
`;

export const Email = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #161e2e;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
  border-radius: 3px;

  padding: 6px 8px;

  > svg {
    width: 16px;
    height: 16px;

    cursor: pointer;
  }
`;

export const PredictUsersWrapper = styled.ul``;

export const Error = styled.p`
  font-weight: 500;
  font-size: 0.8em;
  line-height: 0.93em;
  color: #dd0001;

  margin-top: 16px;
`;

export const ShareableLinkWrapper = styled.div``;

export const OrWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 16px;

  > hr {
    width: 100%;
    border-top: solid 1px #e5e5e5;
    border-left: solid 1px #e5e5e5;
  }

  > span {
    font-size: 16px;
    font-weight: 500;
    color: rgba(104, 108, 123, 0.7);

    text-transform: uppercase;
  }
`;

export const ModalDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  margin-top: 8px;

  color: #686c7b;
`;
