import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  > svg {
    width: 20px;
    height: 20px;

    position: absolute;
    right: 10px;
    top: calc(50% - 10px);

    cursor: pointer;

    opacity: 0.3;
  }
`;

export const InputComponent = styled.input<{ customStyle: string }>`
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;

  &:active {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }

  &:focus {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }
  ${({ customStyle }) => customStyle}
`;
