import { InputComponent } from './input.style';

interface Props {
  value: string;
  handleChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  customStyle?: string;
  type?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  testid?: string;
}

/**
 * Input component
 * @returns
 */
const Input = ({
  value,
  handleChange,
  placeholder,
  type,
  customStyle,
  disabled,
  name,
  id,
  onBlur,
  testid,
}: Props) => {
  return (
    <InputComponent
      $customStyle={customStyle}
      value={value}
      name={name}
      id={id}
      type={type}
      onBlur={onBlur}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      data-testid={testid}
    />
  );
};

Input.defaultProps = {
  value: '',
  id: '',
  customStyle: '',
  placeholder: '',
  type: 'text',
  handleChange: () => '',
};

export default Input;
