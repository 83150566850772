import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  display: none;
  gap: 4px;
`;

export const Note = styled.li`
  position: relative;

  padding: 12px;

  background: #dcdfe2;
  backdrop-filter: blur(10px);

  box-shadow: 0px 2px 4px rgba(31, 63, 97, 0.1);
  border-radius: 4px;

  &:hover > .note-actions-wrapper {
    display: flex;
  }

  cursor: pointer;
`;

export const NoteText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;

  min-height: 60px;

  color: #767986;
`;

export const CreatedAt = styled.time`
  display: inline-block;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  opacity: 0.6;
  color: #767986;

  margin-top: 8px;
`;

export const NoteActionButton = styled.div`
  width: 16px;
  height: 16px;

  background-color: transparent;

  opacity: 0.6;
  cursor: pointer;

  transition-duration: 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 60px;
  height: auto;
  overflow-y: hidden;
  resize: none;

  background-color: transparent;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #767986;
`;

export const Loading = styled.div<{ show: boolean }>`
  position: absolute;
  display: ${({ show }) => (show ? 'block' : 'none')};
  top: 8px;
  right: 8px;

  opacity: 0.3;

  width: 16px;
  height: 16px;
`;

export const NoteSaveWrapper = styled.div``;

export const NoteSaveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  height: 28px;
  background-color: transparent;
  border: 0.5px solid #5850ec;
  border-radius: 26px;
  padding-left: 12px;
  padding-right: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #5850ec;

  > svg {
    width: 12px;
    height: 12px;
  }
`;
