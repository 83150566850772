import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;
  padding: 5px;

  &:active {
    border: 1px solid #5850ec;
    padding: 5px;
  }

  &:focus-within {
    border: 1px solid #5850ec;
    padding: 5px;
  }
`;

export const Select = styled.select`
  height: 100%;
`;

export const Input = styled.input`
  height: 100%;
`;
