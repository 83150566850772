import styled from 'styled-components';

export const ModalWrapper = styled.div<{ hide?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  overflow-y: auto;

  z-index: 21;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;

  &:after {
    content: '';
    display: block;
    height: 32px;
  }
`;

export const Modal = styled.div`
  position: relative;

  width: 100%;
  max-width: 800px;

  border-radius: 6px;

  background-color: #f1f2f3;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 16px;
  justify-content: space-between;

  background-color: #ffffff;
`;

export const Title = styled.h3`
  font-size: 21px;
  color: #1a1f36;
  font-weight: 500;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const PlotWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 187px);
  padding: 16px;
  gap: 16px;
  background-color: #f1f2f3;
`;

export const Widget = styled.div`
  display: inline-block;
  padding: 8px 16px;
  background-color: #fff;
  height: auto;
  border-radius: 8px;
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

export const Value = styled.label`
  font-size: 26px;
  line-height: 35px;
  font-weight: 500;
  color: #1a1f36;
  display: block;
`;

export const Label = styled.label`
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  display: block;
  color: #676b7a;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
`;

export const CanvasContainer = styled.div`
  max-width: 100%;
  background-color: #f1f2f3;

  margin-bottom: 16px;
  padding-left: 16px;
`;

export const ChartCanvas = styled.canvas`
  max-height: 300px;
  width: 100%;
`;
