import { Editors } from 'slickgrid';
import MobileDetect from 'mobile-detect';
import {
  activeFormatter,
  coordsFormatter,
  defaultFormatter,
  numberFormatter,
  typeFormatter,
  distanceFormatter,
  nameFormatter,
  indexFormatter,
} from './formatters';
import { QuickColumn } from './sheet.d';
import PassageEditor from '../../components/slick-table/editors/PassageEditor';
import DistanceEditor from '../../components/slick-table/editors/DistanceEditor';
import { Unit } from 'app/modules/settings/settings.context.d';

// define editors
const editors = {
  Text: Editors.Text,
  Passage: PassageEditor,
  Distance: DistanceEditor,
};

type EditorsKey = keyof typeof editors;

// pattern: id[*]:unit_type, label, column width, formatter, affected by
// if id has _ in the end means that is readonly field
const index: QuickColumn = ['index*', '#', 35, indexFormatter];
const type: QuickColumn = ['type*', 'Type', 40, typeFormatter, ['color', 'active', 'name', 'is_device_trigger']];
const active: QuickColumn = ['active*', 'Hold', 40, activeFormatter];
const pname: QuickColumn = ['name', 'Name', 120, nameFormatter];
const chainage: QuickColumn = [
  'chainage(Distance)',
  'Chainage',
  120,
  distanceFormatter,
];
const longitude: QuickColumn = ['longitude', 'Longitude', 120, coordsFormatter, ['geometry']];
const latitude: QuickColumn = ['latitude', 'Latitude', 120, coordsFormatter, ['geometry']];
const milepost: QuickColumn = [
  'milepost',
  'Milepost',
  undefined,
  numberFormatter,
];
const elevation: QuickColumn = [
  'elevation',
  'Elevation',
  120,
  numberFormatter,
];
const description: QuickColumn = ['description', 'Description', 150];
const comment: QuickColumn = ['comment', 'Comment', 150];
const depth_of_cover: QuickColumn = ['depth_of_cover', 'Depth of cover', 120];
const alignment_sheet: QuickColumn = ['alignment_sheet', 'Alignment Sheet', 150];
const speed_offset: QuickColumn = [
  'speed_offset:speed',
  'Speed Offset',
  110,
  numberFormatter,
];
const device_sn: QuickColumn = ['device_sn', 'Device Sn', 120];
const county: QuickColumn = ['county', 'County', 100];
const state: QuickColumn = ['state', 'State', 120];
const country: QuickColumn = ['country', 'Country', 120];
const tract: QuickColumn = ['tract', 'Tract', 120];
const pressure_differential: QuickColumn = [
  'pressure_differential',
  'Pressure Differential',
  120,
];
const location_description: QuickColumn = [
  'location_description',
  'Location Description',
  150
];
const antenna_height: QuickColumn = [
  'antenna_height',
  'Antenna Height',
  undefined,
  numberFormatter,
];
const datum: QuickColumn = ['datum', 'Datum'];
const elevation_top: QuickColumn = [
  'elevation_top',
  'Elevation (TOP)',
  undefined,
  numberFormatter,
];
const ellipsoid_height: QuickColumn = [
  'ellipsoid_height',
  'Ellipsoid Height',
  undefined,
  numberFormatter,
];
const ellipsoid_height_cop: QuickColumn = [
  'ellipsoid_height_cop',
  'Ellipsoid Height (COP)',
  undefined,
  numberFormatter,
];
const geoid_model: QuickColumn = ['geoid_model', 'Geoid Model'];
const survey_accuracy: QuickColumn = ['survey_accuracy', 'Survey Accuracy'];
const survey_method: QuickColumn = ['survey_method', 'Survey Method'];
const survey_notes: QuickColumn = ['survey_notes', 'Survey Notes'];
const survey_technician: QuickColumn = [
  'survey_technician',
  'Survey Technician',
];
const land_owner: QuickColumn = ['land_owner', 'Land Owner'];
const owner_phone: QuickColumn = ['owner_phone', 'Owner Phone'];
const ht_correction_top: QuickColumn = [
  'ht_correction_top',
  'Ht. Correction (TOP)',
  undefined,
  numberFormatter,
];
const height_comment: QuickColumn = ['height_comment', 'Height Comment'];
const field_notes: QuickColumn = ['field_notes', 'Field Notes'];
const reference_date: QuickColumn = ['reference_date', 'Reference Date'];
const reference_time: QuickColumn = ['reference_time', 'Reference Time'];
const reference_technician: QuickColumn = [
  'reference_technician',
  'Reference Technician',
];
const cell_coverage: QuickColumn = ['cell_coverage', 'Cell Coverage'];
const site_access: QuickColumn = ['site_access', 'Site Access'];

const baseColumnsDict = {
  index,
  active,
  type,
  pname,
  chainage,
  longitude,
  latitude,
  milepost,
  elevation,
  description,
  comment,
  depth_of_cover,
  alignment_sheet,
  speed_offset,
  device_sn,
  county,
  state,
  country,
  tract,
  pressure_differential,
  location_description,
  antenna_height,
  datum,
  elevation_top,
  ellipsoid_height,
  ellipsoid_height_cop,
  geoid_model,
  survey_accuracy,
  survey_method,
  survey_notes,
  survey_technician,
  land_owner,
  owner_phone,
  ht_correction_top,
  height_comment,
  field_notes,
  reference_date,
  reference_time,
  reference_technician,
  cell_coverage,
  site_access,
};

type KeyType = keyof typeof baseColumnsDict;

interface Units {
  speed: Unit | undefined;
  distance: Unit | undefined;
}

/**
 * check if field is read onlt
 * @param key
 * @returns string
 */
const isReadOnly = (key: string) => {
  return key.indexOf('*') !== -1;
};

/**
 * get id from key
 * @retuns string
 */
const getId = (key: string): string => {
  const rgxp = /\([A-z]+\)$/;
  return key.replace('*', '').replace(rgxp, '');
};

/**
 *
 */
const getEditor = (key: string) => {
  const rgxp = /\([A-z]+\)$/;
  if (!rgxp.test(key)) return Editors.Text;

  const indexStart = key.indexOf('(') + 1;
  const indexEnd = key.indexOf(')');

  const editorKey = key.substring(indexStart, indexEnd) as EditorsKey;

  // check if editor exist, if not an error will be dispatch

  if (!editors[editorKey]) {
    throw new Error(`Invalid editor key to the "${getId(key)}" field`);
  }

  return editors[editorKey];
};

/**
 * map quickly column array to SlickGrid Column object
 * @param quicklyColumn
 * @param units
 * @param editorMode
 * @returns Column
 */
export const mapColumn = (
  quickColumn: QuickColumn,
  units: Units,
  editorMode: boolean,
  events: any,
  noBaseColumns = false,
): any => {
  const md = new MobileDetect(window.navigator.userAgent);
  const ref = quickColumn[0];
  const affected_by = quickColumn[4];
  let name = quickColumn[1];
  let width = quickColumn[2];
  let formatter = quickColumn[3];

  // split id of unit
  const [key] = (ref as string).split(':');
  const id = getId(key);
  
  // get potential quick column defined preveously
  const baseQuickColumn: QuickColumn | undefined = baseColumnsDict[id];

  // check if this quick column exists on base columns
  if (!noBaseColumns && baseQuickColumn) {
    // if there are not the properties preveously defined
    // override this undefined properties
    name = name || baseQuickColumn[1];
    width = width || baseQuickColumn[2];
    formatter = formatter || baseQuickColumn[3];
  }

  // readonly check
  const readonly = isReadOnly(key);

  // define column and map the metadata
  const column: any = {
    id,
    field: id,
    name: name as string,
    originalWidth: width || 100,
    width: width || 100,
    formatter: formatter || defaultFormatter,
    resizable: !md.mobile() && !md.tablet(),
    affected_by,
    denyPaste: true,
    focusable: editorMode && !readonly,
  };

  // define editor if is necessary
  if (editorMode && !readonly) {
    column.editor = getEditor(key);
  }

  const event = events[id];
  if (event?.onChange) column.onCellChange = event.onChange;
  if (event?.onClick) column.onCellClick = event.onClick;
  if (event?.onDoubleClick) column.onCellDblClick = event.onDoubleClick;
  return column;
};

/**
 * get acquisition point data fields that is the base to
 * survey sheet and the tracking sheet columns
 **/
const getBaseColumns = (
  units: Units,
  editorMode: boolean,
  customColumns?: QuickColumn[],
  events?: any
) => {
  const columns = Object.keys(baseColumnsDict).map((key) => {
    const quicklyColumn = baseColumnsDict[key as KeyType];
    return mapColumn(quicklyColumn, units, editorMode, events);
  });

  // filter base columns overrided on custom columns
  if (customColumns) {
    return columns.filter(({ id }) => {
      return !customColumns.find((cQuickColumn) => {
        const [key] = (cQuickColumn[0] as string).split(':');
        const cid = getId(key);
        return id === cid;
      });
    });
  }

  return columns;
};

/**
 * get columns customized, filtered and sorted
 * @param units
 * @param editorMode
 * @param query
 * @param customColumns
 * @returns Column[]
 */
export const getColumns = (
  units: Units,
  editorMode = false,
  query?: string[],
  customColumns?: QuickColumn[],
  events?: any // TODO: type events
): any[] => {
  const baseColumns = getBaseColumns(units, editorMode, customColumns, events);
  const additionalColumns: any[] = [];

  if (customColumns) {
    additionalColumns.push(
      ...customColumns.map((quicklyColumn) =>
        mapColumn(quicklyColumn, units, editorMode, events)
      )
    );
  }

  // if not query is sent a;; base column more the additional column
  let columns = [...baseColumns, ...additionalColumns];

  // check if has query to sort and filter the columns
  if (query) {
    const newColumn: any[] = [];
    const setColumn = (id: string) => {
      const column = columns.find((c) => id === c.id);
      if (column) newColumn.push(column);
    };

    query.forEach(setColumn);
    columns = newColumn; // override columns to the sorted and filtered list by query
  }

  return columns;
};

/**
 * get columns customized, filtered and sorted
 * @param units
 * @param editorMode
 * @param query
 * @param customColumns
 * @returns Column[]
 */
export const parseQuickColumns = (
  columns: QuickColumn[],
  editorMode?: boolean,
  events?: any // TODO: type events
) => {
  return columns.map((quicklyColumn) => 
    mapColumn(
      quicklyColumn,
      { speed: undefined, distance: undefined,  },
      !!editorMode,
      events || {},
      true,
    ));
};
