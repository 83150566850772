import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import CloseIcon from '../../icons/close.icon';
import ScanIcon from '../../icons/scan.icon';
import {
  DeviceActions,
  DeviceCloseButton,
  DeviceContent,
  DeviceHeader,
  DeviceModal,
  DeviceModalDescription,
  DeviceModalTitle,
  DeviceModalWrapper,
  DeviceTable,
} from './device-scan-confirmation.style';
import DefaultButton from 'app/components/default-button/default-button';
import LinkButton from 'app/components/link-button/link-button';

interface Props {
  devices: QubeDeviceType[];
  onClose: () => void;
  onConfirm: () => void;
  onScanAnother: () => void;
}

/**
 * 
 */
const DeviceScanConfirmation = (props: Props) => {
  const renderDevices = () => props.devices.map((device) => {
    return (
      <tr key={device.serial}>
        <td>{device.serial}</td>
        <td>-</td>
        <td>-</td>
      </tr>
    );
  });
  
  return (
    <DeviceModalWrapper>
      <DeviceModal>
        <DeviceHeader>
          <DeviceCloseButton
            onClick={props.onClose}
          >
            <CloseIcon />
          </DeviceCloseButton>
        </DeviceHeader>

        <DeviceContent>
          <DeviceModalTitle>Confirm Devices</DeviceModalTitle>
          <DeviceModalDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </DeviceModalDescription>


          <DeviceTable>
            <tr>
              <th>Device name</th>
              <th>Type</th>
              <th>Battery level</th>
            </tr>

            {renderDevices()}
          </DeviceTable>
        </DeviceContent>


        <DeviceActions>
          <DefaultButton
            customStyle={`
              height: 40px;
            `}
            onClick={props.onConfirm}
          >
            Confirm Devices
          </DefaultButton>
          <LinkButton
            handleClick={props.onScanAnother}
            customStyle={`
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;
              color: #767986;
              padding: 0;

              > svg {
                width: 24px;
                stroke: #767986;
              }
            `}
          >
            <ScanIcon />
            Scan Another
          </LinkButton>
        </DeviceActions>
      </DeviceModal>
    </DeviceModalWrapper>
  );
};

export default DeviceScanConfirmation;
