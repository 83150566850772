import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: absolute;
  z-index: 21;
  top: 0;
  right: 0;

  display: grid;
  grid-template-columns: 1fr;

  width: 100%;
  max-height: 100%;

  color: #ffffff;

  @media (min-width: 1024px) {
    width: auto;
    grid-template-columns: 365px;
  }
`;

export const Modal = styled.div`
  position: relative;


  background: #FFF;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  margin 8px;
  color: #000;

  padding-bottom: 28px;

  > svg {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ConfirmationTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;

  margin-top: 14px;
  margin-bottom: 8px;
`;

export const ConfirmationFooter = styled.footer`
  display: grid;
  justify-content: space-between;
  align-items: end;

  grid-template-columns: 1fr;
  gap: 24px;

  margin-top: 24px;

  button {
    height: 36px;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;

  width: 100%;
`;

export const ErrorLabel = styled.label`
  display: block;

  font-size: 12px;

  color: red;

  width: 100%;
`;

export const EventExplanation = styled.p`
  display: block;

  font-size: 10px;

  width: 100%;
  margin-bottom: 24px;
`;

export const Main = styled.div`
  padding: 0 22px;
  border-top: 0.7px solid rgba(130, 132, 143, 0.7);
`;

export const Header = styled.div`
  padding: 0 22px;
`;

export const CloseButtonContainer = styled.button`
  height: 20px;
  border-radius: 6px;

  background-color: transparent;
  font-weight: 500;

  cursor: pointer;

  z-index: 1;
  color: #494c5d;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  margin: 10px 0;

  svg {
    position: relative;
    top: 1px;
    margin-right: 8px;
  }
`;

export const buttonCustomStyle = `
`;
