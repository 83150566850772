import { getProjectInvitation } from 'app/services/invitation.service';
import { InvitationType } from './invitation.page.d';
import { AccountProviderValue } from 'app/modules/account/account.context.d';

/**
 * 
 * @param token 
 * @param setInvitation 
 */
export const getInvitation = async (
  token: string,
  setInvitation: (invitation: InvitationType) => void
) => {
  const invitationResponse = await getProjectInvitation(token);
  setInvitation(invitationResponse.data);
};

/**
 * 
 * @param dispatch 
 * @param history 
 * @param invitationToken 
 * @param registered 
 */
export const logout = (
  dispatch: AccountProviderValue['dispatch'],
  history: any,
  invitationToken: string,
  registered: boolean
) => {
  if (!registered) {
    history.push(`/register?t=${invitationToken}&redirect_to=invitation`);
  } else {
    history.push(`/?redirect_to=invitation&t=${invitationToken}`);
  }

  window.localStorage.removeItem('CDI@auth-token');
  window.localStorage.removeItem('CDI@auth-email');
  dispatch({ type: 'LOGOUT' });
};
