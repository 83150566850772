import styled from 'styled-components';

export const GroupCheckbox = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;

  color: ${({ theme }) => theme.colors.neutra_300};

  > svg {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')}; 

    > rect {
      stroke: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.neutra_300)}; 
      fill: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.transparent)}; 
    }

    > path {
      stroke: ${({ theme }) => theme.colors.surface_white}; 
    }

    &.checked {
      > rect {
        fill: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.primary)}; 
      }
    }
    
  }

  > label {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.outline_on_surface)}; 
  }
`;

export const GroupLabel = styled.label`
  ${({ theme }) => theme.fonts.typograph.caption}
  margin-left: 8px;
`;
