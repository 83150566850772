import { Route, Switch } from "react-router-dom";
import ForgotPassword from "./forgot-password/forgot-password.page";
import Login from "./login/login.page";
import Register from "./register/register.page";

/**
 * 
 * @returns 
 */
const AccountModule = () => (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/forgot-password/:token" component={ForgotPassword} />
    </Switch>
  );
  
  export default AccountModule;
  