import { styled } from 'styled-components';

export const QubeListButtonContainer = styled.div`
  position: relative;
`;

export const QubeListButton = styled.button<{ $opened: boolean }>`
  background: ${({ $opened }) => ($opened ? '#d30000' : 'rgba(62, 66, 85, 0.65)')};
  backdrop-filter: blur(10px);
  border-radius: 50%;
  height: 30px;
  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  transition-duration: 200ms;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;

  > span {
    font-size: 18px;
  }

  &:hover {
    background: #d30000;
  }

  cursor: pointer;
`;

export const QubeListContainer = styled.ul``;

export const QubeListDropdown = styled.div`
  width: 350px;

  position: absolute;
  top: calc(100% + 15px);
  left: -150px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;
  z-index: 25;

  max-height: 260px;
  overflow-y: auto;

  > li + li {
    border-top: solid 1px #dcdfe2;
  }

  header {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;

    color: #090808;
  }
`;