import {
  Column,
  GridOption,
  SlickEventData,
  OnDblClickEventArgs,
  OnClickEventArgs,
  OnCellChangeEventArgs,
  SlickGrid,
  CustomDataView,
} from 'slickgrid';
import { Timezone, Unit } from "app/modules/settings/settings.context.d";
import GTDataView from './slick-table.dataview';

export interface Data {
  id: number;
}

export interface CustomCulumn extends Column {
  originalWidth?: number;
  affected_by?: string[];
  onCellDblClick?: (e: SlickEventData<OnDblClickEventArgs>, args: OnDblClickEventArgs) => void;
  onCellClick?: (e: SlickEventData<OnClickEventArgs>, args: OnClickEventArgs) => void;
  onCellChange?: (e: SlickEventData<OnCellChangeEventArgs>, args: OnCellChangeEventArgs) => void;
}

export class CustomSlickGrid<T extends Data> extends SlickGrid<T> {
  metadata?: SlickGridMetaData;
  constructor(
    element: HTMLElement,
    data: GTDataView<T>,
    columns: Column<any>[],
    options: GridOption,
  ) {
    super(element, data as unknown as CustomDataView<T>, columns, options);
  }
}

export interface SlickTableProps<T extends Data> {
  customStyle?: string;
  permissionType?: string;
  columns: CustomCulumn[];
  data: T[];
  grid: CustomSlickGrid<T> | undefined;
  options?: GridOption;
  onLoad?: (grid: CustomSlickGrid<T>, data: GTDataView<T>) => void;
  noColumnPicker?: boolean;
  metadata?: SlickGridMetaData;
}

export interface SlickRowProps<T extends Data> {
  data: T;
  dataView: GTDataView<T>;
  permissionType?: string;
  grid: CustomSlickGrid<T> | undefined;
  index: number;
}

export interface SlickCellProps<T extends Data> {
  data: T;
  dataView: GTDataView<T>;
  column: CustomCulumn;
  row: number;
  cell: number;
  permissionType?: string;
  grid: CustomSlickGrid<T> | undefined;
}

export interface SlickGridMetaData {
  permission_type: string | undefined;
  launched: boolean;
  units: {
    speed: Unit | undefined;
    distance: Unit | undefined;
    timezone: Timezone | undefined;
  };
}