import { ReactNode, useEffect } from 'react';
import Interact from 'interactjs';
import {
  ResizableContainer,
  DraggableComponent,
  ChidrenWrapper,
} from './resizable-wrapper.style';
import { InspectionContext } from 'app/modules/inspection/inspection.context.d';

interface ResizableWrapperProps {
  children: ReactNode;
  onDrag: ({ deltaY }: { deltaY: number }) => void;
  onDragEnd: ({ deltaY }: { deltaY: number }) => void;
  disabled: boolean;
  grid?: InspectionContext['grid'];
}

const ResizableWrapper = ({
  children,
  onDrag,
  onDragEnd,
  disabled,
  grid,
}: ResizableWrapperProps) => {
  useEffect(() => {
    if (grid) {
      Interact('.draggable').draggable({
        listeners: {
          move: (event: Interact.DragEvent) => {
            onDrag({ deltaY: event.dy });
          },
          dragLeave: (event: Interact.DragEvent) => {
            onDragEnd({ deltaY: event.dy });
          },
          end: (event: Interact.DragEvent) => {
            onDragEnd({ deltaY: event.dy });
          },
        },
      });
    }
  }, [grid]);

  return (
    <ResizableContainer id="GT_SHEET">
      <DraggableComponent disabled={disabled} className="draggable">
        <svg
          width="20"
          height="3"
          viewBox="0 0 20 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="1.5" r="1.5" fill="#767986" />
          <circle cx="10" cy="1.5" r="1.5" fill="#767986" />
          <circle cx="18" cy="1.5" r="1.5" fill="#767986" />
        </svg>
      </DraggableComponent>
      <ChidrenWrapper>{children}</ChidrenWrapper>
    </ResizableContainer>
  );
};

export default ResizableWrapper;
