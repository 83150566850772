import { useContext } from 'react';
import InspectionStore from 'app/modules/inspection/inspection.context';
import {
  MobileIngormationFigureDriveline,
  MobileIngormationFigureGrayPipeline,
  MobileIngormationFigureGreenPipeline,
  MobileIngormationFigureRedHighlight,
  MobileInformationModalContent,
  MobileInformationModalContentCloseButton,
  MobileInformationModalContentHeader,
  MobileInformationModalContentTitle,
  MobileInformationModalInformations,
  MobileInformationModalInformation,
  MobileInformationModalInformationDescription,
  MobileInformationModalInformationFigure,
  MobileInformationModalInformationLabel,
  MobileInformationModalInformationTitle,
  MobileInformationModalMapPointInformations,
  MobileInformationModalPipelinePointsInformations,
  MobileInformationModalWrapper,
} from './mobile-information-modal.style';

/**
 *
 */
const MobileInformationModal = () => {
  const inspectionContext = useContext(InspectionStore);

  /**
   * 
   */
  const handleClose = () => {
    inspectionContext.dispatch({ type: 'MOBILE_TOGGLE_INFORMATIONS_MODAL' });
  };

  return (
    <MobileInformationModalWrapper>
      <MobileInformationModalContent>
        <MobileInformationModalContentHeader>
          <MobileInformationModalContentCloseButton onClick={handleClose}>
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 9.5L9 18.5L10.4 17L3 9.5L10.4 2L9 0.5L0 9.5Z" fill="#323030"/>
            </svg>
          </MobileInformationModalContentCloseButton>

          <MobileInformationModalContentTitle>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.024 9C10.024 8.83424 9.95818 8.67527 9.84097 8.55806C9.72376 8.44085 9.56479 8.375 9.39902 8.375C9.23326 8.375 9.07429 8.44085 8.95708 8.55806C8.83987 8.67527 8.77402 8.83424 8.77402 9V12C8.77402 12.1658 8.83987 12.3247 8.95708 12.4419C9.07429 12.5592 9.23326 12.625 9.39902 12.625C9.56479 12.625 9.72376 12.5592 9.84097 12.4419C9.95818 12.3247 10.024 12.1658 10.024 12V9ZM10.0179 7.61836C10.1819 7.45436 10.274 7.23193 10.274 7C10.274 6.76778 10.1806 6.54559 10.017 6.38199C9.85313 6.21808 9.63082 6.126 9.39902 6.126C9.16723 6.126 8.94492 6.21808 8.78101 6.38199C8.61711 6.5459 8.52502 6.7682 8.52502 7C8.52502 7.23193 8.61716 7.45436 8.78116 7.61836C8.94516 7.78237 9.16759 7.8745 9.39952 7.8745C9.63146 7.8745 9.85389 7.78237 10.0179 7.61836ZM9.40002 2.375C7.51036 2.375 5.69809 3.12567 4.36189 4.46186C3.02569 5.79806 2.27502 7.61033 2.27502 9.5C2.27502 11.3897 3.02569 13.2019 4.36189 14.5381C5.69809 15.8743 7.51036 16.625 9.40002 16.625C11.2897 16.625 13.102 15.8743 14.4382 14.5381C15.7744 13.2019 16.525 11.3897 16.525 9.5C16.525 7.61033 15.7744 5.79806 14.4382 4.46186C13.102 3.12567 11.2897 2.375 9.40002 2.375ZM3.52502 9.5C3.52502 8.72848 3.67699 7.96452 3.97223 7.25173C4.26748 6.53895 4.70023 5.89129 5.24577 5.34575C5.79132 4.8002 6.43897 4.36745 7.15176 4.07221C7.86455 3.77696 8.62851 3.625 9.40002 3.625C10.1715 3.625 10.9355 3.77696 11.6483 4.07221C12.3611 4.36745 13.0087 4.8002 13.5543 5.34575C14.0998 5.89129 14.5326 6.53895 14.8278 7.25173C15.1231 7.96452 15.275 8.72848 15.275 9.5C15.275 11.0581 14.6561 12.5525 13.5543 13.6543C12.4525 14.756 10.9582 15.375 9.40002 15.375C7.84188 15.375 6.34755 14.756 5.24577 13.6543C4.144 12.5525 3.52502 11.0581 3.52502 9.5Z" fill="#4D5158" stroke="#4D5158" strokeWidth="0.25"/>
            </svg>
            Understanding your map
          </MobileInformationModalContentTitle>
        </MobileInformationModalContentHeader>

        <MobileInformationModalInformations>
          <MobileInformationModalMapPointInformations>
            <MobileInformationModalInformationTitle>Map Points</MobileInformationModalInformationTitle>

            <MobileInformationModalInformation>
              <MobileInformationModalInformationFigure>
                <svg width="40" height="40" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="96" height="96" rx="48" fill="#4E46E9" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z" fill="white" />
                  <path d="M12.751 59V56.0972L14.1953 55.8848L20.3408 38.3828H25.7217L31.8813 55.8848L33.3257 56.0972V59H25.6226V56.0972L26.9678 55.8423L26.3447 53.605H19.7178L19.123 55.8423L20.4683 56.0972V59H12.751ZM20.7656 50.1216H25.2827L23.272 43.877L23.0454 43.1548H22.9604L22.7197 43.9478L20.7656 50.1216ZM43.2378 59.2974C41.1043 59.2974 39.2965 58.882 37.8145 58.0513C36.3418 57.2205 35.2184 56.0688 34.4443 54.5962C33.6702 53.1141 33.2832 51.4007 33.2832 49.4561V48.5215C33.2832 46.473 33.6655 44.6652 34.4302 43.0981C35.1948 41.5311 36.304 40.3039 37.7578 39.4165C39.2116 38.5291 40.9674 38.0854 43.0254 38.0854C44.432 38.0854 45.8433 38.3026 47.2593 38.7368C48.6753 39.1616 49.9214 39.8366 50.9976 40.7617V45.5903H47.4575L46.707 42.9707C46.3861 42.6403 45.9282 42.3665 45.3335 42.1494C44.7482 41.9323 44.0874 41.8237 43.3511 41.8237C41.6424 41.8237 40.3586 42.4421 39.4995 43.6787C38.6405 44.9059 38.2109 46.5107 38.2109 48.4932V49.4561C38.2109 51.3252 38.6546 52.812 39.542 53.9165C40.4388 55.021 41.7368 55.5732 43.436 55.5732C44.0308 55.5732 44.6208 55.5119 45.2061 55.3892C45.7913 55.2664 46.2492 55.1296 46.5796 54.9785V52.3164L44.2573 52.0332V48.6064H51.564V57.1592C50.45 57.8766 49.1898 58.4147 47.7832 58.7734C46.3861 59.1227 44.8709 59.2974 43.2378 59.2974ZM53.0366 59V56.0972L55.1606 55.644V41.7529L53.0366 41.2998V38.3828H62.7646L67.749 51.9624H67.834L72.6768 38.3828H82.2915V41.2998L80.1533 41.7529V55.644L82.2915 56.0972V59H73.0591V56.0972L75.2822 55.644V52.9536L75.3955 43.2539L75.3105 43.2397L69.4766 59H65.5684L59.6494 43.4238L59.5503 43.438L59.8901 52.2739V55.644L62.2832 56.0972V59H53.0366Z" fill="white" />
                </svg>
              </MobileInformationModalInformationFigure>
              <div>
                <MobileInformationModalInformationLabel>
                  Blue points: <strong>Default</strong>
                </MobileInformationModalInformationLabel>
                <MobileInformationModalInformationDescription>
                  The Tool will arive and Hold here, awaiting a time input.
                </MobileInformationModalInformationDescription>
              </div>
            </MobileInformationModalInformation>
            <MobileInformationModalInformation>
              <MobileInformationModalInformationFigure>
                <svg width="40" height="40" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="96" height="96" rx="48" fill="#FEDE00" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z" fill="white" />
                  <path d="M12.751 59V56.0972L14.1953 55.8848L20.3408 38.3828H25.7217L31.8813 55.8848L33.3257 56.0972V59H25.6226V56.0972L26.9678 55.8423L26.3447 53.605H19.7178L19.123 55.8423L20.4683 56.0972V59H12.751ZM20.7656 50.1216H25.2827L23.272 43.877L23.0454 43.1548H22.9604L22.7197 43.9478L20.7656 50.1216ZM43.2378 59.2974C41.1043 59.2974 39.2965 58.882 37.8145 58.0513C36.3418 57.2205 35.2184 56.0688 34.4443 54.5962C33.6702 53.1141 33.2832 51.4007 33.2832 49.4561V48.5215C33.2832 46.473 33.6655 44.6652 34.4302 43.0981C35.1948 41.5311 36.304 40.3039 37.7578 39.4165C39.2116 38.5291 40.9674 38.0854 43.0254 38.0854C44.432 38.0854 45.8433 38.3026 47.2593 38.7368C48.6753 39.1616 49.9214 39.8366 50.9976 40.7617V45.5903H47.4575L46.707 42.9707C46.3861 42.6403 45.9282 42.3665 45.3335 42.1494C44.7482 41.9323 44.0874 41.8237 43.3511 41.8237C41.6424 41.8237 40.3586 42.4421 39.4995 43.6787C38.6405 44.9059 38.2109 46.5107 38.2109 48.4932V49.4561C38.2109 51.3252 38.6546 52.812 39.542 53.9165C40.4388 55.021 41.7368 55.5732 43.436 55.5732C44.0308 55.5732 44.6208 55.5119 45.2061 55.3892C45.7913 55.2664 46.2492 55.1296 46.5796 54.9785V52.3164L44.2573 52.0332V48.6064H51.564V57.1592C50.45 57.8766 49.1898 58.4147 47.7832 58.7734C46.3861 59.1227 44.8709 59.2974 43.2378 59.2974ZM53.0366 59V56.0972L55.1606 55.644V41.7529L53.0366 41.2998V38.3828H62.7646L67.749 51.9624H67.834L72.6768 38.3828H82.2915V41.2998L80.1533 41.7529V55.644L82.2915 56.0972V59H73.0591V56.0972L75.2822 55.644V52.9536L75.3955 43.2539L75.3105 43.2397L69.4766 59H65.5684L59.6494 43.4238L59.5503 43.438L59.8901 52.2739V55.644L62.2832 56.0972V59H53.0366Z" fill="white" />
                </svg>
              </MobileInformationModalInformationFigure>
              <div>
                <MobileInformationModalInformationLabel>
                  Yellow points: <strong>Awaiting tool arrival</strong>
                </MobileInformationModalInformationLabel>
                <MobileInformationModalInformationDescription>
                  The point is presently Holding for a time to input.
                </MobileInformationModalInformationDescription>
              </div>
            </MobileInformationModalInformation>
            <MobileInformationModalInformation>
              <MobileInformationModalInformationFigure>
                <svg width="40" height="40" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="96" height="96" rx="48" fill="#888888" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z" fill="white" />
                  <path d="M12.751 59V56.0972L14.1953 55.8848L20.3408 38.3828H25.7217L31.8813 55.8848L33.3257 56.0972V59H25.6226V56.0972L26.9678 55.8423L26.3447 53.605H19.7178L19.123 55.8423L20.4683 56.0972V59H12.751ZM20.7656 50.1216H25.2827L23.272 43.877L23.0454 43.1548H22.9604L22.7197 43.9478L20.7656 50.1216ZM43.2378 59.2974C41.1043 59.2974 39.2965 58.882 37.8145 58.0513C36.3418 57.2205 35.2184 56.0688 34.4443 54.5962C33.6702 53.1141 33.2832 51.4007 33.2832 49.4561V48.5215C33.2832 46.473 33.6655 44.6652 34.4302 43.0981C35.1948 41.5311 36.304 40.3039 37.7578 39.4165C39.2116 38.5291 40.9674 38.0854 43.0254 38.0854C44.432 38.0854 45.8433 38.3026 47.2593 38.7368C48.6753 39.1616 49.9214 39.8366 50.9976 40.7617V45.5903H47.4575L46.707 42.9707C46.3861 42.6403 45.9282 42.3665 45.3335 42.1494C44.7482 41.9323 44.0874 41.8237 43.3511 41.8237C41.6424 41.8237 40.3586 42.4421 39.4995 43.6787C38.6405 44.9059 38.2109 46.5107 38.2109 48.4932V49.4561C38.2109 51.3252 38.6546 52.812 39.542 53.9165C40.4388 55.021 41.7368 55.5732 43.436 55.5732C44.0308 55.5732 44.6208 55.5119 45.2061 55.3892C45.7913 55.2664 46.2492 55.1296 46.5796 54.9785V52.3164L44.2573 52.0332V48.6064H51.564V57.1592C50.45 57.8766 49.1898 58.4147 47.7832 58.7734C46.3861 59.1227 44.8709 59.2974 43.2378 59.2974ZM53.0366 59V56.0972L55.1606 55.644V41.7529L53.0366 41.2998V38.3828H62.7646L67.749 51.9624H67.834L72.6768 38.3828H82.2915V41.2998L80.1533 41.7529V55.644L82.2915 56.0972V59H73.0591V56.0972L75.2822 55.644V52.9536L75.3955 43.2539L75.3105 43.2397L69.4766 59H65.5684L59.6494 43.4238L59.5503 43.438L59.8901 52.2739V55.644L62.2832 56.0972V59H53.0366Z" fill="white" />
                </svg>
              </MobileInformationModalInformationFigure>
              <div>
                <MobileInformationModalInformationLabel>
                  Grey points: <strong>Do not hold Will not Hold</strong>
                </MobileInformationModalInformationLabel>
                <MobileInformationModalInformationDescription>
                  Will not Hold for a time input (often a preset AGM), or was skipped due to downstream point having been tracked
                </MobileInformationModalInformationDescription>
              </div>
            </MobileInformationModalInformation>
          </MobileInformationModalMapPointInformations>
          <MobileInformationModalPipelinePointsInformations>
            <MobileInformationModalInformationTitle>Pipeline Routes</MobileInformationModalInformationTitle>

            <MobileInformationModalInformation>
              <MobileInformationModalInformationFigure>
                <MobileIngormationFigureGrayPipeline />
              </MobileInformationModalInformationFigure>
              <div>
                <MobileInformationModalInformationLabel>
                  Gray: <strong>Default</strong>
                </MobileInformationModalInformationLabel>
                <MobileInformationModalInformationDescription>
                  {`Pipeline's standard route across the map.`}
                </MobileInformationModalInformationDescription>
              </div>
            </MobileInformationModalInformation>
            <MobileInformationModalInformation>
              <MobileInformationModalInformationFigure>
                <MobileIngormationFigureGreenPipeline />
              </MobileInformationModalInformationFigure>
              <div>
                <MobileInformationModalInformationLabel>
                  Green: <strong>Successfully tracked</strong>
                </MobileInformationModalInformationLabel>
                <MobileInformationModalInformationDescription>
                  Pipeline behind the tool that has been successfully tracked.
                </MobileInformationModalInformationDescription>
              </div>
            </MobileInformationModalInformation>
            <MobileInformationModalInformation>
              <MobileInformationModalInformationFigure>
                <MobileIngormationFigureRedHighlight />
              </MobileInformationModalInformationFigure>
              <div>
                <MobileInformationModalInformationLabel>
                  Red highlight: <strong>{`Tool's positional margin of error`}</strong>
                </MobileInformationModalInformationLabel>
                <MobileInformationModalInformationDescription>
                  {`A region that represents the earliest likely arrival of the tool and the latest likely arrival of the tool (i.e. the tool's positional standard deviation).`}
                </MobileInformationModalInformationDescription>
              </div>
            </MobileInformationModalInformation>
            <MobileInformationModalInformation>
              <MobileInformationModalInformationFigure>
                <MobileIngormationFigureDriveline />
              </MobileInformationModalInformationFigure>
              <div>
                <MobileInformationModalInformationLabel>
                  Blue line: <strong>Vehicle driveline</strong>
                </MobileInformationModalInformationLabel>
                <MobileInformationModalInformationDescription>
                  Vehicle route taken to move between tracking points
                </MobileInformationModalInformationDescription>
              </div>
            </MobileInformationModalInformation>
          </MobileInformationModalPipelinePointsInformations>
        </MobileInformationModalInformations>
      </MobileInformationModalContent>
    </MobileInformationModalWrapper>
  );
};

export default MobileInformationModal;
