import { abortRun as abortRunService, checkIsAllowedMakeEditionsOnProject, finishRun as finishRunService, launch } from "app/services/run.service";
import { NotificationProviderValue } from "../notification/notification.context.d";

/**
 * check on api if logged user is allowed to make editions or not
 * @param projectId project id to check
 * @param token logged auth token
 * @param setPermissionType it is set on run context the user permission type, editor or observer
 */
export const checkIsAllowedMakeEditions = async (
  projectId: number,
  token: string,
  setPermissionType: (permissionType: string) => void,
) => {
  const response = await checkIsAllowedMakeEditionsOnProject(projectId.toString(), token);
  setPermissionType(response.data ? 'editor' : 'observer');
};

/**
 * @param id
 * @param run
 * @param token
 * @param dispatch
 * @param setConfirmation
 * @param tstamp
 * @param launchSpeed
 * @param isTest
 * @returns
 */
export const launchRun = async (
  id: number | undefined,
  token: string,
  tstamp: Date,
  launchSpeed: number,
  isTest: boolean,
  notificationDispatch: NotificationProviderValue['dispatch'],
) => {
  try {
    if (!id) return;
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });

    const launchData = {
      id,
      tstamp: tstamp.toISOString(),
      speed: launchSpeed,
      as_test: isTest,
    };

    await launch(id, launchData, token);
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'success',
        title: 'Success',
        text: 'Run was launched successfully',
      },
    });
  } catch (err: any) {
    if (err.response.data.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response.data.title,
          text: err.response.data.message,
        },
      });
    }

    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};

/**
 * @param id
 * @param auth
 * @param setConfirmation
 * @param dispatch
 * @returns
 */
export const abortRun = async (
  id: number,
  token: string,
  notificationDispatch: NotificationProviderValue['dispatch'],
) => {
  if (!token) return;
  try {
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });

    await abortRunService(id, {}, token);
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'success',
        title: 'Success',
        text: 'Run was abort successfully',
      },
    });
  } catch (err: any) {
    if (err.response.data.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response.data.title,
          text: err.response.data.message || 'Error to abort run',
        },
      });
    }
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};

/**
 *
 * @param id
 * @param token
 * @param setConfirmation
 * @param dispatch
 */
export const finishRun = async (
  id: number,
  token: string,
  notificationDispatch: NotificationProviderValue['dispatch'],
  ) => {
  try {
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });
    await finishRunService(id, {}, token);
  } catch (err) {
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Error to finish run',
      },
    });
  }
};

