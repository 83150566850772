import { useContext, useEffect, useState } from 'react';
import {
  centerMap,
  getLogoOrientation,
  getLogoImage,
  initQubeClusters,
} from './run.controller';
import { AuthType } from 'app/modules/account/account.context.d';
import AccountStore from 'app/modules/account/account.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import UpdateEventOverlay from 'app/components/update-event-overlay/update-event-modal';
import CreateEventOverlay from 'app/components/create-event-overlay/create-event-modal';
import EventListOverlay from 'app/modules/inspection/run/devices/desktop/components/event-list-overlay/event-list-overlay';
import Widgets from './components/widgets/widgets';
import { GtLogo } from './run.style';
import TPMarker from './components/tp-marker/tp-marker';
import PigMarker from './components/pig-marker/pig-marker';
import SegmentPolyline from './components/segment-polyline/segment-polyline';
import QubeMarker from './components/qube-marker/qube-marker';
import Pipeline from './components/pipeline/pipeline';
import ChartModal from 'app/modules/inspection/run/components/chart-modal/chart-modal';
import TimePinMarker from './components/time-pin-marker/time-pin-marker';
import LoggedUserMarker from './components/logged-user-marker/logged-user-marker';
import MobileDetect from 'mobile-detect';
import Driveline from './components/driveline/driveline';
import MeasureTool from 'lib/measuretool-googlemaps-v3/src';
import { MarkerClusterer } from './utils/marker-clusterer/markerclusterer';
import { GetBoundsBoxResponse } from 'app/utils/map.utils';
import SettingsStore from 'app/modules/settings/settings.context';
import { changeMapOverlay, checkOverlayUnit, fitBounds } from '../../map.controller';

/**
 * 
 * @param props
 * @returns 
 */
const Run = ({
  map,
  zoom,
  measureTool,
  noWidgets,
  noQubes,
}: {
  map: google.maps.Map;
  zoom: number | undefined;
  measureTool: MeasureTool | undefined;
  noWidgets?: boolean;
  noQubes?: boolean;
}) => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [hideWidgets, setHideWidgets] = useState<boolean>(true);
  const [qubeClusters, setQubeClusters] = useState<MarkerClusterer>();
  const [mapCenter, setMapCenter] = useState<GetBoundsBoxResponse>();

  // map controls states
  const [weatherOverlay, setWeatherOverlay] = useState<string>();

  // change map tile overlay
  useEffect(() => {
    changeMapOverlay({ map, weatherOverlay });
  }, [weatherOverlay]);

  // check if has overlay setted and change the overlay option to match the weather unit
  useEffect(() => {
    checkOverlayUnit({
      weatherOverlay,
      weatherUnit: settingsContext.state.weatherUnit,
      setWeatherOverlay,
    });
  }, [settingsContext.state.weatherUnit]);

  // follow logged user on map
  useEffect(() => {
    const isFollowingPig = inspectionContext.state.following_logged_user;
    if (isFollowingPig && inspectionContext.state.user_position && map) {
      const position = new google.maps.LatLng(inspectionContext.state.user_position);
      fitBounds({ bounds: [position], map });
    }
  }, [inspectionContext.state.following_logged_user, inspectionContext.state.user_position]);

  // follow pig on map
  useEffect(() => {
    const isFollowingPig = inspectionContext.state.following_pig;
    const estimation = inspectionContext.run?.estimation;
    if (isFollowingPig && estimation?.location && map) {
      fitBounds({ bounds: [new google.maps.LatLng({
        lat: estimation?.location?.coordinates[1],
        lng: estimation?.location?.coordinates[0],
      })], map});
    }
  }, [inspectionContext.state.following_pig, inspectionContext.run?.estimation?.location?.coordinates]);

  // changing weather overlay by parent
  useEffect(() => {
    setWeatherOverlay(inspectionContext.state.weather_tile);
  }, [inspectionContext.state.weather_tile, settingsContext.state.weatherUnit]);

  useEffect(() => {    
    const md = new MobileDetect(window.navigator.userAgent);
    if (!md.mobile()) {
      setHideWidgets(false);
    }

    initQubeClusters(
      map,
      setQubeClusters,
      (markers) => inspectionContext.dispatch({ data: markers, type: 'SET_SELECTED_MOBILE_CLUSTER_MARKERS'}), 
      () => inspectionContext.dispatch({ type: 'SET_SELECTED_QUBE_MARKER', data: undefined }),
      inspectionContext.state.permission_type === 'observer',
      auth.token,
    );

    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('cluster');
      sessionStorage.removeItem('cluster-qube');
    });

    return () => {
      window.removeEventListener('beforeunload', () => {});
      sessionStorage.removeItem('cluster');
      sessionStorage.removeItem('cluster-qube');
    };
  }, []);

  // After laod pipeline, center map
  useEffect(() => {
    centerMap({
      pipeline: inspectionContext.run?.pipeline,
      map,
      setMapCenter,
      mapCenter,
    });
  }, [inspectionContext.run?.pipeline?.geometry?.coordinates]);
  
  // After laod pipeline, center map
  useEffect(() => {
    if (mapCenter) {
      map.fitBounds(mapCenter, 70);
    }
  }, [mapCenter]);

  if (!map) return null;

  return (
    <div>
      {
        noWidgets ? null : (
          <Widgets
            hide={hideWidgets}
            displayNone={inspectionContext.state.view_mode === 'observer'}
            setHide={setHideWidgets}
            openChartModal={() =>
              inspectionContext.dispatch({
                type: 'TOGGLE_MODAL',
                data: 'chart'
              })
            }
          />
        )
      }

      <LoggedUserMarker
        map={map} 
      />
      {
        inspectionContext.run?.pipeline ?  (
          <Pipeline
            pipeline={inspectionContext.run.pipeline}
            map={map}
          />
        ) : null
      }
      {
        inspectionContext.run?.pipeline?.driveline ?  (
          <Driveline
            driveline={inspectionContext.run.pipeline.driveline}
            map={map}
          />
        ) : null
      }
      {
        inspectionContext.run?.trackingpoint_set?.map((point, index) => {
          return point ? (
            <TPMarker
              index={index}
              hasLabels={inspectionContext.state.has_labels}
              hasRuler={inspectionContext.state.has_ruler}
              measureTool={measureTool}
              zoom={zoom}
              key={`${point.id}-${index}`}
              point={point}
              map={map}
              handleSelect={(point) => {
                inspectionContext.dispatch({
                  type: 'SET_SELECTED_POINT',
                  data: point,
                });
              }}
            />
          ) : null;
        })
      }
      {
        inspectionContext.run?.timepin_set?.map((timePin, index) => {
          return timePin ? (
            <TimePinMarker
              key={`${timePin.id}-${index}`}
              hasLabels={inspectionContext.state.has_labels}
              timePin={timePin}
              map={map}
            />
          ) : null;
        })
      }
      {
        !noQubes &&
        map &&
        inspectionContext.run?.project?.using_qube &&
          inspectionContext.run.project.device_set.map((device, index) => {
            return device?.qubedevice?._loaded && device?.qubedevice?.position ? (
              <QubeMarker
                key={`${device.serial}-${index}`}
                qube={device.qubedevice}
                cluster={qubeClusters}
                map={map}
                hasLabels={inspectionContext.state.has_labels}
                isObserver={inspectionContext.state.permission_type === 'observer'}
                opened={inspectionContext.state.selected_qube_marker === device.serial}
                next={inspectionContext.run?.project?.device_set[index + 1]?.serial || inspectionContext.run?.project?.device_set[0]?.serial}
                prev={inspectionContext.run?.project?.device_set[index - 1]?.serial || inspectionContext.run?.project?.device_set[inspectionContext.run?.project?.device_set.length - 1]?.serial}
              />
            ) : null;
        })
      }
      {
        inspectionContext.run?.estimation ? (
          <PigMarker
            estimation={inspectionContext.run?.estimation}
            map={map}
          />
        ) : null
      }
      {
        inspectionContext.run?.estimation ? (
          <SegmentPolyline
            estimation={inspectionContext.run.estimation}
            map={map}
          />
        ) : null
      }
      

      <GtLogo
        $observerView={inspectionContext.state.permission_type === 'observer'}
        orientation={getLogoOrientation(inspectionContext.run?.project?.customer)}
        src={getLogoImage(inspectionContext.run?.project?.customer)}
      />
      
      <UpdateEventOverlay />
      <CreateEventOverlay />
      <EventListOverlay />
      <ChartModal />
    </div>
  );
};

export default Run;
