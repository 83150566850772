import styled from 'styled-components';

export const ModalWrapper = styled.div<{ hide?: boolean }>`
  display: grid;
  grid-template-rows: 47px 50px 1fr;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  overflow-y: auto;
  z-index: 21;
`;

export const NameLabel = styled.label`
  margin-left: 16px;
`;

export const Modal = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  border-radius: 6px;
  background-color: #ffffff;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 18px;
  justify-content: space-between;
  border-bottom: 1px solid #e4e6eb;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
`;

export const Container = styled.main`
  height: 100%;
`;
