import styled from 'styled-components';

/**
 * @param light
 * @returns
 */
export const getCardBackground = (light = false, theme) => {
  if (light) return theme.colors.surface_white;
  return theme.colors.surface_frame;
};

export const DeviceCard = styled.div<{ $light?: boolean; $opaque?: boolean, $isClickable?: boolean }>`
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: ${({ $light, theme }) => getCardBackground($light, theme)};
  box-shadow: 0px 4px 10px rgba(37, 43, 64, 0.05);
  border-radius: 6px;
  
  opacity: ${({ $opaque }) => $opaque ? '0.5' : '1'};
  position: relative;
  ${({ $isClickable }) => $isClickable ? 'cursor: pointer;' : ''}

  span.highlight {
    background-color: ${({ theme }) => theme.colors.primary_light_blue};
  }
`;

export const DeviceCardHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;

  padding: 16px;
  border-bottom: 1px solid #dbe1e3;
`;

export const DeviceProjectName = styled.p`
  ${({ theme }) => theme.fonts.typograph.caption};
  font-size: 10px;
  text-transform: uppercase;
`;

export const DeviceCardContent = styled.div`
  padding: 16px;
`;

export const DeviceCheckWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const DeviceInfoList = styled.dl`
  display: grid;
  grid-template-columns: 67px 86px auto;
  justify-content: start;

  gap: 12px;
  margin: 0;
`;

export const DeviceInfo = styled.div``;

export const DeviceInfoLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(105, 115, 134, 0.75);

  margin-bottom: 2px;
`;

export const DeviceInfoValue = styled.dd`
  display: flex;
  align-items: center;
  gap: 4px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  color: #1a1f36;

  margin: 0;
`;

export const DevicePropertiesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 4px;
`;

export const DeviceProperty = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DevicePropertyStatus = styled.span<{ checked: boolean }>`
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: ${({ checked }) => (!checked ? '#d4d4d4' : '#e40f0f')};
`;

export const DevicePropertyLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;
`;

export const DeviceTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DeviceInfoValueBall = styled.span<{ $actived: boolean }>`
  display: block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ $actived }) => $actived ? '#e40f0f' : '#767986'};
`;

export const DeviceTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: uppercase;
  cursor: pointer;
  color: #1a1f36;
`;

export const DeviceStatus = styled.span<{ color: string }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: ${({ color }) => color};
`;

export const DeviceStatusFigure = styled.figure`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: #d4d4d4;

  > img {
    width: 100%;
  }
`;
