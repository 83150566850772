import { setArmed } from "app/components/devices/device-common.controller";
import { InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import MobileDetect from "mobile-detect";

/**
 * format qube uptime
 * @returns
 */
const getUptime = (secs: number): string => {
  if (!secs) return '-';

  const fixZero = (n: number) => (n < 10 ? `0${n.toFixed(0)}` : n.toFixed(0));

  const minutes = Math.floor(secs / 60);
  const secondsUpTo = secs % 60;
  const hours = Math.floor(minutes / 60);
  const minutesUpTo = minutes % 60;

  return `${fixZero(hours)}:${fixZero(minutesUpTo)}:${fixZero(secondsUpTo)}`;
};

/**
 * 
 * @param opened 
 * @param markerEl 
 * @param markerInfoEl 
 * @returns 
 */
export const createContentElement = (
  opened: boolean,
  markerEl: HTMLSpanElement,
  markerInfoEl: HTMLDivElement,
  exitButtonEl: HTMLButtonElement,
): HTMLDivElement => {
  const contentEl = document.createElement('div');
  contentEl.className = `GT-QUBE-MARKER ${opened ? 'active' : ''}`;  
  contentEl.appendChild(markerEl);
  contentEl.appendChild(markerInfoEl);
  contentEl.appendChild(exitButtonEl);

  if (opened && contentEl.parentElement) {
    contentEl.parentElement.style.zIndex = '60';
  }

  return contentEl;
}

/**
 * 
 * @param device 
 * @returns 
 */
export const createMarkerElement = (
  device: QubeDeviceType,
): HTMLSpanElement => {
  const markerEl = document.createElement('div');
  const markerContent = document.createElement('span');
  const markerQubeStatus = document.createElement('span');

  markerEl.className = 'marker';
  markerQubeStatus.className = 'status';

  const serial = device.serial.slice(-4);
  markerContent.innerHTML = `
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7001 12.6C16.7001 12.904 16.5321 13.168 16.2761 13.304L9.95605 16.856C9.82805 16.952 9.66805 17 9.50005 17C9.33205 17 9.17205 16.952 9.04405 16.856L2.72405 13.304C2.5958 13.2366 2.48846 13.1353 2.41371 13.0112C2.33896 12.8871 2.29965 12.7449 2.30005 12.6V5.4C2.30005 5.096 2.46805 4.832 2.72405 4.696L9.04405 1.144C9.17205 1.048 9.33205 1 9.50005 1C9.66805 1 9.82805 1.048 9.95605 1.144L16.2761 4.696C16.5321 4.832 16.7001 5.096 16.7001 5.4V12.6ZM9.50005 2.72L4.73205 5.4L9.50005 8.08L14.2681 5.4L9.50005 2.72ZM3.90005 12.128L8.70005 14.832V9.464L3.90005 6.768V12.128ZM15.1001 12.128V6.768L10.3001 9.464V14.832L15.1001 12.128Z" fill="#D30000"/>
    </svg>
    <h4>${serial}</h4>
  `;


  if (device.online) {
    markerEl.classList.add('online');
  }

  if (device.armed) {
    markerEl.classList.add('armed');
  }

  markerEl.appendChild(markerContent);
  markerEl.appendChild(markerQubeStatus);
  return markerEl;
}

/**
 * 
 * @param isObserver 
 * @param device 
 * @returns 
 */
export const createArmButtonElement = (
  isObserver: boolean,
  device: QubeDeviceType,
  token: string,
): HTMLButtonElement => {
  const armButton = document.createElement('button');
  armButton.className = `button arm ${device.armed ? 'armed' : ''}`;
  armButton.disabled = !isObserver && !device.online;
  armButton.title = device.online ? '' : 'Qube can\'t be armed/disarmed while offline';

  if (device.armed) {
    armButton.classList.add('armed');
  }
  
  if (!isObserver) {
    armButton.addEventListener('click', () => {
      armButton.disabled = true;
      armButton.classList.add('waiting');
      armButton.innerHTML = `
        <span class="icon material-symbols-outlined">progress_activity</span>
      `;
  
      setArmed(device.serial, !device.armed, token);
    });
  }

  armButton.innerHTML = `
    <span class="switch"></span>
    <span class="text">
      ${device.armed ? 'Armed' : 'Disarmed'}
    </span>
  `;
  
  return armButton;
}

const copy = (target: Element) => {
  const range = document.createRange();
  const selection = window.getSelection();

  // Clear any existing selections
  selection?.removeAllRanges();

  // Define the range you want to select
  range.setStart(target, 0);
  range.setEnd(target, target.childNodes.length);

  // Add the range to the selection
  selection?.addRange(range);

  navigator.clipboard.writeText(target.textContent || '');
};

type ClusteredOptions = {
  clustered: boolean;
  index: number;
  length: number;
}

/**
 * 
 * @param device 
 * @param footerEl 
 * @returns 
 */
export const createMarkerInfoElement = (device: QubeDeviceType, isObserver: boolean, token: string, clusteredOptions: ClusteredOptions | undefined = undefined): HTMLDivElement => {
  const headerEl = createHeaderElement(device);
  const markerInfoEl = document.createElement('div'); 
  const contentEl = document.createElement('div');
  const armButton = createArmButtonElement(isObserver, device, token);
  const copyIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 10.667C5 9.95967 5.28099 9.28131 5.78115 8.78115C6.28131 8.28099 6.95967 8 7.667 8H16.333C16.6832 8 17.03 8.06898 17.3536 8.20301C17.6772 8.33704 17.9712 8.53349 18.2189 8.78115C18.4665 9.0288 18.663 9.32281 18.797 9.64638C18.931 9.96996 19 10.3168 19 10.667V19.333C19 19.6832 18.931 20.03 18.797 20.3536C18.663 20.6772 18.4665 20.9712 18.2189 21.2189C17.9712 21.4665 17.6772 21.663 17.3536 21.797C17.03 21.931 16.6832 22 16.333 22H7.667C7.31676 22 6.96996 21.931 6.64638 21.797C6.32281 21.663 6.0288 21.4665 5.78115 21.2189C5.53349 20.9712 5.33704 20.6772 5.20301 20.3536C5.06898 20.03 5 19.6832 5 19.333V10.667Z" stroke="#595959" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.012 17.737C1.70534 17.5622 1.45027 17.3095 1.27258 17.0045C1.09488 16.6995 1.00085 16.353 1 16V6C1 4.9 1.9 4 3 4H13C13.75 4 14.158 4.385 14.5 5" stroke="#595959" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
  
  const copySerialButton = document.createElement('button');
  const copyCoordsButton = document.createElement('button');

  copySerialButton.addEventListener('click', () => {
    const target = contentEl.querySelector('.serial p') as Element;
    copySerialButton.innerHTML = '<span class="copied">Copied!</span>';
    copy(target);
  });

   copyCoordsButton.addEventListener('click', () => {
    const target = contentEl.querySelector('.coords p') as Element;
    copyCoordsButton.innerHTML = '<span class="copied">Copied!</span>';
    copy(target);
  });

  copySerialButton.innerHTML = copyIcon;
  copyCoordsButton.innerHTML = copyIcon;
  copySerialButton.classList.add('copy');
  copyCoordsButton.classList.add('copy');

  markerInfoEl.className = 'content';
  markerInfoEl.appendChild(headerEl);
  markerInfoEl.appendChild(contentEl);

  markerInfoEl.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  if (clusteredOptions?.clustered) {
    const footerEl = createFooterElement(clusteredOptions.index, clusteredOptions.length);
    markerInfoEl.appendChild(footerEl);
  } 

  if (device.online) {
    markerInfoEl.classList.add('online');
  }

  contentEl.innerHTML = `
    <div class="connection">
      <span class="icon"></span>
      <p>${device.online ? 'Online' : 'Offline'}</p>
    </div>

    <div class="item">
      <label>Uptime</label>
      <p>${getUptime(device.uptime_secs)}</p>
    </div>

    <div class="item serial with-copy">
      <div>
        <label>Serial number</label>
        <p>${device.serial}</p>
      </div>
    </div>

    <div class="item coords with-copy">
      <div>
        <label>Location</label>
        <p>${device.position.coordinates[1].toFixed(5)}, ${device.position.coordinates[0].toFixed(5)}</p>
      </div>
    </div>

    <div class="item arm">
      <label>Status</label>
    </div>
  `;
  
  contentEl.querySelector('.serial')?.append(copySerialButton);
  contentEl.querySelector('.coords')?.append(copyCoordsButton);
  contentEl.querySelector('.arm')?.append(armButton);
  return markerInfoEl;
}

export const createHeaderElement = (device: QubeDeviceType): HTMLHeadElement => {
  const serial = device.serial.slice(-4);
  const battery100 = `
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M8.2 3.4V6.6M10.6 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#56F000" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
  const battery80 = `
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M8.2 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#56F000" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
  const battery40 = `
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#FFF500" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
  const battery20 = `
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#FF2222" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
  
  const batteries = { 20: battery20, 40: battery40, 80: battery80, 100: battery100, };
  const headerEl = document.createElement('header');
  const batteryKeyList = Object.keys(batteries).filter((key) => parseInt(key) > device.battery_level); 
  const batteryKey = batteryKeyList.length ? batteryKeyList[0] : 20;
  headerEl.innerHTML = `
    <div>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.2 13.1C16.2 13.404 16.032 13.668 15.776 13.804L9.45599 17.356C9.32799 17.452 9.16799 17.5 8.99999 17.5C8.83199 17.5 8.67199 17.452 8.54399 17.356L2.22399 13.804C2.09573 13.7366 1.9884 13.6353 1.91365 13.5112C1.8389 13.3871 1.79959 13.2449 1.79999 13.1V5.9C1.79999 5.596 1.96799 5.332 2.22399 5.196L8.54399 1.644C8.67199 1.548 8.83199 1.5 8.99999 1.5C9.16799 1.5 9.32799 1.548 9.45599 1.644L15.776 5.196C16.032 5.332 16.2 5.596 16.2 5.9V13.1ZM8.99999 3.22L4.23199 5.9L8.99999 8.58L13.768 5.9L8.99999 3.22ZM3.39999 12.628L8.19999 15.332V9.964L3.39999 7.268V12.628ZM14.6 12.628V7.268L9.79999 9.964V15.332L14.6 12.628Z" fill="#D30000"/>
      </svg>  
      <h4>${serial}</h4>
    </div>
    <div>
      <div class="battery">
        ${batteries[batteryKey]}
        <span>${device.battery_level}%</span>
      </div>
    </div>
  `;
  
  return headerEl;
};

/**
 * 
 * @returns 
 */
export const createExitButtonElement = () => {
  const exitButton = document.createElement('button');
  exitButton.className = 'exit';
  exitButton.innerHTML = `
    <span class="material-icons-outlined">close</span>
  `;
  return exitButton;
};



/**
 * 
 * @param device 
 * @param dispatch 
 * @param next 
 * @param prev 
 * @returns 
 */
export const createFooterElement = (index: number, length: number): HTMLHeadElement => {
  const footerEl = document.createElement('footer');
  const arrowsWrapper = document.createElement('div');
  const prevButton = document.createElement('button');
  const nextButton = document.createElement('button');

  nextButton.innerHTML = '<span class="material-icons-outlined">chevron_right</span>';
  prevButton.innerHTML = '<span class="material-icons-outlined">chevron_left</span>';

  arrowsWrapper.classList.add('arrows');
  arrowsWrapper.append(prevButton);
  arrowsWrapper.append(nextButton);
  
  footerEl.innerHTML = `
    <div class="counter">${index + 1} of ${length}</div>
  `;
  
  footerEl.append(arrowsWrapper);

  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile();
  prevButton.addEventListener(isMobile ? 'touchend' : 'click', (e) => {
    const target = e.currentTarget as HTMLElement;
    const current = target.parentElement?.parentElement?.parentElement as HTMLElement;
    const prev = current.previousElementSibling;

    if (prev?.className.includes('content')) {
      sessionStorage.setItem('cluster-qube', prev.id?.replace('qube-', ''));
      prev.classList.add('active');
      current.classList.remove('active');
    }
  });

  nextButton.addEventListener(isMobile ? 'touchend' : 'click', (e) => {
    const target = e.currentTarget as HTMLElement;
    const current = target.parentElement?.parentElement?.parentElement as HTMLElement;
    const next = current.nextElementSibling;

    if (next?.className.includes('content')) {
      sessionStorage.setItem('cluster-qube', next.id?.replace('qube-', ''));
      next.classList.add('active');
      current.classList.remove('active');
    }
  });

  return footerEl;
}

/**
 * 
 * @param device 
 * @param token 
 * @param isObserver 
 * @param opened 
 * @param dispatch 
 * @param next 
 * @param prev 
 * @param modalOpened 
 * @returns 
 */
export const createQubeMarkerOverlay = (
  device: QubeDeviceType,
  token: string,
  isObserver: boolean,
  opened: boolean,
  dispatch: InspectionProviderValue['dispatch'],
  next: string | undefined,
  prev: string | undefined,
  modalOpened: boolean,
): {
  contentEl: HTMLDivElement,
  markerEl: HTMLSpanElement,
} => {
  const markerEl = createMarkerElement(device);
  const markerInfoEl = createMarkerInfoElement(device, isObserver, token);
  const exitButtonEl = createExitButtonElement();

  const md = new MobileDetect(window.navigator.userAgent);
  const contentEl = createContentElement(!md.mobile() && opened, markerEl, markerInfoEl, exitButtonEl);

  if (md.mobile()) {
    markerEl.addEventListener('touchend', (e) => {
      e.preventDefault();
      dispatch({
        type: 'SET_SELECTED_QUBE_MARKER',
        data: opened ? null : device.serial,
      });

      dispatch({
        type: 'SET_MOBILE_SELECTED_QUBE',
        data: opened ? null : device,
      });
    });
  } else {
    markerEl.addEventListener('click', () => {
      dispatch({
        type: 'SET_SELECTED_QUBE_MARKER',
        data: opened ? null : device.serial,
      });
    });

    exitButtonEl.addEventListener('click', (e) => {
      e.preventDefault();
      dispatch({
        type: 'SET_SELECTED_QUBE_MARKER',
        data: null,
      });
    });

    contentEl.addEventListener('mouseover', () => {
      const parent = contentEl.parentElement as HTMLElement;
      parent.style.zIndex = `${parseInt(parent.style.zIndex) + 10}`;
    });

    contentEl.addEventListener('mouseout', () => {
      const parent = contentEl.parentElement as HTMLElement;
      parent.style.zIndex = `${parseInt(parent.style.zIndex) - 10}`;
    });
  }

  if (modalOpened) {
    contentEl.querySelector('span')?.classList.add('fixed');
  }

  return {
    contentEl,
    markerEl,
  };
}
