import styled from 'styled-components';

export const Card = styled.li`
  background: #ffffff;
  border: 1px solid #e7ebee;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(37, 43, 64, 0.05);
  border-radius: 6px;
  overflow: hidden;

  cursor: pointer;
  max-width: 900px;
  width: 100%;
`;

export const CollapseButton = styled.button<{
  showList: boolean;
  hideButton: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: transparent;
  padding: 6px 8px;

  transition-duration: 30ms;
  cursor: pointer;

  svg {
    transform: rotate(${({ showList }) => (showList ? '0' : '-90deg')});
  }
  ${({ hideButton }) => (hideButton ? 'display: none;' : '')}
`;

export const RunsAmount = styled.span`
  display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #686c7b;
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 80px 1fr;
  justify-content: start;
  align-items: center;
`;

export const RunsWrapper = styled.div<{ show: boolean }>`
  border-radius: 0 0 6px 6px;
  overflow: hidden;

  ${({ show }) => (show ? '' : 'display: none;')}
`;

export const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const ProjectInfoWrapper = styled.div`
  padding: 16px 20px;
`;

export const ParticipantsWrapper = styled.div`
  display: none;
  justify-content: flex-end;

  padding: 16px 20px;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const NextRunLabel = styled.label`
  display: block;
  margin-top: 8px;

  display: block;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2em;

  color: #686c7b;

  > strong {
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    font-size: 11px;
  }
`;

export const ScheduledWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
