import { useContext, useMemo, useState } from "react";
import InspectionStore from "app/modules/inspection/inspection.context";
import { ScheduledActionWrapper, Dropdown } from "./finished-state.style";

/**
 * 
 * @param props 
 * @returns 
 */
const FinishedState = () => {
  const inspectionContext = useContext(InspectionStore);
  const [hide, setHide] = useState<boolean>(true);
  const list = useMemo(() => {
    const reset = { id: 'abort', label: 'Reset Run', onClick: () => {} };

    const list = [] as typeof reset[];
    if (inspectionContext.run?.estimation?.can_be_aborted) list.push(reset);
    return list;
  }, []);

  /**
   * 
   */
  const handleClick = () => {};
  
  return (
    <ScheduledActionWrapper>
      <button
        data-testid="scheduled-action-button"
        disabled={inspectionContext.state.permission_type !== 'editor'}
        onClick={handleClick}
      >
        {inspectionContext.run?.status === 'EARLY_FINISHED' ? 'Finished Early' : 'Finished'}
      </button>
      <Dropdown $hide={hide} data-testid="scheduled-action-list">
        {
          list.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                setHide(true);
                item.onClick()
              }}
            >
              {item.label}
            </li>
          ))
        }
      </Dropdown>
    </ScheduledActionWrapper>
  );
};

export default FinishedState;
