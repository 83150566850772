import { BatteryWrapper } from './battery.style';

interface Props {
  level: number;
  disableLevelText?: boolean;
  width?: number;
  id: string;
}

/**
 *
 * @param props
 * @returns
 */
const Battery = (props: Props) => {
  const batteryId = `GT-BATTERY-${props.id}`;

  /**
   *
   */
  const getBatteryColor = () => {
    if (props.level >= 50) return '#3fc182';
    if (props.level >= 20) return '#e9d02d';
    return '#e92d2d';
  };

  /**
   *
   */
  const getBatteryValue = () => {
    if (!props.level) return 0;
    return props.level * 0.01;
  };

  return (
    <BatteryWrapper width={props.width || 40}>
      <svg viewBox="0 0 50 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.07805 20C3.51433 20 3.04817 19.8583 2.67958 19.575C2.31099 19.2917 2.1267 18.9333 2.1267 18.5V14H1C0.447715 14 0 13.5523 0 13V7C0 6.44772 0.447715 6 1 6H2.1267V1.5C2.1267 1.06667 2.31099 0.708334 2.67958 0.425C3.04817 0.141667 3.51433 0 4.07805 0H48.0486C48.6124 0 49.0785 0.141667 49.4471 0.425C49.8157 0.708334 50 1.06667 50 1.5V18.5C50 18.9333 49.8157 19.2917 49.4471 19.575C49.0785 19.8583 48.6124 20 48.0486 20H4.07805Z"
          fill={`url(#${batteryId}`}
        />

        <defs>
          <linearGradient
            id={batteryId}
            x1="50"
            y1="10"
            x2="-2.0362"
            y2="10"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={getBatteryValue()}
              stopColor={getBatteryColor()}
              data-testid="battery-background"
            />
            <stop offset={getBatteryValue()} stopColor="#D4D4D4" />
          </linearGradient>
        </defs>
      </svg>

      {!props.disableLevelText ? <span>{props.level}%</span> : null}
    </BatteryWrapper>
  );
};

Battery.defaultProps = {
  width: 40,
};

export default Battery;
