import styled from 'styled-components';

export const TrackingSheetContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const TrackingSheetSkeletonWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 70px 200px 70px 120px 90px 100px 130px 100px 120px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 130px 100px 100px 120px;
  grid-template-rows: repeat(10, 20px);
  align-items: start;

  gap: 8px;
  padding-left: 8px;
  padding-right: 8px;

  > div:first-child {
  position: relative;
  top: 8px;
  }
`;
