import styled from 'styled-components';

export const ScheduledOnButtonWrapper = styled.div`
  position: relative;
  
  display: grid;
  grid-template-columns: auto auto;

  border-radius: 6px;

  > div:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  > button:last-child,
  > div:last-child > button {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    
    height: 30px;

    padding: 8px 16px;
    color: ${({ theme }) => theme.colors.surface_white};
    background-color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-weight: 600;

    cursor: pointer;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.neutra_300};
      cursor: not-allowed;
    }
  }
`;

export const ScheduleoDateTimeButton = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  padding: 8px;

  ${({ theme }) => theme.fonts.typograph.overline};
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};


  background-color: ${({ theme }) => theme.colors.surface_white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
