import styled from 'styled-components';

export const EmptyContent = styled.div`
  text-align: center;
  padding: 180px 60px;
`;

export const EmptyContentTitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;

  color: #161e2e;

  margin-bottom: 12px;
`;

export const RunList = styled.ul`
  display: grid;
  gap: 8px;

  padding-bottom: 32px;
`;

export const RunsSkeletonWrapper = styled.div`
  display: grid;
  gap: 8px;
`;
