import { useState, useMemo, useContext } from 'react';
import * as unorm from 'unorm';
import NotificationStore from 'app/modules/notification/notification.context';
import Card from 'app/components/devices/device-card/device-card';
import { QubeDict } from './devices.d';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import Skeleton from 'app/components/skeleton/skeleton';
import SearchInput from 'app/components/search-input/search-input';
import Checkbox from 'app/components/checkbox/checkbox';
import DeviceFloatBar from './components/device-float-bar/device-float-bar';
import InputGroup from 'app/components/input-group/inputGroup';
import DefaultButton from 'app/components/default-button/default-button';
import DeviceAddIcon from './icons/add.icon';
import DeviceScanIcon from './icons/scan.icon';
import DeviceCustomerDevicesModal from './components/device-customer-devices-modal/device-customer-devices-modal';
import DeviceScanConfirmation from './components/device-scan-confirmation/device-scan-confirmation';
import DeviceScan from './components/device-scan/device-scan';
import { handleConfirmDevices, handleRemoveDevices } from './devices.controller';
import DeviceCarousel from 'app/components/devices/device-carrousel/device-carousel';
import CustomerDevicesTable from './components/customer-devices-table/customer-devices-table';
import CustomerDevicesFloatBar from './components/customer-devices-float-bar/customer-devices-float-bar';
import {
  Container,
  DeviceCheckAll,
  DeviceHeader,
  DeviceHeaderActions,
  DeviceContent,
  DeviceContentSearch,
  DeviceSidebar,
  DeviceSidebarSearch,
  DeviceSidebarTableWrapper,
  DeviceList,
  deviceSearchCustomStyle,
} from './devices.style';
import DeviceTypeScan from './components/device-type-scan/device-type-scan';

interface Props {
  authToken: string;
  projectId: number;
  isAdministrator: boolean;
  projectName: string;
  devices: QubeDeviceType[];
  customerDevices: QubeDeviceType[];
}

/**
 * 
 * @param amount 
 */
const getDragImage = (amount: number) => {
  // Create a canvas and draw a visual representation as a drag image
  const canvas = document.createElement('canvas');
  const squareSize = 50;
  const amountCircleSize = 10;

  canvas.width = squareSize + amountCircleSize / 2; // canvas width
  canvas.height = squareSize + amountCircleSize / 2; // canvas height
  const ctx = canvas.getContext('2d');
  
  if (!ctx) return;
  const borderRadius = 6;
  ctx.fillStyle = '#ffffff';
  ctx.strokeStyle = '#ffffff';
  
  // Create a rounded rectangle
  ctx.beginPath();
  ctx.moveTo(borderRadius, canvas.height - squareSize + amountCircleSize / 2);
  ctx.lineTo(squareSize - borderRadius, canvas.height - squareSize + amountCircleSize / 2);
  ctx.lineTo(squareSize - borderRadius, canvas.height - borderRadius);
  ctx.lineTo(borderRadius, canvas.height - borderRadius);

  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  
  // Load the device icon
  const deviceImage = new Image();
  deviceImage.src = '/assets/images/qube-icon.png';
  
  // Draw the device icon on the canvas
  const deviceImageSize = 30;
  ctx.drawImage(deviceImage, (squareSize - deviceImageSize) / 2, (canvas.height - squareSize + amountCircleSize / 2 + (squareSize - deviceImageSize)) / 2, deviceImageSize, deviceImageSize);
  
  ctx.fillStyle = '#f21e2b';
  ctx.beginPath();
  ctx.arc(canvas.width - amountCircleSize, amountCircleSize, amountCircleSize, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.fill();


  ctx.fillStyle = '#ffffff';
  ctx.fillText(amount.toString(), canvas.width - 8 - amountCircleSize / 2, amountCircleSize + 3);

  // Create a new image from the canvas
  const dImage = new Image();
  dImage.src = canvas.toDataURL(); // Convert the content of the canvas to a data URL
  return dImage;
};

/**
 * 
 * @param props 
 * @returns 
 */
const Devices = (props: Props) => {
  const notificationContext = useContext(NotificationStore);

  const [search, setSearch] = useState<string>(''); 
  const [allDevicesSearch, setAllDevicesSearch] = useState<string>(''); 
  const [scannedDevices, setScannedDevices] = useState<QubeDeviceType[]>([]);
  const [deviceToShow, setDeviceToShow] = useState<QubeDeviceType>();
  const [selectedDevicesToRemove, setSelectedDevicesToRemove] = useState<string[]>([]);
  const [selectedDevicesToAdd, setSelectedDevicesToAdd] = useState<string[]>([]);
  const [openAddQubeModal, setOpenAddQubeModal] = useState<boolean>(false);
  const [openScanQubeModal, setOpenScanQubeModal] = useState<boolean>(false);
  const [openTypeScanQubeModal, setOpenTypeScanQubeModal] = useState<boolean>(false);
  const [openScanConfirmationModal, setOpenScanConfirmationModal] = useState<boolean>(false);
  const [dragTimeout, setDragTimeout] = useState<number>();
  const [deviceDragOver, setDeviceDragOver] = useState<QubeDeviceType>();
  const [deviceToRemoveDragOver, setDeviceToRemoveDragOver] = useState<QubeDeviceType>();
  const [deviceDragging, setDeviceDragging] = useState<QubeDeviceType>();
  const [deviceToRemoveDragging, setDeviceToRemoveDragging] = useState<QubeDeviceType>();
  const [showingInactiveDevices, setShowingInactiveDevices] = useState<boolean>(false);
  const [customerDevicesDic, setCustomerDevicesDic] = useState<QubeDict>({});

  const devices = useMemo(() => {
    const ndevices: QubeDict = {};
    props.devices.forEach((device) => {
      if (device) ndevices[device.serial] = device;
    });
    return ndevices;
  }, [props.devices]); 

  const searchedDevices = useMemo(() => {
    const normalizedSearchText = unorm.nfkd(search).toLocaleLowerCase();
    const results = props.devices.filter((device) => {
      const normalizedObjectSerial = unorm.nfkd(device?.serial || '').toLocaleLowerCase();
      return normalizedObjectSerial.includes(normalizedSearchText)
        && device 
        && device.serial !== deviceDragging?.serial
    });
  
    return results;
  }, [search, deviceDragging, props.devices]);

  const customerDevices = useMemo(() => {
    const ndevices: QubeDict = {};
    props.customerDevices.forEach((device) => {
      if (device) ndevices[device.serial] = device;
    });

    return ndevices;
  }, [props.customerDevices]);

  const customerDeviceList = useMemo(() => {
    if (!devices || !props.customerDevices) return [];
    const customerDeviceDic = {};
    const deviceList = props.customerDevices.filter((device) => {
      const resp = device && !devices[device.serial]
      if (resp) customerDeviceDic[device.serial] = device;
      return resp;
    });
    setCustomerDevicesDic(customerDeviceDic);
    return deviceList;
  }, [devices, props.customerDevices]);

  const searchedAllDevices = useMemo(() => {
    const normalizedSearchText = unorm.nfkd(allDevicesSearch).toLocaleLowerCase();
    const results = customerDeviceList.filter((device) => {
      const normalizedObjectSerial = unorm.nfkd(device?.serial || '').toLocaleLowerCase();
      return normalizedObjectSerial.includes(normalizedSearchText);
    });
  
    return results;
  }, [allDevicesSearch, devices, customerDeviceList]);

  const dragImage = useMemo(() => {
    if (selectedDevicesToRemove.length < 2) return null;
    return getDragImage(selectedDevicesToRemove.length) || null;
  }, [selectedDevicesToRemove.length]);

  /**
   * 
   */
  const handleScan = (serial: string) => {
    const device = customerDevices[serial] as QubeDeviceType | undefined;
    if (!device) {
      // does not found a qube device in the customer list  
      notificationContext.dispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: 'Scan Error!',
          text: 'Invalid QRCode'
        },
      });
      return;
    }

    if (scannedDevices.find((scannedDevice) => scannedDevice.serial === serial)) {
      setOpenScanConfirmationModal(true);
      // already scanned
      notificationContext.dispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: 'Already Scanned!',
        },
      });
      return;
    }

    setScannedDevices([...scannedDevices, device])
    setOpenScanConfirmationModal(true);
  };

  /**
   * render device card list
   */
  const renderDevices = (hideCheckbox: boolean) => searchedDevices.map((device, index) => device ? (
    <Card
      dragImage={dragImage}
      onDrag={setDeviceToRemoveDragging}
      key={device.serial}
      device={device}
      hideCheckbox={hideCheckbox}
      onClick={() => {
        setDeviceToShow(device);
        setShowingInactiveDevices(false);
      }}
      onSelect={() => {
        const selecteds = [...selectedDevicesToRemove];
        const selectedIndex = selectedDevicesToRemove.indexOf(device.serial)

        if (selectedIndex === -1) {
          selecteds.push(device.serial);
        } else {
          selecteds.splice(selectedIndex, 1);
        }

        setSelectedDevicesToRemove(selecteds);
        setSelectedDevicesToAdd([]);
      }}
      selected={selectedDevicesToRemove.indexOf(device.serial) !== -1}
      light
    />
  ) : (
    <Skeleton key={index} loading width="100%" height="100px" />
  ));


  return (
    <Container>
      <DeviceHeader>
        <DeviceHeaderActions>
          <DefaultButton
            onClick={() => setOpenAddQubeModal(true)}
            customStyle={`
              display: flex;
              align-items: center;
              justify-content: flex-start;            
              gap: 8px;

              font-size: 14px;
            `}
          >
            <DeviceAddIcon />
            Add Devices
          </DefaultButton>

          <DefaultButton
            onClick={() => setOpenScanQubeModal(true)}
            customStyle={`
              display: flex;
              align-items: center;
              justify-content: flex-start;            
              gap: 8px;

              font-size: 14px;
            `}
          >
            <DeviceScanIcon />
            Scan Devices
          </DefaultButton>
        </DeviceHeaderActions>
      </DeviceHeader>

      <DeviceSidebar hide={!props.isAdministrator}>
        <DeviceSidebarSearch>
          <InputGroup customStyle="padding: 0;" label="All Devices">
            <SearchInput
              value={allDevicesSearch}
              placeholder="Search for devices"
              onChange={(e) => setAllDevicesSearch(e.target.value)}
            />
          </InputGroup>
        </DeviceSidebarSearch>

        <p>Select a device to add to this project</p>

        <DeviceSidebarTableWrapper
          onDrop={(event) => {
            event.preventDefault();
            const serial = event.dataTransfer.getData('text');
            let selecteds = [...selectedDevicesToRemove, serial];
            selecteds = [...new Set(selecteds)]; 
            const checkeds = props.devices.filter((device) => selecteds.includes(device.serial));

            if (selecteds.length && selecteds.length === checkeds.length)  {
              handleRemoveDevices(props.authToken, props.projectId, selecteds);
              setDeviceToRemoveDragOver(undefined);
              setDeviceToRemoveDragging(undefined);
              setSelectedDevicesToRemove([]);
              notificationContext.dispatch({
                type: 'SET_TOAST',
                data: {
                  type: 'success',
                  text: `${selecteds.length} Qube Device${selecteds.length > 1 ? 's' : ''} have been remove to ${props.projectName.toUpperCase()}.`
                },
              });
            }
          }}
          onDragOver={(event) => {
            if (!deviceToRemoveDragging) return;
            event.preventDefault();

            if (dragTimeout) clearTimeout(dragTimeout);
            setDeviceToRemoveDragOver(deviceToRemoveDragging);
            return false;
          }}
          onDragLeave={(event) => {
            event.preventDefault();

            if (dragTimeout) clearTimeout(dragTimeout);            
            
            const timeout = setTimeout(() => {
              setDeviceToRemoveDragOver(undefined)
              setDeviceToRemoveDragging(undefined)
            }, 600);
            
            setDragTimeout(timeout as unknown as number);
          }}
        >
          <CustomerDevicesTable
            devices={searchedAllDevices}
            dragOverDevice={deviceToRemoveDragOver}
            setSelectedDevices={setSelectedDevicesToAdd}
            setSelectedDevicesToRemoveDevices={setSelectedDevicesToRemove}
            selectedDevices={selectedDevicesToAdd}
            dragDevices={deviceToRemoveDragOver ? selectedDevicesToRemove.map((serial) => customerDevices[serial]) : []}
            draggable
            onDrag={setDeviceDragging}
            setDeviceToShow={(device) => {
              setDeviceToShow(device);
              setShowingInactiveDevices(true);
            }}
          />
        </DeviceSidebarTableWrapper>
      </DeviceSidebar>

      <DeviceContent>
        <DeviceContentSearch>
          <InputGroup customStyle={deviceSearchCustomStyle} label="Project Devices">
            <SearchInput
              value={search}
              placeholder="Search for devices"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>

          <DefaultButton
            onClick={() => setOpenTypeScanQubeModal(true)}
            customStyle={`
              display: none;
              align-items: center;
              justify-content: flex-start;            
              gap: 8px;

              height: 40px;

              font-size: 14px;

              @media (min-width: 1024px) {
                display: flex;
                ${props.isAdministrator ? '' : 'display: none;'}
              }
            `}
          >
            <DeviceAddIcon />
            Scan Devices
          </DefaultButton>

          <DeviceCheckAll hide={!props.isAdministrator}>
            <Checkbox
              checked={selectedDevicesToRemove.length && selectedDevicesToRemove.length === props.devices.length}
              onClick={() => {
                if (selectedDevicesToRemove.length === props.devices.length) {
                  setSelectedDevicesToRemove([]);
                  setSelectedDevicesToAdd([]);
                } else {
                  const allSerials = props.devices.map(((device) => device.serial));
                  setSelectedDevicesToRemove(allSerials);
                  setSelectedDevicesToAdd([]);
                }
              }}
            />
          </DeviceCheckAll>
        </DeviceContentSearch>
        
        <DeviceList
          id="target"
          data-testid="device-list"
          onDrop={(event) => {
            event.preventDefault();
            const serial = event.dataTransfer.getData('text');
            let selecteds = [...selectedDevicesToAdd, serial];
            selecteds = [...new Set(selecteds)]; 
            const checkeds = customerDeviceList.filter((device) => selecteds.includes(device.serial));

            if (selecteds.length && selecteds.length === checkeds.length)  {
              handleConfirmDevices(props.authToken, props.projectId, selecteds);
              setDeviceDragOver(undefined);
              setDeviceDragging(undefined);
              setSelectedDevicesToAdd([]);
              notificationContext.dispatch({
                type: 'SET_TOAST',
                data: {
                  type: 'success',
                  text: `${selecteds.length} Qube Device${selecteds.length > 1 ? 's' : ''} have been added to ${props.projectName.toUpperCase()}.`
                },
              });
            }
          }}
          onDragOver={(event) => {
            event.preventDefault();

            if (dragTimeout) clearTimeout(dragTimeout);
            setDeviceDragOver(deviceDragging);
            return false;
          }}
          onDragLeave={(event) => {
            event.preventDefault();
            
            const timeout = setTimeout(() => {
              setDeviceDragOver(undefined)
              setDeviceDragging(undefined)
            }, 600);
            
            setDragTimeout(timeout as unknown as number);
          }}
        >
          {
            deviceDragOver && selectedDevicesToAdd.findIndex((serial) => deviceDragOver?.serial === serial) === -1 ? (
              <Card
                onDrag={() => null}
                key={deviceDragOver.serial}
                device={deviceDragOver}
                onClick={() => {}}
                onSelect={() => {}}
                selected={false}
                light
                opaque
              />
            ) : null
          }
          
          {
            deviceDragOver ? selectedDevicesToAdd.map((serial) => {
              const device = customerDevices[serial];
              return (
                <Card
                  onDrag={() => null}
                  key={device.serial}
                  device={device}
                  onClick={() => {}}
                  onSelect={() => {}}
                  selected={false}
                  light
                  opaque
                />
              );
            }) : null
          }

          {renderDevices(!props.isAdministrator)}
        </DeviceList>
      </DeviceContent>
      
      {
        selectedDevicesToAdd.length ? (
          <CustomerDevicesFloatBar
            onlyDesk
            amount={selectedDevicesToAdd.length}
            onAddDevice={() => {
              handleConfirmDevices(
                props.authToken,
                props.projectId,
                selectedDevicesToAdd,
              );

              setSelectedDevicesToAdd([]);
              notificationContext.dispatch({
                type: 'SET_TOAST',
                data: {
                  type: 'success',
                  text: `Qube Devices have been add to ${props.projectName.toUpperCase()}.`
                },
              });
            }}
          />
        ) : null
      }

      {
        selectedDevicesToRemove.length ? (
          <DeviceFloatBar
            amount={selectedDevicesToRemove.length}
            onRemoveDevices={() => {
              handleRemoveDevices(
                props.authToken,
                props.projectId,
                selectedDevicesToRemove,
              );
              setSelectedDevicesToRemove([]);
              notificationContext.dispatch({
                type: 'SET_TOAST',
                data: {
                  type: 'success',
                  text: `Qube Devices have been removed to ${props.projectName.toUpperCase()}.`
                },
              });
            }}
          />
        ) : null
      }

      {
        openAddQubeModal ? (
          <DeviceCustomerDevicesModal
            devices={customerDeviceList}
            onClose={() => setOpenAddQubeModal(false)}
            onAddDevices={(deviceIds) => {
              handleConfirmDevices(props.authToken, props.projectId, deviceIds);
              notificationContext.dispatch({
                type: 'SET_TOAST',
                data: {
                  type: 'success',
                  text: `Qube Devices have been added to ${props.projectName.toUpperCase()}.`
                },
              });
            }}
          />
        ) : null
      }
      
      {
        openScanQubeModal ? (
          <DeviceScan
            onClose={() => setOpenScanQubeModal(false)}
            onScan={(text) => handleScan(text)}
          />
        ) : null
      }
      
      {
        openTypeScanQubeModal ? (
          <DeviceTypeScan
            onClose={() => setOpenTypeScanQubeModal(false)}
            onScan={(text) => handleScan(text)}
          />
        ) : null
      }

      <DeviceCarousel
        devices={showingInactiveDevices ? customerDevicesDic : devices}
        changeSelectedDevice={setDeviceToShow}
        hideArmButton={!props.isAdministrator}
        selected={deviceToShow}
        onClose={() => {
          setDeviceToShow(undefined);
          setShowingInactiveDevices(false);
        }}
      />

      {
        openScanConfirmationModal ? (
          <DeviceScanConfirmation
            devices={scannedDevices}
            onConfirm={() => {
              const devicesIds = scannedDevices.map(({ serial }) => serial)
              setOpenScanConfirmationModal(false);
              setScannedDevices([]);
              handleConfirmDevices(props.authToken, props.projectId, devicesIds);
              notificationContext.dispatch({
                type: 'SET_TOAST',
                data: {
                  type: 'success',
                  text: `Qube Devices have been added to ${props.projectName.toUpperCase()}.`
                },
              });
            }}
            onClose={() => setOpenScanConfirmationModal(false)}
            onScanAnother={() => {
              const viewportWidth = window.innerWidth;
              
              setOpenScanConfirmationModal(false);

              if (viewportWidth > 1024) {
                setOpenTypeScanQubeModal(true);
              } else {
                setOpenScanQubeModal(true);
              }
            }}
          />
        ) : null
      }
    </Container>
  );
};

export default Devices;
