import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import AcccountStore from 'app/modules/account/account.context';
import ProfileModal from 'app/components/profile-modal/profile-modal';
import { verifyAuth } from './private-wrapper.controller';
import { handleAcceptShareableInvitation } from './public-wrapper.controller';

/**
 *
 * @param param0
 * @returns
 */
const PublicWrapper = ({ children }: any) => {
  const history = useHistory();
  const accountContext = useContext(AcccountStore);

  // TODO: refactory that
  const redirect = () => {
    const type = qs.parse(window.location.search).redirect_to as
      | 'invitation'
      | null;
  
    const types = {
      invitation: () => {
        history.push(`/projects`);
      },
      'shareable-invitation': () => {
        const token = qs.parse(window.location.toString()).t as string;
        if (token) {
          handleAcceptShareableInvitation(
            token,
            accountContext.state.auth?.user.id,
            accountContext.dispatch,
            history
          );
        }
      },
    };
  
    if (type && types[type]) types[type]();
    else history.push(`/projects`);
  };

  /**
   *
   */
  useEffect(() => {
    const token: string | null = window.localStorage.getItem('CDI@auth-token');
    verifyAuth(token || '', accountContext.dispatch);
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (accountContext.state.auth) redirect();
  }, [accountContext.state.auth]);

  if (!accountContext.state.verified) return null;
  return (
    <>
      {children}
      <ProfileModal />
    </>
  );
};


export default PublicWrapper;
