import styled from 'styled-components';

export const FigureWrapper = styled.figure`
  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;

    color: #676b7a;

    padding: 4px;

    margin-bottom: 8px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 220px;

  object-position: center;
  object-fit: contain;

  cursor: pointer;
`;
