import { Modals, SettingsContext, SettingsDispatchType } from "./settings.context.d";

export const initialModalsValue: Modals = {
  chart: false,
  createProject: false,
  createSurvey: false,
  profile: false,
  updateSurvey: false,
  createEvent: false,
  onlineUsers: false,
  eventList: false,
};

/**
 * 
 * @param action 
 * @param state 
 * @returns 
 */
export const handleModalChange = (action: SettingsDispatchType, state: SettingsContext) => {
  const data = action.data as { [key: string]: boolean };
  return {
    ...state,
    modals: { ...initialModalsValue, ...data },
  };
}