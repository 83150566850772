import { ProjectType } from 'app/interfaces/inspection.interfaces';
import UserAvatar from '../user-avatar/userAvatar';

export const columnsModel = (): any[] => [
  {
    field: 'avatar',
    headerName: '',
    element: ({ user }: any) => UserAvatar({ user }),
    width: 50,
  },
  {
    field: 'first_name',
    headerName: 'Name',
    formatter: (user: any) => `${user.first_name} ${user.last_name}`,
  },
  { field: 'phone', headerName: 'Phone' },
  { field: 'email', headerName: 'Email', width: 200, ellipsis: true },
  {
    field: 'projects',
    headerName: 'Projects',
    formatter: (user: any) => {
      return user.participant_set
        .map(({ project }: { project: ProjectType }) => project.name)
        .join(' / ');
    },
  },
];
