import { TrackingPointCascadeType } from "app/modules/inspection/inspection.interfaces";
import { useEffect, useState } from "react";
import { createMarkerElement, renderMarker, toggleLabel } from "./tp-marker.controller";
import { getMarkerPosition, getTrackingpointIcon } from "app/utils/map.utils";
import MeasureTool from 'lib/measuretool-googlemaps-v3/src';
import { TPointMarkerGlobalStyle } from './to-marker.style';

interface Props {
  point: TrackingPointCascadeType;
  index: number;
  zoom: number| undefined;
  hasLabels: boolean;
  hasRuler: boolean;
  measureTool: MeasureTool | undefined;
  handleSelect?: (point: TrackingPointCascadeType) => void;
  map: google.maps.Map;
}

const TPMarker = ({
  point,
  map,
  zoom,
  hasLabels,
  hasRuler,
  measureTool,
  index,
  handleSelect,
}: Props) => {
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
  const [isClicked, setIsClicked] = useState<number>();
  
  /** */
  const handleClick = () => {
    if (marker && measureTool && hasRuler) {
      measureTool.checkClick({
        latLng: marker?.position,
      });
      return;
    }
    handleSelect && handleSelect(point);
  };

  useEffect(() => {
    const promisse = renderMarker(setIsClicked, point, map, setMarker, index, marker);
    return () => {
      promisse.then((marker) => {
        if (marker) marker.map = null;
      })
    };
  }, []);

  useEffect(() => {
    if (isClicked) handleClick();
  }, [isClicked])

  useEffect(() => {
    const coords = point.geometry?.coordinates;
    if (coords && marker) {
      marker.position = getMarkerPosition(coords)
    }
  }, [point.geometry?.coordinates, marker]);

  useEffect(() => {
    if (point.disabled && marker) {
      // TODO: marker.setOptions({ opacity: 0.5 });
    }
  }, [point.disabled, marker]);

  useEffect(() => {
    if (marker) {
      toggleLabel(marker, hasLabels);
    }
  }, [hasLabels]);
  
  useEffect(() => {
    if (marker) {
      const icon = getTrackingpointIcon(point, undefined, zoom === 6 ? 15 : zoom); // TODO TYPE
      marker.content = createMarkerElement(point, icon)
      toggleLabel(marker, hasLabels);
    }
    
  }, [point?.type, point.is_device_trigger, point.name, point.color, marker, point.chainage]);

  useEffect(() => {
    if (marker) {
      const label = marker.getElementsByTagName('label')[0];
      if (label) {
        label.innerText = point.name || '';
      }
    }
  }, [point.name, marker]);

  useEffect(() => {
    if (zoom && marker) {
      // TODO: zoom
    }
  }, [zoom, marker]);

  return <TPointMarkerGlobalStyle />;
};

export default TPMarker;