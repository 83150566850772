import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .MuiTextField-root {
    width: 100%;
    height: 100%;
  }

  .MuiTextField-root {
    width: 100%;
    height: 100%;
    display: block;
  }

  .MuiInput-underline:before {
    border: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }

  .MuiInput-underline:after {
    border: none;
  }

  .MuiFormControl-marginNormal {
    margin: 0;
    padding: 0;
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
  }

  .MuiInputBase-input {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 18px;
    color: #676b7a;
  }

  > label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;

    padding-left: 4px;
    color: #5850ec;

    cursor: pointer;

    > figure {
      position: relative;
      top: 2px;
    }
  }
`;

export const Dropdown = styled.div<{ hide: boolean }>`
  position: absolute;
  display: ${({ hide }) => (hide ? 'none' : 'block')};

  ul {
    list-style: none;

    position: relative;

    width: 150px;
    min-height: 50px;

    border-radius: 3px;

    background-color: #ffffff;

    top: -15px;
    left: 90%;

    z-index: 3;

    box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);

    > li {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 6px;
      padding-bottom: 6px;

      cursor: pointer;

      &:hover {
        color: #5850ec;
        background-color: rgba(220, 223, 226, 0.25);
      }
    }
  }
`;
