import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Wrapper } from './cellInputDate.style';

interface Props {
  value: string;
  handleChange: (data: any) => void;
  hasError: () => string;
  placeholder: string;
  customStyle: string;
  field?: string;
  id: string;
  disabled?: boolean;
}

/**
 * Input component
 * @returns
 */
const CellInputDate = ({
  value,
  handleChange,
  id,
  disabled,
  field,
  hasError,
}: Props) => {
  const error = hasError();
  return (
    <Wrapper error={error}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disabled={disabled}
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          inputVariant="standard"
          value={value ? new Date(`${value}`) : null}
          autoOk={true}
          onChange={() => ''}
          onAccept={(date) =>
            handleChange({
              value: date,
              oldValue: new Date(`${value}`),
              id,
              field,
            })
          }
        />
      </MuiPickersUtilsProvider>
    </Wrapper>
  );
};

CellInputDate.defaultProps = {
  value: '',
  id: '',
  customStyle: '',
  disabled: false,
  placeholder: '',
  handleChange: () => '',
  hasError: () => '',
};

export default CellInputDate;
