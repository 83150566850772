import { generateShareableLink } from '../../services/invitation.service';
import { GeneratedType } from './shareable-link.d';
import { roles } from './shareable-link.collections';

export const generateLinks = async (
  token: string,
  project: string,
  setGenerated: (generated: GeneratedType) => void,
  generated: GeneratedType,
  roleId: 'observer' | 'editor' | 'admin'
) => {
  const role = roles.find(({ id }) => roleId === id);

  if (!role) {
    return;
  }

  const generateResponse = await generateShareableLink(
    project,
    role.editor,
    role.admin,
    token
  );

  const newGenerated = { ...generated } as GeneratedType;
  newGenerated[roleId] = generateResponse.data.url;

  setGenerated(newGenerated);
};
