import { useContext } from 'react';
import { formatTimezoneDate } from '@/lib/formatter';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { ConnectionErrorLabel, ConnectionErrorLabelTimezone } from './connection-warning.style';

/**
 * 
 * @returns 
 */
const ConnectionWarning = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const connectionErrorText = `${settingsContext.state.timezone?.label} time.`;
  const connectionLostText = `Connection lost at ${formatTimezoneDate({
    date: inspectionContext.state.no_connection_since || '',
    timezone: settingsContext.state.timezone?.id,
    format: 'HH:mm',
   })}`;
  
  if (!inspectionContext.state.no_connection_since) return null;

  return (
    <ConnectionErrorLabel $show={!!inspectionContext.state.no_connection_since}>
      {connectionLostText}
      <ConnectionErrorLabelTimezone>{connectionErrorText}</ConnectionErrorLabelTimezone>
    </ConnectionErrorLabel>
  );
};

export default ConnectionWarning;
