import { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import InputMask from 'react-input-mask';
import { extractNumbers, formatMps, formatSpeedToMps, formatTimezoneDate } from '@/lib/formatter';
import {
  ModalWrapper,
  Modal,
  ModalFigure,
  ButtonWrapper,
  ConfirmationTitle,
  ConfirmationText,
  ConfirmationFooter,
  TimeWrapper,
} from './confirmation-launch-modal.style';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import NotificationStore from 'app/modules/notification/notification.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import Radio from 'app/components/radio/radio';
import InputGroup from 'pa/src/app/components/molecules/input-group/input-group';
import Input from 'app/components/input/input';
import Button from 'app/components/button/button';
import LinkButton from 'app/components/link-button/link-button';
import InputDate from 'app/components/input-date/input-date';
import { launchRun } from 'app/modules/inspection/inspection.controller';

/**
 * 
 * @returns
 */
const ConfirmationLaunchModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const notificationContext = useContext(NotificationStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [launchSpeed, setLaunchSpeed] = useState<string>();
  const [launchDate, setLaunchDate] = useState<string>();
  const [launchTime, setLaunchTime] = useState<string>('');
  const [isTest, setIsTest] = useState<boolean>(false);
  const [launchDateTime, setLaunchDateTime] = useState<Date>();

  useEffect(() => {
    const now = new Date();
    setLaunchDateTime(now);
    
    const time = formatTimezoneDate({
      date: now,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'HH:mm:ss.SSS',
    });
    const date = formatTimezoneDate({
      date: now,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'YYYY/MM/DD',
    });
    setLaunchDate(date);
    setLaunchTime(time);
    return () => {
      setLaunchDate(undefined); 
      setLaunchTime('');
    }
  }, []);

  useEffect(() => {
    setLaunchSpeed(
      formatMps({
        distance: inspectionContext.run?.predicted_launch_speed,
        unit: settingsContext.state.speedUnit,
        returnNumber: true,
      }) as string
    );
  }, [
    settingsContext.state.speedUnit,
    inspectionContext.run?.predicted_launch_speed
  ]);

  /**
   * 
   */
  const handleCancel = () => {
    inspectionContext.dispatch({ type: 'CLOSE_MODALS' });
  };

  return (
    <ModalWrapper id="confirmation-launch-modal">
      <Modal>
        <ModalFigure>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff">
          <path d="M12 5.99L19.53 19H4.47L12 5.99M2.74 18c-.77 1.33.19 3 1.73 3h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18zM11 11v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zm0 5h2v2h-2z" />
        </svg>  
        </ModalFigure>
        <ConfirmationTitle>Are you sure?</ConfirmationTitle>
        <ConfirmationText>The Run simulation will be started, and 1 credit will be spent.
        You currently have {inspectionContext.run?.customer_balance} credits.
        You will have one hour to abort the run and get the credit back.</ConfirmationText>
        <Radio
          customStyle="margin: 15px 0;"
          selected={isTest}
          text="Launch as test"
          onClick={() => setIsTest(!isTest)}
        />
        <InputGroup
            label={`Launch Time ${settingsContext.state.timezone?.label}:`}
            error=""
            description='YYYY-MM-DD &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  HH:mm:ss.SSS'
            customStyle={`
              visibility: hidden;
              width: 2px;
              height: 2px;
              padding: 0;
              label {
                margin-bottom: 8px;
                padding-right: 8px;
                padding-bottom: 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }
              > div {

  
                > input {
                  width: 100px;
                  display: inline-block;
                  padding: 4px 8px;
                  border: 1px solid #e5e5e5;
                  border-radius: 4px;
  
                  &:active {
                    padding: 5px 10px;
                    border: 1px solid #5850ec;
                  }
                  
                  &:focus {
                    padding: 5px 10px;
                    border: 1px solid #5850ec;
                  }
                }
              }
            `}
          >
            <TimeWrapper>
              <InputDate
                placeholder="YYYY/MM/DD"
                customStyle={`
                  width: 100px;
                  display: inline-block;
                  padding-left: 4px;
                  height: 25px;
                  padding-right: 4px;
                `}
                testid="create-timepin-date-field"
                handleChange={({ value }) => {
                  setLaunchDate(
                    formatTimezoneDate({
                      date: value,
                      timezone: settingsContext.state?.timezone?.id || '',
                      format: 'YYYY/MM/DD',
                    })
                  )
                }}
                value={launchDate}
                id="confirmation-launch-modal-date"
              />
              <InputMask
                id="confirmation-launch-modal-time"
                maskChar=""
                mask="99:99:99.9999"
                placeholder='HH:mm:ss.SSS'
                value={launchTime}
                onChange={({ target }) => setLaunchTime(extractNumbers(target.value, true))}
              />
            </TimeWrapper>
          </InputGroup>
        <ConfirmationFooter>
          <InputGroup
            label={`Predicted launch Speed (${settingsContext.state.speedUnit?.label})`}
            error=""
            customStyle={`
              padding: 0;

              label {
                font-size: 13px;
              }
            `}
          >
            <Input
              testid='confirmation-launch-modal-predicted-speed'
              customStyle={`
                width: 60px;

                padding-left: 4px;
                padding-right: 4px;
              `}
              value={launchSpeed}
              handleChange={(event) => setLaunchSpeed(event.target.value)}
            />
          </InputGroup>
          <ButtonWrapper>
            <Button
              disabled={!launchSpeed}
              handleClick={() => {
                
                const speed =  +formatSpeedToMps({
                  distance: launchSpeed,
                  unit: settingsContext.state.speedUnit?.id,
                });

                if (!inspectionContext.run || !launchDateTime) return;
                const momentDateTime = moment.utc(launchDateTime.toISOString());


                launchRun(
                  inspectionContext.run.id,
                  auth.token,
                  momentDateTime.toDate(),
                  speed,
                  isTest,
                  notificationContext.dispatch,
                );

                inspectionContext.dispatch({ type: 'CLOSE_MODALS' });
              }}
              value="Confirm"
            />
            <LinkButton handleClick={handleCancel}>Cancel</LinkButton>
          </ButtonWrapper>
        </ConfirmationFooter>
      </Modal>
    </ModalWrapper>
  );
};

ConfirmationLaunchModal.defaultProps = {
  hide: true,
};

export default ConfirmationLaunchModal;
