import { verifyToken } from "app/services/auth.service";
import { acceptShareableInvitation } from "app/services/invitation.service";
import { AccountDispatchType } from "./account.context.d";

/**
 * 
 * @param token 
 * @param userId 
 * @param dispatch 
 * @param history 
 * @returns 
 */
export const handleAcceptShareableInvitation = async (
  token: string,
  userId: number | undefined,
  dispatch: (action: AccountDispatchType) => void,
  history: any
) => {
  let acceptResponse: any;

  try {
    acceptResponse = await acceptShareableInvitation(token, {
      user_id: userId,
    });
  } catch (err: any) {
    if (
      err.response.data.error === 'shareable-invitation/already-participant'
    ) {
      history.push(`/projects/${err.response.data.data.project.id}`);
      return;
    }
  }

  const verifyResponse = await verifyToken(acceptResponse.data.auth_token);

  window.localStorage.setItem('CDI@auth-token', acceptResponse.data.auth_token);
  window.localStorage.setItem('CDI@auth-email', verifyResponse.data.user.email);
  
  dispatch({
    type: 'LOGIN',
    data: {
      user: verifyResponse.data.user,
      token: acceptResponse.data.auth_token,
    },
  });

  history.push(`/projects/${acceptResponse.data.invitation.project.id}`);
};
