import { useStatus } from 'app/services/no-connection.service';
import Typograph from 'stories/type/typograph/typograph';
import { FloatingRefreshNotificationContainer } from './floating-refresh-notification.style';
import { useEffect, useState } from 'react';
import FilledButton from 'stories/base/buttons/filled-button/filled-button';

interface Props {
  noConnectionSince: Date | undefined;
}

/**
 * 
 * @param props 
 * @returns 
 */
const FloatingRefreshNotification = (props: Props) => {
  const { upgraded } = useStatus(props.noConnectionSince);
  const [hasUpdate, setHasUpdate] = useState<boolean>(false);

  /**
   * 
   */
  const onRefresh = () => {
    setHasUpdate(false);
    window.location.reload();
  };
  
  useEffect(() => {
    if (upgraded) setHasUpdate(true);
  }, [upgraded]);

  // if there is no connection since, do not show the notification
  if (!hasUpdate) return null;

  return (
    <FloatingRefreshNotificationContainer>
      <Typograph type="body2">We’ve released a new version of GlobalTrack. Please refresh the page to access the latest features.</Typograph>
      <FilledButton styleType="secondary" onClick={onRefresh} text="Refresh" />
    </FloatingRefreshNotificationContainer>
  );
};

export default FloatingRefreshNotification;
