import styled from 'styled-components';


export const ProjectContainer = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto auto auto 42px 1fr;

  @media (max-width: 1023px) {
    padding-top: 0;
  }
`;

export const ProjectHeader = styled.header`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  display: grid;
  grid-template-columns: 1fr auto auto;

  > div:last-child {
    padding-top: 16px;
  }
`;

export const ProjectTitle = styled.label<{
  customStyle?: string;
  hidden?: boolean;
}>`
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 20px;
  max-width: 900px;
  width: 100%;

  ${({ hidden }) => (hidden ? 'display: none' : '')};
  ${({ customStyle }) => customStyle}
`;

export const CreatedBy = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #686c7b;
`;

export const DescriptionWrapper = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export const DescriptionLabel = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #161e2e;
`;

export const DescriptionText = styled.p`
  margin-top: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  max-width: 694px;

  color: #686c7b;
`;

export const ProjectContent = styled.main`
  padding: 16px;
  background-color: rgba(240, 242, 243, 0.5);

  width: 100%;
  overflow-x: auto;

  @media (max-width: 1023px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const TabWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const SkeletonTextWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin-top: 16px;
`;

export const MenuButton = styled.button<{ hide: boolean }>`
  border: 1px solid #dfe0e4;
  border-radius: 6px;
  background-color: transparent;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  cursor: pointer;
  position: relative;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const MenuListWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  top: 40px;
  right: 0;

  display: grid;

  width: 120px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;

  overflow: hidden;
  z-index: 3;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const MenuListItem = styled.div`
  padding: 8px;

  display: grid;
  grid-template-columns: 24px auto;
  justify-items: start;

  gap: 6px;

  font-size: 13px;
  font-weight: 500;

  color: #6b7280;

  :hover {
    background-color: #f1f1f1;
  }
`;
