import styled from 'styled-components';

export const ModalWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  z-index: 21;
  top: 0;
  right: 0;

  display: grid;
  grid-template-columns: 1fr;

  width: 100%;
  max-height: 100%;

  color: #ffffff;

  @media (min-width: 1024px) {
    width: auto;
    grid-template-columns: 365px;
  }
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const Modal = styled.div`
  position: relative;


  background: #FFF;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  margin 8px;
  color: #000;

  padding-bottom: 28px;

  > svg {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ConfirmationTitle = styled.h3<{ customStyle?: string }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;

  margin-top: 14px;
  margin-bottom: 8px;
  ${({ customStyle }) => customStyle}
`;

export const ConfirmationFooter = styled.footer`
  display: grid;
  justify-content: space-between;
  align-items: end;

  grid-template-columns: 1fr;
  gap: 24px;

  margin-top: 24px;

  button {
    height: 36px;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;

  width: 100%;
`;

export const EventExplanation = styled.p`
  display: block;

  font-size: 10px;

  width: 100%;
  margin-bottom: 24px;
`;

export const Main = styled.div`
  padding: 0 22px;
  border-top: 0.7px solid rgba(130, 132, 143, 0.7);
`;

export const Header = styled.div`
  padding: 0 22px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 20px;
  width: 100%;
  border-radius: 6px;

  background-color: transparent;
  font-weight: 500;

  z-index: 1;
  color: #494c5d;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  margin: 5px 0;

  svg {
    cursor: pointer;
    position: relative;
    top: 1px;
    margin-right: 8px;
  }
`;

export const buttonCustomStyle = `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  text-align: center;

  color: #FAFAFB;
`;

export const CardsListWrapper = styled.div`
  width: 100%;
  padding: 14px 0;
`;

export const CardWrapper = styled.div`
  width: 100%;
  background-color: #f5f5f8;
  padding: 8px;
  border-radius: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 6px 0;
`;

export const CardLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
`;

export const CardButton = styled.button`
  padding: 6px 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  text-align: center;

  color: #ffffff;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

  background-color: #5850ec;
  border-radius: 30px;

  cursor: pointer;
`;
