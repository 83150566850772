import Skeleton from 'app/components/skeleton/skeleton';
import RunCard from 'app/components/run-card/run-card';
import { onDeleteRun } from '../../project.controller';
import {
  EmptyContent,
  EmptyContentTitle,
  RunList,
  RunsSkeletonWrapper,
} from './run.style';

interface RunsProps {
  runs: any[];
  loading: boolean;
  auth: any;
  refreshFunc: (data?: any) => void;
  setLoading: (loading: boolean) => void;
  setRuns: any;
  usingLineStat: boolean;
  usingQube: boolean;
  hideRunMenu?: boolean;
}

/**
 *
 * @param runs
 * @returns
 */
const renderRuns = (
  runs: any,
  onUpdated: (data?: any) => void,
  setLoading: any,
  auth: any,
  setRuns: any,
  usingLineStat: boolean,
  usingQube: boolean,
  hideRunMenu?: boolean | undefined,
) =>{
  return runs.map((run: any) => (
    <RunCard
      usingQube={usingQube}
      key={run.id}
      run={run}
      hideMenu={hideRunMenu}
      onUpdated={onUpdated}
      usingLineStat={usingLineStat}
      onDelete={() =>
        onDeleteRun({
          setLoading,
          run,
          auth,
          setRuns,
          runs,
        })
      }
    />
  ));
}
const Runs = ({
  runs,
  loading,
  auth,
  refreshFunc,
  setLoading,
  setRuns,
  usingLineStat,
  hideRunMenu,
  usingQube,
}: RunsProps) =>
  !runs.length && !loading ? (
    <EmptyContent>
      <EmptyContentTitle>
        This project doesn’t have any runs yet...
      </EmptyContentTitle>
    </EmptyContent>
  ) : (
    <RunList>
      {!loading ? (
        renderRuns(
          runs,
          refreshFunc,
          setLoading,
          auth,
          setRuns,
          usingLineStat,
          usingQube,
          hideRunMenu,
        )
      ) : (
        <RunsSkeletonWrapper>
          <Skeleton loading={loading} height="112px" />
          <Skeleton loading={loading} height="112px" />
        </RunsSkeletonWrapper>
      )}
    </RunList>
  );

export default Runs;
