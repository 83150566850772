import { useContext } from 'react';
import { RouterHistory } from '@sentry/react/types/reactrouter';
import { useHistory, Link } from 'react-router-dom';
import { formatTimezoneDate } from '@/lib/formatter';
// context
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
// interfaces
import { AuthType } from 'app/modules/account/account.context.d';
import { RunType } from 'app/interfaces/inspection.interfaces';
import ProgressBar from 'app/components/progress-bar/progress-bar';
import ProjectCard from 'app/components/project-card/project-card';
import Map from 'app/components/common/map/map';
import Skeleton from 'app/components/skeleton/skeleton';
import Header from '../../components/header/header';
import { Props } from './list.d';
import {
  ActiveRunWrapper,
  ProjectContent,
  RunList,
  EmptyContent,
  EmptyContentTitle,
  RunsSkeletonWrapper,
  RunCardWrapper,
  RunCardsWrapper,
  ProgressBarContainer,
  SectionTitle,
  RunName,
  LaunchedAt,
  Pig,
  PercentageLabel,
  Right,
  Left,
  SlimButton,
  ListFooter,
} from './list.style';

/**
 *
 * @param runs
 * @returns
 */
const renderProjects = (projects: any[]) =>
  projects?.map((project: any, i: number) => (
    <ProjectCard
      index={i}
      key={project.id}
      id={project.id}
      qttRuns={project.runs?.length || 0}
      name={project.name}
      usingLineStat={project.using_linestat}
      usingQube={project.using_qube}
      runs={project.runs || []}
      company={project.company}
      description={project.description}
      completedPoints={project.completed_points}
      totalPoints={project.total_points}
      nextLocation={project.next_point_coordinates}
      nextEta={project.next_point_eta}
      participants={project.participant_set.map((participant) => participant.user)}
    />
  ));

/**
 *
 */
const renderActiveRuns = (
  runs: RunType[],
  history: RouterHistory,
  timezone?: string
) =>
  runs.map((r) => {
    const total_points = r?.total_points || 0;
    let currentPercentage = (r.completed_points / total_points) * 100;
    if (isNaN(currentPercentage)) currentPercentage = 0;

    return (
      <Link key={`run-project-card-${r.id}`} to={`/runs/${r.id}`}>
        <RunCardWrapper>
          <Pig src="/assets/icons/tracking-points/pig.svg" />
          <RunName>{r.name}</RunName>
          <LaunchedAt>
            <strong>Launched at:</strong>{' '}
            {formatTimezoneDate({
              date: r.launch_time?.toString() || '',
              timezone: timezone || '',
              format: 'MMM DD YYYY, H:mm',
            })}
          </LaunchedAt>
          <ProgressBarContainer>
            <ProgressBar currentPercentage={currentPercentage} />
            <PercentageLabel>
              {`${Math.ceil(currentPercentage)}% complete (${r.completed_points
                } of ${total_points})`}
            </PercentageLabel>
          </ProgressBarContainer>
        </RunCardWrapper>
      </Link>
    );
  });

const defaultProps = {
  activeRuns: [] as RunType[],
};

/**
 *
 * @param params
 * @returns
 */
const List = ({
  projects,
  runs,
  loading,
  onLoadMore,
  hasMore,
  activeRuns,
  onOpenCreateProjectModal,
}: Props & typeof defaultProps) => {
  const history = useHistory();
  const settingsContext = useContext(SettingsStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  return (
    <ProjectContent>
      <Left>
        <Header
          isAdministrator={!!auth?.user?.managed_customer?.id}
          onOpenCreationModal={onOpenCreateProjectModal}
        />
        <RunCardsWrapper>
          <SectionTitle hidden={!activeRuns?.length}>Running</SectionTitle>
          <ActiveRunWrapper data-testid="active-runs">
            {renderActiveRuns(activeRuns, history, settingsContext.state?.timezone?.id)}
          </ActiveRunWrapper>
        </RunCardsWrapper>
        <SectionTitle
          customStyle={activeRuns?.length ? '' : 'padding-top: 40px;'}
        >
          Projects
        </SectionTitle>

        {!projects.length && !loading ? (
          <EmptyContent>
            <EmptyContentTitle>
              You don’t have any projects yet...
            </EmptyContentTitle>
          </EmptyContent>
        ) : (
          <RunList>{renderProjects(projects)}</RunList>
        )}

        {loading ? (
          <RunsSkeletonWrapper>
            <Skeleton loading={loading} height="112px" />
            <Skeleton loading={loading} height="112px" />
            <Skeleton loading={loading} height="112px" />
          </RunsSkeletonWrapper>
        ) : null}

        {hasMore && !loading ? (
          <ListFooter>
            <SlimButton onClick={onLoadMore} data-testid="load-more">
              Load More
            </SlimButton>
          </ListFooter>
        ) : null}
      </Left>
      <Right>
        <Map
          bounds={[]}
          runs={runs}
          modules={['project']}
          streetView
          view_mode={'editor'}
          controllers={['weather', 'zoom', 'map-type']}
        />
      </Right>
    </ProjectContent>
  );
};

List.defaultProps = defaultProps;

export default List;
