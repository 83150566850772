import {
  deleteSurvey,
  getSurvey,
  updateSurveyPoint,
  uploadSurveyPointMedia,
} from 'app/services/survey.service';
import { AccountContext, AuthType } from 'app/modules/account/account.context.d';
import { SurveyProviderValue } from 'app/modules/survey/survey.context.d';
import { SettingsContext } from 'app/modules/settings/settings.context.d';
import { PROPORTION } from 'app/components/slick-table/editors/DistanceEditor';

export const fetchSurvey = async ({
  id,
  auth,
  dispatch,
}: {
  id: number;
  auth: AccountContext['auth'];
  dispatch: SurveyProviderValue['dispatch'];
}) => {
  if (!auth) return;
  const surveyResponse = await getSurvey(id, auth.token);
  dispatch({
    type: 'SET_SURVEY',
    data: surveyResponse.data
  });
};

export const onStepChange = async (
  formData: any,
  surveyContext: SurveyProviderValue,
  nextStep: number,
  auth: AuthType,
  setIsLoading: (data: boolean) => void,
  settingsContext: SettingsContext,
) => {
  if (!surveyContext.state.selected_point || !surveyContext.state.survey?.surveypoint_set) return;

  const updatedPoint = await saveAction({
    point: formData,
    auth,
    setIsLoading,
    settingsContext,
  });
  const index = surveyContext.state.points_dic[surveyContext.state.selected_point].index;
  const pointSet = surveyContext.state.survey?.surveypoint_set;
  surveyContext.dispatch({
    type: 'UPDATE_POINT',
    data: updatedPoint
  });

  if (index === 0 && nextStep === -1) {
    const prev = pointSet[index - 1]
    surveyContext.dispatch({
      type: 'SET_SELECTED_POINT',
      data: prev.id
    });
  } else if (index === pointSet.length - 1 && nextStep === 1) {
    surveyContext.dispatch({
      type: 'SET_SELECTED_POINT',
      data: pointSet[0].id
    });
  } else {
    surveyContext.dispatch({
      type: 'SET_SELECTED_POINT',
      data: pointSet[index + nextStep].id
    });
  }
};

export const saveAction = async ({
  point,
  auth,
  setIsLoading,
  settingsContext,
}: any) => {
  const pointToUpdate = {
    name: point.name,
    speed_offset: point.speedOffset ? parseFloat(point.speedOffset) : 0,
    description: point.description,
    antenna_height: point.antennaHeight
      ? parseInt(point.antennaHeight, 10)
      : null,
    chainage: PROPORTION[settingsContext.distanceUnit.id] * parseFloat(point.chainage) || 0,
    datum: point.datum,
    elevation_top: point.elevationTop ? parseInt(point.elevationTop, 10) : null,
    ellipsoid_height: point.ellipsoidHeight
      ? parseInt(point.ellipsoidHeight, 10)
      : null,
    ellipsoid_height_cop: point.ellipsoidHeightCop
      ? parseInt(point.ellipsoidHeightCop, 10)
      : null,
    geoid_model: point.geoidModel,
    survey_accuracy: point.surveyAccuracy,
    survey_method: point.surveyMethod,
    surveyor: point.surveyor,
    comment: point.comment,
    depth_of_cover: point.depthOfCover,
    station_number: point.stationNumber,
    milepost: point.milepost,
    alignment_sheet: point.alignmentSheet,
    raw_footage: point.rawFootage,
    device_sn: point.deviceSn,
    county: point.county,
    country: point.country,
    state: point.state,
    tract: point.tract,
    pressure_differential: point.pressureDifferential,
    location_description: point.locationDescription,
    land_owner: point.land_owner,
    owner_phone: point.owner_phone,
    ht_correction_top: point.ht_correction_top,
    height_comment: point.height_comment,
    survey_notes: point.survey_notes,
    survey_technician: point.survey_technician,
    survey_date: point.survey_date,
    field_notes: point.field_notes,
    reference_technician: point.reference_technician,
    reference_date: point.reference_date,
    reference_time: point.reference_time,
    cell_coverage: point.cell_coverage,
    site_access: point.site_access,
  };
  setIsLoading(true);

  const mediasPromise = point.medias
    .filter((m: any) => !m.id)
    .map(
      (m: any) =>
        new Promise((res, rej) => {
          uploadSurveyPointMedia(
            `${point.id}`,
            m.file,
            point.captionsToNewMedia[m.url] || null,
            point.newSiteSketchs[m.url] || false,
            auth.token
          )
            .then((response: any) => res(response))
            .catch((err) => rej(err));
        })
    );
  try {
    await Promise.all(mediasPromise);
  } catch (err) {
    setIsLoading(false);
  }

  try {
    const response = await updateSurveyPoint(
      pointToUpdate,
      point.id,
      auth.token
    );
    const updatedPoint = response.data.point;

    setIsLoading(false);
    return updatedPoint;
  } catch (err) {
    setIsLoading(false);
  }
};

export const onDeleteSurvey = async ({
  survey,
  auth,
  onDone,
  dispatch,
}: any) => {
  try {
    deleteSurvey(survey.id, auth.token);
  } catch (err: any) {
    if (err.response?.data?.type) {
      return dispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }

  dispatch({
    type: 'SET_TOAST',
    data: {
      type: 'loading',
      text: 'Deleting Survey',
      timeout: 2000,
    },
  });

  // await animation time
  setTimeout(onDone, 2000)
};
