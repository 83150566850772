import styled from 'styled-components';

export const Headline1 = styled.h1`
  ${({ theme }) => theme.fonts.typograph.headline1};
`;

export const Headline2 = styled.h2`
  ${({ theme }) => theme.fonts.typograph.headline2};
`;

export const Headline3 = styled.h3`
  ${({ theme }) => theme.fonts.typograph.headline3};
`;

export const Headline4 = styled.h4`
  ${({ theme }) => theme.fonts.typograph.headline4};
`;

export const Headline5 = styled.h5`
  ${({ theme }) => theme.fonts.typograph.headline5};
`;

export const Headline6 = styled.h6`
  ${({ theme }) => theme.fonts.typograph.headline6};
`;

export const Subtitle1 = styled.p`
  ${({ theme }) => theme.fonts.typograph.subtitle1};
`;

export const Subtitle2 = styled.p`
  ${({ theme }) => theme.fonts.typograph.subtitle2};
`;

export const Body1 = styled.p`
  ${({ theme }) => theme.fonts.typograph.body1};
`;

export const Body2 = styled.p`
  ${({ theme }) => theme.fonts.typograph.body2};
`;

export const Button1 = styled.p`
  ${({ theme }) => theme.fonts.typograph.button1};
`;

export const Button2 = styled.p`
  ${({ theme }) => theme.fonts.typograph.button2};
`;

export const Caption = styled.p`
  ${({ theme }) => theme.fonts.typograph.caption};
`;

export const Overline = styled.p`
  ${({ theme }) => theme.fonts.typograph.overline};
`;
