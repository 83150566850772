import styled from 'styled-components';

export const ResizableContainer = styled.div`
  width: 100vw;
  height: 100%;
`;

export const DraggableComponent = styled.span<{ disabled: boolean }>`
  display: none;
  text-align: center;
  width: 100%;

  background-color: #DCDFE2;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'row-resize')};

  transition-duration: 0.001s;

  font-size: 4px;
  line-height: 9px;
  font-weight: 700;
  letter-spacing: 1px;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const ChidrenWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    height: calc(100% - 14px);
  }
`;
