import styled from 'styled-components';

const bgColors = { primary: 'primary', secondary: 'neutra_700' }
const bgHoverColors = { primary: 'primary_800', secondary: 'neutra_800' }
const bgActiveColors = { primary: 'primary_900', secondary: 'neutra_900' }

export const Button = styled.button<{ $style: 'primary' | 'secondary' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: ${({ theme, $style }) => theme.colors[bgColors[$style]]};

  border: none;
  border-radius: 6px;

  color: ${({ theme }) => theme.colors.surface_white};
  cursor: pointer;

  transition-duration: 400ms;

  &:hover {
    background-color: ${({ theme, $style }) => theme.colors[bgHoverColors[$style]]};
  }
  
  &:active,
  &:visited {
    background-color: ${({ theme, $style }) => theme.colors[bgActiveColors[$style]]};
  }
  
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.neutra_500};
    cursor: not-allowed;
  }

  > span.material-icons-round {
    font-size: 24px;
  }
`; 
