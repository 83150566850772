import { useState } from 'react';
import {
  DesktopDataEditorInput,
  DesktopDataEditorText,
  DesktopDataEditorWrapper,
} from './desktop-data-editor.style';

interface Props {
  value: string;
  isObserver: boolean;
  onChange: (value: string) => void;
}

/**
 *
 * @returns
 */
const DesktopDataEditor = (props: Props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.value);

  return (
    <div onDoubleClick={() => setEditing(true)}>
      <DesktopDataEditorWrapper>
        <DesktopDataEditorText
          isObserver={props.isObserver}
          onClick={() => (props.isObserver ? undefined : setEditing(true))}
        >
          {props.value || '-'}
        </DesktopDataEditorText>

        {editing ? (
          <DesktopDataEditorInput
            autoFocus
            value={value}
            disabled={!editing}
            onBlur={() => {
              setEditing(false);
              if (props.value !== value) props.onChange(value);
            }}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        ) : null}
      </DesktopDataEditorWrapper>
    </div>
  );
};

export default DesktopDataEditor;
