import styled from 'styled-components';

export const ShareableLinkWrapper = styled.div``;

export const CopyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 8px;

  border: 1px solid #e5e5e5;
  border-radius: 3px 0px 0px 3px;
`;

export const CopyButton = styled.button<{ copied?: boolean }>`
  position: relative;
  /* top: -1px; */

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background-color: #f1f1f1;

  height: 38px;
  width: 48px;

  border-radius: 0px 3px 3px 0px;
  cursor: pointer;

  ${({ copied }) =>
    copied
      ? `
    border: 1px solid #31d988;
  `
      : null}
`;

export const Input = styled.input`
  border-radius: 3px 0px 0px 3px;

  width: 100%;
  height: 38px;
  padding: 5px 10px;

  background-color: transparent;

  &:active {
    padding: 5px 10px;
    border: none;
  }

  &:focus {
    padding: 5px 10px;
    border: none;
  }
`;

export const Tabs = styled.ul`
  display: flex;
  gap: 8px;

  margin-top: 8px;
  margin-bottom: 12px;
`;

export const Tab = styled.li<{ selected: boolean }>`
  cursor: pointer;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1em;

  color: #1a1e36;

  padding-bottom: 6px;

  user-select: none;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? `
    border-bottom: 2px solid #5850ec;
    color: #5850ec;
    font-weight: 500;
  `
      : null}
`;
