import { Route, Switch } from 'react-router-dom';
import Toast from 'app/components/toast/toast';
import Invitation from './invitation/invitation.page';
import ShareableInvitation from './shareable-invitation/shareable-invitation.page';

/**
 * 
 * @returns 
 */
const InvitationsModule = () => (
  <Switch>
    <Route exact path="/invitations/:token" component={Invitation} />
    <Route exact path="/invitations/:token/shareable" component={ShareableInvitation} />

    <Toast />
  </Switch>
);

export default InvitationsModule;
