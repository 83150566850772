import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Column } from '../../table.interfaces';
import { GtTableHeadColumn } from './table-head-column.style';

/**
 *
 * @param type
 * @returns
 */
const renderSortIcon = (type: string) =>
  type === 'asc' ? (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.86824 5.48057C4.48435 6.15239 3.51565 6.15239 3.13176 5.48057L0.854936 1.49614C0.47399 0.829482 0.955357 3.37812e-07 1.72318 4.04937e-07L6.27682 8.03029e-07C7.04464 8.70154e-07 7.52601 0.829483 7.14506 1.49614L4.86824 5.48057Z"
        fill="rgba(0, 0, 0, 0.5)"
      />
    </svg>
  ) : (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(180deg)' }}
    >
      <path
        d="M4.86824 5.48057C4.48435 6.15239 3.51565 6.15239 3.13176 5.48057L0.854936 1.49614C0.47399 0.829482 0.955357 3.37812e-07 1.72318 4.04937e-07L6.27682 8.03029e-07C7.04464 8.70154e-07 7.52601 0.829483 7.14506 1.49614L4.86824 5.48057Z"
        fill="rgba(0, 0, 0, 0.5)"
      />
    </svg>
  );

interface Props {
  column: Column;
  columnsSort: string[];
  dragOver: string;
  index: number;
  sortedBy: string;
  sortType: null | 'asc' | 'desc';
  noResize?: boolean;
  setDragOver: (dragOver: string) => void;
  setColumnsSort: (columnsSort: string[]) => void;
  setSortedby: (sortedBy: string) => void;
  setSortType: (sortType: null | 'asc' | 'desc') => void;
  onResize: (width: number) => void;
}

let DRAG_TIMEOUT: any = null;

const TableHeadColumn = ({
  column,
  columnsSort,
  dragOver,
  index,
  sortedBy,
  sortType,
  noResize,
  setDragOver,
  setColumnsSort,
  setSortedby,
  setSortType,
  onResize,
}: Props) => {
  const { width, ref } = useResizeDetector();

  const [currentWidth, setCurrentWidth] = useState(column.width);

  useEffect(() => {
    if (!width || width === currentWidth) return;
    if (width !== currentWidth && !noResize) {
      onResize(width);
      setCurrentWidth(width);
    }
  }, [width]);

  return (
    <GtTableHeadColumn
      ref={ref}
      isDragOver={dragOver === column.field}
      sortable={column.sortable}
      key={`${index}-${column.field}`}
      id={column.field}
      width={column.width}
      draggable
      onDragStart={(e: any) => {
        e.dataTransfer.setData('text/plain', column.field);
      }}
      onDragEnd={(e: any) => {
        e.preventDefault();
        setDragOver('');
      }}
      onDrop={(e: any) => {
        const sort = [...columnsSort];

        const dragIndex = sort.indexOf(e.dataTransfer.getData('text'));
        sort.splice(dragIndex, 1);

        const dropIndex = sort.indexOf(column.field);
        sort.splice(dropIndex, 0, e.dataTransfer.getData('text'));

        setDragOver('');
        setColumnsSort(sort);
      }}
      onDragOver={(e: any) => {
        e.preventDefault();
        if (dragOver !== column.field) {
          if (DRAG_TIMEOUT) clearTimeout(DRAG_TIMEOUT);
          DRAG_TIMEOUT = setTimeout(() => setDragOver(column.field), 1);
        }
      }}
      onClick={() => {
        if (!column.sortable) return;

        if (!sortType || sortType !== column.field) {
          setSortType('asc');
        }

        if (sortType === 'asc' && sortedBy === column.field) {
          setSortType('desc');
        }

        if (sortType === 'desc' && sortedBy === column.field) {
          setSortType(null);
        }

        setSortedby(column.field);
      }}
    >
      <span>
        {column.headerName}
        {sortType && sortedBy === column.field ? renderSortIcon(sortType) : null}
      </span>
    </GtTableHeadColumn>
  );
};

export default TableHeadColumn;
