import { useContext, useEffect, useState } from 'react';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { setArmed } from '../device-common.controller';
import { Button } from './arm-disarm-button.style';
import { Spinner } from 'app/components/spinner/spinner';

interface Props {
  armed: boolean;
  online: boolean;
  mqttId: string;
  style?: React.CSSProperties;
}

/**
 *
 * @param props
 * @returns
 */
export const ArmDisarmButton = (
  props: Props
) => {
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [waiting, setWaiting] = useState<boolean>(false);
  const [lastArmed, setLastArmed] = useState<boolean>(false);

  useEffect(() => {
    if (waiting && props.armed === lastArmed) {
      setWaiting(false);
    }
  }, [props.armed]);

  const style = Object.assign(props.style || {});

  if (props.armed) {
    style.backgroundColor = '#e40f0f';
    style.color = '#ffffff';
  }
  const isOnlineTitle = `click to ${props.armed ? 'Disarm' : 'Arm'}`;
  const isOfflineTitle = "Qube can't be armed when offline";

  return (
    <Button
      waiting={waiting}
      online={!!props.online}
      disabled={waiting || !props.online}
      title={props.online ? isOnlineTitle : isOfflineTitle}
      onClick={() => {
        if (waiting || !auth?.token) return;
        setLastArmed(!props.armed);
        setWaiting(true);
        setArmed(props.mqttId, !props.armed, auth.token);
      }}
      style={style}
    >
      {
        waiting ? (
          <Spinner />
        ) : (props.armed ? 'Disarm' : 'Arm')}
    </Button>
  );
};
