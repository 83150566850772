import { Wrapper, Dropdown } from './cell-selector.style';
import { useState } from 'react';

interface Props {
  id: string;
  value: string;
  handleChange: (data: any) => void;
  options?: { id: string | number; label: string }[];
}

/**
 * Input component
 * @returns
 */
const CellSelector = ({ id, value, handleChange, options }: Props) => {
  const [dropdown, setDropdown] = useState<boolean>(false);
  const dropdownId = `gt_dropdown_${id}`;

  return (
    <Wrapper
      tabIndex={0}
      onBlur={(e: any) => {
        if (e.relatedTarget?.id === dropdownId) return;
        setDropdown(false);
      }}
    >
      <label onClick={() => setDropdown(!dropdown)}>
        {options?.find(({ id }) => value === id)?.label || 'Select'}
        <figure>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            height="28px"
            width="28px"
          >
            <path fill="#5850ec" d="M24 30 14 20.05H34Z" />
          </svg>
        </figure>
      </label>

      <Dropdown hide={!dropdown}>
        <ul tabIndex={0} id={dropdownId}>
          {options?.map(({ id, label }) => (
            <li
              key={id}
              onClick={() => {
                handleChange({ id, label });
                setDropdown(false);
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </Dropdown>
    </Wrapper>
  );
};

CellSelector.defaultProps = {
  value: '',
  id: '',
  customStyle: '',
  disabled: false,
  placeholder: '',
  handleChange: () => '',
};

export default CellSelector;
