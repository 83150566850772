import styled from 'styled-components';

export const ToastWrapper = styled.div<any>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  position: fixed;
  bottom: 59px;
  left: 0;

  z-index: 50;

  width: 100%;
  text-align: center;
`;

export const ToastComponent = styled.div`
  display: inline-block;
  width: 337px;
  padding: 0 10px 10px;

  background: #3e4255;
  box-shadow: 0px 6px 8px rgba(51, 57, 78, 0.2);
  border-radius: 12px;

  animation-name: fadein;
  animation-duration: 300ms;

  > svg {
    position: relative;
    top: -19px;
    width: 38px;
    height: 38px;
  }

  > div {
    position: relative;
    top: -12px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(100px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ToastTitle = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #f1f1f1;

  margin-bottom: 6px;
`;

export const ToastText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;

  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 10px;
`;
