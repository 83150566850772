import { useContext, useMemo } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
// context
import AccountStore from 'app/modules/account/account.context';
// interfaces
import { AuthType, PermissionType } from 'app/modules/account/account.context.d';
import { links } from './navigation.model';
import ProfileButton from './components/profile-button/profile-button';
import {
  ContactUs,
  InfoWrapper,
  GtLogo,
  GtNameLogoSvg,
  LogoContainer,
  NavigationWrapper,
  NavigationHeader,
  NavigationList,
  NavigationItem,
  NavigationLink,
  NavigationItemIcon,
  NavigationItemText,
  NavigationFooter,
  ProfileButtonWrapper,
  ProfileName,
  ProfileEmail,
} from './navigation.style';

interface Item {
  id: string;
  iconType: string;
  text: string;
  href: string;
  external?: boolean;
  mobile?: boolean;
  roles?: PermissionType[];
}

const icons: any = {
  projects: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M9 22.5q-1.25 0-2.125-.875T6 19.5V9q0-1.25.875-2.125T9 6h10.5q1.25 0 2.125.875T22.5 9v10.5q0 1.25-.875 2.125T19.5 22.5ZM9 42q-1.25 0-2.125-.875T6 39V28.5q0-1.25.875-2.125T9 25.5h10.5q1.25 0 2.125.875T22.5 28.5V39q0 1.25-.875 2.125T19.5 42Zm19.5-19.5q-1.25 0-2.125-.875T25.5 19.5V9q0-1.25.875-2.125T28.5 6H39q1.25 0 2.125.875T42 9v10.5q0 1.25-.875 2.125T39 22.5Zm0 19.5q-1.25 0-2.125-.875T25.5 39V28.5q0-1.25.875-2.125T28.5 25.5H39q1.25 0 2.125.875T42 28.5V39q0 1.25-.875 2.125T39 42ZM9 19.5h10.5V9H9Zm19.5 0H39V9H28.5Zm0 19.5H39V28.5H28.5ZM9 39h10.5V28.5H9Zm19.5-19.5Zm0 9Zm-9 0Zm0-9Z" />
    </svg>
  ),
  people: (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" fill="none" />
      <g>
        <rect
          width="1600"
          height="900"
          transform="translate(-13 -152)"
          fill="none"
        />
        <rect x="-13" y="-152" width="240" height="1659" fill="none" />
        <circle
          opacity="0.85"
          cx="9"
          cy="9"
          r="8"
          className="blank-hover"
          stroke="#6B7280"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <g opacity="0.85" className="blank-hover">
          <mask
            id="mask0_482_694"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="16"
            height="16"
          >
            <circle
              className="blank-hover"
              cx="9"
              cy="9"
              r="8"
              fill="#6B7280"
            />
          </mask>
          <g mask="url(#mask0_482_694)">
            <circle
              cx="9"
              cy="17"
              r="6"
              stroke="#6B7280"
              className="blank-hover"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </g>
        </g>
        <circle
          className="blank-hover blank-fill"
          opacity="0.85"
          cx="9"
          cy="7"
          r="2"
          fill="#6B7280"
        />
      </g>
    </svg>
  ),
  documentation: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      fill="#6B7280"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g />
        <g>
          <path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
          <g>
            <path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
            <path d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
            <path d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
          </g>
        </g>
      </g>
    </svg>
  ),
  surveys: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      fill="#6B7280"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M19.3,16.9c0.4-0.7,0.7-1.5,0.7-2.4c0-2.5-2-4.5-4.5-4.5S11,12,11,14.5s2,4.5,4.5,4.5c0.9,0,1.7-0.3,2.4-0.7l3.2,3.2 l1.4-1.4L19.3,16.9z M15.5,17c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S16.9,17,15.5,17z M12,20v2 C6.48,22,2,17.52,2,12C2,6.48,6.48,2,12,2c4.84,0,8.87,3.44,9.8,8h-2.07c-0.64-2.46-2.4-4.47-4.73-5.41V5c0,1.1-0.9,2-2,2h-2v2 c0,0.55-0.45,1-1,1H8v2h2v3H9l-4.79-4.79C4.08,10.79,4,11.38,4,12C4,16.41,7.59,20,12,20z" />
    </svg>
  ),
  calendar: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      stroke="#6B7280"
    >
      <path d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z" />
    </svg>
  ),
  support: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#6B7280" viewBox="0 0 48 48">
      <path d="M24.2 35.65q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.75-7.3h2.95q0-1.3.325-2.375T27.75 23.5q1.55-1.3 2.2-2.55.65-1.25.65-2.75 0-2.65-1.725-4.25t-4.575-1.6q-2.45 0-4.325 1.225T17.25 16.95l2.65 1q.55-1.4 1.65-2.175 1.1-.775 2.6-.775 1.7 0 2.75.925t1.05 2.375q0 1.1-.65 2.075-.65.975-1.9 2.025-1.5 1.3-2.225 2.575-.725 1.275-.725 3.375ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z" />
    </svg>
  ),
  devices: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#6B7280" viewBox="0 0 48 48">
      <path d="M6 42v-9h7.7l8.8-8.8v-7.45q-1.75-.6-2.875-2.025Q18.5 13.3 18.5 11.5q0-2.3 1.6-3.9T24 6q2.3 0 3.9 1.6t1.6 3.9q0 1.8-1.125 3.225T25.5 16.75v7.45l8.8 8.8H42v9h-9v-5.8l-9-9-9 9V42Z" />
    </svg>
  ),
};

/**
 * 
 * @param roles 
 * @param permissions 
 * @returns 
 */
const roleCondition = (roles: PermissionType[], permissions: PermissionType[]) => {
  return roles ? roles.some((role) => permissions.includes(role)) : true;
}

/**
 * 
 * @param items 
 * @param isSurveyManager 
 * @param IsCustomerManager 
 * @param customerId 
 * @returns 
 */
const renderNavigationListItems = (items: Item[]) => {
  return items.map((item) => (
    <NavigationItem key={item.id} mobile={item.mobile}>
      {item.external ? (
        <NavigationLink id={item.id} href={item.href} target="__blank">
          <NavigationItemIcon>{icons[item.iconType]}</NavigationItemIcon>
          <NavigationItemText>{item.text}</NavigationItemText>
        </NavigationLink>
      ) : (
        <Link id={item.id} to={item.href}>
          <NavigationLink
            href={item.href}
          >
            <NavigationItemIcon>{icons[item.iconType]}</NavigationItemIcon>
            <NavigationItemText>{item.text}</NavigationItemText>
          </NavigationLink>
        </Link>
      )}
    </NavigationItem>
  ));
};

const Navigation = () => {
  // router
  const history = useHistory<RouteComponentProps['history']>();
  // contexts
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  // memorize links
  const nlinks = useMemo(() => {
    const ref_id = {
      ':customer_id': auth.user?.managed_customer?.id,
    };

    return links
      .filter((item) => item.roles ? roleCondition(item.roles, accountContext.state.permissions) : true)
      .map((item) => {
        const refs = item.href.match(/:\w+/g) || [];
        let href = item.href;
        for (const ref of refs) {
          href = href.replace(ref, ref_id[ref])
        }
        return { ...item, href };
      });
  }, [accountContext.state.permissions, auth.user?.managed_customer]);

  return (
    <NavigationWrapper data-testid="navigation-menu">
      <NavigationHeader>
        <LogoContainer>
          <GtLogo src="/assets/icons/gt-logo.svg" />
          <GtNameLogoSvg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 91.34 7.92"
          >
            <path
              d="M90.91,163.13V162h3.7a2.28,2.28,0,0,1,0,.45c-.06,2-2,3.23-4.43,3.23-2.9,0-4.78-1.45-4.78-3.49s1.93-3.44,4.73-3.44a8.67,8.67,0,0,1,3.48.63v1.24a7,7,0,0,0-3.3-.71c-1.94,0-3.46.76-3.46,2.28s1.4,2.33,3.32,2.33c1.43,0,2.63-.44,2.91-1.37Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M96.07,165.54v-6h1.31v4.89h4.39v1.13Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M106.54,165.66c-2.34,0-4.22-1.19-4.22-3.12s1.88-3.12,4.22-3.12,4.22,1.17,4.22,3.12S108.89,165.66,106.54,165.66Zm0-5.09c-1.63,0-2.82.73-2.82,2s1.19,2,2.82,2,2.82-.76,2.82-2S108.17,160.57,106.54,160.57Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              fill="#231f20"
              d="M112.34,165.54v-6h3.9c1.09,0,2.43.07,2.43,1.65a1.18,1.18,0,0,1-.79,1.06,1.47,1.47,0,0,1,1.32,1.49c0,1.49-1.33,1.82-2.49,1.82Zm1.31-5V162h2.67c.55,0,1-.18,1-.7s-.43-.7-1-.7Zm0,2.36v1.58h3c.63,0,1.11-.16,1.11-.78s-.48-.8-1.11-.8Z"
              transform="translate(-85.44 -157.74)"
            />
            <path
              fill="#231f20"
              d="M125.78,164.52h-4.09l-.66,1h-1.47l4.18-6.12,4.17,6.12h-1.46Zm-3.38-1.07h2.68l-1.34-2Z"
              transform="translate(-85.44 -157.74)"
            />
            <path
              d="M128.92,165.54v-6h1.31v4.89h4.39v1.13Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M136.62,165.54V160h-3v-1.15H141V160h-3v5.54Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M147.25,165.54,146,163.66H143.4v1.88h-1.31v-6h4.11a2.11,2.11,0,0,1,2.38,2.06,2,2,0,0,1-1.26,1.88l1.39,2.08Zm-3.85-4.86v1.82H146c1,0,1.16-.49,1.16-.92s-.16-.9-1.16-.9Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M155.43,164.52h-4.09l-.66,1h-1.46l4.17-6.12,4.17,6.12H156.1Zm-3.38-1.07h2.68l-1.34-2Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M164.88,165.12a6.61,6.61,0,0,1-2.77.54c-2.67,0-4.34-1.12-4.34-3.12s1.67-3.12,4.34-3.12a6.43,6.43,0,0,1,2.77.55v1.24a5.45,5.45,0,0,0-2.74-.65c-1.86,0-3,.74-3,2s1.09,2,3,2a6.07,6.07,0,0,0,2.71-.56Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M170.43,162l3,3.56h-1.71l-2.29-2.71-1.58,1.34v1.37h-1.31v-6h1.31v3.14l3.77-3.14h1.71Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M174.6,158.08a1.43,1.43,0,0,0-.44-.07c-.19,0-.38,0-.38.18,0,.31.92-.07.92.62,0,.41-.32.47-.62.47a2.38,2.38,0,0,1-.55-.06V159a2.07,2.07,0,0,0,.5.06c.23,0,.43,0,.43-.19,0-.34-.93.05-.93-.62,0-.4.34-.46.58-.46a1.9,1.9,0,0,1,.49.07Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
            <path
              d="M176.53,159.25l-.24-.87-.48.9-.48-.9-.25.87h-.24l.43-1.51.54,1.06.54-1.06.43,1.51Z"
              transform="translate(-85.44 -157.74)"
              fill="#231f20"
            />
          </GtNameLogoSvg>
        </LogoContainer>
      </NavigationHeader>
      <NavigationList>
        {renderNavigationListItems(nlinks)}
      </NavigationList>
      <NavigationFooter>
        <ContactUs>
          <strong>Contact Us:</strong> globaltrack@pigging.com
        </ContactUs>
        <ProfileButtonWrapper>
          <ProfileButton
            profile={{
              name: `${auth?.user?.first_name} ${auth?.user?.last_name}`,
              email: auth.user?.email,
              avatar: auth.user?.avatar,
              role: auth.user?.is_superuser ? 'administrator' : 'user',
            }}
            openProfileModal={() => {
              accountContext.dispatch({
                type: 'OPEN_PROFILE_MODAL',
              });
            }}
            logout={() => {
              window.localStorage.removeItem('CDI@auth-token');
              window.localStorage.removeItem('CDI@auth-email');

              accountContext.dispatch({
                type: 'LOGOUT',
              });

              history.push('/');
              window.location.reload();
            }}
          />
          <InfoWrapper>
            <ProfileName>{`${auth.user?.first_name} ${auth.user?.last_name}`}</ProfileName>
            <ProfileEmail>{auth.user.email}</ProfileEmail>
          </InfoWrapper>
        </ProfileButtonWrapper>
      </NavigationFooter>
    </NavigationWrapper>
  );
};

export default Navigation;
