import styled from 'styled-components';

export const ModalWrapper = styled.div<{ hide?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  overflow-y: auto;

  z-index: 21;
`;

export const Modal = styled.div`
  position: relative;

  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  border-radius: 6px;

  background-color: #ffffff;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 18px;
  justify-content: space-between;

  border-bottom: 1px solid #e4e6eb;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  height: 20px;
`;

export const Container = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;

  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const Form = styled.div`
  width: 450px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18;
  line-height: 22px;

  color: #161e2e;
`;

export const IlustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  background-color: #eff0f3;

  @media (max-width: 1024px) {
    svg {
      max-width: 400px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Dataset = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  justify-content: space-between;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 60px;
  height: auto;
  overflow-y: hidden;
  resize: none;

  background-color: transparent;

  padding: 5px 10px;
  border-radius: 3px;
  font-family: Inter;
  border: 1px solid #e5e5e5;

  &:active {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }
  &:focus {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }
`;
