import { useState, useEffect } from 'react';
import InputGroup from 'app/components/input-group/inputGroup';
import Input from 'app/components/input/input';
import {
  Title,
  Form,
  FormWrapper,
  Container,
  Row,
  FilesWrapper,
  FileItem,
  FileLabel,
  IlustrationWrapper,
} from './first-form.style';
import ButtonComponent from 'app/components/button/button';
import { NewRunIlustration } from '../../svgs/newRun.icons';

interface FileChanged {
  fileType: string;
  fileName: string;
  fileExt: string;
  file: File;
}

const FirstStep = ({
  onSubmit,
  errors,
  isLoading,
  setErrors,
  survey,
}: any) => {
  const [name, setName] = useState<string>(survey?.name);
  const [surveyBy, setSurveyBy] = useState<string>(survey?.survey_by);
  const [gpsAccuracy, setGpsAccuracy] = useState<string>(
    survey?.gps_survey_accuracy
  );
  const [gpsDatum, setGpsDatum] = useState<string>(survey?.gps_datum);
  const [gpsEquipment, setGpsEquipment] = useState<string>(
    survey?.gps_equipment
  );
  const [geoidModel, setGeoidModel] = useState<string>(survey?.geoid_model);
  const [kmls, setKmls] = useState<FileChanged[]>(survey?.surveyfile_set);
  const [owner, setOwner] = useState<string>(survey?.owner);

  const [surveyorPhone, setSurveyorPhone] = useState<string>('');
  const [surveyorCompany, setSurveyorCompany] = useState<string>('');

  const cleanFields = () => {
    setName('');
    setSurveyBy('');
    setGpsAccuracy('');
    setGpsDatum('');
    setGpsEquipment('');
    setGeoidModel('');
    setOwner('');
    setKmls([]);
    setErrors({});
    setSurveyorCompany('');
    setSurveyorPhone('');
  };

  useEffect(() => {
    setName(survey?.name || '');
    setSurveyBy(survey?.survey_by || '');
    setGpsAccuracy(survey?.gps_survey_accuracy || '');
    setGpsDatum(survey?.gps_datum || '');
    setGpsEquipment(survey?.gps_equipment || '');
    setGeoidModel(survey?.geoid_model || '');
    setOwner(survey?.owner || '');
    setKmls(survey?.surveyfile_set || []);
    setSurveyorCompany(survey?.survey_company || '');
    setSurveyorPhone(survey?.surveyor_phone || '');
    setErrors({});
  }, [survey]);

  return (
    <Container>
      <FormWrapper>
        <Form>
          <Title>Edit Survey</Title>
          <Row>
            <InputGroup error={errors?.name} label="Survey Name">
              <Input
                id="update-survey-name"
                value={name}
                handleChange={({ target }) => setName(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup error={errors?.gpsAccuracy} label="GPS survey accuracy">
              <Input
                value={gpsAccuracy}
                handleChange={({ target }) => setGpsAccuracy(target.value)}
              />
            </InputGroup>
            <InputGroup error={errors?.gpsDatum} label="GPS datum">
              <Input
                value={gpsDatum}
                handleChange={({ target }) => setGpsDatum(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup error={errors?.geoidModel} label="Geoid model">
              <Input
                value={geoidModel}
                handleChange={({ target }) => setGeoidModel(target.value)}
              />
            </InputGroup>
            <InputGroup error={errors?.gpsEquipment} label="GPS equipment">
              <Input
                value={gpsEquipment}
                handleChange={({ target }) => setGpsEquipment(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.surveyorCompany}
              label="Surveyor Company"
            >
              <Input
                value={surveyorCompany}
                handleChange={({ target }) => setSurveyorCompany(target.value)}
              />
            </InputGroup>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.surveyorPhone}
              label="Surveyor Phone"
            >
              <Input
                value={surveyorPhone}
                type="tel"
                handleChange={({ target }) => setSurveyorPhone(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              customStyle="padding: 6px 0;"
              error={errors?.owner}
              label="Owner/Operator"
            >
              <Input
                value={owner}
                handleChange={({ target }) => setOwner(target.value)}
              />
            </InputGroup>
          </Row>
          <FilesWrapper>
            {kmls?.map((kml: any, i: number) => (
              <FileItem key={i}>
                <FileLabel>{`${kml.name}`}</FileLabel>
              </FileItem>
            ))}
          </FilesWrapper>
          <Row>
            <ButtonComponent
              id="update-survey-submit"
              customStyle={`
                margin-top: 32px;
                :focus {
                  padding: 5px 10px;
                  border: 1px solid #e5e5e5;
                  border-radius: 6px;
                  width: 100%;
                  background-color: #5850ec;
                  color: white;
                  font-weight: 500;
                  font-size: 0.93em;
                  line-height: 1.12em;
                  cursor: pointer;
                  margin-top: 32px;
                }
              `}
              handleClick={() =>
                onSubmit(
                  {
                    id: survey.id,
                    name,
                    surveyBy,
                    gpsAccuracy,
                    gpsDatum,
                    gpsEquipment,
                    geoidModel,
                    owner,
                    surveyorCompany,
                    surveyorPhone,
                  },
                  cleanFields
                )
              }
              value={isLoading ? 'loading...' : 'Save Survey'}
              disabled={isLoading}
            />
          </Row>
        </Form>
        <IlustrationWrapper>
          <NewRunIlustration />
        </IlustrationWrapper>
      </FormWrapper>
    </Container>
  );
};

FirstStep.defaultProps = {};

export default FirstStep;
