import styled from 'styled-components';

export const Wrapper = styled.div<{ error?: string }>`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

  ${({ error }) => (error ? 'background-color: rgba(220, 20, 60, 0.2);' : null)}
  .MuiTextField-root {
    width: 100%;
    height: 100%;
  }
  .MuiTextField-root {
    width: 100%;
    height: 100%;
    display: block;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiFormControl-marginNormal {
    margin: 0;
    padding: 0;
  }
  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
  }
  .MuiInputBase-input {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 18px;
    color: #676b7a;
  }
  .MuiFormControl-root {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;
