import { deleteTrackingPointMedia, toggleTrackingPointMediaSiteSketch, updateTrackingPointMediaCaption } from "../tracking-point-overlay/tracking-point-overlay.service";

/**
 * 
 * @param mediaId 
 * @param token 
 * @param setConfirmationModal 
 */
export const deleteMedia = async (
  mediaId: string,
  token: string,
  setConfirmationModal: (data: any) => void
) => {
  await deleteTrackingPointMedia(mediaId, token);
  setConfirmationModal({});
};

/**
 * 
 * @param caption 
 * @param mediaId 
 * @param token 
 */
export const updateMediaCaption = async (
  caption: string,
  mediaId: string,
  token: string
) => {
  await updateTrackingPointMediaCaption(caption, mediaId, token);
};

/**
 * 
 * @param isSiteSketch 
 * @param mediaId 
 * @param token 
 */
export const updateSiteSketch = async (
  isSiteSketch: boolean,
  mediaId: string,
  token: string
) => {
  await toggleTrackingPointMediaSiteSketch(isSiteSketch, mediaId, token);
};
