import { ModalWrapper, Figure, CloseButton } from './media-modal.style';

const MediaModal = ({
  media,
  open,
  close,
}: {
  media: string;
  open: boolean;
  close: () => void;
}) => (
  <ModalWrapper open={open} onClick={close}>
    <CloseButton onClick={close}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="14.2427"
          y="4.34375"
          width="2"
          height="14"
          rx="1"
          transform="rotate(45 14.2427 4.34375)"
          fill="white"
        />
        <rect
          x="15.6567"
          y="14.2422"
          width="2"
          height="14"
          rx="1"
          transform="rotate(135 15.6567 14.2422)"
          fill="white"
        />
      </svg>
    </CloseButton>
    <Figure src={media} alt="" onClick={(e) => e.stopPropagation()} />
  </ModalWrapper>
);

export default MediaModal;
