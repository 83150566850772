import styled from 'styled-components';

export const CardContainer = styled.div`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.neutra_200};
  box-shadow: 0px 6px 10px 0px rgba(51, 57, 78, 0.20);
  border-radius: 6px;

  overflow: hidden;
`;

export const CardTopContainer = styled.div<{ $highlighted: boolean }>`
  background-color: ${({ theme, $highlighted }) => $highlighted ? theme.colors.primary : theme.colors.surface};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  padding: 8px 16px;

  > div > p:last-child {
    width: 100%;
    text-aling: left;
    color: ${({ theme, $highlighted }) => $highlighted ? theme.colors.surface_white : theme.colors.outline_on_surface};
  }

  > div > p:first-child {
    color: ${({ theme, $highlighted }) => $highlighted ? theme.colors.surface_white : theme.colors.neutra_600};
  }
`;

export const CardBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 16px;

  div:first-child {
    display: grid;
    text-transform: uppercase;

    > p {
      font-size: 9px;

      > strong {
        color: ${({ theme }) => theme.colors.neutra_600};
      }
    }
  }
`;

export const CardAutoAccept = styled.span`
  padding: 2px 3px;
  border: solid 1.5px ${({ theme }) => theme.colors.secondary_base_green};
  color: ${({ theme }) => theme.colors.secondary_base_green};\
  border-radius: 4px;
  
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const CardActions = styled.div`
  display: flex;
  gap: 8px;

  > button {
    width: 70px;
    height: 28px;
  }
`;

export const CardDateTime = styled.div`
  display: grid;

  > p:first-child {
    font-size: 9px;
  }
`;

export const CaptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
`;