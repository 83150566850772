import { ReactNode, useContext } from 'react';
import NotificationStore from 'app/modules/notification/notification.context';
import Navigation from '../navigation/navigation';
import { NavigationContainerDiv } from './navigation-container.style';
import Toast from '../toast/toast';

interface Props {
  children: ReactNode
}

/**
 * 
 * @param props 
 * @returns 
 */
const NavigationContainer = (props: Props) => {
  const notificationContext = useContext(NotificationStore);

  return (
    <NavigationContainerDiv>
      <Navigation />
      {props.children}
      {notificationContext.state.toast ? <Toast /> : null}
    </NavigationContainerDiv>
  ) 
};

export default NavigationContainer;