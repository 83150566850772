import { markTriggerAsSeen } from "app/services/run.service";

/**
 * 
 * @param token 
 * @param triggersSeen 
 */
export const markTriggersAsSeen = (token: string, triggers: number[]) => {  
  for (const id of triggers) {
    markTriggerAsSeen(id, token);
  }
};
