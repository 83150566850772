import { createProjectInvitation } from '../../services/invitation.service';
import { getUserPredict } from '../../services/user.service';
import { RoleType } from './invitation-with-project-modal.d';

export const getPredictUsers = async (
  email: string,
  token: string,
  setPredictedUsers: (users: any[]) => void
) => {
  let predictResponse: any;

  try {
    predictResponse = await getUserPredict(email, token);
  } catch (err) {
    console.log([err]);
  }

  setPredictedUsers(predictResponse.data.users);
};

export const sendInvitations = async (
  emails: string[],
  selectedRole: RoleType,
  projects: number[],
  token: string
) => {
  const invitation = {
    emails,
    editor: selectedRole.editor,
    admin: selectedRole.admin,
    projects,
  };

  await createProjectInvitation(invitation, token);
};
