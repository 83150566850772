import Avatar from 'app/components/avatar/avatar';
import { Wrapper } from './userAvatar.style';

/**
 *
 * @returns
 */
const UserAvatar = ({ user, imgSize, onClick, title }: any) => {
  return (
    <Wrapper
      title={title || ''}
      onClick={() => (onClick ? onClick() : null)}
      imgSize={imgSize}
    >
      <Avatar
        src={user.avatar}
        firstName={user.first_name}
        lastName={user.last_name}
        email={user.email}
        size={28}
      />
    </Wrapper>
  );
};

export default UserAvatar;
