import styled from 'styled-components';

export const TrackingPointOverlayPresetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TrackingPointOverlayHeader = styled.header`
  display: grid;
  grid-template-columns: 36px auto auto;
  align-content: center;
  gap: 8px;

  padding: 8px 16px;
  margin-top: 8px;
`;

export const TrackingPointOverlayHeaderPointName = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;
  
  color: rgb(75, 80, 102);
`

export const TrackingPointOverlayHeaderPointNameContainer = styled.div`
  position: relative;  
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    width: 40px;
    height: 4px;
    margin-top: 8px;
    background: #5850ec;
    border-radius: 6px; 
  }
`;

export const TrackingPointOverlayHeaderPointType = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.2em;

  color: #767986;

  text-transform: uppercase;
`;

export const TrackingPointOverlayPassageInformation = styled.dl`
  display: flex;
  flex-direction: column;
  margin: 0;

  gap: 6px;
`;

export const TrackingPointOverlayPassageInformationPassage = styled(TrackingPointOverlayPassageInformation)`
  align-items: flex-start;
`;

export const TrackingPointOverlayPassageDate = styled.dt<{ $invalid: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.2em;

  > svg {
    margin-right: 6px;
  }
`;

export const TrackingPointOverlayPassageTime = styled(TrackingPointOverlayPassageDate)``;


export const TrackingPointOverlayPassageDataLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.2em;
  margin: 0;

  color: #4B5066;
`;

export const TrackingPointOverlayPassageDataValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.2em;
  margin: 0;


  color: #4B5066;
`;

export const TrackingPointOverlayPassageDataGroup = styled.div`
  display: flex;
  gap: 4px;
`;

export const TrackingPointOverlayMetaDataTableWrapper = styled.div`
  width: 100%;
  margin-top: 8px;

  padding-left: 16px;
  padding-right: 16px;

  transition-duration: 0.4s;
`;

export const TrackingPointOverlayMetaDataTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border: 0.5px solid #dcdfe2;

  > tr {
    height: 18.5px;
  }

  > tr,
  > tr > td {
    border: 0.5px solid #dcdfe2;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;

    color: #767986;

    padding-left: 6px;
    padding-right: 6px;
  }
`;


export const TrackingPointOverlaySeeMoreButton = styled.button<{ active: boolean }>`
  height: 24px;
  padding: 7px 12px;
  background-color: #dcdfe2;
  border-radius: 100px;
  scroll-padding-bottom: 100px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;

  color: #767986;

  cursor: pointer;

  > svg {
    position: relative;
    margin-right: 6px;
    transition-duration: 200ms;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  > span {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: 13px;
    left: 0;
    display: inline-block;
    background-color: #dcdfe2;
    height: 1px;
    width: 100%;
  }
`;

export const TrackingPointOverlaySeeMoreButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
`;


export const TrackingPointOverlayMedia = styled.div`
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid rgb(220,  223, 226);
`;

export const TrackingPointOverlayMediaHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 2px;

  font-family: 'Inter';
  font-style: normal;
  font-size: 13px;
  line-height: 13px;

  color: #5850ec;

  > strong {
    font-weight: 600;
  }

  > svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
`;

export const TrackingPointOverlayMediaContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  margin-top: 12px;
  margin-bottom: 12px;
`;

export const TrackingPointOverlayMediaContentDropzone = styled.div<{ dropping: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  gap: 4px;

  height: 80px;
  background-color: rgba(220, 223, 226, 0.5);
  filter: drop-shadow(0px 2px 4px rgba(51, 57, 78, 0.1));
  border-radius: 4px;

  padding: 4px;

  border: dashed 1px;
  border-color: ${({ dropping }) => (dropping ? '#5850ec' : '#dcdfe2')};
  transition-duration: 400ms;

  cursor: pointer;

  > p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.2em;

    color: #767986;
  }

  > svg {
    opacity: 0.7;
  }
`;

export const TrackingPointOverlayMediaFooter = styled.footer<{ $hidden: boolean }>`
  display: flex;
  justify-content: flex-end;

  > input {
    display: none;
  }
  ${({ $hidden }) => $hidden ? 'display: none;' : ''}
`;

export const TrackingPointOverlayMediaFooterMediaGalleryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  height: 28px;
  background-color: transparent;
  border: 0.5px solid #5850ec;
  border-radius: 26px;
  padding-left: 12px;
  padding-right: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #5850ec;
  cursor: pointer;

  > svg {
    width: 17px;
    height: 18px;
  }
`;

export const TrackingPointOverlayNotes = styled.div`
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;

  margin-bottom: 16px;
`;

export const TrackingPointOverlayNotesHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 2px;

  font-family: 'Inter';
  font-style: normal;
  font-size: 13px;
  line-height: 13px;

  color: #5850ec;

  > strong {
    font-weight: 600;
  }

  > svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
`;

export const TrackingPointOverlayNotesContent = styled.div`
  display: grid;
  gap: 12px;

  margin-top: 12px;
  margin-bottom: 12px;

  > p {
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
    line-height: 13px;

    color: #767986;
    text-align: center;
    padding: 8px;
  }
`;

export const TrackingPointOverlayNotesFooter = styled.footer<{ $hidden: boolean }>`
  display: flex;
  justify-content: flex-end;

  > input {
    display: none;
  }
  ${({ $hidden }) => $hidden ? 'display: none;' : ''}
`;

export const TrackingPointOverlayNotesFooterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  height: 28px;
  background-color: transparent;
  border: 0.5px solid #5850ec;
  border-radius: 26px;
  padding-left: 12px;
  padding-right: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #5850ec;

  cursor: pointer;

  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const TrackingPointOverlayNoteList = styled.ul`
  display: grid;
  gap: 10px;

  > p {
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
    line-height: 13px;

    color: #767986;
    text-align: center;
    padding: 8px;
  }
`;
