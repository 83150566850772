import { useContext, useState, useMemo, useEffect } from 'react';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import Skeleton from 'app/components/skeleton/skeleton';
import ScheduledOnButton from './components/scheduled-on-button/scheduled-on-button';
import { weatherImperialOptions, weatherMetricOptions } from 'app/components/common/map/map.model';
import { WeatherOption } from 'app/components/common/map/map.d';
import ConnectionWarning from './components/connection-warning/connection-warning';
import FilledButton from 'stories/base/buttons/filled-button/filled-button';
import HelpButtonLegend from 'app/components/help-button-icons-legend/helpButtonIconsLegend';
import DropdownButton from 'app/components/dropdown-button/dropdown-button';
import {
  distanceUnitOptions,
  speedUnitOptions,
  weatherUnitOptions,
  widgetOptions,
} from './over-run-header.models';
import { Header, RunName } from './overlay-run-header.style';
import { handleMeasurementUnits } from './overlay-run-header.controller';
import { exportcsv } from 'app/modules/inspection/run/exports/run-csv-exporter';
import { exportxlxs } from 'app/modules/inspection/run/exports/run-xlxs-exporter';
import { RunCascadeType } from 'app/modules/inspection/inspection.interfaces';
import QubeList from './components/qube-list/qube-list';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';

/**
 *
 * @param param
 * @returns
 */
const OverlayHeader = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const [openTimezoneMenu, setOpenTimezoneMenu] = useState(false);
  const [openWidgetsMenu, setOpenWidgetsMenu] = useState(false);
  const [openOverlaysMenu, setOpenOverlaysMenu] = useState(false);
  const [openUnitsMenu, setOpenUnitsMenu] = useState(false);
  const [openColumnsMenu, setOpenColumnsMenu] = useState(false);
  const [openExportMenu, setOpenExportMenu] = useState(false);
  const [openHelpMenu, setOpenHelpMenu] = useState(false);
  const selectedTimezone = useMemo(() => {
    return settingsContext.state.timezoneList?.find((tzone: any) => 
      settingsContext.state.timezone?.id === tzone.id);
  }, [settingsContext.state.timezone?.id, settingsContext.state.timezoneList]);
  const [selectedGridColumns, setSelectedGridColumns] = useState({});
  const allGridColumns = useMemo(() => {
    return inspectionContext.state.grid?.getColumns() || [];
  }, [inspectionContext.state.grid]);
  const gridColumns = useMemo(() => {
    return allGridColumns?.map((c) => {
      return {
        id: c.field,
        label: c.name,
        checked: selectedGridColumns[c.field],
      };
    }) || [];
  }, [allGridColumns, selectedGridColumns]);

  useEffect(() => {    
    const dic = allGridColumns.reduce((acc, c) => {
      acc[c.field] = true;
      return acc;
    }, {});

    setSelectedGridColumns(dic);
  }, [allGridColumns]);

  /**
   * 
   */
  const resetMenus = () => {
    setOpenTimezoneMenu(false);
    setOpenWidgetsMenu(false);
    setOpenOverlaysMenu(false);
    setOpenUnitsMenu(false);
    setOpenColumnsMenu(false);
    setOpenExportMenu(false);
    setOpenHelpMenu(false);
  };

  const weatherOptions: WeatherOption[] =
  settingsContext.state.weatherUnit?.id === 'farenheit'
      ? weatherImperialOptions
      : weatherMetricOptions;
  
  return (
    <Header>
      <div>
        <Skeleton loading={!inspectionContext.run?.id} width="120px" height="16px" inline>
          <RunName>{inspectionContext.run?.name}</RunName>
        </Skeleton>
        
        <ScheduledOnButton />
        <ConnectionWarning />
      </div>

      <div>
        <QubeList
          qubes={inspectionContext.run
            ?.project?.device_set?.filter(({ qubedevice }) => !!qubedevice)
            .map(({ qubedevice }) => qubedevice as QubeDeviceType) || []
          }
        />

        <DropdownButton
          circular
          icon="menu_book"
          type="radio"
          show={false}
          groups={[]}
          onShow={() => window.open('/docs/', '__blank')}
        />

        <HelpButtonLegend
          title="Legend"
          hidden={false}
          show={openHelpMenu}
          onShow={() => {
            resetMenus();
            setOpenHelpMenu(!openHelpMenu);
          }}
          onClose={() => {
            resetMenus();
          }}
        />

        <DropdownButton
          circular
          title="Download Tracking Sheet"
          icon="download"
          type="label"
          show={openExportMenu}
          handleShow={(showing: boolean) => {
            resetMenus();
            setOpenExportMenu(showing);
          }}
          groups={[[
            {id: 'csv', text: '.csv', index: 0 },
            {id: 'xlsx', text: '.xlsx', index: 1 },
          ]]}
          onClick={(option) => {
            const exportRun = inspectionContext.run as RunCascadeType;             
            const data = {
              csv: () => exportcsv(inspectionContext.state, settingsContext.state, exportRun),
              xlsx: () => exportxlxs(inspectionContext.state, settingsContext.state, exportRun)
            };
            if (data[option.id]) data[option.id]()
          }}
        />

        <DropdownButton
          text="columns"
          type="checkbox"
          show={openColumnsMenu}
          hideButton={inspectionContext.state.view_mode === 'observer'}
          handleShow={(show: boolean) => {
            resetMenus();
            setOpenColumnsMenu(show);
          }}
          groups={[gridColumns]}
          onClick={(option) => {
            const dic = {
              ...selectedGridColumns,
              [option.id]: !selectedGridColumns[option.id],
            };

            setSelectedGridColumns(dic);
            const filteredColumns = allGridColumns.filter((c) => dic[c.field]);
            inspectionContext.state.grid?.setColumns(filteredColumns);
          }}
        />

        <DropdownButton
          text={`${settingsContext.state.speedUnit?.label} / ${settingsContext.state.distanceUnit?.label
            } / ${settingsContext.state.weatherUnit?.label?.toLowerCase()}`}
          type="radio"
          show={openUnitsMenu}
          onShow={(show: boolean) => {
            resetMenus();
            setOpenUnitsMenu(show);
          }}
          groupTitles={['Speed', 'Distance', 'Temperature']}
          groups={[
            speedUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.speedUnit?.id,
            })),
            distanceUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.distanceUnit?.id,
            })),
            weatherUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.weatherUnit?.id,
            })),
          ]}
          onClick={(option: any, index: number) => {
            resetMenus();
            handleMeasurementUnits(option, index, settingsContext);
          }}
        />

        <DropdownButton
          text="weather"
          type="radio"
          show={openOverlaysMenu}
          hideButton={inspectionContext.state.view_mode === 'observer'}
          onShow={(show: boolean) => {
            resetMenus();
            setOpenOverlaysMenu(show);
          }}
          groups={[
            weatherOptions
              .sort((o1, o2) => (o1.index > o2.index ? 1 : -1))
              .map((option) => ({
                ...option,
                selected: new RegExp(`^${inspectionContext.state.weather_tile}`).test(option.id),
              })),
          ]}
          onClick={(option) => {
            resetMenus();
            if (option.id === inspectionContext.state.weather_tile) {
              inspectionContext.dispatch({
                type: 'SET_WEATHER_TILE',
                data: undefined,
              });
              return;
            }
            inspectionContext.dispatch({
              type: 'SET_WEATHER_TILE',
              data: option.id,
            });
          }}
        />

        <DropdownButton
          text="widgets"
          type="checkbox"
          hideButton={inspectionContext.state.view_mode === 'observer'}
          show={openWidgetsMenu}
          onShow={(show: boolean) => {
            resetMenus();
            setOpenWidgetsMenu(show);
          }}
          groups={[
            widgetOptions.map((option) => ({
              ...option,
              checked: inspectionContext.state.widgets[option.id],
            })),
          ]}
          onClick={(option) => {
            inspectionContext.dispatch({
              type: 'SET_WIDGETS',
              data: {
                ...inspectionContext.state.widgets,
                [option.id]: !inspectionContext.state.widgets[option.id],
              },
            });
          }}
        />

        <DropdownButton
          show={openTimezoneMenu}
          text={selectedTimezone?.label}
          customStyle={'z-index: 35;'}
          testId="timezone-dropdown"
          type="radio"
          onShow={(show: boolean) => {
            resetMenus();
            setOpenTimezoneMenu(show);
          }}
          groups={[
            settingsContext.state.timezoneList?.map((tzone: any) => ({
              id: tzone?.id,
              label: tzone?.label,
              selected: tzone?.id === selectedTimezone?.id,
            })),
          ]}
          onClick={(option: any) => {
            settingsContext.dispatch({
              type: 'SET_TIMEZONE_UNIT',
              data: option
            });

            resetMenus();
          }}
        />

        {
          inspectionContext.state.permission_type === 'editor' ?
          (
            <DropdownButton
            text="Events"
            type="radio"
            show={false}
            hideButton={inspectionContext.state.view_mode === 'observer'}
            groups={[]}
            onShow={() => {
              inspectionContext.dispatch({
                type: 'TOGGLE_MODAL',
                data: 'event',
              });
            }}
          />
          ) : null
        }
       
        <FilledButton
          icon="visibility"
          style={{ fontSize: '12px', height: '30px', padding: '0 8px'}}
          text={`Go to ${inspectionContext.state.view_mode === 'observer' ? 'Editor' : 'Observer'} view`}
          onClick={() => {
            inspectionContext.dispatch({
              type: 'TOGGLE_VIEW_MODE',
            });
          }}
        />
      </div>
    </Header>
  );
};

export default OverlayHeader;
