import styled from 'styled-components';

export const DeviceTable = styled.table`
  width: 100%;

  border-radius: 6px;
  border: 1px solid #dfe0e4;
  background: #FFF;
  box-shadow: 0px 5px 10px 0px rgba(29, 33, 57, 0.03), 0px 2px 2px 0px rgba(29, 33, 57, 0.05);

  > tr {
    width: 100%;
    border-left: 1px solid #dfe0e4;
    border-right: 1px solid #dfe0e4;
  }

  > tr > th {
    height: 38px;
    text-align: center;
    vertical-align: middle;
    
    color: rgba(26, 31, 54, 0.65);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  > tr > td {
    border-top: 1px solid #dfe0e4;
    height: 38px;

    text-align: center;
    vertical-align: middle;

    color: #161e2e;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;

    > a,
    > span,
    > strong {
      color: #5850ec;
    }

    > div {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const DeviceTableEmpty = styled.p`
  color: rgba(26, 31, 54, 0.45);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;

  padding: 32px;
`;

