import InspectionStore from "app/modules/inspection/inspection.context";
import AccountStore from "app/modules/account/account.context";
import { useContext, useEffect, useState } from "react";
import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import { getQubeContent, renderQubeDevice } from "./qube-marker.controller";
import { QubeGlobalStyle } from "./qube-marker.style";
import { AuthType } from "app/modules/account/account.context.d";
import { getMarkerPosition } from "app/utils/map.utils";
import { MarkerClusterer } from "../../utils/marker-clusterer/markerclusterer";
import { CustomMarker } from "./qube-marker.interface";

interface Props {
  qube: QubeDeviceType;
  map: google.maps.Map;
  hasLabels: boolean;
  isObserver: boolean;
  opened: boolean;
  cluster?: MarkerClusterer;
  next: string | undefined;
  prev: string | undefined;
}

/**
 * 
 * @param props 
 * @returns 
 */
const QubeMarker = (props: Props) => {
  const authContext = useContext(AccountStore);
  const auth = authContext.state.auth as AuthType;
  const inspectionContext = useContext(InspectionStore);
  const [marker, setMarker] = useState<CustomMarker>();

  useEffect(() => {
    const promise = renderQubeDevice(
      props.qube,
      props.next,
      props.prev,
      props.isObserver,
      false,
      props.map,
      setMarker,
      inspectionContext.dispatch,
      auth.token,
    );

    promise.then((marker) => {
      if (props.qube.position?.coordinates && !props.qube.geolocked) {
        props.cluster?.addMarker(marker);
      }
    });

    return () => {
      promise.then((marker) => {
        marker.map  = null
      });
    };
  }, []);

  useEffect(() => {
    if (marker) {
      marker.content = getQubeContent(
        props.qube,
        props.next,
        props.prev,
        props.opened,
        props.isObserver,
        false,
        inspectionContext.dispatch,
        auth.token,
      );
    }

    if (props.opened) {
      marker?.map?.setOptions({ scrollwheel: true });
      sessionStorage.removeItem('cluster');
      sessionStorage.removeItem('cluster-qube');
      const eventTargetElementElement = document.querySelector('div.GT-QUBE-CLUSTER.active') as HTMLElement;
      
      if (eventTargetElementElement) {
        const content = eventTargetElementElement;
        const current = content.querySelector(`div.content.active`);
        content?.classList.remove('active');
        current?.classList.remove('active');
        content.querySelector('.qube-list-content')?.classList.remove('close');
        content.querySelector('.return-to-list')?.classList.remove('show');
        return;
      }
    }
  }, [props.qube, props.opened, props.qube?.armed])
  
  useEffect(() => {
    const coords = props.qube.position?.coordinates;
    if (coords && marker) {
      marker.position = getMarkerPosition(coords);
      marker.data = { qube: props.qube };
      props.cluster?.removeMarker(marker);
      props.cluster?.addMarker(marker);
    }
  }, [props.qube?.online, props.qube?.armed, props.qube?.position?.coordinates[0], props.qube?.position?.coordinates[1], marker]);

  return <QubeGlobalStyle />;
};

export default QubeMarker;