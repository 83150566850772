import * as style from './typograph.style';

type TypographType = 'headline1' |'headline2' |'headline3' |'headline4' |'headline5' |'headline6' |'subtitle1' |'subtitle2' |'body1' |'body2' |'button1' |'button2' |'caption' | 'overline'

interface Props {
  text?: string;
  children?: React.ReactNode;
  type?: TypographType;
};

/**
 * 
 * @param props 
 */
const Typograph = ({ children, text, type = 'body1' }: Props) => {
  const typeFirstChar = type.charAt(0).toUpperCase();
  const typeRemaningChars = type.slice(1);
  const Component = style[typeFirstChar + typeRemaningChars];
  return (
    <Component>{text || children}</Component>
  )
};

export default Typograph;
