import styled from 'styled-components';

export const TabList = styled.ul`
  position: relative;
  display: flex;
  gap: 16px;

  z-index: 2;
`;

export const TabItem = styled.li<{ selected: boolean; hide: boolean | undefined }>`
  display: ${({ hide }) => (hide ? 'none' : 'inline-block')};

  padding-left: 4px;
  padding-right: 4px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;

  box-shadow: ${({ selected }) =>
    selected ? '0px 4px 0px -1px #5850ec' : 'none'};

  > a,
  span {
    color: ${({ selected }) => (selected ? '#5850ec' : '#99a2ad')};
  }
`;

export const Quantity = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  opacity: 0.5;

  margin-left: 4px;
  color: ${({ theme }) => theme.colors.neutra_400};
`;
