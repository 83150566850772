import { SettingsContext } from "app/modules/settings/settings.context.d";
import { InspectionContext } from "../../inspection.context.d";
import { formatTimezoneDate } from "@/lib/formatter";
import { convertCSVPoint } from "./exporters.formatter";
import { RunCascadeType } from "../../inspection.interfaces";
import { generateColumns } from "../devices/desktop/components/tracking-sheet/tracking-sheet.controller";
import { AuthType } from "app/modules/account/account.context.d";

const generateCsv = (columnNames: string[], pointList: any, settingsState: SettingsContext, runName: string) => {
  const csv = [columnNames].concat(pointList).map(row =>
    row
    .map(String)  // convert every value to String
    .map(v => v.replaceAll('"', '""'))  // escape double quotes
    .map(v => `"${v}"`)  // quote it
    .join(',')  // comma-separated
  ).join('\r\n');  // rows starting on new lines

  // Create a blob
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  const assingAt = formatTimezoneDate({
    date: new Date(),
    timezone: settingsState.timezone?.id || 'UTC',
    format: 'YYYY-MM-DD_-_HH:mm:ss'
  }).replace('-', 'Y').replace('-','M').replace('_', 'D_').replace(':', 'H').replace(':', 'M') + 'S';
  
  const timezoneStr = settingsState.timezone?.label?.replace(/ /g, '_');
  const filenameStr = `${runName}_-_${assingAt}_-_${timezoneStr}`;
  pom.setAttribute('download', filenameStr);
  pom.click();
}

/**
 * @param 
 * @returns 
 */
export const exportcsv = (
  inspectionState: InspectionContext,
  settingsState: SettingsContext,
  run: RunCascadeType,
) => {
  const columns = inspectionState.grid?.getColumns().filter((col) => {
    const c = col as any;
    if (c.field === 'ete') return false;
    else if (c.field === 'input_type') return false;
    else if (c.field === 'triggers') return false;
    else {
      return true;
    }
  });

  const fieldsWithUnits = {
    speed: settingsState.speedUnit?.id,
    speed_delta: settingsState.speedUnit?.id,
    distance: settingsState.distanceUnit?.id,
    distance_delta: settingsState.distanceUnit?.id,
    chainage: settingsState.distanceUnit?.id,
  };

  const columnFields = columns?.map((column: any) => column.field) || [];
  const columnNames = columns?.map((column: any) => {
    return column.name + (fieldsWithUnits[column.field] ? ` (${fieldsWithUnits[column.field]})` : '');
  }) || [];

  const pointList = run.trackingpoint_set?.map((point) => {
    return convertCSVPoint(point, columnFields, settingsState);
  }) as string[][];
  
  generateCsv(columnNames, pointList, settingsState, run?.name || ''); 
};

/**
 * @param 
 * @returns 
 */
export const exportCsvFromCascadeRun = (
  run: RunCascadeType,
  settingsState: SettingsContext,
  auth: AuthType,
) => {
  const columns = generateColumns(
    { dispatch: () => {}, run: undefined, state: {} as InspectionContext},
    settingsState,
    false, // is observer
    auth,
    () => null, 
    () => null, // setTriggerPoint
  );
  const columnFields = columns.map((column: any) => column.field);
  const columnNames = columns.map((column: any) => column.name);
  const pointList = run?.trackingpoint_set?.map((p) => {
    return convertCSVPoint(p, columnFields, settingsState);
  }) as string[][];
  
  generateCsv(columnNames, pointList, settingsState, run.name || '');
}
