import { SurveyContext } from "app/modules/survey/survey.context.d";
import { formatPipePointsToPath } from "@/lib/formatter";


/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderPipeline = (
  pipeline: SurveyContext['pipeline'],
  map: google.maps.Map,
  outerPolyline: google.maps.Polyline | undefined,
  setOuterPolyline: (data: google.maps.Polyline | undefined) => void,
  innerPolyline: google.maps.Polyline | undefined,
  setInnerPolyline: (data: google.maps.Polyline | undefined) => void,
) => {
  if (innerPolyline) innerPolyline.setMap(null);
  if (outerPolyline) outerPolyline.setMap(null);
  if (!pipeline?.geometry?.coordinates) return;

  const points = formatPipePointsToPath({
    project: null,
    name: null,
    length: null,
    id: null,
    start: { coordinates: [], type: '' },
    geometry: { coordinates: pipeline.geometry.coordinates, type: '' },
  });

  const innerPipe = new window.google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#f1f1f1',
    strokeOpacity: 0.45,
    strokeWeight: 4,
    zIndex: 1,
    map,
  });

  const outerPipe = new window.google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#6e7881',
    strokeOpacity: 1,
    strokeWeight: 8,
    zIndex: 0,
    map,
  });
  
  setOuterPolyline(outerPipe);
  setInnerPolyline(innerPipe);
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const changePipeline = (
  pipeline: SurveyContext['pipeline'],
  map: google.maps.Map,
  outerPolyline: google.maps.Polyline | undefined,
  innerPolyline: google.maps.Polyline | undefined,
) => {
  if (!pipeline?.geometry?.coordinates) return;

  const points = formatPipePointsToPath({
    project: null,
    name: null,
    length: null,
    id: null,
    start: { coordinates: [], type: '' },
    geometry: { coordinates: pipeline.geometry.coordinates, type: '' },
  });

  points && outerPolyline?.setPath(points);
  points && innerPolyline?.setPath(points);
  map && outerPolyline?.setMap(map);
  map && innerPolyline?.setMap(map);
  
};
