import styled from 'styled-components';

export const GtTable = styled.table<{
  isLoading?: boolean;
  hideScroll?: boolean;
  customStyle?: string;
}>`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;

  grid-template-rows: auto 1fr;
  ${({ hideScroll }) => (hideScroll ? '' : 'overflow-y: auto;')}

  overflow-x: ${({ hideScroll }) => (hideScroll ? 'hidden' : 'auto')};

  overflow-x: ${({ hideScroll }) => (hideScroll ? 'hidden' : 'auto')};

  @media print {
    overflow: hidden;
  }

  border: solid 1px #dcdfe2;
  ${({ isLoading }) => (isLoading ? 'opacity: 0.5;' : '')}
  ${({ customStyle }) => customStyle}
`;

export const GtTableHead = styled.thead`
  background-color: #ffffff;
  border-bottom: solid 1px #dcdfe2;

  font-family: IBM Plex Mono, monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 1em;

  color: rgba(26, 31, 54, 0.65);
  background: rgba(220, 223, 226, 0.25);

  z-index: 3;
  min-width: 0;
  max-width: 100%;
`;

export const GtTableBody = styled.tbody``;

export const GtTableRow = styled.tr<{
  isSelected?: boolean;
  columnsLength: number;
  onClick?: () => void;
}>`
  display: grid;
  grid-template-columns: repeat(${({ columnsLength }) => columnsLength}, auto);

  * {
    ${({ onClick }) => (onClick ? 'cursor: pointer' : '')}
  }

  &:not(:last-child) {
    border-bottom: solid 1px #dcdfe2;
  }

  &:nth-child(even) {
    background-color: rgba(220, 223, 226, 0.35);
  }

  ${({ isSelected }) =>
    isSelected ? 'background-color: rgba(0,0,0, 0.35) !important;' : ''}

  :hover {
    background-color: rgba(220, 223, 226, 0.5);
  }
`;
