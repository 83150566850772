import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100%;

  position: relative;
`;

export const FormHeader = styled.header`
  margin-bottom: 26px;
`;

export const FormTitle = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #161e2e;
`;

export const FormDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.65);

  margin-top: 5px;
`;

export const Fieldset = styled.fieldset`
  display: grid;
  gap: 12px;

  padding-bottom: 24px;
  margin-bottom: 16px;

  &:not(:last-child) {
    border-bottom: solid 1px #dfe4ea;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;

  background-color: transparent;
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;

  &:active {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }

  &:focus {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 12px;

  border-top: solid 1px #dfe4ea;
  padding-top: 24px;
`;

export const SlimButton = styled.button`
  border: solid 1px #def0e4;
  color: #161e2e;

  background-color: transparent;
  border-radius: 6px;

  padding: 11px 12px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1em;

  cursor: pointer;
`;
