import { ProjectsContext } from "./projects.context.d";

export const initialProjectsState: ProjectsContext = {
  loading: false,
  participants_dic: {},
  users_dic: {},
  runs_dic: {},
  qubes_dic: {},
  project: undefined,
};
