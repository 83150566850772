import styled from 'styled-components';

export const ActiveRunWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  margin-bottom: 16px;
  user-select: none;
`;

export const ProgressBarContainer = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;

export const ProjectContent = styled.main`
  background-color: rgba(240, 242, 243, 0.5);
  background-color: #f0f2f380;
  height: calc(100vh - 42px);
  overflow: auto;

  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Left = styled.div`
  overflow: auto;
  padding: 10px 16px 16px 16px;
`;

export const Right = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const RunList = styled.ul`
  display: grid;
  gap: 8px;
`;

export const EmptyContent = styled.div`
  text-align: center;
  padding: 180px 60px;
`;

export const EmptyContentTitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;

  color: #161e2e;

  margin-bottom: 12px;
`;

export const RunsSkeletonWrapper = styled.div`
  display: grid;
  gap: 8px;

  margin-top: 8px;
`;

export const RunCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
`;

export const SectionTitle = styled.label<{
  customStyle?: string;
  hidden?: boolean;
}>`
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 20px;
  max-width: 900px;
  width: 100%;

  ${({ hidden }) => (hidden ? 'display: none;' : '')}
  ${({ customStyle }) => customStyle}
`;

export const RunName = styled.h3`
  display: inline-block;
  margin-bottom: 4px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2em;

  @media (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const LaunchedAt = styled.label`
  display: block;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2em;

  color: #686c7b;

  > strong {
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    font-size: 11px;
  }
`;

export const PercentageLabel = styled.label`
  display: block;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 1.4em;

  color: #919191;

  @media (min-width: 1024px) {
    font-size: 10px;
  }
`;

export const Pig = styled.img`
  position: absolute;
  width: 22px;
  right: 3px;
  top: 1px;

  @media (min-width: 1024px) {
    width: 26px;
  }
`;

export const RunCardWrapper = styled.div`
  position: relative;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e7ebee;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgb(37 43 64 / 5%);
  border-radius: 6px;
  padding: 16px 20px;
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

export const SlimButton = styled.button`
  border: solid 1px #686c7b;
  color: #686c7b;
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: transparent;
  border-radius: 6px;

  padding: 8px 11px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1em;

  cursor: pointer;
`;

export const ListFooter = styled.footer`
  padding-top: 24px;
  padding-bottom: 32px;
`;
