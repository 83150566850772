import { Wrapper } from './cellInputText.style';
import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

interface Props {
  value: string;
  handleChange: (data: any) => void;
  placeholder: string;
  customStyle: string;
  id: string | number;
  field?: string;
  disabled?: boolean;
}

/**
 * Input component
 * @returns
 */
const CellInputText = ({
  value,
  handleChange,
  id,
  disabled,
  field,
}: Props) => {
  const [fieldValue, setFieldValue] = useState(value || '');

  const handleFieldChange = (target: any) => {
    setFieldValue(target.value);
  };

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const keyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleChange({ value: fieldValue, oldValue: value, id, field });
    }
  };

  return (
    <Wrapper>
      <TextField
        disabled={disabled}
        value={fieldValue || ''}
        title={fieldValue}
        onKeyPress={keyPress}
        onChange={({ target }) => handleFieldChange(target)}
        onBlur={() =>
          handleChange({ value: fieldValue, oldValue: value, id, field })
        }
        id={`${id}`}
      />
    </Wrapper>
  );
};

CellInputText.defaultProps = {
  value: '',
  id: '',
  customStyle: '',
  disabled: false,
  placeholder: '',
  handleChange: () => '',
};

export default CellInputText;
