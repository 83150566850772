import {
  HeaderContainer,
  CloseButton,
  RunName,
  GenerateReportButton,
} from './header.style';

interface HeaderProps {
  name: string;
  onClose(): void;
}

const Header = ({ name, onClose }: HeaderProps) => (
  <HeaderContainer>
    <div>
      <CloseButton>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClose}
        >
          <rect
            x="8.53564"
            y="0.0507812"
            width="2"
            height="12"
            rx="1"
            transform="rotate(45 8.53564 0.0507812)"
            fill="#161E2E"
          />
          <rect
            x="9.94971"
            y="8.53516"
            width="2"
            height="12"
            rx="1"
            transform="rotate(135 9.94971 8.53516)"
            fill="#161E2E"
          />
        </svg>
      </CloseButton>
    </div>
    <RunName>
      <strong>Generate Report</strong> {name}
    </RunName>
    <div>
      <GenerateReportButton onClick={() => window.print()}>
        Generate Report
      </GenerateReportButton>
    </div>
  </HeaderContainer>
);

export default Header;
