import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  position: sticky;
  right: 0;

  display: flex;
  align-items: center;
  gap: 4px;
  height: 28px;

  background-color: transparent;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  color: #5850ec;

  cursor: pointer;
`;

export const Dropdown = styled.ul<{ show: boolean }>`
  position: absolute;
  right: -51px;
  bottom: 10px;

  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: 224px;
  flex-direction: column;
  text-align: left;
  gap: 8px;

  background-color: #ffffff;

  padding: 16px 28px;

  background: #ffffff;
  border: 1px solid #dfe4ea;
  box-sizing: border-box;
  box-shadow: 0px 6px 8px rgba(51, 57, 78, 0.2);
  border-radius: 6px;
`;

export const Item = styled.li`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  color: #9d9fa9;

  &:hover {
    color: #5850ec;
  }
`;
