import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
  display: grid;
  grid-template-columns: 139px 1fr 139px;
  grid-template-rows: 42px;

  align-items: center;
  background-color: #ffffff;

  border-bottom: solid 1px #e4e6eb;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  cursor: pointer;

  > svg {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 3px;
    padding-bottom: 3px;

    box-sizing: content-box;

    border-right: solid 1px #e4e6eb;
  }
`;

export const RunName = styled.h1`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #161e2e;

  strong {
    font-weight: 500;
  }
`;

export const GenerateReportButton = styled.button`
  height: 42px;
  width: 100%;
  cursor: pointer;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #fafafb;

  background-color: #5850ec;
`;
