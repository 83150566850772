import styled from 'styled-components';

export const Card = styled.div<{ $focused: boolean }>`
  scroll-snap-align: center;

  width: 100%;
  height: 150px;

  background: #ffffff;
  border-radius: 6px;

  box-shadow: 0px 6px 10px 0px #33394E33;
 
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-template-rows: 1fr;

  opacity: ${({ $focused }) => ($focused ? 1 : 0.3)};
`;

export const CardContent = styled.div`
  display: grid;
  align-content: space-between;
`;

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 6px;

  background-color: #f5f5f8;
  padding: 12px;

  border-bottom: 0.6px solid #dcdfe2;
  padding-bottom: 8px;
`;

export const CardMainData = styled.dl`
  display: flex;
  gap: 14px;

  margin: 0;

  padding-left: 12px;
  padding-right: 12px;
`;

export const CardMainDataGroup = styled.div`
  display: flex;
  gap: 4px;
`;

export const CardMainDataLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;

  margin: 0 ;
  color: ${({ theme }) => theme.colors.neutra_400};
`;

export const CardMainDataValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  margin: 0;

  color: ${({ theme }) => theme.colors.neutra_400};
`;

export const CardPassage = styled.div<{ $isObserver: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isObserver }) => $isObserver ? '1fr 1fr' : 'auto 1fr 1fr'};
  gap: 7px;

  padding-left: 12px;
  padding-right: 12px;
`;

export const CardPassageInput = styled.div<{ $invalid: boolean, $isObserver: boolean }>`
  display: flex;

  border: 0.5px solid;
  background-color: ${({ $invalid }) => (!$invalid ? 'transparent' : 'rgba(237, 32, 36, 0.1)')};
  border-color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};
  border-radius: 6px;

  padding: 3px;

  align-items: center;
  justify-content: flex-start;

  color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  ${({ $isObserver }) => $isObserver ? 'border: 0;' : ''}

  > svg {
    margin-right: 8px;

    > * {
      fill: ${({ $invalid }) => (!$invalid ? '#5850EC' : 'rgba(237, 32, 36, 1)')};
    }
  }

  > input {
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    background-color: transparent;
    color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};
  }
`;

export const CardSidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.neutra_300};
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  
  color: #4B5066
`;

export const CardTrackingPointName = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #4B5066;
  
`;

export const CardTrackingPointTypeIcon = styled.img`
  width: 22px;
  height: 22px;
`;

export const CardDeviceExplanationContainer = styled.div`
  position: relative;
`;

export const CardDeviceExplanationButton = styled.button`
  width: 22px;
  height: 22px;

  background: #dcdfe2;
  border-radius: 50%;
`;

export const CardDeviceInformation = styled.div`
  display: flex;
  gap: 24px;

  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
`;

export const CardDeviceInputType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-right: 11px;
  
  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;

    color: #4b5066;
  }
`;

export const CardDeviceExplanationTooltip = styled.div`
  position: absolute;

  width: 163px; 
  bottom: calc(-100% + 60px);
  right: -11px;
  background-color: #ffffff;
  padding: 8px 16px 16px 16px;
  border-radius: 4px;

  -webkit-filter: drop-shadow(0px 1px 2px rgba(0,0,0,.2));
  filter: drop-shadow(0px 1px 2px rgba(0,0,0,.2));

  span {
    content: '';
    position: absolute;
    top: 98%;
    right: 14px;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    z-index: 2;
  }
`;

export const CardDeviceExplanationTooltipCloseButton = styled.button`
  background-color: transparent;
  float: right;
  position: relative;
  top: 8px;
`;

export const CardDeviceExplanationTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;

  > p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: center;

    color: #656978;
  }

  > figure {
    margin-top: -8px;
    margin-bottom: 4px;
  }
`; 
