import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import Battery from '../battery/battery';
import {
  DeviceCard,
  DeviceCardContent,
  DeviceCardHeader,
  DeviceCheckWrapper,
  DeviceInfoList,
  DeviceInfo,
  DeviceInfoLabel,
  DeviceInfoValue,
  DeviceInfoValueBall,
  DeviceProjectName,
  DeviceTitleWrapper,
  DeviceTitle,
  DeviceStatusFigure,
  getCardBackground,
} from './device-card.style';
import {
  getUptime,
} from '../device-common.controller';
import Checkbox from 'app/components/checkbox/checkbox';

interface Props {
  device: QubeDeviceType;
  onClick: (device: QubeDeviceType) => void;
  onDrag: (device: QubeDeviceType) => void;
  onSelect: (device: QubeDeviceType) => void;
  selected: boolean;
  id?: number | string;
  light?: boolean;
  opaque?: boolean;
  hideCheckbox?: boolean;
  stringPart?: string;
  dragImage?: HTMLImageElement | null;
}

/**
 *
 * @param props
 * @returns
 */
const Card = (props: Props) => {
  const theme = useTheme();
  const [dragImage, setDragImage] = useState<HTMLImageElement>();

  useEffect(() => {
    if (props.dragImage) return;

    // Create a canvas and draw a visual representation as a drag image
    const canvas = document.createElement('canvas');
    canvas.width = 180; // canvas width
    canvas.height = 50; // canvas height
    const ctx = canvas.getContext('2d');
    
    if (!ctx) return;
    const borderRadius = 4;
    ctx.fillStyle = getCardBackground(props.light, theme);
    ctx.globalAlpha = 1;

    ctx.beginPath();
    
    // Create a rounded rectangle
    ctx.moveTo(borderRadius, 0);
    ctx.lineTo(canvas.width - borderRadius, 0);
    ctx.arcTo(canvas.width, 0, canvas.width, borderRadius, borderRadius);
    ctx.lineTo(canvas.width, canvas.height - borderRadius);
    ctx.arcTo(canvas.width, canvas.height, canvas.width - borderRadius, canvas.height, borderRadius);
    ctx.lineTo(borderRadius, canvas.height);
    ctx.arcTo(0, canvas.height, 0, canvas.height - borderRadius, borderRadius);
    ctx.lineTo(0, borderRadius);
    ctx.arcTo(0, 0, borderRadius, 0, borderRadius);
    ctx.closePath();
    ctx.fill();

    // Load the device icon
    const deviceImage = new Image();
    deviceImage.src = '/assets/images/qube-icon.png';
    
    // Draw the device icon on the canvas
    const devicePadding = 10;
    const deviceImageSize = 30;
    ctx.drawImage(deviceImage, devicePadding, (canvas.height - deviceImageSize) / 2, deviceImageSize, deviceImageSize);

    // Draw the device serial number
    ctx.fillStyle = theme.colors.outline_on_surface;
    ctx.font = '500 12px Inter, sans-serif';
    const deviceSerialX = devicePadding + deviceImageSize + devicePadding;
    const deviceSerialY = devicePadding + 12;
    ctx.fillText(props.device.serial, deviceSerialX, deviceSerialY);

    // Draw the project name
    ctx.fillStyle = theme.colors.outline_on_surface;
    ctx.font = '10px Inter, sans-serif';
    const projectNameX = deviceSerialX;
    const projectNameY = deviceSerialY + 12 + devicePadding / 3;
    ctx.fillText(props.device.project_name?.toLocaleUpperCase() || '-', projectNameX, projectNameY);

    // Create a new image from the canvas
    const dImage = new Image();
    dImage.src = canvas.toDataURL(); // Convert the content of the canvas to a data URL
    setDragImage(dImage);
  }, [props.dragImage, props.device.serial, props.device.project_name, props.light, theme]);

  // highlight text
  const highlight = useCallback((text: string) => {
    if (props.stringPart) {
      const regex = new RegExp('(' + props.stringPart + ')', 'gi');
      return text.split(regex).map((part, index) => 
        regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
      );
    }

    return text;
  }, [props.stringPart]);

  /**
   *
   */
  const handleClick = () => {
    props.onClick(props.device);
  };
  
  /**
   *
   */
  const handleSelect = (e: InputEvent) => {
    e.stopPropagation();
    props.onSelect(props.device);
  };

  /**
   *
   * @param event
   * @param device
   */
  const dragstartHandler = (event, device: QubeDeviceType) => {   
    event.dataTransfer.setData("text", event.target.id);
    // Set the cursor image while dragging
    if (dragImage || props.dragImage) {
      event.dataTransfer.setDragImage(props.dragImage || dragImage, 0, 50);
    }
    
    props.onDrag?.(device);
  };

  return (
    <DeviceCard
      $isClickable={!!props.onClick}
      $light={props.light}
      $opaque={props.opaque}
      id={props.id?.toString() || props.device.serial}
      draggable
      onDragStart={(event) => dragstartHandler(event, props.device)}
      onClick={handleClick} key={props.device.serial}
    >
      <DeviceCardHeader>
        <DeviceStatusFigure>
          <img src="/assets/images/qube-icon.png" />
        </DeviceStatusFigure>
        <DeviceTitleWrapper>
          <DeviceTitle>{highlight(props.device.serial)}</DeviceTitle>
          <DeviceProjectName>{props.device.project_name ? highlight(props.device.project_name) :  '-'}</DeviceProjectName>
        </DeviceTitleWrapper>

        <DeviceCheckWrapper>
          {props.hideCheckbox ? null : <Checkbox checked={props.selected} onClick={handleSelect} />}
        </DeviceCheckWrapper>
      </DeviceCardHeader>

      <DeviceCardContent>
        <DeviceInfoList>
          <DeviceInfo>
            <DeviceInfoLabel>Status</DeviceInfoLabel>
            <DeviceInfoValue>
              <DeviceInfoValueBall $actived={props.device.online} />
              {props.device.online ? 'Online' : 'Offline'}
            </DeviceInfoValue>
          </DeviceInfo>
          <DeviceInfo>
            <DeviceInfoLabel>Type</DeviceInfoLabel>
            <DeviceInfoValue>
              <DeviceInfoValueBall $actived={props.device.geolocked} />
              {props.device.geolocked ? 'Geolocked' : 'Ungeolocked'}
            </DeviceInfoValue>
          </DeviceInfo>
          <DeviceInfo>
            <DeviceInfoLabel>Battery</DeviceInfoLabel>
            <DeviceInfoValue>
              <Battery
                id={props.device.serial}
                level={props.device.battery_level}
              />
            </DeviceInfoValue>
          </DeviceInfo>
          <DeviceInfo>
            <DeviceInfoLabel>Status</DeviceInfoLabel>
            <DeviceInfoValue>
              <DeviceInfoValueBall $actived={props.device.armed} />
              {props.device.armed ? 'Armed' : 'Disarmed'}
            </DeviceInfoValue>
          </DeviceInfo>
          <DeviceInfo>
            <DeviceInfoLabel>Uptime</DeviceInfoLabel>
            <DeviceInfoValue>{getUptime(props.device.uptime_secs)}</DeviceInfoValue>
          </DeviceInfo>
        </DeviceInfoList>
      </DeviceCardContent>
    </DeviceCard>
  );
};

export default Card;
