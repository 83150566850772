import { formatSpeedToMps } from '@/lib/formatter';
import moment from 'moment-timezone';
import { updateRun } from '../../services/run.service';

export const saveRun = async ({
  auth,
  updatedRun,
  run,
  setErrors,
  timezone,
  speedUnit,
  setIsLoading,
  onUpdated,
  onClose,
  dispatch,
}: any) => {
  setIsLoading(true);
  const startDate = moment.tz(timezone.id);
  const [year, month, day] = updatedRun.startDate.split('/');
  const testDate = new Date(`${year}/${month}/${day}`);

  startDate.year(+year);
  startDate.month(+month - 1);
  startDate.date(+day);

  if (!updatedRun.name) {
    setIsLoading(false);
    return setErrors({
      name: 'Name is required',
    });
  }
  if (!updatedRun.predictedSpeed) {
    setIsLoading(false);
    return setErrors({
      predictedSpeed: 'Predicted Launch Speed is required',
    });
  }

  if (testDate.toString() === 'Invalid Date') {
    setIsLoading(false);
    return setErrors({
      startDate: 'Invalid Date',
    });
  }

  const h = +updatedRun.startTime.slice(0, 2);
  const m = +updatedRun.startTime.slice(3, 5);
  const s = +updatedRun.startTime.slice(6, 8);
  const mm = +updatedRun.startTime.slice(9);
  const isValid = !(h > 23 || m > 59 || s > 59 || mm > 999);
  if (isNaN(h) || isNaN(m) || isNaN(s) || isNaN(mm)) {
    setIsLoading(false);
    return setErrors({
      startTime: 'Invalid Time',
    });
  }
  
  if (!isValid) {
    setIsLoading(false);
    return setErrors({
      startTime: 'Invalid Time',
    });
  }

  startDate.hour(h);
  startDate.minute(m);
  startDate.second(s);
  startDate.millisecond(mm);

  const runBody = {
    id: run.id,
    name: updatedRun.name,
    predicted_launch_speed: +formatSpeedToMps({
      distance: updatedRun.predictedSpeed,
      unit: speedUnit?.id,
      returnNumber: true,
    }),
    run_type: updatedRun.type,
    scheduled_launch_time: startDate.utc(),
    email: updatedRun.email,
    sms: updatedRun.sms,
  };
  
  try {
    await updateRun(run.id, runBody, auth.token);
    setIsLoading(false);
    onUpdated();
    onClose();
  } catch (err: any) {
    setIsLoading(false);
    if (err.response?.data?.type) {
      return dispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};
