import styled from 'styled-components';

export const Wrapper = styled.div<{ $customStyle?: string }>`
  display: grid;
  grid-template-columns: 18px auto;
  align-items: center;

  > svg {
    cursor: pointer;
  }

  > label {
    white-space: nowrap;
    max-width: 210px;
  }

  ${({ $customStyle }) => $customStyle ? $customStyle : ''}
`;

export const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;

  margin-left: 8px;
  color: #6b7280;
`;
