import { RunType } from "app/interfaces/inspection.interfaces";
import { AuthType } from "app/modules/account/account.context.d";
import { exportCsvFromCascadeRun } from "app/modules/inspection/run/exports/run-csv-exporter";
import { exportXlxsFromCascadeRun } from "app/modules/inspection/run/exports/run-xlxs-exporter";
import { SettingsContext } from "app/modules/settings/settings.context.d";
import { find } from "app/services/run.service";

export const downloadSheetAsCsv = async (run: RunType, settingsState: SettingsContext, auth?: AuthType) => {
  if (!auth) return;

  const completeRun = await find(run.id, auth.token);
  exportCsvFromCascadeRun(completeRun.data, settingsState, auth);
};

export const downloadSheetAsXlxs = async (run: RunType, settingsState: SettingsContext, auth?: AuthType) => {
  if (!auth) return;

  const completeRun = await find(run.id, auth.token);
  exportXlxsFromCascadeRun(completeRun.data, settingsState, auth);
};
