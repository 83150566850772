import { useReducer, createContext, ReactNode } from 'react';
import { reducer } from './notification.reducer';
import {
  NotificationContext,
  NotificationDispatchType,
  NotificationProviderValue,
} from './notification.context.d';

const initialNotificationState = {
  toast: undefined,
};

const NotificationStore = createContext({} as NotificationProviderValue);

/**
 * 
 * @returns 
 */
export const NotificationProvider = (props: { children: ReactNode }) => {
  // Define state and dispatch method for this Context
  const reducerData = useReducer(reducer, { ...initialNotificationState });
  const state: NotificationContext = reducerData[0];
  const dispatch: (data: NotificationDispatchType) => void = reducerData[1];
  
  return (
    <NotificationStore.Provider value={{ state, dispatch }}>
      {props.children}
    </NotificationStore.Provider>
  );
};


export default NotificationStore;
