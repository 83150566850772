import {
  AccountContext,
  AccountDispatchType,
  AuthType,
  PermissionType,
} from './account.context.d';

/**
 * 
 * @param state
 * @param action 
 * @returns account context state
 */
export const reducer = (
  state: AccountContext,
  action: AccountDispatchType
): AccountContext => {
  switch (action.type) {
    case 'LOGIN':
      if (action.data)
        return { ...state, auth: action.data as AuthType };
      return state;
    case 'LOGOUT':
      return { ...state, auth: undefined };
    case 'OPEN_PROFILE_MODAL':
      return { ...state, opened_profile_modal: true };
    case 'CLOSE_PROFILE_MODAL':
      return { ...state, opened_profile_modal: false };
    case 'SET_REGISTER_DATA':
      return { ...state, register_data: undefined };
    case 'SET_PERMISSIONS':
      return { ...state, permissions: action.data as PermissionType[] }
    case 'SET_LOADING':
      return { ...state, loading: action.data as boolean }
    case 'SET_VERIFIED':
      return { ...state, verified: action.data as boolean }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};
