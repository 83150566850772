import styled from 'styled-components';

export const QubeModalWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 21;

  background-color: #ffffff;

  height: -webkit-fill-available;
  width: 100%;

  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;

  display: grid;
  grid-template-rows: auto 1fr;
  align-content: start;
`;



export const QubeModalPagination = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  color: #ffffff;
`;

export const QubeModalFooter = styled.footer`
  position: absolute;
  top: 100%;
  z-index: 1;

  background-color: #323030;
  width: 100%;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 16px;

  > div {
    display: flex;
    align-items: center;
    gap: 4px;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      box-shadow: 0px 3.71px 8.65px 0px #00000033;

      width: 42px;
      height: 42px;
      border-radius: 50%;

      font-size: 36px;
    }
  }
`;
