import styled from 'styled-components';

export const Container = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    overflow: auto;
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 1em;
  padding-bottom: 10px;
  display: block;

  strong {
    font-weight: 600;
  }

  color: #4b5066;
`;

export const Form = styled.div`
  width: 450px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.label`
  display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18;
  line-height: 22px;
  color: #161e2e;
`;

export const Explanation = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;

  color: #6b7280;
  font-size: 10px;
`;

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const Row = styled.div<{ customStyle?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  ${({ customStyle }) => customStyle ? customStyle : ''}
`;

export const MapWrapper = styled.div`
  height: 100%;
`;

export const SelectInput = styled.select`
  padding: 5px 15px 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;
  &:active {
    padding: 5px 15px 5px 10px;
    border: 1px solid #5850ec;
  }
  &:focus {
    padding: 5px 15px 5px 10px;
    border: 1px solid #5850ec;
  }
  ${({ customStyle }: any) => customStyle}
`;

export const LinkButton = styled.button`
  background-color: transparent;
  margin-top: 32px;
  text-decoration: underline;

  ${({ customStyle }: any) => customStyle}
`;

export const TimeInputWrapper = styled.div`
  input {
    padding: 5px 10px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    width: 100%;

    &:active {
      padding: 5px 10px;
      border: 1px solid #5850ec;
    }

    &:focus {
      padding: 5px 10px;
      border: 1px solid #5850ec;
    }
  }
`;
