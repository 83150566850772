import { useState, useEffect, useRef } from 'react';
import TableHeadColumn from './components/table-head-column/table-head-column';
import TableRow from './components/table-row/tableRow';
import { Column, TableProps } from './table.interfaces';
import { GtTable, GtTableHead, GtTableBody, GtTableRow } from './table.style';

/**
 *
 * @param columns
 * @returns
 */
const renderHeadColumns = (
  columns: Column[],
  sortedBy: string,
  sortType: null | 'asc' | 'desc',
  dragOver: string,
  columnsSort: string[],
  noResize: boolean | undefined,
  setSortedby: (sortedBy: string) => void,
  setSortType: (sortType: null | 'asc' | 'desc') => void,
  setDragOver: (dragOver: string) => void,
  setColumnsSort: (columnsSort: string[]) => void,
  setCopyColumns: (columns: Column[]) => void,
  onResize?: (columns: Column[]) => void
) =>
  columns
    .sort(
      (columnA, columnB) =>
        columnsSort.indexOf(columnA.field) - columnsSort.indexOf(columnB.field)
    )
    .map((column, i) => (
      <TableHeadColumn
        key={column.field}
        column={column}
        noResize={noResize}
        index={i}
        dragOver={dragOver}
        columnsSort={columnsSort}
        sortedBy={sortedBy}
        sortType={sortType}
        setSortedby={setSortedby}
        setSortType={setSortType}
        setDragOver={setDragOver}
        setColumnsSort={setColumnsSort}
        onResize={(width: number) => {
          if (dragOver) return;

          const copyColumns: Column[] = [...columns];
          const columnIndex: number = copyColumns.findIndex(
            (col) => col.field === column.field
          );

          copyColumns[columnIndex].width = width;
          if (onResize) onResize(copyColumns);
          setCopyColumns(copyColumns);
        }}
      />
    ));

/**
 *
 * @param columns
 * @returns
 */
const renderRows = (
  rows: any[],
  columns: Column[],
  data: any,
  selectedLine: any,
  lineHandleClick: (data: any) => void
) =>
  rows.map((row, i) => (
    <TableRow
      key={`${row?.id}-${i}`}
      row={row}
      columns={columns}
      data={data}
      selectedLine={selectedLine}
      lineHandleClick={lineHandleClick}
    />
  ));

/**
 *
 * @param rows
 * @param columns
 * @returns
 */
const sortRows = (
  sortedBy: string,
  sortType: null | 'asc' | 'desc',
  rows: any[]
) => {
  if (!sortedBy || !sortType) return rows;
  return rows.sort((rowA, rowB) => {
    // check if type is string
    if (typeof rowB[sortedBy] === 'string') {
      // check sort type
      if (sortType !== 'desc')
        return ('' + rowA[sortedBy]).localeCompare(rowB[sortedBy]);

      return ('' + rowB[sortedBy]).localeCompare(rowA[sortedBy]);
    }

    // check sort type
    if (sortType !== 'desc') return rowA[sortedBy] - rowB[sortedBy];
    return rowB[sortedBy] - rowA[sortedBy];
  });
};

/**
 *
 * @param param0
 * @returns
 */
const Table = ({
  columns,
  rows,
  isLoading,
  hideScroll,
  data,
  selectedLine,
  customStyle,
  noResize,
  lineHandleClick,
  onResize,
}: TableProps) => {
  const tableRef = useRef<any>(null);
  const [copyColumns, setCopyColumns] = useState<Column[]>([...columns]);
  const [sortedBy, setSortedBy] = useState<string>('');
  const [dragOver, setDragOver] = useState<string>('');
  const [sortType, setSortType] = useState<null | 'asc' | 'desc'>(null);
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  const [columnsSort, setColumnsSort] = useState<string[]>(
    columns.map((column) => column.field)
  );

  useEffect(() => {
    setSortedRows(sortRows(sortedBy, sortType, [...rows]));
  }, [rows]);

  useEffect(() => {
    setCopyColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    if (!sortedRows.length) {
      setSortedRows(sortRows(sortedBy, sortType, [...rows]));
    }
  }, [sortedBy, sortType]);

  useEffect(() => {
    if (tableRef?.current && selectedLine) {
      tableRef?.current?.scrollIntoView({ behavior: 'smooth' });
      const element = window.document.getElementById(`gt-row-${selectedLine}`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selectedLine]);

  return (
    <GtTable
      customStyle={customStyle}
      ref={tableRef}
      isLoading={!!isLoading}
      hideScroll={hideScroll}
    >
      <GtTableHead>
        <GtTableRow columnsLength={copyColumns.length}>
          {renderHeadColumns(
            copyColumns,
            sortedBy,
            sortType,
            dragOver,
            columnsSort,
            noResize,
            setSortedBy,
            setSortType,
            setDragOver,
            setColumnsSort,
            setCopyColumns,
            onResize
          )}
        </GtTableRow>
      </GtTableHead>
      <GtTableBody>
        {renderRows(
          sortedRows,
          copyColumns,
          data,
          selectedLine,
          lineHandleClick
        )}
      </GtTableBody>
    </GtTable>
  );
};

Table.defaultProps = {
  lineHandleClick: () => '',
  onresize: () => null,
};

export default Table;
