import { RunCascadeType } from "app/modules/inspection/inspection.interfaces";
import { useEffect, useState } from "react";
import { changePipeline, renderPipeline } from "./pipeline.controller";

interface Props {
  pipeline: RunCascadeType['pipeline'];
  map: google.maps.Map;
}

const Pipeline = ({ pipeline, map }: Props) => {
  const [innerPipe, setInnerPipe] = useState<google.maps.Polyline>();
  const [outerPipe, setOuterPipe] = useState<google.maps.Polyline>();

  useEffect(() => {
    return () => {
      innerPipe?.setMap(null);
      outerPipe?.setMap(null);
    };
  }, []);

  useEffect(() => {
    if (!innerPipe && !outerPipe) {
      renderPipeline({ pipeline, map, setInnerPipe, setOuterPipe });
    }
  }, [pipeline?._loaded]);

  useEffect(() => {
    changePipeline({ pipeline, innerPipe, outerPipe });
  }, [pipeline?.geometry?.coordinates]);

  return null;
};

export default Pipeline;