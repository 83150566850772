import { useEffect, useState } from 'react';
import CloseIcon from '../../icons/close.icon';
import {
  DeviceModal,
  DeviceHeader,
  DeviceCloseButton,
} from './device-scan.style';
import { Html5Qrcode, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';

interface Props {
  onClose: () => void;
  onScan: (decodedText: string) => void;
}

/**
 * 
 */
const DeviceScan = (props: Props) => {
  const [scanner, setScanner] = useState<Html5Qrcode>();
  
  const handleClose = async () => {
    props.onClose();

    if (scanner?.isScanning) {
      scanner.pause(true);
      await scanner.stop();
      scanner.clear();
    }
  };

  /**
   * 
   */
  const handleScanSuccess = async (html5Qrcode, decodedText) => {
    if (html5Qrcode?.isScanning) {
      html5Qrcode.pause(true);
      await html5Qrcode.stop();
      html5Qrcode.clear();
    }

    props.onScan(decodedText);
    props.onClose();
  };
  
  /**
   * 
   */
  const handleScanError = () => {
    // TODO
  };
  
  useEffect(() => {
    const width = document.body.clientWidth;
    const height = document.body.clientHeight - 42;

    const config = {
      fps: 10,
      qrbox: { width: 200, height: 200 },
      aspectRatio: height / width,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    };
    
    const html5Qrcode = new Html5Qrcode('scan-render');
    html5Qrcode.start(
      { facingMode: "environment" },
      config,
      (decodedText) => handleScanSuccess(html5Qrcode, decodedText),
      () => handleScanError(),
    );
    setScanner(html5Qrcode);
  }, []);
  
  return (
    <DeviceModal>
      <DeviceHeader>
        <DeviceCloseButton
          onClick={handleClose}
        >
          <CloseIcon />
        </DeviceCloseButton>
      </DeviceHeader>

      <div id="scan-render" />
    </DeviceModal>
  );
};

export default DeviceScan;
