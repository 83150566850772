import styled from 'styled-components';

export const DeviceModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 30;
`;

export const DeviceModal = styled.div`
  width: 100%;
  max-width: 580px;

  background: #ffffff;

  border-radius 6px;
  padding-bottom: 32px;
`;

export const DeviceCloseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
`;

export const DeviceHeader = styled.header`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  padding-left: 8px;
  padding-right: 8px;
`;

export const DeviceModalFigure = styled.figure`
  position: relative;
  top: -26px;
  left: calc(50% - 26px);

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5850ec;
  width: 52px;
  height: 52px;

  border-radius: 50%;
`;

export const DeviceModalTitle = styled.h2`
  color: #1a1f36;
  text-align: center;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeviceModalDescription = styled.p`
  color: #656978;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  max-width: 257px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
`;

export const DeviceModalTyperWrapper = styled.div`
  width: 242px;
  border: 1px solid #dfe0e4;

  margin: 32px auto;
`;

export const DeviceModalTyperLabel = styled.label`
  display: block;
  padding: 13px 19px;
  border-bottom: 1px solid #dfe0e4;

  color: rgba(26, 31, 54, 0.65);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DeviceModalTyperInput = styled.input`
  padding: 13px 19px;

  color: rgba(26, 31, 54, 0.65);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:focus {
    padding: 13px 19px;
  }

  &::placeholder  {
    color: var(--Light-gray, #DCDFE2);
  }
`;

export const DeviceModalActions = styled.div`
  width: 242px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 40px;
  gap: 8px;
  
  margin-left: auto;
  margin-right: auto;
`;

export const cancelButtonCustomStyle = `
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(29, 33, 57, 0.03), 0px 2px 2px 0px rgba(29, 33, 57, 0.05);
  color: var(--Medium-gray, #767986);
`;
