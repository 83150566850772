import { useContext, useState } from 'react';
import InputGroup from '../../../../input-group/inputGroup';
import InputPassword from '../../../../input-password/inputPassword';
import Button from '../../../../button/button';
import { submit } from './security.controller';
import { ErrorsType } from './security.d';
import {
  Form,
  FormHeader,
  FormTitle,
  FormDescription,
  Fieldset,
  ActionsWrapper,
  SlimButton,
} from './security.style';
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';

const Security = () => {
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);
  const [errors, setErrors] = useState<ErrorsType>({});
  const [loading, setLoading] = useState<boolean>(false);
  // form values
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  if (!accountContext.state.auth) return null;

  return (
    <Form autoComplete="off">
      <div>
        <FormHeader>
          <FormTitle>Security</FormTitle>
          <FormDescription>
            Register a new password. If you use GlobalTrack on other devices,
            you will need to log in again on them with your new password.
          </FormDescription>
        </FormHeader>
        <Fieldset>
          <InputGroup label="Current Password" error={errors.currentPassword}>
            <InputPassword
              customStyle={`height: 40px;`}
              value={currentPassword}
              handleChange={(e: any) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </InputGroup>
          <div />
          <InputGroup label="New Password" error={errors.newPassword}>
            <InputPassword
              customStyle={`height: 40px;`}
              value={newPassword}
              handleChange={(e: any) => {
                setNewPassword(e.target.value);
              }}
            />
          </InputGroup>
          <InputGroup label="Confirm Password" error={errors.confirmPassword}>
            <InputPassword
              customStyle={`height: 40px;`}
              value={confirmPassword}
              handleChange={(e: any) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </InputGroup>
        </Fieldset>
      </div>
      <ActionsWrapper>
        <Button
          disabled={loading}
          handleClick={() => {
            if (!accountContext.state.auth) return;

            submit(
              setLoading,
              setErrors,
              currentPassword,
              newPassword,
              confirmPassword,
              accountContext.state.auth.token,
              notificationContext.dispatch
            );
          }}
          customStyle={`
              width: auto;
              padding: 12px 16px;

              &:focus {
                padding: 12px 16px;
              }
            `}
          value="Update"
        />
        <SlimButton
          type="button"
          onClick={() => {
            accountContext.dispatch({
              type: 'CLOSE_PROFILE_MODAL',
            });
          }}
        >
          Cancel
        </SlimButton>
      </ActionsWrapper>
    </Form>
  );
};

export default Security;
