import { Wrapper } from './cellInputNumber.style';
import { TextField } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';

/**
 *
 * extractNumbers - that function extract all numbers of an string
 *
 * @function extractNumbers
 * @param  {string} text  that function extract all numbers of an string
 * @returns {string} it's the string that have only the filtered numbers
 */
const extractNumbers = (text: string) => {
  let number = '';

  Object.keys(text).forEach((i: any) => {
    const n = text[i];
    if (
      parseInt(n, 10) ||
      parseInt(n, 10) === 0 ||
      n === '+' ||
      n === ':' ||
      n === '-' ||
      n === '.'
    )
      number += n;
  });

  return number;
};

interface Props {
  value: string;
  handleChange: (data: any) => void;
  hasError?: () => string;
  placeholder: string;
  customStyle: string;
  field?: string;
  id: string | number;
  disabled?: boolean;
}

/**
 * Input component
 * @returns
 */
const CellInputNumber = ({
  value,
  handleChange,
  id,
  disabled,
  field,
  hasError,
}: Props) => {
  const [fieldValue, setFieldValue] = useState(value || '');
  const error = hasError ? hasError() : '';
  const handleFieldChange = (target: any) => {
    setFieldValue(extractNumbers(target.value));
  };

  const keyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleChange({ value: fieldValue, oldValue: value, id, field });
    }
  };

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <Wrapper error={error}>
      <TextField
        disabled={disabled}
        value={fieldValue}
        onChange={({ target }) => handleFieldChange(target)}
        onKeyPress={keyPress}
        onBlur={() =>
          handleChange({ value: fieldValue, oldValue: value, id, field })
        }
        id={`${id}`}
      />
    </Wrapper>
  );
};

CellInputNumber.defaultProps = {
  value: '',
  id: '',
  customStyle: '',
  disabled: false,
  placeholder: '',
  handleChange: () => '',
  hasError: () => '',
};

export default CellInputNumber;
