import styled from 'styled-components';

export const ControlList = styled.ul<{ $hasStreetViewButton: boolean }>`
  position: absolute;
  top: 40px;
  left: ${({ $hasStreetViewButton }) => ($hasStreetViewButton ? '95px' : '10px')};

  display: flex;
  flex-wrap: nowrap;
  border-radius: 4px;
  overflow: hidden;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  @media(min-width: 1024px) {
    top: 10px;
    left: ${({ $hasStreetViewButton }) => ($hasStreetViewButton ? '51px' : '10px')};
  }
`;

export const ControlItem = styled.li``;

export const Control = styled.button`
  width: 70px;
  height: 36px;

  padding: 0px 8px;

  background-color: #ffffff;
  backdrop-filter: blur(10px);

  display: flex;
  align-items: center;
  justify-content: center;

  transition-duration: 400ms;

  font-size: 12px;
  font-weight: 500;

  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;
