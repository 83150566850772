import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import { SettingsContext, Unit } from 'app/modules/settings/settings.context.d';
import { Chart, registerables } from 'chart.js';
import { RunCascadeType, TrackingPointCascadeType } from '../inspection.interfaces';

export const getSpeedCanvas = (
  run: RunCascadeType,
  speedUnit: Unit | undefined,
) => {
  const speeds = run.trackingpoint_set?.filter((point) => {
    const adHocWNoPassage = point.type === "ad-hoc-point" && !point.passage?.id;
    const holdedPoint = point.color === "red";
    return (!adHocWNoPassage && !holdedPoint);
  }).map(
    (point, index) => {
      if (
        (point.type === "ad-hoc-point" && !point.passage?.id) ||
        !point.passage?.id
      ){
        return undefined;
      }
      const speeds = (formatMps({
        distance: index === 0 ? run.predicted_launch_speed : point.speed,
        unit: speedUnit,
        returnNumber: true,
      }) || undefined) as number | undefined;
      return (speeds)
    }
  );

  return speeds || [];
};

const getElevationsCanvas = (
  points: TrackingPointCascadeType[],
  distanceUnit: Unit | undefined,
) => {
  const elevationstoChart = points.filter((point) => {
    const adHocWNoPassage = point.type === "ad-hoc-point" && !point.passage?.id;
    const holdedPoint = !point.active;
    return (!adHocWNoPassage && !holdedPoint);
  }).map((point) => {
    return point.elevation ? formatDistance({
      distance: point.elevation,
      unit: distanceUnit?.id || 'ft',
      returnNumber: true,
    }): undefined;
  });

  return elevationstoChart;
};

const getInclinationsCanvas = (points: TrackingPointCascadeType[]) => {
  const inclinationstoChart = points.filter((point) => {
    const adHocWNoPassage = point.type === "ad-hoc-point" && !point.passage?.id;
    const holdedPoint = !point.active;
    return (!adHocWNoPassage && !holdedPoint);
  }).map((point) => {
    return point.inclination ? point.inclination : undefined;
  });
  return inclinationstoChart;
};

export interface CanvasObj {
  speedChart: Chart;
  elevationChart: Chart;
  inclinationChart: Chart;
}

export const setCanvasPlot = (
  distanceUnit: SettingsContext['distanceUnit'],
  speedUnit: SettingsContext['speedUnit'],
  canvas: CanvasObj | undefined,
  setCanvas: (data: any) => void,
  run: RunCascadeType,
  speeds: (string | number)[],
) => {
  if (!document || !run) return;
  if (canvas?.speedChart?.destroy) canvas.speedChart.destroy();
  if (canvas?.elevationChart?.destroy) canvas.elevationChart.destroy();
  if (canvas?.inclinationChart?.destroy) canvas.inclinationChart.destroy();

  const speedChart: any = document.getElementById('report-speed-chart');
  const elevationChart: any = document.getElementById('report-elevation-chart');
  const inclinationChart: any = document.getElementById(
    'report-inclination-chart'
  );
  if (!speedChart || !elevationChart || !inclinationChart) return;
  const speedCtx = speedChart?.getContext('2d');
  const elevationCtx = elevationChart?.getContext('2d');
  const inclinationCtx = inclinationChart?.getContext('2d');

  Chart.register(...registerables);

  const points = run.trackingpoint_set
  const distances = points?.filter((point) => {
    const adHocWNoPassage = point.type === "ad-hoc-point" && !point.passage?.id;
    const holdedPoint = point.color === "red";
    return (!adHocWNoPassage && !holdedPoint);
  }).map((point) => {
    return point?.name?.slice(0, 10)
  }) || [];

  const speedsDataset: any[] = [];

  const elevations = getElevationsCanvas(points || [], distanceUnit);
  const elevationsDataset: any[] = [];

  const inclinations = getInclinationsCanvas(points || []);
  const inclinationsDataset: any[] = [];

  speedsDataset.push({
    label: `Speed (${speedUnit?.label})`,
    backgroundColor: 'rgb(255, 99, 132)',
    borderColor: 'rgb(255, 99, 132)',
    data: speeds.map((speed) => {
      return +speed > 0 || +speed == 0 ? +speed : undefined
    	}),
    tension: 0.1,
    });

  elevationsDataset.push({
    label: `Elevation (${distanceUnit?.label})`,
    backgroundColor: 'rgb(0, 99, 132)',
    borderColor: 'rgb(0, 99, 132)',
    data: [...elevations],
    animation: {
      duration: 0,
    },
  });

  inclinationsDataset.push({
    label: `Inclination (°)`,
    backgroundColor: 'rgb(127, 255, 0)',
    borderColor: 'rgb(127, 255, 0)',
    data: [...inclinations],
    animation: {
      duration: 0,
    },
  });

  const speedsChart = new Chart(speedCtx, {
    type: 'line',
    data: {
      labels: [...distances],
      datasets: speedsDataset,
    },
  });

  const elevationsChart = new Chart(elevationCtx, {
    type: 'line',
    data: {
      labels: [...distances],
      datasets: elevationsDataset,
    },
  });

  const inclinationsChart = new Chart(inclinationCtx, {
    type: 'line',
    data: {
      labels: [...distances],
      datasets: inclinationsDataset,
    },
    options: {},
  });

  setCanvas({
    speedChart: speedsChart,
    elevationChart: elevationsChart,
    inclinationChart: inclinationsChart,
  });

  return ({
    speedChart: speedsChart,
    elevationChart: elevationsChart,
    inclinationChart: inclinationsChart,
  });
};

/**
 * This function map tracking points to table rows
 */
export const mapRows = (
  run: RunCascadeType,
  settingsState: SettingsContext,
) => {
  return run.trackingpoint_set?.map((point, index) => {
      if (!run || !point) return {};
      const passage = point.passage;
      const time = formatTimezoneDate({
        date: passage?.tstamp,
        timezone: settingsState.timezone?.id,
        format: 'HH:mm:ss.SSS',
      });
      const date = formatTimezoneDate({
        date: passage?.tstamp,
        timezone: settingsState.timezone?.id,
        format: 'YYYY-MM-DD',
      });

      const previousPoint = index - 1 >= 0 ? run.trackingpoint_set?.[index - 1] : null;
      const previousDistance = previousPoint
        ? point.distance - previousPoint.distance
        : 0;

      return {
        launched: run.launched,
        is_finished: run.is_finished,
        status: run.status,
        id: point.id,
        runId: run.id,
        passage: run.launched && passage?.tstamp,
        passageId: run.launched && passage?.id,
        passageDate: run.launched && date,
        passageTime: run.launched && time,
        speed: run.launched && point?.speed,
        speedDelta: run.launched && point?.speed_delta,
        longitude: point?.geometry.coordinates[0],
        latitude: point?.geometry.coordinates[1],
        deviceSN: point?.device_sn,
        tract: point?.tract,
        country: point?.country,
        state: point?.state,
        location: `${(+point?.geometry.coordinates[1]).toFixed(6)},${(+point?.geometry
          .coordinates[0]).toFixed(6)}`,
        locationDescription: point?.location_description,
        pressureDifferential: point?.pressure_differential,
        rawFootage: point?.raw_footage,
        stationingNumber: point?.station_number,
        depthOfCover: point?.depth_of_cover,
        alignmentSheet: point?.alignment_sheet,
        eta: run.launched && point?.eta,
        distance: run.launched && point?.distance,
        ete: passage ? passage?.tstamp : point?.eta,
        name: point?.name,
        index: index + 1,
        timezone: settingsState.timezone,
        milepost: point?.milepost,
        comment: point?.comment || '',
        description: point?.description || '',
        speedUnit: settingsState.speedUnit,
        previousDistance,
        distanceUnit: settingsState.distanceUnit,
      };
    }
  );
};
