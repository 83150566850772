import { useCallback, useEffect, useState, useMemo, DragEvent } from 'react';
import { ProjectSimpleType } from "app/modules/customer/customer.interfaces";
import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import Typograph from "stories/type/typograph/typograph";
import {
  ProjectDevicesList,
  ProjectDevicesContainer,
} from './project-devices.style';
import Checkbox from "stories/base/forms/checkbox/checkbox";
import Toltip from 'app/components/toltip/toltip';

interface Props {
  project?: ProjectSimpleType;
  devices?: QubeDeviceType[];
  selecteds: number[];
  selectedCustomerDevices: QubeDeviceType[];
  disabled: boolean;
  search: string;
  deviceDragging: QubeDeviceType | undefined;
  onDragLeaveDevices: () => void;
  onAssignDevices: () => void;
  onRemoveDevice: (deviceId: number) => void;
  onSelectDevice: (deviceId: number) => void;
  onDropDevice: (event: DragEvent<HTMLDivElement>) => void;
}

/**
 * 
 * @returns 
 */
const ProjectDevices = (props: Props) => {
    const [opened, setOpened] = useState(false);
    const [deviceDragOver, setDeviceDragOver] = useState<QubeDeviceType>();
    const [dragTimeout, setDragTimeout] = useState<number>();
    const isThisProject = useMemo(() => {
      if (!deviceDragOver) return false;
      return props.devices?.findIndex((device) => device.id === deviceDragOver.id) !== -1;
    }, [deviceDragOver, props.devices]);
    
    const devicesDic = useMemo(() => {
      const dic = {};
      for (const device of props.devices || []) {
        dic[device.id] = device;
      }
      return dic;
    }, [props.devices]);

    // highlight text
    const highlight = useCallback((text: string) => {
      if (props.search) {
        const regex = new RegExp('(' + props.search + ')', 'gi');
        return text.split(regex).map((part, index) => 
          regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
        );
      }

      return text;
    }, [props.search]);

  useEffect(() => {
    if (props.search || deviceDragOver) setOpened(true);
  }, [props.search, deviceDragOver]);

  return (
    <ProjectDevicesContainer
      onDrop={(event) => {
        props.onDropDevice(event);
        setDeviceDragOver(undefined);
        setOpened(true);
      }}
      onDragOver={(event) => {
        if (!props.deviceDragging) return;
        event.preventDefault();

        if (dragTimeout) clearTimeout(dragTimeout);
        setDeviceDragOver(props.deviceDragging);
        return false;
      }}
      onDragLeave={(event) => {
        event.preventDefault();

        if (dragTimeout) clearTimeout(dragTimeout);            
        
        const timeout = setTimeout(() => {
          setDeviceDragOver(undefined)
          props.onDragLeaveDevices()
        }, 100);
        
        setDragTimeout(timeout as unknown as number);
      }}
    >
      <header onClick={() => setOpened(!opened)}>
        <Typograph type="subtitle2">
          {highlight(props.project?.name || '')} ({props.devices?.length || 0})
        </Typograph>
        <span className="material-symbols-outlined">{opened ? 'arrow_drop_down' : 'arrow_drop_up'}</span>
      </header>

      <ProjectDevicesList opened={opened} disabled={props.disabled}>
        {props.devices?.map((device) => (
          <li key={device.serial} className={props.selectedCustomerDevices.length < 2 && device.id === deviceDragOver?.id ? 'text_highlight' : ''}>
            <Checkbox checked={props.selecteds.includes(device.id)} onClick={() => props.onSelectDevice(device.id)}>
              {highlight(device.serial)}
            </Checkbox>
            <span className="material-symbols-outlined" onClick={() => props.onRemoveDevice(device.id)}>close</span>
          </li>
        ))}

        {
          props.selectedCustomerDevices.length < 2 && deviceDragOver && !isThisProject ? (
            <li className="drag-over">
              <Checkbox checked={false} onClick={() => {}}>
                {highlight(deviceDragOver.serial)}
              </Checkbox>
            </li>
          ) : null
        }
        
        {
          props.selectedCustomerDevices.length > 1 && deviceDragOver ? (
            props.selectedCustomerDevices.map((device) => {
              if (devicesDic[device.id]) return null;
              return (
                <li key={device.serial} className="drag-over">
                  <Checkbox checked={false} onClick={() => {}}>
                    {highlight(device.serial)}
                  </Checkbox>
                </li>
              );
            })
          ) : null
        }

          
        <li className="add-device">
          <Toltip text="Select device to add" active={false} hover={props.disabled}>
            <button disabled={props.disabled} onClick={props.onAssignDevices}>
              <span className="material-symbols-outlined">add</span>
              <Typograph type="caption" text="Add device to project" />
            </button>
          </Toltip>
        </li>
      </ProjectDevicesList>
    </ProjectDevicesContainer>
  );
};

export default ProjectDevices;
