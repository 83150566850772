import { useState } from 'react';
import {
  MobileDataEditorInput,
  MobileDataEditorText,
  MobileDataEditorWrapper,
} from './mobile-data-editor.style';

interface Props {
  value: string;
  isObserver: boolean;
  onChange: (value: string) => void;
}

/**
 *
 * @returns
 */
const MobileDataEditor = (props: Props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.value);

  return (
    <MobileDataEditorWrapper>
      <MobileDataEditorText
        isObserver={props.isObserver}
        onClick={() => (props.isObserver ? undefined : setEditing(true))}
      >
        {props.value || '-'}
      </MobileDataEditorText>

      {editing ? (
        <MobileDataEditorInput
          autoFocus
          value={value}
          disabled={!editing}
          onBlur={() => {
            setEditing(false);
            if (props.value !== value) props.onChange(value);
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      ) : null}
    </MobileDataEditorWrapper>
  );
};

export default MobileDataEditor;
