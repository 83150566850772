import { DeviceTable, DeviceTableEmpty } from './customer-devices-table.style';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import Checkbox from 'app/components/checkbox/checkbox';

interface Props {
  devices: QubeDeviceType[];
  dragOverDevice?: QubeDeviceType | undefined;
  dragDevices: QubeDeviceType[];
  selectedDevices: string[];
  setSelectedDevices: (ids: string[]) => void;
  setSelectedDevicesToRemoveDevices: (ids: string[]) => void;
  draggable?: boolean;
  onDrag?: (device: QubeDeviceType) => void;
  setDeviceToShow?: (device: QubeDeviceType) => void;
}

/**
 * 
 * @param props 
 * @returns 
 */
const CustomerDevicesTable = (props: Props) => {
  /**
   * 
   * @returns 
   */
  const getGreatherSelected = (until: number) => {
    let greatherIndex = -1;

    for (const serial of props.selectedDevices) {
        const index = props.devices.slice(0, until).findIndex((device) => device.serial === serial);
        if (index !== -1 && index > greatherIndex) {
            greatherIndex = index;
        }
    }

    return greatherIndex;
  };

  /**
   *
   * @param event
   * @param device
   */
  const dragstartHandler = (event, device: QubeDeviceType) => {
    event.dataTransfer.setData("text", event.target.id);
    event.dataTransfer.dropEffect = "move";
    props.onDrag?.(device);
  };

  /**
   * 
   * @param device 
   */
  const handleDefaultClick = (device: QubeDeviceType) => {
    const selectedDevices = [...props.selectedDevices];
    const index = selectedDevices.indexOf(device.serial);

    if (index === -1) {
      selectedDevices.push(device.serial);
      props.setSelectedDevices(selectedDevices);
    } else {
      selectedDevices.splice(index, 1);
      props.setSelectedDevices(selectedDevices);
    }

    props.setSelectedDevicesToRemoveDevices([])
  } 
  
  const renderDevices = (devices: QubeDeviceType[]) => devices.map((device, index) => {
    return (
      <tr
        draggable={props.draggable}
        onDragStart={(event) => dragstartHandler(event, device)}
        id={device.serial}
        key={device.serial}
      >
        <td>
          <Checkbox
            customStyle="padding: 0 12px;"
            checked={props.selectedDevices.indexOf(device.serial) !== -1}
            onShiftSlick={() => {
              const selectedDevices = [...props.selectedDevices];
              if (!selectedDevices.length) {
                handleDefaultClick(device);
                return;
              }

              if (props.selectedDevices.indexOf(device.serial) === -1) {
                const greatherSelected = getGreatherSelected(index);
                const toSelectList = props.devices.slice(greatherSelected + 1, index).map(({ serial }) => serial);
                selectedDevices.push(...toSelectList, device.serial);
                props.setSelectedDevices(selectedDevices);
                props.setSelectedDevicesToRemoveDevices([])
              }
            }}
            onClick={() => {
              handleDefaultClick(device);
            }}
          />
        </td>
        <td onClick={() => props.setDeviceToShow && props.setDeviceToShow(device)}><span>{device.serial}</span></td>
        <td>QUBE</td>
        <td>{device.project_name || '-'}</td>
      </tr>
    );
  });
  
  return (
    <>
      <DeviceTable>
        <tr>
          <th style={{ width: '15%'}}>
            <Checkbox
              customStyle="padding: 0 12px;"
              checked={props.selectedDevices.length && props.selectedDevices.length === props.devices.length}
              onClick={() => {
                if (props.selectedDevices.length !== props.devices.length) {
                  const allSerials = props.devices.map((device) => device.serial);
                  props.setSelectedDevices(allSerials);
                  props.setSelectedDevicesToRemoveDevices([]);
                } else {
                  props.setSelectedDevices([]);
                  props.setSelectedDevicesToRemoveDevices([]);
                }
              }}
            />
          </th>
          <th style={{ width: '30%'}}>Device name</th>
          <th style={{ width: '25%'}}>Type</th>
          <th style={{ width: '30%'}}>Project</th>
        </tr>
        
        {
          props.dragOverDevice && props.dragDevices.findIndex(({ serial }) => serial === props.dragOverDevice?.serial) === -1 ? (
            <tr
              draggable={props.draggable}
              onDragStart={() => {}}
              id={props.dragOverDevice.serial}
              key={props.dragOverDevice.serial}
            >
              <td>
                <Checkbox
                  customStyle="padding: 0 12px;"
                  checked={false}
                  onShiftSlick={() => {}}
                  onClick={() => {}}
                />
              </td>
              <td><span>{props.dragOverDevice.serial}</span></td>
              <td>QUBE</td>
              <td>{props.dragOverDevice.project_name || '-'}</td>
            </tr>
          ) : null
        }

        {renderDevices(props.dragDevices)}  
        {renderDevices(props.devices)}  
      </DeviceTable>
      {!props.devices.length && !props.dragOverDevice ? (
        <DeviceTableEmpty>No devices.</DeviceTableEmpty>
      ): null}
    </>
  );
};

export default CustomerDevicesTable;
