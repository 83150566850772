import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
// context
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
// interfaces
import { AuthType } from 'app/modules/account/account.context.d';
import Breadcrumbs, { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
import { RunType } from 'app/interfaces/inspection.interfaces';
import CalendarContent from './contents/calendar/calendar-content';
import NavigationContainer from 'app/components/navigation-container/navigation-container';
import { init, loadMore } from './calendar.controller';
import { CalendarContainer } from './calendar.page.style';
import { RunsDictType } from './calendar.page.d';

/**
 *
 * @returns
 */
const Calendar = () => {
  // router
  const history = useHistory();
  // contexts
  const settingsContext = useContext(SettingsStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  // states
  const [month, setMonth] = useState<number>(moment().month() + 1);
  const [year, setYear] = useState<number>(moment().year());
  const [runs, setRuns] = useState<RunType[]>([]);
  const [runsDict, setRunsDict] = useState<RunsDictType>({});
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbs: Breadcrumb[] = [
    {
      id: '#2',
      text: 'Projects page',
      loading,
      href: '',
    },
  ];

  useEffect(() => {
    if (!auth?.token) return history.push('/');
    init({
      setLoading,
      setRunsDict,
      auth,
      month,
      year,
    });
  }, [auth.token]);

  useEffect(() => {
    if (!Object.keys(runsDict).length ) return;
    loadMore({
      runsDict,
      setRunsDict,
      month,
      year,
      auth,
    });
  }, [month, year]);

  useEffect(() => {
    const cachedList: RunType[] = [];
    Object.keys(runsDict).map((key) => {
      if (runsDict[key]) cachedList.push(...runsDict[key]);
    });

    const list = _.uniqBy(cachedList, 'id');
    setRuns(list);
  }, [runsDict]);

  return (
    <NavigationContainer>
      <CalendarContainer>
        <Breadcrumbs routes={breadcrumbs} />
        <CalendarContent
          loading={loading}
          runs={runs}
          getMonth={setMonth}
          getYear={setYear}
          weatherUnit={settingsContext.state.weatherUnit?.id}
          navigateTo={history.push}
        />
      </CalendarContainer>
    </NavigationContainer>
  );
};

export default Calendar;
