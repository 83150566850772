import styled from 'styled-components';

export const Modal = styled.aside`
  width: 100%;
  min-height: calc(100vh - 40px);
  background-color: #ffffff;

  @media (min-width: 1024px) {
    min-width: 350px;
    max-width: 600px;
    min-height: auto;
    border-radius: 6px;
  }
`;

export const Header = styled.header`
  position: relative;
`;

export const AGMTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #161e2e;

  margin-bottom: 4px;
`;

export const DeviceSerialNumber = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #161e2e;
`;

export const TriggersAmount = styled.p`
  text-align: right;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #161e2e;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 13.5px;

  background-color: transparent;
  cursor: pointer;
`;

export const Content = styled.div`
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;

  max-height: 465px;

  &:after {
    content: '';
    display: block;
    height: 20px;
  }

  position: static;
  overflow-y: auto;

  @media (min-width: 1024px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
