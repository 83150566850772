import DefaultButton from 'app/components/default-button/default-button';
import { ChangeEvent, useMemo, useState } from 'react';
import { countries } from './phone-input.model';
import { 
  CountryCodeDropdown,
  CountryCodeInput,
  CountryCodeList,
  CountryCodeSearchInput,
  Input,
  InputWrapper,
  SelectedCountry,
} from './phone-input.style';

interface Props {
  onCountryChange: (country: string, countryCallingCode: string) => void;
  onPhoneChange: (e: ChangeEvent<HTMLInputElement>) => void;
  phone?: string;
  testid?: string;
  country: string;
}

/**
 *
 * @returns
 */
const PhoneInput = ({
  onCountryChange,
  onPhoneChange,
  phone,
  country,
  testid,
}: Props) => {
  const [countryCodeSearch, setCountryCodeSearch] = useState<string>('');
  const [countryCodeListOpened, setCountryCodeListOpened] = useState<boolean>(false);
  const filteredCountries = useMemo(() => {
    if (!countryCodeSearch) return countries;
    const filterExp = new RegExp(`${countryCodeSearch}`, 'gi');
    return countries.filter((c) => filterExp.test(c.countryCallingCode) ||
                                         filterExp.test(c.countryCode) ||
                                         filterExp.test(c.countryNameEn) ||
                                         filterExp.test(c.countryNameLocal));
  }, [countryCodeSearch]);
  const selectedCountry = useMemo(() => {
    return countries.find((c) => c.countryCode === country);
  }, [country]);

  /**
   *
   * @returns
   */
  const renderOptions = () =>
    filteredCountries.map((country) => (
      <li
        key={country.countryCode}
        className="gt_phone_input"
        onClick={() => {
          onCountryChange(country.countryCallingCode, country.countryCode);
          setCountryCodeListOpened(false);
          setCountryCodeSearch('');
        }}
      >
        <figure>
          <img src={`/assets/icons/country-flags/${country.countryCode.toLowerCase()}.svg`} />
        </figure> 
        
        <span>
          {country.countryNameEn}
        </span>
        
        <strong>
          +{country.countryCallingCode}
        </strong>
      </li>
    ));

  return (
    <InputWrapper>
      <CountryCodeInput
        tabIndex={1}
        data-testid={testid || 'phone-input'}
        onBlur={(e) => {
          if (e.relatedTarget?.classList.contains('gt_phone_input')) return;
          if (e.target?.classList.contains('gt_phone_input')) return;
          setCountryCodeListOpened(false)
        }}
      >
        <SelectedCountry onClick={() => setCountryCodeListOpened(!countryCodeListOpened)}>
          + {selectedCountry?.countryCallingCode}
        </SelectedCountry>
        <CountryCodeDropdown
          open={countryCodeListOpened}
          className="gt_phone_input"
        >
          <CountryCodeSearchInput
            type="text"
            value={countryCodeSearch}
            placeholder="Search Country Code"
            className="gt_phone_input"
            onChange={(e) => {
              setCountryCodeSearch(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />

          <CountryCodeList>
            {renderOptions()}
          </CountryCodeList>

          <DefaultButton
            onClick={() => {
              setCountryCodeListOpened(false)
              setCountryCodeSearch('');
            }}
          >
            Cancel
          </DefaultButton>
        </CountryCodeDropdown>
      </CountryCodeInput> 
      <Input value={phone} type="tel" onChange={onPhoneChange} />
    </InputWrapper>
  );
};

export default PhoneInput;
