import Avatar from '../../../avatar/avatar';
import { Card } from './predict-user-card.style';

interface Props {
  avatar?: string;
  customStyle?: string;
  email: string;
  first_name: string;
  last_name: string;
  onClick: () => void;
}

const PredictUserCard = ({
  avatar,
  email,
  first_name,
  last_name,
  onClick,
  customStyle,
}: Props) => (
  <Card customStyle={customStyle} onClick={onClick}>
    <Avatar
      src={avatar}
      email={email}
      firstName={first_name}
      lastName={last_name}
      size={28}
    />
    <data>{`${first_name} ${last_name}`}</data>
    <span>{email}</span>
  </Card>
);

export default PredictUserCard;
