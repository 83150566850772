import { useState, useEffect, JSXElementConstructor } from 'react';
import moment, { Moment } from 'moment';
import {
  Calendar as BigCalendar,
  Event,
  momentLocalizer,
  Views,
} from 'react-big-calendar';
import { Props } from './calendar-content.d';
import {
  CalendarActions,
  CalendarControl,
  CalendarTypeControl,
  CalendarTodayButton,
  CalendarHeader,
  CalendarNavigation,
  CalendarTitle,
  ProjectContent,
  EventCard,
  EventRunName,
  WeatherInformation,
  Temperature,
} from './calendar-content.style';

const CalendarComponent = BigCalendar as unknown as JSXElementConstructor<any>;

const localizer = momentLocalizer(moment);
const MONTH = Views.MONTH;
const WEEK = Views.WEEK;

/**
 *
 * @param
 * @returns
 */
const CalendarContent = ({ runs, getMonth, getYear, navigateTo, weatherUnit }: Props) => {
  const [date, setDate] = useState<Moment>(moment());
  const [view, setView] = useState<typeof MONTH | typeof WEEK>(MONTH);
  const [events, setEvents] = useState<Event[]>([]);

  const getEvents = (wheater: string) =>
    runs.map(
      ({ project, calendar_start, calendar_end, name, forecast, id }) => ({
        resource: id,
        start: calendar_start,
        end: calendar_end,
        title: (
          <EventCard title={`${project?.name} - ${name}`} key={id} data-testid={`run/${id}`}>
            {forecast ? (
              <WeatherInformation>
                <img
                  alt=""
                  src={`/assets/icons/weather/${forecast?.icon || 'sunny.png'}`}
                />
                <Temperature>
                  {parseInt(
                    wheater === 'farenheit'
                      ? forecast.feelslikeF
                      : forecast.feelslikeC,
                    10
                  )}
                  °{wheater === 'farenheit' ? 'F' : 'C'}
                </Temperature>
              </WeatherInformation>
            ) : null}
            <EventRunName>{`${project?.name} - ${name}`}</EventRunName>
          </EventCard>
        ),
      })
    );

  useEffect(() => {
    if (getMonth) getMonth(date.month() + 1);
    if (getYear) getYear(date.year());
  }, [date]);

  useEffect(() => {
    const newEvents = getEvents(weatherUnit || 'fahrenheit');
    setEvents(newEvents);
  }, [weatherUnit, runs]);

  const weekStart = date.clone().startOf('week');
  const weekEnd = date.clone().endOf('week');

  return (
    <ProjectContent month={view === MONTH} id="bigcalendar">
      <CalendarHeader>
        <CalendarTypeControl>
          <CalendarControl
            selected={MONTH === view}
            onClick={() => setView(MONTH)}
          >
            Month
          </CalendarControl>
        </CalendarTypeControl>

        <CalendarNavigation>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              if (view === WEEK) {
                const newDate = date.clone();
                newDate.add(-1, 'weeks');
                setDate(newDate);
              }

              if (view === MONTH) {
                const newDate = date.clone();
                newDate.add(-1, 'months');
                setDate(newDate);
              }
            }}
          >
            <rect
              x="5.66016"
              y="0.00195312"
              width="2"
              height="7.99949"
              rx="1"
              transform="rotate(45 5.66016 0.00195312)"
              fill="#8C929D"
            />
            <rect
              x="7.07129"
              y="9.90137"
              width="2"
              height="8.00087"
              rx="1"
              transform="rotate(135 7.07129 9.90137)"
              fill="#8C929D"
            />
          </svg>

          <CalendarTitle>
            {view === Views.MONTH
              ? date.format('MMMM YYYY')
              : `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')}`}
          </CalendarTitle>

          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              if (view === WEEK) {
                const newDate = date.clone();
                newDate.add(1, 'weeks');
                setDate(newDate);
              }

              if (view === MONTH) {
                const newDate = date.clone();
                newDate.add(1, 'months');
                setDate(newDate);
              }
            }}
          >
            <rect
              x="5.65625"
              y="11.3145"
              width="2"
              height="7.99949"
              rx="1"
              transform="rotate(-135 5.65625 11.3145)"
              fill="#8C929D"
            />
            <rect
              x="4.24512"
              y="1.41504"
              width="2"
              height="8.00087"
              rx="1"
              transform="rotate(-45 4.24512 1.41504)"
              fill="#8C929D"
            />
          </svg>
        </CalendarNavigation>

        <CalendarActions>
          <CalendarTodayButton onClick={() => setDate(moment())}>
            Today
          </CalendarTodayButton>
        </CalendarActions>
      </CalendarHeader>

      <CalendarComponent
        formats={{
          timeGutterFormat: (date, culture, l) =>
            l
              ? l.format(date, 'HH:mm', culture)
              : moment(date).format('HH:mm '),
        }}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultView={Views.MONTH}
        views={[Views.MONTH, Views.WEEK]}
        toolbar={false}
        date={date.toDate()}
        view={view}
        onView={() => null}
        onNavigate={() => null}
        onSelectEvent={(event) => {
          navigateTo(`/runs/${event.resource}`);
        }}
        showAllEvents
      />
    </ProjectContent>
  );
};

export default CalendarContent;
