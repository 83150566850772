import InspectionStore from 'app/modules/inspection/inspection.context';
import { TimePinType } from 'app/modules/inspection/inspection.interfaces.d';
import { useContext, useEffect, useState } from 'react';
import { createMarkerElement, renderMarker } from './time-pin-marker.controller';
import { getMarkerPosition } from 'app/utils/map.utils';
import { TimePinGlobalStyle } from './time-pin-marker.style';

interface Props {
  timePin: TimePinType;
  hasLabels: boolean;
  map: google.maps.Map;
}

const TimePinMarker = ({ timePin, map, hasLabels }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();

  useEffect(() => {
    let promisse;
    if (timePin._loaded) {
      promisse = renderMarker(inspectionContext, timePin, map, setMarker, marker);
    }
    return () => {
      promisse?.then((marker) => {
        if (marker) {
          marker.map = null;
        }
      });
    }
  }, [timePin._loaded, timePin.title]);

  useEffect(() => {
    if (marker) {
      marker.content = createMarkerElement(timePin, inspectionContext.dispatch);
    }
  }, [
    timePin?.title,
    timePin?.tstamp,
  ]);

  useEffect(() => {
    const coords = timePin?.location?.coordinates;
    if (coords && marker) {
      marker.position = getMarkerPosition(coords)
    }
  }, [timePin?.location, marker]);

  useEffect(() => {
    if (marker) {
      const label = marker.getElementsByTagName('label')[0];
      if (label) {
        label.innerText = timePin.title || '';
      }
    }
  }, [timePin.title, marker]);

  useEffect(() => {
    if (hasLabels && marker) {
      const label = marker.getElementsByTagName('label')[0];
      label?.classList.remove("display-none");
    }
    if (!hasLabels && marker) {
      const label = marker.getElementsByTagName('label')[0];
      label?.classList.add("display-none");
    }
  }, [hasLabels, marker]);

  return <TimePinGlobalStyle />;
};

export default TimePinMarker;