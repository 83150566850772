import { createProjectInvitation } from '../../services/invitation.service';
import { getUserPredict } from '../../services/user.service';
import { RoleType } from './invitation-modal.d';

export const getPredictUsers = async (
  email: string,
  token: string,
  setPredictedUsers: (users: any[]) => void
) => {
  const predictResponse = await getUserPredict(email, token);
  setPredictedUsers(predictResponse.data.users);
};

export const sendInvitations = async (
  emails: string[],
  selectedRole: RoleType,
  projectId: number,
  token: string
) => {
  const invitation = {
    emails,
    editor: selectedRole.editor,
    admin: selectedRole.admin,
    projects: [projectId],
  };

  await createProjectInvitation(invitation, token);
};
