import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import { ProjectsContext } from "../projects.context.d";

export const updateQube = (state: ProjectsContext, qube: QubeDeviceType) => {
  const stateCopy = { ...state };
  const qubesDic = { ...stateCopy.qubes_dic }

  qubesDic[qube.serial] = qube;

  stateCopy.qubes_dic = qubesDic;

  return stateCopy;
}