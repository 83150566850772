import styled from 'styled-components';

export const ScheduleDropdownContainer = styled.div<{ $open: boolean }>`
  position: absolute;
  top: calc(100% + 15px);
  left: 0;

  width: 420px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;
  z-index: 25;

  overflow-y: auto;

  > li {
    cursor: pointer;
    padding: 10px 20px;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.neutra_100};
    }
  }

  > li + li {
    border-top: solid 1px #dcdfe2;
  }
`;

export const ScheduleDropdownHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.surface_white};
  border-radius: 6px 6px 0 0;

  padding: 8px 16px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.outline};

  > span.material-icons-outlined {
    color: ${({ theme }) => theme.colors.surface_on_high};
    font-size: 17px;
    font-weight: 900;
    cursor: pointer;
  }
`;

export const ScheduleDropdownForm = styled.form`
  padding: 16px;
`;

export const ScheduleDropdownFormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  & + & {
    margin-top: 24px;
  }
`;
