import {
  extractNumbers,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import SettingsStore from 'app/modules/settings/settings.context';
import { useState, useContext, useEffect } from 'react';
import InputGroup from '../../../input-group/inputGroup';
import Input from '../../../input/input';
import { NewRunIlustration } from '../../svgs/newRun.icons';
import {
  Title,
  Explanation,
  Form,
  FormWrapper,
  IlustrationWrapper,
  Container,
  Row,
  SelectInput,
} from './firstStep.style';
import ButtonComponent from '../../../button/button';
import InputDate from '../../../input-date/input-date';
import VMasker from 'vanilla-masker';
import Checkbox from '../../../checkbox/checkbox';

const FirstStep = ({ onSubmit, errors, isLoading, run }: any) => {
  const settingsContext = useContext(SettingsStore);
  const [name, setName] = useState(run?.name);
  const [type, setType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [predictedSpeed, setPredictedSpeed] = useState('');
  const [sms, setSMS] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);
  const [types] = useState([
    {
      id: 'smart',
      label: 'Smart',
    },
    {
      id: 'geometry',
      label: 'Geometry',
    },
    {
      id: 'cleaning',
      label: 'Cleaning',
    },
  ]);

  const cleanFields = () => {
    setName('');
    setType('');
    setStartDate('');
    setStartTime('');
    setPredictedSpeed('');
    setSMS(false);
    setEmail(false);
  };

  useEffect(() => {
    const date = formatTimezoneDate({
      date: run?.scheduled_launch_at,
      timezone: settingsContext.state.timezone?.id || '',
      format: 'YYYY/MM/DD',
    });
    const time = formatTimezoneDate({
      date: run?.scheduled_launch_at,
      timezone: settingsContext.state.timezone?.id || '',
      format: 'HH:mm:ss',
    });

    setName(run?.name);
    setType(run?.run_type);
    setEmail(!!run?.email);
    setSMS(!!run?.sms);
    setType(run?.run_type);
    setStartDate(date);
    setStartTime(time);
    setPredictedSpeed(
      formatMps({
        distance: run?.predicted_launch_speed,
        unit: settingsContext.state.speedUnit,
        returnNumber: true,
      }) as string
    );
    return () => {
      cleanFields();
    };
  }, [
    run?.scheduled_launch_at,
    run?.name,
    run?.run_type,
    run?.predicted_launch_speed,
    run?.sms,
    run?.email,
  ]);

  return (
    <Container>
      <FormWrapper>
        <Form>
          <Title>Edit Run</Title>
          <Row>
            <InputGroup error={errors?.name} label="Run Name">
              <Input
                value={name}
                handleChange={({ target }) => setName(target.value)}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup error={errors?.startDate} label="Run start date">
              <InputDate
                handleChange={({ value }) =>
                  setStartDate(
                    formatTimezoneDate({
                      date: value,
                      timezone: settingsContext.state.timezone?.id || '',
                      format: 'YYYY/MM/DD',
                    })
                  )
                }
                value={startDate}
                id="startDate"
                placeholder="YYYY/MM/DD'"
              />
            </InputGroup>
            <InputGroup error={errors?.startTime} label="Run start time">
              <Input
                placeholder="HH:mm:ss"
                value={VMasker.toPattern(`${startTime}`, '99:99:99.9999')}
                handleChange={(e) =>
                  setStartTime(extractNumbers(e.target.value))
                }
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              error={errors?.predictedSpeed}
              label={`Predicted Launch Speed (${settingsContext.state.speedUnit?.id})`}
            >
              <Input
                value={predictedSpeed}
                handleChange={({ target }) =>
                  setPredictedSpeed(extractNumbers(target.value))
                }
              />
            </InputGroup>
            <InputGroup error={errors?.type} label="Run Type">
              <SelectInput
                value={type}
                onChange={({ target }) => setType(target.value)}
                name="Run Type"
              >
                <option value={''}>-----</option>
                {types.map((t: any) => (
                  <option key={t.id} value={t.id}>
                    {t.label}
                  </option>
                ))}
              </SelectInput>
            </InputGroup>
          </Row>

          <div>
            <InputGroup label="">
              <Checkbox checked={sms} onClick={() => setSMS(!sms)} text="SMS" />
              <Explanation>
                {
                  '(users on this project will be notified when the run launches, passages are registered and when it finishes)'
                }
              </Explanation>
            </InputGroup>
          </div>
          <div>
            <InputGroup label="">
              <Checkbox
                checked={email}
                onClick={() => setEmail(!email)}
                text="Email"
              />
              <Explanation>
                {
                  '(users on this project will be notified when passages are registered)'
                }
              </Explanation>
            </InputGroup>
          </div>

          <Row>
            <ButtonComponent
              customStyle="margin-top: 32px;"
              handleClick={() =>
                onSubmit(
                  {
                    name,
                    type,
                    predictedSpeed,
                    startDate,
                    startTime,
                    email,
                    sms,
                  },
                  cleanFields
                )
              }
              value={isLoading ? 'loading...' : 'Save'}
              disabled={isLoading}
            />
          </Row>
        </Form>
      </FormWrapper>
      <IlustrationWrapper>
        <NewRunIlustration />
      </IlustrationWrapper>
    </Container>
  );
};
FirstStep.defaultProps = {};

export default FirstStep;
