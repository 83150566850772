import { MouseEvent, useContext, useEffect, useState } from 'react';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import { Loader } from '@googlemaps/js-api-loader';
import {
  DeviceCarouselList,
  DeviceCarouselModal,
  DeviceCarouselWrapper,
  DeviceCarouselPrevButton,
  DeviceCarouselNextButton,
  DeviceCarouselModalHeader,
  DeviceCarouselModalContent,
  DeviceCarouselModalAbout,
  DeviceCarouselModalRecentLocation,
  DeviceCarouselModalRecentLocationHeader,
  DeviceCloseButton,
  DeviceContentSectionTitle,
  DeviceTitleWrapper,
  DeviceTitle,
  DeviceStatus,
  DeviceStatusFigure,
  DeviceInfo,
  DeviceInfoList,
  DeviceInfoLabel,
  DeviceInfoValue,
  DeviceMap,
  DeviceCoords,
  DeviceCarouselModalAboutContent,
  DevicePropertiesList,
  DeviceProperty,
  DevicePropertyLabel,
  DevicePropertyStatus,
} from './device-carousel.style';

import {
  ArmDisarmButton,
} from '../arm-disarm-button/arm-disarm-button';

import {
  getUptime,
} from '../device-common.controller';

import Battery from '../battery/battery';
import { QubeDict } from 'app/modules/projects/project/components/devices/devices.d';
import { GOOGLE_MAPS_LOADER_PARAMETERS } from 'app/components/common/map/map.controller';
import { formatTimezoneDate } from '@/lib/formatter';
import SettingsStore from 'app/modules/settings/settings.context';

type DeviceMap = { map: google.maps.Map; marker: google.maps.Marker };
type DeviceMapDict = { [key: string]: DeviceMap };

interface Props {
  devices: QubeDict;
  selected?: QubeDeviceType;
  hideArmButton?: boolean;
  onClose: () => void;
  changeSelectedDevice: (q: QubeDeviceType) => void;
}

// google map settings
const GOOGLE_MAP_ID = '90f559dd3d1ba78f';
const DEFAULT_GOOGLE_MAP_OPTIONS = {
  mapId: GOOGLE_MAP_ID,
  zoom: 13,
  disableDefaultUI: false,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControl: false,
  disableDoubleClickZoom: true,
  scrollwheel: false,
  draggable: false,
};

/**
 *
 * @param props
 */
const DeviceCarousel = (props: Props) => {
  const [devicesMap, setDevicesMap] = useState<DeviceMapDict>({});
  const settingsContext = useContext(SettingsStore);

  /**
   *
   */
  const createMap = async (device: QubeDeviceType) => {
    const elementId = `#GT-DEVICE-MAP-${device.serial}`;
    const element = document.querySelector(elementId) as HTMLElement;
    const loader = new Loader(GOOGLE_MAPS_LOADER_PARAMETERS);

    await loader.load();
    
    const google = window.google;
    const googleMapOptions = {
      ...DEFAULT_GOOGLE_MAP_OPTIONS,
      center: { lat: device.position.coordinates[1], lng: device.position.coordinates[0] },
    };

    const map = new google.maps.Map(element, googleMapOptions);

    const icon = {
      url: '/assets/images/qube-icon.png',
      scaledSize: new window.google.maps.Size(20, 20), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(10, 10), // anchor
    };

    const marker = new window.google.maps.Marker({
      icon,
      title: 'Device',
      opacity: 1,
      zIndex: 10,
      map,
      clickable: false,
      position: { lat: device.position.coordinates[1], lng: device.position.coordinates[0] },
    });

    const nDevicesMap = { ...devicesMap };
    nDevicesMap[device.serial] = { map, marker };
    setDevicesMap(nDevicesMap);
  };

  /**
   *
   */
  const changeMapPosition = (device: QubeDeviceType) => {
    const { map, marker } = devicesMap[device.serial];
    marker.setPosition({ lat: device.position.coordinates[1], lng: device.position.coordinates[0] });
    map.setCenter({ lat: device.position.coordinates[1], lng: device.position.coordinates[0] });
  };

  useEffect(() => {
    Object.keys(props.devices).forEach(async (key) => {
      const device = props.devices[key];
      if (!device.position) return;
      if (devicesMap[key]) changeMapPosition(device);
      else createMap(device);
    });
  }, [props.devices]);

  useEffect(() => {
    if (props.selected) {
      const devicesIds = Object.keys(props.devices);
      const deviceIndex = devicesIds.indexOf(props.selected.serial);

      const listEl = document.querySelector('#device-carousel-list');

      listEl?.scrollTo(0,0);
      listEl?.scrollBy({
        left: 520 * deviceIndex,
        behavior: 'smooth',
      });
    }
  }, [props.selected]);

  /**
   * @returns
   */
  const renderDeviceModals = () =>
    Object.keys(props.devices).map((key) => {
      const device = props.devices[key];

      const getStatus = () => {
        const status = {
          'out-of-service': {
            id: 'out-of-service',
            text: '',
            color: '#8b93a1',
          },
          deployed: {
            id: 'deployed',
            text: `Deployed on Active Run`,
            color: '#8b93a1',
          },
        };

        return status['out-of-service'];
      };

      return (
        <DeviceCarouselModal key={device.serial}>
          <DeviceCarouselModalHeader>
            <DeviceStatusFigure src="/assets/images/qube-icon.png"  />
            <DeviceTitleWrapper>
              <DeviceTitle>{device.serial}</DeviceTitle>
              <DeviceStatus color={getStatus().color}>
                {getStatus().text}
              </DeviceStatus>
            </DeviceTitleWrapper>

            <DeviceCloseButton
              onClick={props.onClose}
              data-testid="device-carousel-button"
            >
              <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="16.9492" y="4.94922" width="2" height="16" transform="rotate(45 16.9492 4.94922)" fill="#99A2AD"/>
                <rect x="18.3633" y="16.2637" width="2" height="16" transform="rotate(135 18.3633 16.2637)" fill="#99A2AD"/>
              </svg>
            </DeviceCloseButton>
          </DeviceCarouselModalHeader>
          <DeviceCarouselModalContent>
            <DeviceCarouselModalAbout>
              <DeviceContentSectionTitle>About</DeviceContentSectionTitle>
              <DeviceCarouselModalAboutContent>
                <DevicePropertiesList>
                  <DeviceProperty>
                    <DevicePropertyStatus checked={device.online} />
                    <DevicePropertyLabel>Online</DevicePropertyLabel>
                  </DeviceProperty>
                  <DeviceProperty>
                    <DevicePropertyStatus checked={device.geolocked} />
                    <DevicePropertyLabel>GeoLocked</DevicePropertyLabel>
                  </DeviceProperty>
                  <DeviceProperty>
                    <DevicePropertyStatus checked={device.armed} />
                    <DevicePropertyLabel>
                      {device.armed ? 'Armed' : 'Disarmed'}
                      {
                        props.hideArmButton ? null : (
                          <ArmDisarmButton
                            mqttId={device.serial}
                            online={device.online}
                            armed={device.armed}
                          />
                        )
                      }
                    </DevicePropertyLabel>
                  </DeviceProperty>
                </DevicePropertiesList>

                <DeviceInfoList>
                  <DeviceInfo>
                    <DeviceInfoLabel>Type</DeviceInfoLabel>
                    <DeviceInfoValue>Qube</DeviceInfoValue>
                  </DeviceInfo>
                  <DeviceInfo>
                    <DeviceInfoLabel>Class</DeviceInfoLabel>
                    <DeviceInfoValue>AGM</DeviceInfoValue>
                  </DeviceInfo>
                  <DeviceInfo>
                    <DeviceInfoLabel>Date Added</DeviceInfoLabel>
                    <DeviceInfoValue>{formatTimezoneDate({
                      date: device.created_at,
                      timezone: settingsContext.state.timezone?.id,
                      format: 'YYYY/MM/DD'
                    })}</DeviceInfoValue>
                  </DeviceInfo>
                  <DeviceInfo>
                    <DeviceInfoLabel>Uptime</DeviceInfoLabel>
                    <DeviceInfoValue>{getUptime(device.uptime_secs)}</DeviceInfoValue>
                  </DeviceInfo>
                  <DeviceInfo>
                    <DeviceInfoLabel>Battery</DeviceInfoLabel>
                    <DeviceInfoValue>
                      <Battery
                        id={device.serial}
                        level={device.battery_level}
                      />
                    </DeviceInfoValue>
                  </DeviceInfo>
                </DeviceInfoList>
              </DeviceCarouselModalAboutContent>
            </DeviceCarouselModalAbout>

            <DeviceCarouselModalRecentLocation>
              <DeviceCarouselModalRecentLocationHeader>
                <DeviceContentSectionTitle>
                  Recent Location
                </DeviceContentSectionTitle>

                {
                  device.position ? (
                    <DeviceCoords>{`${device.position?.coordinates[1]}, ${device.position?.coordinates[0]}`}</DeviceCoords>
                  ) : (
                    <p>Unknown Location</p>
                  )
                }
              </DeviceCarouselModalRecentLocationHeader>

              <DeviceMap id={`GT-DEVICE-MAP-${device.serial}`} />
            </DeviceCarouselModalRecentLocation>
          </DeviceCarouselModalContent>
        </DeviceCarouselModal>
      );
    });

  /**
   *
   */
  const handlePrevClick = (e: MouseEvent) => {
    e.stopPropagation();
    const keys = Object.keys(props.devices);
    const index = keys.indexOf(`${props.selected?.serial}`);

    const device = props.devices[keys[index - 1]];
    if (device) {
      props.changeSelectedDevice(device);
    }

    const listEl = document.querySelector('#device-carousel-list');
    listEl?.scrollBy({
      top: 0,
      left: -520,
      behavior: 'smooth',
    });
  };

  /**
   *
   */
  const handleNextClick = (e: MouseEvent) => {
    e.stopPropagation();
    const keys = Object.keys(props.devices);
    const index = keys.indexOf(`${props.selected?.serial}`);

    const device = props.devices[keys[index + 1]];
    if (device) {
      props.changeSelectedDevice(device);
    }

    const listEl = document.querySelector('#device-carousel-list');
    listEl?.scrollBy({
      top: 0,
      left: 520,
      behavior: 'smooth',
    });
  };

  return (
    <DeviceCarouselWrapper
      hide={!props.selected}
      onClick={props.onClose}
      data-testid="carousel-outside"
    >
      <DeviceCarouselPrevButton
        onClick={handlePrevClick}
        data-testid="device-carousel-prev"
        hide={Object.keys(props.devices).length <= 1}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32px"
          width="32px"
          viewBox="0 0 48 48"
        >
          <path
            d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z"
            fill="#ffffff"
          />
        </svg>
      </DeviceCarouselPrevButton>

      <DeviceCarouselList
        count={Object.keys(props.devices).length}
        onClick={(e) => e.stopPropagation()}
        id="device-carousel-list"
        data-testid="device-carousel-list"
      >
        {renderDeviceModals()}
      </DeviceCarouselList>

      <DeviceCarouselNextButton
        onClick={handleNextClick}
        data-testid="device-carousel-next"
        hide={Object.keys(props.devices).length <= 1}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32px"
          width="32px"
          viewBox="0 0 48 48"
        >
          <path
            d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z"
            fill="#ffffff"
          />
        </svg>
      </DeviceCarouselNextButton>
    </DeviceCarouselWrapper>
  );
};

export default DeviceCarousel;
