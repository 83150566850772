import styled from 'styled-components';

export const Button = styled.div<{ $active: boolean }>`
  background-color: ${({ $active }) =>
    $active ? 'rgba(80, 88, 236, 0.45)' : 'rgba(255, 255, 255, 0.45)'};
  backdrop-filter: blur(10px);
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  width: 34px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  @media (min-width: 1024px) {
    top: 95px;
  }
`;
