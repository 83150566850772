import InspectionStore from "app/modules/inspection/inspection.context";
import { EstimationType } from "app/modules/inspection/inspection.interfaces";
import { useContext, useEffect, useState } from "react";
import { changeYellowPolyline, changeGreenPolyline, renderYellowPolyline, renderGreenPolyline } from "./segment-polyline.controller";

interface Props {
  estimation: EstimationType;
  map: google.maps.Map;
}

const SegmentPolyline = ({ estimation, map }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [green, setGreen] = useState<google.maps.Polyline>();
  const [yellow, setYellow] = useState<google.maps.Polyline>();

  useEffect(() => {
    return () => {
      green?.setMap(null);
      yellow?.setMap(null);
    };
  }, []);

  useEffect(() => {
    if (!green && !yellow) {
      const yellowPoly = renderYellowPolyline(inspectionContext, estimation, map, yellow);
      const greenPoly = renderGreenPolyline(estimation, map, green);
      setYellow(yellowPoly);
      setGreen(greenPoly);
    }
  }, [estimation._loaded]);

  useEffect(() => {
    if (yellow) {
      changeYellowPolyline({ polyline: yellow, estimation, map })
    }
    if (green) {
      changeGreenPolyline({ polyline: green, estimation, map })
    }
  }, [yellow, green, estimation.yellow_segment, estimation.green_segment]);

  return null;
};

export default SegmentPolyline;