import { keyCode } from 'slickgrid';

export const PROPORTION: { [key: string]: number } = {
  m: 1,
  km: 1000,
  ft: 1 / 3.28084,
  mi: 1609.34,
};

class DistanceEditor {
  args: any;
  input: any;
  unit: any;
  unitDisplay: any;
  keyCaptureList: number[];

  constructor(args: any) {
    // When pasting several rows with ctrl+v, args.item is not present
    // distance_unit is a user preference and is equal for all rows,
    // so it's safe to use first row
    this.unit = args.grid.metadata?.units?.distance;


    const inputWrapper = document.createElement('div');
    inputWrapper.className = 'gt-editable-input-with-unit';

    const input = document.createElement('input');
    input.className = 'gt-unit-input';
    input.type = 'text';

    const span = document.createElement('span');
    span.className = 'gt-unit';

    span.innerHTML = this.unit?.id;

    inputWrapper.appendChild(input);
    inputWrapper.appendChild(span);

    args.container.append(inputWrapper);

    this.args = args;
    this.input = input;
    this.unitDisplay = span;
    this.keyCaptureList = [keyCode.LEFT, keyCode.RIGHT];
  }

  validate(): { valid: boolean; msg: string | null } {
    return {
      valid: true,
      msg: null,
    };
  }

  loadValue(context: any): void {
    let defaultValue: number = parseFloat(context[this.args.column.field]) || 0;
    defaultValue /= PROPORTION[this.unit.id];

    this.input.value = defaultValue.toFixed(2);
    this.input.defaultValue = this.input.value;
    this.input.select();
  }

  serializeValue(): number {
    return parseFloat(this.input.value.replace(',', ''));
  }

  applyValue(item: any, state: any): void {
    item[this.args.column.field] = parseFloat(state) * PROPORTION[this.unit.id];
  }

  destroy(): void {
    this.input.remove();
    this.unitDisplay.remove();
  }

  focus(): void {
    this.input.focus();
  }

  getValue(): string {
    return this.input.value || '';
  }

  setValue(value: string): void {
    this.input.value = value;
  }

  isValueChanged(): boolean {
    return this.input.value !== this.input.defaultValue;
  }
}

export default DistanceEditor;
