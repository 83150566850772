import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL;

/**
 * request to django api a run
 * @param id run reference on django api
 * @returns promise that contains a run
 */
export const find = (id: number, token: string) =>
  axios.get(`${API_URL}/runs/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * request to django api runs of month
 * @param month number of month
 * @param token auth user id
 * @returns promise that contains runs
 */
export const getByMonth = (month: number, year: number, token: string) =>
  axios.get(`${API_URL}/runs/?month=${month}&year=${year}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * request to django api the pig position of a run
 */
export const getPigPosition = (id: number, token: string) =>
  axios.get(`${API_URL}/estimated_location/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const updatePassage = (id: string, data: any, token: string) => {
  return axios.put(`${API_URL}/passages/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const createPassage = (data: any, token: string) => {
  return axios.post(`${API_URL}/passages/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const createRun = (projectId: string, data: any, token: string) => {
  return axios.post(`${API_URL}/projects/${projectId}/runs/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
/**
 * request to django api passage deletion
 * @param passageId passage reference
 * @param token logged auth token
 */
export const deletePassage = (passageId: string | number, token: string) => {
  return axios.delete(`${API_URL}/passages/${passageId}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const updateTrackingPoint = (id: string | number, data: any, token: string) => {
  return axios.put(`${API_URL}/trackingPoints/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const toggleActiveTrackingPoint = (
  id: string,
  data: any,
  token: string
) => {
  return axios.put(`${API_URL}/trackingPoints/${id}/toggle_active/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const updateRun = (id: string, data: any, token: string) => {
  return axios.put(`${API_URL}/runs/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * Delete run
 * @param id run id
 * @param token auth token
 */
export const deleteRun = (id: string, token: string) => {
  return axios.delete(`${API_URL}/runs/${id}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const launch = (id: number, data: any, token: string) => {
  const csrfs = document.cookie.match('(^|;)\\s*csrftoken\\s*=\\s*([^;]+)');
  const csrf = csrfs ? csrfs.pop() : '';
  axios.defaults.headers.delete['X-CSRFToken'] = csrf;
  axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest';
  return axios.post(`${API_URL}/launch/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrf,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const abortRun = (id: number, data: any, token: string) => {
  const csrfs = document.cookie.match('(^|;)\\s*csrftoken\\s*=\\s*([^;]+)');
  const csrf = csrfs ? csrfs.pop() : '';
  axios.defaults.headers.delete['X-CSRFToken'] = csrf;
  axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest';
  return axios.post(`${API_URL}/abort/${id}/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrf,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const finishRun = (id: number, data: any, token: string) => {
  const csrfs = document.cookie.match('(^|;)\\s*csrftoken\\s*=\\s*([^;]+)');
  const csrf = csrfs ? csrfs.pop() : '';
  axios.defaults.headers.delete['X-CSRFToken'] = csrf;
  axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest';
  return axios.post(`${API_URL}/finish/${id}/`, data, {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
      'X-CSRFToken': csrf,
    },
  });
};

/**
 * create websocket to stream the run data
 * @param id run reference on django api
 * @param cb callback function
 */
export const getRunElevations = (id: string, token: string) => {
  if (!id) {
    return;
  }
  const csrfs = document.cookie.match('(^|;)\\s*csrftoken\\s*=\\s*([^;]+)');
  const csrf = csrfs ? csrfs.pop() : '';
  axios.defaults.headers.delete['X-CSRFToken'] = csrf;
  axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest';
  return axios.get(`${API_URL}/runs/${id}/elevations/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrf,
    },
  });
};

/**
 * check if th logged user is allowed make editions
 * @param projectId project id
 * @param token auth token
 */
export const checkIsAllowedMakeEditionsOnProject = (
  projectId: string,
  token: string
) => {
  return axios.get(`${API_URL}/projects/${projectId}/editor/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
};

interface Coords {
  latitude: number;
  longitude: number;
}

/**
 * make user check in on run
 * @param position coords object
 * @param runId run id
 * @param token auth token
 */
export const reportPosition = (
  location: Coords,
  runId: string,
  token: string
) => {
  const { latitude, longitude } = location;
  return axios.post(
    `${API_URL}/runs/${runId}/report-location/`,
    { location: [longitude, latitude] },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
};

/**
 * make user offline on run
 * @param runId run id
 * @param token auth token
 */
export const disconnedUserOfRun = (runId: number, token: string) => {
  return axios.post(
    `${API_URL}/runs/${runId}/disconnect/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
};

/**
 * request to django api the forescast weather of location in the determinad date
 */
export const forecastWeather = (coords: string, to: number, token: string) =>
  axios.get(`${API_URL}/forecast_weather/?coords=${coords}&to=${to}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * request to django api delete event
 */
export const createEvent = (
  date: string,
  name: string,
  runId?: string | number | null,
  token?: string
) =>
  axios.post(
    `${API_URL}/timepins/`,
    {
      tstamp: date,
      title: name,
      run_id: runId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 * request to django api delete event
 */
export const deleteEvent = (eventId: string | number, token: string) =>
  axios.delete(`${API_URL}/timepins/${eventId}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * request to django api update event
 */
export const updateEvent = (
  eventId: string | number,
  event: any,
  token: string
) =>
  axios.put(`${API_URL}/timepins/${eventId}/`, event, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * request to api approve passage captured by device and reject anyone
 * accepted previously
 */
export const approveTrigger = (triggerId: number, token: string) =>
  axios.post(
    `${API_URL}/triggers/${triggerId}/accept/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 * request to api reject passage captured by device
 */
export const rejectTrigger = (triggerId: number, token: string) =>
  axios.post(
    `${API_URL}/triggers/${triggerId}/reject/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 *
 */
export const markTriggerAsSeen = (triggerId: number, token: string) =>
  axios.post(
    `${API_URL}/triggers/${triggerId}/mark-seen/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

/**
 * get launched and not finished runs
 * @param token user authentication token
 */
export const getActiveRuns = (token: string) =>
  axios.get(`${API_URL}/active-runs/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
