import { MouseEvent } from 'react';
import Button from '../button/button';
import LinkButton from '../link-button/link-button';
import { ConfirmationModalType } from './confirmation-modal.d';
import {
  ModalWrapper,
  Modal,
  ModalFigure,
  ConfirmationTitle,
  ConfirmationText,
  ConfirmationFooter,
} from './confirmation-modal.style';

/**
 * 
 * @param param0 
 * @returns 
 */
const ConfirmationModal = ({
  icon,
  hide,
  id,
  title,
  text,
  confirmationText,
  cancelationText,
  onConfirm,
  onCancel,
  hideCancelButton,
  children,
}: ConfirmationModalType) => {
  const handleConfirm = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onConfirm(e);
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCancel(e);
  }

  return (
    <ModalWrapper id={id} $hide={!!hide} onClick={(e) => e.preventDefault()}>
      <Modal>
        <ModalFigure>{icon}</ModalFigure>
        <ConfirmationTitle>{title}</ConfirmationTitle>
        <ConfirmationText>{text}</ConfirmationText>
        
        {children ? children : null}

        <ConfirmationFooter>
          <Button id="confirmation-modal-confirm" handleClick={handleConfirm} value={confirmationText} />
          {hideCancelButton ? null : (
            <LinkButton handleClick={handleCancel}>{cancelationText}</LinkButton>
          )}
        </ConfirmationFooter>
      </Modal>
    </ModalWrapper>
  );
};

ConfirmationModal.defaultProps = {
  hide: true,
  children: undefined,
  title: 'What is Lorem Ipsum?',
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  confirmationText: 'Confirm',
  cancelationText: 'Cancel',
  hideCancelButton: false,
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff">
      <path d="M12 5.99L19.53 19H4.47L12 5.99M2.74 18c-.77 1.33.19 3 1.73 3h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18zM11 11v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zm0 5h2v2h-2z" />
    </svg>
  ),
};

export default ConfirmationModal;
