import moment from 'moment-timezone';
import { timezoneListModel } from '../../models/timezones';

/**
 * 
 */
export const getDefaultTimezone = () => {
  const date = new Date();
  const nearOffset = date.getTimezoneOffset();
  const myTimezoneList = timezoneListModel.map((t) => ({
    label: t.name,
    id: t.location,
    offset: t.offset,
  }));

  const currentTimezone = myTimezoneList.find(
    (t: any) => t.id === moment.tz.guess()
  );

  const nearTimezone = myTimezoneList.find(({ offset }) => offset === (nearOffset * -1));
  return currentTimezone || nearTimezone;
};
