import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const TrackingPointGalleryArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  border-radius: 12px;
  background: #dcdfe2;

  cursor: pointer;
`;

export const TrackingPointGalleryArrowNext = styled.div<{ $hide: boolean }>`
  display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
  position: absolute;
  height: 100%;

  align-items: center;

  top: 0;
  right: 0px;
  z-index: 3;

  svg {
    position: relative;
    right: -2px;

    transform: rotateY(160deg);
  }
`;

export const TrackingPointGalleryArrowPrev = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  position: absolute;
  height: 100%;

  align-items: center;

  left: 0px;
  z-index: 3;

  svg {
    position: relative;
    left: -2px;
  }
`;

export const TrackingPointGalleryCloseButton = styled.button`
  width: 30px;
  height: 30px;

  background-color: transparent;
  cursor: pointer;
`;

export const TrackingPointGalleryContainer = styled.div`
  width: 80%;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  overflow: auto;
`;

export const TrackingPointGalleryContainerHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 16px;
  border-bottom: 0.5px solid rgb(220, 223, 226);
`;

export const TrackingPointGalleryContainerHeaderTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 8px;

  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  line-height: 1em;

  color: #1a1f36;
`;

export const TrackingPointGalleryEmptyContainer = styled.div``;

export const TrackingPointGalleryEmptyText = styled.p``;

export const TrackingPointGalleryFixedContainer = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;

  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.85);
`;

export const TrackingPointGalleryMedias = styled.ul`
  padding: 32px 24px;
`;
