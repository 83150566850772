import styled from 'styled-components';

export const GtLogo = styled.img<{
  orientation?: string;
  $observerView: boolean;
}>`
  display: none;

  position: absolute;
  z-index: 1;
  top: 10px;
  right: 48px;

  vertical-align: middle;
  margin-right: 8px;

  opacity: 0.7;
  backdrop-filter: blur(10px);

  ${({ orientation }) =>
    orientation !== 'PORTRAIT'
      ? `
      object-fit: cover;
      object-position: center;
      height: 48px;
    `
      : `
      height: 48px;
    `}

  @media (min-width: 1024px) {
    display: inline-block;
    right: ${({ $observerView }) => ($observerView ? '315px' : '48px')};
  }
`;
