import { useContext, useMemo, useState } from 'react';
import { formatTimezoneDate } from '@/lib/formatter';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import {
  RunStatusDropdownWrapper,
  RunStatusDropdownButton,
  RunStatusDropdownItem,
  RunStatusTimeButton,
  RunStatusWrapper,
  RunStatusDropdown,
} from './mobile-run-status.style';

/**
 *
 * @returns
 */
const MobileRunStatus = () => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const [isOpenedDropdown, setIsOpenedDropdown] = useState<boolean>(false);
  const launchPoint = useMemo(() => {
    if (!inspectionContext.run?.trackingpoint_set) return null;
    return inspectionContext.run.trackingpoint_set[0];
  }, [inspectionContext.run?.trackingpoint_set]);

  const tense = {
    WAITING: 'SCHEDULED ON',
    ACTIVE: 'LAUNCHED ON',
    EARLY_FINISHED: 'FINISHED ON',
    FINISHED: 'FINISHED ON',
  };
  
  const status = {
    WAITING: 'WAITING',
    ACTIVE: 'ACTIVE',
    EARLY_FINISHED: 'FINISHED EARLY',
    FINISHED: 'FINISHED',
  };

  if (!inspectionContext.run || !launchPoint) return null;

  const canBeAbort = inspectionContext.run.estimation
    ? inspectionContext.run.estimation.can_be_aborted
    : true;

  /**
   *
   */
  const handleAbort = () => {
    setIsOpenedDropdown(false);
    inspectionContext.dispatch({
      type: 'TOGGLE_MODAL',
      data: 'aborting',
    });
  };

  /**
   *
   */
  const handleFinish = () => {
    setIsOpenedDropdown(false);
    inspectionContext.dispatch({
      type: 'TOGGLE_MODAL',
      data: 'finishing',
    });
  };

  /**
   *
   */
  const handleOpenScheduleModal = () => {
    if (inspectionContext.state.permission_type !== 'editor') return;
    if (inspectionContext.run?.launched) return;
    if (inspectionContext.run?.is_finished) return;
    
    setIsOpenedDropdown(false);
    inspectionContext.dispatch({
      type: 'MOBILE_TOGGLE_SCHEDULE_MODAL',
    });
  };

  const launchDate = launchPoint.passage?.tstamp || inspectionContext.run.scheduled_launch_at;
  return (
    <RunStatusWrapper>
      <RunStatusTimeButton onClick={inspectionContext.state.permission_type !== 'editor' ? undefined : handleOpenScheduleModal}>
        {tense[inspectionContext.run.status as string]}
        &nbsp;
        {formatTimezoneDate({
          date: launchDate,
          timezone: settingsContext.state.timezone?.id || 'utc',
          format: 'MMM DD, YYYY',
        })}
      </RunStatusTimeButton>

      {
        inspectionContext.run.launched && !inspectionContext.run.is_finished ? (
          <RunStatusDropdownWrapper>
            <RunStatusDropdownButton
              onClick={() => inspectionContext.state.permission_type !== 'editor' 
                ? undefined :
                setIsOpenedDropdown(!isOpenedDropdown)
              }
            >
              {status[inspectionContext.run.status as string]}
            </RunStatusDropdownButton>
            <RunStatusDropdown hide={!isOpenedDropdown}>
              {
                canBeAbort ? (
                  <RunStatusDropdownItem onClick={handleAbort}>Abort Run</RunStatusDropdownItem>
                ) : null
              }
              <RunStatusDropdownItem onClick={handleFinish}>Finish Run</RunStatusDropdownItem>
            </RunStatusDropdown>
          </RunStatusDropdownWrapper>
        ) : null
      }
    </RunStatusWrapper>
  );
};

export default MobileRunStatus;
