import InspectionStore from "app/modules/inspection/inspection.context";
import { EstimationType } from "app/modules/inspection/inspection.interfaces";
import { useContext, useEffect, useState } from "react";
import { removeMarkerFromMap, renderMarker } from "./pig-marker.controller";
import { getMarkerPosition } from "app/utils/map.utils";
import { PigMarkerGlobalStyle } from "./pig-marker.style";

interface Props {
  estimation: EstimationType;
  map: google.maps.Map;
}

const PigMarker = ({ estimation, map }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();

  useEffect(() => {
    return () => {
      if (marker) marker.map  = null
    };
  }, []);

  useEffect(() => {
    if (!marker && map && estimation?.location) {
      renderMarker(
        estimation,
        map,
        marker,
        setMarker,
        inspectionContext,
      );
    } else if (marker && !estimation.location) {
      removeMarkerFromMap(marker, setMarker);
    }
  }, [estimation?.location, map]);

  useEffect(() => {
    const coords = estimation?.location?.coordinates;
    if (coords && marker) {
      marker.position = getMarkerPosition(coords);
    }
  }, [estimation?.location, marker]);

  useEffect(() => {
    if (marker && estimation && !estimation.id && estimation.id !== 0) {
      removeMarkerFromMap(marker, setMarker);
    }
  }, [estimation?.id, marker]);

  return <PigMarkerGlobalStyle />;
};

export default PigMarker;
