import InspectionStore from "app/modules/inspection/inspection.context";
import { useContext, useEffect, useState } from "react";
import { renderMarker } from "./logged-user-marker.controller";
import { LoggedUserGlobalStyle } from "./logged-user-marker.style";
import AccountStore from "app/modules/account/account.context";

interface Props {
  map: google.maps.Map;
}

const LoggedUserMarker = ({ map }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();

  useEffect(() => {
    return () => {
        if (marker) marker.map  = null
    };
  }, []);

  useEffect(() => {
    if (marker) return;
    renderMarker(
      map,
      marker,
      setMarker,
      inspectionContext,
      accountContext.state
    );
  }, [accountContext.state.auth?.user]);

  useEffect(() => {
    if (inspectionContext.state.has_labels && marker) {
      const label = marker.getElementsByTagName('label')[0];
      label.classList.remove("display-none");
    }
    if (!inspectionContext.state.has_labels && marker) {
      const label = marker.getElementsByTagName('label')[0];
      if (label) label.classList.add("display-none");
    }
  }, [inspectionContext.state.has_labels, marker]);

  return <LoggedUserGlobalStyle />;
};

export default LoggedUserMarker;