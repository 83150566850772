import styled from 'styled-components';

export const MobileWidgetsBtn = styled.button`
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 8px;
  right: 82px;

  box-shadow: 0px 6px 10px rgba(51, 57, 78, 0.2);

  z-index: 1;

  > svg {
    opacity: 0.7;
  }
`;
