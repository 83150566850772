import { useState, useEffect } from 'react';
import {
  GtTable,
  GtTableBody,
  GtTableHead,
  GtTableRow,
} from './simple-table.style';
import { Column } from './simple-table.d';
import TableHeadColumn from './components/table-head-column/table-head-column';
import TableRow from './components/table-row/tableRow';

/**
 *
 * @param columns
 * @returns
 */
const renderHeadColumns = (
  columns: Column[],
  sortedBy: string,
  sortType: null | 'asc' | 'desc',
  dragOver: string,
  columnsSort: string[],
  setSortedby: (sortedBy: string) => void,
  setSortType: (sortType: null | 'asc' | 'desc') => void,
  setDragOver: (dragOver: string) => void,
  setColumnsSort: (columnsSort: string[]) => void,
  setCopyColumns: (columns: Column[]) => void
) =>
  columns
    .sort(
      (columnA, columnB) =>
        columnsSort.indexOf(columnA.field) - columnsSort.indexOf(columnB.field)
    )
    .map((column, i) => (
      <TableHeadColumn
        key={column.field}
        column={column}
        index={i}
        dragOver={dragOver}
        columnsSort={columnsSort}
        sortedBy={sortedBy}
        sortType={sortType}
        setSortedby={setSortedby}
        setSortType={setSortType}
        setDragOver={setDragOver}
        setColumnsSort={setColumnsSort}
        onResize={(width: number) => {
          if (dragOver) return;

          const copyColumns: Column[] = [...columns];
          const columnIndex: number = copyColumns.findIndex(
            (col) => col.field === column.field
          );

          copyColumns[columnIndex].width = width;
          setCopyColumns(copyColumns);
        }}
      />
    ));

/**
 *
 * @param columns
 * @returns
 */
const renderRows = (rows: any[], columns: Column[]) =>
  rows.map((row, i) => (
    <TableRow key={`${row?.id}-${i}`} columns={columns} row={row} />
  ));

/**
 *
 * @param rows
 * @param columns
 * @returns
 */
const sortRows = (
  sortedBy: string,
  sortType: null | 'asc' | 'desc',
  rows: any[]
) => {
  if (!sortedBy || !sortType) return rows;

  return rows.sort((rowA, rowB) => {
    // check if type is string
    if (typeof rowB[sortedBy] === 'string') {
      // check sort type
      if (sortType !== 'desc')
        return ('' + rowA[sortedBy]).localeCompare(rowB[sortedBy]);

      return ('' + rowB[sortedBy]).localeCompare(rowA[sortedBy]);
    }

    // check sort type
    if (sortType !== 'desc') return rowA[sortedBy] - rowB[sortedBy];
    return rowB[sortedBy] - rowA[sortedBy];
  });
};

/**
 *
 * @returns
 */
const SimpleTable = ({ columns, rows }: any) => {
  const [sortedBy, setSortedBy] = useState<string>('');
  const [copyColumns, setCopyColumns] = useState<Column[]>([...columns]);
  const [dragOver, setDragOver] = useState<string>('');
  const [sortType, setSortType] = useState<null | 'asc' | 'desc'>(null);
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  const [columnsSort, setColumnsSort] = useState<string[]>(
    columns.map((column: Column) => column.field)
  );

  useEffect(() => {
    setSortedRows(sortRows(sortedBy, sortType, [...rows]));
  }, [rows]);

  useEffect(() => {
    setCopyColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    if (!sortedRows.length && rows.length) {
      setSortedRows(sortRows(sortedBy, sortType, [...rows]));
    }
  }, [sortedBy, sortType]);

  return (
    <GtTable>
      <GtTableHead>
        <GtTableRow>
          {renderHeadColumns(
            columns,
            sortedBy,
            sortType,
            dragOver,
            columnsSort,
            setSortedBy,
            setSortType,
            setDragOver,
            setColumnsSort,
            setCopyColumns
          )}
        </GtTableRow>
      </GtTableHead>
      <GtTableBody>{renderRows(sortedRows, copyColumns)}</GtTableBody>
    </GtTable>
  );
};

export default SimpleTable;
