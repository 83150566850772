import { formatSpeedToMps } from '@/lib/formatter';
import moment from 'moment-timezone';
import { createRun } from '../../services/run.service';
import { getAvailableSurveys } from '../../services/projects.service';

export const saveRun = async ({
  auth,
  history,
  run,
  setErrors,
  timezone,
  speedUnit,
  projectId,
  setIsLoading,
  cleanFields,
  setConfirmationModal,
  customerId,
}: any) => {
  setIsLoading(true);
  if (!run.name) {
    setIsLoading(false);
    return setErrors({
      name: 'Name is required',
    });
  }
  if (!run.surveyId) {
    setIsLoading(false);
    return setErrors({
      survey: 'Survey is required',
    });
  }
  if (!run.startDate) {
    setIsLoading(false);
    return setErrors({
      startDate: 'Start Date is required',
    });
  }
  if (!run.startTime) {
    setIsLoading(false);
    return setErrors({
      startTime: 'Start Time is required',
    });
  }

  if (!run.predictedSpeed) {
    setIsLoading(false);
    return setErrors({
      predictedSpeed: 'Predicted Launch Speed is required',
    });
  }

  const startDate = moment.tz(timezone.id);
  const [year, month, day] = run.startDate.split('/');
  const testDate = new Date(`${year}/${month}/${day}`);
  startDate.year(+year);
  startDate.month(+month - 1);
  startDate.date(+day);

  

  if (testDate.toString() === 'Invalid Date') {
    setIsLoading(false);
    return setErrors({
      startDate: 'Invalid Date',
    });
  }

  const h = +run.startTime.slice(0, 2);
  const m = +run.startTime.slice(3, 5);
  const s = +run.startTime.slice(6, 8);
  const mm = +run.startTime.slice(9);
  const isValid = !(h > 23) &&
                    !(m > 59) &&
                    !(s > 59) &&
                    !(mm > 999) &&
                    !Number.isNaN(h) &&
                    !Number.isNaN(m) &&
                    !Number.isNaN(s) &&
                    !Number.isNaN(mm);

  if (!isValid) {
    setIsLoading(false);
    return setErrors({
      startTime: 'Invalid Time',
    });
  }

  startDate.hour(h);
  startDate.minute(m);
  startDate.second(s);
  startDate.millisecond(mm);
  const runBody = {
    name: run.name,
    predicted_launch_speed: +formatSpeedToMps({
      distance: run.predictedSpeed,
      unit: speedUnit?.id,
      returnNumber: true,
    }),
    survey_id: run.surveyId,
    run_type: run.type,
    scheduled_launch_time: startDate.utc(),
    project_id: projectId,
    reverse: run.reverse,
    sms: run.sms,
    email: run.email,
  };

  let runProjectResponse;

  try {
    runProjectResponse = await createRun(projectId, runBody, auth.token);
  } catch (err: any) {
    if (err?.response?.data?.type === 'survey/duplicate-chainage') {
      if (auth.user.managed_customer?.id === customerId) {
        setConfirmationModal({
          hide: false,
          title: 'Uninterpretable Format:',
          text: `More than one tracking point share the same chainage: \n\n ● ${err.response?.data?.error[0]} \n ● ${err.response?.data?.error[1]} \n\n Two different points cannot share the same chainage. Correct the survey you selected in order to create this run.`,
          confirmationText: 'Correct now, go to survey page',
          cancelationText: 'Later',
          onConfirm: () => history.push(`/survey/${run.surveyId}`),
          onCancel: () => setConfirmationModal(),
        });
        setErrors({ 'manager-error': 'survey/duplicate-chainage' })
      } else {
        setConfirmationModal({
          hide: false,
          title: 'Uninterpretable Format:',
          text: `More than one tracking point share the same chainage: \n\n ● ${err.response?.data?.error[0]} \n ● ${err.response?.data?.error[1]} \n\n Two different points cannot share the same chainage. Contact the customer manager or suveyor to correct this survey.`,
          confirmationText: 'Ok!',
          hideCancelButton: true,
          onConfirm: () => setConfirmationModal(),
        });
        setErrors({ 'no-manager-error': 'survey/duplicate-chainage' })
      }
    } else {
      setErrors({ other: 'Unexpected Error' })
    }
    setIsLoading(false);
    return;
  }

  const runId = runProjectResponse.data;
  setIsLoading(false);
  cleanFields();
  history.push(`/runs/${runId}`);
};

export const loadSurveys = async ({ projectId, auth, setSurveys }: any) => {
  const surveys = await getAvailableSurveys(projectId, auth.token);
  setSurveys(surveys.data);
};
