import styled from 'styled-components';

export const TrackingPointPassageCancelButton = styled.button`
  background-color: #ffffff;
  border-radius: 6px;

  height: 42px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  text-transform: uppercase;

  color: #f6382d;
`;

export const TrackingPointPassageConfirmButton = styled.button`
  background-color: #ffffff;
  border-radius: 6px;

  height: 42px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  text-transform: uppercase;

  color: #5850EC;
`;

export const TrackingPointPassageContent = styled.div`
  background-color: #ffffff;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  min-height: 253px;
`;

export const TrackingPointPassageFigure = styled.figure`
  position: relative;
  top: -26px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 51px;
  height: 51px;

  border-radius: 50%;
  background-color: #5850ec;
`;

export const TrackingPointPassageInputs = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 110px 110px;
  justify-content: center;
  gap: 7px;

  margin-top: 16px;
  margin-bottom: 21px;
  padding-bottom: 21px;

  border-bottom: 0.6px solid #dcdfe2;
`;

export const TrackingPointPassageInput = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;

  background-color: transparent;
  border: 0.5px solid #5850EC;
  border-radius: 6px;

  padding: 3px 6px;

  align-items: center;
  justify-content: flex-start;

  color: #5850ec;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  > input {
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    color: #5850ec;
  }
`;

export const TrackingPointPassageMassage = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #656978;

  text-align: center;
`;

export const TrackingPointPassageMassageTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #1a1f36;

  margin-bottom: 6px;
`;

export const TrackingPointPassageMassageWrapper = styled.div``;

export const TrackingPointPassageTrackingPointName = styled.h4`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #4B5066;
`;

export const TrackingPointPassageTrackingPointTypeIcon = styled.img`
  width: 19px;
  height: 19px;

  vertical-align: middle;
  margin-right: 4px;
`;

export const TrackingPointPassageWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 21;

  background-color: #707A8C80;

  display: grid;
  justify-content: center;
  align-content: end;
  gap: 9px;

  padding: 32px;
  width: 100%;
  height: 100%;
`;
