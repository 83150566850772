import styled from 'styled-components';

export const Button = styled.button<{
  online: boolean;
  disabled: boolean;
  waiting: boolean;
}>`
  text-align: center;
  width: 70px;
  height: 22px;
  border: 1px solid ${({ disabled }) => disabled ? '#f1f1f1' : '#444444'};

  padding: 3px;
  font-size: 12px;
  border-radius: 3px;
  float: right;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ online, waiting }) => {
    if (waiting) return 'wait;';
    if (!online) return 'not-allowed;';
    return 'pointer;'
  }}
`;
