import { useContext, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  saveSurvey,
} from './createSurveyModal.controller';
import {
  ModalWrapper,
  Modal,
  Header,
  CloseIcon,
  Container,
  NameLabel,
} from './createSurveyModal.style';
import Form from './components/first-form/first-form';
import AccountStore from 'app/modules/account/account.context';
import SurveyStore from 'app/modules/survey/survey.context';
import SettingsStore from 'app/modules/settings/settings.context';
import Button from 'app/components/default-button/default-button';
import ConfirmationModal from 'app/components/confirmation-modal/confirmation-modal';

const CreateSurveyModal = ({ onDone }: any) => {
  const surveyContext = useContext(SurveyStore);
  const accountContext = useContext(AccountStore);
  const settingsContext = useContext(SettingsStore);
  const history = useHistory();
  const [createdSurvey, setCreatedSurvey] = useState<any>();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<any>(null);
  const [reset, setReset] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<any>({});
  const opened = useMemo(() => {
    const isOpened = surveyContext.state.opened_modal === 'create-survey-modal';
    if (isOpened) {
      settingsContext.dispatch({ type: 'SCROLL_BLOCK', data: true });
    } else settingsContext.dispatch({ type: 'SCROLL_BLOCK', data: false });
    return isOpened
  }, [surveyContext.state.opened_modal]);

  const resetForm = () => {
    setStep(null);
    setReset(!reset);
    setCreatedSurvey(null);
  };

  if (!opened) return null;
  return (
    <ModalWrapper>
      <Modal>
        <Header>
          <CloseIcon
            onClick={() =>
              surveyContext.dispatch({ type: 'OPEN_MODAL' })
            }
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
            <NameLabel>
              {createdSurvey?.name || 'Create a new Survey'}
            </NameLabel>
          </CloseIcon>
          <div>
            {step?.id === 'map-step' ? (
              <Button
                customStyle="width: 150px;"
                onClick={() => {
                  onDone();
                  surveyContext.dispatch({ type: 'OPEN_MODAL' });
                  resetForm();
                }}
              >
                Done
              </Button>
            ) : null}
          </div>
        </Header>
        <Container>
          <Form
            reset={reset}
            setErrors={setErrors}
            onSubmit={(survey: any) => {
              saveSurvey({
                survey,
                setStep,
                auth: accountContext.state.auth,
                setCreatedSurvey,
                setErrors,
                setIsLoading,
                closeModal: () => surveyContext.dispatch({ type: 'OPEN_MODAL' }),
                dispatch: (data) => console.log('dispatch: ', data),
                distanceUnit: settingsContext.state.distanceUnit,
                setConfirmationModal,
                history,
              });
            }}
            errors={errors}
            isLoading={isLoading}
          />
        </Container>
      </Modal>
      <ConfirmationModal {...confirmationModal} />
    </ModalWrapper>
  );
};

CreateSurveyModal.defaultProps = {};

export default CreateSurveyModal;
