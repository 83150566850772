import { useContext, useEffect, useMemo } from 'react';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';
import { AuthType } from 'app/modules/account/account.context.d';
import SatellitePassages from '../../../../components/satellite-passages/satellite-passages';
import Overlay from 'stories/layout/overlay/overlay';
import AccountStore from 'app/modules/account/account.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { AGMTitle, Content, DeviceSerialNumber, Header, Modal } from './satelite-passages-overlay.style';

/**
 *
 * @returns
 */
const SatelitePassagesOverlay = () => {
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const inspectionContext = useContext(InspectionStore);
  const tpoint = inspectionContext.state.selected_point_triggers as TrackingPointCascadeType;

  useEffect(() => {
    inspectionContext.dispatch({ type: 'SET_SELECTED_POINT_TRIGGERS', data: inspectionContext.run?.trackingpoint_set?.[tpoint.index] });
    
  }, [inspectionContext.run?.trackingpoint_set?.[tpoint.index]?.trigger_set]);

  // memorize memorize max height
  const maxHeight = useMemo(() => {
    return window.innerHeight - 113;
  }, []);
  
  /**
   * close satellite modal
   */
  const onClose = () => {
    inspectionContext.dispatch({
      type: 'SET_SELECTED_POINT_TRIGGERS',
      data: undefined,
    });
  };

  /**
   * render header component
   * @returns React.ReactNode
   */
  const HeaderComponent = () => (
    <Header>
      <div>
        <AGMTitle>{tpoint.name}</AGMTitle>
        <DeviceSerialNumber>
          DEVICE SERIAL NUMBER: {tpoint.device_sn || '-'}
        </DeviceSerialNumber>
      </div>
    </Header>
  );

  return (
    <Overlay
      id="GT_DRAGGABLE_SATELLITE_PASSAGES_WRAPPER"
      maxHeight={maxHeight}
      onClose={onClose}
      Header={HeaderComponent}
      x={inspectionContext.state.selected_point ? -372 : -10}
      y={100}
    >
      <Modal onClick={(e) => e.stopPropagation()}>
        <Content>
          <SatellitePassages
            onClose={onClose}
            onTriggerMoreInfosClick={(trigger) => {
              inspectionContext.dispatch({ type: 'SET_SELECTED_TRIGGER_PASSAGE', data: trigger })
              setTimeout(() => {
                const element = document.querySelector('._highlighted_trigger');
                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }, 1);
            }}
            agm={tpoint}
            authToken={auth.token}
            isObserver={inspectionContext.state.permission_type === 'observer'}
            columns={2}
          />
        </Content>
      </Modal>
    </Overlay>
  );
};

export default SatelitePassagesOverlay;
