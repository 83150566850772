/***************************************************************************
 * Generated by RxDjango on 2024-09-02 17:45 (UTC)                         *
 *                                                                         *
 * Based on all ContextChannel.as_asgi() calls in backend.asgi.application *
 *                                                                         *
 * This is expected to match project/channels.py                           *
 *                                                                         *
 *                          DO NOT EDIT THIS FILE                          *
 *                                                                         *
 * To rebuild it, run:                                                     *
 *                                                                         *
 *     ./manage.py makefrontend [project]                                  *
 *                                                                         *
 ***************************************************************************/
import { ContextChannel } from '@rxdjango/react';

const SOCKET_URL = import.meta.env.VITE_SOCKET_URL.replace(/ws$/, '');

import { ProjectType } from './project.interfaces.d';


export class ProjectChannel extends ContextChannel<ProjectType> {

  anchor = 'project.serializers.ProjectSerializer';
  endpoint: string = 'ws/project/{project_id}/';

  args: { [key: string]: number | string } = {};

  baseURL: string = SOCKET_URL;

  constructor(project_id: number, token: string) {
    super(token);
    this.args['project_id'] = project_id;
  }

  model = {
    "project.serializers.ProjectSerializer": {
      "customer": "customer.serializers.CustomerSerializer",
      "creator": "account.serializers.UserSerializer",
      "final_customer": "project.serializers.FinalCustomerSerializer",
      "participant_set": "account.serializers.ParticipantSerializer",
      "device_set": "detection.serializers.DeviceSerializer"
    },
    "customer.serializers.CustomerSerializer": {
      "company": "account.serializers.CompanySerializer",
      "qube_set": "qube.serializers.QubeDeviceSerializer",
      "project_set": "customer.serializers.ProjectSimpleSerializer"
    },
    "account.serializers.CompanySerializer": {},
    "qube.serializers.QubeDeviceSerializer": {},
    "customer.serializers.ProjectSimpleSerializer": {
      "device_set": "customer.serializers.DeviceSimpleSerializer"
    },
    "customer.serializers.DeviceSimpleSerializer": {},
    "project.serializers.FinalCustomerSerializer": {},
    "account.serializers.ParticipantSerializer": {
      "project": "account.serializers.ProjectLocalSerializer",
      "user": "account.serializers.UserSerializer"
    },
    "account.serializers.ProjectLocalSerializer": {},
    "account.serializers.UserSerializer": {},
    "detection.serializers.DeviceSerializer": {
      "qubedevice": "qube.serializers.QubeDeviceSerializer"
    }
  };
}