import Skeleton from 'app/components/skeleton/skeleton';
import { ReactNode } from 'react';
import { Widget, WidgetContent, WidgetLabel, WidgetValue } from './mobile-weather-widget.style';

interface Props {
  isLoading: boolean;
  value: string | ReactNode;
  label: string;
  figure: string | ReactNode;
}

const MobileWeatherWidget = (props: Props) => {
  return (
    <Widget>
      <figure className="large">
          {typeof props.figure === 'string' ? (
            <img
              alt=""
              src={props.figure}
            />
          ) : props.figure }
        </figure>
      <WidgetContent>
        <Skeleton loading={props.isLoading} width="59px" height="9px">
        {typeof props.value === 'string' ? (
            <WidgetValue>{props.value}</WidgetValue>
          ) : props.value }
        </Skeleton>
        <Skeleton loading={props.isLoading} width="32px" height="6px">
          <WidgetLabel>{props.label}</WidgetLabel>
        </Skeleton>
      </WidgetContent>
    </Widget>
  );
};

MobileWeatherWidget.defaultProps = {
  figure: '/assets/icons/weather/sunny.png'
}

export default MobileWeatherWidget;
