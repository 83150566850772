import Avatar from '../../../../components/avatar/avatar';
import { Card } from './predict-user-card.style';

interface Props {
  avatar?: string;
  email: string;
  first_name: string;
  last_name: string;
  onClick: () => void;
}

const PredictUserCard = ({
  avatar,
  email,
  first_name,
  last_name,
  onClick,
}: Props) => (
  <Card onClick={onClick}>
    <Avatar
      src={avatar}
      email={email}
      firstName={first_name}
      lastName={last_name}
      size={24}
    />
    {first_name && last_name ? (
      <data>{`${first_name} ${last_name}`}</data>
    ) : null}
    <span>{email}</span>
  </Card>
);

export default PredictUserCard;
