import { updateSurveyPoint } from 'app/services/survey.service';
import { sheetEvents } from './survey-sheet.model';
import { SettingsContext, Unit } from 'app/modules/settings/settings.context.d';
import { SurveyContext, SurveyProviderValue } from 'app/modules/survey/survey.context.d';
import { SurveyPointType } from 'app/modules/survey/survey.interfaces';

/**
 *
 * @returns
 */
const selectPoint = async (args: any, handleSelectPoint) => { // TODO: type event args
  const point = args.grid.getDataItem(args.row);
  const nextPoint = args.grid.getDataItem(args.row + 1);
  const prevPoint = args.grid.getDataItem(args.row - 1);
  handleSelectPoint({ point, nextPoint, prevPoint });
};

/**
 *
 * @returns
 */
const handleCommonTextChange = async (
  args: any,
  token: string,
  dispatch: SurveyProviderValue['dispatch'],
) => { // TODO: type event args
  const data: any = {};
  const point = args.item;
  const column = args.column || args.grid.getColumns()[args.cell]
  data[column.field] = args.item[column.field];
  await updateSurveyPoint(data, point.id, token);

  const cell = args.grid.getActiveCell();
  args.grid.gotoCell(cell.row + 1, cell.cell, true);
  data.id = point.id;
  dispatch({
    type: 'UPDATE_POINT',
    data,
  })
};

export const getMapBounds = (row: any, nextPoint, prevPoint) => {
  if (!prevPoint?.geometry.coordinates[1] || !nextPoint?.geometry.coordinates[1]) {
    return [
      {
        lat: row.geometry.coordinates[1],
        lng: row.geometry.coordinates[0],
      },
    ];
  }

  const bounds: any = [
    { lat: row.geometry.coordinates[1], lng: row.geometry.coordinates[0] },
    {
      lat: prevPoint?.geometry.coordinates[1],
      lng: prevPoint?.geometry.coordinates,
    },
    {
      lat: nextPoint?.geometry.coordinates[1],
      lng: nextPoint?.geometry.coordinates,
    },
  ];

  return bounds;
};

/**
 *
 */
export const getEvents = (
  dispatch: SurveyProviderValue['dispatch'],
  token: string,
  handleSelectPoint: (data: any) => void,
) => {
  const changeHandles = {
    handleCommonTextChange,
  };

  const clickHandles = {
    selectPoint: (args) => selectPoint(args, handleSelectPoint),
  };
  const doubleClickHandles = {};

  const events = {};
  Object.keys(sheetEvents).forEach((key) => {
    const event: {
      onChange?: (e: any, args: any) => void;
      onClick?: (e: any, args: any) => void;
      onDoubleClick?: (e: any, args: any) => void;
    } = {};
    if (sheetEvents[key].onChange) {
      event.onChange = (e: any, args: any) =>
        changeHandles[sheetEvents[key].onChange](args, token, dispatch);
    }

    if (sheetEvents[key].onClick) {
      event.onClick = (e: any, args: any) =>
        clickHandles[sheetEvents[key].onClick](args, token);
    }

    if (sheetEvents[key].onDoubleClick) {
      event.onDoubleClick = (e: any, args: any) =>
        doubleClickHandles[sheetEvents[key].onDoubleClick](args, token);
    }

    events[key] = event;
  });

  return events;
};

/**
 *
 * @param state
 * @returns
 */
export const parsePoints = (
  surveyState: SurveyContext,
  settingsContext: SettingsContext,
  isObserver: boolean,
  setParsedPoints: (data: any[]) => void,
) => {
  /**
   *
   */
  const overideParsePointToRow = (point: SurveyPointType, index: number) => {
    return parsePointToRow(
      point,
      index,
      settingsContext.timezone?.id,
      settingsContext.distanceUnit || { id: 'm', label: 'm' },
      settingsContext.speedUnit || { id: 'mph', label: 'mph' },
      isObserver,
    );
  };

  // map tracking points to slickgrid row data
  const data: any = [];
  surveyState.survey?.surveypoint_set?.forEach((point, index) => {
    const p = surveyState.points_dic[point?.id];
    if (p) data.push(overideParsePointToRow(p, index))
  });
  if (data) setParsedPoints(data);
};

export const refreshGrid = (
  data: any[],
  grid: Slick.Grid<SurveyPointType>
) => {
  if (data) {
    for (const [index, row] of data.entries()) {
      const data = grid.getData();
      data[index] = row;
      grid.invalidateRow(index);
    }
  }
  grid.render();
}

/**
 * parse tracking points to slickgrid row data
 */
const parsePointToRow = (
  point: SurveyPointType,
  index: number,
  timezone = 'utc',
  distanceUnit: Unit,
  speedUnit: Unit,
  isObserver?: boolean,
) => {
  return {
    ...point,
    index: index + 1,
    timezone,
    latitude: point.geometry?.coordinates[1],
    longitude: point.geometry?.coordinates[0],
    distance_unit: distanceUnit,
    speed_unit: speedUnit,
    is_observer: isObserver,
  };
};
