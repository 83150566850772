import { SkeletonStyle } from './skeleton.style';

const Skeleton = ({
  loading,
  width,
  height,
  children,
  inline,
  color,
  hidden,
}: {
  width: string;
  height: string;
  color?: string;
  inline: boolean;
  loading: boolean;
  children?: any;
  hidden?: boolean;
}) =>
  loading ? (
    <SkeletonStyle
      $hidden={hidden}
      $inline={inline}
      $height={height}
      $width={width}
      $color={color}
      className={`rect pulse`}
    />
  ) : (
    children || null
  );

Skeleton.defaultProps = {
  hidden: false,
  width: '100%',
  height: '24px',
  inline: false,
  color: '#f1f1f1',
};

export default Skeleton;
