const Elevation = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 86 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.9341 47.8959L57.0744 14.918L38.8936 47.8959H74.9341Z"
      fill="white"
    />
    <path
      d="M74.9339 47.8959L57.0742 14.918L57.0513 14.9521L59.0013 47.8959H74.9339Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M66.7099 47.8977L40.7635 0L14.3696 47.8977H66.7099Z"
      fill="white"
    />
    <path
      d="M66.7098 47.8977L40.7633 0L40.3618 0.72779L44.1242 47.8977H66.7098Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M45.8678 47.8964L27.3887 13.7812L8.58838 47.8964H45.8678Z"
      fill="white"
    />
    <path
      d="M27.3886 13.793L25.106 47.8967H45.8677L27.3886 13.793Z"
      fill="url(#paint2_linear)"
    />
    <g filter="url(#filter0_dd)">
      <path
        d="M82 47.8976C78.1803 48.2501 74.2115 47.5905 70.5753 46.1236C66.9391 44.6453 63.6012 42.5187 60.5615 40.1307C57.4874 37.7767 54.7115 35.1158 51.8438 32.7163C50.4215 31.5223 48.9991 30.3396 47.5194 29.4413C46.0397 28.5315 44.5141 27.9175 43.1376 28.0198C41.7382 28.0539 40.1782 28.7931 38.71 29.7369C37.2303 30.6922 35.8079 31.8748 34.3741 33.0802C31.495 35.4797 28.6847 38.1065 25.5532 40.4036C22.4791 42.7462 19.0953 44.8158 15.4362 46.2259C11.7885 47.636 7.81971 48.2501 4 47.8976C7.80824 47.4882 11.4674 46.442 14.7479 44.6907C18.0515 42.9964 21.0338 40.7561 23.8671 38.3226C26.6774 35.8663 29.3844 33.2508 32.2176 30.6353C33.6515 29.3389 35.1656 28.088 36.8518 26.9395C37.7235 26.3823 38.6068 25.8364 39.6276 25.4157C40.6371 24.9949 41.7497 24.6424 42.9656 24.5969C45.4432 24.4946 47.5768 25.518 49.2974 26.6097C51.0524 27.7241 52.5665 28.9864 54.0003 30.2942C56.845 32.921 59.5062 35.582 62.2821 38.0951C65.0809 40.5628 68.0173 42.8599 71.2979 44.6111C74.5441 46.4079 78.1803 47.4996 82 47.8976Z"
        fill="url(#paint3_linear)"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd"
        x="0"
        y="20.5898"
        width="86"
        height="37.4052"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.968627 0 0 0 0 0.25098 0 0 0 0 0.192157 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow"
          result="effect2_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="65.9926"
        y1="14.918"
        x2="65.9926"
        y2="47.8959"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3A6B2" />
        <stop offset="1" stopColor="#A3A6B2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="53.5358"
        y1="0"
        x2="53.5358"
        y2="47.8977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3A6B2" />
        <stop offset="1" stopColor="#A3A6B2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="35.4868"
        y1="13.793"
        x2="35.4868"
        y2="47.8967"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3A6B2" />
        <stop offset="1" stopColor="#A3A6B2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="43"
        y1="24.5898"
        x2="43"
        y2="47.9951"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF764A" />
        <stop offset="1" stopColor="#F4332B" />
      </linearGradient>
    </defs>
  </svg>
);

export default Elevation;
