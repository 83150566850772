import styled from 'styled-components';

export const GtTable = styled.table<{
  isLoading?: boolean;
  hiddeScroll?: boolean;
  customStyle?: string;
}>`
  position: relative;
  width: 100%;

  ${({ hiddeScroll }) => (hiddeScroll ? '' : 'overflow: auto;')}

  border: solid 1px #dcdfe2;
  ${({ isLoading }) => (isLoading ? 'opacity: 0.5;' : '')}
  ${({ customStyle }) => customStyle}
`;

export const GtTableHead = styled.thead`
  background-color: #ffffff;
  border-bottom: solid 1px #dcdfe2;

  position: sticky;
  top: 0;

  z-index: 3;
  background-color: rgba(220, 223, 226);
`;

export const GtTableBody = styled.tbody``;

export const GtTableRow = styled.tr<{
  isSelected?: boolean;
  onClick?: () => void;
}>`
  /* height: 30px; */
  display: flex;
  flex-wrap: nowrap;

  * {
    ${({ onClick }) => (onClick ? 'cursor: pointer' : '')}
  }

  &:not(:last-child) {
    border-bottom: solid 1px #dcdfe2;
  }

  &:nth-child(even) {
    background-color: rgba(220, 223, 226, 0.35);
  }

  ${({ isSelected }) =>
    isSelected ? 'background-color: rgba(0,0,0, 0.35) !important;' : ''}

  :hover {
    background-color: rgba(220, 223, 226, 0.5);
  }
`;
