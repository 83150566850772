import Skeleton from 'app/components/skeleton/skeleton';
import { ReactNode } from 'react';
import { Widget, WidgetContent, WidgetLabel, WidgetValue } from './mobile-pig-widget.style';

interface Props {
  isLoading: boolean;
  handleClick: () => void;
  isSelected?: boolean;
  value: string | ReactNode;
  label: string;
  figure: string | ReactNode;
}

const MobilePigWidget = (props: Props) => {
  return (
    <Widget onClick={props.handleClick} isSelected={props.isSelected}>
      <figure className="large">
          {typeof props.figure === 'string' ? (
            <img
              alt=""
              src={props.figure}
            />
          ) : props.figure }
        </figure>
      <WidgetContent>
        <Skeleton loading={props.isLoading} width="59px" height="9px">
          <WidgetLabel isSelected={props.isSelected}>{`${props.label}:`}</WidgetLabel>
          {typeof props.value === 'string' ? (
            <WidgetValue isSelected={props.isSelected}>{props.value}</WidgetValue>
          ) : props.value }
        </Skeleton>
      </WidgetContent>
    </Widget>
  );
};

MobilePigWidget.defaultProps = {
  figure: '/assets/icons/weather/sunny.png'
}

export default MobilePigWidget;
