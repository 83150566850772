import styled from 'styled-components';

export const GtSolutionsSVG = styled.svg`
  width: 85%;
  margin-top: 38px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
