import { GroupLabel, GroupCheckbox } from './checkbox.style';

interface Props {
  checked: boolean;
  text?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick: (event: MouseEvent) => void;
  onShiftSlick?: (event: MouseEvent) => void;
}

/**
 * 
 * @param param0 
 * @returns 
 */
const Checkbox = (props: Props) => {
  /**
   * 
   */
  const handleClick = (e) => {
    if (e.shiftKey) {
      props.onShiftSlick?.(e);
      return;
    }

    props.onClick(e);
  };

  return (
    <GroupCheckbox onClick={handleClick} disabled={!!props.disabled}>
      {props.checked ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="checked"
        >
          <rect width="18" height="18" rx="3" />
          <path d="M4 8.5L7.33333 12L14 5" strokeWidth="2" />
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1"
            width="16"
            height="16"
            rx="2"
            strokeWidth="2"
          />
        </svg>
      )}
      {
        props.text || props.children ? (
          <GroupLabel>{props.text || props.children}</GroupLabel>
        ) : null
      }
    </GroupCheckbox>
  );
};

export default Checkbox;
