import styled from 'styled-components';

export const GtTableRow = styled.tr<{
  isSelected?: boolean;
  onClick?: () => void;
}>`
  display: flex;
  flex-wrap: nowrap;
  * {
    ${({ onClick }) => (onClick ? 'cursor: pointer' : '')}
  }

  &:not(:last-child) {
    border-bottom: solid 1px #dcdfe2;
  }

  &:nth-child(even) {
    background-color: rgba(220, 223, 226, 0.35);
  }

  ${({ isSelected }) =>
    isSelected ? 'background-color: rgba(0,0,0, 0.35) !important;' : ''}
  :hover {
    background-color: rgba(220, 223, 226, 0.5);
  }
`;

export const GtTableColumn = styled.td<{
  width?: number;
  removePadding?: string;
  ellipsis?: boolean;
}>`
  display: inline-flex;
  align-items: center;

  width: ${({ width }) => width || 200}px;
  height: 30px;
  padding: 3px;

  text-align: left;

  font-size: 13px;
  line-height: 18px;

  color: #676b7a;
  overflow: hidden;

  ${({ ellipsis }) =>
    ellipsis
      ? `
      > p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  `
      : null}

  &:not(:last-child) {
    border-right: solid 1px #dcdfe2;
  }
  &:last-child {
  }

  ${({ removePadding }) => (removePadding ? 'padding: 0' : '')}
`;
