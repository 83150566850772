import { DriveLineType } from "app/interfaces/inspection.interfaces";


/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderDriveline = (
  driveline: DriveLineType,
  map: google.maps.Map,
  drivePolylines: google.maps.Polyline[] | undefined,
  setDrivePolylines: (data: google.maps.Polyline[] | undefined) => void,
) => {
  if (!driveline?.coordinates) return;

  drivePolylines?.forEach((polyline) => {
    polyline.setMap(null);
  });

  const polylines: google.maps.Polyline[] = [];


  driveline.coordinates.forEach((path: any) => {
    const points = path.map((p: number[]) => ({ lng: p[0], lat: p[1] }));
    const polyline = new google.maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: '#4e4ee9',
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 1,
      map,
    });
    polyline.setMap(map);
    polylines.push(polyline);
  });
  setDrivePolylines(polylines);
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const changeDriveline = (
  driveline: DriveLineType,
  map: google.maps.Map,
  drivePolylines: google.maps.Polyline[] | undefined,
  setDrivePolylines: (data: google.maps.Polyline[]) => void,
) => {
  if (!driveline?.coordinates) return;
  drivePolylines?.forEach((polyline) => {
    polyline.setMap(null);
  });

  const polylines: google.maps.Polyline[] = [];

  driveline.coordinates.forEach((path: any) => {
    const points = path.map((p: number[]) => ({ lng: p[0], lat: p[1] }));
    const polyline = new google.maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: '#4e4ee9',
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 1,
      map,
    });
    polyline.setMap(map);
    polylines.push(polyline);
  });

  setDrivePolylines(polylines);
};
