import styled from 'styled-components';

export const SwitchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SwitchButtonLabel = styled.label<{ customLabelStyle?: string }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;

  color: #4b5066;

  ${({ customLabelStyle }) => customLabelStyle}
`;

export const SwitchButtonSwitch = styled.div<{ disabled: boolean; $on: boolean }>`
  display: flex;
  justify-content: ${({ $on }) => ($on ? 'flex-end' : 'flex-start')};

  width: 42px;
  height: 23px;

  padding: 1.5px;

  background-color: ${({ $on }) => ($on ? '#5850ec' : '#dcdfe2')};
  border-radius: 100px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const SwitchButtonSwitchBall = styled.span`
  display: inline-block;

  width: 20px;
  height: 20px;
  border-radius: 50%;

  background-color: #ffffff;
`;
