export const getCleanSurvey = () => ({
  id: 0,
  customer: 0,
  owner: null,
  name: '',
  speed_up_at_tees: false,
  pipeline: {},
  survey_by: null,
  surveyfile_set: [],
  surveypoint_set: [],
  surveyor: null,
  created: '',
  updated_at: '',
  gps_survey_accuracy: null,
  gps_datum: null,
  geoid_model: null,
  gps_equipment: null,
  survey_company: null,
  surveyor_phone: null,
});