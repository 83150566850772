import { Data } from "./slick-table.interfaces";

class GTDataView<T extends Data> {
  private items: T[] = [];
  private id2index: { [key: number]: number } = {};

  public getItem(index: number): T {
    return this.items[index];
  }

  public getItemById(id: number): T {
    return this.items[this.id2index[id]];
  }

  public getLength(): number {
    return this.items.length;
  }

  public setItems(items: T[]): void {
    // it makes a copy of the items
    for (const [index, item] of items.entries()) {
      if (!item.id) continue;
      if (this.id2index[item.id] !== undefined) {
        this.updateItem(this.id2index[item.id], item);
        this.id2index[item.id] = index;
        continue;
      }

      this.items.splice(index, 0, { ...item });
      this.id2index[item.id] = index;
    }

    const right = this.items.map(({ id }) => id); 
    const left = items.map(({ id }) => id); 
    const remaining = this.getRemaining(left, right);
    
    for (const id of remaining) {
      const index = this.id2index[id];
      this.items.splice(index, 1);
      delete this.id2index[id];
    }
  }

  public updateItem(id: number, data: T): void {
    const index = this.id2index[id];
    if (index === undefined) return;
    this.items[index] = { ...this.items[index], ...data };
  }

  private getRemaining(left: number[], right: number[]): number[] {
    const set1 = new Set(left);
    return right.filter((item) => !set1.has(item));
  }
}

export default GTDataView;
