export const ShowControls = () => (
  <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="2">
    <path d="M7.5 8.40002L14.25 1.65003L13.125 0.600026L7.5 6.15002L1.875 0.600025L0.750002 1.65002L7.5 8.40002Z" fill="#4D5158" strokeWidth="10px" />
  </svg>

);

export const HideControls = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 4.59998L2.75 11.35L3.875 12.4L9.5 6.84998L15.125 12.4L16.25 11.35L9.5 4.59998Z" fill="#4D5158" strokeWidth="10px" />
  </svg>
);
