const Speed = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        fill="#1A1C1E"
      />
      <path
        d="M29.9999 54.827C43.7118 54.827 54.8275 43.7114 54.8275 29.9995C54.8275 16.2876 43.7118 5.17188 29.9999 5.17188C16.2881 5.17188 5.17236 16.2876 5.17236 29.9995C5.17236 43.7114 16.2881 54.827 29.9999 54.827Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M30 54.8281C43.7069 54.8281 54.8276 43.7074 54.8276 30.0005C54.8276 23.1419 52.0448 16.935 47.5552 12.4453L30 30.0005V54.8281Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M30 43.4473C37.4273 43.4473 43.4483 37.4263 43.4483 29.9991C43.4483 22.5718 37.4273 16.5508 30 16.5508C22.5728 16.5508 16.5518 22.5718 16.5518 29.9991C16.5518 37.4263 22.5728 43.4473 30 43.4473Z"
        fill="#37383C"
      />
      <path
        d="M30.0002 37.2406C33.9995 37.2406 37.2415 33.9985 37.2415 29.9992C37.2415 25.9999 33.9995 22.7578 30.0002 22.7578C26.0009 22.7578 22.7588 25.9999 22.7588 29.9992C22.7588 33.9985 26.0009 37.2406 30.0002 37.2406Z"
        fill="#1A1C1E"
      />
      <path
        d="M30.0001 35.173C32.8567 35.173 35.1725 32.8572 35.1725 30.0005C35.1725 27.1439 32.8567 24.8281 30.0001 24.8281C27.1434 24.8281 24.8276 27.1439 24.8276 30.0005C24.8276 32.8572 27.1434 35.173 30.0001 35.173Z"
        fill="#737473"
      />
      <path
        d="M29.9999 2.07031C29.4206 2.07031 28.9344 2.56686 28.893 3.1979L27.1344 29.2048C26.9999 31.0358 28.3241 32.5876 29.9999 32.5876C31.6758 32.5876 32.9999 31.0358 32.8758 29.2048L31.1172 3.1979C31.0654 2.56686 30.5792 2.07031 29.9999 2.07031Z"
        fill="white"
      />
      <path
        opacity="0.35"
        d="M29.9999 51.2068C41.7121 51.2068 51.2068 41.7121 51.2068 29.9999C51.2068 18.2876 41.7121 8.79297 29.9999 8.79297C18.2876 8.79297 8.79297 18.2876 8.79297 29.9999C8.79297 41.7121 18.2876 51.2068 29.9999 51.2068Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeDasharray="2 4"
      />
      <path
        d="M4.78955 46.2508C10.1275 54.5163 19.4171 59.9991 29.9999 59.9991C40.5827 59.9991 49.8723 54.5163 55.2102 46.2508H53.8137C49.0033 46.2508 44.2033 45.1543 40.0654 42.7232C37.1068 40.9956 33.6723 39.9922 29.9999 39.9922C26.3275 39.9922 22.893 40.9853 19.9344 42.7232C15.7861 45.1543 10.9861 46.2508 6.1861 46.2508H4.78955Z"
        fill="url(#paint2_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="29.9999"
        y1="54.827"
        x2="29.9999"
        y2="5.17188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3864E5" />
        <stop offset="1" stopColor="#38C3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="42.4138"
        y1="12.4453"
        x2="42.4138"
        y2="54.8281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8946" />
        <stop offset="1" stopColor="#F6382D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="29.9999"
        y1="39.9922"
        x2="29.9999"
        y2="59.9991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#BDC0C6" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Speed;
