import styled from 'styled-components';

export const Wrapper = styled.div<{ $customStyle: string; $circular: boolean; $hideButton: boolean }>`
  position: relative;
  width: auto;
  ${({ $hideButton }) => ($hideButton ? 'display: none;' : '')}
  ${({ $customStyle }) => $customStyle}

  > button {
    border-radius: ${({ $circular }) => ($circular ? '50%' : '6px')};
    width: ${({ $circular }) => ($circular ? '30px' : 'auto')};
    padding: ${({ $circular }) => ($circular ? '0' : '0 12px')};
  }
`;

export const LabelItem = styled.li`
  &:hover {
    background-color: #f3f4f6;
    cursor: pointer;
  }
`;

export const Button = styled.button`
  padding-left: 12px;
  padding-right: 12px;
  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  height: 30px;

  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  transition-duration: 200ms;

  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;

  > span {
    font-size: 18px;
  }

  &:hover {
    background: #5850ec;
  }

  cursor: pointer;
`;

export const DropdownList = styled.ul<{ $hide: boolean }>`
  position: absolute;
  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
  top: calc(100% + 15px);
  right: 0;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;
  z-index: 25;

  max-height: 300px;
  overflow-y: auto;

  > li + li {
    border-top: solid 1px #dcdfe2;
  }
`;

export const Group = styled.ul<{ $type: string }>`
  display: grid;
  gap: 10px;
  padding: ${({ $type }) => ($type !== 'label' ? '16px' : '0')};

  & + & {
    border-top: solid 1px #dcdfe2;
  }

  > li {
    display: flex;
    flex-wrap: nowrap;
  }
`;

export const GroupTitle = styled.h3`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;

  font-family: Inter;
  font-style: bold;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;

  color: rgba(26, 31, 54, 0.8);
`;

export const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;

  color: #6b7280;

  min-width: 100px;
  padding: 8px;
`;
