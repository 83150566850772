import { FigureWrapper, Image } from './report-image.style';

interface Props {
  sketch: boolean;
  media: string;
  caption: string;
  onClick: () => void;
}

const ReaportImage = ({ media, caption, onClick }: Props) => (
  <FigureWrapper>
    <Image alt="" src={media} onClick={onClick} />
    {caption ? <p>{caption}</p> : null}
  </FigureWrapper>
);

export default ReaportImage;
