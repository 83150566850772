import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  position: sticky;
  right: 0;

  display: flex;
  align-items: center;
  gap: 4px;
  height: 28px;

  z-index: 2;

  background-color: transparent;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  color: #5850ec;

  cursor: pointer;
`;

export const Dropdown = styled.ul<{ show: boolean }>`
  position: absolute;
  right: -100px;

  display: ${({ show }) => (show ? 'flex' : 'none')};
  list-style: none;
  width: 224px;
  flex-direction: column;
  text-align: left;
  gap: 8px;

  background-color: #ffffff;

  background: #ffffff;
  border: 1px solid #dfe4ea;
  box-sizing: border-box;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 3px;
`;

export const Item = styled.li`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #9d9fa9;

  cursor: pointer;

  &:hover {
    color: #5850ec;
    background-color: rgba(220, 223, 226, 0.25);
  }
`;
