import { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import IMask from 'imask';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import DateInput from 'stories/base/forms/date-input/date-input';
import Input from 'stories/base/forms/input/input';
import Typograph from 'stories/type/typograph/typograph';
import IconButton from 'stories/base/buttons/icon-button/icon-button';
import {
  ScheduleDropdownContainer,
  ScheduleDropdownForm,
  ScheduleDropdownHeader,
  ScheduleDropdownFormRow,
} from './schedule-dropdown.style'
import FilledButton from 'stories/base/buttons/filled-button/filled-button';
import OutlineButton from 'stories/base/buttons/outlined-button/outline-button';
import { formatMps, formatTimezoneDate } from '@/lib/formatter';
import { updateScheduledRun } from '../../scheduled-on-button.controller';
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';

interface Props {
  open: boolean;
  onClose: () => void;
}

const TimeMask = new IMask.MaskedPattern({
  mask: 'HH:mm:ss.SSS',
  blocks: {
    HH: { mask: /^[0-9]{1,2}$/ },
    mm: { mask: /^[0-9]{1,2}$/ },
    ss: { mask: /^[0-9]{1,2}$/ },
    SSS: { mask: /^[0-9]{1,3}$/ },
  },
});

/**
 * 
 * @param props 
 */
const ScheduleDropdown = (props: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);
  const speedUnit = settingsContext.state.speedUnit?.label;
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [predictedLaunchSpeed, setPredictedLaunchSpeed] = useState('');

  useEffect(() => {
    if (inspectionContext.run) {
      const formatedDate = formatTimezoneDate({
        date: inspectionContext.run.scheduled_launch_at,
        timezone: settingsContext.state.timezone?.id || 'utc',
        format: 'YYYY/MM/DD',
      });
      const formatedTime = formatTimezoneDate({
        date: inspectionContext.run.scheduled_launch_at,
        timezone: settingsContext.state.timezone?.id || 'utc',
        format: 'HH:mm:ss.SSS',
      });
      setDate(formatedDate);
      setTime(formatedTime);
    }
  }, [props.open, settingsContext.state.timezone?.id]);

  useEffect(() => {
    setPredictedLaunchSpeed(formatMps({
      distance: inspectionContext.run?.predicted_launch_speed,
      unit: settingsContext.state.speedUnit,
      returnNumber: true,
    }) as string)
  }, [inspectionContext.run?.predicted_launch_speed, settingsContext.state.speedUnit?.id]);

  return (
    <ScheduleDropdownContainer $open={props.open} data-testid="schedule-form">
      <ScheduleDropdownHeader>
        <Typograph type="subtitle1">Schedule</Typograph>
        <IconButton styleType="transparent" icon="close" onClick={props.onClose} />
      </ScheduleDropdownHeader>

      <ScheduleDropdownForm>
        <ScheduleDropdownFormRow>
          <DateInput
            icon="calendar_today"
            id="date"
            label="Date"
            value={date}
            onChange={({ value }) => {
              const formatedDate = moment.utc(value).format('YYYY/MM/DD');
              setDate(formatedDate);
            }}
          />

          <Input
            icon="timer"
            id="time"
            label="Time"
            value={TimeMask.resolve(time)}
            onChange={(e) => {
              setTime(e.target.value);
            }}
          />
        </ScheduleDropdownFormRow>

        <ScheduleDropdownFormRow>
          <Input
            id="predicted-launch-speed"
            label={`Predicted Launch Speed (${speedUnit})`}
            value={predictedLaunchSpeed}
            onChange={(e) => {
              setPredictedLaunchSpeed(e.target.value);
            }}
          />
        </ScheduleDropdownFormRow>

        <ScheduleDropdownFormRow>
          <FilledButton text="Re-schedule" onClick={() => {
            updateScheduledRun(
              date,
              time,
              predictedLaunchSpeed,
              inspectionContext.run?.id,
              accountContext.state.auth?.token,
              settingsContext.state.timezone,
              notificationContext.dispatch,
              settingsContext.state.speedUnit,
            );
            props.onClose();
          }} />
          <OutlineButton text="Cancel" onClick={props.onClose} />
        </ScheduleDropdownFormRow>
      </ScheduleDropdownForm>
    </ScheduleDropdownContainer>
  );
};

export default ScheduleDropdown;
