import moment from 'moment-timezone';
import { NotificationProviderValue } from "app/modules/notification/notification.context.d";
import { Timezone, Unit } from "app/modules/settings/settings.context.d";
import { formatSpeedToMps } from '@/lib/formatter';
import { updateRun } from 'app/services/run.service';

/**
 *
 * @param data
 * @param dispatch
 * @param timezone
 * @returns
 */
export const updateScheduledRun = async (
  date: string,
  time: string,
  speed: string,
  runId: number | undefined,
  token: string | undefined,
  timezone: Timezone | undefined,
  notificationDispatch: NotificationProviderValue['dispatch'],
  speedUnit?: Unit | undefined,
) => {
  if (!timezone?.id) return;
  if (!speedUnit?.id) return;
  if (!token) return;
  if (!runId) return;

  const startDate = moment(date).tz(timezone.id);
  const cleanTime = time.replace(/:/g, '').replace('.', '');

  const h = +cleanTime.slice(0, 2);
  const m = +cleanTime.slice(2, 4);
  const s = +cleanTime.slice(4, 6);
  const mm = +cleanTime.slice(6,);
  const isValid = !(h > 23 || m > 59 || s > 59 || mm > 999);

  if (!isValid) {
    return notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Invalid date time',
      },
    });
  }

  startDate.hour(h);
  startDate.minute(m);
  startDate.second(s);
  startDate.millisecond(mm);

  const dataToApi = {
    id: runId,
    scheduled_launch_at: startDate.toISOString(),
    scheduled_launch_time: startDate.toISOString(),
    predicted_launch_speed: formatSpeedToMps({
      distance: +speed,
      unit: speedUnit.id,
    }),
  };

  try {
    await updateRun(runId.toString(), dataToApi, token);
  } catch (err: any) {
    if (err.response?.data?.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};