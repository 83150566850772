import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { RouterHistory } from '@sentry/react/types/reactrouter';
import { RunType } from '../../../../../interfaces/inspection.interfaces';
import { getBoundsBox } from '../../../../../utils/map.utils';
import { IconRenderer } from './cluster-icon';

/**
 *
 */
export const init = ({
  map,
  runs,
  history,
  setSelectedRuns,
}: {
  map: google.maps.Map;
  runs: RunType[];
  history: RouterHistory;
  setSelectedRuns?: (data: RunType[]) => void;
}) => {
  renderRunMarkers({ map, runs, history, setSelectedRuns });
};

/**
 *
 * @param run
 * @returns
 */
const getMarkerIcon = ({ run }: { run: RunType }) => {
  const isActiveRun = run.launched && !run.is_finished;

  return {
    url: isActiveRun
      ? '/assets/icons/red-run-marker.svg'
      : '/assets/icons/run-marker.svg',
    scaledSize: new window.google.maps.Size(60, 60), // scaled size
    origin: new window.google.maps.Point(0, 0), // origin
    anchor: new window.google.maps.Point(30, 30), // anchor
  };
};

/**
 *
 */
const onMarkerClick = (history: RouterHistory, id: number) => {
  history.push(`/runs/${id}`);
};

/**
 *
 * @param param0
 */
const renderRunMarkers = ({
  map,
  runs,
  history,
  setSelectedRuns,
}: {
  map: google.maps.Map;
  runs: RunType[];
  history: RouterHistory;
  setSelectedRuns?: (data: RunType[]) => void;
}) => {
  const markers = runs
    .filter((run) => run.first_point_coords && run.first_point_coords.length)
    .map((run) => {
      const marker = new window.google.maps.Marker({
        map,
        position: {
          lng: run.first_point_coords[0],
          lat: run.first_point_coords[1],
        },
        icon: getMarkerIcon({ run }),
        title: `(${run.id}) - ${run.name}`,
      });

      marker.addListener('click', () => onMarkerClick(history, run.id || -1));

      return marker;
    });

  renderRunClusters({ markers, runs, map, setSelectedRuns });
};

/**
 *
 */
const renderRunClusters = ({
  markers,
  runs,
  map,
  setSelectedRuns,
}: {
  markers: google.maps.Marker[];
  runs: RunType[];
  map: google.maps.Map;
  setSelectedRuns?: (data: RunType[]) => void;
}) => {
  const cluster = new MarkerClusterer({
    markers,
    map,
    renderer: new IconRenderer(),
  });

  cluster.onClusterClick = (_, cluster) => {
    const selectedRuns: RunType[] = [];
    runs.forEach((run) => {
      const foundRun = cluster?.markers?.find((marker: any) => {
        const id = marker.title
          .split(' - ')[0]
          .replace('(', '')
          .replace(')', '');

        return id === `${run.id}`;
      });

      if (foundRun) {
        selectedRuns.push(run);
      }
    });
    setSelectedRuns && setSelectedRuns(selectedRuns);
  };
};

/**
 *
 * @param points
 * @param map
 */
export const centerMap = ({
  runs,
  map,
}: {
  runs: RunType[];
  map: google.maps.Map;
}) => {
  if (runs.length === 1) {
    map.setCenter({
      lng: runs[0].first_point_coords[0],
      lat: runs[0].first_point_coords[1],
    });
    map.setZoom(10);
  } else {
    const center: any = getBoundsBox(
      runs
        .filter(
          (run) => run.first_point_coords && run.first_point_coords.length
        )
        .map((run) => ({
          lng: run.first_point_coords[0],
          lat: run.first_point_coords[1],
        }))
    );

    map.fitBounds(center, 70);
  }
};
