import styled from 'styled-components';

export const TrackingPointModalAddButton = styled.button`
  width: 42px;
  height: 26px;
  background: #5850ec;
  border-radius: 26px;
  padding: 6px 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;

  color: #ffffff;
`;

export const TrackingPointModalCloseButton = styled.button`
  background-color: #ffffff;
  border-radius: 6px;

  height: 42px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  text-transform: uppercase;

  color: #5850EC;
`;

export const TrackingPointModalContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 18px;

  min-height: 253px;
`;

export const TrackingPointModalContentHeader = styled.header`
  display: grid;
  grid-template-columns: minmax(120px, auto) 1fr;
  gap: 12px;

  padding-top: 12px;
  padding-bottom: 12px;
`;

export const TrackingPointModalMetaDataTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border: 0.5px solid #dcdfe2;

  > tr {
    height: 18.5px;
  }

  > tr,
  > tr > td {
    border: 0.5px solid #dcdfe2;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 7.5px;
    line-height: 14px;

    color: #767986;

    padding-left: 6px;
    padding-right: 6px;
  }
`;

export const TrackingPointModalWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 21;

  background-color: #707A8C80;
  
  height: -webkit-fill-available;
  width: 100%;
  
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 9px;

  & > :first-child {
    margin-top: auto !important;
  }
  
  > div {
    display: grid;

    width: 100%;
    gap: 9px;
    padding: 16px;
  }
`;

export const TrackingPointPresetWrapper = styled.div<{ $isObserver: boolean }>`
  padding-top: 12px;
  padding-bottom: 12px;

  border-bottom: 0.5px solid #dcdfe2;
  ${({ $isObserver}) => $isObserver ? 'display: none;' : ''}
`;

export const TrackingPointModalTrackingPointName = styled.h4`
  position: relative;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #4B5066;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;

    width: 32px;
    height: 4px;

    background: #5850ec;
    border-radius: 6px;
  }

  margin-bottom: 10px;
`;

export const TrackingPointModalPassage = styled.div<{ isObserver: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 7px;

  margin-top: 22px;
  margin-bottom: 22px;
  ${({ isObserver}) => isObserver ? 'display: none;' : ''}
`;

export const TrackingPointModalPassageInvalidReason = styled.p`
  color: rgba(237, 32, 36, 1);

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  margin-bottom: 22px;
  margin-top: -10px;
  margin-left: 50px;
`;

export const TrackingPointModalPassageInput = styled.div<{ $invalid: boolean }>`
  display: flex;

  border: 0.5px solid;
  background-color: ${({ $invalid }) => (!$invalid ? 'transparent' : 'rgba(237, 32, 36, 0.1)')};
  border-color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};
  border-radius: 6px;

  padding: 6px;

  align-items: center;
  justify-content: flex-start;

  color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  > svg {
    margin-right: 8px;

    > * {
      fill: ${({ $invalid }) => (!$invalid ? 'transparent' : 'rgba(237, 32, 36, 1)')};
    }
  }

  > input {
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    background-color: transparent;
    color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};
  }
`;

export const TrackingPointPassageInformation = styled.dl`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0;

  gap: 6px;
`;

export const TrackingPointPassageInformationPassage = styled(TrackingPointPassageInformation)`
  align-items: flex-start;
`;

export const TrackingPointPassageDate = styled.dt<{ $invalid: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  color: ${({ $invalid }) => (!$invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  > svg {
    margin-right: 6px;
  }
`;

export const TrackingPointPassageTime = styled(TrackingPointPassageDate)``;


export const TrackingPointPassageDataLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  margin: 0;

  color: #4B5066;
`;

export const TrackingPointPassageDataValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  margin: 0;


  color: #4B5066;
`;

export const TrackingPointPassageDataGroup = styled.div`
  display: flex;
  gap: 4px;
`;

export const TrackingPointSeeMoreButton = styled.button<{ active: boolean }>`
  height: 24px;
  padding: 7px 12px;
  background-color: #dcdfe2;
  border-radius: 100px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;

  color: #767986;

  > svg {
    position: relative;
    margin-right: 6px;
    transition-duration: 200ms;
    transform: ${({ active }) => (!active ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  > span {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: 13px;
    left: 0;
    display: inline-block;
    background-color: #dcdfe2;
    height: 1px;
    width: 100%;
  }
`;

export const TrackingPointSeeMoreButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  margin-top: 24px;
`;

export const TrackingPointModalMedia = styled.div`
  margin-top: 12px;
`;

export const TrackingPointModalMediaHeader = styled.header`
  font-family: 'Inter';
  font-style: normal;
  font-size: 11px;
  line-height: 11px;

  color: #5850ec;

  > strong {
    font-weight: 700;
  }

  > svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    vertical-align: middle;
  }
`;

export const TrackingPointModalMediaContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;

  margin-top: 15px;
  margin-bottom: 15px;
`;

export const TrackingPointModalMediaFigure = styled.figure`
  border-radius: 4px;
  overflow: hidden;
  background-color: #dcdfe2;
  filter: drop-shadow(0px 2px 4px rgba(51, 57, 78, 0.1));
  cursor: pointer;
`;

export const TrackingPointModalMetaDataTableWrapper = styled.div`
  width: 100%;
  max-height: 300px;
  margin-top: 18px;

  overflow: auto;
`;

export const TrackingPointModalMediaImage = styled.img`
  width: 100%;
  height: 72px;

  object-fit: cover;
`;

export const TrackingPointModalMediaFooter = styled.footer<{ hidden: boolean }>`
  display: flex;
  justify-content: flex-end;

  > input {
    display: none;
  }
  ${({ hidden }) => hidden ? 'display: none;' : ''}
`;

export const TrackingPointModalMediaFooterMediaGalleryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  height: 28px;
  background-color: transparent;
  border: 0.5px solid #5850ec;
  border-radius: 26px;
  padding-left: 12px;
  padding-right: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #5850ec;

  > svg {
    width: 12px;
    height: 12px;
  }
`;
