const colors = {
  // primary colors
  primary: '#5850ec',
  primary_900: '#2e2a8b',
  primary_800: '#3b38b5',
  primary_700: '#4e48da',
  primary_600: '#655af7',
  primary_500: '#806eff',
  primary_400: '#9b85ff',
  primary_300: '#b69eff',
  primary_200: '#cfb8ff',
  primary_100: '#e4d4ff',
  primary_50: '#f7f0ff',
  primary_base_blue: '#5850ec',
  primary_dark_blue: '#3e38B2',
  primary_light_blue: 'rgba(88, 80, 236, 0.20);',
  // secondary colors
  secondary_base_green: '#31d988',
  secondary_dark_green: '#1b9e5f',
  secondary_dark_yellow: '#fede00',
  secondary_base_pink: '#fab0b5',
  secondary_dark_pink: '#ef5769',
  secondary_base_gray: '#8e949f',
  secondary_dark_gray: '#3e4255',
  disabled: '#d2d1d2', 
  // neutra colors
  neutra: '#3e4255',
  neutra_900: '#363945',
  neutra_800: '#474B5B',
  neutra_700: '#5a5d71',
  neutra_600: '#6d7186',
  neutra_500: '#82859a',
  neutra_400: '#979aae',
  neutra_300: '#adb0c1',
  neutra_200: '#c4c6d3',
  neutra_100: '#dbdce5',
  neutra_50: '#f3f3f6',
  // system colors
  system_success: '#30d988',
  system_warning: '#fede00',
  system_danger: '#f21e2b',
  // surface colors
  surface_white: '#ffffff',
  surface: '#f7f8f9',
  surface_frame: '#f0f2f3',
  // borders and outlines
  outline: 'rgba(0, 0, 0, 0.12)',
  outline_on_surface: 'rgba(0, 0, 0, 0.87)',

  transparent: 'transparent',
};

export default colors;
