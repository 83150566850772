import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
  justify-content: center;

  top: -4px;
  padding: 2px;

  vertical-align: middle;

  height: 100%;

  border-radius: 4px;
`;

export const FlyToButton = styled.button<{ openedList?: boolean }>`
  position: relative;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:disabled {
    cursor: default;
  }
  svg {
    margin-left: 3px;
    transform: rotate(${({ openedList }) => (!openedList ? '0' : '-90deg')});
  }
`;

export const IconImage = styled.img<{ size?: string }>`
  width: ${({ size }) => size || '36px'};
  height: ${({ size }) => size || '36px'};
  position: relative;
`;

export const ListWrapper = styled.div<{ oppened: boolean }>`
  position: absolute;
  left: 100%;
  z-index: 10;
  top: 0;

  width: 250px;
  max-height: 300px;
  background-color: #fff;
  border: 1px solid #dfe0e4;
  border-radius: 6px;
  box-shadow: 0px 8px 10px rgba(29, 33, 57, 0.15);

  text-align: center;

  margin-left: 8px;

  ${({ oppened }) => (oppened ? '' : 'display: none;')}

  @media(max-width: 768px) {
    position: fixed;
    z-index: 10;
    
    width: 100%;
    max-width: 100%;
    
    height: 100vh;
    max-height: 100vh;

    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0;
  }
`;

export const Selector = styled.ul`
  max-height: calc(100% - 79px);
  overflow: auto;
  border-top: 1px solid #dfe0e4;

  @media (min-width: 1024px) {
    max-height: 180px;
  } 
`;

export const SelectorItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 10px 10px;
  cursor: pointer;
  border-bottom: 1px solid #dfe0e4;

  :first-child {
    border-top: none;
  }
  :hover {
    background-color: #f1f1f1;
  }
  svg {
    max-height: 25px;
    max-width: 25px;
  }
  * {
    cursor: pointer;
  }
`;

export const SelectorItemLabel = styled.label<{ customLabelStyle?: string; }>`
  padding-left: 10px;
  display: block;

  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 1em;

  color: #1a1f36;

  ${({ customLabelStyle }) => customLabelStyle}
`;

export const IconChooserLabel = styled.label`
  display: block;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #161e2e;
  text-align: left;

  padding: 12px 20px;

`;

export const Background = styled.div<{ oppened: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: #000;
  ${({ oppened }) => (oppened ? '' : 'display: none;')}
`;

export const ListFixedTop = styled.div`
  position: sticky;
  top: 0;
  background-color: #ffffff;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
`;

export const SvgTag = styled.svg`
  width: 15px;
  height: 15px;
  cursor: pointer;
`;
