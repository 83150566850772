import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import {
  CardWrapper,
  CardSerial,
  CardInfoWrapper,
  CardInfo,
  CardInfoSignal,
  CardInfoLabel,
  CardBattery,
} from './qube-item.style'

interface Props {
  qube: QubeDeviceType;
  onClick: () => void;
}

/**
 * 
 * @returns 
 */
const QubeItem = (props: Props) => {
  const battery100 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M8.2 3.4V6.6M10.6 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#56F000" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const battery80 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M8.2 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#56F000" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const battery40 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#FFF500" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const battery20 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#FF2222" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
  const batteries = { 20: battery20, 40: battery40, 80: battery80, 100: battery100, };
  const batteryKeyList = Object.keys(batteries).filter((key) => parseInt(key) > props.qube.battery_level); 
  const batteryKey = batteryKeyList.length ? batteryKeyList[0] : 20;

  return (
    <CardWrapper onClick={props.onClick}>
      <CardSerial>{props.qube.serial.slice(-4)}</CardSerial>
      <CardInfoWrapper>
        <CardInfo>
          <CardInfoSignal $checked={props.qube.online} />
          <CardInfoLabel>{props.qube.online ? 'Online' : 'Offline'}</CardInfoLabel>
        </CardInfo>
        <CardInfo>
          <CardInfoSignal $checked={props.qube.armed} />
          <CardInfoLabel>{props.qube.armed ? 'Armed' : 'Disarmed'}</CardInfoLabel>
        </CardInfo>
      </CardInfoWrapper>
      <CardBattery>
        {batteries[batteryKey]}
        <span>{props.qube.battery_level}%</span>
      </CardBattery>
      <span className="material-icons-outlined">chevron_right</span>
    </CardWrapper>
  );
};

export default QubeItem;
