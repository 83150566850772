import styled from 'styled-components';

export const Header = styled.header<{ $hidden?: boolean }>`
  display: none;
  position: relative;

  z-index: 3;
  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);

  gap: 16px;

  width: 100%;

  align-items: center;
  justify-content: space-between;

  height: auto;
  padding: 10px 15px;

  will-change: transform, height;
  transition-property: transform, height;
  transition-duration: 0ms;

  > div {
    display: flex;
    gap: 8px;
    align-items: center;

    button > span.material-icons-outlined {
      font-size: 18px;
      paddin
    }

    button {
      gap: 4px;
    }
  }

  > button {
    bottom: -42px;
  }

  @media (min-width: 1024px) {
    display: flex;
    ${({ $hidden }) => ($hidden ? 'display: none;' : '')}
  }
  ${({ $hidden }) => ($hidden ? 'display: none;' : '')}
`;

export const RunName = styled.h1`
  display: inline-block;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #ffffff;
`;