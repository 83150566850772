import styled from 'styled-components';

export const TrackingPointMediasWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: grid;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  z-index: 21;

  @media (min-width: 1024px) {
    position: relative;
  }
`;

export const TrackingPointFloatCloseButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 6px;

  position: absolute;
  top: 8px;
  right: 8px;

  background: rgba(62, 66, 85, 0.35);
  backdrop-filter: blur(10px);

  cursor: pointer;

  z-index: 1;

  svg {
    position: relative;
    top: 1px;
  }
`;

export const ArrowPrev = styled.div<{ hide: boolean }>`
  position: absolute;
  height: 100%;

  display: none;

  align-items: center;

  left: 1.5%;
  z-index: 3;

  svg {
    position: relative;
    left: -2px;
  }

  @media (min-width: 1024px) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
  }
`;

export const ArrowNext = styled.div<{ hide: boolean }>`
  position: absolute;
  height: 100%;

  display: none;
  align-items: center;

  top: 0;
  right: 1.5%;
  z-index: 3;

  svg {
    position: relative;
    right: -2px;

    transform: rotateY(160deg);
  }

  @media (min-width: 1024px) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
  }
`;

export const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  border-radius: 12px;

  background: rgba(255, 255, 255, 0.25);
`;

export const EmptyMediaWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const EmptyMediaMainText = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;

  color: #ffffff;
`;
