import { formatPipePointsToPath } from "@/lib/formatter";
import { GetBoundsBoxResponse, getBoundsBox } from "app/utils/map.utils";
import { RunCascadeType } from "app/modules/inspection/inspection.interfaces";
import { CustomerType } from "app/modules/customer/customer.interfaces";
import { MarkerClusterer } from "./utils/marker-clusterer/markerclusterer";
import { IconRenderer } from "./components/qube-marker/cluster-icon";
import { GridAlgorithm } from "./utils/marker-clusterer/algorithms";
import { CustomerMarker } from "./components/qube-marker/qube-marker.interfaces";
import MobileDetect from "mobile-detect";

/**
 *
 */
export const centerMap = ({
  pipeline,
  map,
  setMapCenter,
  mapCenter
}: {
  pipeline?: RunCascadeType['pipeline'];
  map?: google.maps.Map;
  setMapCenter: (center: GetBoundsBoxResponse) => void;
  mapCenter: GetBoundsBoxResponse | undefined;
}) => {
  if (pipeline?.geometry && map) {
    const points = formatPipePointsToPath({
      project: null,
      name: null,
      length: null,
      id: null,
      start: { coordinates: [], type: '' },
      geometry: { coordinates: pipeline.geometry.coordinates, type: '' },
    });
    if (points) {
      const center: GetBoundsBoxResponse = getBoundsBox(points);
      if (!mapCenter) {
        setMapCenter(center);
      } else if (JSON.stringify(center) !== JSON.stringify(mapCenter)) {
        setMapCenter(center);
      }
    }
  }
};

/**
 *
 */
export const getLogoOrientation = (customer: CustomerType | undefined) => {
  const subscribed = customer?.subscribed;
  const logo = customer?.logo;

  return (subscribed && logo) ? logo : 'PORTRAIT';
};

/**
 *
 */
export const getLogoImage = (customer: CustomerType | undefined) => {
  const subscribed = customer?.subscribed;
  const logo = customer?.logo;
  return subscribed && logo ? logo : '/assets/icons/gt-logo.svg';
};

/**
 * 
 * @param eventTargetElementElementL 
 * @returns 
 */
const closeQubeCluster = (eventTargetElementElement: HTMLElement) => {
  sessionStorage.removeItem('cluster');
  
  const content = eventTargetElementElement;
  const current = content.querySelector(`div.content.active`);
  current?.classList.remove('active');
  content.querySelector('.qube-list-content')?.classList.remove('close');
  content.querySelector('.return-to-list')?.classList.remove('show');
  content.classList.remove('active');
};

/**
 *
 */
export const initQubeClusters = (
  map: google.maps.Map,
  setQubeClusters: (clusters: MarkerClusterer) => void,
  setMobileClusterMarkers: (markers: CustomerMarker[]) => void,
  closeQube: () => void,
  isObserver: boolean,
  token: string,
) => {
  const onClusterClick = (_, c) => {
    const md = new MobileDetect(window.navigator.userAgent);
    const clusterModalElement = document.querySelector('#QUBE_CLUSTER_MODAL') as HTMLElement; 
    
    if (md.mobile()) {
      const markers = c.markers as CustomerMarker[];
      setMobileClusterMarkers(!clusterModalElement ? markers : []);  
      return;
    }

    closeQube();
    const target = _.domEvent.currentTarget as HTMLElement;
    const anotherOpens = document.querySelector('div.GT-QUBE-CLUSTER.active') as HTMLElement;
    const eventTargetElementElement = target.querySelector('div.GT-QUBE-CLUSTER') as HTMLElement;
    
    if (eventTargetElementElement.classList.contains('active')) {
      map.setOptions({ scrollwheel: true });
      closeQubeCluster(eventTargetElementElement);
      return;
    }

    if (anotherOpens) closeQubeCluster(anotherOpens);

    window.sessionStorage.setItem('cluster', eventTargetElementElement.id);
    eventTargetElementElement.classList.add('active');
    map.setOptions({ scrollwheel: false });
  };
  
  const cluster = new MarkerClusterer({
    map,
    renderer: new IconRenderer(isObserver, token, map),
    algorithm: new GridAlgorithm({}),
  });
    
  cluster.onClusterClick = onClusterClick;
  setQubeClusters(cluster);
};
