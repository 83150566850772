import { assignQubes, unassignQubes } from "app/services/projects.service";

/**
 * 
 */
export const handleConfirmDevices = async (token: string, projectId: number, devices: string[]) => {
  await assignQubes(token, projectId, devices);
};

/**
 * 
 */
export const handleRemoveDevices = async (token: string, projectId: number, devices: string[]) => {
  await unassignQubes(token, projectId, devices);
};