import { formatDistance, formatTimezoneDate } from '@/lib/formatter';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RunType } from '../../../../../interfaces/inspection.interfaces';
import { centerMap, init } from './project.controller';
import {
  Details,
  Footer,
  InfoGroup,
  Main,
  Modal,
  Run,
  RunTitle,
  Wrapper,
} from './project.style';
import SettingsStore from 'app/modules/settings/settings.context';

const units = {
  ft: 'mi',
  mi: 'mi',
  m: 'km',
  km: 'km',
};

interface Props {
  runs?: RunType[];
  map: google.maps.Map;
}

const Project = ({ runs, map }: Props) => {
  const history = useHistory();
  const settingsContext = useContext(SettingsStore);
  const [selectedRuns, setSelectedRuns] = useState<RunType[]>([]);

  // load modules
  useEffect(() => {
    if (runs) {
      init({ map, runs, history, setSelectedRuns });
      centerMap({ runs, map });
    }
  }, [runs]);

  return (
    <Wrapper
      displayNone={!selectedRuns.length}
      onClick={(e: any) => {
        e.stopPropagation();
        setSelectedRuns([]);
      }}
    >
      <Modal onClick={(e: any) => e.stopPropagation()}>
        {selectedRuns.map((run: RunType) => (
          <Run onClick={() => history.push(`/runs/${run.id}/`)} key={run.id}>
            <Main>
              <RunTitle>{run.name}</RunTitle>
              <Details>
                <InfoGroup>
                  <strong>{'Scheduled to: '}</strong>
                  {formatTimezoneDate({
                    date: `${run?.scheduled_launch_at}`,
                    timezone: settingsContext.state.timezone?.id || '',
                    format: 'MM/DD HH:mm:ss',
                  })}
                </InfoGroup>
                <InfoGroup>
                  <strong>{'Total Length: '}</strong>
                  {formatDistance({
                    distance: run.length,
                    unit: units[
                      (settingsContext.state.distanceUnit?.id || 'ft') as
                      | 'ft'
                      | 'mi'
                      | 'm'
                      | 'km'
                    ],
                  })}
                </InfoGroup>
              </Details>
            </Main>
            <Footer>
              {`Run is ${Math.ceil(
                ((run.completed_points || 0) / (run.total_points || 0)) * 100
              )}% complete (${run.completed_points} of ${run.total_points})`}
            </Footer>
          </Run>
        ))}
      </Modal>
    </Wrapper>
  );
};

export default Project;
