import { permissions } from "app/modules/account/account.context";

export const links = [
  {
    id: 'projects',
    href: '/projects',
    iconType: 'projects',
    text: 'Projects',
    mobile: true,
  },
  {
    id: 'calendar',
    href: '/calendar',
    iconType: 'calendar',
    text: 'Calendar',
    mobile: true,
  },
  {
    id: 'devices',
    href: '/customer/:customer_id/devices',
    iconType: 'devices',
    roles: [permissions.CUSTOMER_MANAGER],
    text: 'Devices',
    mobile: true,
  },
  {
    id: 'people',
    href: '/people',
    iconType: 'people',
    text: 'People',
    mobile: false,
  },
  {
    id: 'surveys',
    href: '/surveys',
    iconType: 'surveys',
    roles: [permissions.SURVEY_MANAGER, permissions.CUSTOMER_MANAGER],
    text: 'Surveys',
    mobile: true,
  },
  {
    id: 'docs',
    href: '/docs/',
    iconType: 'documentation',
    text: 'Documentation',
    external: true,
    mobile: true,
  },
];
