import { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
import { RunCascadeType } from '../inspection.interfaces';

/**
 * @param run
 * @returns
 */
export const getBreadcrumbs = (run: RunCascadeType): Breadcrumb[] => [
  {
    id: '#1',
    text: 'Projects',
    href: `/projects`,
    loading: false,
  },
  {
    id: '#2',
    text: run?.project?.name || '',
    href: `/projects/${run?.project?.id}`,
    loading: !run?.project,
  },
  {
    id: '#3',
    text: run?.name || '',
    href: `/runs/${run?.id}`,
    loading: !run,
  },
];
