import styled from 'styled-components';

export const Widget = styled.li`
  display: grid;
  align-items: center;
  grid-template-columns: 30px auto;

  padding: 4px 6px;
  gap: 8px;

  > figure {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      max-width: 100%;
      padding: 5px;
    }

    &.large {
      width: 30px;

      > img {
        padding: 0;
      }
    }

    &.xlarge {
      /* width: 70px; */

      > img {
        padding: 0;
      }
    }

    > svg {
      /* width: 100%; */
    }
  }
`;

export const WidgetContent = styled.div`
  display: block;
`;

export const WidgetValue = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;

  white-space: nowrap;
  display: block;
  /* color: #ffffff; */
`;

export const WidgetLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;

  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;

  color: rgba(26, 31, 54, 0.65);
`;
