import { createGlobalStyle } from 'styled-components'

export const QubeGlobalStyle = createGlobalStyle`
  .GT-QUBE-MARKER {
    transition-duration: 0.3s;
    z-index: 30;
    
    &.active,
    &:hover {
      z-index: 49; // ensure that the marker is above the other markers
    }

    > div.marker {
      position: relative;
      top: -8px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      
      width: 63px;
      height: 34px;
      padding: 4px, 8px, 4px, 8px;
      border-radius: 40px;
      background-color: ${({ theme }) => theme.colors.surface_white};
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
      transition-duration: 0.3s;

      > span.status {
        position: absolute;
        top: -1px;
        left: -1px;

        width: 14px;
        height: 14px;
        border-radius: 50%;

        background-color: #a4abb6;
        border: solid 1px #4d5158;
      }

      &.online > span.status {
        background-color: #56f000;
        border-color: #308c04;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
        border-width: 4px;
        border-style: solid;
        border-color: transparent transparent white transparent;
      }

      > span {
        display: flex;
      }

      > span > svg {
        height: 16px;
      }

      > span > h4 {
        ${({ theme }) => theme.fonts.typograph.body2};
        font-size: 10px;
        line-height: 1rem;
        font-weight: 500;
      }

      &.armed {
        background-color: #d30000;

        > span > h4 {
          color: ${({ theme }) => theme.colors.surface_white};
        }

        &:hover {
          color: ${({ theme }) => theme.colors.surface_white};

          > span > svg > path {
            fill: ${({ theme }) => theme.colors.surface_white};
          }
        }

        &:before {
          border-color: transparent transparent #d30000 transparent;
        }

        > span > svg > path {
          fill: ${({ theme }) => theme.colors.surface_white};
        }
      }
    }
  }

  .GT-QUBE-MARKER {
    &.active > .content {
      display: block;
    }
  }

  .GT-QUBE-CLUSTER {
    > .content.active {
      display: block;
    }
  }

  .GT-QUBE-MARKER,
  .GT-QUBE-CLUSTER {
    > button.exit,
    > button.return-to-list {
      display: none;
      cursor: pointer;

      position: absolute;
      top: 105%;
      right: -120px;

      background-color: #ececec;
      color: #000000;

      transition-duration: 0.3s;
      border-radius: 50%;

      width: 40px;
      height: 40px;

      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);

      &:hover {
        background-color: #000000;
        color: #ffffff;
      }
    }

    &.active > button.exit {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active > div.marker,
    &:hover > div.marker {
      background-color: #323030;

      > span > h4 {
        color: ${({ theme }) => theme.colors.surface_white};
      }

      > span > svg > path {
        fill: #ffffff;
      }

      &:before {
        border-color: transparent transparent #323030 transparent;
      }
    }

    > .content {
      position: absolute;
      left: calc(50% - 96px);
      top: 105%;
      
      display: none;
      width: 200px;
      background-color: ${({ theme }) => theme.colors.surface_white};
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

      > div {
        padding-top: 8px;
      }

      > header {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
        border-bottom: solid 1px #ececec;
        
        > div {
          display: flex;
          align-items: center;
          gap: 4px;

          > h4 {
            ${({ theme }) => theme.fonts.typograph.body2};
            font-size: 14px;
            font-weight: 700;
          }

          > div.battery {
            display: flex;
            align-items: center;
            gap: 4px;

            padding: 4px 6px 4px 6px;
            color: #ffffff;
            background-color: #090808;
            border-radius: 40px;

            > span {
              font-size: 10px;
            }
          }
        }
      }

      div.connection {
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 4px;
        
        > span.icon {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 50%;

          background-color: #a4abb6;
          border: solid 1px #4d5158;
        }

        > p {
          ${({ theme }) => theme.fonts.typograph.body2};
          font-size: 12px;
          line-height: 1rem;
          font-weight: 700;
        }
      }

      &.online div.connection > span.icon {
        background-color: #56f000;
        border-color: #308c04;
      }

      div.item {
        padding-left: 8px;
        padding-right: 8px;

        margin-bottom: 4px;
        &.with-copy {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &.arm {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: solid 1px #ececec;
          margin-bottom: 0;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        label {
          ${({ theme }) => theme.fonts.typograph.body2};
          line-height: 1rem;
          color: #595959;      
        }

        p {
          ${({ theme }) => theme.fonts.typograph.body2};
          line-height: 1rem;
          font-weight: 700;
          color: #090808;
        }

        button.copy {
          background-color: transparent;
          border: none;
          padding: none;

          margin-left: 8px;
          cursor: pointer;

          > span.copied {
            background-color: #090808;
            color: ${({ theme }) => theme.colors.surface_white};
            border-radius: 4px;
            padding: 2px 4px;

            font-size: 12px;
          }
        }

        button.arm {
          cursor: pointer;
          position: relative;
          transition-duration: 0.3s;

          display: flex;
          align-content: center;
          
          width: 88px;
          height: 24px;

          border-radius: 37px;
          background-color: #a4abb6;
          color: #a4abb6;
          border: none;
          padding: none;

          @keyframes loop {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          &.waiting {
            opacity: 0.5;
            transition-duration: 0.3s;
            cursor: not-allowed;

            > span.switch {
              display: none; 
            }

            > span.text {
              display: none;
            }

            > span.icon {
              color: #ffffff; 
              font-size: 21px;
              animation: loop 1s linear infinite;
            }

            justify-content: center;
            align-items: center;
          }

          > span.switch {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            box-shadow: 0 4px 8px 0 #09080833;

            background-color: #ffffff;
            z-index: 1;
          }

          > span.text {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 0;
            color: #ffffff;
            font-size: 12px;
            margin-right: 8px;
            margin-left: 8px;
          }

          &.armed {
            background-color: #d30000;
            > span.switch {
              transform: translateX(64px);
            }

            > span.text {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  .GT-QUBE-CLUSTER {
    position: relative;

    > button.exit {
      top: auto;
      bottom: calc(-100% - 12px);
      right: -136px;
    }

    > button.return-to-list.show {
      display: flex;
    }

    > button.return-to-list {
      top: auto;
      bottom: calc(-100% - 60px);
      right: -136px;
      display: none;
      align-items: center;
      justify-content: center;

      &:hover svg path {
        fill: ${({ theme }) => theme.colors.surface_white};
        stroke: ${({ theme }) => theme.colors.surface_white};
      }
    }

    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.surface_white};
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
      transition-duration: 0.3s;

      &::before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
        border-width: 4px;
        border-style: solid;
        border-color: transparent transparent white transparent;
      }

      > span {
        position: absolute;
        top: -5px;
        right: -5px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 18px;
        height: 18px;
        
        border-radius: 50%;
        background-color: #090808;
        border: solid 1px #595959;
        ${({ theme }) => theme.fonts.typograph.body2};
        color: ${({ theme }) => theme.colors.surface_white};
        font-size: 10px;
        font-weight: 700;
      }
    }

    &.active > .marker,
    > .marker:hover { 
      background-color: #323030;

      &::before {
        transition-duration: 0.3s;
        border-color: transparent transparent #323030 transparent;
      }

      > svg > path {
        fill: ${({ theme }) => theme.colors.surface_white};
      }
    }

    > div.qube-list-content {
      display: none;
    }

    &.active > div.qube-list-content {
      display: block;
    }

    > div.content {
      display: none;
      position: absolute;
      bottom: revert-layer;
      margin-top: 10px;

      overflow: hidden;

      > footer {
        padding: 8px;
        background-color: #323030;

        display: flex;
        align-items: center;
        justify-content: space-between;

        > div.counter {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 34px;
          gap: 4px;
          border-radius: 32px;
          border: solid 1px #ffffff;
          color: #ffffff; 
          
          padding-left: 16px;
          padding-right: 16px;

          font-size: 14px;
        }

        > div.arrows {
          display: flex;
          align-items: center;
          gap: 8px;

          > button {
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: #ffffff;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            border: none;
            cursor: pointer;

            > svg {
              width: 16px;
              height: 16px;
            }
          }
        
        }
      }
    }

    > div.qube-list-content {
      position: absolute;

      bottom: revert-layer;
      left: 50%;
      transform: translate(-50%, 0);

      margin-top: 12px;

      width: 200px;
      background-color: ${({ theme }) => theme.colors.surface_white};
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

      color: ${({ theme }) => theme.colors.neutra};

      > header {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 8px;

        padding: 8px;

        border-bottom: solid 1px #ececec;

        > h4 {
          display: flex;
          align-items: center;
          gap: 8px;

          height: 34px;
          border-radius: 32px;
          padding-left: 16px;
          padding-right: 16px;

          ${({ theme }) => theme.fonts.typograph.body2};
          font-size: 14px;
          color: #090808;
          background-color: #ececec;

          > span {
            color: ${({ theme }) => theme.colors.surface_white};
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #090808;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            border: solid 1px #595959;

            font-size: 10px;
            font-weight: 700;
          }
        }
      }

      > ul {
        padding: 8px;
        list-style: none;


        max-height: 250px;
        overflow-y: auto;

        > li:not(:last-child) {
          border-bottom: solid 1px #ececec;
        }

        > li {
          padding-top: 11px;;
          padding-bottom: 11px;;
          padding-left: 8px;
          padding-right: 8px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            display: flex;
            align-items: center;
            gap: 4px;

            > .online,
            > .offline {
              width: 16px;
              height: 16px;
              border-radius: 50%;

              background-color: #a4abb6;
            }

            > .online {
              background-color: #56f000;
            }

            > .baterry {
              display: flex;
              align-items: center;
              margin-right: 12px;

              background-color: #090808;
              padding: 4px 8px;
              height: 20px;
              border-radius: 20px;
            }
          }
        }
      }

      &.close {
        display: none;
      }
    }
  }
`;
