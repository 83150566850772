import {
  SlickCellSelectionModel,
  SlickGrid,
  GridOption,
  SlickAutoTooltips,
} from 'slickgrid';
import GTDataView from './slick-table.dataview';
import { CustomCulumn, CustomSlickGrid, Data, SlickGridMetaData } from './slick-table.interfaces';
import { SlickCellExternalCopyManagerCustom } from './slick.cellexternalcopymanager';

const STORAGE_COLUMN_KEY = Math.random().toString();

/**
 *
 * @param columns
 * @param dispatch
 */
export const init = <T extends Data>(
  columns: CustomCulumn[],
  data: GTDataView<T>,
  option: GridOption,
  onLoad?: (grid: CustomSlickGrid<T>, d: GTDataView<T>) => void,
  metadata?: SlickGridMetaData,
) => {
  const columnsReference = {};
  columns.forEach((c) => {
    columnsReference[c.id] = c;
  });

  // get on local storage potentials configuration saved, to keep
  // user configuration
  const stringColumns = window.localStorage.getItem(STORAGE_COLUMN_KEY);
  let gridColumns: CustomCulumn[];

  // check if there is saved columns configuration
  // ?? this configuration is restricted to the browser
  // if another user is logged should get configuration
  // of preveouly user. would we save this configuration
  // with a user reference
  if (stringColumns) {
    gridColumns = JSON.parse(stringColumns);
    gridColumns = gridColumns.filter((c) => columnsReference[c.id]);
    gridColumns = gridColumns.map((c) => ({
      ...columnsReference[c.id],
      originalWidth: c.originalWidth,
    }));
  } else {
    gridColumns = [...columns];
  }

  // get element to render the slickgrid
  const gridElement = document.querySelector('#grid') as HTMLElement;
  if (!gridElement) throw 'There is not element wrapper to grid';
  
  // init slickgrid
  const grid = new CustomSlickGrid<T>(
    gridElement,
    data,
    gridColumns,
    option,
  );

  if (metadata) {
    grid.metadata = metadata;
  }

  grid.onDblClick.subscribe((e, args) => {
    const column = grid && grid.getColumns && grid.getColumns()[args.cell] as CustomCulumn;
    column?.onCellDblClick?.(e, args);
  });

  grid.onClick.subscribe((e, args) => {
    const column = grid.getColumns && grid.getColumns()[args.cell] as CustomCulumn;
    column?.onCellClick?.(e, args);
  });

  grid.onCellChange.subscribe((e, args) => {
    const column = grid.getColumns && grid.getColumns()[args.cell] as CustomCulumn;
    column?.onCellChange?.(e, args);
  });

  grid.onColumnsReordered.subscribe(() => {
    window.localStorage.setItem(
      STORAGE_COLUMN_KEY,
      JSON.stringify(grid.getColumns())
    );
  });

  grid.onColumnsResized.subscribe(() => {
    window.localStorage.setItem(
      STORAGE_COLUMN_KEY,
      JSON.stringify(grid.getColumns())
    );
  });

  const pluginOptions = {
    // readOnlyMode : false,
    includeHeaderWhenCopying : false,
  };
  grid.registerPlugin(new SlickAutoTooltips());

  // register copy pasta plugin
  grid.setSelectionModel(new SlickCellSelectionModel());

  const slickCellExternalCopyManager = new SlickCellExternalCopyManagerCustom(pluginOptions);

  grid.registerPlugin(slickCellExternalCopyManager);

  // set resize lister
  // TODO: clear listener on unmount, this should be spending client memory
  window.addEventListener('resize', () => {
    grid.resizeCanvas();
  });

  
  // dristribute grid to parents, if necessary
  if (onLoad) onLoad(grid, data);
  grid.render();
};

/**
 *
 * @param grid
 * @param index
 * @param data
 */
export const reloadPoint = <T>(index: number, data: T, grid: SlickGrid) => {
  if (!data) return;

  const columns = grid.getColumns();
  const gridData = grid.getData();
  const rowData = gridData[index];
  gridData[index] = data;

  for (const column of columns as CustomCulumn[]) {
    if (!rowData || rowData[column.field] !== data[column.field]) {
      const col = grid.getColumnIndex(column.field);
      grid.invalidateRow(index)
      grid.updateCell(index, col);
    }

    if (column.affected_by) {
      for (const affected of column.affected_by) {
        if (!rowData || rowData[affected] !== data[affected]) {
          const col = grid.getColumnIndex(column.field);
          grid.invalidateRow(index)
          grid.updateCell(index, col);
        }
      }
    }
  }
  grid.render()
};
