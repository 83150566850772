import { Label, Wrapper } from './checkbox.style';

/**
 * 
 * @param param0 
 * @returns 
 */
const Checkbox = ({ checked, text, onClick, onShiftSlick, customStyle }: any) => {
  /**
   * 
   */
  const handleClick = (e) => {
    if (e.shiftKey) {
      onShiftSlick?.(e);
      return;
    }

    onClick(e);
  };

  return (
    <Wrapper onClick={handleClick} $customStyle={customStyle}>
      {checked ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="18" height="18" rx="3" fill="#5850EC" />
          <path d="M4 8.5L7.33333 12L14 5" stroke="#F7F8F9" strokeWidth="2" />
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1"
            width="16"
            height="16"
            rx="2"
            stroke="#93A3BC"
            strokeWidth="2"
          />
        </svg>
      )}
      {
        text ? (
          <Label>{text}</Label>
        ) : null
      }
    </Wrapper>
  );
};

export default Checkbox;
