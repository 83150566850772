export const roles = {
  admin: {
    label: 'Project Manager',
    permissions: [
      'Edit all project information and participants',
      'Add and edit runs',
      "Launch and finish the project's runs.",
      'Add, delete and edit passages on runs',
      "Edit media, icons and notes on project's runs",
    ],
  },
  editor: {
    label: 'Editor',
    permissions: [
      'Access all project information, its runs and reports',
      'Launch and finish any run in the project',
      'Add, delete and edit data in the tracking sheet ',
      'Edit media, icons and notes in any run in the project',
    ],
  },
  observer: {
    label: 'Observer',
    permissions: [
      'See project and run information',
      'Follow real time updating of new passages on all runs',
      'Navigate through run map and reports',
    ],
  },
};
