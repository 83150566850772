import { createGlobalStyle } from "styled-components";

export const PigMarkerGlobalStyle = createGlobalStyle`
  .GT-PIG-MARKER-ICON {
    position: relative;
    >img {
      position: absolute;
      width: 80px;
      height: 80px;
      top: -40px;
      left: -40px;
    }
  }
`;