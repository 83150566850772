import { MobileInformationBtn } from './mobile-information-button.style';

interface Props {
  onClick: () => void;
}

/**
 *
 * @returns
 */
const MobileInformationButton = (props: Props) => (
  <MobileInformationBtn onClick={props.onClick}>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.124 9C10.124 8.83424 10.0582 8.67527 9.94094 8.55806C9.82373 8.44085 9.66476 8.375 9.499 8.375C9.33324 8.375 9.17427 8.44085 9.05706 8.55806C8.93985 8.67527 8.874 8.83424 8.874 9V12C8.874 12.1658 8.93985 12.3247 9.05706 12.4419C9.17427 12.5592 9.33324 12.625 9.499 12.625C9.66476 12.625 9.82373 12.5592 9.94094 12.4419C10.0582 12.3247 10.124 12.1658 10.124 12V9ZM10.1179 7.61836C10.2819 7.45436 10.374 7.23193 10.374 7C10.374 6.76778 10.2806 6.54559 10.117 6.38199C9.9531 6.21808 9.7308 6.126 9.499 6.126C9.2672 6.126 9.0449 6.21808 8.88099 6.38199C8.71708 6.5459 8.625 6.7682 8.625 7C8.625 7.23193 8.71713 7.45436 8.88113 7.61836C9.04514 7.78237 9.26757 7.8745 9.4995 7.8745C9.73143 7.8745 9.95386 7.78237 10.1179 7.61836ZM9.5 2.375C7.61033 2.375 5.79806 3.12567 4.46186 4.46186C3.12567 5.79806 2.375 7.61033 2.375 9.5C2.375 11.3897 3.12567 13.2019 4.46186 14.5381C5.79806 15.8743 7.61033 16.625 9.5 16.625C11.3897 16.625 13.2019 15.8743 14.5381 14.5381C15.8743 13.2019 16.625 11.3897 16.625 9.5C16.625 7.61033 15.8743 5.79806 14.5381 4.46186C13.2019 3.12567 11.3897 2.375 9.5 2.375ZM3.625 9.5C3.625 8.72848 3.77696 7.96452 4.07221 7.25173C4.36745 6.53895 4.8002 5.89129 5.34575 5.34575C5.89129 4.8002 6.53895 4.36745 7.25173 4.07221C7.96452 3.77696 8.72848 3.625 9.5 3.625C10.2715 3.625 11.0355 3.77696 11.7483 4.07221C12.4611 4.36745 13.1087 4.8002 13.6543 5.34575C14.1998 5.89129 14.6325 6.53895 14.9278 7.25173C15.223 7.96452 15.375 8.72848 15.375 9.5C15.375 11.0581 14.756 12.5525 13.6543 13.6543C12.5525 14.756 11.0581 15.375 9.5 15.375C7.94185 15.375 6.44752 14.756 5.34575 13.6543C4.24397 12.5525 3.625 11.0581 3.625 9.5Z" fill="#4D5158" stroke="#4D5158" strokeWidth="0.25"/>
    </svg>
  </MobileInformationBtn>
);

export default MobileInformationButton;
