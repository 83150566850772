import styled from 'styled-components';

export const UnauthorizedText = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #161e2e;

  text-align: center;
`;

export const UnauthorizedIllustration = styled.svg`
  display: block;

  width: 100%;
  max-width: 300px;

  margin-left: auto;
  margin-right: auto;

  opacity: 0.6;

  margin-bottom: 32px;
  margin-top: 18vh;
`;
