import { NotificationContext, NotificationDispatchType, Toast } from './notification.context.d';

/**
 * 
 * @param state
 * @param action 
 * @returns toast context state
 */
export const reducer = (
  state: NotificationContext,
  action: NotificationDispatchType
): NotificationContext => {
  switch (action.type) {
    case 'SET_TOAST':
      if (action.data)
        return { ...state, toast: action.data as Toast };
      return state;
    case 'CLOSE_TOAST':
      return { ...state, toast: undefined };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};
