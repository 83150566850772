import styled from 'styled-components';

export const ConnectionErrorLabel = styled.label<{ $show: boolean }>`
  display: inline-block;

  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  color: #ffffff;
  background-color: #dc143c;

  padding: 5px 10px;
  border-radius: 8px;
  
  ${({ $show }) => ($show ? '' : 'display: none;')}
`;

export const ConnectionErrorLabelTimezone = styled.label`
  font-size: 10px;
  padding-left: 5px;
  font-weight: normal;
`;
