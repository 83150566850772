import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL || '/api';

interface ProjecInvitationType {
  emails: string[];
  projects: number[];
  editor: boolean;
  admin: boolean;
}

/**
 * request to django api send to an email list a project invitation
 * @param invitation invitation data
 * @returns promise that contains status of an invitation
 */
export const createProjectInvitation = (
  invitation: ProjecInvitationType,
  token: string
) =>
  axios.post(`${API_URL}/projectinvitations/`, invitation, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

/**
 * request to django validate the token and invitation data
 * @param token invitation token
 * @returns promise that contains status of an invitation
 */
export const getProjectInvitation = (token: string) =>
  axios.get(`${API_URL}/invitations/${token}/`);

/**
 * request to django accept the invitation
 * @param token invitation token
 * @returns promise that contains status of an invitation
 */
export const acceptProjectInvitation = (token: string, data?: any) =>
  axios.post(`${API_URL}/invitations/${token}/accept/`, data);

/**
 * request to django decline the invitation
 * @param token invitation token
 * @returns promise that contains status of an invitation
 */
export const declineProjectInvitation = (token: string) =>
  axios.post(`${API_URL}/invitations/${token}/reject/`);

/**
 * request to django generate a shareable link to the invitation
 * @param project id of project to generate link
 * @returns promise that contains status of an invitation
 */
export const generateShareableLink = (
  project: string,
  editor: boolean,
  admin: boolean,
  authToken: string
) =>
  axios.post(
    `${API_URL}/projectinvitations/shareable/`,
    {
      project,
      editor,
      admin,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    }
  );

export const getShareableInvitation = (token: string) =>
  axios.get(`${API_URL}/shareable-invitations/${token}/`);

/**
 * request to django accept the invitation
 * @param token invitation token
 * @returns promise that contains status of an invitation
 */
export const acceptShareableInvitation = (token: string, data?: any) =>
  axios.post(`${API_URL}/shareable-invitations/${token}/accept/`, data);
