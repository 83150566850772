import styled from 'styled-components';

export const ModalWrapper = styled.div<{ opened: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 30;
`;

export const Modal = styled.div`
  width: 100%;
  max-height: 90%;
  max-width: 520px;

  overflow: auto;

  background: #ffffff;

  border-radius: 6px;

  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const ModalHeader = styled.header`
  padding: 32px;

  position: sticky;
  top: 0;
  left: 0;

  z-index: 3;
  background-color: #ffffff;
`;

export const ModalFooter = styled.footer`
  background-color: #f7f8f9;
  padding: 16px 24px;

  position: sticky;
  bottom: -1px;
  left: 0;
`;

export const ModalContent = styled.div`
  position: relative;

  background-color: #f7f8f9;
  border-radius: 0 0 6px 6px;
`;

export const ModalTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;

  color: #161e2e;

  margin-bottom: 5px;

  &:active {
    padding: 0;
    border: none;
  }

  &:focus {
    padding: 0;
    border: none;
  }
`;

export const ModalDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #686c7b;
`;

export const UserInformations = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  margin-top: 16px;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Name = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #4b5066;
`;

export const Email = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #4b5066;
`;

export const Ball = styled.span`
  display: none;

  width: 4px;
  height: 4px;
  left: 691px;
  top: 354px;

  border-radius: 50%;

  background: #c4c4c4;

  @media (min-width: 1024px) {
    display: inline;
  }
`;

export const ProjectList = styled.ul``;

export const ProjectListHeader = styled.header`
  display: flex;
  justify-content: space-between;

  border-top: solid 1px #dfe4ea;
  border-bottom: solid 1px #dfe4ea;

  padding: 11px 30px;
`;

export const ProjectListTitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: rgba(26, 31, 54, 0.65);
`;

export const ProjectListItem = styled.li<{ focus: boolean }>`
  padding: 9px 30px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: ${({ focus }) => (!focus ? 'rgba(26, 31, 54, 0.65)' : '#5850ec')};

  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    gap: 16px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;

  position: absolute;
  top: 16px;
  right: 16px;

  cursor: pointer;
`;

export const EmptyProjectsWrapper = styled.div`
  text-align: center;
  padding: 32px 16px 16px 16px;
`;

export const EmptyText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: rgba(26, 31, 54, 0.65);
`;
