import { TrackingPointCascadeType } from "app/modules/inspection/inspection.interfaces";
import { getTrackingpointIcon } from "app/utils/map.utils";
import { captureException } from "@sentry/react";

/**
 *
 * @param tp
 * @param state
 * @param setSelectedTimePin
 * @param map
 * @returns
 */
export const createMarkerElement = (
  tp: TrackingPointCascadeType,
  icon: google.maps.Icon | undefined,
) => {

  const markerWrapper = document.createElement('div');
  const markerEl = document.createElement('img');

  markerEl.setAttribute('src', icon?.url || '');
  markerWrapper.setAttribute('class', 'GT-TRACKING-POINT-MARKER-ICON');
  markerWrapper.appendChild(markerEl)
  const label = document.createElement('label');
  label.innerText = tp.name as string;
  label.setAttribute('class', 'display-none');
  markerWrapper.appendChild(label);

  return markerWrapper;
};

export const toggleLabel = (marker: google.maps.marker.AdvancedMarkerElement, hasLabels: boolean) => {
  if (hasLabels && marker) {
    const label = marker.getElementsByTagName('label')[0];
    label?.classList.remove("display-none");
  }
  if (!hasLabels && marker) {
    const label = marker.getElementsByTagName('label')[0];
    label?.classList.add("display-none");
  }
}

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderMarker = async (
  setIsClickced: (tstmp: number) => void,
  point: TrackingPointCascadeType,
  map: google.maps.Map,
  setMarker: (m: google.maps.marker.AdvancedMarkerElement) => void,
  index: number,
  oldmarker?: google.maps.marker.AdvancedMarkerElement,
) => {
  if (oldmarker) {
    oldmarker.map = null;
  }
  let AdvancedMarkerElement;
  try {
    const markerLib = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    AdvancedMarkerElement = markerLib.AdvancedMarkerElement as google.maps.MarkerLibrary['AdvancedMarkerElement'];

  } catch (err) {
    captureException(err);
  }

  if (!point) return;
  const mapZoom = map.getZoom();

  const icon = getTrackingpointIcon(point, undefined, mapZoom === 6 ? 15 : mapZoom); // TODO TYPE

  const marker = new AdvancedMarkerElement({
    map,
    gmpClickable: true,
    content: createMarkerElement(point, icon),
    // scale: 100,
  })  as google.maps.marker.AdvancedMarkerElement;
  

  marker.zIndex = index + 3;
  marker.onclick = () => setIsClickced(Date.now());
  setMarker(marker);
  return marker;
};
