import { MaterialIcon } from 'material-icons';
import { Button } from './icon-button.style';

interface Props {
  onClick: () => void;
  styleType?: 'primary' | 'secondary' | 'transparent';
  disabled?: boolean; 
  icon?: MaterialIcon;
}

/**
 * 
 * @param props 
 */
const IconButton = ({
  onClick,
  styleType = 'primary',
  disabled = false,
  icon = undefined,
}: Props) => {
  return (
    <Button
      type="button"
      disabled={disabled}
      onClick={onClick}
      $style={styleType}
    >
      {icon ? <span className="material-icons-outlined">{icon}</span> : null}
    </Button>
  );
};

export default IconButton;