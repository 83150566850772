import styled from 'styled-components';

const bgColors = { default: 'surface_white', confirm: 'surface_white', cancel: 'surface_white' };
const textColors = { default: 'neutra_900', confirm: 'primary', cancel: 'system_danger' };
const bgHoverColors = { default: 'neutra_700', confirm: 'primary', cancel: 'system_danger' };
const bgActiveColors = { default: 'neutra_900', confirm: 'primary', cancel: 'system_danger' };

export const Button = styled.button<{ $style: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: ${({ theme, $style }) => theme.colors[bgColors[$style]]};

  border: 1px solid ${({ theme }) => theme.colors.neutra_300};
  border-radius: 6px;

  color: ${({ theme, $style }) => theme.colors[textColors[$style]]};
  cursor: pointer;

  transition-duration: 400ms;

  &:hover {
    color: ${({ theme }) => theme.colors.surface_white};
    background-color: ${({ theme, $style }) => theme.colors[bgHoverColors[$style]]};
  }
  
  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.surface_white};
    background-color: ${({ theme, $style }) => theme.colors[bgActiveColors[$style]]};
  }
  
  &:disabled {
    background-color: ${({ theme }) => theme.colors.surface_white};
    color: ${({ theme }) => theme.colors.neutra_100};
    border-color: ${({ theme }) => theme.colors.neutra_100};
    cursor: not-allowed;
  }

  > span.material-icons-round {
    font-size: 24px;
  }
`; 
