import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InputGroup from '../input-group/inputGroup';
import Button from '../button/button';
import ShareableLink from '../shareable-link/shareable-link';
import { GeneratedType } from '../shareable-link/shareable-link.d';
import {
  getPredictUsers,
  sendInvitations,
} from './invitation-modal.controller';
import { PredictUserType, RoleType } from './invitation-modal.d';
import {
  CloseButton,
  Email,
  Error,
  ModalWrapper,
  Modal,
  ModalHeader,
  ModalContent,
  ModalTitle,
  MultiSelectInputWrapper,
  InviteForm,
  Input,
  PredictUsersWrapper,
  ShareableLinkWrapper,
  OrWrapper,
  ModalDescription,
} from './invitation-modal.style';
import PredictUserCard from './components/predict-user-card/predict-user-card';
import RoleSelector from './components/role-selector/role-selector';
import { AuthType } from 'app/modules/account/account.context.d';
import NotificationStore from 'app/modules/notification/notification.context';

interface Props {
  opened: boolean;
  onClose: () => void;
  links: GeneratedType;
  auth: AuthType;
}

interface ErrorType {
  role?: string;
  email?: string;
}

const renderPredictedUsers = (
  users: PredictUserType[],
  onAdd: (email: string) => void
) =>
  users.map((user) => (
    <PredictUserCard
      key={user.email}
      avatar={user.avatar}
      email={user.email}
      first_name={user.first_name}
      last_name={user.last_name || ''}
      onClick={() => onAdd(user.email)}
    />
  ));

const renderSelecteds = (
  emails: string[],
  onRemove: (emailIndex: number) => void
) =>
  emails.map((email, index) => (
    <Email key={email}>
      {email}
      <svg
        onClick={() => onRemove(index)}
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="16.2617"
          y="4.94922"
          width="2"
          height="16"
          transform="rotate(45 16.2617 4.94922)"
          fill="#99A2AD"
        />
        <rect
          x="17.6758"
          y="16.2637"
          width="2"
          height="16"
          transform="rotate(135 17.6758 16.2637)"
          fill="#99a2ad"
        />
      </svg>
    </Email>
  ));

const InviteModal = ({ onClose, opened, links, auth }: Props) => {
  const { id } = useParams<{ id: string }>();
  const notificationContext = useContext(NotificationStore);
  const [emailText, setEmailText] = useState<string>('');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [predictedUsers, setPredictedUsers] = useState<PredictUserType[]>([]);
  const [selectedRole, setSelectedRole] = useState<RoleType | null>(null);
  const [error, setError] = useState<ErrorType>({});
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setSelectedEmails([]);
    setSelectedRole(null);
    setEmailText('');
    setError({});
  }, [opened]);

  const handleRemove = (emailIndex: number) => {
    const newSelectedEmails = [...selectedEmails];
    newSelectedEmails.splice(emailIndex, 1);
    setSelectedEmails(newSelectedEmails);
  };

  const getError = () => {
    if (error.email && error.role) return 'Email and role not selected';
    if (error.email) return error.email;
    if (error.role) return error.role;
    return undefined;
  };

  return (
    <ModalWrapper opened={opened} onClick={onClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Invite Users</ModalTitle>
          <ModalDescription>
            {`Send the invitation to the user's e-mail or share a link to invite them. `}
          </ModalDescription>
          <CloseButton onClick={onClose}>
            <svg
              viewBox="0 0 23 23"
              width="23"
              height="23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="16.2617"
                y="4.94922"
                width="2"
                height="16"
                transform="rotate(45 16.2617 4.94922)"
                fill="#99A2AD"
              />
              <rect
                x="17.6758"
                y="16.2637"
                width="2"
                height="16"
                transform="rotate(135 17.6758 16.2637)"
                fill="#99a2ad"
              />
            </svg>
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          <ShareableLinkWrapper>
            <ShareableLink
              authToken={auth.token}
              project={id}
              links={links}
            />
          </ShareableLinkWrapper>

          <OrWrapper>
            <hr />
            <span>or</span>
            <hr />
          </OrWrapper>

          <InviteForm>
            <InputGroup
              label="Email"
              description="Press ENTER or SPACE to confirm"
              customStyle={`
                p {
                  color: #000000;
                  font-size: 10px;
                  margin-top: 8px;
                }

                .input-group-error {
                  display: inline-block;
                  margin-top: 16px;
                  margin-bottom: 8px;

                  font-size: 12px;
                  font-weight: 500;
                }
              `}
            >
              <MultiSelectInputWrapper tabIndex={1} focus={focus}>
                <div>
                  <ul>{renderSelecteds(selectedEmails, handleRemove)}</ul>
                  <Input
                    value={emailText}
                    onFocus={() => {
                      setFocus(true);
                    }}
                    onBlur={() => {
                      setFocus(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();

                        const emailRxp =
                          /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

                        if (!emailRxp.test(emailText)) {
                          setError({ email: 'This is not a valid email' });
                          return;
                        }

                        setError({ email: undefined });

                        setSelectedEmails([
                          ...selectedEmails,
                          emailText.toLowerCase(),
                        ]);
                        setEmailText('');
                      }
                    }}
                    placeholder={'Enter email address'}
                    onChange={(e) => {
                      setEmailText(e.target.value);

                      if (e.target.value) {
                        getPredictUsers(
                          e.target.value,
                          auth.token,
                          setPredictedUsers
                        );
                      } else {
                        setPredictedUsers([]);
                      }
                    }}
                  />
                </div>
                <RoleSelector
                  selected={selectedRole}
                  onSelect={(role: RoleType | null) => setSelectedRole(role)}
                />
              </MultiSelectInputWrapper>
            </InputGroup>

            <PredictUsersWrapper>
              {renderPredictedUsers(predictedUsers, (email: string) => {
                setSelectedEmails([...selectedEmails, email]);
                setPredictedUsers([]);
                setError({ email: undefined });
                setEmailText('');
              })}
            </PredictUsersWrapper>

            <Button
              disabled={!auth}
              customStyle={`
                width: auto;
                height: 30px;

                padding-left: 32px;
                padding-right: 32px;

                margin-top: 8px;

                &:focus {
                  padding-left: 32px;
                  padding-right: 32px;

                  height: 30px;
                }
              `}
              type="button"
              handleClick={() => {
                const error: ErrorType = {};

                if (!selectedRole) {
                  error.role = 'Role not selected';
                }

                if (!selectedEmails.length) {
                  error.email = 'No one email selected';
                }

                if (!selectedRole || Object.keys(error).length) {
                  setError(error);
                  return;
                }

                sendInvitations(
                  selectedEmails,
                  selectedRole,
                  +id,
                  auth.token
                );

                notificationContext.dispatch({
                  type: 'SET_TOAST',
                  data: {
                    type: 'success',
                    text: 'Invitation sent!'
                  },
                });

                onClose();

                setSelectedEmails([]);
                setSelectedRole(null);
                setEmailText('');
              }}
              value="Send invite"
            />

            {getError() ? <Error>{getError()}</Error> : null}
          </InviteForm>
        </ModalContent>
      </Modal>
    </ModalWrapper>
  );
};

export default InviteModal;
