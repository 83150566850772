import { PipelineType } from 'app/interfaces/inspection.interfaces';
import moment from 'moment-timezone';

interface formatTimezoneDateProps {
  date: Date | string | number | null | undefined;
  timezone?: string;
  format: string;
}

export const formatTimezoneDate = ({
  date,
  timezone,
  format,
}: formatTimezoneDateProps) => {
  let fixedDate: string | undefined = undefined;
  if (!date || !format) return '';

  if (typeof date === 'string' && date.indexOf('+') !== -1) {
    const charIndex = date.indexOf('+');
    fixedDate = date.substring(0, charIndex);

    const momentDate = moment.utc(fixedDate);
    momentDate.tz(timezone || 'UTC');
    return `${momentDate.format(format)}`;
  }

  return `${moment.tz(date, timezone || 'UTC').format(format)}`;
};

interface formatMpsProps {
  distance: any;
  unit: any;
  returnNumber?: boolean;
}

export const formatMps = ({
  distance,
  unit,
  returnNumber,
}: formatMpsProps): string | number => {
  if (!unit?.label && !unit?.id) return '';
  if (!distance && distance !== 0) return '';

  const handlers: { [index: string]: any } = {
    kmh: (value: number): any => (+value * 3.6).toFixed(2),
    mph: (value: number): any => (+value * 2.237).toFixed(2),
    mps: (value: number): any => +value.toFixed(2),
  };

  const defaultResp = (): any => '';
  const converter = handlers[`${unit.id}`] || defaultResp;

  if (returnNumber) return converter(+distance);
  return `${converter(+distance)} ${unit.label}`;
};

interface formatSpeedToMps {
  distance: any;
  unit: string;
  returnNumber?: boolean;
}

export const formatSpeedToMps = ({
  distance,
  unit,
}: formatMpsProps): string => {
  if (!unit) return '';
  if (!distance && distance !== 0) return '';

  const handlers: { [index: string]: any } = {
    kmh: (value: number): any => (+value / 3.6).toFixed(2) || '',
    mph: (value: number): any => (+value / 2.237).toFixed(2) || '',
    mps: (value: number): any => +value.toFixed(2) || '',
  };

  const defaultResp = (): any => '';
  const converter = handlers[`${unit}`] || defaultResp;

  return converter(+distance);
};

interface formatTimezoneDateFromNowProps {
  date: Date;
  timezone: string;
  isNext: boolean;
}

export const formatTimezoneDateFromNow = ({
  date,
  timezone,
  isNext,
}: formatTimezoneDateFromNowProps) => {
  if (!date || !timezone) return '';
  if (!isNext) return `${moment.tz(date, timezone).fromNow()}`;

  const diff = new Date(date).getTime() - new Date().getTime();
  const sig = diff < 0 ? '-' : '';
  const secs = Math.abs(Math.round(diff / 1000));
  const seconds = secs % 60;
  const minutes = Math.round(secs / 60) % 60;
  const hours = Math.round(secs / 3600);

  let fmt = seconds > 9 ? `${seconds}` : `0${seconds}`;
  if (minutes) fmt = minutes > 9 ? `${minutes}:${fmt}` : `0${minutes}:${fmt}`;
  if (hours) fmt = `${hours}:${fmt}`;

  return `${sig}${fmt}`;
};

export const formatTimezoneDateFromNowTime = ({
  date,
  timezone,
}: formatTimezoneDateFromNowProps) => {
  if (!date || !timezone) return '';
  const nowTime = new Date().getTime();
  const dateTime = new Date(date).getTime();

  const timeDiff = nowTime > dateTime ? nowTime - dateTime : dateTime - nowTime;

  const days = Math.floor(timeDiff / 86400000); // 86400000 = miliseconds in a day
  const daysRemainingTime = timeDiff % 86400000;

  const hours = Math.floor(daysRemainingTime / 3600000); // 3600000 = miliseconds in a hour
  const hoursRemainingTime = daysRemainingTime % 3600000;

  const minutes = Math.floor(hoursRemainingTime / 60000); // 60000 = miliseconds in a minute
  const minutesRemainingTime = hoursRemainingTime % 60000;

  const seconds = Math.floor(minutesRemainingTime / 1000); // 1000 = miliseconds in a second

  const addZero = (number: any) => {
    let myNumber = number;
    const isPositive = number > 0;
    if (!isPositive) myNumber = -number;
    return myNumber < 9 && myNumber > 0 ? `0${myNumber}` : `${myNumber}`;
  };

  return `${addZero(days)}:${addZero(hours)}:${addZero(minutes)}:${addZero(
    seconds
  )} ${seconds < 0 ? 'ago' : ''}`;
};

interface formatDistanceProps {
  distance: any;
  unit?: string;
  returnNumber?: boolean;
  biggerNumber?: boolean;
}
export const formatDistance = ({
  distance,
  unit,
  returnNumber,
  biggerNumber,
}: formatDistanceProps): string | number => {
  if (!unit) return '';
  if (!distance && distance !== 0) return '';

  const getUnit = {
    ft: () => biggerNumber ? 'mi' : 'ft',
    m: () => biggerNumber ? 'km' : 'm',
    km: () => 'km',
    mi: () => 'mi',
  };

  const normalizedUnit = unit ? getUnit[unit]() : ''

  const handlers: { [index: string]: any } = {
    ft: (value: any): any => (parseFloat(value) * 3.28084).toFixed(2) || '',
    m: (value: any): any => parseFloat(value).toFixed(2) || '',
    km: (value: any): any => (parseFloat(value) / 1000).toFixed(2) || '',
    mi: (value: any): any =>
      (parseFloat(value) * 0.0006213727366498068).toFixed(2) || '',
  };

  const defaultResp = (): any => '';
  const converter = handlers[normalizedUnit.toString()] || defaultResp;
  if (returnNumber) return converter(+distance);
  return `${converter(distance)} ${normalizedUnit}`;
};

/**
 *
 * extractNumbers - that function extract all numbers of an string
 *
 * @function extractNumbers
 * @param  {string} text  that function extract all numbers of an string
 * @returns {string} it's the string that have only the filtered numbers
 */
export const extractNumbers = (text: string, onlyNombers?: boolean) => {
  let number = '';

  Object.keys(text).forEach((i: any) => {
    const n = text[i];
    if (
      parseInt(n, 10) ||
      parseInt(n, 10) === 0 ||
      (!onlyNombers && n === '+') ||
      (!onlyNombers && n === ':') ||
      (!onlyNombers && n === '-') ||
      (!onlyNombers && n === '.')
    )
      number += n;
  });

  return number;
};

/**
 *
 * formatPipePointsToPath
 *
 * @function formatPipePointsToPath
 * @param  {string} text  that function extract all numbers of an string
 * @returns {string} it's the string that have only the filtered numbers
 */
export const formatPipePointsToPath = (PipePoints: PipelineType) =>
  PipePoints.geometry?.coordinates.map((p: any) => ({
    lng: p[0],
    lat: p[1],
  }));
