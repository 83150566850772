import styled from 'styled-components';

export const HelpButton = styled.button<{ $hidden?: boolean; $show: boolean }>`
  position: relative;
  width: 30px;
  height: 30px;

  background-color: ${({ theme, $show }) => !$show ? 'rgba(62, 66, 85, 0.65)' : theme.colors.primary};

  border-radius: 100%;
  font-size: 17px;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  backdrop-filter: blur(10px);
  transition: background-color 0.3s;

  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  label {
    cursor: pointer;
  }

  ${({ $hidden }) => ($hidden ? 'display: none;' : '')}
`;

export const HelpContainer = styled.div<{ $show: boolean }>`
  position: absolute;
  width: 750px;
  top: calc(100% + 20px);
  right: -150px;
  padding: 8px 0;

  background-color: ${({ theme }) => theme.colors.surface_white};

  font-size: 17px;

  border-radius: 8px;

  z-index: 10;
  display: ${({ $show }) => ($show ? 'block' : 'none')};

  > header {
    padding: 16px;
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
`;

export const HelpTitle = styled.label`
  width: 100%;
  font-weight: bold;

  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;

  text-align: left;
`;

export const HelpList = styled.ul`
  width: 100%;
`;

export const HelpCard = styled.li`
  display: grid;
  grid-template-columns: 50px 1fr;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  justify-content: space-between;
`;

export const HelpCardImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const HelpTextWrapper = styled.div`
  width: 100%;
`;

export const HelpCardTitle = styled.label`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 15px;
`;

export const HelpCardText = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  margin-top: 5px;

  
  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;

    border-radius: 50%;
    background-color: #494c5d;
    margin-right: 5px;
    margin-left: 16px;

    margin-top: 4px;
  }
`;

export const PipelinePiece = styled.div<{ $color: string }>`
  width: 10px;
  height: 35px;
  background-color: ${({ $color }) => $color || '#9acd32'};
  border: 1px solid #444444;
  transform: rotate(142deg);
  margin-left: 10px;
`;

export const PipelineHightLightedPiece = styled.div`
  width: 10px;
  height: 35px;
  background-color: rgba(254, 0, 0, 0.75);
  /* border: 1px solid #444444; */
  box-shadow: 10px 10px 119px 50px rgba(254, 0, 0, 0.85);
`;

export const PipelineHightLightWrapper = styled.div`
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const DrivelineWrapper = styled.div`
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #8a8aff;
  justify-content: center;
  overflow: hidden;
`;

export const DrivelineedPiece = styled.div`
  width: 6px;
  height: 35px;
  background-color: #3333c8;
  /* border: 1px solid #3333c8; */
  transform: rotate(142deg);
  box-shadow: 10px 10px 119px 50px #fff;
`;

export const SeparatorWrapper = styled.div`
  padding: 16px;

  > p {
    color: #090808;

    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 1em;

    text-align: left;

    padding-bottom: 16px;
  }
`;

export const SeparatorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
