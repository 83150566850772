import styled from 'styled-components';

export const GtTable = styled.table<{
  isLoading?: boolean;
  hiddeScroll?: boolean;
  customStyle?: string;
}>`
  position: relative;
  width: 100%;

  ${({ hiddeScroll }) => (hiddeScroll ? '' : 'overflow: auto;')}

  border: solid 1px #dcdfe2;
  ${({ isLoading }) => (isLoading ? 'opacity: 0.5;' : '')}
  ${({ customStyle }) => customStyle}
`;

export const GtTableHead = styled.thead`
  background-color: #ffffff;
  border-bottom: solid 1px #dcdfe2;

  position: sticky;
  top: 0;

  z-index: 3;
  background-color: rgba(220, 223, 226);
`;

export const GtTableBody = styled.tbody``;

export const GtTableRow = styled.tr<{
  isSelected?: boolean;
  onClick?: () => void;
}>`
  /* height: 30px; */
  display: flex;
  flex-wrap: nowrap;

  * {
    ${({ onClick }) => (onClick ? 'cursor: pointer' : '')}
  }

  &:not(:last-child) {
    border-bottom: solid 1px #dcdfe2;
  }

  &:nth-child(even) {
    background-color: rgba(220, 223, 226, 0.35);
  }

  ${({ isSelected }) =>
    isSelected ? 'background-color: rgba(0,0,0, 0.35) !important;' : ''}

  :hover {
    background-color: rgba(220, 223, 226, 0.5);
  }
`;

export const GtTableColumn = styled.td<{
  width?: number;
  removePadding?: string;
  alignTo?: string | undefined;
  ellipsis?: boolean;
}>`
  display: inline-glock;

  min-width: ${({ width }) => width || 200}px;
  min-height: 35px;
  padding: 6px;

  text-align: ${({ alignTo }) => alignTo || 'left'};

  font-size: 13px;
  line-height: 18px;

  ${({ ellipsis }) =>
    ellipsis
      ? `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: no-wrap;
  `
      : null}

  color: #676b7a;

  &:not(:last-child) {
    border-right: solid 1px #dcdfe2;
  }

  ${({ removePadding }) => (removePadding ? 'padding: 0' : '')}
`;

export const GtTableHeadColumn = styled.th<{
  width?: number;
  sortable?: boolean;
  isDragOver?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  width: ${({ width }) => width || 200}px;
  height: 30px;
  padding: 6px;

  text-align: left;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  color: rgba(26, 31, 54, 0.8);

  background-color: rgba(220, 223, 226);
  user-select: none;

  overflow-x: auto;

  &:not(:last-child) {
    border-right: solid 1px #fff;
  }

  ${({ isDragOver }) =>
    isDragOver
      ? `
        border-left: solid 2px #5850ec !important;
    `
      : `

    `}

  ${({ sortable }) =>
    sortable
      ? `
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `
      : ''}
`;
