import { ReactElement, useReducer } from 'react';
import { Route, Switch } from 'react-router-dom';
import Calendar from './calendar/calendar.page';
import Project from './project/project.page';
import People from './people/people.page';
import ProjectList from './project-list/project-list.page';
import Toast from 'app/components/toast/toast';
import { reducer } from './projects.reducer';
import { ProjectsContext, ProjectsDispatchType } from './projects.context.d';
import ProjectsStore from './projects.context';
import { initialProjectsState } from './project.state';

interface Props {
  value?: unknown;
  testing?: boolean;
  children: ReactElement;
}

/**
 * 
 * @returns 
 */
const ProjectsModule = ({ children, value }: Props) => {
  const reducerData = useReducer(reducer, { ...initialProjectsState, ...value as ProjectsContext });
  const state: ProjectsContext = reducerData[0];
  const dispatch: (data: ProjectsDispatchType) => void = reducerData[1];

  return (
    <ProjectsStore.Provider value={{ state, dispatch }}>
      <Switch>
        <Route exact path="/calendar" component={Calendar} />
        <Route exact path="/people" component={People} />
        <Route exact path="/projects" component={ProjectList} />
        <Route path="/projects/:id" component={Project} />
        {children}

        <Toast />
      </Switch>
    </ProjectsStore.Provider>

  );
}

export default ProjectsModule;
