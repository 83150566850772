import { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import VMasker from 'vanilla-masker';
import { extractNumbers, formatTimezoneDate } from '@/lib/formatter';
import Button from '../button/button';
import InputGroup from '../input-group/inputGroup';
import Input from '../input/input';
import LinkButton from '../link-button/link-button';
import {
  ModalWrapper,
  Modal,
  ButtonWrapper,
  ConfirmationTitle,
  ConfirmationFooter,
  buttonCustomStyle,
  deleteButtonCustomStyle,
  CloseButtonContainer,
  Header,
  EventExplanation,
  Main,
  ErrorLabel,
  LocationWrapper,
  CopyWrapper,
} from './update-event-modal.style';
import { onDeleteEvent, saveEvent } from './update-event-modal.controller';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import AccountStore from 'app/modules/account/account.context';
import DateInput from 'stories/base/forms/date-input/date-input';

const UpdateEventOverlay = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const accountContext = useContext(AccountStore);
  const [name, setName] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [error, setError] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timePosition, setTimeposition] = useState<any>(null);
  const [copied, setCopied] = useState<boolean>(false);

  const clear = () => {
    setName('');
    setTime('');
    setDate('');
    setError({});
  };
  useEffect(() => {
    return () => {
      clear();
    }
  }, []);

  useEffect(() => {
    if (inspectionContext.state.selected_timepin) {
      setName(inspectionContext.state.selected_timepin.title);
      setTime(
        formatTimezoneDate({
          date: inspectionContext.state.selected_timepin.tstamp,
          timezone: settingsContext.state.timezone?.id || '',
          format: 'HH:mm:ss.SSS',
        })
      );
      setDate(
        formatTimezoneDate({
          date: inspectionContext.state.selected_timepin.tstamp,
          timezone: settingsContext.state.timezone?.id || '',
          format: 'YYYY-MM-DD',
        })
      );
    }
  }, [
    inspectionContext.state.selected_timepin?.title,
    inspectionContext.state.selected_timepin?.tstamp,
    settingsContext.state.timezone?.id
  ]);
  
  useEffect(() => {
    if (inspectionContext.state.selected_point) {
      inspectionContext.dispatch({
        type: 'SET_SELECTED_TIMEPIN',
        data: undefined
      })
    }
  }, [inspectionContext.state.selected_point]);

  useEffect(() => {
    if (timePosition?.element && timePosition?.position) {
      timePosition.element.selectionStart = timePosition.position;
      timePosition.element.selectionEnd = timePosition.position;
    }
  }, [time]);

  if (!inspectionContext.state.selected_timepin) return null;

  return (
    <ModalWrapper hide={false}>
      <Modal>
        <Header>
          <ConfirmationTitle>Events</ConfirmationTitle>
          <EventExplanation>
            {`
              Add a date and time between the launch and finish time (or ETA at
              receiver if it's an ongoing run). A pin will drop and show where the
              pig was/will be at that time.
            `}
          </EventExplanation>
        </Header>
        <Main>
          <CloseButtonContainer
            onClick={() => {
              inspectionContext.dispatch({
                type: 'SET_SELECTED_TIMEPIN',
                data: undefined,
              });
              inspectionContext.dispatch({
                type: 'TOGGLE_MODAL',
                data: 'events',
              });
            }}
          >
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.94977"
                width="2"
                height="7"
                rx="1"
                transform="rotate(45 4.94977 0)"
                fill="#494C5D"
              />
              <rect
                x="6.36395"
                y="8.48438"
                width="2"
                height="7"
                rx="1"
                transform="rotate(135 6.36395 8.48438)"
                fill="#494C5D"
              />
              <rect
                x="0.449768"
                y="3.94922"
                width="14"
                height="2"
                rx="1"
                fill="#494C5D"
              />
            </svg>
            Back
          </CloseButtonContainer>
          <InputGroup
            label="Name"
            customStyle={`
              padding: 0;
              label {
                margin: 8px 0;
                padding-right: 8px;
                padding-bottom: 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }
            `}
          >
            <Input
              customStyle={`
                ${error?.field === 'name' ? 'border: 1px solid red;' : ''}
              `}
              value={name}
              placeholder={'Event Name'}
              handleChange={(event) => setName(event.target.value)}
            />
          </InputGroup>
          <InputGroup
            label="Time"
            customStyle={`
              padding: 0;
              label {
                margin: 8px 0;
                padding-right: 8px;
                padding-bottom: 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }
              > input {
                padding: 5px 10px;
                border: 1px solid #e5e5e5;
                border-radius: 3px;
                width: 100%;
              }
            `}
          >
            <InputMask
              maskChar=""
              mask="99:99:99.9999"
              placeholder="HH:mm:ss"
              value={time}
              onChange={(event) => {
                 const start = event.target.selectionStart;
                const formatedValue = VMasker.toPattern(
                  extractNumbers(event.target.value),
                  '99:99:99.9999'
                );

                if (
                  formatedValue.length < time.length &&
                  formatedValue.length !== 1
                ) {
                  setTimeposition({ element: event.target, position: start });
                }

                setTime(formatedValue);
              }}
            />
          </InputGroup>
          <InputGroup
            error=""
            label="Date"
            customStyle={`
              ${error?.field === 'time' ? 'border: 1px solid red;' : ''}
            `}
          >
            <DateInput
              icon="calendar_today"
              id="date"
              tz={settingsContext.state.timezone}
              value={date}
              onChange={({ value }) => {
                if (!value) return;
                setDate(`${value.getFullYear()}-${(value.getMonth()+1) < 10 ? `0${value.getMonth()+1}` : value.getMonth()+1}-${value.getDate()}`);
              }}
            />
          </InputGroup>
          <LocationWrapper>
            <InputGroup
              error=""
              label="Longitude"
              customStyle={`
                padding: 0;
                label {
                  margin: 8px 0;
                  padding-right: 8px;
                  padding-bottom: 0px;
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 16px;
                }
              `}
            >
              <Input
                disabled
                value={inspectionContext.state.selected_timepin?.location?.coordinates[0].toFixed(9)}
                handleChange={() => ''}
              />
            </InputGroup>
            <InputGroup
              error=""
              label="Latitude"
              customStyle={`
                padding: 0;
                label {
                  margin: 8px 0;
                  padding-right: 8px;
                  padding-bottom: 0px;
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 16px;
                }
              `}
            >
              <Input
                disabled
                value={inspectionContext.state.selected_timepin?.location?.coordinates[1].toFixed(9)}
                handleChange={() => ''}
              />
            </InputGroup>
            <CopyWrapper title={`Copy: "lat, lng"`} onClick={() => {
              const lng = inspectionContext.state.selected_timepin?.location?.coordinates[0].toFixed(9);
              const lat = inspectionContext.state.selected_timepin?.location?.coordinates[1].toFixed(9);
              navigator.clipboard.writeText(`${lng}, ${lat}`);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 4000)
            }}>
              { !copied ?
                <svg
                  
                  className="copy-icon"
                  enableBackground="new 0 0 64 64"
                  version="1.1"
                  viewBox="0 0 64 64"
                >
                  <g id="Layer_1"/>
                  <g id="icon">
                    <path d="M51.588,8.008H21.569c-2.414,0-4.379,1.964-4.379,4.379v4.815h-4.818c-2.413,0-4.376,1.963-4.376,4.376v30.024   c0,2.413,1.963,4.376,4.376,4.376h30.024c2.413,0,4.376-1.963,4.376-4.376v-4.818h4.815c2.414,0,4.379-1.965,4.379-4.379V12.387   C55.967,9.972,54.002,8.008,51.588,8.008z M44.772,51.602c0,1.311-1.065,2.376-2.376,2.376H12.372   c-1.311,0-2.376-1.065-2.376-2.376V21.578c0-1.311,1.065-2.376,2.376-2.376h30.024c1.311,0,2.376,1.065,2.376,2.376V51.602z    M53.967,42.405c0,1.312-1.067,2.379-2.379,2.379h-4.815V21.578c0-2.413-1.963-4.376-4.376-4.376H19.19v-4.815   c0-1.312,1.067-2.379,2.379-2.379h30.019c1.312,0,2.379,1.067,2.379,2.379V42.405z"/>
                  </g>
                </svg>
                :
                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7L5.5 11.5L16 1" stroke="#1B9E5F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                
              }
            </CopyWrapper>
          </LocationWrapper>
          <ErrorLabel>{error.error}</ErrorLabel>
          <ConfirmationFooter>
            <ButtonWrapper>
              <Button
                customStyle={buttonCustomStyle}
                disabled={isLoading}
                handleClick={() =>
                  saveEvent(
                    {
                      name,
                      time,
                      date,
                      id: inspectionContext.state.selected_timepin?.id,
                    },
                    inspectionContext,
                    settingsContext.state,
                    accountContext.state,
                    setIsLoading,
                    setError
                  )
                }
                value="Update"
              />
              <LinkButton
                customStyle={`
                  border: 1px solid #DCDFE2;
                  box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
                `}
                handleClick={() => inspectionContext.dispatch({
                  type: 'SET_SELECTED_TIMEPIN',
                  data: undefined,
                })}
              >
                Cancel
              </LinkButton>
            </ButtonWrapper>
            <Button
              customStyle={deleteButtonCustomStyle}
              disabled={isLoading}
              handleClick={() =>
                onDeleteEvent(
                  inspectionContext.state.selected_timepin?.id,
                  accountContext.state,
                  inspectionContext.dispatch,
                  setIsLoading,
                  clear,
                )
              }
              value="Delete"
            />
          </ConfirmationFooter>
        </Main>
      </Modal>
    </ModalWrapper>
  );
};

export default UpdateEventOverlay;
