import { getSurveys } from 'app/services/survey.service';

export const getAllSurveys = async ({
  setAllSurveys,
  setSurveys,
  auth,
  setLoading,
}: any) => {
  setLoading(true);
  try {
    const surveysResponse = await getSurveys(auth.token);
    setAllSurveys(surveysResponse.data.surveys);
    setSurveys(surveysResponse.data.surveys);
    setLoading(false);
  } catch (err) {
    setLoading(false);
  }
};

export const filterUserBySearch = ({ allSurveys, search, setSurveys }: any) => {
  if (allSurveys.length && search) {
    const filteredUsers = allSurveys.filter((survey: any) => {
      const reg = new RegExp(`${search}`, 'gi');

      if (reg.test(survey.name)) return true;
      if (reg.test(survey.pipeline?.name)) return true;

      return false;
    });

    setSurveys(filteredUsers);
  } else if (!search) {
    setSurveys(allSurveys);
  }
};
