import { useState } from 'react';
import {
  Wrapper,
  Dots,
  ListWrapper,
  Option,
  OptionLabel,
} from './columnSelector.style';

interface HeaderProps {
  columns: any;
  selecteds: any;
  customStyle?: string;
  onSelect(column: string): void;
}

const ColumnSelector = ({
  customStyle,
  onSelect,
  columns,
  selecteds,
}: HeaderProps) => {
  const [show, setShow] = useState(false);
  return (
    <Wrapper customStyle={customStyle}>
      <Dots onClick={() => setShow(!show)} src="/assets/icons/tree-dots.svg" />
      <ListWrapper show={show}>
        {columns.map((c: any) => (
          <Option onClick={() => onSelect(c)} key={c.field}>
            {selecteds.find((col: any) => col.field === c.field) ? (
              <svg
                width="15"
                height="15"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="18" height="18" rx="3" fill="#5850EC" />
                <path
                  d="M4 8.5L7.33333 12L14 5"
                  stroke="#F7F8F9"
                  strokeWidth="2"
                />
              </svg>
            ) : (
              <div />
            )}
            <OptionLabel>{c.headerName}</OptionLabel>
          </Option>
        ))}
      </ListWrapper>
    </Wrapper>
  );
};
export default ColumnSelector;
