import styled from 'styled-components';

export const ToltipMessage = styled.div`
  position: absolute;
  bottom: calc(100% + 2px);
  left: 45%;

  background-color: #161e2e;
  color: #ffffff;
  padding: 8px;

  display: inline-flex;
  max-width: 200px;
  white-space: nowrap;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1em;

  border-radius: 3px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    top: 100%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: black;
    z-index: 3;

    transform: rotate(180deg);
  }
`;

export const ToltipWrapper = styled.div`
  position: relative;
  display: grid;
`;
