import styled from 'styled-components';

export const QubeClusterModalWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 21;

  background-color: #ffffff;

  height: -webkit-fill-available;
  width: 100%;

  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;

  display: grid;
  grid-template-rows: auto auto 1fr;
  align-content: start;
`;

export const QubeClusterModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  border-bottom: solid 1px #ececec;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
  }
`;

export const QubeClusterModalGoBackButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const QubeClusterModalTitle = styled.h2`
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 1em;

  > svg {
    vertical-align: middle;
    margin-right: 4px;
  }
`;

export const QubeClusterModalList = styled.ul``;


