import { captureException } from '@sentry/react';
import { InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { TimePinType } from 'app/modules/inspection/inspection.interfaces.d';

/**
 *
 * @param tp
 * @param state
 * @param setSelectedTimePin
 * @param map
 * @returns
 */
export const createMarkerElement = (
  tp: TimePinType,
  inspectionDispatch: InspectionProviderValue['dispatch'],
) => {
  const markerWrapper = document.createElement('div');
  const markerEl = document.createElement('img');

  markerEl.setAttribute('src', 'https://cdi-gt-medias.s3.us-east-2.amazonaws.com/map-marker.svg');
  markerWrapper.setAttribute('class', 'GT-TIME-PIN-MARKER-ICON');

  markerEl.onclick = () => {
    inspectionDispatch({
      type: 'SET_SELECTED_TIMEPIN',
      data: tp,
    })
  }

  markerWrapper.appendChild(markerEl);
  const label = document.createElement('label');
  label.innerText = tp.title as string;
  label.setAttribute('class', 'display-none');
  markerWrapper.appendChild(label);

  return markerWrapper;
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderMarker =  async (
  inspectionContext: InspectionProviderValue,
  timePin: TimePinType,
  map: google.maps.Map,
  setMarker: (m: google.maps.marker.AdvancedMarkerElement) => void,
  oldMarker?: google.maps.marker.AdvancedMarkerElement,
) => {
  if (oldMarker) {
    oldMarker.map = null;
  }
  let AdvancedMarkerElement
  try {
    const markerLib = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    AdvancedMarkerElement = markerLib.AdvancedMarkerElement as google.maps.MarkerLibrary['AdvancedMarkerElement'];

  } catch (err) {
    captureException(err);
  }
  const marker = new AdvancedMarkerElement({
    map,
    gmpClickable: true,
    content: createMarkerElement(timePin, inspectionContext.dispatch),
    zIndex: 3,
  });

  setMarker(marker);
  return marker;
};