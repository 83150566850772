import { useContext, useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import InspectionStore from "app/modules/inspection/inspection.context";
import AccountStore from "app/modules/account/account.context";
import { AuthType } from "app/modules/account/account.context.d";
import { deleteMedia, updateMediaCaption, updateSiteSketch } from "./tracking-point-gallery.controller";
import { TrackingPointCascadeType } from "app/modules/inspection/inspection.interfaces";
import MediaCarrouselItem from "app/components/media-carrousel-item/media-carrousel-item";
import ConfirmationModal from "app/components/confirmation-modal/confirmation-modal";
import {
  TrackingPointGalleryArrowButton,
  TrackingPointGalleryArrowNext,
  TrackingPointGalleryArrowPrev,
  TrackingPointGalleryCloseButton,
  TrackingPointGalleryContainer,
  TrackingPointGalleryContainerHeader,
  TrackingPointGalleryContainerHeaderTitle,
  TrackingPointGalleryEmptyContainer,
  TrackingPointGalleryEmptyText,
  TrackingPointGalleryFixedContainer,
  TrackingPointGalleryMedias,
} from './tracking-point-gallery.style';

/**
 * 
 */
const TrackingPointGallery = () => {
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [confirmationModal, setConfirmationModal] = useState<any>();
  const tpoint = inspectionContext.state.selected_point as TrackingPointCascadeType;
  const selected = inspectionContext.state.selected_media as number;

  /**
   * 
   */
  const handleCloseGallery = () => {
    inspectionContext.dispatch({
      type: 'SET_SELECTED_MEDIA',
      data: undefined,
    });
  };

  /**
   * 
   * @param id 
   */
  const handleSelectedMediaChange = (selected: number) => {
    inspectionContext.dispatch({
      type: 'SET_SELECTED_MEDIA',
      data: selected,
    });
    
    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_POINT_TO_GALLERY',
      data: undefined,
    });
  };

  useEffect(() => {
    if (tpoint?.index && inspectionContext.run?.trackingpoint_set?.[tpoint.index]) {
      inspectionContext.dispatch({
        type: 'SET_SELECTED_POINT',
        data: inspectionContext.run.trackingpoint_set[tpoint.index],
      });
    }
  }, [inspectionContext.run?.trackingpoint_set?.[tpoint?.index]?.media_set]);
  
  if (!tpoint) return null;
  return (
    <TrackingPointGalleryFixedContainer data-testid={`GT-MEDIA-CARROUSEL-MEDIA-${selected}`} onClick={handleCloseGallery}>
      <TrackingPointGalleryContainer onClick={(e) => e.stopPropagation()}>
        <TrackingPointGalleryContainerHeader>
          <TrackingPointGalleryContainerHeaderTitle>Media Gallery - {tpoint.name}</TrackingPointGalleryContainerHeaderTitle>
          <TrackingPointGalleryCloseButton
            onClick={handleCloseGallery}
            data-testid="GT-TP-MEDIA-GALLERY-CLOSE-BUTTON"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#1a1f36"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#1a1f36"
              />
            </svg>
          </TrackingPointGalleryCloseButton>
        </TrackingPointGalleryContainerHeader>

        <TrackingPointGalleryMedias>
            {tpoint.media_set?.length ? (
              <Carousel
                showIndicators={false}
                selectedItem={selected}
                showThumbs={false}
                showStatus={false}
                renderArrowPrev={(clickHandler, hasPrev) => (
                  <TrackingPointGalleryArrowPrev hide={!hasPrev}>
                    <TrackingPointGalleryArrowButton
                      onClick={() => {
                        if (selected > 0) {
                          handleSelectedMediaChange(selected - 1);
                        }

                        clickHandler();
                      }}
                    >
                      <svg
                        width="17"
                        height="27"
                        viewBox="0 0 17 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.65"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                          fill="white"
                        />
                      </svg>
                    </TrackingPointGalleryArrowButton>
                  </TrackingPointGalleryArrowPrev>
                )}
                renderArrowNext={(clickHandler, hasNext) => (
                  <TrackingPointGalleryArrowNext $hide={!hasNext}>
                    <TrackingPointGalleryArrowButton
                      onClick={() => {
                        if (tpoint.media_set && selected < tpoint.media_set.length - 1) {
                          handleSelectedMediaChange(selected + 1);
                        }

                        clickHandler();
                      }}
                    >
                      <svg
                        width="17"
                        height="27"
                        viewBox="0 0 17 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.65"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                          fill="white"
                        />
                      </svg>
                    </TrackingPointGalleryArrowButton>
                  </TrackingPointGalleryArrowNext>
                )}
              >
                {[...tpoint.media_set]
                  .sort((mediaA: any, mediaB: any) =>
                    mediaA.is_sketch > mediaB.is_sketch ? -1 : 1
                  )
                  .map((media: any) => (
                    <MediaCarrouselItem
                      onDelete={() => {
                      setConfirmationModal({
                          hide: false,
                          title: 'Are you sure?',
                          text: 'This media will be deleted permanently.',
                          onConfirm: () => {
                            deleteMedia(media.id, auth.token, setConfirmationModal);
                          },
                          onCancel: () => setConfirmationModal({}),
                        });
                      }}
                      onToggleSiteSketch={() => {
                        updateSiteSketch(!media.is_sketch, media.id, auth.token);
                      }}
                      onEditCaption={(caption: string) => {
                        updateMediaCaption(caption, media.id, auth.token);
                      }}
                      setAllowArrowNavigation={() => ''}
                      caption={media.caption}
                      media={media.media}
                      mediaId={media.id}
                      isSiteSketch={media.is_sketch}
                      permissionType={inspectionContext.state.permission_type as 'observer' | 'editor'}
                      key={media.id}
                    />
                  ))}
              </Carousel>
            ) : (
              <TrackingPointGalleryEmptyContainer>
                <TrackingPointGalleryEmptyText>
                  This tracking point doesn’t have any media yet...
                </TrackingPointGalleryEmptyText>
              </TrackingPointGalleryEmptyContainer>
            )}
          </TrackingPointGalleryMedias>

          {confirmationModal ? <ConfirmationModal {...confirmationModal} /> : null}
      </TrackingPointGalleryContainer>
    </TrackingPointGalleryFixedContainer>
  );
};

export default TrackingPointGallery;