import { ReactElement, useReducer } from 'react';
import { Route, Switch } from 'react-router-dom';
import Toast from 'app/components/toast/toast';
import { initialCustomerState } from './customer.state';
import { CustomerContext, CustomerDispatchType } from './customer.context.d';
import CustomerStore from './customer.context';
import { reducer } from './customer.reducer';
import Devices from './devices/devices.page';

interface Props {
  value?: unknown;
  testing?: boolean;
  children: ReactElement;
}

/**
 * 
 * @returns 
 */
const CustomerModule = ({ children, value }: Props) => {
  const reducerData = useReducer(reducer, { ...initialCustomerState, ...value as CustomerContext });
  const state: CustomerContext = reducerData[0];
  const dispatch: (data: CustomerDispatchType) => void = reducerData[1];

  return (
    <CustomerStore.Provider value={{ state, dispatch }}>
      <Switch>
        <Route path="/customer/:id/devices" component={Devices} />
        {children}
        <Toast />
      </Switch>
    </CustomerStore.Provider>
  );
}

export default CustomerModule;
