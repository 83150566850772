import { ChangeEvent, FocusEvent } from 'react';
import { MaterialIcon } from 'material-icons';
import {
  Group,
  GroupLabel,
  GroupInput,
  GroupError,
  GroupInputWrapper,
} from './input.style';

interface Props {
  id: string;
  name?: string;
  value: string;
  icon?: MaterialIcon;
  iconAlignment?: 'left' | 'right';
  testid?: string;
  label?: string;
  error?: string;
  errorTestId?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
};


/**
 * 
 * @param props 
 * @returns 
 */
const Input = ({
  id,
  label,
  name,
  value,
  error,
  icon,
  iconAlignment = 'left',
  placeholder,
  testid,
  onChange,
  onBlur,
  onFocus,
  disabled = false,
  type = 'text',
  errorTestId,
}: Props) => {
  return (
    <Group>
      {label ? <GroupLabel disabled={disabled}>{label}</GroupLabel> : null}
      <GroupInputWrapper $iconAlignment={iconAlignment} $error={!!error} $icon={icon}>
        {icon && iconAlignment === 'left' ? <span className="material-icons-outlined">{icon}</span> : null}
        <GroupInput
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur ? onBlur : () => {}}
          onFocus={onFocus ? onFocus : () => {}}
          disabled={disabled}
          data-testid={testid}
        />
        {icon && iconAlignment === 'right' ? <span className="material-icons-outlined">{icon}</span> : null}
      </GroupInputWrapper>
      {error ? <GroupError data-testid={errorTestId}>{error}</GroupError> : null}
    </Group>
  );
};

export default Input;
