import { useContext } from "react";
import InspectionStore from "app/modules/inspection/inspection.context";

/**
 * 
 * @param props 
 * @returns 
 */
const ScheduledState = () => {
  const inspectionContext = useContext(InspectionStore);
  
  /**
   * 
   */
  const handleClick = () => {
    inspectionContext.dispatch({
      type: 'TOGGLE_MODAL',
      data: 'launching',
    });
  };
  
  return (
    <button
      data-testid="scheduled-action-button"
      disabled={inspectionContext.state.permission_type !== 'editor'}
      onClick={handleClick}
    >
      Launch Now
    </button>
  );
};

export default ScheduledState;
