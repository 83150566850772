import { InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { EstimationType } from "app/modules/inspection/inspection.interfaces";
import { captureException } from "@sentry/react";


export const createMarkerElement = (): HTMLSpanElement => {
  const markerWrapper = document.createElement('div');
  const markerEl = document.createElement('img');

  markerEl.setAttribute('src', '/assets/icons/tracking-points/pig.svg');
  markerWrapper.setAttribute('class', 'GT-PIG-MARKER-ICON');
  markerWrapper.appendChild(markerEl)

  return markerWrapper;
}



/**
 *  renderTrackingpoints
 * @param param0
 */
export const removeMarkerFromMap = (
  marker: google.maps.marker.AdvancedMarkerElement,
  setMarker: (data?: google.maps.marker.AdvancedMarkerElement) => void
) => {
  marker.map = null;
  setMarker();
}
/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderMarker = async (
  estimation: EstimationType,
  map: google.maps.Map,
  oldmarker: google.maps.marker.AdvancedMarkerElement | undefined,
  setMarker: (mk: google.maps.marker.AdvancedMarkerElement) => void,
  inspectionContext: InspectionProviderValue,
) => {
  if (oldmarker) {
    oldmarker.map = null;
  }
  let AdvancedMarkerElement
  try {
    const markerLib = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    AdvancedMarkerElement = markerLib.AdvancedMarkerElement as google.maps.MarkerLibrary['AdvancedMarkerElement'];

  } catch (err) {
    captureException(err);
  }

  const marker = new AdvancedMarkerElement({
    map,
    gmpClickable: true,
    content: createMarkerElement(),
    zIndex: 2,
  });

  google.maps.event.addListener(map, 'drag', () => {
    inspectionContext.dispatch({
      type: 'SET_IS_FOLLOWING_PIG',
      data: false,
    });
    inspectionContext.dispatch({
      type: 'SET_IS_FOLLOWING_LOGGED_USER',
      data: false,
    });
  });

  setMarker(marker);
  return marker;
};