import styled from 'styled-components';

export const Bar = styled.div<{ onlyDesk?: boolean }>`

  width: 100%;
  height: 48px;
  padding: 8px; 
  box-shadow: 0px -8px 20px 0px rgba(38, 59, 82, 0.10);
  background-color: #ffffff;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;

  display: ${({ onlyDesk }) => (onlyDesk ? 'none' : 'grid')};
  grid-template-columns: auto 1fr;
  gap: 8px;
  justify-items: end;
  align-items: center;

  color: #17181B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 1024px) {
    display: grid;
  }
`;

export const BarSelecteds = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const BarSelectedsAmount = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #5850ec;
  
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const BarActions = styled.div`
`;


