import styled from 'styled-components';
import 'react-big-calendar/lib/css/react-big-calendar.css';

export const ProjectContent = styled.main<{ month: boolean }>`
  padding: 10px 16px 16px 16px;

  background-color: rgba(240, 242, 243, 0.5);
  background-color: #f0f2f380;
  height: calc(100vh - 42px);
  overflow: auto;

  display: grid;
  grid-template-columns: 1fr;

  ${({ month }) =>
    month
      ? `
    > .rbc-calendar {
      min-height: 700px;
    }
  `
      : ''}

  > .rbc-calendar .rbc-month-view,
  > .rbc-calendar .rbc-time-view,
  > .rbc-calendar .rbc-time-content,
  > .rbc-calendar .rbc-time-header {
    border: none;
    gap: 4px;
  }

  > .rbc-calendar .rbc-time-header-content {
    border: none;
  }

  > .rbc-calendar .rbc-time-column {
    border: none;
    gap: 4px;

    &.rbc-today {
      background-color: transparent;
    }

    .rbc-events-container {
      margin-top: 10px;
      margin-left: 10px;
    }

    .rbc-current-time-indicator {
      background-color: transparent;
    }

    .rbc-event {
      background-color: #ffffff;
      box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
      border-radius: 3px;
      border: none;
      padding: 8px;

      > .rbc-event-label,
      > .rbc-event-content {
        font-family: 'Inter';
        font-style: normal;
        line-height: 109.3%;

        color: #161e2e;
      }

      > .rbc-event-content {
        font-weight: 400;
        font-size: 14px;
      }

      > .rbc-event-label {
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 11px;
      }
    }

    .rbc-timeslot-group {
      border: none;
      border-radius: 6px;
      background-color: #f0f2f3;

      padding: 10xp;

      .rbc-label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;

        text-transform: uppercase;
      }

      > .rbc-events-container {
        border: none;
        gap: 4px;
        margin-top: 10px;
      }
    }
  }

  > .rbc-calendar .rbc-header,
  > .rbc-time-view .rbc-header {
    border: none;
    background-color: transparent;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 130.11%;

    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: rgba(105, 115, 134, 0.75);

    &.rbc-overflowing {
      border: none;
    }
  }

  > .rbc-calendar .rbc-row-bg {
    gap: 4px;
  }

  > .rbc-calendar .rbc-day-slot .rbc-time-slot {
    border: none;
  }

  > .rbc-calendar .rbc-time-content > * + * > * {
    border: none;
  }

  > .rbc-calendar .rbc-month-row {
    border: none;
  }

  > .rbc-calendar .rbc-day-bg {
    background-color: #f0f2f3;
    border-radius: 6px;
    border: none;
  }

  > .rbc-calendar .rbc-date-cell {
    padding: 10px;

    &.rbc-now > .rbc-button-link {
      color: #ffffff;
      background-color: #5850ec;
      padding: 4px;
      border-radius: 50%;
    }
  }

  > .rbc-calendar .rbc-row-segment {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 4px;

    > .rbc-event {
      background-color: #6b7280;
      box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
      border-radius: 3px;

      > .rbc-event-content p strong,
      > .rbc-event-content h3 {
        font-family: 'Inter';
        font-style: normal;
        font-size: 11px;
        line-height: 109.3%;

        color: #ffffff;
      }
    }
  }
`;

export const CalendarControl = styled.button<{ selected: boolean }>`
  height: 40px;

  background-color: ${({ selected }) => (selected ? '#5850ec' : 'transparent')};
  border: solid 1px ${({ selected }) => (selected ? '#5850ec' : '#dfe0e4')};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  transition-duration: 200ms;

  color: ${({ selected }) => (selected ? '#ffffff' : '#161e2e')};
  cursor: pointer;

  &:nth-child(1) {
    border-radius: 6px 6px 6px 6px;
  }

  &:nth-child(2) {
    border-radius: 0 6px 6px 0;
  }
`;

export const CalendarTypeControl = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-radius: 6px;
  overflow: hidden;
`;

export const CalendarHeader = styled.header`
  display: grid;
  grid-template-columns: 140px 1fr 140px;
  align-items: center;

  margin-top: 16px;
  margin-bottom: 24px;
`;

export const CalendarActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const CalendarTodayButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  height: 40px;
  min-width: 70px;

  color: #161e2e;
  user-select: none;

  background-color: transparent;
  border: solid 1px #dfe0e4;
  border-radius: 5px;

  cursor: pointer;

  transition-duration: 200ms;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const CalendarNavigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #161e2e;

  span,
  svg {
    cursor: pointer;
    user-select: none;
  }
`;

export const CalendarTitle = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #161e2e;

  text-align: center;

  user-select: none;
`;

export const WeatherInformation = styled.p`
  display: flex;
  align-items: center;
  gap: 3px;

  > img {
    width: 18px;
    vertical-align: middle;
  }
`;

export const EventCard = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 1px;
`;

export const EventRunName = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;

  color: #1a1f36;
`;

export const Temperature = styled.strong`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;

  color: #1a1f36;
`;
