import styled from 'styled-components';

export const Container = styled.main`
  display: grid;
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Form = styled.div`
  width: 100%;
  padding: 14px 16px;

  overflow: auto;
  height: calc(100vh - 47px);
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.label`
  display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18;
  line-height: 22px;
  color: #161e2e;
`;

export const Button = styled.button`
  background-color: #fff;
  border: 1px solid #dfe0e4;
  border-radius: 6px;
  padding: 20px;
  width: 200px;
  margin: 16px 10px 0 0;
  cursor: pointer;
`;

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const IlustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #eff0f3;
  @media (max-width: 1024px) {
    svg {
      max-width: 400px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LinkButton = styled.button`
  background-color: transparent;
  text-decoration: underline;
  ${({ customStyle }: any) => customStyle}
`;

export const FilesWrapper = styled.div``;

export const FileItem = styled.div`
  padding: 8px 0;
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

export const FileLabel = styled.label`
  font-size: 14px;
`;

export const DownloadText = styled.a`
  font-weight: 500;
  font-size: 0.75em;
  line-height: 0.75em;

  color: blue;
  text-decoration: underline;
  margin-top: 8px;
  opacity: 0.7;

  display: block;
`;

export const dropDownCustomStyle = `
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 180px;

  button {
    background-color: #fff;
    border: 1px solid #dfe0e4;
    border-radius: 3px;
    width: 100%;
    max-width: 100%;
    color: #000;
    height: 27px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      right: 10px;
    }
  }

  button:hover {
    background-color: #fff;
    border: 1px solid #dfe0e4;
    border-radius: 3px;
    width: 100%;
    max-width: 100%;
    color: #000;
    height: 27px;
  }

  ul {
    width: 180px;
  }
`;
