import { useEffect, useState } from "react";
import { renderDriveline } from "./driveline.controller";
import { PipelineType } from "app/modules/survey/survey.interfaces";

interface Props {
  driveline: PipelineType['driveline'];
  map: google.maps.Map;
}

const Driveline = ({ driveline, map }: Props) => {
  const [drivelinePoly, setDrivelinePoly] = useState<google.maps.Polyline[  ]>();

  useEffect(() => {
    renderDriveline({ driveline, map, setDrivelinePoly, drivelinePoly });

    return () => {
      drivelinePoly?.forEach((polyline) => {
        polyline.setMap(null);
      });
    };
  }, [driveline?.coordinates]);

  return null;
};

export default Driveline;