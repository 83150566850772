import { useTheme } from 'styled-components'; 
import { SkeletonStyle } from './skeleton.style';

interface Props {
  width: string;
  height: string;
  inline: boolean;
  loading: boolean;
  color?: string;
  children?: any;
  hidden?: boolean;
}

/**
 * 
 * @param param0 
 * @returns 
 */
const Skeleton = ({
  loading,
  width,
  height,
  children,
  inline,
  color,
  hidden,
}: Props) => {
  const theme = useTheme();
  const defaultColor = theme.colors.neutra_100;
  
  return loading ? (
    <SkeletonStyle
      $hidden={hidden}
      $inline={inline}
      $height={height}
      $width={width}
      $color={color || defaultColor}
      className={`rect pulse`}
    />
  ) : (
    children || null
  );
};

Skeleton.defaultProps = {
  width: '100%',
  height: '24px',
  inline: false,
};

export default Skeleton;
