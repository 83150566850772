import { Column } from 'app/components/table/table.interfaces';

export const columnsModel = (): Column[] => [
    { field: 'index', headerName: '#', width: 25 },
    { field: 'name', headerName: 'Point Name' },
    {
      field: 'speed',
      headerName: 'Speed',
      valueGetter: (row?: any) => row?.formatedSpeed,
      valueFormatter: (value: any) => value,
      width: 100,
    },
    {
      field: 'passage',
      headerName: 'Passage',
      width: 150,
      valueGetter: (row?: any) => row?.passage,
      valueFormatter: (value: any): string => value
    },
    {
      field: 'distance',
      headerName: 'Distance',
      width: 120,
      valueGetter: (row?: any) => row?.distance,
      valueFormatter: (value: any): string => value
    },
    {
      field: 'inclination',
      headerName: 'Inclination',
      width: 120,
      valueGetter: (row?: any) => {
        return row.formatedInclination;
      },
    },
    {
      field: 'elevation',
      headerName: 'Elevation',
      width: 120,
      valueGetter: (row?: any) => row.formatedElevation,
      valueFormatter: (value: any): string => value,
    },
    {
      field: 'location',
      headerName: 'Location',
      valueGetter: (row?: any) => row.location,
    },
  ];
