import { useMemo, useState } from 'react';
import * as unorm from 'unorm';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import SearchInput from "app/components/search-input/search-input";
import CloseIcon from '../../icons/close.icon';
import {
  DeviceModal,
  DeviceCloseButton,
  DeviceCustomerDeviceTablewrapper,
  DeviceHeader,
  DeviceModalText,
  DeviceHeaderSearch,
} from './device-customer-devices-modal.style';
import CustomerDevicesFloatBar from '../customer-devices-float-bar/customer-devices-float-bar';
import CustomerDevicesTable from '../customer-devices-table/customer-devices-table';

interface Props {
  devices: QubeDeviceType[];
  onClose: () => void;
  onAddDevices: (deviceIds: string[]) => void;
}

/**
 * 
 */
const DeviceCustomerDevicesModal = (props: Props) => {
  const [search, setSearch] = useState<string>('');
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const searchedDevices = useMemo(() => {
    const normalizedSearchText = unorm.nfkd(search).toLocaleLowerCase();
    const results = props.devices.filter((device) => {
      const normalizedObjectSerial = unorm.nfkd(device?.serial || '').toLocaleLowerCase();
      return normalizedObjectSerial.includes(normalizedSearchText);
    });
  
    return results;
  }, [search, props.devices]);

  return (
    <DeviceModal>
      <DeviceHeader>
        <DeviceCloseButton onClick={props.onClose}>
          <CloseIcon />
        </DeviceCloseButton>
      </DeviceHeader>

      <DeviceModalText>Select a device to add  to this project</DeviceModalText>
      
      <DeviceHeaderSearch>
        <SearchInput
          value={search}
          placeholder="Search for devices"
          onChange={(e) => setSearch(e.target.value)}
        />
      </DeviceHeaderSearch>

      <DeviceCustomerDeviceTablewrapper>
        <CustomerDevicesTable
          dragDevices={[]}
          devices={searchedDevices}
          setSelectedDevices={setSelectedDevices}
          setSelectedDevicesToRemoveDevices={() => {}}
          selectedDevices={selectedDevices}
        />
      </DeviceCustomerDeviceTablewrapper>
      

      {
        selectedDevices.length ? (
          <CustomerDevicesFloatBar
            amount={selectedDevices.length}
            onAddDevice={() => {
              props.onAddDevices(selectedDevices);
              props.onClose();
            }}
          />
        ) : null
      }
    </DeviceModal>
  );
};

export default DeviceCustomerDevicesModal;
