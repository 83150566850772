import { formatPipePointsToPath } from "@/lib/formatter";
import { RunCascadeType } from "app/modules/inspection/inspection.interfaces";

/**
 * renderPipeline
 * @returns
 */
export const renderPipeline = ({
  pipeline,
  map,
  setInnerPipe,
  setOuterPipe
}: {
  map?: google.maps.Map;
  pipeline?: RunCascadeType['pipeline'];
  setInnerPipe: (data: google.maps.Polyline) => void;
  setOuterPipe: (data: google.maps.Polyline) => void;
}) => {
  // TODO: understand what is pipelinePoly
  if (!pipeline?.geometry?.coordinates) return;

  const points = formatPipePointsToPath({
    project: null,
    name: null,
    length: null,
    id: null,
    start: { coordinates: [], type: '' },
    geometry: { coordinates: pipeline.geometry.coordinates, type: '' },
  });

  const innerPipe = new google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#f1f1f1',
    strokeOpacity: 0.45,
    strokeWeight: 4,
    zIndex: 1,
    map,
  });

  const outerPipe = new google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#6e7881',
    strokeOpacity: 1,
    strokeWeight: 8,
    zIndex: 0,
    map,
  });

  setInnerPipe(innerPipe);
  setOuterPipe(outerPipe);
};

/**
 * changePipeline
 * @returns
 */
export const changePipeline = ({
  pipeline,
  innerPipe,
  outerPipe,
}: {
  pipeline?: RunCascadeType['pipeline'];
  innerPipe?: google.maps.Polyline;
  outerPipe?: google.maps.Polyline;
}) => {
  // TODO: understand what is pipelinePoly
    if (!pipeline?.geometry?.coordinates || !innerPipe || !outerPipe) return;

  const points = formatPipePointsToPath({
    project: null,
    name: null,
    length: null,
    id: null,
    start: { coordinates: [], type: '' },
    geometry: { coordinates: pipeline.geometry.coordinates, type: '' },
  });
  
  if (points) {
    innerPipe.setPath(points);
    outerPipe.setPath(points);
  }
};