import { formatTimezoneDate } from "@/lib/formatter";
import { TriggerType } from "app/modules/detection/detection.interfaces";
import { MagneticData } from "app/modules/inspection/run/components/charts/components/magnetic-chart/magnetic-chart";
import { SettingsContext } from "app/modules/settings/settings.context.d";
import { Timezone } from "app/modules/settings/settings.context.d";
import axios from "axios";
import moment from "moment";

const LOCAL_URL = import.meta.env.VITE_LOCAL_URL;

const m = 60;
const h = m * 60;

const THRESHOLDS = [
  1,
  5,
  10,
  15,
  30,
  60,
  2 * m,
  5 * m,
  10 * m,
  15 * m,
  20 * m,
  30 * m,
  h,
  2 * h,
  3 * h,
  6 * h,
  12 * h,
];

const TICKS = 4;

export const normalizeTstamp = (tstamp: string | undefined): Date | undefined => {
  if (!tstamp) return new Date('invalid');
  if(/\+/g.test(tstamp)) {
    return new Date(`${tstamp.split('+')[0]}Z`);
  }
  return new Date(tstamp)
}

export const getTriggerTstamp = (trigger: TriggerType | undefined): Date | undefined => {
  return normalizeTstamp(trigger?.tstamp);
}

export const formatTimeTick = (
  tick: Date,
  i: number,
  ticks: Date[],
  timezone: SettingsContext['timezone'],
) => {
  if (!tick) return;
  if (!tick.getTime) tick = new Date(tick);

  let peer = i > 0 ? ticks[i - 1] : ticks[i + 1];
  if (!peer) {
    return formatTimezoneDate({
      date: tick,
      timezone: timezone?.id || '',
      format: 'HH:mm:ss',
    });
  }

  if (!peer.getTime) peer = new Date(peer);

  const delta = Math.abs(peer.getTime() - tick.getTime()) / 1000;

  if (delta < 90) {
    return formatTimezoneDate({
      date: tick,
      timezone: timezone?.id || '',
      format: 'HH:mm:ss',
    });
  }

  return formatTimezoneDate({
    date: tick,
    timezone: timezone?.id || '',
    format: 'HH:mm',
  });
};

export const getZoomTicks = (range: [Date, Date] | null) => {
  if (!range) return [];
  if (!range[0].getTime) return [];

  const start = range[0].getTime();
  const end = range[1].getTime();
  const delta = (end - start) / 1000;
  let step;

  for (let i = 0; step === undefined && i < THRESHOLDS.length; i++) {
    const threshold = THRESHOLDS[i];
    if (delta < threshold * (TICKS + 1)) {
      step = THRESHOLDS[i];
    }
  }
  if (!step) step = THRESHOLDS[THRESHOLDS.length - 1];

  const periods = Math.floor(start / (1000 * step));
  let tstamp = new Date(periods * step * 1000);
  const ticks: any = [];
  while (tstamp.getTime() < end) {
    const tick = new Date(
      tstamp.getFullYear(),
      tstamp.getMonth(),
      tstamp.getDate(),
      tstamp.getHours(),
      tstamp.getMinutes(),
      tstamp.getSeconds()
    );
    ticks.push(tick);
    tstamp = new Date(tick.getTime() + step * 1000);
  }

  return ticks;
};

export const getMediaUrl = (trigger, media) => {
  const tMedia = trigger?.medias ? trigger.medias[media] : '';
  const isNotLocal = /^https?:/.test(tMedia);
  const localUrl = LOCAL_URL || 'http://localhost:8000';
  return `${isNotLocal ? '' : localUrl}${tMedia}`;
}

export const getXYData = async (
  trigger: TriggerType,
  timezone: Timezone['id'],
  setMagneticData: (data: {x: MagneticData[], y: MagneticData[]}) => void,
  setRangeY: (data: [number, number]) => void,
  rangeY: [number, number] | undefined,
  setRangeX: (data: [Date, Date]) => void,
  setLoading: (data: boolean) => void,
  type: 'dc' | 'ac',
) => {
  setLoading(true);
  const xUrl = getMediaUrl(trigger, `${type}x`);
  let xArr;
  try {
    const xResp = await axios.get(xUrl, { responseType: 'arraybuffer' });
    xArr = decodeMagneticData(xResp.data);
  } catch (err) {
    setMagneticData({ x: [], y: [] });
    setLoading(false);
    throw new Error('Error to get ACX')
  }
  const yUrl = getMediaUrl(trigger, `${type}y`);
  let yArr;
  try {
    const yResp = await axios.get(yUrl, { responseType: 'arraybuffer' });
    yArr = decodeMagneticData(yResp.data);
  } catch {
    setLoading(false);
    setMagneticData({ x: [], y: [] });
    throw new Error('Error to get ACY')
  }

  const data = {
    x: xArr ? getTimeOnMagneticData(xArr.floatArray, trigger, timezone) : [],
    y: yArr ? getTimeOnMagneticData(yArr.floatArray, trigger, timezone) : [],
  };

  setMagneticData(data);

  const rangeX = [data.x[0].x, data.x[data.x.length - 1].x] as [Date, Date];
  setRangeX(rangeX);

  const min = Math.min(xArr?.min, yArr?.min)
  const max = Math.max(xArr?.max, yArr?.max);
  if (!rangeY || (rangeY[0] !== min || rangeY[1] !== max)) setRangeY([min, max])
  setTimeout(() => {
    setLoading(false);
  }, 200);
}

export const  decodeGeophoneData = (buffer: ArrayBuffer) => {
  const byteArray: number[] = [];
  const dataView: DataView = new DataView(buffer);

  // Iterate over each byte
  for (let i = 0; i < buffer.byteLength; i += 1) {
      const byte: number = dataView.getInt8(i);
      byteArray.push(byte);
  }

  return byteArray;
}


const getTimeOnMagneticData = (data, trigger, timezone) => {
  const date = moment.tz(trigger.medias._start, timezone || 'UTC');
  const tstamp = date.toDate().getTime();
  return data.map((value: number, i: number) => {
    const currentTZTime = moment.tz(tstamp + i * 100, timezone || 'UTC');
    const currentTime = currentTZTime.toDate();

    return { x: currentTime, y: value };
  });
}

export const  decodeMagneticData = (buffer: ArrayBuffer) => {
    const floatArray: number[] = [];
    const dataView: DataView = new DataView(buffer);
    let min = 0;
    let max = 0;

    // Iterate over each 4-byte block
    for (let i = 0; i < buffer.byteLength; i += 4) {
      // Read a float; second parameter is the byte offset
      // Assuming little endian; set to true if the data is in big endian
      const float: number = dataView.getFloat32(i, true);
      if (float < min) {
        min = float;
      } else if (float > max) {
        max = float;
      }
      floatArray.push(float);
    }

    return {
      floatArray,
      max,
      min,
    };
}
