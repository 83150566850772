import { InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { EstimationType } from "app/modules/inspection/inspection.interfaces";

const convert = (line: { coordinates: number[][]}) =>
    line?.coordinates.map((p) => ({
      lng: p[0],
      lat: p[1],
    }));

/**
 *
 * @param param0
 */
export const changeYellowPolyline = ({
  polyline,
  estimation,
  map,
}: {
  polyline?: google.maps.Polyline;
  estimation: EstimationType,
  map: google.maps.Map,
}) => {
  if (!estimation.yellow_segment && !polyline) return;

  if (!estimation.yellow_segment && polyline) {
    polyline.setMap(null);
  }
  
  if (estimation.yellow_segment && polyline) {
    polyline.setPath(convert(estimation.yellow_segment))
    polyline.setMap(map);
  }
};
/**
 *
 * @param param0
 */
export const changeGreenPolyline = ({
  polyline,
  estimation,
  map,
}: {
  polyline?: google.maps.Polyline;
  estimation: EstimationType,
  map: google.maps.Map,
}) => {
  if (!estimation.green_segment && !polyline) return;

  if (!estimation.green_segment && polyline) {
    polyline.setMap(null);
  }
  
  if (estimation.green_segment && polyline) {
    polyline.setPath(convert(estimation.green_segment))
    polyline.setMap(map);
  }
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderYellowPolyline = (
  inspectionContext: InspectionProviderValue,
  estimation: EstimationType,
  map: google.maps.Map,
  polyline?: google.maps.Polyline,
): google.maps.Polyline => {
  if (polyline) {
    polyline.setMap(null);
  }

  const yellow = new google.maps.Polyline({
    path: convert(estimation.yellow_segment),
    geodesic: true,
    strokeColor: '#fe0000',
    strokeOpacity: 0.5,
    strokeWeight: 22,
    zIndex: 1.5,
  });
  
  yellow.setMap(map);

  // google.maps.event.addListener(yellow, 'mouseover', (e: google.maps.MapMouseEvent) => {
  //   inspectionContext.dispatch({
  //     type: 'SET_EXPLANATION_MAP_TOOLTIP',
  //     data: {
  //       type: 'DEVIATION',
  //       position: e.latLng,
  //     }
  //   })
  // });

  // google.maps.event.addListener(yellow, 'mouseout', () => {
  //   inspectionContext.dispatch({
  //     type: 'SET_EXPLANATION_MAP_TOOLTIP',
  //     data: undefined
  //   })
  // });

  return yellow;
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderGreenPolyline = (
  estimation: EstimationType,
  map: google.maps.Map,
  polyline?: google.maps.Polyline,
) => {
  if (polyline) {
    polyline.setMap(null);
  }

  const green = new google.maps.Polyline({
    path: convert(estimation.green_segment),
    geodesic: true,
    strokeColor: '#149E4C',
    strokeOpacity: 1,
    strokeWeight: 8,
    zIndex: 2,
  });
  
  green.setMap(map);
  return green;
};