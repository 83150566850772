import { getSurvey } from '../../../../services/survey.service';
import { AccountContext } from 'app/modules/account/account.context.d';
import { SurveyType } from 'app/modules/survey/survey.interfaces';

export const loadSurvey = async ({
  surveyId,
  auth,
  setStoredSurveys,
  storedSurveys,
  setSurveyData,
}: {
  surveyId: number;
  setSurveyData: (data: SurveyType) => void;
  auth: AccountContext['auth'];
  storedSurveys: { [key: number]: SurveyType};
  setStoredSurveys: (data: { [key: number]: SurveyType}) => void;
}) => {
  if (!surveyId || !auth || !surveyId) return;

  if (storedSurveys[surveyId]) {
    return setSurveyData(storedSurveys[surveyId]);
  }

  const surveyResp = await getSurvey(surveyId, auth?.token);
  const storedsCopy = JSON.parse(JSON.stringify(storedSurveys));
  storedsCopy[surveyId] = surveyResp.data;
  setStoredSurveys(storedsCopy);
  setSurveyData(surveyResp.data);
};
