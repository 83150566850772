import { formatPipePointsToPath } from '@/lib/formatter';
import { getBoundsBox } from '../../../../../utils/map.utils';
import { serializeSurvey } from 'app/modules/survey/handlers/survey.survey.handlers';
import { SurveyContext } from 'app/modules/survey/survey.context.d';
import { PipelineType } from 'app/modules/survey/survey.interfaces';

/**
 * 
 * @param localSurvey 
 * @param setPipeline 
 * @param setSurvey 
 * @param setPointsDic 
 * @returns 
 */
export const mapSurvey = (localSurvey, setPipeline, setSurvey) => {
  const serialized = serializeSurvey({} as unknown as SurveyContext, localSurvey);

  setPipeline(serialized.pipeline);
  setSurvey(serialized.survey);
  return serialized;
};

/**
 *
 */
export const centerMap = ({ pipeline, map }: {
  pipeline?: PipelineType;
  map?: google.maps.Map;
}) => {
  if (pipeline?.geometry && map) {
    const points = formatPipePointsToPath({
      project: null,
      name: null,
      length: null,
      id: null,
      start: { coordinates: [], type: '' },
      geometry: { coordinates: pipeline.geometry?.coordinates || [], type: '' },
    });

    if (points) {
      const center: any = getBoundsBox(points);
      map.fitBounds(center, 70);
    }
  }
};
