import styled from 'styled-components';

export const Button = styled.button<{ customStyle: string }>`
  padding: 6px 7px;
  background: #ffffff;
  border: 1px solid #dcdfe2;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
  border-radius: 6px;

  cursor: pointer;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;
  transition-duration: 400ms;

  &:hover {
    background-color: #f1f1f1;
  }

  ${({ customStyle }) => customStyle}
`;
