/***************************************************************************
 * Generated by RxDjango on 2024-09-02 17:45 (UTC)                         *
 *                                                                         *
 * Based on all ContextChannel.as_asgi() calls in backend.asgi.application *
 *                                                                         *
 * This is expected to match customer/channels.py                          *
 *                                                                         *
 *                          DO NOT EDIT THIS FILE                          *
 *                                                                         *
 * To rebuild it, run:                                                     *
 *                                                                         *
 *     ./manage.py makefrontend [customer]                                 *
 *                                                                         *
 ***************************************************************************/
import { ContextChannel } from '@rxdjango/react';

const SOCKET_URL = import.meta.env.VITE_SOCKET_URL.replace(/ws$/, '');

import { CustomerType } from './customer.interfaces.d';


export class CustomerDevicesChannel extends ContextChannel<CustomerType> {

  anchor = 'customer.serializers.CustomerSerializer';
  endpoint: string = 'ws/qube-devices/{customer_id}/';

  args: { [key: string]: number | string } = {};

  baseURL: string = SOCKET_URL;

  constructor(customer_id: number, token: string) {
    super(token);
    this.args['customer_id'] = customer_id;
  }

  model = {
    "customer.serializers.CustomerSerializer": {
      "company": "account.serializers.CompanySerializer",
      "qube_set": "qube.serializers.QubeDeviceSerializer",
      "project_set": "customer.serializers.ProjectSimpleSerializer"
    },
    "account.serializers.CompanySerializer": {},
    "qube.serializers.QubeDeviceSerializer": {},
    "customer.serializers.ProjectSimpleSerializer": {
      "device_set": "customer.serializers.DeviceSimpleSerializer"
    },
    "customer.serializers.DeviceSimpleSerializer": {}
  };
}