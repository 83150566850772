import moment from 'moment-timezone';
import { formatMps, formatSpeedToMps, formatTimezoneDate } from '@/lib/formatter';
import { updateRun } from 'app/services/run.service';
import { Timezone, Unit } from 'app/modules/settings/settings.context.d';
import { NotificationProviderValue } from 'app/modules/notification/notification.context.d';

export const initComponent = ({
  setSpeed,
  run,
  settingsContext,
  setInitialTime,
  setInitialDate,
}) => {
  if (run?.scheduled_launch_at) {
    const launch_at = moment.utc(run?.scheduled_launch_at)

    setInitialTime(
      formatTimezoneDate({
        date: launch_at.toDate(),
        timezone: settingsContext.state.timezone?.id,
        format: 'HH:mm:ss.SSS',
      })
    );

    setInitialDate(
      formatTimezoneDate({
        date: launch_at.toDate(),
        timezone: settingsContext.state.timezone?.id,
        format: 'YYYY/MM/DD',
      })
    );
  }

  if (run?.initial_speed) {
    const speed = formatMps({
      distance: run.initial_speed,
      unit: settingsContext.state.speedUnit,
      returnNumber: true,
    });

    setSpeed(speed);
  }
};

export const getStatusLabel = (inspectionState): string => {
  if (inspectionState.run?.status === 'EARLY_FINISHED') return 'FINISHED EARLY';
  else return `${inspectionState.run?.status}`;
};

/**
 *
 * @param data
 * @param dispatch
 * @param timezone
 * @returns
 */
export const updateScheduledRun = async (
  date: string,
  time: string,
  speed: string,
  runId: number | undefined,
  token: string | undefined,
  timezone: Timezone | undefined,
  notificationDispatch: NotificationProviderValue['dispatch'],
  speedUnit?: Unit | undefined,
  hidePicker?: (hide: boolean) => void,
) => {
  if (!timezone?.id) return;
  if (!speedUnit?.id) return;
  if (!token) return;
  if (!runId) return;

  const cleanTime = time.replace(/:/g, '').replace('.', '');
  
  const h = cleanTime.slice(0, 2);
  const m = cleanTime.slice(2, 4);
  const s = cleanTime.slice(4, 6);
  const mm = cleanTime.slice(6, );
  const isValid = !(+h > 23 || +m > 59 || +s > 59 || +mm > 999);

  if (!isValid) {
    return notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Invalid date time',
      },
    });
  }

  const startDate = moment.tz(`${date.replace(/\//g, '-')} ${h}:${m}:${s}.${mm}`, timezone.id);

  const dataToApi = {
    id: runId,
    scheduled_launch_at: startDate.toISOString(),
    scheduled_launch_time: startDate.toISOString(),
    predicted_launch_speed: formatSpeedToMps({
      distance: +speed,
      unit: speedUnit.id,
    }),
  };
  try {
    await updateRun(runId.toString(), dataToApi, token);
    if (hidePicker) hidePicker(false);
  } catch (err: any) {
    if (err.response?.data?.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};