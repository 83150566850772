import { useContext, useEffect, useState } from 'react';
// context
import AccountStore from 'app/modules/account/account.context';
// interfaces
import { AuthType } from 'app/modules/account/account.context.d';
import InputGroup from '../input-group/inputGroup';
import Button from '../button/button';
import {
  getPredictUsers,
  sendInvitations,
} from './invitation-with-project-modal.controller';
import { PredictUserType, RoleType } from './invitation-with-project-modal.d';
import {
  CloseButton,
  Email,
  ModalWrapper,
  Modal,
  ModalHeader,
  ModalContent,
  ModalTitle,
  MultiSelectInputWrapper,
  InviteForm,
  Input,
  PredictUsersWrapper,
  ProjectList,
  ProjectListHeader,
  ProjectListTitle,
  ProjectListItem,
  EmptyProjectsWrapper,
  EmptyText,
  Error,
} from './invitation-with-project-modal.style';
import PredictUserCard from './components/predict-user-card/predict-user-card';
import RoleSelector from './components/role-selector/role-selector';
import Checkbox from '../checkbox/checkbox';
import { ProjectType } from '../../interfaces/inspection.interfaces';

interface Props {
  opened: boolean;
  onClose: () => void;
  managerProjects: ProjectType[];
}

interface ErrorType {
  role?: string;
  email?: string;
  project?: string;
}

const renderPredictedUsers = (
  selecteds: string[],
  users: PredictUserType[],
  onAdd: (email: string) => void,
  auth: AuthType | null
) =>
  users
    .filter((user) => {
      if (user.email === auth?.user.email) {
        return false;
      }

      if (selecteds.indexOf(user.email) !== -1) {
        return false;
      }

      return true;
    })
    .map((user) => (
      <li key={user.email}>
        <PredictUserCard
          avatar={user.avatar}
          email={user.email}
          first_name={user.first_name}
          last_name={user.last_name || ''}
          onClick={() => onAdd(user.email)}
        />
      </li>
    ));

const renderProjectItems = (
  managerProjects: ProjectType[],
  selectedProjects: number[],
  setSelectedProjects: (projects: number[]) => void
) =>
  managerProjects.map((project) => (
    <ProjectListItem key={project.id}>
      {project.name}
      <div>
        <Checkbox
          checked={project.id && selectedProjects.indexOf(project.id) !== -1}
          onClick={() => {
            if (!project.id) return;

            const index = selectedProjects.indexOf(project.id);
            const checked = index !== -1;

            const newSelectedProjects = [...selectedProjects] as number[];

            if (checked) {
              newSelectedProjects.splice(index, 1);
            } else {
              newSelectedProjects.push(project.id);
            }

            setSelectedProjects(newSelectedProjects);
          }}
        />
      </div>
    </ProjectListItem>
  ));

const renderSelecteds = (
  emails: string[],
  onRemove: (emailIndex: number) => void
) =>
  emails.map((email, index) => (
    <Email key={email}>
      {email}
      <svg
        onClick={() => onRemove(index)}
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="16.2617"
          y="4.94922"
          width="2"
          height="16"
          transform="rotate(45 16.2617 4.94922)"
          fill="#99A2AD"
        />
        <rect
          x="17.6758"
          y="16.2637"
          width="2"
          height="16"
          transform="rotate(135 17.6758 16.2637)"
          fill="#99a2ad"
        />
      </svg>
    </Email>
  ));

/**
 * 
 * @param param0 
 * @returns 
 */
const InvitationWithProjectModal = ({
  onClose,
  opened,
  managerProjects,
}: Props) => {
  // contexts
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  const [emailText, setEmailText] = useState<string>('');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [predictedUsers, setPredictedUsers] = useState<PredictUserType[]>([]);
  const [selectedRole, setSelectedRole] = useState<RoleType | null>(null);
  const [error, setError] = useState<ErrorType>({});
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setSelectedEmails([]);
    setSelectedRole(null);
    setEmailText('');
    setPredictedUsers([]);
    setSelectedProjects([]);
  }, [opened]);

  const handleRemove = (emailIndex: number) => {
    const newSelectedEmails = [...selectedEmails];
    newSelectedEmails.splice(emailIndex, 1);
    setSelectedEmails(newSelectedEmails);
  };

  const getError = () => {
    if (error.email && error.role && error.project) {
      return 'You should be select at at least one project, one email and the role.';
    }

    if (error.project) return error.project;
    if (error.email) return error.email;
    if (error.role) return error.role;
    return undefined;
  };

  return (
    <ModalWrapper opened={opened} onClick={onClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Invite</ModalTitle>
          <CloseButton onClick={onClose}>
            <svg
              viewBox="0 0 23 23"
              width="23"
              height="23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="16.2617"
                y="4.94922"
                width="2"
                height="16"
                transform="rotate(45 16.2617 4.94922)"
                fill="#99A2AD"
              />
              <rect
                x="17.6758"
                y="16.2637"
                width="2"
                height="16"
                transform="rotate(135 17.6758 16.2637)"
                fill="#99a2ad"
              />
            </svg>
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          <InviteForm>
            <InputGroup
              label="Email"
              description="Press ENTER or SPACE to confirm"
              customStyle={`
                p {
                  color: #000000;
                  font-size: 10px;
                  margin-top: 8px;
                }

                .input-group-error {
                  display: inline-block;
                  margin-top: 16px;
                  margin-bottom: 8px;

                  font-size: 12px;
                  font-weight: 500;
                }
              `}
            >
              <MultiSelectInputWrapper tabIndex={1} focus={focus}>
                <div>
                  <ul>{renderSelecteds(selectedEmails, handleRemove)}</ul>
                  <Input
                    value={emailText}
                    onFocus={() => {
                      setFocus(true);
                    }}
                    onBlur={() => {
                      setFocus(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();

                        const emailRxp =
                          /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

                        if (!emailRxp.test(emailText)) {
                          setError({ email: 'This is not a valid email' });
                          return;
                        }

                        setError({ email: undefined });

                        setSelectedEmails([
                          ...selectedEmails,
                          emailText.toLowerCase(),
                        ]);
                        setEmailText('');
                      }
                    }}
                    placeholder={'Enter email address'}
                    onChange={(e) => {
                      setEmailText(e.target.value);

                      if (e.target.value) {
                        getPredictUsers(
                          e.target.value,
                          auth.token,
                          setPredictedUsers
                        );
                      } else {
                        setPredictedUsers([]);
                      }
                    }}
                  />
                </div>
                <RoleSelector
                  selected={selectedRole}
                  onSelect={(role: RoleType | null) => setSelectedRole(role)}
                />
              </MultiSelectInputWrapper>
            </InputGroup>

            <PredictUsersWrapper>
              {renderPredictedUsers(
                selectedEmails,
                predictedUsers,
                (email: string) => {
                  setSelectedEmails([...selectedEmails, email]);
                  setError({ email: undefined });
                  setPredictedUsers([]);
                  setEmailText('');
                },
                auth
              )}
            </PredictUsersWrapper>

            <ProjectList>
              <ProjectListHeader>
                <ProjectListTitle>Projects</ProjectListTitle>
                <Checkbox
                  checked={
                    selectedProjects.length === managerProjects.length &&
                    managerProjects.length !== 0
                  }
                  onClick={() => {
                    if (selectedProjects.length === managerProjects.length) {
                      setSelectedProjects([]);
                    } else {
                      setSelectedProjects(
                        managerProjects.map((project) => project?.id || -1)
                      );
                    }
                  }}
                />
              </ProjectListHeader>
              {managerProjects.length ? (
                renderProjectItems(
                  managerProjects,
                  selectedProjects,
                  setSelectedProjects
                )
              ) : (
                <EmptyProjectsWrapper>
                  <EmptyText>
                    There are no projects you can invite users to.
                  </EmptyText>
                </EmptyProjectsWrapper>
              )}
            </ProjectList>

            <Button
              disabled={!auth || !managerProjects.length}
              customStyle={`
                width: auto;
                padding-left: 32px;
                padding-right: 32px;
                height: 36px;

                &:focus {
                  padding-left: 32px;
                  padding-right: 32px;

                  height: 36px;
                }
              `}
              type="button"
              handleClick={() => {
                if (!auth || !managerProjects.length) return;

                const error: ErrorType = {};

                if (!selectedProjects.length) {
                  error.project = 'No one project selected';
                }

                if (!selectedRole) {
                  error.role = 'Role not selected';
                }

                if (!selectedEmails.length) {
                  error.email = 'No one email selected';
                }

                if (!selectedRole || Object.keys(error).length) {
                  setError(error);
                  return;
                }

                sendInvitations(
                  selectedEmails,
                  selectedRole,
                  selectedProjects,
                  auth.token
                );

                // TODO: TOAST

                // dispatch({
                //   type: 'SET_TOAST',
                //   data: {
                //     title: 'Invitations sent!',
                //     type: 'success',
                //   },
                // });

                onClose();

                setSelectedEmails([]);
                setSelectedRole(null);
                setSelectedProjects([]);
                setEmailText('');
              }}
              value="Send invite"
            />

            {getError() ? <Error>{getError()}</Error> : null}
          </InviteForm>
        </ModalContent>
      </Modal>
    </ModalWrapper>
  );
};

export default InvitationWithProjectModal;
