import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 2fr 5fr;
    align-items: start;
    gap: 40px;

    min-height: 100%;
  }
`;

export const DeviceHeader = styled.header`
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const DeviceCheckAll = styled.div<{ hide?: boolean }>`
  display: ${({ hide }) => hide ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 2px 0px rgba(29, 33, 57, 0.05);
`;

export const DeviceList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 170px;
  gap: 12px;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const DeviceContent = styled.div``;

export const DeviceSidebar = styled.div<{ hide?: boolean }>`
  display: none;

  > p {
    color: #656978;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    margin-bottom: 4px;
  }

  @media (min-width: 1024px) {
    display: ${({ hide }) => hide ? 'none' : 'grid'};
    grid-template-rows: auto auto 1fr;
    align-items: start;
    gap: 8px;
    height: calc(100vh - 320px);
  }
`;

export const DeviceSidebarTableWrapper = styled.div`
  max-height: 100%;
  overflow: auto;
`;

export const DeviceContentSearch = styled.div`
  grid-template-columns: 1fr 40px;
  display: grid;
  grid-gap: 10px;

  margin-bottom: 16px;
  align-items: end;
  
  @media (min-width: 1024px) {
    grid-template-columns: 34.5% auto 1fr;
    justify-items: end;
    margin-bottom: 24px;
  }
`;

export const deviceSearchCustomStyle = `
  padding: 0;
  width: 100%;
`;

export const DeviceSidebarSearch = styled.div`
  display: grid;
  align-items: end;
  margin-bottom: 16px;
`;

export const DeviceSidebarActions = styled.div`
  margin-bottom: 16px;
  > button {
    width: auto;
  }
`;

export const DeviceHeaderActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  margin-bottom: 32px;
`;

export const EmptyContent = styled.div`
  text-align: center;
  padding: 180px 60px;
`;

export const EmptyContentTitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;

  color: #161e2e;

  margin-bottom: 12px;
`;
