import { FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from 'react';
import Interact from 'interactjs';
import Typograph from 'stories/type/typograph/typograph';
import OutlineButton from 'stories/base/buttons/outlined-button/outline-button';
import {
  OverlayContainer,
  OverlayContent,
  OverlayFooter,
  OverlayHeader,
} from './overlay.style';

interface Props {
  id: string;
  maxHeight?: number;
  x: number;
  y: number;
  children: ReactNode | null;
  title?: string;
  Header?: FunctionComponent;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
};

/**
 * 
 * @param props 
 * @returns 
 */
const Overlay = ({ Header, ...props }: Props) => {
  // memorize memorize max height
  const maxHeight = useMemo(() => {
    return props.maxHeight || window.innerHeight - 120;
  }, [props.maxHeight]);

  // memorize memorize draggable id
  const draggableId = useMemo(() => {
    return `${props.id}_DRAGGABLE`;
  }, [props.id]);

  // create callback to drag move
  const dragMoveListener = useCallback((event)  => {
    const wrapper = document.getElementById(props.id);
    if (!wrapper) return null;
    const checkIfExists = (data: 'data-x' | 'data-y'): string => {
      return wrapper?.getAttribute(data) || ''
    }
    // keep the dragged position in the data-x/data-y attributes
    const x = (parseFloat(checkIfExists('data-x')) || 0) + event.dx
    const y = (parseFloat(checkIfExists('data-y')) || 0) + event.dy

    // translate the element
    wrapper.style!.transform = 'translate(' + x + 'px, ' + y + 'px)'

    // update the posiion attributes
    wrapper?.setAttribute('data-x', x)
    wrapper?.setAttribute('data-y', y)
  }, [props.id]);

  // set inital position
  useEffect(() => {
    // target elements with the "#GT_DRAGGABLE_TRACKING_POINT" class
    Interact(`#${draggableId}`)
      .draggable({
        inertia: true,
        modifiers: [
          Interact.modifiers.restrictRect({
            endOnly: true
          })
        ],
        listeners: {
          move: dragMoveListener
        }
      })
      .styleCursor(false);
  }, []);

  return (
    <OverlayContainer id={props.id} maxHeight={maxHeight} x={props.x} y={props.y} tabIndex={0}>
      <OverlayHeader id={draggableId}>
        {
          Header ? <Header /> : <Typograph type="subtitle1" text={props.title} />
        }
        <span onClick={props.onClose} className="material-icons-outlined">close</span>
      </OverlayHeader>

      <OverlayContent>{props.children}</OverlayContent>
      
      {
        props.onConfirm || props.onCancel ? (
          <OverlayFooter>
            { props.onConfirm ? (<OutlineButton styleType="confirm" text="Confirm" onClick={props.onConfirm} />) : null }
            { props.onCancel ? (<OutlineButton styleType="cancel" text="Cancel" onClick={props.onCancel} />) : null }
          </OverlayFooter>
        ) : null
      }
    </OverlayContainer>
  );
};

export default Overlay;
