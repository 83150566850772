export const aerisOptions = ({ weatherUnit }: any) => [
  {
    id: 'radar-global',
    label: 'Radar',
  },
  {
    id: 'lightning-strikes-5m-icons',
    label: 'Lightning',
  },
  {
    id: 'radar-global,lightning-strikes-5m-icons',
    label: 'Radar and Lightning',
  },
  {
    id:
      weatherUnit?.id === 'farenheit'
        ? 'temperatures:blend(grain-merge),temperatures-text-dk'
        : 'temperatures:blend(grain-merge),temperatures-text-metric-dk',
    label: 'Current Temperatures',
  },
  {
    id: 'satellite-geocolor:blend(screen)',
    label: 'Cloud Cover (Satellite)',
  },
  {
    id: 'alerts',
    label: 'Alerts (USA)',
  },
  {
    id: 'humidity:blend(grain-merge)',
    label: 'Humidity',
  },
];

export const speedUnitOptions = [
  {
    id: 'mps',
    label: 'm/s',
  },
  {
    id: 'kmh',
    label: 'km/h',
  },
  {
    id: 'mph',
    label: 'mph',
  },
];

export const distanceUnitOptions = [
  {
    id: 'm',
    label: 'm',
  },
  {
    id: 'km',
    label: 'km',
  },
  {
    id: 'ft',
    label: 'ft',
  },
  {
    id: 'mi',
    label: 'mi',
  },
];


export const widgetOptions = [
  {
    id: 'weather',
    label: 'Weather',
  },
  {
    id: 'speed',
    label: 'Speed',
  },
  {
    id: 'elevation',
    label: 'Elevation',
  },
  {
    id: 'inclination',
    label: 'Inclination',
  },
  {
    id: 'arrival',
    label: 'Estimated Time of Arrival',
  },
  {
    id: 'next-point',
    label: 'Estimate to next Tracking Point',
  },
];
