import styled from 'styled-components';

/**
 * 
 * @param columns 
 */
const generateColumns = (columns: number) => {
  const autos: string[] = [];
  for (let index = 1; index < columns; index = index + 1) {
    autos.push('1fr');
  }

  return autos.join(' ');
};

export const Content = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => generateColumns(columns)};
  gap: 16px;
`;

export const PassagesSection = styled.section<{ hide?: boolean }>`
  display: grid;
  gap: 8px;

  & + & {
    margin-top: 22px;
  }

  ${({ hide }) => hide ? 'display: none;': ''}
`;

export const SectionTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(105, 115, 134, 0.75);

  margin-bottom: 9px;
`;

export const Empty = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: rgba(26, 31, 54, 0.65);
`;

export const EmptyWrapper = styled.div`
  text-align: center;
  padding: 16px;
`;
