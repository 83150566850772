import { useContext, useState, useMemo, useEffect } from 'react';
import {
  saveSurvey,
} from './updateSurveyModal.controller';
import {
  ModalWrapper,
  Modal,
  Header,
  CloseIcon,
  Container,
  NameLabel,
} from './updateSurveyModal.style';
import Form from './components/first-form/first-form';
import AccountStore from 'app/modules/account/account.context';
import SurveyStore from '../../survey.context';
import SettingsStore from 'app/modules/settings/settings.context';

const UpdateSurveyModal = () => {
  const accountContext = useContext(AccountStore);
  const surveyContext = useContext(SurveyStore);
  const settingsContext = useContext(SettingsStore);
  const [survey, setSurvey] = useState(surveyContext.state.survey);
  const [errors, setErrors] = useState({});
  const opened = useMemo(() => {
    const isOpened = surveyContext.state.opened_modal === 'update-survey-modal';
    if (isOpened) {
      settingsContext.dispatch({ type: 'SCROLL_BLOCK', data: true });
    } else settingsContext.dispatch({ type: 'SCROLL_BLOCK', data: false });
    return isOpened
  }, [surveyContext.state.opened_modal]);
  
  useEffect(() => {
    setSurvey(surveyContext.state.survey)
  }, [surveyContext.state.survey])


  if (!opened) return null
  return (
    <ModalWrapper>
      <Modal>
        <Header>
          <CloseIcon
            onClick={() => 
              surveyContext.dispatch({ type: 'OPEN_MODAL' })
            }
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
            <NameLabel>{survey?.name}</NameLabel>
          </CloseIcon>
        </Header>
        <Container>
          <Form
            survey={survey}
            setErrors={setErrors}
            onSubmit={(survey: any) => {
              saveSurvey({
                survey,
                auth: accountContext.state.auth,
                setErrors,
                dispatch: surveyContext.dispatch,
              });
            }}
            errors={errors}
            isLoading={surveyContext.state.loading}
          />
        </Container>
      </Modal>
    </ModalWrapper>
  );
};

UpdateSurveyModal.defaultProps = {};

export default UpdateSurveyModal;
