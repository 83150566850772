import styled from 'styled-components';

export const SurveyPageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const TemplateContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 92px);
  display: grid;
  grid-template-rows: 1fr 50vh;
  overflow: auto;
  overflow-x: hidden;
`;

export const MapContainer = styled.div`
  width: 100%;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: initial;
    position: relative;
    & img {
      max-width: none;
    }
    .gmnoprint[role*='menu'] {
      border-radius: 4px !important;
      box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px !important;
      > .gm-style-mtc {
        > button {
          height: 36px !important;
          background-color: rgba(255, 255, 255) !important;
          backdrop-filter: blur(10px);
          font-size: 16px !important;
        }
      }
    }
    .info-window {
      .run-title {
        margin-bottom: 16px;
      }
      .field + .field {
        margin-top: 4px;
      }
      .field > label {
        font-weight: 700;
      }
    }
  }
  @media (max-width: 374px) {
    display: none;
  }
`;

export const MenuButton = styled.button<{ hide: boolean }>`
  border: 1px solid #dfe0e4;
  border-radius: 6px;
  background-color: transparent;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  cursor: pointer;

  position: absolute;
  right: 10px;
  top: 5px;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const MenuListWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  top: 40px;
  right: 0;

  display: grid;

  width: 145px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;

  overflow: hidden;
  z-index: 3;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const MenuListItem = styled.div`
  padding: 8px;

  display: grid;
  grid-template-columns: 24px auto;
  justify-items: start;

  gap: 6px;

  font-size: 13px;
  font-weight: 500;

  color: #6b7280;

  :hover {
    background-color: #f1f1f1;
  }
`;
