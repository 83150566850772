import styled from 'styled-components';

interface SkeletonProps {
  $inline?: boolean;
  $hidden?: boolean;
  $width?: string;
  $height?: string;
  $color?: string;
}

export const SkeletonStyle = styled.div<SkeletonProps>`
  position: relative;
  display: ${({ $inline }) => ($inline ? 'inline-block' : 'block')};
  ${({ $hidden }) => ($hidden ? 'display: none;' : '')}
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  background-color: rgba(255, 255, 255, 0.25);

  & + & {
    margin-top: 8px;
  }

  &.rect {
    border-radius: 4px;
  }

  &.circle {
    border-radius: 50%;
  }

  &.text {
    height: 20px;
    transform: scale(1, 0.6);
    margin-top: 0;
    border-radius: 4px;
    margin-bottom: 0;
    transform-origin: 0 60%;
    line-height: 16px;
  }

  &.wave {
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      animation: wave 1s linear 0.5s infinite;
      transform: translate(-100%);
      background: linear-gradient(
        90deg,
        transparent,
        ${({ $color }) => $color},
        transparent
      );
    }
  }

  &.pulse {
    overflow: hidden;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      animation: pulse 1.5s ease-in-out 0.5s infinite;
      background: ${({ $color }) => $color};
;
    }
  }

  @keyframes wave {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
`;
