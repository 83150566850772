import Skeleton from '../../../../../../skeleton/skeleton';
import Elevation from './icons/Elevation';
import Speed from './icons/Speed';
import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import {
  WidgetsContainer,
  WidgetList,
  Widget,
  WidgetContent,
  WidgetValue,
  WidgetLabel,
  Timer,
  Menu,
  CloseDiv,
  TrackingIcon,
} from './widgets.style';
import { useContext, useMemo } from 'react';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import { getTrackingPointIconSrc } from 'app/utils/map.utils';
import { RunCascadeType } from 'app/modules/inspection/inspection.interfaces';

/**
 *
 * @returns
 */
const Widgets = ({
  displayNone,
  hide,
  setHide,
  }: any) => {
    const inspectionContext = useContext(InspectionStore);
    const settingsContext = useContext(SettingsStore);
    const speed = useMemo(() => inspectionContext.run?.estimation?.speed, [inspectionContext.run?.estimation?.speed])
    const elevation = useMemo(() => inspectionContext.run?.estimation?.elevation, [inspectionContext.run?.estimation?.elevation])
    const forecast = useMemo(() => inspectionContext.run?.forecast, [inspectionContext.run?.forecast])
    const inclination = useMemo(() => inspectionContext.run?.estimation?.inclination, [inspectionContext.run?.estimation?.inclination])
    const final_eta = useMemo(() => inspectionContext.run?.estimation?.final_eta, [inspectionContext.run?.estimation?.final_eta])
    const endPoint = useMemo(() => inspectionContext.run?.trackingpoint_set?.[inspectionContext.run?.trackingpoint_set?.length - 1], [inspectionContext.run?.trackingpoint_set])
    const nextPointIndex = useMemo(() => {
      const run = inspectionContext.run as RunCascadeType;
      if (!run?.trackingpoint_set) return undefined;
      const npoint = run.trackingpoint_set?.findIndex((point) => point.id === inspectionContext.run?.estimation?.next_point);
      return npoint;
    }, [inspectionContext.run?.estimation?.next_point]);
      
    const nextPoint = useMemo(() => {
      if (nextPointIndex === undefined) return undefined;
      return inspectionContext.run?.trackingpoint_set?.[nextPointIndex];
    }, [nextPointIndex, inspectionContext.run?.trackingpoint_set]);
    
    return (inspectionContext.state.widgets ? (
      <WidgetsContainer
        $displayNone={displayNone}
        $hide={hide || !inspectionContext.state.widgets}
      >
        <WidgetList>
          <CloseDiv onClick={() => setHide(true)}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
              <path
                d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
                fill="#9CA4AC"
              />
              <path
                d="M14.8282 9.17155L9.17139 14.8284"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.17139 9.17155L14.8282 14.8284"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CloseDiv>
          <Widget $hide={!inspectionContext.state.widgets['weather']}>
            <figure className="large">
              <img // TODO: Fix forecast widgets
                alt=""
                src={`/assets/icons/weather/${forecast?.icon || 'sunny.png'
                  }`}
              />
            </figure>
            <WidgetContent>
              <Skeleton
                loading={!!inspectionContext.run?.launched && !forecast?.feelslikeF}
                width="59px"
                height="9px"
              >
                <WidgetValue className='value'>
                  {settingsContext.state.weatherUnit?.id === 'farenheit'
                    ? forecast?.feelslikeF
                    : forecast?.feelslikeC}
                  {settingsContext.state.weatherUnit?.label || '---'}
                </WidgetValue>
              </Skeleton>
              <Skeleton
                loading={!!inspectionContext.run?.launched && !forecast?.weather}
                width="32px"
                height="6px"
              >
                <WidgetLabel>{forecast?.weather || 'Weather'}</WidgetLabel>
              </Skeleton>
            </WidgetContent>
          </Widget>
          <Widget
            $hide={!inspectionContext.state.widgets['speed']}
            onClick={() => inspectionContext.dispatch({ type: 'TOGGLE_MODAL', data: 'chart/speed'})}
            data-testid="w-speed"
            $pointer
          >
            <figure>
              <Speed />
            </figure>
            <WidgetContent>
              <Skeleton
                loading={false}
                width="59px"
                height="9px"
              >
                <WidgetValue className='value'>
                  {formatMps({
                    distance: speed, // TODO: When run is finished show the last passage speed
                    unit: settingsContext.state.speedUnit,
                  }) || '---'}
                </WidgetValue>
              </Skeleton>
              <Skeleton loading={false} width="32px" height="6px">
                <WidgetLabel>Speed</WidgetLabel>
              </Skeleton>
            </WidgetContent>
          </Widget>
          <Widget
            $hide={!inspectionContext.state.widgets['elevation']}
            onClick={() => inspectionContext.dispatch({ type: 'TOGGLE_MODAL', data: 'chart/elevation'})}
            data-testid="w-elevation"
            $pointer
          >
            <figure className="xlarge">
              <Elevation />
            </figure>
            <WidgetContent>
              <Skeleton loading={!!(inspectionContext.run?.launched && !elevation)} width="59px" height="9px">
                <WidgetValue className='value'>
                  {formatDistance({
                    distance: elevation,  // TODO: When run is finished show the last passage elevation
                    unit: settingsContext.state.distanceUnit?.id,
                  }) || '---'}
                </WidgetValue>
              </Skeleton>
              <Skeleton loading={false} width="32px" height="6px">
                <WidgetLabel>Elevation</WidgetLabel>
              </Skeleton>
            </WidgetContent>
          </Widget>
          <Widget
            $hide={!inspectionContext.state.widgets['inclination']}
            onClick={() =>inspectionContext.dispatch({ type: 'TOGGLE_MODAL', data: 'chart/inclination'})}
            $pointer
            data-testid="w-inclination"
          >
            <figure>
              <img alt="" src="/assets/icons/inclination.png" />
            </figure>
            <WidgetContent>
              <Skeleton
                loading={!!(inspectionContext.run?.launched && !inclination)}
                width="59px"
                height="9px"
              >
                <WidgetValue className='value'>
                  {`${inclination ? `${inclination}º` : '---'}`}
                </WidgetValue>
              </Skeleton>
              <Skeleton loading={false} width="32px" height="6px">
                <WidgetLabel>Inclination</WidgetLabel>
              </Skeleton>
            </WidgetContent>
          </Widget>
          <Widget // TODO: get next point 
            data-testid="w-eta-next-point"
            $minWidth="370px"
            $hide={
              !inspectionContext.state.widgets['next_point'] ||
              !inspectionContext.run?.launched ||
              !!inspectionContext.run?.is_finished
            }
          >
            <TrackingIcon
              alt=""
              src={nextPoint ? getTrackingPointIconSrc(nextPoint, 'no-border')?.url : ''}
            />
            <WidgetContent>
              <Skeleton loading={!nextPoint?.eta} width="59px" height="9px">
                <WidgetValue className='value'>
                  <div>
                    <Timer>
                      {formatTimezoneDate({
                        date: nextPoint?.eta,
                        timezone: settingsContext.state.timezone?.id,
                        format: 'H:mm',
                      })}
                    </Timer>
                    {formatTimezoneDate({
                      date: nextPoint?.eta,
                      timezone: settingsContext.state.timezone?.id,
                      format: 'll',
                    })}
                  </div>
                </WidgetValue>
              </Skeleton>
              <Skeleton loading={false} width="32px" height="6px">
                <WidgetLabel>ETA at Next Point</WidgetLabel>
              </Skeleton>
            </WidgetContent>
          </Widget>
          <Widget
            data-testid="w-eta-receiver"
            $minWidth="370px"
            $hide={!!(
              !inspectionContext.state.widgets['arrival'] ||
              !inspectionContext.run?.launched ||
              inspectionContext.run?.is_finished
            )}
          >
            <TrackingIcon //TODO: get last point icon
              alt=""
              src={endPoint ? getTrackingPointIconSrc(endPoint, 'no-border')?.url : ''}
            />
            <WidgetContent>
              <Skeleton loading={!final_eta} width="59px" height="9px">
                <WidgetValue className='value'>
                  <div>
                    <Timer>
                      {formatTimezoneDate({
                        date: final_eta,
                        timezone: settingsContext.state.timezone?.id,
                        format: 'H:mm',
                      })}
                    </Timer>
                    {formatTimezoneDate({
                      date: final_eta,
                      timezone: settingsContext.state.timezone?.id,
                      format: 'll',
                    })}
                  </div>
                </WidgetValue>
              </Skeleton>
              <Skeleton loading={false} width="32px" height="6px">
                <WidgetLabel>ETA at Receiver</WidgetLabel>
              </Skeleton>
            </WidgetContent>
          </Widget>
        </WidgetList>
        <Menu data-testid="w-collapse-button" $isShowing={!hide} onClick={() => setHide(!hide)}>
          <svg viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 9L5 5L1 1V9Z"
              fill="#5850ec"
              stroke="#5850ec"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        </Menu>
      </WidgetsContainer>
    ) : null
  );
};

export default Widgets;
