import {
  ActionsWrapper,
  HeaderWrapper,
  NewProjectButton,
} from './header.style';

interface Props {
  isAdministrator: boolean;
  onOpenCreationModal: () => void;
}

const Header = ({ isAdministrator, onOpenCreationModal }: Props) => (
  <HeaderWrapper>
    <ActionsWrapper>
      <NewProjectButton data-testid="new-project-button" hide={!isAdministrator} onClick={onOpenCreationModal}>
        + New Project
      </NewProjectButton>
    </ActionsWrapper>
  </HeaderWrapper>
);

export default Header;
