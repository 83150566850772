import styled from 'styled-components';

export const Container = styled.main`
  display: grid;
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Form = styled.div`
  width: 100%;
  padding: 0 16px;

  width: 100%;
  padding: 30px 16px 60px;
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.label`
  display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18;
  line-height: 22px;
  color: #161e2e;
`;

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const IlustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #eff0f3;
  @media (max-width: 1024px) {
    svg {
      max-width: 400px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FilesWrapper = styled.div``;

export const FileItem = styled.div`
  padding: 8px 0;
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

export const FileLabel = styled.label`
  font-size: 14px;
`;
