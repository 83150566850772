import { ReactElement, useReducer } from "react";
import { Route, Switch } from 'react-router-dom';
import SurveyList from './survey-list/survey-list.page';
import SurveyPage from './survey/survey.page';
import SurveyStore from "./survey.context";
import { SurveyContext, SurveyDispatchType } from "./survey.context.d";
import { reducer } from "./survey.reducer";

export const initialSurveyState: SurveyContext = {
  survey: undefined,
  opened_modal: undefined,
  loading: false,
  selected_point: undefined,
  points_dic: {},
  medias_dic: {},
  files_dic: {},
  pipeline: undefined,
}

interface Props {
  value?: unknown;
  children: ReactElement;
}

/**
 * 
 * @returns 
 */
const SurveyModule = ({ value, children }: Props) => {
  // Define state and dispatch method for this Context
  const reducerData = useReducer(reducer, { ...initialSurveyState, ...value as SurveyContext });
  const state: SurveyContext = reducerData[0];
  const dispatch: (data: SurveyDispatchType) => void = reducerData[1];

  // props comes afterwards so the can override the default ones.
  return (
    <SurveyStore.Provider value={{ state, dispatch }}>
      <Switch>
        <Route exact path="/surveys" component={SurveyList} />
        <Route exact path="/surveys/:id" component={SurveyPage} />
        {children}
      </Switch>
    </SurveyStore.Provider>

  );
}
export default SurveyModule;
