import styled from 'styled-components';

/**
 * Get the position of the overlay based on the x and y coordinates
 * @param x The x coordinate
 * @param y The y coordinate
 * @returns String with the position of the overlay
 */
const getPosition = (x: number, y: number) => {
  const xPosition = x >= 0 ? 'left' : 'right';
  const yPosition = y >= 0 ? 'top' : 'bottom';
  return `
    ${yPosition}: ${Math.abs(y)}px;
    ${xPosition}: ${Math.abs(x)}px;
  `;
};

export const OverlayContainer = styled.div<{ maxHeight: number; x: number; y: number }>`
  position: absolute;
  z-index: 11;
  
  ${({ x, y }) => getPosition(x, y)}

  width: auto;
  min-width: 300px; 
  min-height: 200px;
  max-height: ${({ maxHeight }) => maxHeight}px;

  display: grid;
  grid-template-rows: 50px 1fr;

  &:focus {
    z-index: 12;
  }
`;

export const OverlayHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.surface_white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 0 0;
  cursor: move;

  padding: 8px 16px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.outline};

  > span.material-icons-outlined {
    color: ${({ theme }) => theme.colors.surface_on_high};
    font-size: 17px;
    font-weight: 900;
    cursor: pointer;
  }
`;

export const OverlayContent = styled.div`
  background-color: ${({ theme }) => theme.colors.surface_white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 6px 6px;

  overflow: auto;
`;

export const OverlayFooter = styled.footer`
  position: absolute;
  width: 100%;
  top: 100%;

  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  padding-top: 16px;
  padding-bottom: 16px;
`;