import styled from 'styled-components';

export const NavigationContainerDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 240px 1fr;
  }
`;
