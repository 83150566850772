import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumbs, { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
// context
import AccountStore, { permissions } from 'app/modules/account/account.context';
// interfaces
import { AuthType } from 'app/modules/account/account.context.d';
import { SurveyType } from 'app/services/survey.service';
import SurveysTable from './components/table/surveys-table';
import SearchInput from 'app/components/search-input/search-input';
import NavigationContainer from 'app/components/navigation-container/navigation-container';
import DefaultButton from 'app/components/default-button/default-button';
import { filterUserBySearch, getAllSurveys } from './survey-list.page.controller';
import {
  ActionsWrapper,
  List,
  UsersContainer,
  UsersContent,
  UsersContentHeader,
  Title,
} from './survey-list.page.style';
import SurveyStore from '../survey.context';
import CreateSurveyModal from '../components/create-survey-modal/createSurveyModal';

/**
 *
 * @returns
 */
const SurveysPage = () => {
  // router
  const history = useHistory();
  // contexts
  const accountContext = useContext(AccountStore);
  const surveyContext = useContext(SurveyStore);
  const auth = accountContext.state.auth as AuthType;
  const isSurveyManager = accountContext.state.permissions.includes(permissions.SURVEY_MANAGER); 
  
  const [loading, setLoading] = useState<boolean>(false);
  const [viewType, setViewType] = useState<string>('cards');
  const [search, setSearch] = useState<string>('');
  const [allSurveys, setAllSurveys] = useState<any[]>([]);
  const [surveys, setSurveys] = useState<any[]>([]);

  const breadcrumbs: Breadcrumb[] = [
    {
      id: '#1',
      text: 'Surveys',
      loading,
      href: '/surveys',
    },
  ];

  useEffect(() => {
    const viewType = window.localStorage.getItem('CDI@users-page/view-mode');
    if (viewType) setViewType(viewType);
    getAllSurveys({ setAllSurveys, setSurveys, auth, setLoading });
  }, []);

  useEffect(() => {
    if (!auth.token) history.push('/');
  }, [auth.token]);

  useEffect(() => {
    filterUserBySearch({ allSurveys, search, setSurveys });
  }, [search]);

  useEffect(() => {
    window.localStorage.setItem('CDI@users-page/view-mode', viewType);
  }, [viewType]);
  
  useEffect(() => {
    if (!isSurveyManager) history.push('/projects');
  }, [isSurveyManager]);

  return (
    <NavigationContainer>

        <UsersContainer>
          <Breadcrumbs
            customStyle={`
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              @media (min-width: 1024px) {
                margin-left: 240px;
              }
            `}
            routes={breadcrumbs}
          />
          <UsersContent>
            <UsersContentHeader>
              <Title>Surveys</Title>
              <ActionsWrapper>
                <SearchInput
                  customStyle={`
                    max-width: 500px;
                  `}
                  value={search}
                  placeholder="Search by name or pipeline name"
                  onChange={({ target }) => setSearch(target.value)}
                />
                <DefaultButton
                  onClick={() => {
                    surveyContext.dispatch({ type: 'OPEN_MODAL', data: 'create-survey-modal' })
                  }}
                  customStyle={`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    position: relative;
                    top: 1px;
                    height: 40px;
                  `}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="22" height="22" rx="3" fill="#3E38B3" />
                    <rect x="10" y="5" width="2" height="12" rx="1" fill="white" />
                    <rect
                      x="17"
                      y="10"
                      width="2"
                      height="12"
                      rx="1"
                      transform="rotate(90 17 10)"
                      fill="white"
                    />
                  </svg>
                  Upload Survey
                </DefaultButton>
              </ActionsWrapper>
            </UsersContentHeader>
            {!loading ? (
              <List id="survey-list-table">
                <SurveysTable
                  handleLineCLick={(survey: SurveyType) => {
                    history.push(`/surveys/${survey.id}`, { isEditing: true });
                  }}
                  surveys={surveys}
                />
              </List>
            ) : (
              <label>Loading . . .</label>
            )}
          </UsersContent>
          <CreateSurveyModal
            onDone={() =>
              getAllSurveys({
                setAllSurveys,
                setSurveys,
                auth,
                setLoading,
              })
            }
          />
        </UsersContainer>
    </NavigationContainer>
  );
};

export default SurveysPage;
