import styled from 'styled-components';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 450px 1fr;

  header {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  footer {
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    display: block;
    height: 100vh;
    width: 100%;
  }
`;

export const ForgotPasswordLink = styled.a`
  display: flex;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.12em;
  color: #5850ec;
  justify-content: flex-end;
  cursor: pointer;

  margin-top: -8px;
`;

export const Form = styled.form`
  display: grid;
  gap: 16px;

  width: 500px;
`;

export const ActionGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Banner = styled.section`
  background-color: #eff0f3;
  font-size: 0.75;
  line-height: 1.125em;
  cursor: pointer;
  position: relative;
  padding: 24px 26px 0;
`;

export const IlustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 260px;
  margin: auto;

  margin-top: 24px;

  @media (min-width: 768px) {
    width: 360px;
    margin-top: 104px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const ContactText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2em;
  color: #4b5066;

  margin-top: 32px;

  > strong {
    font-weight: 500;
  }
`;
