import styled from 'styled-components';

export const InputWrapper = styled.div<{ customStyle?: string }>`
  display: grid;
  grid-template-columns: 1fr 26px;
  align-items: center;

  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 5px 10px;

  width: 100%;
  height: 40px;

  overflow: hidden;

  > figure {
    display: inline-block;
    text-align: right;
  }

  ${({ customStyle }) => customStyle}
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: 100%;
  padding: 0;

  &:focus {
    padding: 0;
  }
`;
