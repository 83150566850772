import { updateProject } from '../../services/projects.service';
import { Project } from './editProjectModal.interfaces';

export const saveProject = async (
  project: Project,
  auth: any,
  closeAction: any,
  cb: (data?: any) => void
) => {
  await updateProject(project, auth.token);
  closeAction();

  cb();
};
