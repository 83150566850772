import FilledButton from "stories/base/buttons/filled-button/filled-button";
import TextButton from "stories/base/buttons/text-button/text-button";
import Typograph from "stories/type/typograph/typograph";
import {
  ProjectDevicesBarContainer,
  ProjectDevicesBarSelectedAmount,
  ProjectDevicesBarActionsContainer,
} from "./project-devices-bar.style";

interface Props {
  selecteds: number[];
  onDeselectAll: () => void;
  onRemoveDevices: () => void;
}

/**
 * 
 * @param props 
 * @returns 
 */
const ProjectDevicesBar = (props: Props) => {  
  return (
    <ProjectDevicesBarContainer>
      <div>
        <ProjectDevicesBarSelectedAmount>
          {props.selecteds.length}
        </ProjectDevicesBarSelectedAmount>
        <Typograph type="subtitle2" text="Selected" />
        <TextButton text="Deselect all" onClick={props.onDeselectAll} />
      </div>
      
      <div>
        <ProjectDevicesBarActionsContainer>
          <FilledButton
            icon="remove"
            text="Remove devices"
            onClick={props.onRemoveDevices}
          />
        </ProjectDevicesBarActionsContainer>
      </div>
    </ProjectDevicesBarContainer>
  );
};

export default ProjectDevicesBar;